import React, { useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import LanguageContext from 'language-context';
import { getCompanyInLists } from 'store/company/tasks';
import Icon from 'components/icon';
import Loading from 'components/loading';
import Tooltip from 'components/tooltip';
import WidgetHeader from 'components/widget_header';
import moment from 'moment';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

const ListsList = ({ data }) => {
  const [open, setOpen] = useState([]);

  const tc = useContext(LanguageContext);

  const handleClick = (id) => {
    const isOpen = open.includes(id);

    isOpen
      ? setOpen(open.filter((listId) => listId !== id))
      : setOpen([...open, id]);
  };

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={tc.lists}
    >
      {data.map((list) => (
        <div key={list.id}>
          <ListItemButton onClick={() => handleClick(list.id)}>
            <ListItemIcon>
              <Icon val="list" />
            </ListItemIcon>
            <ListItemText
              primary={list.name}
              secondary={`${tc.created}: ${moment(
                new Date(list.created)
              ).format('YYYY-MM-DD')}`}
            />
            {open.includes(list.id) ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          {list.users && list.users.length > 0
            ? list.users.map((user) =>
                user && user.name ? (
                  <Collapse
                    in={open.includes(list.id)}
                    timeout="auto"
                    unmountOnExit
                    key={user.id}
                  >
                    <List component="div" disablePadding>
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <Icon val="user" />
                        </ListItemIcon>
                        <ListItemText primary={user.name} />
                      </ListItemButton>
                    </List>
                  </Collapse>
                ) : null
              )
            : null}
        </div>
      ))}
    </List>
  );
};
/**
 * CompanyInLists component displays information about a company in lists.
 *
 * @param id - The ID of the company.
 * @param companyInLists - Information about the company in various lists.
 * @param isMinimized - A boolean indicating whether the component is minimized or not.
 * @param onMinimizeChange - Callback function invoked when the minimize state changes.
 */
const CompanyInLists = ({
  id,
  companyInLists,
  isMinimized,
  onMinimizeChange,
  isCompanyPage,
}) => {
  const [minimize, setMinimize] = useState(true);
  const tc = useContext(LanguageContext);
  useEffect(() => {
    setMinimize(isMinimized);
  }, [isMinimized]);

  useEffect(() => {
    if (id) {
      getCompanyInLists({ id });
    }
  }, [id]);

  return (
    <div className="companyInListsWrapper" id="companyInLists">
      <div className="companyInListsWrapper__companyInLists">
        <div className="companyInListsWrapper__companyInLists__header">
          <WidgetHeader
            dashboardItems={
              isCompanyPage
                ? null
                : [
                    {
                      disabled: !minimize,
                      icon: 'maximize',
                      label: tc.maximize,
                      onClick: () => {
                        setMinimize(false);
                      },
                    },
                    {
                      disabled: minimize,
                      icon: 'minimize',
                      label: tc.minimize,
                      onClick: () => {
                        setMinimize(true);
                        if (onMinimizeChange) {
                          onMinimizeChange(true);
                        }
                      },
                    },
                  ]
            }
            headline={tc.lists}
            headlineSub={tc.prospectExistsInFollowingLists}
          />
        </div>
        <div
          className={
            minimize
              ? 'companyInListsWrapper__companyInLists__content minimize'
              : 'companyInListsWrapper__companyInLists__content'
          }
        >
          {companyInLists ? <ListsList data={companyInLists} /> : <Loading />}
        </div>
      </div>
    </div>
  );
};

const MapStateToProps = (state) => {
  return {
    companyInLists: state.company.companyInLists,
  };
};

export default connect(MapStateToProps)(CompanyInLists);
