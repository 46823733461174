import React, { useContext, useEffect, useState } from 'react';
import LanguageContext from 'language-context';
import { getLists, removeLists, undoArchive } from 'store/lists/tasks';
import { connect } from 'react-redux';
import { tableHelper } from 'helpers';
import { Table } from 'components/table';
import Info from 'components/info';
import Menu from 'components/menu';
import PageHeader from 'components/page_header';
import Popup from 'components/popup';
import WidgetHeader from 'components/widget_header';
import WidgetFooter from 'components/widget_footer';

const ListsArchived = (state) => {
  const [activePopup, setActivePopup] = useState('');
  const [selectedLists, setSelectedLists] = useState([]);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    getLists({
      archived: true,
      orderInformation: true,
    });
  }, []);

  const _removeLists = async () => {
    setActivePopup('');
    await removeLists({
      archived: true,
      listIds: selectedLists.map((list) => list.id),
    });
    return setSelectedLists([]);
  };

  const _stateCheck = () => {
    return !!(state && state.lists && state.lists.listsArchived !== null);
  };

  const _undoArchive = async () => {
    setActivePopup('');
    await undoArchive({
      listIds: selectedLists.map((list) => list.id),
    });
    return setSelectedLists([]);
  };

  return (
    <div className="listsSubcomponentWrapper">
      <div className="listsSubcomponentWrapper__listsSubcomponent">
        <div className="listsSubcomponentWrapper__listsSubcomponent__header">
          <PageHeader
            headlineSub={
              _stateCheck()
                ? `${tc.total} ${
                    state.lists.listsArchived.length
                  } ${tc.lists.toLowerCase()}`
                : null
            }
          />
        </div>
        {_stateCheck() ? (
          <div className="listsSubcomponentWrapper__listsSubcomponent__content">
            <div className="listsSubcomponentWrapper__listsSubcomponent__content__top">
              {state.lists.listsArchived.length ? (
                <Menu
                  items={[
                    {
                      disabled: !selectedLists.length,
                      label: tc.undoArchive,
                      onClick: _undoArchive,
                      type: 'button',
                    },
                    {
                      disabled: !selectedLists.length,
                      label:
                        selectedLists.length > 1
                          ? tc.removeLists
                          : tc.removeList,
                      onClick: () => {
                        setActivePopup('removeLists');
                      },
                      type: 'button',
                    },
                  ]}
                />
              ) : null}
            </div>
            <div className="listsSubcomponentWrapper__listsSubcomponent__content__bottom">
              {state.lists.listsArchived.length ? (
                <Table
                  adjustToContainer={true}
                  columns={tableHelper.getListsColumns()}
                  onSelect={(arr) => {
                    setSelectedLists(
                      state.lists.listsArchived.filter((list) =>
                        arr.includes(list.id)
                      )
                    );
                  }}
                  rows={tableHelper.getListsRows(state.lists.listsArchived)}
                  selected={selectedLists.map((list) => list.id)}
                />
              ) : (
                <Info>
                  <h4>{tc.noListsArchived}</h4>
                  <p>{tc.noListsArchivedWhy}</p>
                </Info>
              )}
            </div>
            {activePopup === 'removeLists' ? (
              <Popup
                close={() => {
                  setActivePopup('');
                }}
                size="small"
              >
                <div className="listsGenericWrapper">
                  <div className="listsGenericWrapper__listsGeneric">
                    <div className="listsGenericWrapper__listsGeneric__header">
                      <WidgetHeader
                        headline={
                          selectedLists.length > 1
                            ? tc.removeLists
                            : tc.removeList
                        }
                      />
                    </div>
                    <div className="listsGenericWrapper__listsGeneric__content">
                      <p>{tc.ensure}</p>
                    </div>
                    <div className="listsGenericWrapper__listsGeneric__footer">
                      <WidgetFooter
                        buttonOneFunc={_removeLists}
                        buttonOneText={tc.remove}
                        buttonTwoFunc={() => {
                          setActivePopup('');
                        }}
                        buttonTwoText={tc.cancel}
                      />
                    </div>
                  </div>
                </div>
              </Popup>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

const MapStateToProps = (state) => {
  return {
    lists: state.lists,
  };
};

export default connect(MapStateToProps)(ListsArchived);
