import React, { useContext, useEffect, useRef, useState } from 'react';
import LanguageContext from 'language-context';
import { createDeal } from 'store/agile/tasks';
import { showFlashMessage } from 'store/flash_messages/tasks';
import ReactS3Uploader from 'react-s3-uploader';
import carHelper from 'shared_helpers/car_helper';
import { dealHelper } from 'helpers';
import { connect } from 'react-redux';
import { Dropdown, DropdownItem } from 'components/dropdown';
import ActionButton from 'components/action_button';
import ColleaguesDropdown from 'components/colleagues_dropdown';
import Loading from 'components/loading';
import Popup from 'components/popup';
import Search from 'components/search';
import WidgetFooter from 'components/widget_footer';
import WidgetHeader from 'components/widget_header';
import DragAndDropUploader from '../drag_and_drop/drag_and_drop_uploader';
import Icon from 'components/icon';
import Tooltip from 'components/tooltip';
/**
 * Render a component where user can create a new deal.
 *
 * If target is provided, that prospect is included in the deal, otherwise it starts with an empty deal.
 * User always have the possibility to search for prospects to att to deal.
 * If target is provided and state.props.koncern === true user have the possibility to add specific cars from koncern fleet to deal.
 * If target is provided and state.props.koncern === true user have the possibility to add companies from within that koncern to deal.
 *
 * @param state.props.cars - array (optional) - Object of cars to be included in deal.
 * @param state.props.close - func - Function to close component. Send param true when deal is created.
 * @param state.props.headline - string (optional) - If sub headline is wanted.
 * @param state.props.koncern - bool (optional) - If target is a company org nr and type === 'company' and the company is part of a koncern, this should be set to true.
 * @param state.props.prospects - array (optional) - Object of prospects to be included in deal.
 * @param state.props.target - string (optional) - Company org nr | person user id.
 */
const CreateDeal = (state) => {
  const [newDealObj, setNewDealObj] = useState({});

  const newDealDescriptionInputRef = useRef(null);
  const newDealNameInputRef = useRef(null);
  const newDealPotentialInputRef = useRef(null);

  const [selectedOption, setSelectedOption] = useState('prospect');

  const tc = useContext(LanguageContext);

  useEffect(() => {
    if (state.props && state.user && state.user.info) {
      setNewDealObj({
        cars:
          state.props.cars && Array.isArray(state.props.cars)
            ? state.props.cars
            : [],
        contacts: [],
        description: '',
        files: [],
        name: '',
        prospects: [],
        prospectId:
          state.props.prospects && Array.isArray(state.props.prospects)
            ? state.props.prospects[0]
            : null,
        user_id: state.user.info.id,
        userName: state.user.info.name,
      });
    }
  }, [state.props, state.user]);

  const dealHasCar = newDealObj.cars?.length > 0;
  const dealHasProspect = newDealObj.prospects?.length > 0;

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const handleFileUpload = (files) => {
    const updatedFiles = files.map((file) => ({
      s3_filename: file.name,
      original_name: file.name,
    }));
    setNewDealObj({
      ...newDealObj,
      files: [...newDealObj.files, ...updatedFiles],
    });
  };

  const _addSelectedItemsToDealObj = () => {
    const selectedCars = state.search.selectedCars;
    const selectedProspect = state.search.selectedAll;
    let prospects = [];
    let cars = [];

    // Check if a prospect is being added
    if (selectedProspect.length > 0) {
      // If a prospect is being added, remove all cars
      setNewDealObj({
        ...newDealObj,
        prospects: selectedProspect,
        cars: [], // Remove all cars
      });

      return; // Exit the function early
    }

    // Add cars only if no prospect is being added
    selectedCars.forEach((car) => {
      if (carHelper.isValidRegNumber(car.id)) {
        if (!newDealObj.cars.find((x) => x.id === car.id)) {
          cars.push(car);
        }
      }
    });

    setNewDealObj({
      ...newDealObj,
      prospects: prospects,
      cars: cars,
    });
  };

  const _finishUpload = async (e, f) => {
    setNewDealObj({
      ...newDealObj,
      files: newDealObj.files.concat([
        {
          s3_filename: e.filename,
          original_name: f.name,
        },
      ]),
    });
  };

  const _onInputChange = () => {
    let potential = newDealObj.potential;
    if (
      newDealPotentialInputRef?.current &&
      Number.isFinite(+newDealPotentialInputRef.current.value)
    ) {
      // Make sure potential is a monetary value.
      potential = +newDealPotentialInputRef.current.value;
    }

    setNewDealObj({
      ...newDealObj,
      description:
        newDealDescriptionInputRef && newDealDescriptionInputRef.current
          ? newDealDescriptionInputRef.current.value
          : newDealObj.description,
      name:
        newDealNameInputRef && newDealNameInputRef.current
          ? newDealNameInputRef.current.value
          : newDealObj.name,
      potential: potential,
    });
  };

  const _removeCarFromDealObj = (id) => {
    setNewDealObj({
      ...newDealObj,
      cars: newDealObj.cars.filter((num) => num.id !== id),
    });
  };

  const _removeFileFromDealObj = (s3_filename) => {
    setNewDealObj({
      ...newDealObj,
      files: newDealObj.files.filter((num) => num.s3_filename !== s3_filename),
    });
  };

  const _removeProspectFromDealObj = (id) => {
    setNewDealObj({
      ...newDealObj,
      prospects: newDealObj.prospects.filter((num) => num.id !== id),
    });
  };

  const _saveDeal = async () => {
    if (!selectedOption) {
      // If no option is selected, display an error message or provide feedback to the user
      showFlashMessage(tc.error, 'fail');
      return; // Exit the function early, preventing deal creation
    }
    const { prospects, cars, ...deal } = newDealObj;

    await createDeal({
      ...deal,
      car: cars?.[0]?.id || null,
      prospectId: prospects[0]?.id || null,
      prospectSource: 'newDeal',
    });
    return state.props.close(true);
  };

  const _startFileUpload = () => {
    document.querySelector('#s3Uploader').click();
  };

  const _stateCheck = () => {
    return !!(
      state &&
      state.props &&
      state.search &&
      newDealObj &&
      Object.keys(newDealObj).length
    );
  };

  const _uploadError = (message) => {
    console.error('S3 file upload error:', message);
    showFlashMessage(tc.couldNotUploadFile, 'fail');
  };

  return _stateCheck() ? (
    <Popup close={state.props.close} size="medium">
      <div className="createDealWrapper">
        <div className="createDealWrapper__createDeal">
          <div className="createDealWrapper__createDeal__header">
            <WidgetHeader
              headline={
                <>
                  <div className="createDealWrapper__createDeal__header__center">
                    <Icon className="small" val="label" />

                    <h2>
                      {tc.createNewDeal}
                      {selectedOption === 'vehicle'
                        ? ' - ' + tc.vehicle
                        : selectedOption === 'prospect'
                        ? ' - ' + tc.prospect
                        : ''}
                    </h2>
                    <Tooltip tooltipContent={tc.toCreateDeal} placement="right">
                      <div className="createDealWrapper__createDeal__header__left__icon">
                        <Icon class="" val="infoOutlined" />
                      </div>
                    </Tooltip>
                  </div>
                </>
              }
              headlineSub={state.props.headline ? state.props.headline : null}
            />
          </div>

          <div className="createDealWrapper__createDeal__content">
            <div className="createDealWrapper__createDeal__content__top">
              <div className="createDealWrapper__createDeal__content__top__item">
                <p className="label">{tc.dealName}</p>
                <input
                  onChange={_onInputChange}
                  ref={newDealNameInputRef}
                  type="text"
                  value={newDealObj.name || ''}
                />
              </div>

              <div className="createDealWrapper__createDeal__content__top__item">
                <p className="label">{tc.owner}</p>
                <ColleaguesDropdown
                  activeId={newDealObj.user_id}
                  activeIds={[]}
                  activeName={newDealObj.userName}
                  onClick={(id, name) => {
                    setNewDealObj({
                      ...newDealObj,
                      user_id: id,
                      userName: name,
                    });
                  }}
                  transparent={true}
                />
              </div>
              <div className="createDealWrapper__createDeal__content__top__item">
                <p className="label">{tc.dealPotential}</p>
                <input
                  onChange={_onInputChange}
                  ref={newDealPotentialInputRef}
                  type="text"
                  value={newDealObj.potential || ''}
                />
              </div>

              <div className="createDealWrapper__createDeal__content__top__item">
                <div className="createDealWrapper__createDeal__content__top__item__container">
                  <div className="createDealWrapper__createDeal__content__top__item__container__flex">
                    <p className="label">{tc.connectProspectsAndVehicles}</p>
                    <p className="label">
                      {tc.search}:
                      {selectedOption === 'vehicle'
                        ? tc.vehicle
                        : selectedOption === 'prospect'
                        ? tc.prospect
                        : ''}
                    </p>
                  </div>
                  <div className="createDealWrapper__createDeal__content__top__item__container">
                    {selectedOption ? (
                      <Search
                        type={selectedOption === 'vehicle' ? 'cars' : 'all'}
                        save={_addSelectedItemsToDealObj}
                        shouldRemoveCars={
                          selectedOption === 'prospect' ? true : null
                        }
                      />
                    ) : (
                      <div className="searchSelectWrapper__searchSelect__header__top">
                        <div className="searchSelectWrapper__searchSelect__header__top__input">
                          <Tooltip
                            tooltipContent="Affärstyp inte vald"
                            placement="right"
                          >
                            <input
                              className="createDealWrapper__createDeal__content__top__item__inputField__disabled"
                              type="text"
                              disabled
                            />
                          </Tooltip>
                        </div>
                        <div className="searchSelectWrapper__searchSelect__header__top__icon">
                          <Icon val="search" />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="createDealWrapper__createDeal__content__top__item">
                <p className="label">{tc.description}</p>
                <input
                  className="createDealWrapper__createDeal__content__top__item__inputField"
                  onChange={_onInputChange}
                  ref={newDealDescriptionInputRef}
                  type="text"
                  value={newDealObj.description || ''}
                />
              </div>
              <div className="createDealWrapper__createDeal__content__top__item__chipholders">
                <div className="createDealWrapper__createDeal__content__bottom__left">
                  <div className="createDealWrapper__createDeal__content__bottom__item__buttons">
                    <ActionButton
                      label={tc.vehicle}
                      className="dropdown-item"
                      onClick={() => handleOptionChange('vehicle')}
                      disabled={dealHasProspect}
                      type={
                        selectedOption === 'vehicle' ? 'highlight' : 'regular'
                      }
                    />

                    <ActionButton
                      label={tc.prospect}
                      className="dropdown-item"
                      onClick={() => handleOptionChange('prospect')}
                      disabled={dealHasCar}
                      type={
                        selectedOption === 'prospect' ? 'highlight' : 'regular'
                      }
                    />
                    <Tooltip
                      tooltipContent={tc.chooseDeal}
                      tooltipPlacement="right"
                    >
                      <Icon val="infoOutlined"></Icon>
                    </Tooltip>
                  </div>
                </div>
                <div className="createDealWrapper__createDeal__content__bottom__right">
                  <div className="createDealWrapper__createDeal__content__bottom__item">
                    <div className="createDealWrapper__createDeal__content__bottom__item__chipholder">
                      {newDealObj.prospects.length ? (
                        newDealObj.prospects.map((prospect, i) => {
                          if (
                            prospect.id.toString() ===
                            state.props?.target?.toString()
                          ) {
                            return (
                              <div
                                className="createDealWrapper__createDeal__content__bottom__item__chipholder"
                                key={prospect.id}
                              >
                                <div className="createDealWrapper__createDeal__content__bottom__item__chipholder__label">
                                  {tc.prospectMultiple}:
                                </div>
                                <div className="createDealWrapper__createDeal__content__bottom__item__chipholder__chip">
                                  {prospect.name}
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <div
                                className="createDealWrapper__createDeal__content__bottom__item__chipholder"
                                key={prospect.id}
                              >
                                <div className="createDealWrapper__createDeal__content__bottom__item__chipholder__label">
                                  {tc.prospectMultiple}:
                                </div>
                                <div className="createDealWrapper__createDeal__content__bottom__item__chipholder__chip">
                                  <p>{prospect.name}</p>

                                  <Icon
                                    val="remove"
                                    onClick={() => {
                                      _removeProspectFromDealObj(prospect.id);
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          }
                        })
                      ) : (
                        <div className="createDealWrapper__createDeal__content__bottom__item__chipholder__noItem">
                          <div className="createDealWrapper__createDeal__content__bottom__item__chipholder__label">
                            {tc.prospectMultiple}:
                          </div>
                          <div className="createDealWrapper__createDeal__content__bottom__item__chipholder__info">
                            {tc.noProspectConnections}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="createDealWrapper__createDeal__content__bottom__item">
                    <div className="createDealWrapper__createDeal__content__bottom__item__chipholder">
                      {newDealObj.cars.length ? (
                        newDealObj.cars.map((car, i) => (
                          <div
                            className="createDealWrapper__createDeal__content__bottom__item__chipholder"
                            key={car.id}
                          >
                            <div className="createDealWrapper__createDeal__content__bottom__item__chipholder__label">
                              {tc.vehicles}:
                            </div>
                            <div className="createDealWrapper__createDeal__content__bottom__item__chipholder__chip">
                              <p>{car.name}</p>
                              {car.id === state?.props?.target ? (
                                ''
                              ) : (
                                <Icon
                                  val="remove"
                                  onClick={() => {
                                    _removeCarFromDealObj(car.id);
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="createDealWrapper__createDeal__content__bottom__item__chipholder__noItem">
                          <div className="createDealWrapper__createDeal__content__bottom__item__chipholder__label">
                            {tc.vehicles}:
                          </div>
                          <div className="createDealWrapper__createDeal__content__bottom__item__chipholder__info">
                            {tc.noVehicleConnections}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="createDealWrapper__createDeal__content__bottom__item">
                    <div className="createDealWrapper__createDeal__content__bottom__item__chipholder">
                      {newDealObj.files.length ? (
                        <Dropdown
                          displayValue={tc.files}
                          closeOnItemClick={true}
                          styling={{ adjustToHeader: true }}
                          transparent={true}
                        >
                          {newDealObj.files.map((file, i) => (
                            <DropdownItem
                              key={file.s3_filename}
                              iconString="remove"
                              onClick={() => {
                                _removeFileFromDealObj(file.s3_filename);
                              }}
                              label={
                                <div className="createDealWrapper__createDeal__content__bottom__item__chipholder__dropdownItem">
                                  {file.original_name}
                                </div>
                              }
                            />
                          ))}
                        </Dropdown>
                      ) : (
                        <div className="createDealWrapper__createDeal__content__bottom__item__chipholder__noItem">
                          <div className="createDealWrapper__createDeal__content__bottom__item__chipholder__label">
                            {tc.files}:
                          </div>
                          <div className="createDealWrapper__createDeal__content__bottom__item__chipholder__info">
                            {tc.noFiles}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden">
              <ReactS3Uploader
                id="s3Uploader"
                signingUrl="/s3/sign"
                onFinish={_finishUpload}
                onError={_uploadError}
                contentDisposition="auto"
                scrubFilename={(filename) =>
                  filename.replace(/[^\w\d_\-.]+/gi, '')
                }
                uploadRequestHeaders={{ 'x-amz-acl': 'private' }}
              />
            </div>
          </div>
          <div className="createDealWrapper__createDeal__footer">
            <DragAndDropUploader
              onUpload={handleFileUpload}
              enableFileInput={true}
            />
            <WidgetFooter
              buttonOneFunc={_saveDeal}
              buttonOneText={tc.createNewDeal}
              buttonTwoFunc={() => {
                if (typeof state.props.close === 'function') {
                  state.props.close(false);
                }
              }}
              buttonTwoText={tc.cancel}
              buttonThreeFunc={_startFileUpload}
              buttonThreeText={tc.upload}
              alignTwoButtonsRight={true}
            />
          </div>
        </div>
      </div>
    </Popup>
  ) : (
    <Loading />
  );
};

const MapStateToProps = (state, props) => {
  return {
    props: props,
    search: state.search,
    user: state.user,
  };
};

export default connect(MapStateToProps)(CreateDeal);
