import React, { useContext, useEffect, useRef, useState } from "react";
import LanguageContext from "language-context";
import moment from "moment";
import ActionButton from "components/action_button";
import EventsCompleteEvent from "./events_complete_event";
import EventsRemoveEvent from "./events_remove_event";
import UserImage from "components/user_image";

/**
 * Render an event row for EventsFlow component.
 */
const EventsFlowItem = (props) => {
  const [responsiveClassWidth, setResponsiveClassWidth] = useState("");
  const [showCompleteEvent, setShowCompleteEvent] = useState(false);
  const [showRemoveEvent, setShowRemoveEvent] = useState(false);
  const eventsFlowItemRef = useRef(null);
  const observer = useRef(null);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    _setResponsiveClass();
    observer.current = new ResizeObserver(_setResponsiveClass);
    observer.current.observe(eventsFlowItemRef.current);

    const ref = eventsFlowItemRef.current;

    return () => observer.current.unobserve(ref);
  }, []);

  const _setResponsiveClass = () => {
    if (!eventsFlowItemRef?.current) {
      return;
    }

    const width = eventsFlowItemRef.current.getBoundingClientRect().width;
    const breakpoint1 = 344; // Might be a good idea to keep this consistent with ActivitiesFlowItem.

    if (width <= breakpoint1) {
      setResponsiveClassWidth("eventsFlowItemResponsiveWidth1");
    } else if (width > breakpoint1) {
      setResponsiveClassWidth("");
    }
  };

  return (
    <div
      className={`eventsFlowItemWrapper ${responsiveClassWidth}`}
      ref={eventsFlowItemRef}
    >
      <div className="eventsFlowItemWrapper__eventsFlowItem">
        <div className="eventsFlowItemWrapper__eventsFlowItem__left">
          <UserImage img={props.user?.img} name={props.user?.name} />
        </div>
        <div className="eventsFlowItemWrapper__eventsFlowItem__right">
          <div className="eventsFlowItemWrapper__eventsFlowItem__right__left">
            <div className="eventsFlowItemWrapper__eventsFlowItem__right__left__action">
              {props.action}
            </div>
            {props.comment ? (
              <div className="eventsFlowItemWrapper__eventsFlowItem__right__left__comment">
                <span className="highlight marginRight">{tc.comment}:</span>
                <span className="comment">{props.comment}</span>
              </div>
            ) : null}
            <div className="eventsFlowItemWrapper__eventsFlowItem__right__left__footer">
              {props.date ? (
                <div className="eventsFlowItemWrapper__eventsFlowItem__right__left__footer__date">
                  <span>
                    {moment(new Date(props.date), "DD/MM/YYYY").fromNow()},
                  </span>
                </div>
              ) : null}
              {props.user?.name?.length ? (
                <div className="eventsFlowItemWrapper__eventsFlowItem__right__left__footer__user">
                  {props.user.name}
                </div>
              ) : null}
            </div>
          </div>
          {props.isEditable ? (
            <div className="eventsFlowItemWrapper__eventsFlowItem__right__right">
              <div className="eventsFlowItemWrapper__eventsFlowItem__right__right__edit">
                <ActionButton
                  label={tc.completeEvent}
                  onClick={() => {
                    setShowCompleteEvent(true);
                  }}
                />
                <ActionButton
                  label={tc.remove}
                  onClick={() => {
                    setShowRemoveEvent(true);
                  }}
                />
              </div>
            </div>
          ) : null}
        </div>
        {showCompleteEvent ? (
          <EventsCompleteEvent
            action={props.actionType}
            close={() => {
              setShowCompleteEvent(false);
            }}
            eventId={props.eventId}
          />
        ) : null}
        {showRemoveEvent && (
          <EventsRemoveEvent
            action={props.actionType}
            close={() => {
              setShowRemoveEvent(false);
            }}
            dealId={props.dealId}
            eventId={props.eventId}
          />
        )}
      </div>
    </div>
  );
};

export default EventsFlowItem;
