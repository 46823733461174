import React, { useContext, useEffect } from "react";
import moment from "moment";
import LanguageContext from "language-context";
import DataVisualisation from "./data_visualisation";

const SalesView = ({
  salesFilters,
  filters,
  toDate,
  fromDate,
  orgnumber,
  selectedMeasures,
  orderBy,
  setDefaultMeasures,
  setDefaultOrderBy,
}) => {
  const tc = useContext(LanguageContext);

  useEffect(() => {
    setDefaultMeasures([]);
    setDefaultOrderBy([]);
  }, []);

  useEffect(() => {
    if (!selectedMeasures.length) {
      setDefaultMeasures(["Sale.count"]);
    }

    if (!orderBy.length) {
      setDefaultOrderBy(["Sale.count"]);
    }

    if (!selectedMeasures.some((m) => orderBy.includes(m))) {
      setDefaultOrderBy([selectedMeasures[0]]);
    }
  }, [selectedMeasures, orderBy]);

  return (
    <div className="analyseWrapper__analyse__content__sales">
      {orderBy[0] !== undefined && (
        <>
          <DataVisualisation
            query={{
              measures: selectedMeasures.length
                ? selectedMeasures
                : ["Sale.count"],
              timeDimensions: [{ dimension: "Sale.date_sold" }],
              order: { [orderBy[0]]: "desc" },
            }}
            filters={salesFilters.concat(filters)}
            title={tc.dividedBySalesDate}
            subTitle=""
            dateRange={[
              moment(fromDate).format("YYYY-MM-DD"),
              moment(toDate).format("YYYY-MM-DD"),
            ]}
            defaultGranularity={2}
            drillType="time"
            defaults={{
              fromDate: "2021-01-01",
              toDate: "2022-01-01",
            }}
            type="analyseSales"
          />

          <DataVisualisation
            query={{
              measures: selectedMeasures.length
                ? selectedMeasures
                : ["Sale.count"],
              timeDimensions: [{ dimension: "Sale.date_sold" }],
              order: { [orderBy[0]]: "desc" },
              limit: 10,
            }}
            filters={salesFilters.concat(filters)}
            subTitle=""
            title={tc.dividedBySalesDateAndVehicle}
            dateRange={[
              moment(fromDate).format("YYYY-MM-DD"),
              moment(toDate).format("YYYY-MM-DD"),
            ]}
            defaultGranularity={1}
            drillType="dimension"
            drillDownOptions={[
              { type: "bar", dimension: "Sale.type" },
              { type: "bar", dimension: "Sale.brand" },
              { type: "bar", dimension: "Sale.model" },
            ]}
            type="analyseSales"
          />

          <DataVisualisation
            query={{
              measures: selectedMeasures.length
                ? selectedMeasures
                : ["Sale.count"],
              timeDimensions: [{ dimension: "Sale.date_sold" }],
              order: { [orderBy[0]]: "desc" },
              limit: 10,
            }}
            filters={[{ member: "Sellers.org_namn", operator: "set" }]
              .concat(salesFilters)
              .concat(filters)}
            subTitle=""
            title={tc.dividedBySalesDateAndRetailer}
            dateRange={[
              moment(fromDate).format("YYYY-MM-DD"),
              moment(toDate).format("YYYY-MM-DD"),
            ]}
            defaultGranularity={0}
            drillType="dimension"
            drillDownOptions={[
              { type: "bar", dimension: "Sellers.org_namn" },
              { type: "bar", dimension: "Sellers.filial_namn" },
              { type: "bar", dimension: "Sale.type" },
              { type: "bar", dimension: "Sale.brand" },
              { type: "bar", dimension: "Sale.model" },
            ]}
            type="analyseSales"
          />
          {orgnumber && (
            <DataVisualisation
              query={{
                measures: selectedMeasures.length
                  ? selectedMeasures
                  : ["Sale.count"],
                timeDimensions: [{ dimension: "Sale.date_sold" }],
                order: { [orderBy[0]]: "desc" },
                limit: 10,
              }}
              filters={[
                {
                  member: "Sellers.user_id",
                  operator: "equals",
                  values: [orgnumber],
                },
              ]
                .concat(salesFilters)
                .concat(filters)}
              title={tc.mySales}
              dateRange={[
                moment(fromDate).format("YYYY-MM-DD"),
                moment(toDate).format("YYYY-MM-DD"),
              ]}
              defaultGranularity={0}
              drillType="dimension"
              drillDownOptions={[
                { type: "bar", dimension: "Sellers.org_namn" },
                { type: "bar", dimension: "Sale.type" },
                { type: "bar", dimension: "Sale.brand" },
                { type: "bar", dimension: "Sale.model" },
              ]}
              type="analyseSales"
            />
          )}
          <DataVisualisation
            query={{
              measures: selectedMeasures.length
                ? selectedMeasures
                : ["Sale.count"],
              timeDimensions: [{ dimension: "Sale.date_sold" }],
              order: { [orderBy[0]]: "desc" },
              limit: 10,
            }}
            filters={[{"member": "Buyers.gender", "operator": "set"}]
              .concat(salesFilters)
              .concat(filters)}
            subTitle=""
            title={tc.dividedByBuyer}
            dateRange={[
              moment(fromDate).format("YYYY-MM-DD"),
              moment(toDate).format("YYYY-MM-DD"),
            ]}
            defaultGranularity={0}
            drillType="dimension"
            drillDownOptions={[
              { type: "bar", dimension: "Buyers.gender" },
              { type: "bar", dimension: "Buyers.age" },
            ]}
            type="analyseSales"
          />
        </>
      )}
    </div>
  );
};

export default SalesView;
