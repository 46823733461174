import React, { useContext, useEffect, useRef, useState } from "react";
import LanguageContext from "language-context";
import carHelper from "shared_helpers/car_helper";
import companyHelper from "shared_helpers/company_helper";
import ActionButton from "components/action_button";
import Icon from "components/icon";
import Tooltip from "components/tooltip";
import Popup from "components/popup";
import WidgetFooter from "components/widget_footer";
import WidgetHeader from "components/widget_header";

/**
 * Render a contact item in edit mode.
 *
 * @param props.cancelEdit
 * @param props.contact
 * @param props.removeContact
 * @param props.removeEntityFromContact
 * @param props.saveChanges
 */
const ContactEditItem = (props) => {
  const [contactObj, setContactObj] = useState({});
  const [showRemoveContact, setShowRemoveContact] = useState(false);
  const [removeFromEntity, setRemoveFromEntity] = useState(null);
  const contactCommentInputRef = useRef(null);
  const contactEmailInputRefs = useRef([]);
  const contactNameInputRef = useRef(null);
  const contactPhoneInputRefs = useRef([]);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    let contact = props.contact;

    // To add empty input fields.
    if (!contact.email.length) {
      contact.email = [""];
    }

    if (!contact.tele.length) {
      contact.tele = [""];
    }

    setContactObj(props.contact);
  }, [props.contact]);

  const _onInputChange = () => {
    setContactObj({
      ...contactObj,
      comment: contactCommentInputRef.current.value,
      email: contactEmailInputRefs.current.map((emailRef) => emailRef.value),
      name: contactNameInputRef.current.value,
      tele: contactPhoneInputRefs.current.map((teleRef) => teleRef.value),
    });
  };

  const _saveChanges = () => {
    let contact = contactObj;

    if (!Array.isArray(contact.email)) {
      contact.email = [];
    }
    if (!Array.isArray(contact.tele)) {
      contact.tele = [];
    }

    // Filter out empty values.
    contact.email = contact.email.filter((email) => email && email.length);
    contact.tele = contact.tele.filter((tele) => tele && tele.length);

    props.saveChanges(contact);
  };

  return (
    <div className="contactsWrapper__contacts__content__contacts__item">
      <div className="contactsWrapper__contacts__content__contacts__item__header edit">
        <div className="contactsWrapper__contacts__content__contacts__item__header__name">
          <input
            onChange={_onInputChange}
            placeholder={tc.addName}
            ref={contactNameInputRef}
            type="text"
            value={contactObj.name ? contactObj.name : ""}
          />
        </div>
      </div>
      <div className="contactsWrapper__contacts__content__contacts__item__content">
        <div className="contactsWrapper__contacts__content__contacts__item__content__row">
          <div className="contactsWrapper__contacts__content__contacts__item__content__row__right">
            {contactObj.tele && contactObj.tele.length
              ? contactObj.tele.map((tele, i) => {
                  return (
                    <input
                      key={i}
                      onChange={_onInputChange}
                      placeholder={tc.addPhone}
                      ref={(el) => (contactPhoneInputRefs.current[i] = el)}
                      type="text"
                      value={tele}
                    />
                  );
                })
              : null}
            <div
              className="contactsWrapper__contacts__content__contacts__item__content__row__right__addField"
              onClick={() => {
                setContactObj({
                  ...contactObj,
                  tele: Array.isArray(contactObj.tele)
                    ? contactObj.tele.concat([""])
                    : [""],
                });
              }}
            >
              <Icon val="add" />
            </div>
          </div>
        </div>
        <div className="contactsWrapper__contacts__content__contacts__item__content__row">
          <div className="contactsWrapper__contacts__content__contacts__item__content__row__right">
            {contactObj.email && contactObj.email.length
              ? contactObj.email.map((email, i) => {
                  return (
                    <input
                      key={i}
                      onChange={_onInputChange}
                      placeholder={tc.addEmail}
                      ref={(el) => (contactEmailInputRefs.current[i] = el)}
                      type="text"
                      value={email}
                    />
                  );
                })
              : null}
            <div
              className="contactsWrapper__contacts__content__contacts__item__content__row__right__addField"
              onClick={() => {
                setContactObj({
                  ...contactObj,
                  email: Array.isArray(contactObj.email)
                    ? contactObj.email.concat([""])
                    : [""],
                });
              }}
            >
              <Icon val="add" />
            </div>
          </div>
        </div>
        <div className="contactsWrapper__contacts__content__contacts__item__content__row">
          <div className="contactsWrapper__contacts__content__contacts__item__content__row__right">
            <input
              onChange={_onInputChange}
              placeholder={tc.addDescription}
              ref={contactCommentInputRef}
              type="text"
              value={contactObj.comment ? contactObj.comment : ""}
            />
          </div>
        </div>
        <div className="contactsWrapper__contacts__content__contacts__item__content__row">
          <div className="contactsWrapper__contacts__content__contacts__item__content__row__right__entities">
            <span className="label">{tc.connectedTo}:</span>
            {contactObj.savedTo && contactObj.savedTo.length
              ? contactObj.savedTo.map((entity, i) => {
                  // entity can be deal, company or regnr
                  let connection;
                  if (carHelper.isValidRegNumber(entity.entityId)) {
                    connection = entity.entityName
                      ? entity.entityName
                      : entity.entityId;
                  } else if (companyHelper.isValidOrgNr(entity.entityId)) {
                    connection = entity.entityName
                      ? entity.entityName
                      : tc.company;
                  } else {
                    connection = tc.deal;
                  }
                  return (
                    <div
                      className="contactsWrapper__contacts__content__contacts__item__content__row__right__entities__entity"
                      key={i}
                    >
                      {connection}
                      <Tooltip tooltipContent={tc.removeEntityFromContact}>
                        <Icon
                          val="remove"
                          onClick={() => {
                            setRemoveFromEntity({
                              entityId: entity.entityId,
                              entityType: entity.entityType,
                            });
                          }}
                        />
                      </Tooltip>
                      {i === contactObj.savedTo.length - 1 ? "" : ", "}
                    </div>
                  );
                })
              : null}
          </div>
        </div>
        <div className="contactsWrapper__contacts__content__contacts__item__content__row">
          <div className="contactsWrapper__contacts__content__contacts__item__content__row__buttons">
            <ActionButton
              label={tc.cancel}
              onClick={() => {
                props.cancelEdit();
              }}
              type="regular"
            />
            <ActionButton
              label={tc.removeContact}
              onClick={() => {
                setShowRemoveContact(true);
              }}
              type="regular"
            />
            <ActionButton
              label={tc.save}
              onClick={_saveChanges}
              type="highlight"
            />
          </div>
        </div>
        {showRemoveContact ? (
          <Popup
            close={() => {
              setShowRemoveContact(false);
            }}
            size="small"
          >
            <div className="genericPopupContentWrapper">
              <div className="genericPopupContentWrapper__genericPopupContent">
                <div className="genericPopupContentWrapper__genericPopupContent__header">
                  <WidgetHeader headline={tc.removeContact} />
                </div>
                <div className="genericPopupContentWrapper__genericPopupContent__content">
                  <p>{tc.ensure}</p>
                </div>
                <div className="genericPopupContentWrapper__genericPopupContent__footer">
                  <WidgetFooter
                    buttonOneFunc={() => {
                      props.removeContact(props.contact.id);
                      setShowRemoveContact(false);
                    }}
                    buttonOneText={tc.remove}
                    buttonTwoFunc={() => {
                      setShowRemoveContact(false);
                    }}
                    buttonTwoText={tc.cancel}
                  />
                </div>
              </div>
            </div>
          </Popup>
        ) : null}
        {removeFromEntity ? (
          <Popup
            close={() => {
              setRemoveFromEntity(false);
            }}
            size="small"
          >
            <div className="genericPopupContentWrapper">
              <div className="genericPopupContentWrapper__genericPopupContent">
                <div className="genericPopupContentWrapper__genericPopupContent__header">
                  <WidgetHeader headline={tc.removeEntityFromContact} />
                </div>
                <div className="genericPopupContentWrapper__genericPopupContent__content">
                  <p>{tc.ensure}</p>
                </div>
                <div className="genericPopupContentWrapper__genericPopupContent__footer">
                  <WidgetFooter
                    buttonOneFunc={() => {
                      props.removeEntityFromContact({
                        id: props.contact.id,
                        ...removeFromEntity,
                      });
                      setRemoveFromEntity(null);
                    }}
                    buttonOneText={tc.remove}
                    buttonTwoFunc={() => {
                      setRemoveFromEntity(false);
                    }}
                    buttonTwoText={tc.cancel}
                  />
                </div>
              </div>
            </div>
          </Popup>
        ) : null}
      </div>
    </div>
  );
};

export default ContactEditItem;
