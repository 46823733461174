import { excelActionTypes } from "./actions";

interface excelState {
  downloadUrl: string | null;
  rowsOrderedName: number | null;
  rowsOrderedManagement: number | null;
  rowPriceProspect: number | null;
  rowPriceVehicle: number | null;
  rowsProspects: number | null;
  rowsVehicles: number | null;
  totalCost: number | null;
}

const initialState: excelState = {
  downloadUrl: null,
  rowsOrderedName: null,
  rowsOrderedManagement: null,
  rowPriceProspect: null,
  rowPriceVehicle: null,
  rowsProspects: null,
  rowsVehicles: null,
  totalCost: null,
};

export const excelReducer = (state = initialState, action) => {
  switch (action.type) {
    case excelActionTypes.SET_EXCEL_DOWNLOAD_URL: {
      return {
        ...state,
        downloadUrl: action.payload,
      };
    }
    case excelActionTypes.SET_EXCEL_ROWS_ORDERED_MANAGEMENT: {
      return {
        ...state,
        rowsOrderedManagement: action.payload,
      };
    }
    case excelActionTypes.SET_EXCEL_ROWS_ORDERED_NAME: {
      return {
        ...state,
        rowsOrderedName: action.payload,
      };
    }
    case excelActionTypes.SET_EXCEL_ROWPRICE_PROSPECT: {
      return {
        ...state,
        rowPriceProspect: action.payload,
      };
    }
    case excelActionTypes.SET_EXCEL_ROWPRICE_VEHICLE: {
      return {
        ...state,
        rowPriceVehicle: action.payload,
      };
    }
    case excelActionTypes.SET_EXCEL_ROWS_PROSPECTS: {
      return {
        ...state,
        rowsProspects: action.payload,
      };
    }
    case excelActionTypes.SET_EXCEL_ROWS_VEHICLES: {
      return {
        ...state,
        rowsVehicles: action.payload,
      };
    }
    case excelActionTypes.SET_EXCEL_TOTALCOST: {
      return {
        ...state,
        totalCost: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
