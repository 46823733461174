import { ordersActionTypes } from "./actions";

interface OrdersState {
  activeOrderStage: "start" | "selectList" | "listOrder";
  activeOrderType: "management" | "mailings" | "name" | "phone";
  list: object | null;
  mailingsInput: {
    name: string;
    pdf: string;
    s3Key: string;
    s3SignedUrl: string;
  };
  mailingsType: "user" | "car";
  mailingsIsReady: boolean; // Used when PDF file is generated and previewed.
  mailingsPostage: {
    color: boolean;
    postage: "A" | "B";
    simplexDuplex: "D" | "S";
    type: "envelope" | "postcard";
  };
  mailingsPdfPreview: {
    blob: any;
    pages: 0;
  };
  mailingsPrice: number;
}

const initialState: OrdersState = {
  activeOrderStage: "selectList",
  activeOrderType: "name",
  list: null,
  mailingsInput: {
    name: "",
    pdf: "",
    s3Key: "",
    s3SignedUrl: "",
  },
  mailingsType: "user",
  mailingsIsReady: false,
  mailingsPostage: {
    color: true,
    postage: "A",
    simplexDuplex: "D",
    type: "envelope",
  },
  mailingsPdfPreview: {
    blob: null,
    pages: 0,
  },
  mailingsPrice: 0,
};

export const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case ordersActionTypes.ORDERS_RESET_MAILINGS: {
      return {
        ...state,
        mailingsInput: initialState.mailingsInput,
        mailingsIsReady: initialState.mailingsIsReady,
        mailingsPostage: initialState.mailingsPostage,
        mailingsPdfPreview: initialState.mailingsPdfPreview,
        mailingsType: initialState.mailingsType,
      };
    }
    case ordersActionTypes.ORDERS_SET_ACTIVE_ORDER_STAGE: {
      return {
        ...state,
        activeOrderStage: action.payload,
      };
    }
    case ordersActionTypes.ORDERS_SET_ACTIVE_ORDER_TYPE: {
      return {
        ...state,
        activeOrderType: action.payload,
      };
    }
    case ordersActionTypes.ORDERS_SET_LIST: {
      return {
        ...state,
        list: action.payload,
      };
    }
    case ordersActionTypes.ORDERS_SET_MAILINGS_INPUT: {
      return {
        ...state,
        mailingsInput: action.payload,
      };
    }
    case ordersActionTypes.ORDERS_SET_MAILINGS_IS_READY: {
      return {
        ...state,
        mailingsIsReady: action.payload,
      };
    }
    case ordersActionTypes.ORDERS_SET_MAILINGS_POSTAGE: {
      return {
        ...state,
        mailingsPostage: action.payload,
      };
    }
    case ordersActionTypes.ORDERS_SET_MAILINGS_TYPE: {
      return { ...state, mailingsType: action.payload };
    }
    case ordersActionTypes.ORDERS_SET_MAILINGS_PDF_PREVIEW: {
      switch (action.payload.type) {
        case "blob":
          return {
            ...state,
            mailingsPdfPreview: {
              ...state.mailingsPdfPreview,
              blob: action.payload.val,
            },
          };
        case "pages":
          return {
            ...state,
            mailingsPdfPreview: {
              ...state.mailingsPdfPreview,
              pages: action.payload.val,
            },
          };
        default:
          return state;
      }
    }
    case ordersActionTypes.ORDERS_SET_MAILINGS_PRICE: {
      return {
        ...state,
        mailingsPrice: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
