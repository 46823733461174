import React, { useState, useContext } from 'react';
import LanguageContext from 'language-context';
import WidgetHeader from 'components/widget_header';
import WidgetFooter from 'components/widget_footer';
import Popup from 'components/popup';
import ToggleOption from 'components/toggle_option';

const SaveRegionPresetPopup = ({ close, save }) => {
  const tc = useContext(LanguageContext);

  const [name, setName] = useState('');
  const [share, setShare] = useState(false);

  return (
    <Popup>
      <div className="">
        <WidgetHeader headline={tc.groupsRegionSaveArea} />
        <input
          type="text"
          placeholder={tc.groupsRegionName}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <ToggleOption
          active={share}
          key={'shareRegionPreset'}
          label={tc.groupsRegionShare}
          onClick={() => setShare(!share)}
        />
        <WidgetFooter
          buttonOneFunc={() => {
            if (name.length > 1) {
              save({ name, shared: share });
              close();
            }
          }}
          buttonOneText={tc.save}
          buttonTwoFunc={() => close()}
          buttonTwoText={tc.cancel}
          disableButtonOne={name.length < 1}
        />
      </div>
    </Popup>
  );
};

export default SaveRegionPresetPopup;
