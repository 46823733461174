import {commentActionTypes} from './actions';

interface CommentState {
    editId: string | null,
    new: {
        added: string | null,
        comment: string | null,
        dealer_id: number | null,
        id: number | null,
        target: string | null,
        user_id: number | null,
    },
    removeId: string | null,
}

const initialState: CommentState = {
    editId: null,
    new: {
        added: null,
        comment: null,
        dealer_id: null,
        id: null,
        target: null,
        user_id: null,
    },
    removeId: null,
};

export const commentReducer = (state = initialState, action) => {
    switch(action.type) {
        case commentActionTypes.COMMENT_SET_COMMENT: {
            return {
                ...state,
                new: {
                    ...state.new,
                    added: action.payload.added,
                    comment: action.payload.comment,
                    dealer_id: action.payload.dealer_id,
                    id: action.payload.id,
                    target: action.payload.target,
                    user: action.payload.user_id,
                }
            }
        }
        case commentActionTypes.COMMENT_SET_COMMENT_COMMENT: {
            return {
                ...state,
                new: {
                    ...state.new,
                    comment: action.payload,
                }
            }
        }
        case commentActionTypes.COMMENT_SET_EDIT_ID: {
            return {
                ...state,
                editId: action.payload,
            }
        }
        case commentActionTypes.COMMENT_SET_REMOVE_ID: {
            return {
                ...state,
                removeId: action.payload,
            }
        }
        default: {
            return state;
        }
    }
};
