import { store } from 'store';
import { request } from 'helpers';
import personHelper from 'shared_helpers/person_helper';
import { dealActionTypes } from './actions';
import { showFlashMessage } from 'store/flash_messages/tasks';
import companyHelper from 'shared_helpers/company_helper';
import _ from 'underscore';
import { event } from 'tracking';
import * as text from 'text-content';

/**
 * Retrieve one deal.
 *
 * @param payload
 * @param payload.id - string
 * @param prospectInfo - bool - Mostly used after a deal update. Use this flag when we already have a deal in state and want to retrieve changes, but prospects hasn't changed so no new prospectInfo is needed.
 */
export const getDeal = async (payload, prospectInfo = true) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (!payload || !payload?.id) {
      return console.error('Missing params in getDeal', payload);
    }

    // Get deal info.
    const deal = await request({
      method: 'get',
      url: '/deals/' + payload.id,
    });

    if (!deal || deal instanceof Error) {
      showFlashMessage(tc.couldNotGetDeal, 'fail');
      console.error('No data in getDeal');
      return store.dispatch({ type: dealActionTypes.SET_DEAL, payload: {} });
    }

    if (deal && !deal.name) {
      deal.name = tc.nameMissing;
    }

    // Set deal, before getting complementary information.
    store.dispatch({ type: dealActionTypes.SET_DEAL, payload: deal });

    // Get list name.
    if (deal.moved_from_list) {
      const list = await request({
        method: 'get',
        url: '/lists/getList/' + deal.moved_from_list,
      });
      const listName = list && !(list instanceof Error) ? list.name : '';
      store.dispatch({
        type: dealActionTypes.SET_LIST_NAME,
        payload: listName,
      });
    } else {
      store.dispatch({ type: dealActionTypes.SET_LIST_NAME, payload: null });
    }

    // Get prospects info.
    if (prospectInfo && deal.prospect_id) {
      const prospectInfo = await getProspectInfo({ ids: [deal.prospect_id] });
      store.dispatch({
        type: dealActionTypes.SET_PROSPECT_INFO,
        payload: prospectInfo,
      });
    }

    return;
  } catch (err) {
    return console.error('Error in getDeal:', err);
  }
};

/**
 * Get prospect data for a list of ids, both persons and companies.
 * Returns a list with objects mapped for component rendering.
 *
 * @param payload.ids - array
 */
export const getProspectInfo = async (payload) => {
  try {
    if (
      !payload ||
      (payload && !payload.ids) ||
      (payload && payload.ids && payload.ids.length === 0)
    ) {
      return [];
    }

    const prospectPromises = await payload.ids.map(async (id) => {
      if (companyHelper.isValidOrgNr(id)) {
        return await request({
          method: 'get',
          url: '/company/companyBasicInfo/' + id,
        });
      } else {
        return await request({
          method: 'get',
          url: '/privatePerson/' + id,
        });
      }
    });

    const data: any = await Promise.all(prospectPromises);

    if (!data || data instanceof Error) {
      console.error('Error in getProspectInfo', data);
      return [];
    }

    let prospectInfo = data.map((num: any) => {
      if (num && num.person && num.person.length) {
        const person = num.person[0].person;
        let name;
        if (!person.name || person.name === '') {
          name = personHelper.buildPersonDefaultName(
            person.gender,
            person.birthYear
          );
        } else {
          name = person.name;
        }

        return {
          address: person.address ? person.address : '',
          gender: person.gender ? person.gender : '',
          id: num.person[0].id ? num.person[0].id : '',
          name: name,
          type: 'person',
          zip: person.zip ? person.zip : '',
          zipMuncipality: person.zipMuncipality ? person.zipMuncipality : '',
        };
      } else if (num) {
        return {
          address: num.address ? num.address : '',
          id: num.user_id ? num.user_id : '',
          name: num.name ? num.name : '',
          parentCompany: num.parentCompanyId ? num.parentCompanyId : '',
          type: 'company',
          zip: num.zip ? num.zip : '',
          zipMuncipality: num.zipMuncipality ? num.zipMuncipality : '',
        };
      } else {
        return null;
      }
    });

    prospectInfo = prospectInfo.filter((num) => !!num);

    prospectInfo = prospectInfo.sort((a: any, b: any) => {
      if (a.name < b.name) {
        return -1;
      } else if (a.name > b.name) {
        return 1;
      } else {
        return 0;
      }
    });

    return prospectInfo;
  } catch (err) {
    console.error('Error in getProspectInfo', err);
    return [];
  }
};

/**
 * Restore a deal.
 *
 * @param payload.id - string - Deal id.
 */
export const restoreDeal = async (payload) => {
  try {
    if (!payload || !payload?.id) {
      return console.error('Missing params in restoreDeal', payload);
    }

    event({
      category: 'deal',
      action: 'deal_restore_deal',
    });

    let data = await request({
      data: {
        id: payload.id,
      },
      method: 'put',
      url: '/deals/restoreDeal',
    });

    if (data instanceof Error) {
      return console.error('Error in restoreDeal', data);
    }

    return true;
  } catch (err) {
    return console.error('Error in restoreDeal', err);
  }
};

/**
 * Update a deal.
 *
 * All properties optional.
 * (We do not include deal.comments - as far as I can tell it's a deprecated property. Same with deal.contacts.)
 *
 * @param payload.cars - array
 * @param payload.description - string
 * @param payload.files - array
 * @param payload.id - string - If not provided, we update the deal in state.
 * @param payload.name - string
 * @param payload.potential - number - This field has historically been a string, we've changed this to represent a monetary value.
 * @param payload.prospects - array
 * @param payload.user_id - number
 */
export const updateDeal = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (!payload) {
      return console.error('Missing params in updateDeal', payload);
    }

    event({
      category: 'deal',
      action: 'deal_update_deal',
    });

    const params: any = {};
    let promises = [];

    // Cars
    if (payload.hasOwnProperty('cars')) {
      params.cars = payload.cars;
      if (params.cars.length) {
        params.cars = params.cars.sort((a: string, b: string) => {
          if (a < b) {
            return -1;
          } else if (a > b) {
            return 1;
          } else {
            return 0;
          }
        });
        params.cars = _.uniq(params.cars, true);
      }
    }

    // Files.
    if (payload.hasOwnProperty('files')) {
      params.files = payload.files;
    }

    // Description.
    if (payload.hasOwnProperty('description')) {
      params.description = payload.description;
    }

    // Name.
    if (payload.hasOwnProperty('name')) {
      params.name = payload.name;
    }

    // Potential.
    if (payload.hasOwnProperty('potential')) {
      params.potential = +payload.potential;
    }

    // Prospects.
    if (payload.hasOwnProperty('prospects')) {
      params.prospects = payload.prospects;
      if (params.prospects.length) {
        params.prospects = params.prospects.map((num) => num.toString());
        params.prospects = _.uniq(params.prospects);
      }
    }

    // When no deal id is provided we update the deal that is set in state.
    const dealInScope = payload.id ? null : store.getState().deal.deal;

    // Deal owner.
    if (
      dealInScope &&
      payload.hasOwnProperty('user_id') &&
      payload.user_id !== '' &&
      Number(dealInScope.user_id) !== Number(payload.user_id)
    ) {
      // Adjust updated deal obj.
      params.prev_user_id = Number(dealInScope.user_id);
      params.user_id = Number(payload.user_id);

      // When deal owner changes, we need to create a deal action.
      promises = promises.concat(
        await request({
          data: {
            action: 'owner',
            dealId: dealInScope._id,
            prevUserId: dealInScope.user_id,
            user_id: payload.user_id,
            moved: true,
          },
          method: 'post',
          url: '/deals/actions',
        })
      );
    }

    // Add promise for actual deal update.
    promises = promises.concat(
      await request({
        data: {
          id: payload.id ? payload.id : dealInScope._id,
          properties: params,
        },
        method: 'put',
        url: '/deals',
      })
    );

    // All set, lets resolve promises.
    const data = await Promise.all(promises);

    if (!data) {
      return console.error('Error in updateDeal, promise chain fail.');
    }

    if (dealInScope) {
      const prospectInfo = payload.hasOwnProperty('prospects');
      await getDeal({ id: dealInScope._id }, prospectInfo);
      return showFlashMessage(tc.dealWasUpdated, 'success');
    } else {
      return showFlashMessage(tc.dealWasUpdated, 'success');
    }
  } catch (err) {
    return console.error('Error in updateDeal', err);
  }
};
