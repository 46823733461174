import { companyActionTypes } from "./actions";

interface CompanyState {
  company: object;
  deals: Array<object> | null;
  fleet: object | null;
  koncern: object | null;
  koncernDeals: Array<object> | null;
  responsible: object | null;
  supertemp: Array<object> | null;
  emails: Array<string> | null;
  phoneNumbers: Array<string> | null;
  info: Array<any> | null;
  companyInLists: Array<any> | null;
  contracts: object;
  dealers: Array<object> | null;
}

const initialState: CompanyState = {
  company: {},
  deals: null,
  fleet: null,
  koncern: null,
  koncernDeals: null,
  responsible: null,
  supertemp: null,
  emails: null,
  phoneNumbers: null,
  info: null,
  companyInLists: null,
  contracts: {},
  dealers: null
};

export const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case companyActionTypes.SET_COMPANY: {
      return {
        ...state,
        company: action.payload.company,
        deals: action.payload.deals,
        fleet: action.payload.fleet,
        koncern: action.payload.koncern,
        koncernDeals: action.payload.koncernDeals,
        supertemp: action.payload.supertemp,
      };
    }
    case companyActionTypes.SET_COMPANY_INFO: {
      return {
        ...state,
        info: action.payload,
      };
    }
    case companyActionTypes.SET_COMPANY_KONCERN: {
      return {
        ...state,
        koncern: action.payload,
      };
    }
    case companyActionTypes.SET_COMPANY_EMAILS: {
      return {
        ...state,
        emails: action.payload,
      };
    }
    case companyActionTypes.SET_COMPANY_PHONENUMBERS: {
      return {
        ...state,
        phoneNumbers: action.payload,
      };
    }
    case companyActionTypes.SET_COMPANY_RESPONSIBLE: {
      return {
        ...state,
        responsible: action.payload,
      };
    }
    case companyActionTypes.SET_COMPANY_DEALS: {
      return {
        ...state,
        deals: action.payload,
      };
    }
    case companyActionTypes.SET_COMPANY_IN_LISTS: {
      return {
        ...state,
        companyInLists: action.payload,
      };
    }
    case companyActionTypes.SET_COMPANY_CONTRACTS: {
      return { ...state, contracts: action.payload };
    }
    default: {
      return state;
    }
  }
};
