import { widgetsActionTypes } from "./actions";

interface WidgetsState {
  data: object | null;
}

const initialState: WidgetsState = {
  data: null,
};

export const widgetsReducer = (state = initialState, action) => {
  switch (action.type) {
    case widgetsActionTypes.SET_WIDGETS_DATA: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case widgetsActionTypes.SET_LAYOUTS: {
      return{
        ...state,
        data: {
          ...state.data,
          gridViews: action.payload.layouts,
        }
      }
    }
    case widgetsActionTypes.SET_WIDGETS: {
      return{
        ...state,
        data: {
          ...state.data,
          widgets: action.payload.widgets,
        }
      }
    }
    default: {
      return state;
    }
  }
};



// return store.dispatch({
//   type: widgetsActionTypes.SET_LAYOUTS,
//   payload: {layouts, configuration}
// });