import React, { useContext } from "react";
import LanguageContext from "language-context";
import MailingsParams from "components/mailings_params";
import PdfArchiveManageFiles from "components/pdf_archive_manage_files/pdf_archive_manage_files";
import ActionButton from "../action_button";

/**
 * Settings orders section.
 */
const SettingsOrders = () => {
  const tc = useContext(LanguageContext);

  return (
    <div className="settingsPageWrapper">
      <div className="settingsPageWrapper__settingsPage">
        <div
          className="settingsPageWrapper__settingsPage__section"
          id="pdfArchive"
        >
          <div className="settingsPageWrapper__settingsPage__section__header">
            {tc.pdfArchive}
          </div>
          <div className="settingsPageWrapper__settingsPage__section__content">
            <div className="settingsPageWrapper__settingsPage__section__content__pdfArchiveWrapper">
              <div className="settingsPageWrapper__settingsPage__section__content__pdfArchiveWrapper__pdfArchive">
                <div className="settingsPageWrapper__settingsPage__section__content__pdfArchiveWrapper__pdfArchive__content">
                  <div className="settingsPageWrapper__settingsPage__section__content__pdfArchiveWrapper__pdfArchive__content__info">
                    <div className="settingsPageWrapper__settingsPage__section__content__pdfArchiveWrapper__pdfArchive__content__info__left">
                      <p>{tc.pdfArchiveInstruction1}</p>
                      <p>{tc.pdfArchiveInstruction2}</p>
                      <p>{tc.orderMailingsInstructionsInfo3}</p>
                      <div className="flexHolder marginTopMedium">
                        <ActionButton
                          icon="download"
                          label={`${tc.template} ${tc.envelope.toLowerCase()}`}
                          href="/files/brevutskick/blank_envelope.pdf"
                          type="regular"
                        />
                        <ActionButton
                          icon="download"
                          label={`${tc.template} ${tc.postcard.toLowerCase()}`}
                          href="/files/brevutskick/blank_postcard.pdf"
                          type="regular"
                        />
                      </div>
                    </div>
                    <div className="settingsPageWrapper__settingsPage__section__content__pdfArchiveWrapper__pdfArchive__content__info__right">
                      <MailingsParams />
                    </div>
                  </div>
                  <div className="settingsPageWrapper__settingsPage__section__content__pdfArchiveWrapper__pdfArchive__content__manageFiles">
                    <PdfArchiveManageFiles />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsOrders;
