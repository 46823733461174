import { request } from 'helpers';
import { store } from 'store';
import { event } from 'tracking';
import { scriptsActionTypes } from './actions';
import { showFlashMessage } from '../flash_messages/tasks';

export const ScriptManager = {
  getScripts: async () => {
    try {
      const scripts = await request({
        method: 'get',
        url: '/scripts/',
      });

      if (!scripts || scripts instanceof Error) {
        return console.error('Could not get data in getScripts: ', scripts);
      }

      return store.dispatch({
        type: scriptsActionTypes.SET_SCRIPTS_DATA,
        payload: scripts,
      });
    } catch (e) {
      console.error('Error in getScripts: ', e);
    }
  },

  createScript: async (payload: {
    script: { title: string; text: string };
  }): Promise<{
    title: string;
    text: string;
    id: number;
    user_id: number;
  } | void> => {
    try {
      if (!payload || !payload.script) {
        return console.error('Missing params in createScript');
      }

      event({ category: 'scripts', action: 'scripts_create_script' });

      const { script } = payload;
      const newScript = await request({
        data: {
          title: script.title,
          text: script.text,
        },
        method: 'post',
        url: '/scripts/',
      });

      if (!newScript || newScript instanceof Error) {
        return console.error(
          'Could not create item in createScripts',
          newScript
        );
      }

      return newScript;
    } catch (e) {
      return console.error('Error in createScript: ', e);
    }
  },

  setScriptsLocal: (
    scripts: Array<{ text: string; title: string; id: number }>
  ) => {
    if (!scripts || !Array.isArray(scripts)) {
      return console.error(
        'Could not set scripts locally - scripts: ',
        scripts
      );
    }

    store.dispatch({
      type: scriptsActionTypes.SET_SCRIPTS_DATA,
      payload: scripts,
    });
  },

  updateScript: async (payload: {
    id: string | undefined;
    title: string;
    text: string;
  }) => {
    try {
      if (!payload || !payload.text || !payload.title) {
        showFlashMessage(
          'Något gick fel med att uppdatera eller skapa manus.',
          'fail'
        );
        return console.error('Missing params in updateScripts: ', payload);
      }
      event({ category: 'scripts', action: 'scripts_update_script' });

      const scripts = await request({
        data: { id: payload.id, title: payload.title, text: payload.text },
        method: 'put',
        url: '/scripts/',
      });
    } catch (e) {
      console.error('Something went wrong updating script: ', payload);
      showFlashMessage('Något gick fel med att uppdatera ditt manus.');
    }
  },

  deleteScript: async (scriptId: number) => {
    try {
      if (!scriptId) {
        showFlashMessage('Något gick fel med att ta bort manuset.');
        return console.error('Missing params in deleteScript: ', scriptId);
      }

      event({ category: 'scripts', action: 'scripts_delete_script' });
      await request({
        data: {
          scriptId,
        },
        method: 'delete',
        url: '/scripts',
      });
    } catch (e) {
      console.error('Something went wrong deleting script: ', e);
      showFlashMessage(
        'Något gick fel med att ta bort manuset, var god försök igen eller kontakta support.',
        'fail'
      );
    }
  },
};
