import React, { useEffect, useState } from "react";
import Icon from "components/icon";

/**
 * Just a box that holds information that is collapsible.
 *
 * @param props.content - element | string - Content.
 * @param props.header - string - Header.
 * @param props.minimized - bool (optional) - When starting off in minimized mode.
 */
const Collapsible = (props) => {
  const [minimize, setMinimize] = useState(true);

  useEffect(() => {
    setMinimize(!!props.minimized);
  }, [props.minimized]);

  return (
    <div className="collapsibleWrapper">
      <div className="collapsibleWrapper__collapsible">
        <div
          className={
            minimize
              ? "collapsibleWrapper__collapsible__header minimize"
              : "collapsibleWrapper__collapsible__header"
          }
          onClick={() => {
            setMinimize(!minimize);
          }}
        >
          <div className="collapsibleWrapper__collapsible__header__left">
            {props.header}
          </div>
          <div className="collapsibleWrapper__collapsible__header__right">
            <Icon val={minimize ? "maximize" : "minimize"} />
          </div>
        </div>
        <div
          className={
            minimize
              ? "collapsibleWrapper__collapsible__content minimize"
              : "collapsibleWrapper__collapsible__content"
          }
        >
          <div className="collapsibleWrapper__collapsible__content__paddingHolder">
            {props.content}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Collapsible;
