import React from 'react';
import { connect } from 'react-redux';
import {
  Dropdown,
  DropdownItem,
  DropdownItemDelimiter,
} from 'components/dropdown';
import Loading from 'components/loading';

/**
 * Render a dropdown list of user colleagues. Can be used when setting owner of a deal, company etc.
 *
 * @param state.props.activeId - number - User id that is currently active.
 * @param state.props.activeIds - array - User ids that is currently active.
 * @param state.props.activeName - string - Name that should be displayed at top.
 * @param state.props.activeNames - array - Names that should be displayed at top.
 * @param state.props.transparent - bool (optional) - If we want top row transparent.
 * @param state.props.onClick - func - When choosing a colleague, always sends back id and name as params.
 * @param state.props.excludeConnections - bool - Exlude connected dealers, allow only colleagues
 */
const ColleaguesDropdown = (state) => {
  const _renderColleagueList = () => {
    let colleagues = [];

    if (state.user?.connections?.length && !state.props.excludeConnections) {
      // Render colleagues with dealer name delimiter, first the users own dealer...
      colleagues = [
        <DropdownItemDelimiter
          key={state.user.info.dealerName}
          label={state.user.info.dealerName}
        />,
      ];
      colleagues = colleagues.concat(
        state.user.colleagues.map((user, i) => {
          return (
            <DropdownItem
              active={
                state.props.activeId
                  ? state.props.activeId && state.props.activeId === user.id
                  : state.props.activeIds &&
                    state.props.activeIds.includes(user.id)
              }
              key={user.id}
              label={user.name}
              onClick={() => {
                state.props.onClick(user.id, user.name, user.dealer_id);
              }}
            />
          );
        })
      );

      // ...then the connections.
      colleagues = colleagues.concat(
        state.user.connections.map((dealer, i) => {
          const items = [];
          items.push(
            <DropdownItemDelimiter key={dealer.name} label={dealer.name} />
          );
          dealer.users.forEach((user) => {
            items.push(
              <DropdownItem
                active={
                  state.props.activeId
                    ? state.props.activeId && state.props.activeId === user.id
                    : state.props.activeIds &&
                      state.props.activeIds.includes(user.id)
                }
                key={user.id}
                label={user.name}
                onClick={() => {
                  state.props.onClick(user.id, user.name, user.dealer_id);
                }}
              />
            );
          });
          return items;
        })
      );
    } else {
      colleagues = state.user.colleagues.map((user, i) => {
        // Render colleagues without delimiter.
        return (
          <DropdownItem
            active={
              state.props.activeId
                ? state.props.activeId && state.props.activeId === user.id
                : state.props.activeIds &&
                  state.props.activeIds.includes(user.id)
            }
            key={user.id}
            label={user.name}
            onClick={() => {
              state.props.onClick(user.id, user.name, user.dealer_id);
            }}
          />
        );
      });
    }

    return (
      <Dropdown
        displayValue={
          state.props.activeNames
            ? state.props.activeNames.join(', ')
            : state.props.activeName
        }
        transparent={state.props.transparent}
        styling={{ adjustToHeader: true }}
      >
        {colleagues}
      </Dropdown>
    );
  };

  const _stateCheck = () => {
    return !!state?.user?.info;
  };

  return _stateCheck() ? _renderColleagueList() : <Loading />;
};

const MapStateToProps = (state, props) => {
  return {
    props: props,
    user: state.user,
  };
};

export default connect(MapStateToProps)(ColleaguesDropdown);
