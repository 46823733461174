import React, { useContext, useEffect, useRef, useState } from 'react';
import LanguageContext from 'language-context';
import { getHistoricFleetNew } from 'store/fleet/tasks';
import { Table } from 'components/table';
import Info from 'components/info';
import WidgetHeader from 'components/widget_header';

/**
 * Render a historic fleet table.
 *
 * @param id { number } - prospect_id
 */
const FleetWidgetHistoric = ({ id, isMinimized, isCompanyPage }) => {
  const [fleet, setFleet] = useState([]);
  const [columnLinks, setColumnLinks] = useState(null);
  const [minimize, setMinimize] = useState(false);

  const isFetching = useRef(false);

  const tc = useContext(LanguageContext);
  useEffect(() => {
    setMinimize(isMinimized);
  }, [isMinimized]);

  useEffect(() => {
    const getFleet = async () => {
      isFetching.current = true;
      const fleet = await getHistoricFleetNew(id);

      const links = fleet.map((row) => ({
        id: row.reg_number,
        reg_number: {
          url: `/fordon/${row.reg_number}`,
        },
        user_name: {
          url:
            String(row.user_id).length >= 10
              ? `/foretag/${row.user_id}`
              : row.user_id
              ? `/person/${row.user_id}`
              : null,
        },
        bought_from_name: {
          url:
            String(row.bought_from_id).length >= 10
              ? `/foretag/${row.bought_from_id}`
              : row.bought_from_id
              ? `/person/${row.bought_from_id}`
              : null,
        },
        sold_to_name: {
          url:
            String(row.sold_to_id).length >= 10
              ? `/foretag/${row.sold_to_id}`
              : row.sold_to_id
              ? `/person/${row.sold_to_id}`
              : null,
        },
      }));

      setColumnLinks(links);

      setFleet(fleet);

      isFetching.current = false;
    };

    if (id && !isFetching.current) {
      getFleet(id);
    }
  }, [id]);

  const columns = [
    {
      id: 'reg_number',
      visible: true,
      numeric: false,
      label: tc.regNumber,
    },
    {
      id: 'brand',
      visible: true,
      numeric: false,
      label: tc.brand,
    },
    {
      id: 'model',
      visible: true,
      numeric: false,
      label: tc.model,
    },
    {
      id: 'user_period',
      visible: true,
      numeric: false,
      label: tc.possessionTime,
    },
    {
      id: 'date_acquired',
      visible: true,
      numeric: false,
      label: tc.dateAcquired,
    },
    {
      id: 'date_sold',
      visible: true,
      numeric: false,
      label: tc.dateSold,
    },
    {
      id: 'bought_from_name',
      visible: true,
      numeric: false,
      label: tc.sellerName,
    },
    {
      id: 'sold_to_name',
      visible: true,
      numeric: false,
      label: `${tc.sold} ${tc.to.toLowerCase()}`,
    },
  ];

  return (
    <div
      className="fleetWidgetWrapper"
      style={{
        width: isCompanyPage ? '100%' : 'auto',
      }}
    >
      <div className="fleetWidgetWrapper__fleetWidget">
        <div className="fleetWidgetWrapper__fleetWidget__header">
          <WidgetHeader
            dashboardItems={
              isCompanyPage
                ? null
                : [
                    {
                      disabled: !minimize,
                      icon: 'maximize',
                      label: tc.maximize,
                      onClick: () => {
                        setMinimize(false);
                      },
                    },
                    {
                      disabled: minimize,
                      icon: 'minimize',
                      label: tc.minimize,
                      onClick: () => {
                        setMinimize(true);
                      },
                    },
                  ]
            }
            headline={tc.fleetHistoric}
            headlineSub="Visar upp till 20 senaste fordon"
          />
        </div>
        <div
          className={
            minimize
              ? 'fleetWidgetWrapper__fleetWidget__content minimize'
              : 'fleetWidgetWrapper__fleetWidget__content'
          }
        >
          <>
            {fleet.length && columnLinks ? (
              <Table
                columns={columns}
                rows={fleet}
                total={fleet.length}
                columnLinks={columnLinks}
              />
            ) : (
              <Info>
                <h4>{tc.noFleet}</h4>
                {fleet.query ? (
                  <p>{`${tc.showing} 0 ${tc.of.toLowerCase()} ${
                    fleet.amount
                  }. ${
                    tc.toReset
                  } ${tc.clickOn.toLowerCase()} ${tc.reload.toLowerCase()}.`}</p>
                ) : (
                  <p>{tc.noFleetWhy}</p>
                )}
              </Info>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default FleetWidgetHistoric;
