import React, { useContext } from 'react';
import LanguageContext from 'language-context';
import HomeForm from './home_form';

const HomeContact = () => {
  const tc = useContext(LanguageContext);

  return (
    <div className="homeContactWrapper">
      <div className="homeContactWrapper__homeContact">
        <div className="homeContactWrapper__homeContact__left">
          <div className="homeContactWrapper__homeContact__left__content">
            <div className="homeContactWrapper__homeContact__header">
              <h2>{tc.homeContactGetInTouch}</h2>
              <h4>{tc.homeContactGetInTouchSub}</h4>
            </div>
            <div className="homeContactWrapper__homeContact__left__content__form">
              <HomeForm />
            </div>
            <div className="homeContactWrapper__homeContact__left__content__info">
              <p>{tc.homeGettingStartedFormInfo1}</p>
              <p>{tc.homeGettingStartedFormInfo2}</p>
            </div>
          </div>
        </div>
        <div className="homeContactWrapper__homeContact__right">
          <div className="homeContactWrapper__homeContact__right__content">
            <div className="homeContactWrapper__homeContact__header">
              <h2>{tc.homeContactFindUs}</h2>
              <h4>{tc.homeContactFindUsSub}</h4>
            </div>
            <div className="homeContactWrapper__homeContact__right__content__item">
              <div className="homeContactWrapper__homeContact__right__content__item__row">
                <div className="homeContactWrapper__homeContact__right__content__item__row__label">
                  <span>{tc.homeContactWaysAdress}</span>
                </div>
                <div className="homeContactWrapper__homeContact__right__content__item__row__value">
                  <span>{tc.homeContactWaysAdressValue1}</span>
                  <span>{tc.homeContactWaysAdressValue2}</span>
                  <span>{tc.homeContactWaysAdressValue3}</span>
                </div>
              </div>
              <div className="homeContactWrapper__homeContact__right__content__item__row">
                <div className="homeContactWrapper__homeContact__right__content__item__row__label">
                  <span>{tc.homeContactWaysPhone}</span>
                </div>
                <div className="homeContactWrapper__homeContact__right__content__item__row__value">
                  <span>{tc.homeContactWaysPhoneValue}</span>
                </div>
              </div>
              <div className="homeContactWrapper__homeContact__right__content__item__row">
                <div className="homeContactWrapper__homeContact__right__content__item__row__label">
                  <span>{tc.homeContactWaysEmail}</span>
                </div>
                <div className="homeContactWrapper__homeContact__right__content__item__row__value">
                  <span>{tc.homeContactWaysEmailValue}</span>
                </div>
              </div>
              <div className="homeContactWrapper__homeContact__right__content__item__row">
                <div className="homeContactWrapper__homeContact__right__content__item__row__value marginTop">
                  <span>
                    <strong>{tc.homeContactWaysInfo}</strong>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeContact;
