import React, { useContext, useState, useRef, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { textHelper } from 'helpers';
import LanguageContext from 'language-context';
import Activities from 'components/activities';
import CompanyInfo from './company_info';
import CompanyInLists from './company_in_lists';
import Contacts from 'components/contacts';
import CreateDeal from 'components/create_deal';
import Events from 'components/events';
import FleetWidget from 'components/fleet_widget';
import FleetWidgetHistoric from 'components/fleet_widget/fleet_widget_new';
import Loading from 'components/loading';
import Menu from 'components/menu';
import PageHeader from 'components/page_header';
import SaveToList from 'components/save_to_list';
import { getDeals } from 'store/company/tasks';
import AddActivityToProspectPopup from '../activities/add_activity_to_prospect';
import KoncernStructure from 'components/koncern/koncern_structure';
import CompanySubNavigation from 'components/company/company_sub_navigation';
import CompanyAdditionalInformation from 'components/company/company_additional_information';
import Icon from 'components/icon/icon';
import { resetActivitiesByTarget } from 'store/activities/tasks';
const Company = ({ company, fleetAmount = null }) => {
  let { id } = useParams();
  id = textHelper.removeDashFromOrgnr(id);
  const [showCreateDeal, setShowCreateDeal] = useState(false);
  const [showSaveToList, setShowSaveToList] = useState(false);
  const [showAddActivity, setShowAddActivity] = useState(false);

  // states for minimizing the different sections in company page
  const [minimizeCompanyInfo, setMinimizeCompanyInfo] = useState(false);
  const [minimizeAdditionalInformation, setMinimizeAdditionalInformation] =
    useState(true);
  const [minimizeLists, setMinimizeLists] = useState(true);
  const [minimizePlannedActivities, setMinimizePlannedActivities] =
    useState(true);
  const [minimizeContacts, setMinimizeContacts] = useState(false);
  const [minimizeKoncern, setMinimizeKoncern] = useState(false);
  const [minimizeFleet, setMinimizeFleet] = useState(false);
  const [minimizeActivity, setMinimizeActivity] = useState(false);
  const [minimizeFleetHistoric, setMinimizeFleetHistoric] = useState(false);
  const tc = useContext(LanguageContext);

  /*scroll ref for shortcuts*/
  const additionalInformation = useRef(null);
  const vehiclesRef = useRef(null);
  const koncernRef = useRef(null);
  const listRef = useRef(null);
  const plannedActivityRef = useRef(null);
  const contactPersonRef = useRef(null);
  const activitiesRef = useRef(null);
  const topOfPageRef = useRef(null);
  const vehiclesHistoricRef = useRef(null);

  const history = useHistory();
  const goToAllaBolag = () => {
    const url = `http://allabolag.se/${id}`;
    window.open(url, '_blank');
  };

  const goToReports = () => {
    const url = `/reports?rapport=27&filterName=Orgnr&filterValue=${id}`;
    history.push(url);
  };
  //same order as the page componets
  const sectionNavInfo = [
    {
      name: tc.additionalInformation,
      ref: additionalInformation,
      icon: 'infoOutlined',
      onClick: () => {
        setMinimizeAdditionalInformation(false);
      },
    },
    {
      name: tc.lists,
      ref: listRef,
      icon: 'list',
      onClick: () => {
        setMinimizeLists(false);
      },
    },
    {
      name: tc.events,
      ref: plannedActivityRef,
      icon: 'eventsCalendar',
      onClick: () => {
        setMinimizePlannedActivities(false);
      },
    },
    {
      name: tc.contacts,
      ref: contactPersonRef,
      icon: 'contact',
      onClick: () => {
        setMinimizeContacts(false);
      },
    },
    ...(company.koncern && company.koncern.id
      ? [
          {
            name: tc.koncern,
            ref: koncernRef,
            icon: 'koncern',
            onClick: () => {
              setMinimizeKoncern(false);
            },
          },
        ]
      : []),
    {
      name: tc.fleet,
      ref: vehiclesRef,
      icon: 'corporation',
      onClick: () => {
        setMinimizeFleet(false);
      },
    },
    {
      name: tc.fleetHistoric,
      ref: vehiclesHistoricRef,
      icon: 'history',
      onClick: () => {
        setMinimizeFleetHistoric(false);
      },
    },
    {
      name: tc.activity,
      ref: activitiesRef,
      icon: 'activity',
      onClick: () => {
        setMinimizeActivity(false);
      },
    },
    {
      name: tc.navigateToAllaBolag,
      ref: null,
      icon: 'navigateNext',
      onClick: goToAllaBolag,
    },
    {
      name: tc.fleetReport,
      ref: null,
      icon: 'navigateNext',
      onClick: goToReports,
    },
  ];

  const isCompanyPage = window.location.pathname.includes('foretag');

  useEffect(() => {
    topOfPageRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [id]);

  useEffect(() => {
    if (isCompanyPage) {
      resetActivitiesByTarget();
    }
  }, [id, isCompanyPage]);

  return (
    <div className="companyWrapper" ref={topOfPageRef}>
      <div className="companyWrapper__company">
        <div className="companyWrapper__company__header">
          <div className="companyWrapper__company__header__top">
            <PageHeader
              headline={
                company && company.info ? (
                  company.info.name
                ) : (
                  <Loading small={true} />
                )
              }
            />
          </div>
          <div className="companyWrapper__company__header__bottom">
            <Menu
              skipSort={true}
              items={[
                {
                  label: tc.saveToList,
                  onClick: () => {
                    setShowSaveToList(true);
                  },
                  type: 'button',
                },
                {
                  label: tc.createNewDeal,
                  onClick: () => {
                    setShowCreateDeal(true);
                  },
                  type: 'button',
                },
                {
                  label: tc.addActivity,
                  onClick: () => {
                    setShowAddActivity(true);
                  },
                  type: 'button',
                },
              ]}
            />
          </div>
        </div>
        <div className="companyWrapper__company__content">
          <CompanySubNavigation
            sectionNavInfo={sectionNavInfo}
            companyId={company?.info?.company_id}
          />
          <div
            className={
              minimizeCompanyInfo
                ? 'companyWrapper__company__content__item minimize'
                : 'companyWrapper__company__content__item'
            }
            style={{
              cursor: minimizeCompanyInfo ? 'pointer' : 'default',
            }}
            onClick={() => {
              if (minimizeCompanyInfo) {
                setMinimizeCompanyInfo(false);
              }
            }}
          >
            <CompanyInfo
              id={id}
              isMinimized={minimizeCompanyInfo}
              onMinimizeChange={setMinimizeCompanyInfo}
              isCompanyPage={isCompanyPage}
              fleetAmount={fleetAmount}
            />
            <div className="companyWrapper__company__content__item__block__header__icon">
              {minimizeCompanyInfo ? (
                <Icon
                  val="maximize"
                  onClick={() => setMinimizeCompanyInfo(false)}
                  class="companyWrapper__company__content__item__icon__cursor"
                />
              ) : (
                <Icon
                  val="minimize"
                  onClick={() => setMinimizeCompanyInfo(true)}
                  class="companyWrapper__company__content__item__icon__cursor"
                />
              )}
            </div>
          </div>
          <div
            ref={additionalInformation}
            className={
              minimizeAdditionalInformation
                ? 'companyWrapper__company__content__item minimize'
                : 'companyWrapper__company__content__item'
            }
            style={{
              cursor: minimizeAdditionalInformation ? 'pointer' : 'default',
            }}
            onClick={() => {
              if (minimizeAdditionalInformation) {
                setMinimizeAdditionalInformation(false);
              }
            }}
          >
            <CompanyAdditionalInformation
              companyInfo={company.info}
              isMinimized={minimizeAdditionalInformation}
              onMinimizeChange={setMinimizeAdditionalInformation}
              isCompanyPage={isCompanyPage}
            />
            <div className="companyWrapper__company__content__item__block__header__icon">
              {minimizeAdditionalInformation ? (
                <Icon
                  val="maximize"
                  onClick={() => setMinimizeAdditionalInformation(false)}
                  class="companyWrapper__company__content__item__icon__cursor"
                />
              ) : (
                <Icon
                  val="minimize"
                  onClick={() => setMinimizeAdditionalInformation(true)}
                  class="companyWrapper__company__content__item__icon__cursor"
                />
              )}
            </div>
          </div>
          <div
            style={{
              cursor: minimizeLists ? 'pointer' : 'default',
            }}
            onClick={() => {
              if (minimizeLists) {
                setMinimizeLists(false);
              }
            }}
            ref={listRef}
            className={
              minimizeLists
                ? 'companyWrapper__company__content__item minimize'
                : 'companyWrapper__company__content__item'
            }
          >
            <CompanyInLists
              id={id}
              isMinimized={minimizeLists}
              onMinimizeChange={setMinimizeLists}
              isCompanyPage={isCompanyPage}
            />
            <div className="companyWrapper__company__content__item__block__header__icon">
              {minimizeLists ? (
                <Icon
                  val="maximize"
                  onClick={() => setMinimizeLists(false)}
                  class="companyWrapper__company__content__item__icon__cursor"
                />
              ) : (
                <Icon
                  val="minimize"
                  onClick={() => setMinimizeLists(true)}
                  class="companyWrapper__company__content__item__icon__cursor"
                />
              )}
            </div>
          </div>
          <div
            ref={plannedActivityRef}
            className={
              minimizePlannedActivities
                ? 'companyWrapper__company__content__item minimize'
                : 'companyWrapper__company__content__item'
            }
            style={{
              cursor: minimizePlannedActivities ? 'pointer' : 'default',
            }}
            onClick={() => {
              if (minimizePlannedActivities) {
                setMinimizePlannedActivities(false);
              }
            }}
          >
            <Events
              onlyShowFlow={true}
              target={id}
              type="target"
              view="flow"
              isMinimized={minimizePlannedActivities}
              onMinimizeChange={setMinimizePlannedActivities}
              isCompanyPage={isCompanyPage}
            />
            <div className="companyWrapper__company__content__item__block__header__icon">
              {minimizePlannedActivities ? (
                <Icon
                  val="maximize"
                  onClick={() => setMinimizePlannedActivities(false)}
                  class="companyWrapper__company__content__item__icon__cursor"
                />
              ) : (
                <Icon
                  val="minimize"
                  onClick={() => setMinimizePlannedActivities(true)}
                  class="companyWrapper__company__content__item__icon__cursor"
                />
              )}
            </div>
          </div>
          <div
            ref={contactPersonRef}
            className={
              minimizeContacts
                ? 'companyWrapper__company__content__item minimize'
                : 'companyWrapper__company__content__item'
            }
            style={{
              cursor: minimizeContacts ? 'pointer' : 'default',
            }}
            onClick={() => {
              if (minimizeContacts) {
                setMinimizeContacts(false);
              }
            }}
          >
            <Contacts
              entityId={id}
              entityName={
                company && company.info ? (
                  company.info.name
                ) : (
                  <Loading small={true} />
                )
              }
              entityType="company"
              isMinimized={minimizeContacts}
              onMinimizeChange={setMinimizeContacts}
              isCompanyPage={isCompanyPage}
            />
            <div className="companyWrapper__company__content__item__block__header__icon">
              {minimizeContacts ? (
                <Icon
                  val="maximize"
                  onClick={() => setMinimizeContacts(false)}
                  class="companyWrapper__company__content__item__icon__cursor"
                />
              ) : (
                <Icon
                  val="minimize"
                  onClick={() => setMinimizeContacts(true)}
                  class="companyWrapper__company__content__item__icon__cursor"
                />
              )}
            </div>
          </div>
          {company.koncern && company.koncern.id ? (
            <div
              ref={koncernRef}
              className={
                minimizeKoncern
                  ? 'companyWrapper__company__content__item minimize'
                  : 'companyWrapper__company__content__item'
              }
              style={{
                cursor: minimizeKoncern ? 'pointer' : 'default',
              }}
              onClick={() => {
                if (minimizeKoncern) {
                  setMinimizeKoncern(false);
                }
              }}
            >
              <KoncernStructure
                id={company.koncern?.id}
                isMinimized={minimizeKoncern}
                onMinimizeChange={setMinimizeKoncern}
                isCompanyPage={isCompanyPage}
              />

              <div className="companyWrapper__company__content__item__block__header__icon">
                {minimizeKoncern ? (
                  <Icon
                    val="maximize"
                    onClick={() => setMinimizeKoncern(false)}
                    class="companyWrapper__company__content__item__icon__cursor"
                  />
                ) : (
                  <Icon
                    val="minimize"
                    onClick={() => setMinimizeKoncern(true)}
                    class="companyWrapper__company__content__item__icon__cursor"
                  />
                )}
              </div>
            </div>
          ) : null}
          <div
            ref={vehiclesRef}
            className={
              minimizeFleet
                ? 'companyWrapper__company__content__item minimize'
                : 'companyWrapper__company__content__item'
            }
            style={{
              cursor: minimizeFleet ? 'pointer' : 'default',
            }}
            onClick={() => {
              if (minimizeFleet) {
                setMinimizeFleet(false);
              }
            }}
          >
            <FleetWidget
              prospectId={id}
              isMinimized={minimizeFleet}
              onMinimizeChange={setMinimizeFleet}
              isCompanyPage={isCompanyPage}
            />
            <div className="companyWrapper__company__content__item__block__header__icon">
              {minimizeFleet ? (
                <Icon
                  val="maximize"
                  onClick={() => setMinimizeFleet(false)}
                  class="companyWrapper__company__content__item__icon__cursor"
                />
              ) : (
                <Icon
                  val="minimize"
                  onClick={() => setMinimizeFleet(true)}
                  class="companyWrapper__company__content__item__icon__cursor"
                />
              )}
            </div>
          </div>
          <div
            className={
              minimizeFleetHistoric
                ? 'companyWrapper__company__content__item minimize'
                : 'companyWrapper__company__content__item'
            }
            ref={vehiclesHistoricRef}
            style={{
              cursor: minimizeFleetHistoric ? 'pointer' : 'default',
            }}
            onClick={() => {
              if (minimizeFleetHistoric) {
                setMinimizeFleetHistoric(false);
              }
            }}
          >
            <FleetWidgetHistoric
              id={id}
              topOfPageRef={topOfPageRef}
              isMinimized={minimizeFleetHistoric}
              onMinimizeChange={setMinimizeFleetHistoric}
              isCompanyPage={isCompanyPage}
            />
            <div className="companyWrapper__company__content__item__block__header__icon">
              {minimizeFleetHistoric ? (
                <Icon
                  val="maximize"
                  onClick={() => setMinimizeFleetHistoric(false)}
                  class="companyWrapper__company__content__item__icon__cursor"
                />
              ) : (
                <Icon
                  val="minimize"
                  onClick={() => setMinimizeFleetHistoric(true)}
                  class="companyWrapper__company__content__item__icon__cursor"
                />
              )}
            </div>
          </div>
          <div
            ref={activitiesRef}
            className={
              minimizeActivity
                ? 'companyWrapper__company__content__item minimize'
                : 'companyWrapper__company__content__item'
            }
            style={{
              cursor: minimizeActivity ? 'pointer' : 'default',
            }}
            onClick={() => {
              if (minimizeActivity) {
                setMinimizeActivity(false);
              }
            }}
          >
            <Activities
              target={id}
              view="flow"
              isMinimized={minimizeActivity}
              onMinimizeChange={setMinimizeActivity}
              isCompanyPage={isCompanyPage}
              targetType={'company'}
            />
            <div className="companyWrapper__company__content__item__block__header__icon">
              {minimizeActivity ? (
                <Icon
                  val="maximize"
                  onClick={() => setMinimizeActivity(false)}
                  class="companyWrapper__company__content__item__icon__cursor"
                />
              ) : (
                <Icon
                  val="minimize"
                  onClick={() => setMinimizeActivity(true)}
                  class="companyWrapper__company__content__item__icon__cursor"
                />
              )}
            </div>
          </div>

          {showCreateDeal && company && company.info && company.koncern ? (
            <CreateDeal
              close={async (dealCreated) => {
                if (dealCreated) {
                  setShowCreateDeal(false);
                  return await getDeals({ id: id });
                } else {
                  return setShowCreateDeal(false);
                }
              }}
              headline={
                tc.with +
                ' ' +
                tc.connection.toLowerCase() +
                ' ' +
                tc.to.toLowerCase() +
                ' ' +
                company.info.name
              }
              koncern={company.koncern.hasKoncern}
              prospects={[{ id: id, name: company.info.name }]}
              target={id}
            />
          ) : null}
          {showSaveToList ? (
            <SaveToList
              close={() => {
                setShowSaveToList(false);
              }}
              prospects={[id]}
            />
          ) : null}
          {showAddActivity ? (
            <AddActivityToProspectPopup
              close={() => {
                setShowAddActivity(false);
              }}
              prospectId={id}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

const MapStateToProps = (state) => {
  return {
    company: state.company,
    fleetAmount: state.fleet?.fleet?.total,
  };
};

export default connect(MapStateToProps)(Company);
