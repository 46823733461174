import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import ActivityCompletionHeader from 'components/activity_completion/activity_completion_header';
import ActivityCompletionContent from 'components/activity_completion/activity_completion_content';
import SaveActivityWidget from "components/save_activity_widget";

const ActivityCompletion = ({
  filter,
  user,
  widget,
  rowsPerPage,
}) => {
  const [responsiveClassWidth, setResponsiveClassWidth] = useState("");
  const [showSaveToDashboard, setShowSaveToDashboard] = useState(false);
  const [minimize, setMinimize] = useState(false);
  const activitiesRef = useRef(null);
  const observer = useRef(null);

  useEffect(() => {
    _setResponsiveClass();
    observer.current = new ResizeObserver(_setResponsiveClass);
    observer.current.observe(activitiesRef.current);

    const ref = activitiesRef.current;

    return () => observer.current.unobserve(ref);
  }, []);

  const _setResponsiveClass = () => {
    if (!activitiesRef?.current) {
      return;
    }

    const width = activitiesRef.current.getBoundingClientRect().width;
    const breakpoint1 = 400;

    if (width <= breakpoint1) {
      setResponsiveClassWidth("activitiesResponsiveWidth1");
    } else if (width > breakpoint1) {
      setResponsiveClassWidth("");
    }
  };

  return (
    <div className="activitiesWrapper" ref={activitiesRef}>
      <div className="activitiesWrapper__activities">
        <ActivityCompletionHeader
          minimize={minimize}
          setMinimize={setMinimize}
          showSaveToDashboard={showSaveToDashboard}
          setShowAddToDashboard={setShowSaveToDashboard}
          widget={widget}
        />
        <ActivityCompletionContent
          responsiveClassWidth={responsiveClassWidth}
          filter={filter}
          user={user}
          widget={widget}
          rowsPerPage={rowsPerPage}
        />
        {showSaveToDashboard ? (
          <SaveActivityWidget
            close={() => {
              setShowSaveToDashboard(false);
            }}
            type="activityCompletion"
          />
        ) : null}
      </div>
    </div>
  );
};

const MapStateToProps = (state) => {
  return {
    filter: state.activity.filter,
    user: state.user,
  };
};

export default connect(MapStateToProps)(ActivityCompletion);
