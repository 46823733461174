import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import LanguageContext from 'language-context';
import { NavLink } from 'react-router-dom';
import {
  setResponsibility,
  updateCompanyInformation,
  getBasicCompanyInfo,
  getBasicKoncernInfo,
  getBasicContactInfo,
  getResponsible,
  getDeals,
  getCompanyContracts,
} from 'store/company/tasks';
import { showFlashMessage } from 'store/flash_messages/tasks';
import ColleaguesDropdown from 'components/colleagues_dropdown/colleagues_dropdown';
import Icon from 'components/icon';
import Loading from 'components/loading';
import Tooltip from 'components/tooltip';
import WidgetHeader from 'components/widget_header';
import Popup from 'components/popup';
import WidgetFooter from 'components/widget_footer';
import Info from '../info';

const ContactInfo = ({ info, onEditSave, onDelete, editable }) => {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(info.value || '');
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [toDelete, setToDelete] = useState(null);

  const tc = useContext(LanguageContext);

  const ConfirmPopup = (state) => {
    return (
      <Popup close={() => setShowConfirmPopup(false)}>
        <p>{tc.ensure}</p>
        <WidgetFooter
          buttonOneFunc={() => {
            if (toDelete) {
              onDelete(toDelete);
              setToDelete(null);
            }
          }}
          buttonTwoFunc={() => setShowConfirmPopup(false)}
          buttonOneText={tc.remove}
          buttonTwoText={tc.cancel}
        />
      </Popup>
    );
  };

  return (
    <>
      {editable ? (
        <>
          {editing ? (
            <>
              <div className="companyInfoWrapper__companyInfo__content__itemsWrapper__item__editField">
                <input
                  onChange={(e) => setValue(e.target.value)}
                  placeholder={
                    info.type === 'phone' ? tc.addPhone : tc.addEmail
                  }
                  type="text"
                  value={value}
                />
                <Tooltip tooltipContent={tc.cancel}>
                  <Icon val="clear" onClick={() => setEditing(false)} />
                </Tooltip>
                <Tooltip tooltipContent={tc.save}>
                  <Icon
                    val="save"
                    onClick={() => {
                      if (value) {
                        onEditSave({
                          value,
                          prospectId: info.prospectId,
                          valueId: info.id,
                          type: info.type,
                        });
                        setEditing(false);
                      }
                    }}
                  />
                </Tooltip>
              </div>
            </>
          ) : (
            <>
              <div className="companyInfoWrapper__companyInfo__content__itemsWrapper__item__field">
                <a
                  href={`${info.type === 'phone' ? 'tel:+46' : 'mailto:'}${
                    info.value
                  }`}
                >
                  {info.value}
                </a>
                <Tooltip tooltipContent={tc.edit}>
                  <Icon val="edit" onClick={() => setEditing(true)} />
                </Tooltip>
                <Tooltip tooltipContent={tc.remove}>
                  <Icon
                    val="remove"
                    onClick={() => {
                      setShowConfirmPopup(true);
                      setToDelete({
                        value,
                        prospectId: info.prospectId,
                        valueId: info.id,
                        type: info.type,
                      });
                    }}
                  />
                </Tooltip>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <div className="companyInfoWrapper__companyInfo__content__itemsWrapper__item__field">
            <a
              href={`${info.type === 'phone' ? 'tel:+46' : 'mailto'}${
                info.value
              }`}
            >
              {info.value}
            </a>
          </div>
        </>
      )}
      {showConfirmPopup && <ConfirmPopup />}
    </>
  );
};

const AddContactInfo = ({ info, onSave }) => {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState('');

  const tc = useContext(LanguageContext);

  return (
    <div className="companyInfoWrapper__companyInfo__content__itemsWrapper__item__addField">
      {!editing ? (
        <Tooltip
          tooltipContent={info.type === 'phone' ? tc.addPhone : tc.addEmail}
        >
          <Icon val="add" onClick={() => setEditing(true)} />
        </Tooltip>
      ) : (
        <div className="companyInfoWrapper__companyInfo__content__itemsWrapper__item__editField">
          <input
            onChange={(e) => setValue(e.target.value)}
            placeholder={info.type === 'phone' ? tc.addPhone : tc.addEmail}
            type="text"
            value={value}
          />
          <Tooltip tooltipContent={tc.cancel}>
            <Icon val="clear" onClick={() => setEditing(false)} />
          </Tooltip>
          <Tooltip tooltipContent={tc.save}>
            <Icon
              val="save"
              onClick={() => {
                if (value) {
                  onSave({
                    value,
                    id: info.id,
                    prospectId: info.prospectId,
                    type: info.type,
                  });
                  setEditing(false);
                }
              }}
            />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

/**
 * companyInfo component.
 */
const CompanyInfo = ({
  companyInfo,
  koncernInfo,
  phoneNumbers,
  emails,
  responsible,
  id,
  contracts,
  isMinimized,
  onMinimizeChange,
  isCompanyPage,
  fleetAmount,
}) => {
  const [minimize, setMinimize] = useState(false);
  const [changeResponsible, setChangeResponsible] = useState(false);
  const [responsibleObj, setResponsibleObj] = useState(null);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    setMinimize(isMinimized);
  }, [isMinimized]);

  useEffect(() => {
    getBasicCompanyInfo({ id });
    getBasicKoncernInfo({ id });
    getBasicContactInfo({ id });
    getDeals({ id });
    getResponsible({ id });
    getCompanyContracts({ ids: [id] });
  }, [id]);

  useEffect(() => {
    setResponsibleObj(responsible);
  }, [responsible]);

  // Add phone or email.
  const _addValue = async ({ prospectId, value, type }) => {
    if (!value || (value && value === '')) {
      return showFlashMessage(tc.valueCannotBeEmpty, 'info');
    }

    await updateCompanyInformation({
      action: 'add',
      prospectId,
      type,
      value,
    });

    await getBasicContactInfo({ id });
  };

  // Remove phone or email.
  const _removeValue = async ({ prospectId, valueId, type }) => {
    await updateCompanyInformation({
      action: 'delete',
      id: valueId,
      prospectId,
      type,
      value: null,
    });

    await getBasicContactInfo({ id });
  };

  const _updateValue = async ({ prospectId, valueId, value, type }) => {
    if (!value || (value && value === '')) {
      return showFlashMessage(tc.valueCannotBeEmpty, 'info');
    }

    await updateCompanyInformation({
      action: 'edit',
      id: valueId,
      prospectId,
      type,
      value,
    });

    await getBasicContactInfo({ id });
  };

  const _saveResponsible = async () => {
    setChangeResponsible(false);
    await setResponsibility({
      entityId: companyInfo.company_id,
      responsibleUserId: responsibleObj.responsibleUserId,
    });

    const data = await getResponsible({ id });

    setResponsibleObj(data);
  };

  const copyToClipboard = (textToCopy) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        {
          showFlashMessage(tc.copyConfirmationMessage, 'success');
        }
      })
      .catch((err) => {
        console.error('Error copying to clipboard:', err);
        {
          showFlashMessage(tc.error, 'fail');
        }
      });
  };

  const contractKeysToDisplay = [
    'contract_customer_id',
    'discount',
    'responsible_party',
    'start_date',
    'end_date',
  ];

  const keysToText = {
    contract_customer_id: tc.contractCustomerId,
    discount: tc.discount,
    responsible_party: tc.responsibleParty,
    start_date: tc.startDate,
    end_date: tc.endDate,
  };

  return (
    <div className="companyInfoWrapper">
      <div className="companyInfoWrapper__companyInfo">
        <div className="companyInfoWrapper__companyInfo__header">
          <WidgetHeader
            dashboardItems={
              isCompanyPage
                ? null
                : [
                    {
                      disabled: !minimize,
                      icon: 'maximize',
                      label: tc.maximize,
                      onClick: () => {
                        setMinimize(false);
                      },
                    },
                    {
                      disabled: minimize,
                      icon: 'minimize',
                      label: tc.minimize,
                      onClick: () => {
                        setMinimize(true);
                        if (onMinimizeChange) {
                          onMinimizeChange(true);
                        }
                      },
                    },
                  ]
            }
            headlineElement={
              <>
                <div className="companyInfoWrapper__companyInfo__header__big">
                  <h2>
                    {companyInfo ? companyInfo.name : <Loading small={true} />}
                  </h2>
                </div>
                <div className="companyInfoWrapper__companyInfo__header__big">
                  <div className="companyInfoWrapper__companyInfo__header__big__content"></div>
                </div>
              </>
            }
            headlineSub={
              <div>
                {contracts && contracts[id] ? (
                  <Info>
                    <div>
                      {tc.customerHasContract}{' '}
                      {contracts[id].map((c) => {
                        return (
                          <div
                            key={c.contract_customer_id}
                            style={{
                              marginBottom: '8px',
                            }}
                          >
                            {contractKeysToDisplay.map((key) => {
                              return c[key] ? (
                                <p
                                  style={
                                    key === 'contract_customer_id'
                                      ? {
                                          fontSize: '1.05rem',
                                          borderBottom: '1px solid black',
                                        }
                                      : {}
                                  }
                                  key={key}
                                >
                                  {keysToText[key] ?? key} : {c[key]}
                                </p>
                              ) : null;
                            })}
                          </div>
                        );
                      })}
                    </div>
                  </Info>
                ) : null}
              </div>
            }
          />
        </div>
        <div
          className={
            minimize
              ? 'companyInfoWrapper__companyInfo__content minimize'
              : 'companyInfoWrapper__companyInfo__content'
          }
        >
          <div className="companyInfoWrapper__companyInfo__content__itemsWrapper">
            <div className="companyInfoWrapper__companyInfo__content__itemsWrapper__item">
              <div className="companyInfoWrapper__companyInfo__content__flexContainer">
                <div className="companyInfoWrapper__companyInfo__content__widthContainer">
                  <p className="companyInfoWrapper__companyInfo__content__itemsWrapper__item__companyInfoLabel">
                    {tc.owner}
                  </p>
                </div>
                <div className="companyInfoWrapper__companyInfo__content__flexContainer__flex">
                  {changeResponsible ? (
                    <div className="editable">
                      <ColleaguesDropdown
                        activeId={responsibleObj.responsibleUserId}
                        activeName={responsibleObj.responsibleUserName}
                        transparent={true}
                        onClick={(id, name) => {
                          setResponsibleObj({
                            ...responsibleObj,
                            responsibleUserId: id,
                            responsibleUserName: name,
                          });
                        }}
                      />
                      <Tooltip tooltipContent={tc.cancel}>
                        <Icon
                          val="clear"
                          onClick={() => {
                            if (responsible) {
                              setResponsibleObj(responsible);
                            } else {
                              setResponsibleObj({
                                responsibleUserId: null,
                                responsibleUserName: '',
                              });
                            }

                            setChangeResponsible(false);
                          }}
                        />
                      </Tooltip>
                      <Tooltip tooltipContent={tc.save}>
                        <Icon
                          val="save"
                          onClick={() => {
                            _saveResponsible();
                          }}
                        />
                      </Tooltip>
                    </div>
                  ) : (
                    <div className="editable">
                      <p>
                        {responsibleObj ? (
                          responsibleObj.responsibleUserName
                        ) : (
                          <span className="italic">{tc.noOwner}</span>
                        )}
                      </p>
                      <Tooltip tooltipContent={tc.changeOwner}>
                        <Icon
                          val="edit"
                          onClick={() => {
                            setChangeResponsible(true);
                          }}
                        />
                      </Tooltip>
                    </div>
                  )}
                </div>
              </div>

              <div className="companyInfoWrapper__companyInfo__content__flexContainer">
                <div className="companyInfoWrapper__companyInfo__content__widthContainer">
                  <p className="companyInfoWrapper__companyInfo__content__itemsWrapper__item__companyInfoLabel">
                    {tc.companyName}
                  </p>
                </div>

                <div className="companyInfoWrapper__companyInfo__content__flexContainer__flex">
                  {companyInfo ? companyInfo.name : <Loading small={true} />}
                </div>
              </div>

              <div className="companyInfoWrapper__companyInfo__content__flexContainer">
                <div className="companyInfoWrapper__companyInfo__content__widthContainer">
                  <p className="companyInfoWrapper__companyInfo__content__itemsWrapper__item__companyInfoLabel">
                    {tc.orgNr}
                  </p>
                </div>
                <div className="companyInfoWrapper__companyInfo__content__flexContainer__flex">
                  <div className="companyInfoWrapper__companyInfo__content__valueText">
                    {companyInfo ? (
                      companyInfo.company_id
                    ) : (
                      <Loading small={true} />
                    )}
                  </div>
                  <Tooltip tooltipContent={tc.copyToClipBoard} inline={true}>
                    <Icon
                      class="companyInfoWrapper__companyInfo__content__copyIcon"
                      val="copyContent"
                      onClick={() =>
                        copyToClipboard(
                          companyInfo ? companyInfo.company_id : ''
                        )
                      }
                    />
                  </Tooltip>
                </div>
              </div>
              <div className="companyInfoWrapper__companyInfo__content__flexContainer">
                <div className="companyInfoWrapper__companyInfo__content__widthContainer">
                  <p className="companyInfoWrapper__companyInfo__content__itemsWrapper__item__companyInfoLabel">
                    {tc.carsOwned}
                  </p>
                </div>
                <div className="companyInfoWrapper__companyInfo__content__flexContainer__flex">
                  {fleetAmount === null ? (
                    <Loading small={true} />
                  ) : (
                    fleetAmount
                  )}
                </div>
              </div>
              <div className="companyInfoWrapper__companyInfo__content__flexContainer">
                <div className="companyInfoWrapper__companyInfo__content__widthContainer">
                  <p className="companyInfoWrapper__companyInfo__content__itemsWrapper__item__companyInfoLabel">
                    {tc.filialer}
                  </p>
                </div>
                <div className="companyInfoWrapper__companyInfo__content__flexContainer__flex">
                  {companyInfo ? (
                    <>
                      {companyInfo.filialer.length > 0 ? (
                        <>
                          <NavLink
                            exact
                            to={`/sok?multiple=true&ids=${JSON.stringify(
                              companyInfo.filialer.map((c) => c.company_id)
                            )}`}
                            key="hem"
                          >
                            {`${
                              companyInfo.filialer.length
                            } ${tc.aPiece.toLowerCase()}`}
                          </NavLink>
                        </>
                      ) : (
                        <span>-</span>
                      )}
                    </>
                  ) : (
                    <Loading small={true} />
                  )}
                </div>
              </div>

              <div className="companyInfoWrapper__companyInfo__content__flexContainer">
                <div className="companyInfoWrapper__companyInfo__content__widthContainer">
                  <p className="companyInfoWrapper__companyInfo__content__itemsWrapper__item__companyInfoLabel">
                    {tc.parentCompany}
                  </p>
                </div>
                <div className="companyInfoWrapper__companyInfo__content__flexContainer__flex">
                  {companyInfo ? (
                    <>
                      {companyInfo.parent_company_id ? (
                        <>
                          <NavLink
                            exact
                            to={`/foretag/${companyInfo.parent_company_id}`}
                            key="hem"
                          >
                            {companyInfo.parent_name}
                          </NavLink>
                        </>
                      ) : (
                        <span>-</span>
                      )}
                    </>
                  ) : (
                    <Loading small={true} />
                  )}
                </div>
              </div>

              <div className="companyInfoWrapper__companyInfo__content__flexContainer">
                <div className="companyInfoWrapper__companyInfo__content__widthContainer">
                  <p className="companyInfoWrapper__companyInfo__content__itemsWrapper__item__companyInfoLabel">
                    {tc.koncernCompany}
                  </p>
                </div>
                <div className="companyInfoWrapper__companyInfo__content__flexContainer__flex">
                  {koncernInfo ? (
                    <>
                      {koncernInfo.hasKoncern ? (
                        koncernInfo.dataMissing ? (
                          <>{tc.missing}</>
                        ) : (
                          <>
                            <p>
                              {`${tc.partOfKoncern} ${tc.with.toLowerCase()} ${
                                koncernInfo.companyCount
                              } ${tc.companies.toLowerCase()} ${tc.and.toLowerCase()} ${
                                koncernInfo.fleetCount
                              } ${tc.cars.toLowerCase()}: `}
                              <NavLink
                                exact
                                to={`/koncern/${koncernInfo.id}`}
                                key="hem"
                              >
                                {koncernInfo.name}
                              </NavLink>
                            </p>
                          </>
                        )
                      ) : (
                        <span>-</span>
                      )}
                    </>
                  ) : (
                    <Loading small={true} />
                  )}
                </div>
              </div>
            </div>
            <div className="companyInfoWrapper__companyInfo__content__itemsWrapper__item">
              <div className="companyInfoWrapper__companyInfo__content__flexContainer">
                <div className="companyInfoWrapper__companyInfo__content__widthContainer">
                  <p className="companyInfoWrapper__companyInfo__content__itemsWrapper__item__companyInfoLabel">
                    {tc.email}
                  </p>
                </div>

                {companyInfo ? (
                  <div className="companyInfoWrapper__companyInfo__content__itemsWrapper__item__field">
                    {emails && emails.length > 0
                      ? emails.map((email) => (
                          <ContactInfo
                            onEditSave={_updateValue}
                            onDelete={_removeValue}
                            info={{
                              ...email,
                              type: 'email',
                              prospectId: companyInfo.company_id,
                            }}
                            editable={email.editable}
                            key={email.id}
                          />
                        ))
                      : null}
                    {
                      <AddContactInfo
                        info={{
                          id: 123,
                          type: 'email',
                          prospectId: companyInfo.company_id,
                        }}
                        onSave={_addValue}
                      />
                    }
                  </div>
                ) : (
                  <Loading small={true} />
                )}
              </div>

              <div className="companyInfoWrapper__companyInfo__content__flexContainer">
                <div className="companyInfoWrapper__companyInfo__content__widthContainer">
                  <p className="companyInfoWrapper__companyInfo__content__itemsWrapper__item__companyInfoLabel">
                    {tc.phoneNumbers}
                  </p>
                </div>
                {companyInfo ? (
                  <div className="companyInfoWrapper__companyInfo__content__flexContainer__flex">
                    {phoneNumbers && phoneNumbers.length > 0 ? (
                      phoneNumbers.map((nr, index) => (
                        <React.Fragment key={nr.id}>
                          <ContactInfo
                            onEditSave={_updateValue}
                            onDelete={_removeValue}
                            info={{
                              ...nr,
                              type: 'phone',
                              prospectId: companyInfo.company_id,
                            }}
                            editable={nr.editable}
                            key={nr.id}
                          />
                          {index !== phoneNumbers.length - 1 && (
                            <span>,&nbsp;</span>
                          )}
                        </React.Fragment>
                      ))
                    ) : (
                      <>
                        <span>-</span>
                      </>
                    )}
                    {
                      <AddContactInfo
                        info={{
                          id: 123,
                          type: 'phone',
                          prospectId: companyInfo.company_id,
                        }}
                        onSave={_addValue}
                      />
                    }
                  </div>
                ) : (
                  <>
                    <Loading small={true} />
                  </>
                )}
              </div>

              <div className="companyInfoWrapper__companyInfo__content__flexContainer">
                <div className="companyInfoWrapper__companyInfo__content__widthContainer">
                  <p className="companyInfoWrapper__companyInfo__content__itemsWrapper__item__companyInfoLabel">
                    {tc.visitingAddress}
                  </p>
                </div>
                {companyInfo ? (
                  <div className="companyInfoWrapper__companyInfo__content__flexContainer__flex">
                    <p>
                      {companyInfo.ba_gatuadress
                        ? `${
                            companyInfo.ba_gatuadress
                              ? companyInfo.ba_gatuadress
                              : ''
                          } ${
                            companyInfo.ba_postnr ? companyInfo.ba_postnr : ''
                          } ${
                            companyInfo.ba_postort ? companyInfo.ba_postort : ''
                          }`
                        : '-'}
                    </p>
                  </div>
                ) : (
                  <Loading small={true} />
                )}
              </div>
              {companyInfo ? (
                <>
                  <div className="companyInfoWrapper__companyInfo__content__flexContainer">
                    <div className="companyInfoWrapper__companyInfo__content__widthContainer">
                      <p className="companyInfoWrapper__companyInfo__content__itemsWrapper__item__companyInfoLabel">
                        {tc.postAddress}
                      </p>
                    </div>
                    <div className="companyInfoWrapper__companyInfo__content__flexContainer__flex">
                      <p>
                        {companyInfo.ua_gatuadress
                          ? `${
                              companyInfo.ua_gatuadress
                                ? companyInfo.ua_gatuadress
                                : ''
                            } ${
                              companyInfo.ua_postnr ? companyInfo.ua_postnr : ''
                            } ${
                              companyInfo.ua_postort
                                ? companyInfo.ua_postort
                                : ''
                            }`
                          : '-'}
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                <Loading small={true} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const MapStateToProps = (state) => {
  return {
    companyInfo: state.company.info,
    koncernInfo: state.company.koncern,
    emails: state.company.emails,
    phoneNumbers: state.company.phoneNumbers,
    deals: state.company.deals,
    responsible: state.company.responsible,
    companyInLists: state.company.companyInLists,
    contracts: state.company.contracts,
  };
};

export default connect(MapStateToProps)(CompanyInfo);
