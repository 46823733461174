import React, { useContext, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { createWidget } from 'store/widgets/tasks';
import { showFlashMessage } from 'store/flash_messages/tasks';
import LanguageContext from 'language-context';
import Popup from 'components/popup';
import ToggleOption from 'components/toggle_option';
import WidgetFooter from 'components/widget_footer';
import WidgetHeader from 'components/widget_header';

/**
 * Save activity widget.
 *
 * Date options:
 * activity filter "from" date - activity filter "to" date
 * activity filter  "from" date - current day (continuous update)
 * current week (continuous update)
 * current month (continuous update)
 *
 * @param state.props.close - func
 * @param state.props.type - 'activities' | 'pipeline'
 * @param state.props.view - 'types' | 'groups' | 'flow'
 * @param state.props.widgetSpecificProperties - object (optional) - Any extra properties provided will be saved with widget.
 */
const SaveActivityWidget = (state) => {
  const dateOptionsFalse = {
    currentMonth: false,
    currentWeek: false,
    previousMonth: false,
    previousWeek: false,
    pastThirtyDays: false,
    past365Days: false,
  };
  const [dateOption, setDateOption] = useState({
    currentMonth: true,
    currentWeek: false,
    previousMonth: false,
    previousWeek: false,
    pastThirtyDays: false,
    past365Days: false,
  });
  const [subscriptionName, setSubscriptionName] = useState('');
  const newWidgetNameInputRef = useRef(null);
  const tc = useContext(LanguageContext);

  const { filter: filterState } = state.activity;

  useEffect(() => {
    newWidgetNameInputRef &&
      newWidgetNameInputRef.current &&
      newWidgetNameInputRef.current.focus();
  }, [newWidgetNameInputRef]);

  const _saveWidget = async () => {
    if (
      !state.activity?.filter?.lists?.length &&
      !state.activity?.filter?.users?.length
    ) {
      return showFlashMessage(tc.eitherListsOrUsersHasToHaveValue, 'info');
    }

    // For different widgets we might want to provide different properties.
    let widgetSpecificProperties = state.props.widgetSpecificProperties
      ? state.props.widgetSpecificProperties
      : {};

    state.props.close();
    await createWidget({
      dashboards: {
        dashboard: {
          ...widgetSpecificProperties,
          active: true,
          minimized: false,
          position: state.widgets?.data?.widgets?.length
            ? state.widgets.data.widgets.filter((num) =>
                num.dashboards.hasOwnProperty('dashboard')
              ).length
            : 0,
          view: state.props.view ? state.props.view : 'flow',
        },
      },
      data: {
        currentMonth: dateOption.currentMonth,
        currentWeek: dateOption.currentWeek,
        previousMonth: dateOption.previousMonth,
        previousWeek: dateOption.previousWeek,
        pastThirtyDays: dateOption.pastThirtyDays,
        past365Days: dateOption.past365Days,
        date: {
          from:
            dateOption.withToDate || dateOption.withFromDate
              ? filterState.date.from
              : null,
          to: dateOption.withToDate ? filterState.date.to : null,
        },
        lists: filterState.lists,
        subscriptions: filterState.subscriptions,
        users: filterState.users,
        prospectTypes: filterState.prospectTypes ?? [],
      },
      type: state.props.type,
      name: subscriptionName,
    });

    return showFlashMessage(tc.widgetHasBeenCreated, 'success');
  };

  return (
    <Popup close={state.props.close} size="medium">
      <div className="genericPopupContentWrapper">
        <div className="genericPopupContentWrapper__genericPopupContent">
          <div className="genericPopupContentWrapper__genericPopupContent__header">
            <WidgetHeader headline={tc.addToDashboard} />
          </div>
          <div className="genericPopupContentWrapper__genericPopupContent__content">
            <div className="genericPopupContentWrapper__genericPopupContent__content__section">
              <p>{`${tc.addToDashboardInfo} ${tc.widgetSaveFilterInfo}`}</p>
            </div>
            <div className="genericPopupContentWrapper__genericPopupContent__content__section">
              <p>
                <span>{tc.nameNewSubscription}:</span>
                <input
                  onChange={(e) => {
                    setSubscriptionName(e.target.value);
                  }}
                  type="text"
                  ref={newWidgetNameInputRef}
                />
              </p>
            </div>
            <div className="genericPopupContentWrapper__genericPopupContent__content__section">
              <ToggleOption
                active={dateOption.previousWeek}
                label={tc.previousWeek}
                labelSub={tc.widgetPreviousWeek}
                onClick={() => {
                  setDateOption({
                    currentMonth: false,
                    currentWeek: false,
                    previousMonth: false,
                    previousWeek: true,
                    withToDate: false,
                    withFromDate: false,
                  });
                }}
              />
              <ToggleOption
                active={dateOption.previousMonth}
                label={tc.previousMonth}
                labelSub={tc.widgetPreviousMonth}
                onClick={() => {
                  setDateOption({
                    currentMonth: false,
                    currentWeek: false,
                    previousMonth: true,
                    previousWeek: false,
                    withToDate: false,
                    withFromDate: false,
                  });
                }}
              />
              <ToggleOption
                active={dateOption.currentWeek}
                label={tc.currentWeek}
                labelSub={tc.widgetCurrentWeek}
                onClick={() => {
                  setDateOption({
                    currentMonth: false,
                    currentWeek: true,
                    previousMonth: false,
                    previousWeek: false,
                    withToDate: false,
                    withFromDate: false,
                  });
                }}
              />
              <ToggleOption
                active={dateOption.currentMonth}
                label={tc.currentMonth}
                labelSub={tc.widgetCurrentMonth}
                onClick={() => {
                  setDateOption({
                    currentMonth: true,
                    currentWeek: false,
                    previousMonth: false,
                    previousWeek: false,
                    withToDate: false,
                    withFromDate: false,
                  });
                }}
              />
              <ToggleOption
                active={dateOption.pastThirtyDays}
                label={tc.pastThirtyDays}
                labelSub={tc.widgetPastThirtyDays}
                onClick={() => {
                  setDateOption({ ...dateOptionsFalse, pastThirtyDays: true });
                }}
              />
              <ToggleOption
                active={dateOption.past365Days}
                label={tc.past365Days}
                labelSub={tc.widgetPast365Days}
                onClick={() => {
                  setDateOption({ ...dateOptionsFalse, past365Days: true });
                }}
              />
            </div>
          </div>
          <div className="genericPopupContentWrapper__genericPopupContent__footer">
            <WidgetFooter
              buttonOneFunc={_saveWidget}
              disableButtonOne={subscriptionName?.length < 2}
              buttonTwoFunc={state.props.close}
              buttonTwoText={tc.cancel}
            />
          </div>
        </div>
      </div>
    </Popup>
  );
};

const MapStateToProps = (state, props) => {
  return {
    activity: state.activity,
    widgets: state.widgets,
    props: props,
  };
};

export default connect(MapStateToProps)(SaveActivityWidget);
