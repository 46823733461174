import React, { useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import LanguageContext from 'language-context';
import { excelHelper } from 'helpers';
import { getExcelDownload, getPriceForExcelDownload } from 'store/excel/tasks';
import { getSettings, updateSettings } from 'store/user/tasks';
import ActionButton from 'components/action_button';
import Collapsible from 'components/collapsible';
import Highlight from 'components/highlight';
import Icon from 'components/icon';
import Loading from 'components/loading';
import Menu from 'components/menu';
import Popup from 'components/popup';
import ToggleOption from 'components/toggle_option';
import WidgetFooter from 'components/widget_footer';
import WidgetHeader from 'components/widget_header';

/**
 * Component used for excel output in lists and fleet pages.
 *
 * Origin - From what source is the export initiated (lists or fleet).
 *          Determines if we go by list ids and build the search based on list
 *          criterias and/or list prospects, or if we go by a prospect id.
 *          For fleet origin we can also include 'koncern' param which includes
 *          all koncern companies for the export.
 *
 * Type - Car or user information, this determines how we search elastic.
 *        So if an export contains 10 prospects that owns 2 cars each,
 *        selecting "user" will result in 10 rows and selecting "car" will
 *        result in 20 rows.
 *        If they select type "car" and also select columns from the user columns
 *        (like Name, Postort etc.) we will charge for the 20 vehicle rows but
 *        also charge for 10 prospect rows since this information will be added to each row.
 *        If they have paid for name or management information in a list, we will
 *        give them discount for the "user" rows. See pricing in shared_helpers/cost_helper.js
 *
 * We have setup this component to work as templates. Selecting "Prospekt" template
 * is the same as setting type to "user", and selecting "Fordon" template is the same
 * as setting type to "car". After selecting this the user gets to select exactly which
 * fields/columns they want in the export. So they're not really a template. Reason is
 * we want to be able to have actual templates, and use the same system for both.
 * Currently we have one true template (Brevutskick) which sets type to "user" and a certain
 * predetermined columns to active.
 *
 * @param state.props.close - func - Close component.
 * @param state.props.koncern - bool (optional) - When sending koncern param to backend, applicable for fleet.
 * @param state.props.prospectId - string - When origin === 'fleet' this needs to be a prospect id.
 * @param state.props.selectedLists - array - When origin === 'lists' this needs to be an array of lists.
 * @param state.props.origin - string - 'fleet' | 'list'
 */
const Excel = (state) => {
  const [approveCost, setApproveCost] = useState(false);
  const [haveActiveUserColumns, setHaveActiveUserColumns] = useState(false);
  const [loading, setLoading] = useState(false);
  const [removeProspectsWithoutNames, setRemoveProspectsWithoutNames] =
    useState(false);
  const [responsiveClassWidth, setResponsiveClassWidth] = useState('');
  const [stage, setStage] = useState(1);
  const [selectedTemplate, setSelectedTemplate] = useState(null); // Current possible templates: "prospect", "vehicle", "mailings".
  const [columns, setColumns] = useState(null);
  const excelRef = useRef(null);
  const observer = useRef(null);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    _setResponsive();
    observer.current = new ResizeObserver(_setResponsive);
    observer.current.observe(excelRef.current);

    return () => {
      observer.current.unobserve(excelRef.current);
      setColumns(null);
      setStage(1);
      setSelectedTemplate(null);
      setLoading(false);
    };
  }, []);

  useEffect(() => {
    if (state.props.origin === 'fleet') {
      // For fleet we only allow vehicle rows so skip first step.
      setSelectedTemplate('vehicle');
      setStage(2);
    }
  }, [state.props.origin]);

  useEffect(() => {
    if (columns) {
      let columnsWithActiveValues = JSON.parse(JSON.stringify(columns));

      // Go ahead and remove unactive values and columns that has no active values.
      for (const prop in columnsWithActiveValues) {
        columnsWithActiveValues[prop] = columnsWithActiveValues[prop].filter(
          (num) => num.active
        );
        if (!columnsWithActiveValues[prop].length) {
          delete columnsWithActiveValues[prop];
        }
      }

      setHaveActiveUserColumns(!!columns.hasOwnProperty('userColumns'));
    }
  }, [columns]);

  useEffect(() => {
    _setActiveColumnsFromSettings();
  }, [state.user.settings, state.props.origin, selectedTemplate]);

  useEffect(() => {
    if (stage === 1) {
      _setActiveColumnsFromSettings();
    }
  }, [stage]);

  const _close = () => {
    if (typeof state.props.close === 'function') {
      state.props.close();
    }
  };

  const _convertToExcel = async () => {
    setLoading(true);
    let columnsWithActiveValues = JSON.parse(JSON.stringify(columns));

    // Remove unactive values and columns without any active values.
    for (const prop in columnsWithActiveValues) {
      columnsWithActiveValues[prop] = columnsWithActiveValues[prop].filter(
        (num) => num.active
      );
      if (!columnsWithActiveValues[prop].length) {
        delete columnsWithActiveValues[prop];
      }
    }

    await getExcelDownload({
      haveActiveUserColumns: haveActiveUserColumns,
      koncern: state.props.origin === 'fleet' ? state.props.koncern : null,
      listIds:
        state.props.origin === 'list'
          ? state.props.selectedLists.map((list) => list.id)
          : null,
      removeProspectsWithoutNames: removeProspectsWithoutNames,
      columns: columnsWithActiveValues,
      origin: state.props.origin,
      prospectId:
        state.props.origin === 'fleet' ? state.props.prospectId : null,
      type: selectedTemplate === 'vehicle' ? 'car' : 'user',
    });

    setLoading(false);
    setStage(4);
  };

  const _getPrice = async () => {
    let columnsWithActiveValues = JSON.parse(JSON.stringify(columns));

    // Go ahead and remove unactive values and columns that has no active values.
    for (const prop in columnsWithActiveValues) {
      columnsWithActiveValues[prop] = columnsWithActiveValues[prop].filter(
        (num) => num.active
      );
      if (!columnsWithActiveValues[prop].length) {
        delete columnsWithActiveValues[prop];
      }
    }

    await getPriceForExcelDownload({
      haveActiveUserColumns: haveActiveUserColumns,
      koncern: state.props.origin === 'fleet' ? !!state.props.koncern : null,
      prospectId:
        state.props.origin === 'fleet' ? +state.props.prospectId : null,
      listIds:
        state.props.origin === 'list' &&
        Array.isArray(state.props.selectedLists)
          ? state.props.selectedLists.map((list) => list.id)
          : null,
      origin: state.props.origin,
      removeProspectsWithoutNames:
        state.props.origin === 'list' ? removeProspectsWithoutNames : null,
      type:
        state.props.origin === 'fleet'
          ? 'car'
          : selectedTemplate === 'vehicle'
          ? 'car'
          : 'user',
    });
  };

  const _isSectionToggled = (section) => {
    return columns[section].find((num) => !num.active);
  };

  const _renderApproveCost = () => {
    return (
      <div className="excelWrapper__excel__content__summary__approveCost">
        <p>
          {tc.approveCostExcelInformationSummary1}{' '}
          {Number.isFinite(state.excel.rowPriceProspect) ? (
            state.excel.rowPriceProspect
          ) : (
            <Loading small={true} inline={true} />
          )}{' '}
          {tc.approveCostExcelInformationSummary2}
          {Number.isFinite(state.excel.rowPriceProspect) ? (
            state.excel.rowPriceProspect
          ) : (
            <Loading small={true} inline={true} />
          )}{' '}
          {tc.approveCostExcelInformationSummary3}
        </p>
        {state.props.origin === 'list' ? (
          <p>
            {tc.excelExcludeProspectsWithoutName1}{' '}
            <strong>
              {removeProspectsWithoutNames
                ? tc.exclude.toLowerCase()
                : tc.include.toLowerCase()}
            </strong>{' '}
            {tc.excelExcludeProspectsWithoutName2}
          </p>
        ) : null}
        <div className="excelWrapper__excel__content__summary__approveCost__button">
          <ToggleOption
            active={approveCost}
            label={tc.approveCost}
            labelSub={tc.approveCostExcel}
            onClick={() => {
              setApproveCost(!approveCost);
            }}
          />
        </div>
      </div>
    );
  };

  const _renderDownloadUrl = () => {
    return (
      <div className="excelWrapper__excel__content__summary__download">
        <p>{tc.excelExportPending}</p>
        <div className="excelWrapper__excel__content__summary__download__button">
          <ActionButton label={tc.close} onClick={_close} type="highlight" />
        </div>
      </div>
    );
  };

  const _renderPrice = () => {
    const priceIsSet =
      Number.isFinite(state.excel?.totalCost) &&
      (Number.isFinite(state.excel?.rowsProspects) ||
        Number.isFinite(state.excel?.rowsVehicles));

    return (
      <div className="excelWrapper__excel__content__summary__price">
        <span className="label">{tc.prices}</span>
        <div className="excelWrapper__excel__content__summary__price__row">
          {`${tc.orderedName}`}:{' '}
          {priceIsSet ? (
            `${state.excel.rowsOrderedName} ${tc.aPiece.toLowerCase()}`
          ) : (
            <Loading small={true} />
          )}
        </div>
        <div className="excelWrapper__excel__content__summary__price__row">
          {`${tc.orderedCompany}`}:{' '}
          {priceIsSet ? (
            `${state.excel.rowsOrderedManagement} ${tc.aPiece.toLowerCase()}`
          ) : (
            <Loading small={true} />
          )}
        </div>
        <div className="excelWrapper__excel__content__summary__price__row">
          {`${tc.pricePerRow} ${tc.prospect.toLowerCase()}`}:{' '}
          {priceIsSet ? (
            `${state.excel.rowPriceProspect} ${tc.swedishCrowns.toLowerCase()}`
          ) : (
            <Loading small={true} />
          )}
        </div>
        <div className="excelWrapper__excel__content__summary__price__row">
          {`${tc.pricePerRow} ${tc.vehicles.toLowerCase()}`}:{' '}
          {priceIsSet ? (
            `${state.excel.rowPriceVehicle} ${tc.swedishCrowns.toLowerCase()}`
          ) : (
            <Loading small={true} />
          )}
        </div>
        <div className="excelWrapper__excel__content__summary__price__row">
          {`${tc.rowsAmount} ${tc.prospects.toLowerCase()}`}:{' '}
          {priceIsSet ? state.excel.rowsProspects : <Loading small={true} />}
        </div>
        <div className="excelWrapper__excel__content__summary__price__row">
          {`${tc.rowsAmount} ${tc.vehicles.toLowerCase()}`}:{' '}
          {priceIsSet ? state.excel.rowsVehicles : <Loading small={true} />}
        </div>
        <div className="excelWrapper__excel__content__summary__price__row priceSum">
          {tc.priceSum}:{' '}
          {priceIsSet ? (
            `${state.excel.totalCost} ${tc.swedishCrowns.toLowerCase()}`
          ) : (
            <Loading small={true} />
          )}
        </div>
      </div>
    );
  };

  const _renderSelectedColumns = () => {
    const selectedFields = [];

    for (const prop in columns) {
      if (Array.isArray(columns[prop])) {
        columns[prop].forEach((num) => {
          if (num.active) {
            selectedFields.push(num.label);
          }
        });
      }
    }

    return (
      <div className="excelWrapper__excel__content__summary__selectedFields">
        <span className="label">{tc.selectedFields}</span>
        <div className="excelWrapper__excel__content__summary__selectedFields__items">
          {selectedFields.map((field, i) => (
            <span key={field + i}>{field}</span>
          ))}
        </div>
      </div>
    );
  };

  const _renderColumns = () => {
    const sections = [];
    let index = 0;
    for (const prop in columns) {
      index++;
      const content = (
        <div className="excelWrapper__excel__content__columns__bottom__section__content">
          {prop !== 'formatColumns' ? (
            <div
              className={
                _isSectionToggled(prop)
                  ? 'excelWrapper__excel__content__columns__bottom__section__content__toggleAll'
                  : 'excelWrapper__excel__content__columns__bottom__section__content__toggleAll active'
              }
              onClick={() => {
                _toggleSection(prop);
              }}
            >
              <Icon val={_isSectionToggled(prop) ? 'checkbox' : 'check'} />
              {_isSectionToggled(prop) ? tc.selectAll : tc.unselectAll}
            </div>
          ) : null}
          {columns[prop].map((num, i) => {
            return (
              <div
                className={
                  num.active
                    ? 'excelWrapper__excel__content__columns__bottom__section__content__item active'
                    : 'excelWrapper__excel__content__columns__bottom__section__content__item'
                }
                key={i}
                onClick={() => {
                  _toggleSelector(prop, num.val);
                }}
              >
                <Icon val={num.active ? 'check' : 'checkbox'} />
                {num.label}
              </div>
            );
          })}
        </div>
      );
      sections.push(
        <div
          className="excelWrapper__excel__content__columns__bottom__section"
          key={index}
        >
          <Collapsible
            content={content}
            header={excelHelper.getSectionHeading(prop)}
          />
        </div>
      );
    }

    return (
      <div className="excelWrapper__excel__content__columns">
        <div className="excelWrapper__excel__content__columns__top">
          <div className="excelWrapper__excel__content__columns__top__info">
            <p>{tc.excelInfoColumns}</p>
          </div>
          {state.props.origin === 'list' ? (
            <div className="excelWrapper__excel__content__columns__top__button">
              <ToggleOption
                active={removeProspectsWithoutNames}
                label={tc.removeProspectsWithoutNames}
                labelSub={tc.removeProspectsWithoutNamesInfo}
                onClick={() => {
                  setRemoveProspectsWithoutNames(!removeProspectsWithoutNames);
                }}
              />
            </div>
          ) : null}
        </div>
        <div className="excelWrapper__excel__content__columns__bottom">
          {sections}
        </div>
      </div>
    );
  };

  const _renderSelectTemplate = () => {
    return (
      <div className="excelWrapper__excel__content__selectTemplate">
        <div className="excelWrapper__excel__content__selectTemplate__info">
          <p>{tc.excelInfoGeneric}</p>
          <p>{tc.excelInfoSelectTemplate}</p>
        </div>
        <div className="excelWrapper__excel__content__selectTemplate__select">
          <Menu
            items={[
              {
                active: selectedTemplate === 'prospect',
                icon: 'prospect',
                label: tc.prospect,
                labelSub: tc.excelInfoProspectRows,
                onClick: () => {
                  setSelectedTemplate('prospect');
                },
                type: 'button',
              },
              {
                active: selectedTemplate === 'vehicle',
                icon: 'car',
                label: tc.vehicle,
                labelSub: tc.excelInfoVehicleRows,
                onClick: () => {
                  setSelectedTemplate('vehicle');
                },
                type: 'button',
              },
              {
                active: selectedTemplate === 'mailings',
                icon: 'mail',
                label: tc.templateMailings,
                labelSub: tc.templateMailingsInfo,
                onClick: () => {
                  setSelectedTemplate('mailings');
                  setRemoveProspectsWithoutNames(true);
                },
                type: 'button',
              },
            ]}
            type="large"
          />
        </div>
      </div>
    );
  };

  const _renderSummary = () => {
    return (
      <div className="excelWrapper__excel__content__summary">
        {_renderSelectedColumns()}
        {_renderPrice()}
        {_renderApproveCost()}
      </div>
    );
  };

  /**
   * Update state and also save/remove fields to settings.
   *
   * @param section
   */
  const _toggleSection = async (section) => {
    let columnsCloned = JSON.parse(JSON.stringify(columns));

    if (columnsCloned[section].find((num) => !num.active)) {
      // Toggle all to checked.
      columnsCloned[section].map((num) => (num.active = true));
    } else {
      // Toggle all to unchecked.
      columnsCloned[section].map((num) => (num.active = false));
    }

    // Settings array only holds "val".
    let newSettingsArr = [];
    for (const prop in columnsCloned) {
      if (Array.isArray(columnsCloned[prop])) {
        newSettingsArr = newSettingsArr.concat(
          columnsCloned[prop].filter((num) => num.active).map((num) => num.val)
        );
      }
    }

    let settings = {
      ...state.user.settings.settings,
      excel: {
        ...state.user.settings.settings.excel,
        [state.props.origin]: newSettingsArr,
      },
    };

    setColumns(columnsCloned);
    await updateSettings({
      settings: settings,
      skipGetSettings: true,
    });
    getSettings();
  };

  /**
   * Update state and also save/remove field to settings.
   *
   * @param section
   * @param val
   */
  const _toggleSelector = async (section, val) => {
    let columnsCloned = JSON.parse(JSON.stringify(columns));

    if (Array.isArray(columnsCloned[section])) {
      columnsCloned[section].map((num) => {
        if (num.val === val) {
          num.active = !num.active;
        }
        return num;
      });
    }

    // Settings array only holds "val".
    let newSettingsArr = [];
    for (const prop in columnsCloned) {
      if (Array.isArray(columnsCloned[prop])) {
        newSettingsArr = newSettingsArr.concat(
          columnsCloned[prop].filter((num) => num.active).map((num) => num.val)
        );
      }
    }

    let settings = {
      ...state.user.settings.settings,
      excel: {
        ...state.user.settings.settings.excel,
        [state.props.origin]: newSettingsArr,
      },
    };

    setColumns(columnsCloned);
    await updateSettings({
      settings: settings,
      skipGetSettings: true,
    });
    getSettings();
  };

  const _setActiveColumnsFromSettings = () => {
    let columnsToggled = excelHelper.getColumns(selectedTemplate);

    if (selectedTemplate === 'mailings') {
      // For templates, set all fields to active.
      for (const prop in columnsToggled) {
        if (Array.isArray(columnsToggled[prop])) {
          columnsToggled[prop].map((num) => {
            num.active = true;
            return num;
          });
        }
      }
    } else if (
      state.user.settings?.settings?.excel &&
      Array.isArray(state.user.settings.settings.excel[state.props.origin])
    ) {
      // Set active fields from user settings.
      for (const prop in columnsToggled) {
        if (Array.isArray(columnsToggled[prop])) {
          columnsToggled[prop].map((num) => {
            num.active = !!state.user.settings.settings.excel[
              state.props.origin
            ].includes(num.val);
            return num;
          });
        }
      }
    }

    setColumns(columnsToggled);
  };

  const _setResponsive = () => {
    _setResponsiveWidth();
  };

  const _setResponsiveWidth = () => {
    if (!excelRef?.current) {
      return;
    }

    const width = excelRef.current.getBoundingClientRect().width;
    const breakpoint1 = 600;

    if (width <= breakpoint1) {
      setResponsiveClassWidth('excelResponsiveWidth1');
    } else if (width > breakpoint1) {
      setResponsiveClassWidth('');
    }
  };

  return (
    <Popup close={_close} size={stage === 1 || stage === 2 ? 'big' : 'medium'}>
      <div className={`excelWrapper ${responsiveClassWidth}`} ref={excelRef}>
        <div className="excelWrapper__excel">
          <div className="excelWrapper__excel__header">
            <WidgetHeader
              headline={tc.excelOutput}
              headlineSub={
                stage === 4 && state.excel.downloadUrl ? null : loading ? (
                  `${tc.preparingDownload}`
                ) : !!columns ? (
                  tc.excelOutputInfo
                ) : (
                  <Loading small={true} />
                )
              }
            />
          </div>
          {loading ? (
            <Loading />
          ) : (
            <>
              {' '}
              <div className="excelWrapper__excel__content">
                {state.props.origin === 'list' ? (
                  <div className="excelWrapper__excel__content__target">
                    <Highlight
                      text={`${
                        tc.excelOutput
                      } ${tc.for.toLowerCase()} ${tc.lists.toLowerCase()}: ${
                        state.props.selectedLists
                          ? state.props.selectedLists
                              .map((num) => num.name)
                              .join(',')
                          : null
                      }`}
                    />
                  </div>
                ) : null}
                {!!columns
                  ? stage === 1
                    ? _renderSelectTemplate()
                    : stage === 2
                    ? _renderColumns()
                    : stage === 3
                    ? _renderSummary()
                    : stage === 4
                    ? _renderDownloadUrl()
                    : null
                  : null}
              </div>
              <div className="excelWrapper__excel__footer">
                {stage === 1 ? (
                  <WidgetFooter
                    disableButtonOne={selectedTemplate === null}
                    buttonOneFunc={() => {
                      if (selectedTemplate === 'mailings') {
                        _getPrice();
                        setStage(3);
                      } else {
                        setStage(2);
                      }
                    }}
                    buttonOneText={tc.next}
                    buttonTwoFunc={_close}
                    buttonTwoText={tc.cancel}
                  />
                ) : stage === 2 ? (
                  <WidgetFooter
                    buttonOneFunc={() => {
                      _getPrice();
                      setStage(3);
                    }}
                    buttonOneText={tc.next}
                    buttonTwoFunc={() => {
                      setSelectedTemplate(null);
                      setStage(1);
                    }}
                    buttonTwoText={tc.goBack}
                    disableButtonTwo={state.props.origin === 'fleet'}
                  />
                ) : stage === 3 ? (
                  <WidgetFooter
                    buttonOneFunc={_convertToExcel}
                    buttonOneText={tc.downloadAndOrderExcel}
                    disableButtonOne={
                      !Number.isFinite(state.excel.totalCost) || !approveCost
                    }
                    buttonTwoFunc={() => {
                      if (selectedTemplate === 'mailings') {
                        setSelectedTemplate(null);
                        setStage(1);
                      } else {
                        setStage(2);
                      }
                    }}
                    buttonTwoText={tc.goBack}
                  />
                ) : null}
              </div>
            </>
          )}
        </div>
      </div>
    </Popup>
  );
};

const MapStateToProps = (state, props) => {
  return {
    excel: state.excel,
    props: props,
    user: state.user,
  };
};

export default connect(MapStateToProps)(Excel);
