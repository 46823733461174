import { Dropdown, DropdownItem, NewDropdown } from 'components/dropdown';
import Icon from 'components/icon';
import Tooltip from 'components/tooltip';
import LanguageContext from 'language-context';
import React, { useContext } from 'react';

/**
 *
 * @param {{scripts: any[], activeScriptId: string | null, setActiveScriptId: () => void,
 * setEditMode: () => void, handleNewClick: () => void }} props
 * @description Renders a view where you can select and read scripts.
 */
const ScriptReadView = ({
  scripts = [],
  activeScriptId,
  setActiveScriptId,
  setEditMode,
  handleNewClick,
}) => {
  const tc = useContext(LanguageContext);
  const activeScript = scripts?.find((script) => script.id === activeScriptId);

  return (
    <div className="scriptWrapper__read">
      <div className="scriptWrapper__read__top">
        {activeScript ? (
          <Tooltip tooltipContent={tc.editScript} placement="top">
            <Icon
              style={{ cursor: 'pointer' }}
              val="edit"
              onClick={() => setEditMode(true)}
            />
          </Tooltip>
        ) : null}

        <NewDropdown
          header={{
            label:
              scripts?.find((script) => script._id === activeScriptId)?.title ||
              tc.chooseScript,
          }}
          closeOnItemClick={true}
          displayValue={
            scripts?.find((script) => script.id === activeScriptId)?.title ||
            tc.chooseScript
          }
          children={[]
            .concat(
              scripts?.map((script) => {
                return (
                  <DropdownItem
                    key={script.id}
                    active={activeScriptId === script.id}
                    label={script.title}
                    onClick={() => {
                      setActiveScriptId(script.id);
                    }}
                  />
                );
              })
            )
            .concat(
              <DropdownItem
                key={'new-script'}
                active={false}
                hideCheckbox={true}
                label={tc.newScript}
                onClick={() => handleNewClick()}
              />
            )}
        ></NewDropdown>
      </div>
      <div className="scriptWrapper__read__bottom">
        {activeScript ? (
          <div className="scriptWrapper__read__bottom__textFields">
            <p className="scriptWrapper__read__bottom__textFields__title">
              {activeScript.title}
            </p>
            <p className="scriptWrapper__read__bottom__textFields__text">
              {activeScript.text}
            </p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ScriptReadView;
