import { activityHelper } from 'helpers/activity_helper';

const widgetHelper = {
  get DEFAULT_GRID_VIEW_ID() {
    return 'grid_default';
  },
  defaultWidgetTypes: ['news', 'leads', 'notifications'],
  widgetTypes: [
    'activities',
    'analyseCar',
    'analyseSales',
    'leads',
    'monitorList',
    'news',
    'notifications',
    'pipeline',
    'activityCompletion',
    'activityDeals',
  ],

  /**
   * @description widgetLimits[widgetType][layout][parameter]
   * @returns value in columns/rows of the grid
   */

  getWidgetLimits: (type, breakpoint) => {
    const breakpoints = ['lg', 'md', 'sm', 'xs', 'xxs'];

    const defaultSizeObject = {
      minW: 1,
      minH: 1,
      maxW: Infinity,
      maxH: Infinity,
      defaultW: 8,
      defaultH: 12,
    };
    if (!widgetHelper.widgetTypes.includes(type)) {
      return defaultSizeObject;
    }
    if (!breakpoint || !breakpoints.includes(breakpoint)) {
      return defaultSizeObject;
    }

    if (widgetHelper.widgetLimits[type]?.[breakpoint]) {
      return widgetHelper.widgetLimits[type][breakpoint];
    } else return defaultSizeObject;
  },

  widgetLimits: {
    activities: {
      lg: {
        minW: 1,
        maxW: Infinity,
        minH: 1,
        maxH: Infinity,
        defaultW: 10,
        defaultH: 14,
      },
      md: {
        minW: 1,
        maxW: Infinity,
        minH: 1,
        maxH: Infinity,
        defaultW: 8,
        defaultH: 12,
      },
      sm: {
        minW: 1,
        maxW: Infinity,
        minH: 1,
        maxH: Infinity,
        defaultW: 8,
        defaultH: 12,
      },
      xs: {
        minW: 1,
        maxW: Infinity,
        minH: 1,
        maxH: Infinity,
        defaultW: 10,
        defaultH: 14,
      },
      xxs: {
        minW: 1,
        maxW: Infinity,
        minH: 1,
        maxH: Infinity,
        defaultW: 8,
        defaultH: 12,
      },
    },
    analyseCar: {
      lg: {
        minW: 1,
        maxW: Infinity,
        minH: 1,
        maxH: Infinity,
        defaultW: 10,
        defaultH: 12,
      },
      md: {
        minW: 1,
        maxW: Infinity,
        minH: 1,
        maxH: Infinity,
        defaultW: 8,
        defaultH: 10,
      },
      sm: {
        minW: 1,
        maxW: Infinity,
        minH: 1,
        maxH: Infinity,
        defaultW: 8,
        defaultH: 10,
      },
      xs: {
        minW: 1,
        maxW: Infinity,
        minH: 1,
        maxH: Infinity,
        defaultW: 10,
        defaultH: 12,
      },
      xxs: {
        minW: 1,
        maxW: Infinity,
        minH: 1,
        maxH: Infinity,
        defaultW: 8,
        defaultH: 10,
      },
    },
    analyseSales: {
      lg: {
        minW: 1,
        maxW: Infinity,
        minH: 1,
        maxH: Infinity,
        defaultW: 10,
        defaultH: 12,
      },
      md: {
        minW: 1,
        maxW: Infinity,
        minH: 1,
        maxH: Infinity,
        defaultW: 8,
        defaultH: 10,
      },
      sm: {
        minW: 1,
        maxW: Infinity,
        minH: 1,
        maxH: Infinity,
        defaultW: 8,
        defaultH: 10,
      },
      xs: {
        minW: 1,
        maxW: Infinity,
        minH: 1,
        maxH: Infinity,
        defaultW: 10,
        defaultH: 12,
      },
      xxs: {
        minW: 1,
        maxW: Infinity,
        minH: 1,
        maxH: Infinity,
        defaultW: 8,
        defaultH: 10,
      },
    },
    leads: {
      lg: {
        minW: 1,
        maxW: Infinity,
        minH: 1,
        maxH: Infinity,
        defaultW: 10,
        defaultH: 10,
      },
      md: {
        minW: 1,
        maxW: Infinity,
        minH: 1,
        maxH: Infinity,
        defaultW: 8,
        defaultH: 10,
      },
      sm: {
        minW: 1,
        maxW: Infinity,
        minH: 1,
        maxH: Infinity,
        defaultW: 8,
        defaultH: 10,
      },
      xs: {
        minW: 1,
        maxW: Infinity,
        minH: 1,
        maxH: Infinity,
        defaultW: 10,
        defaultH: 10,
      },
      xxs: {
        minW: 1,
        maxW: Infinity,
        minH: 1,
        maxH: Infinity,
        defaultW: 8,
        defaultH: 10,
      },
    },
    monitorlist: {
      lg: {
        minW: 1,
        maxW: Infinity,
        minH: 1,
        maxH: Infinity,
        defaultW: 10,
        defaultH: 10,
      },
      md: {
        minW: 1,
        maxW: Infinity,
        minH: 1,
        maxH: Infinity,
        defaultW: 8,
        defaultH: 10,
      },
      sm: {
        minW: 1,
        maxW: Infinity,
        minH: 1,
        maxH: Infinity,
        defaultW: 8,
        defaultH: 10,
      },
      xs: {
        minW: 1,
        maxW: Infinity,
        minH: 1,
        maxH: Infinity,
        defaultW: 10,
        defaultH: 10,
      },
      xxs: {
        minW: 1,
        maxW: Infinity,
        minH: 1,
        maxH: Infinity,
        defaultW: 8,
        defaultH: 10,
      },
    },
    news: {
      lg: {
        minW: 1,
        maxW: Infinity,
        minH: 1,
        maxH: Infinity,
        defaultW: 10,
        defaultH: 10,
      },
      md: {
        minW: 1,
        maxW: Infinity,
        minH: 1,
        maxH: Infinity,
        defaultW: 8,
        defaultH: 10,
      },
      sm: {
        minW: 1,
        maxW: Infinity,
        minH: 1,
        maxH: Infinity,
        defaultW: 8,
        defaultH: 10,
      },
      xs: {
        minW: 1,
        maxW: Infinity,
        minH: 1,
        maxH: Infinity,
        defaultW: 10,
        defaultH: 10,
      },
      xxs: {
        minW: 1,
        maxW: Infinity,
        minH: 1,
        maxH: Infinity,
        defaultW: 8,
        defaultH: 10,
      },
    },
    notifications: {
      lg: {
        minW: 1,
        maxW: Infinity,
        minH: 1,
        maxH: Infinity,
        defaultW: 10,
        defaultH: 14,
      },
      md: {
        minW: 1,
        maxW: Infinity,
        minH: 1,
        maxH: Infinity,
        defaultW: 8,
        defaultH: 12,
      },
      sm: {
        minW: 1,
        maxW: Infinity,
        minH: 1,
        maxH: Infinity,
        defaultW: 8,
        defaultH: 12,
      },
      xs: {
        minW: 1,
        maxW: Infinity,
        minH: 1,
        maxH: Infinity,
        defaultW: 10,
        defaultH: 14,
      },
      xxs: {
        minW: 1,
        maxW: Infinity,
        minH: 1,
        maxH: Infinity,
        defaultW: 8,
        defaultH: 12,
      },
    },
    pipeline: {
      lg: {
        minW: 1,
        maxW: Infinity,
        minH: 1,
        maxH: Infinity,
        defaultW: 10,
        defaultH: 10,
      },
      md: {
        minW: 1,
        maxW: Infinity,
        minH: 1,
        maxH: Infinity,
        defaultW: 8,
        defaultH: 10,
      },
      sm: {
        minW: 1,
        maxW: Infinity,
        minH: 1,
        maxH: Infinity,
        defaultW: 8,
        defaultH: 10,
      },
      xs: {
        minW: 1,
        maxW: Infinity,
        minH: 1,
        maxH: Infinity,
        defaultW: 10,
        defaultH: 10,
      },
      xxs: {
        minW: 1,
        maxW: Infinity,
        minH: 1,
        maxH: Infinity,
        defaultW: 8,
        defaultH: 10,
      },
    },
  },
  breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
  cols: { lg: 20, md: 16, sm: 12, xs: 10, xxs: 8 },
  parseWidgetDate: (payload) => {
    if (!payload) {
      console.error(
        'Missing params in widgetHelper.parseWidgetDate() : ',
        payload
      );
      return '';
    }
    let date = payload.date ?? {};
    if (payload.pastThirtyDays) {
      date = activityHelper.getPastThirtyDaysObj();
    }
    if (payload.past365Days) {
      date = activityHelper.getPast365DaysObj();
    }
    if (payload.currentMonth) {
      // If currentMonth, set dates accordingly.
      date = activityHelper.getCurrentMonthDateObj();
    }

    if (payload.currentWeek) {
      // If currentWeek, set dates accordingly.
      date = activityHelper.getCurrentWeekDateObj();
    }

    if (payload.previousMonth) {
      // If previousMonth, set dates accordingly.
      date = activityHelper.getPreviousMonthDateObj();
    }

    if (payload.previousWeek) {
      // If previousWeek, set dates accordingly.
      date = activityHelper.getPreviousWeekDateObj();
    }

    if (!date.from) {
      const now = new Date();
      date.from = new Date(
        now.setFullYear(
          now.getFullYear() - activityHelper.getDefaultFromYears()
        )
      ).toISOString();
    }

    if (!date.to) {
      date.to = new Date().toISOString();
    }

    return date;
  },

  generateLayoutForNewUser: (widgets) => {
    const breakpoint = 'lg';
    if (!widgets || !Array.isArray(widgets) || widgets.length === 0) return [];
    const activeWidgets = widgets.filter(
      (widget) => widget?.dashboards?.dashboard?.active
    );
    let currentX = 0;
    let currentY = 0;
    let nextY = 0;
    let highestY = 0;
    let columns = widgetHelper.cols[breakpoint];
    let layout = [];

    for (let i = 0; i < activeWidgets.length; i++) {
      const { defaultW, defaultH } = widgetHelper.getWidgetLimits(
        activeWidgets[i].type,
        breakpoint
      );
      // widgetHelper.widgetLimits[
      //   activeWidgets[i].type
      // ]?.[breakpoint]
      //   ? widgetHelper.widgetLimits[activeWidgets[i].type][breakpoint]
      //   : defaultSizeObject;
      if (currentY + defaultH > highestY) {
        highestY = currentY + defaultH;
      }
      if (widgetHelper.hasEnoughSpace(columns, currentX, defaultW)) {
        layout.push({
          i: activeWidgets[i]._id,
          x: currentX,
          y: currentY,
          w: defaultW,
          h: defaultH,
        });
        currentX += defaultW;

        if (nextY < currentY + defaultH) {
          nextY = currentY + defaultH;
        }
      } else {
        currentX = 0;
        currentY = nextY;
        i--;
        continue;
      }
    }
    return layout;
  },

  /**
   *
   * @param {*} widgets
   * @param {*} configuration
   * @param {"lg" | "md" | "sm" | "xs" | "xxs"} breakpoint
   * @description generates a default layout by checking if there is enough space on the current row,
   * if there is it places the widget there, otherwise moves to the next Y coordinate, sets currentX = 0, and continues placing widgets
   * until there are no active widgets left.
   * @returns layout array for current breakpoint
   */
  generateDefaultLayouts: (
    widgets,
    configuration = widgetHelper.DEFAULT_GRID_VIEW_ID,
    breakpoint = 'xxs'
  ) => {
    if (!widgets || !Array.isArray(widgets)) return;
    const activeWidgets = widgets.filter(
      (widget) => widget?.dashboards?.[configuration]?.active
    );
    let currentX = 0;
    let currentY = 0;
    let nextY = 0;
    let highestY = 0;
    let columns = widgetHelper.cols[breakpoint];
    let layout = [];

    for (let i = 0; i < activeWidgets.length; i++) {
      const { defaultW, defaultH } = widgetHelper.getWidgetLimits(
        activeWidgets[i].type,
        breakpoint
      );
      if (currentY + defaultH > highestY) {
        highestY = currentY + defaultH;
      }
      if (widgetHelper.hasEnoughSpace(columns, currentX, defaultW)) {
        layout.push({
          i: activeWidgets[i]._id,
          x: currentX,
          y: currentY,
          w: defaultW,
          h: defaultH,
        });
        currentX += defaultW;

        if (nextY < currentY + defaultH) {
          nextY = currentY + defaultH;
        }
      } else {
        currentX = 0;
        currentY = nextY;
        i--;
        continue;
      }
    }
    return layout;
  },

  /**
   *
   * @param {number} columns - number of columns that make up the entire grid
   * @param {number} currentX - column where current widget will start
   * @param {number} width - width of current widget
   * @returns
   */
  hasEnoughSpace: (columns, currentX, width) => {
    if (currentX + width <= columns) return true;
    else return false;
  },

  getActiveIdsFromWidgets: (widgets, configuration) => {
    return widgets.reduce((acc, widget) => {
      return widget.dashboards?.[configuration]?.active
        ? [...acc, widget._id]
        : acc;
    }, []);
  },

  /**
   *
   * @param {*} layouts
   * @returns a grid layout object with only the i,x,y,w,h properties
   */
  cleanLayouts: (layouts) => {
    if (!layouts) return null;
    let cleanedLayouts = {};

    for (const [breakpoint, layout] of Object.entries(layouts)) {
      cleanedLayouts[breakpoint] = layout.map((child) => ({
        i: child.i,
        x: child.x,
        y: child.y,
        w: child.w,
        h: child.h,
      }));
    }
    return cleanedLayouts;
  },
  /**
   *
   * @param {*} unboundLayouts
   * @param {*} widgets
   * @returns array of layouts with minW, maxW, minH, maxH properties added to each widget
   * @description adds min/max values for height and width
   */
  bindLayouts: (unboundLayouts, widgets) => {
    let metaById = widgets.reduce((acc, widget) => {
      acc[widget._id] = widgetHelper.widgetLimits[widget.type]; // breakpoints widgetLimits
      return acc;
    }, {});

    // now we have the min/max values for each widget _id, so we can dress-up the layouts with that data
    let boundLayouts = {};

    // make sure we aren't using any widgets that have been deleted
    for (const [layoutKey, layout] of Object.entries(unboundLayouts)) {
      unboundLayouts[layoutKey] = layout?.filter((widget) =>
        Object.keys(metaById).includes(widget.i)
      );
    }

    for (const [key, value] of Object.entries(unboundLayouts)) {
      boundLayouts[key] = value.map((child) => {
        return {
          ...child,
          ...metaById[child.i]?.[key],
        };
      });
    }
    if (boundLayouts?.lg?.some((child) => child.w === 1)) {
      console.log('re-setter found!');
    }
    return boundLayouts;
  },

  /**
   * Creates an object like:
   * {key<configuration/gridView>: _id[], ...}
   * Can be useful for filtering out widgets that are not in the current grid view for generating a default layouts object.
   */
  passFilter: (widgets) => {
    if (!Array.isArray(widgets) || widgets.length === 0) {
      return {};
    }
    return widgets.reduce((acc, widget) => {
      for (const [view, options] of Object.entries(widget.dashboards)) {
        if (options.active) {
          acc[view] = acc.hasOwnProperty(view)
            ? [...acc[view], widget._id]
            : [widget._id];
        } else {
          acc[view] = acc.hasOwnProperty(view) ? [...acc[view]] : [];
        }
      }
      return acc;
    }, {});
  },
};

export { widgetHelper };
