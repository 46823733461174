import React, { useContext, useEffect, useState } from 'react';
import LanguageContext from 'language-context';
import listHelper from 'shared_helpers/list_helper';
import { connect } from 'react-redux';
import { createListSubscription } from 'store/lists/tasks';
import { createAndReturnMailingsBlob } from 'store/orders/tasks';
import { convertPdfUrlToBlob } from 'store/pdf/tasks';
import { showFlashMessage } from 'store/flash_messages/tasks';
import ActionButton from 'components/action_button';
import Highlight from 'components/highlight';
import Icon from 'components/icon';
import IconStyled from 'components/icon_styled';
import Info from 'components/info';
import Loading from 'components/loading';
import Menu from 'components/menu';
import PdfArchiveManageFiles from 'components/pdf_archive_manage_files';
import PdfPreview from 'components/pdf_preview';
import Popup from 'components/popup';
import WidgetFooter from 'components/widget_footer';
import WidgetHeader from 'components/widget_header';
import { miscHelper } from 'helpers';

/**
 * Render a component to create list subscriptions.
 *
 * @param state.props.close - func
 * @param state.props.lists - array - Array with list objects.
 * @param state.props.mailings.color - bool (optional) - When sending in a default value for this.
 * @param state.props.mailings.postage - "A" | "D" (optional) - When sending in a default value for this.
 * @param state.props.mailings.s3Key - string (optional) - When sending in a default value for this. If this is provided, s3SignedUrl has to be provided.
 * @param state.props.mailings.s3SignedUrl - string (optional) - When sending in a default value for this. If this is provided, s3Key has to be provided.
 * @param state.props.mailings.simplexDuplex - "D" | "S" (optional) - When sending in a default value for this.
 * @param state.props.mailings.type - "envelope" | "postcard" (optional) - When sending in a default value for this.
 * @param state.props.source - string (optional) - Right now used to signal when component is used in "orders", if so adjust text information.
 * @param state.props.subscribeFlag - number (optional) - When sending in a default value for this.
 */
const CreateListSubscription = (state) => {
  const [fileIsUserApproved, setFileIsUserApproved] = useState(false);
  const [mailings, setMailings] = useState({
    color: true,
    postage: 'A',
    previewPdf: null,
    previewPdfLoading: false,
    s3Key: null,
    s3SignedUrl: null,
    simplexDuplex: 'D',
    totalPages: 2, // Calculated by PDF preview.
    type: 'envelope',
    validation: {
      text: null,
      validated: false,
    },
    mailingsType: 'user',
  });
  const [invalidLists, setInvalidLists] = useState([]);
  const [lists, setLists] = useState([]);
  const [subscribeFlag, setSubscribeFlag] = useState(0);
  const [subsciptionInterval, setSubscriptionInterval] = useState('weekly');
  const [limitProspects, setLimitProspects] = useState(false);
  const [prospectLimit, setProspectLimit] = useState(50);
  const [prospectPrio, setProspectPrio] = useState('random');
  const [excludeMethod, setExcludeMethod] = useState('standard');
  const [excludeExpiration, setExcludeExpiration] = useState(null);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    if (state.props.mailings && Object.keys(state.props.mailings).length) {
      let result = mailings;

      if (state.props.mailings.hasOwnProperty('color')) {
        result.color = !!state.props.mailings.color;
      }

      if (
        state.props.mailings.postage &&
        (state.props.mailings.postage === 'A' ||
          state.props.mailings.postage === 'B')
      ) {
        result.postage = state.props.mailings.postage;
      }

      if (state.props.mailings.s3Key) {
        result.s3Key = state.props.mailings.s3Key;
      }

      if (state.props.mailings.s3SignedUrl) {
        result.s3SignedUrl = state.props.mailings.s3SignedUrl;
      }

      if (
        state.props.mailings.simplexDuplex &&
        (state.props.mailings.simplexDuplex === 'D' ||
          state.props.mailings.simplexDuplex === 'S')
      ) {
        result.simplexDuplex = state.props.mailings.simplexDuplex;
      }

      if (
        state.props.mailings.type &&
        (state.props.mailings.type === 'envelope' ||
          state.props.mailings.type === 'postcard')
      ) {
        result.type = state.props.mailings.type;
      }

      if (result.type === 'postcard') {
        // For current supplier of postcard postage is always "B".
        result.postage = 'B';
      }

      setMailings(result);
    }
  }, [state.props.mailings]);

  useEffect(() => {
    if (Number.isFinite(state.props.subscribeFlag)) {
      setSubscribeFlag(state.props.subscribeFlag);
    }
  }, [state.props.subscribeFlag]);

  useEffect(() => {
    if (Array.isArray(state.props.lists)) {
      // Check which lists already has subscription id.
      // As of now we only allow one subscription per list.
      let invalid = [];
      let valid = [];
      state.props.lists.forEach((num) => {
        if (num?.meta?.subscription_ids?.length) {
          invalid = invalid.concat(num);
        } else if (num) {
          valid = valid.concat(num);
        }
      });

      setLists(valid);
      setInvalidLists(invalid);
    }
  }, [state.props.lists]);

  const _createListSubscription = async () => {
    if (lists.length) {
      let payload = {
        listIds: state.props.lists.map((num) => num.id),
        subscribeFlag: subscribeFlag,
      };

      if (subscribeFlag & listHelper.subscriptionFlags.mailings) {
        payload.mailings = {
          color: mailings.color,
          postage: mailings.postage,
          s3Key: mailings.s3Key,
          simplexDuplex: mailings.simplexDuplex,
          type: mailings.type,
          mailingsType: mailings.mailingsType,
        };
      }

      payload.options = {
        interval: subsciptionInterval,
        limitProspects: limitProspects ? { prospectLimit, prospectPrio } : null,
        excludeProspects:
          excludeMethod === 'timelimit' ? excludeExpiration : null,
      };

      await createListSubscription(payload);

      setSubscribeFlag(0);
      _resetMailings();
      setLists([]);
      setInvalidLists([]);
      state.props.close();
    }
  };

  const _createPreviewPdf = async () => {
    setMailings({
      ...mailings,
      previewPdfLoading: true,
      validation: {
        ...mailings.validation,
        text: null,
      },
    });

    let file = await convertPdfUrlToBlob(mailings.s3SignedUrl);
    const fileReader = new FileReader();
    if (fileReader && file) {
      // 8 MB limit.
      if (file.size / 1000000 > 5) {
        setMailings({
          ...mailings,
          previewPdfLoading: false,
          validation: {
            text: null,
            validated: false,
          },
        });
        return showFlashMessage(tc.fileIsTooBig5Mb, 'fail');
      }

      fileReader.readAsDataURL(file);
      fileReader.onload = async () => {
        if (fileReader.result.indexOf('data:application/pdf;base64,') === -1) {
          setMailings({
            ...mailings,
            previewPdfLoading: false,
            validation: {
              text: null,
              validated: false,
            },
          });
          return showFlashMessage(tc.wrongFileOnlyPdf, 'fail');
        }

        const previewBlob = await createAndReturnMailingsBlob({
          color: mailings.color,
          pdf: fileReader.result.replace('data:application/pdf;base64,', ''),
          postage: mailings.postage,
          s3Key: mailings.s3Key,
          simplexDuplex: mailings.simplexDuplex,
          type: mailings.type,
        });

        if (previewBlob?.sizeError) {
          setMailings({
            ...mailings,
            previewPdf: previewBlob,
            previewPdfLoading: false,
            validation: {
              text: tc.mailingsPreviewSizeWarning,
              validated: false,
            },
          });
        } else {
          setMailings({
            ...mailings,
            previewPdf: previewBlob,
            previewPdfLoading: false,
            validation: {
              text: null,
              validated: true,
            },
          });
        }
      };
    }
  };

  const _renderInvalidLists = () => {
    return (
      <>
        <div className="createListSubscriptionWrapper__createListSubscription__content">
          <div className="createListSubscriptionWrapper__createListSubscription__content__section">
            <div className="createListSubscriptionWrapper__createListSubscription__content__section__sectionHeader">
              <WidgetHeader
                headline={
                  lists.length > 1
                    ? tc.createListSubscriptions
                    : tc.createListSubscription
                }
              />
            </div>
            <div className="createListSubscriptionWrapper__createListSubscription__content__section__listHolder">
              <div className="createListSubscriptionWrapper__createListSubscription__content__section__listHolder__left">
                {invalidLists.length > 1
                  ? tc.invalidSubscriptionLists
                  : tc.invalidSubscriptionList}
                :
              </div>
              <div className="createListSubscriptionWrapper__createListSubscription__content__section__listHolder__right">
                {invalidLists.map((list, i) => {
                  if (i === invalidLists.length - 1) {
                    return <p key={i}>{list.name}</p>;
                  } else {
                    return (
                      <p key={i}>
                        {list.name}
                        <span className="bullet">&#8226;</span>
                      </p>
                    );
                  }
                })}
              </div>
            </div>
          </div>
          <div className="createListSubscriptionWrapper__createListSubscription__content__section__invalid">
            <Info>
              <h4>{tc.invalidSubscriptionLists}</h4>
              <p>{tc.invalidSubscriptionListsWhy}</p>
            </Info>
          </div>
        </div>
        {!lists.length ? (
          <div className="createListSubscriptionWrapper__createListSubscription__footer">
            <WidgetFooter
              buttonTwoFunc={state.props.close}
              buttonTwoText={tc.cancel}
            />
          </div>
        ) : null}
      </>
    );
  };

  const _renderSuboptionsLimitProspects = () => {
    return (
      <>
        <div className="createListSubscriptionWrapper__createListSubscription__content__section">
          <div className="createListSubscriptionWrapper__createListSubscription__content__section__sectionHeader">
            <WidgetHeader headline={tc.limitNumber} />
          </div>
          <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings">
            <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section">
              <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__header">
                <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__header__left">
                  1.
                </div>
                <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__header__right">
                  {tc.chooseProspectPrio}
                </div>
              </div>
              <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__content flexHolder">
                <ActionButton
                  active={prospectPrio === 'fleetSize'}
                  icon={prospectPrio === 'fleetSize' ? 'check' : null}
                  label={tc.fleetSize}
                  onClick={() =>
                    prospectPrio === 'fleetSize'
                      ? setProspectPrio(null)
                      : setProspectPrio('fleetSize')
                  }
                  type="regular"
                />
                <ActionButton
                  active={prospectPrio === 'random'}
                  icon={prospectPrio === 'random' ? 'check' : null}
                  label={tc.random}
                  onClick={() =>
                    prospectPrio === 'random'
                      ? setProspectPrio(null)
                      : setProspectPrio('random')
                  }
                  type="regular"
                />
              </div>
            </div>
            <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section">
              <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__header">
                <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__header__left">
                  1.
                </div>
                <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__header__right">
                  {tc.chooseNumberOfProspectsToGenerate}
                </div>
              </div>
              <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__content flexHolder">
                <input
                  type="text"
                  value={prospectLimit}
                  onChange={(e) => setProspectLimit(e.target.value)}
                  placeholder={tc.chooseNumberOfPRospects}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const _renderSuboptionsExcludeProspects = () => {
    return (
      <>
        <div className="createListSubscriptionWrapper__createListSubscription__content__section">
          <div className="createListSubscriptionWrapper__createListSubscription__content__section__sectionHeader">
            <WidgetHeader headline={tc.excludeProspects} />
          </div>
          <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings">
            <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section">
              <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__header">
                <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__header__left">
                  1.
                </div>
                <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__header__right">
                  {tc.chooseProspectExcludeMethod}
                </div>
              </div>
              <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__content flexHolder">
                <ActionButton
                  active={excludeMethod === 'standard'}
                  icon={excludeMethod === 'standard' ? 'check' : null}
                  label={tc.indefinitely}
                  onClick={() => setExcludeMethod('standard')}
                  type="regular"
                />
                <ActionButton
                  active={excludeMethod === 'timelimit'}
                  icon={excludeMethod === 'timelimit' ? 'check' : null}
                  label={tc.timelimit}
                  onClick={() =>
                    excludeMethod === 'timelimit'
                      ? setExcludeMethod('standard')
                      : setExcludeMethod('timelimit')
                  }
                  type="regular"
                />
              </div>
            </div>
            {excludeMethod === 'timelimit' && (
              <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section">
                <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__header">
                  <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__header__left">
                    2.
                  </div>
                  <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__header__right">
                    {tc.chooseNumberOfMonthsToExcludeProspect}
                  </div>
                </div>
                <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__content flexHolder">
                  <input
                    type="text"
                    value={excludeExpiration}
                    onChange={(e) => setExcludeExpiration(e.target.value)}
                    placeholder={tc.chooseNumberOfMonths}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  const _renderMailings = () => {
    return (
      <div className="createListSubscriptionWrapper__createListSubscription__content__section">
        <div className="createListSubscriptionWrapper__createListSubscription__content__section__sectionHeader">
          <WidgetHeader headline={tc.mailingsInput} />
        </div>
        <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings">
          <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__info">
            <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__info__left">
              <p>{tc.orderMailingsInstructionsInfo1}</p>
              <p>{tc.orderMailingsInstructionsInfo2}</p>
            </div>
            <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__info__right">
              <p>{tc.orderMailingsInstructionsInfoPdfValidation}</p>
              <p>{tc.orderMailingsInstructionsInfo3}</p>
              <div className="flexHolder">
                <ActionButton
                  icon="download"
                  label={`${tc.template} ${tc.envelope.toLowerCase()}`}
                  href="/files/brevutskick/blank_envelope.pdf"
                  type="regular"
                />
                <ActionButton
                  icon="download"
                  label={`${tc.template} ${tc.postcard.toLowerCase()}`}
                  href="/files/brevutskick/blank_postcard.pdf"
                  type="regular"
                />
              </div>
            </div>
          </div>
          <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section">
            <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__header">
              <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__header__left">
                1.
              </div>
              <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__header__right">
                {tc.chooseMailingsMode}
              </div>
            </div>
            <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__content">
              <div className="flexHolder">
                <ActionButton
                  active={mailings.type === 'envelope'}
                  icon={mailings.type === 'envelope' ? 'check' : null}
                  label={tc.envelope}
                  onClick={() => {
                    setFileIsUserApproved(false);
                    setMailings({
                      ...mailings,
                      previewPdf: null,
                      type: 'envelope',
                      validation: {
                        text: null,
                        validated: false,
                      },
                    });
                  }}
                  type="regular"
                />
                <ActionButton
                  active={mailings.type === 'postcard'}
                  icon={mailings.type === 'postcard' ? 'check' : null}
                  label={tc.postcard}
                  onClick={() => {
                    setFileIsUserApproved(false);
                    setMailings({
                      ...mailings,
                      previewPdf: null,
                      postage: 'B',
                      type: 'postcard',
                      validation: {
                        text: null,
                        validated: false,
                      },
                    });
                  }}
                  type="regular"
                />
              </div>
              <p style={{ margin: '10px 0 5px' }}>
                {tc.carMailingsTypeExplanation}
              </p>
              <div className="flexHolder">
                <ActionButton
                  active={mailings.mailingsType === 'user'}
                  icon={mailings.mailingsType === 'user' ? 'check' : null}
                  label={tc.everyUser}
                  onClick={() => {
                    setMailings({
                      ...mailings,
                      mailingsType: 'user',
                    });
                    setFileIsUserApproved(false);
                  }}
                  type="regular"
                />
                <ActionButton
                  disabled={
                    !state.props.lists?.every(
                      (list) => list.meta && list.meta.criterias
                    )
                  }
                  active={mailings.mailingsType === 'car'}
                  icon={mailings.mailingsType === 'car' ? 'check' : null}
                  label={tc.everyVehicle}
                  onClick={() => {
                    setMailings({
                      ...mailings,
                      mailingsType: 'car',
                    });
                    setFileIsUserApproved(false);
                  }}
                  type="regular"
                />
              </div>
            </div>
          </div>
          <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section">
            <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__header">
              <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__header__left">
                2.
              </div>
              <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__header__right">
                {tc.selectFile}
              </div>
            </div>
            <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__content">
              <PdfArchiveManageFiles
                onSelect={_selectPdf}
                preview={false}
                selectedS3Key={mailings.s3Key}
                share={false}
              />
            </div>
          </div>
          {/*{mailings.type === "postcard" ? (*/}
          {/*  <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section">*/}
          {/*    <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__header">*/}
          {/*      <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__header__left">*/}
          {/*        3.*/}
          {/*      </div>*/}
          {/*      <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__header__right">*/}
          {/*        {tc.add} {tc.textContent.toLowerCase()}*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*    <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__content">*/}
          {/*      <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__content__row">*/}
          {/*        <form autoComplete="off">*/}
          {/*          <input*/}
          {/*            onChange={(e) => {*/}
          {/*              setMailings({*/}
          {/*                ...mailings,*/}
          {/*                headline: e.target.value,*/}
          {/*              });*/}
          {/*            }}*/}
          {/*            placeholder={tc.headline}*/}
          {/*            type="text"*/}
          {/*            value={mailings.headline}*/}
          {/*          />*/}
          {/*        </form>*/}
          {/*      </div>*/}
          {/*      <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__content__row">*/}
          {/*        <textarea*/}
          {/*          rows="2"*/}
          {/*          onChange={(e) => {*/}
          {/*            setMailings({*/}
          {/*              ...mailings,*/}
          {/*              text: e.target.value,*/}
          {/*            });*/}
          {/*          }}*/}
          {/*          placeholder={tc.textContent}*/}
          {/*          value={mailings.text}*/}
          {/*        />*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*) : null}*/}
          <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section">
            <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__header">
              <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__header__left">
                3.
              </div>
              <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__header__right">
                {tc.customizeOrder}
              </div>
            </div>
            <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__content">
              <div className="postageItemWrapper">
                <div
                  className={`postageItemWrapper__postageItem ${
                    mailings.type === 'postcard' ? 'disabled' : ''
                  }`}
                  onClick={() => {
                    setMailings({
                      ...mailings,
                      postage: 'A',
                    });
                  }}
                >
                  <Icon val={mailings.postage === 'A' ? 'check' : 'checkbox'} />
                  <p>{tc.postageA}</p>
                </div>
                <div
                  className={`postageItemWrapper__postageItem ${
                    mailings.type === 'postcard' ? 'disabled' : ''
                  }`}
                  onClick={() => {
                    setMailings({
                      ...mailings,
                      postage: 'B',
                    });
                  }}
                >
                  <Icon
                    class="marginLeft"
                    val={mailings.postage === 'B' ? 'check' : 'checkbox'}
                  />
                  <p>{tc.postageB}</p>
                </div>
              </div>
              <div className="postageItemWrapper">
                <div
                  className="postageItemWrapper__postageItem"
                  onClick={() => {
                    setMailings({
                      ...mailings,
                      color: !mailings.color,
                    });
                  }}
                >
                  <Icon val={mailings.color ? 'check' : 'checkbox'} />
                  <p>{tc.color}</p>
                </div>
              </div>
              <div className="postageItemWrapper">
                {mailings.type === 'envelope' ? (
                  <div
                    className={`postageItemWrapper__postageItem ${
                      mailings.totalPages < 2 ? 'disabled' : ''
                    }`}
                    onClick={() => {
                      setMailings({
                        ...mailings,
                        simplexDuplex:
                          mailings.simplexDuplex === 'D' ? 'S' : 'D',
                      });
                    }}
                  >
                    <Icon
                      val={
                        mailings.simplexDuplex === 'D' ? 'check' : 'checkbox'
                      }
                    />
                    <p>{tc.doubleSided}</p>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section">
            <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__header">
              <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__header__left">
                4.
              </div>
              <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__header__right">
                {tc.checkResult}
              </div>
            </div>
            <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__content">
              <p>{tc.checkResultToCreateSubscription}</p>
              {mailings.previewPdfLoading ? (
                <Loading small={true} />
              ) : (
                <div className="flexHolder">
                  <ActionButton
                    disabled={!(mailings.s3Key || mailings.previewPdf)}
                    label={tc.reset}
                    onClick={() => {
                      _resetMailings();
                      setFileIsUserApproved(false);
                    }}
                    type="regular"
                  />
                  <ActionButton
                    disabled={!mailings.s3SignedUrl}
                    label={mailings.previewPdf ? tc.closePreview : tc.preview}
                    onClick={() => {
                      if (mailings.previewPdf) {
                        setMailings({
                          ...mailings,
                          previewPdf: null,
                        });
                      } else if (mailings.s3SignedUrl) {
                        _createPreviewPdf();
                      }
                    }}
                    type="regular"
                  />
                  <ActionButton
                    disabled={
                      !mailings.validation.validated ||
                      !mailings.s3Key ||
                      !mailings.s3SignedUrl ||
                      fileIsUserApproved
                    }
                    label={tc.saveFileForMailings}
                    onClick={() => {
                      setFileIsUserApproved(true);
                      setMailings({
                        ...mailings,
                        previewPdf: null,
                      });
                    }}
                    type="highlight"
                  />
                </div>
              )}
              {mailings.previewPdf ? (
                <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__content__row">
                  {!mailings.validation.text?.length ? (
                    <PdfPreview
                      source={mailings.previewPdf}
                      onLoadDocument={(totalPages) => {
                        if (totalPages < 2) {
                          setMailings({
                            ...mailings,
                            simplexDuplex: 'S',
                            totalPages: totalPages,
                          });
                        } else if (
                          totalPages > 2 &&
                          mailings.type === 'postcard'
                        ) {
                          setMailings({
                            ...mailings,
                            validation: {
                              text: tc.mailingsPreviewPagesWarning,
                              validated: false,
                            },
                          });
                        } else {
                          setMailings({
                            ...mailings,
                            simplexDuplex: 'D',
                            totalPages: totalPages,
                          });
                        }
                      }}
                      // onLoadPage={(obj) => {
                      //   // We already have a size check if the preview file is coming from our mailings backend.
                      //   // But we might want to preview files not generated from mailings backend, so do a size check here as well.

                      //   // Convert points to mm and check for correct size (A4: 210 x 297 mm, A5: 216 x 154 mm).
                      //   const pointToMM = 0.352777778; // 1 point = 0.352777778 mm
                      //   obj.width = obj.width * pointToMM;
                      //   obj.height = obj.height * pointToMM;
                      //   if (
                      //     (mailings.type === "envelope" &&
                      //       (obj.width > 211 ||
                      //         obj.width < 209 ||
                      //         obj.height > 298 ||
                      //         obj.height < 296)) ||
                      //     (mailings.type === "postcard" &&
                      //       (obj.width > 217 ||
                      //         obj.width < 215 ||
                      //         obj.height > 155 ||
                      //         obj.height < 153))
                      //   ) {
                      //     setMailings({
                      //       ...mailings,
                      //       validation: {
                      //         text: tc.mailingsPreviewSizeWarning,
                      //         validated: false,
                      //       },
                      //     });
                      //   } else {
                      //     setMailings({
                      //       ...mailings,
                      //       validation: {
                      //         text: null,
                      //         validated: true,
                      //       },
                      //     });
                      //   }
                      // }}
                    />
                  ) : (
                    <Info>
                      <h4>{tc.mailingsPreviewInvalidPDF}</h4>
                      <p>{mailings.validation.text}</p>
                    </Info>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const _renderSubscriptionInfo = () => {
    return (
      <div className="createListSubscriptionWrapper__createListSubscription__content__section__subscription__info">
        <p>{`${tc.listsSubscriptionsInfo1} ${tc.listsSubscriptionsInfo2}`}</p>
        <p>{tc.listsSubscriptionsInfo3}</p>
        {state.props.source === 'orders' ? (
          <Highlight
            text={`${tc.listsSubscriptionsInfo5} ${tc.listsSubscriptionsInfo6}`}
          />
        ) : null}
      </div>
    );
  };

  const _renderSubscriptionOptions = () => {
    return (
      <div className="createListSubscriptionWrapper__createListSubscription__content__section__subscription__options">
        <div className="createListSubscriptionWrapper__createListSubscription__content__section__subscription__options">
          <Menu
            items={[
              {
                active: !!(subscribeFlag & listHelper.subscriptionFlags.name),
                icon: 'person',
                label: tc.name,
                labelSub: tc.nameSubscriptionInfo,
                onClick: () => {
                  _toggleSubscribeFlagValue(listHelper.subscriptionFlags.name);
                },
                type: 'button',
              },
              {
                active: !!(subscribeFlag & listHelper.subscriptionFlags.phone),
                // disabled: !(subscribeFlag & listHelper.subscriptionFlags.name),
                icon: 'phone',
                label: tc.phoneNumbers,
                labelSub: tc.phoneNumbersSubscriptionInfo,
                onClick: () => {
                  _toggleSubscribeFlagValue(listHelper.subscriptionFlags.phone);
                },
                type: 'button',
              },
              {
                active: !!(
                  subscribeFlag & listHelper.subscriptionFlags.mailings
                ),
                // disabled: !(
                //   subscribeFlag & listHelper.subscriptionFlags.name &&
                //   subscribeFlag & listHelper.subscriptionFlags.excludeProspects
                // ),
                icon: 'mail',
                label: tc.mailings,
                labelSub: tc.mailingsSubscriptionInfo,
                onClick: () => {
                  _toggleSubscribeFlagValue(
                    listHelper.subscriptionFlags.mailings
                  );
                  _resetMailings();
                },
                type: 'button',
              },
              miscHelper.hasHubspotIntegrationPermission(state.user)
                ? {
                    active: !!(
                      subscribeFlag & listHelper.subscriptionFlags.hubspot
                    ),
                    icon: 'hub',
                    label: tc.hubspot,
                    labelSub: tc.hubspotExportData,
                    onClick: () => {
                      _toggleSubscribeFlagValue(
                        listHelper.subscriptionFlags.hubspot
                      );
                    },
                    type: 'button',
                  }
                : null,
            ]}
            type="large"
          />
        </div>
        <div className="createListSubscriptionWrapper__createListSubscription__content__section__subscription__options">
          <Menu
            items={[
              {
                active: subsciptionInterval === 'weekly',
                icon: 'schedule',
                label: tc.weekly,
                labelSub: tc.subscribeWeekly,
                onClick: () =>
                  subsciptionInterval === 'weekly'
                    ? null
                    : setSubscriptionInterval('weekly'),
                type: 'button',
              },
              {
                active: subsciptionInterval === 'monthly',
                icon: 'schedule',
                label: tc.monthly,
                labelSub: tc.subscribeMonthly,
                onClick: () =>
                  subsciptionInterval === 'monthly'
                    ? null
                    : setSubscriptionInterval('monthly'),
                type: 'button',
              },
            ]}
            type="large"
          />
        </div>
        <div className="createListSubscriptionWrapper__createListSubscription__content__section__subscription__options">
          <Menu
            items={[
              {
                active: !!(
                  subscribeFlag & listHelper.subscriptionFlags.excludeProspects
                ),
                icon: 'excludeProspects',
                label: tc.excludeProspects,
                labelSub: tc.excludeProspectsSubscriptionInfo,
                onClick: () => {
                  _toggleSubscribeFlagValue(
                    listHelper.subscriptionFlags.excludeProspects
                  );
                },
                type: 'button',
              },
              {
                active: limitProspects,
                icon: 'contentcut',
                label: tc.limitNumber,
                labelSub: tc.limitNumberOfProspectsToGenerate,
                onClick: () => setLimitProspects(!limitProspects),
                type: 'button',
              },
            ]}
            type="large"
          />
        </div>
      </div>
    );
  };

  const _renderSummary = () => {
    const subscriptionContent = [];

    if (subscribeFlag & listHelper.subscriptionFlags.excludeProspects)
      subscriptionContent.push(tc.excludeProspects);
    if (!(subscribeFlag & listHelper.subscriptionFlags.excludeProspects))
      subscriptionContent.push(tc.excludeProspectsNot);
    if (subscribeFlag & listHelper.subscriptionFlags.name)
      subscriptionContent.push(tc.nameOrder);
    if (subscribeFlag & listHelper.subscriptionFlags.phone)
      subscriptionContent.push(tc.phoneOrder);
    if (subscribeFlag & listHelper.subscriptionFlags.mailings)
      subscriptionContent.push(tc.mailingsOrder);
    if (subscribeFlag & listHelper.subscriptionFlags.hubspot)
      subscriptionContent.push(tc.hubspotOrder);
    if (subscribeFlag === 0 || subscribeFlag === 1)
      subscriptionContent.push(tc.noOrder);
    if (subsciptionInterval === 'weekly') subscriptionContent.push(tc.weekly);
    if (subsciptionInterval === 'monthly') subscriptionContent.push(tc.monthly);
    if (limitProspects && prospectLimit > 0 && prospectPrio)
      subscriptionContent.push(
        `${tc.limitNumberOfProspectsToGenerate} ${prospectLimit} ${tc.aPiece}. ${tc.priority} ${tc[prospectPrio]}`
      );

    let pdfName;
    if (mailings.s3Key && Array.isArray(state.pdf.userPdfs)) {
      const found = state.pdf.userPdfs.find(
        (num) => num.s3Key === mailings.s3Key
      );
      if (found) {
        pdfName = found.name;
      }
    }

    return (
      <div className="createListSubscriptionWrapper__createListSubscription__content__section">
        <div className="createListSubscriptionWrapper__createListSubscription__content__section__sectionHeader">
          <WidgetHeader headline={tc.summary} />
        </div>
        <div className="createListSubscriptionWrapper__createListSubscription__content__section__summary">
          {_subscriptionWillCostMoney() ? (
            <div className="createListSubscriptionWrapper__createListSubscription__content__section__summary__section">
              {tc.listsSubscriptionsInfo4}
            </div>
          ) : null}
          <div className="createListSubscriptionWrapper__createListSubscription__content__section__summary__section">
            <h4>{lists.length > 1 ? tc.lists : tc.list}</h4>
            <p>
              {lists.map((list, i) => {
                if (i === lists.length - 1) {
                  return <span key={i}>{list.name}</span>;
                } else {
                  return (
                    <span key={i}>
                      {list.name}
                      <span className="bullet">&#8226;</span>
                    </span>
                  );
                }
              })}
            </p>
          </div>
          <div className="createListSubscriptionWrapper__createListSubscription__content__section__summary__section">
            <h4>{tc.subscription}</h4>
            <p>
              {subscriptionContent.map((num, i) => {
                if (i === subscriptionContent.length - 1) {
                  return <span key={i}>{num}</span>;
                } else {
                  return (
                    <span key={i}>
                      {num}
                      <span className="bullet">&#8226;</span>
                    </span>
                  );
                }
              })}
            </p>
          </div>
          {subscribeFlag & listHelper.subscriptionFlags.mailings ? (
            <div className="createListSubscriptionWrapper__createListSubscription__content__section__summary__section">
              <h4>{tc.mailings}</h4>
              <div className="createListSubscriptionWrapper__createListSubscription__content__section__summary__section__item">
                <IconStyled
                  iconVal={`${
                    mailings.validation?.validated && fileIsUserApproved
                      ? 'check'
                      : 'exclamation'
                  }`}
                  class={`${
                    mailings.validation?.validated && fileIsUserApproved
                      ? 'successFill'
                      : 'fail'
                  }`}
                />
                {mailings.validation?.validated && fileIsUserApproved ? (
                  <>
                    <span className="marginRight">{tc.validFile}:</span>
                    <span>{pdfName}</span>
                  </>
                ) : (
                  <span>{tc.fileNotApproved}</span>
                )}
              </div>
              <div className="createListSubscriptionWrapper__createListSubscription__content__section__summary__section__item">
                <IconStyled iconVal="check" class="successFill" />
                <span>
                  {mailings.type === 'envelope' ? tc.envelope : tc.postcard}
                </span>
              </div>
              <div className="createListSubscriptionWrapper__createListSubscription__content__section__summary__section__item">
                {mailings.postage === 'A' ? (
                  <>
                    <IconStyled iconVal="check" class="successFill" />
                    <span>{tc.postageA}</span>
                  </>
                ) : (
                  <>
                    <IconStyled iconVal="check" class="successFill" />
                    <span>{tc.postageB}</span>
                  </>
                )}
              </div>
              <div className="createListSubscriptionWrapper__createListSubscription__content__section__summary__section__item">
                <IconStyled iconVal="check" class="successFill" />
                <span>
                  {mailings.color
                    ? tc.color
                    : `${tc.not} ${tc.color.toLowerCase()}`}
                </span>
              </div>
              {mailings.type === 'envelope' ? (
                <div className="createListSubscriptionWrapper__createListSubscription__content__section__summary__section__item">
                  <IconStyled iconVal="check" class="successFill" />
                  <span>
                    {mailings.simplexDuplex === 'D'
                      ? tc.doubleSided
                      : `${tc.not} ${tc.doubleSided.toLowerCase()}`}
                  </span>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const _resetMailings = () => {
    setMailings({
      color: true,
      postage: 'A',
      previewPdf: null,
      previewPdfLoading: false,
      s3Key: null,
      s3SignedUrl: null,
      simplexDuplex: 'D',
      totalPages: 2,
      type: 'envelope',
      validation: {
        text: null,
        validated: false,
      },
      mailingsType: 'user',
    });
    setFileIsUserApproved(false);
  };

  const _selectPdf = (pdf) => {
    setFileIsUserApproved(false);
    if (pdf?.s3Key && pdf.s3Key === mailings.s3Key) {
      setMailings({
        ...mailings,
        previewPdf: null,
        s3Key: null,
        s3SignedUrl: null,
        validation: {
          text: null,
          validated: false,
        },
      });
    } else {
      setMailings({
        ...mailings,
        previewPdf: null,
        s3Key: pdf.s3Key,
        s3SignedUrl: pdf.s3SignedUrl,
        validation: {
          text: null,
          validated: false,
        },
      });
    }
  };

  const _stateCheck = () => {
    return lists.length || invalidLists.length;
  };

  const _subscriptionWillCostMoney = () => {
    return (
      subscribeFlag & listHelper.subscriptionFlags.name ||
      (subscribeFlag & listHelper.subscriptionFlags.phone) |
        (subscribeFlag & listHelper.subscriptionFlags.mailings)
    );
  };

  /**
   * Where we toggle the subscriptions flags.
   * Note that phone order should only be possible when name order also is selected.
   * Note that mailings order should only be possible when name order and excludeProspects also is selected.
   *
   * @param val - bit flag
   */
  const _toggleSubscribeFlagValue = (val) => {
    let flag = subscribeFlag;

    // First check if name order was deselected, if so deselect phone and mailings as well.
    if (
      val === listHelper.subscriptionFlags.name &&
      flag & listHelper.subscriptionFlags.name
    ) {
      flag &= ~listHelper.subscriptionFlags.phone;
      flag &= ~listHelper.subscriptionFlags.mailings;
      flag &= ~listHelper.subscriptionFlags.hubspot;
    }

    // Then check if excludeProspects was deselected, if so deselect mailings as well.
    // if (
    //   val === listHelper.subscriptionFlags.excludeProspects &&
    //   flag & listHelper.subscriptionFlags.excludeProspects
    // ) {
    //   if (flag & listHelper.subscriptionFlags.mailings) {
    //     flag ^= listHelper.subscriptionFlags.mailings;
    //   }
    // }

    // Then toggle the new value.
    flag ^= val;

    // Check if phone is set without name & address, if missing add name order.
    if (flag & listHelper.subscriptionFlags.phone) {
      flag |= listHelper.subscriptionFlags.name; // Add name.
    }

    // Check if mailings is set without name & excludeProspects, if missing add it.
    if (flag & listHelper.subscriptionFlags.mailings) {
      flag |= listHelper.subscriptionFlags.name;
      //   if (~flag & listHelper.subscriptionFlags.excludeProspects) {
      //     flag ^= listHelper.subscriptionFlags.excludeProspects;
      //   }
    }

    if (val === listHelper.subscriptionFlags.mailings) {
      flag &= ~listHelper.subscriptionFlags.hubspot;
    }
    if (val === listHelper.subscriptionFlags.hubspot) {
      flag |= listHelper.subscriptionFlags.name;
      flag |= listHelper.subscriptionFlags.phone;
      flag &= ~listHelper.subscriptionFlags.mailings;
    }

    setSubscribeFlag(flag);
  };

  return (
    <Popup close={state.props.close} size="big">
      <div className="createListSubscriptionWrapper">
        <div className="createListSubscriptionWrapper__createListSubscription">
          {_stateCheck() ? (
            <>
              {invalidLists.length ? _renderInvalidLists() : null}
              {lists.length ? (
                <>
                  <div className="createListSubscriptionWrapper__createListSubscription__content">
                    <div className="createListSubscriptionWrapper__createListSubscription__content__section">
                      <div className="createListSubscriptionWrapper__createListSubscription__content__section__subscription">
                        {!invalidLists.length ? (
                          <div className="createListSubscriptionWrapper__createListSubscription__content__section__sectionHeader">
                            <WidgetHeader
                              headline={
                                lists.length > 1
                                  ? tc.createListSubscriptions
                                  : tc.createListSubscription
                              }
                            />
                          </div>
                        ) : null}
                        {_renderSubscriptionInfo()}
                        {_renderSubscriptionOptions()}
                      </div>
                    </div>
                    {limitProspects && _renderSuboptionsLimitProspects()}
                    {subscribeFlag &
                    listHelper.subscriptionFlags.excludeProspects
                      ? _renderSuboptionsExcludeProspects()
                      : null}
                    {subscribeFlag & listHelper.subscriptionFlags.mailings
                      ? _renderMailings()
                      : null}
                    {_renderSummary()}
                  </div>
                  <div className="createListSubscriptionWrapper__createListSubscription__footer">
                    <WidgetFooter
                      buttonOneFunc={_createListSubscription}
                      buttonOneText={tc.createListSubscription}
                      buttonTwoFunc={state.props.close}
                      buttonTwoText={tc.cancel}
                      disableButtonOne={
                        (subscribeFlag &
                          listHelper.subscriptionFlags.mailings &&
                          (!mailings.s3Key ||
                            !mailings.validation.validated ||
                            !fileIsUserApproved)) ||
                        (limitProspects &&
                          (!prospectPrio || prospectLimit < 1)) ||
                        (excludeMethod === 'timelimit' &&
                          (isNaN(excludeExpiration) ||
                            excludeExpiration < 1)) ||
                        !subsciptionInterval
                      }
                    />
                  </div>
                </>
              ) : null}
            </>
          ) : (
            <>
              <div className="createListSubscriptionWrapper__createListSubscription__content">
                <Info>
                  <h4>{tc.noLists}</h4>
                  <p>{tc.noLists}</p>
                </Info>
              </div>
              <div className="createListSubscriptionWrapper__createListSubscription__footer">
                <WidgetFooter
                  buttonTwoFunc={state.props.close}
                  buttonTwoText={tc.cancel}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </Popup>
  );
};

const MapStateToProps = (state, props) => {
  return {
    pdf: state.pdf,
    props: props,
    user: state.user,
  };
};

export default connect(MapStateToProps)(CreateListSubscription);
