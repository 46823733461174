import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import LanguageContext from 'language-context';
import { setResponsibility } from 'store/car/tasks';
import ColleaguesDropdown from 'components/colleagues_dropdown/colleagues_dropdown';
import Icon from 'components/icon';
import Tooltip from 'components/tooltip';
import WidgetHeader from 'components/widget_header';

const CarInfo = (props) => {
  const [changeResponsible, setChangeResponsible] = useState(false);
  const [responsibleObj, setResponsibleObj] = useState({});
  const tc = useContext(LanguageContext);

  useEffect(() => {
    if (props.responsible) {
      setResponsibleObj(props.responsible);
    } else {
      setResponsibleObj({
        responsibleUserId: null,
        responsibleUserName: '',
      });
    }
  }, [props.responsible]);

  const _formatDate = (date) => {
    return (
      date.toString().substring(0, 4) +
      '-' +
      date.toString().substring(4, 6) +
      '-' +
      date.toString().substring(6, 8)
    );
  };

  const _saveResponsible = async () => {
    setChangeResponsible(false);
    return await setResponsibility({
      entityId: props.id,
      responsibleUserId: responsibleObj.responsibleUserId,
    });
  };

  return (
    <div className="carInfoWrapper">
      <div className="carInfoWrapper__carInfo">
        <div className="carInfoWrapper__carInfo__header">
          <WidgetHeader headline={tc.info} headlineSub={tc.aboutVehicle} />
        </div>
        <div className="carInfoWrapper__carInfo__content">
          <div className="carInfoWrapper__carInfo__content__item">
            <p className="carInfoWrapper__carInfo__content__item__label">
              {tc.owner}
            </p>
            {changeResponsible ? (
              <div className="editable">
                <ColleaguesDropdown
                  activeId={responsibleObj.responsibleUserId}
                  activeIds={[]}
                  activeName={responsibleObj.responsibleUserName}
                  transparent={true}
                  onClick={(id, name) => {
                    setResponsibleObj({
                      ...responsibleObj,
                      responsibleUserId: id,
                      responsibleUserName: name,
                    });
                  }}
                />
                <Tooltip tooltipContent={tc.cancel}>
                  <Icon
                    val="clear"
                    onClick={() => {
                      if (props.responsible) {
                        setResponsibleObj(props.responsible);
                      } else {
                        setResponsibleObj({
                          responsibleUserId: null,
                          responsibleUserName: '',
                        });
                      }

                      setChangeResponsible(false);
                    }}
                  />
                </Tooltip>
                <Tooltip tooltipContent={tc.save}>
                  <Icon
                    val="save"
                    onClick={() => {
                      _saveResponsible();
                    }}
                  />
                </Tooltip>
              </div>
            ) : (
              <div className="editable">
                <p>
                  {responsibleObj.responsibleUserName?.length ? (
                    responsibleObj.responsibleUserName
                  ) : (
                    <span className="italic">{tc.noOwner}</span>
                  )}
                </p>
                <Tooltip tooltipContent={tc.changeOwner}>
                  <Icon
                    val="edit"
                    onClick={() => {
                      setChangeResponsible(true);
                    }}
                  />
                </Tooltip>
              </div>
            )}
          </div>
          <div className="carInfoWrapper__carInfo__content__item">
            <p className="carInfoWrapper__carInfo__content__item__label">
              {tc.vehicleType}
            </p>
            <p>{props.type ? tc[props.type.toLowerCase()] : tc.dataMissing}</p>
          </div>
          <div className="carInfoWrapper__carInfo__content__item">
            <p className="carInfoWrapper__carInfo__content__item__label">
              {tc.regNumber}
            </p>
            <p>{props.reg_number ? props.reg_number : tc.dataMissing}</p>
          </div>
          <div className="carInfoWrapper__carInfo__content__item">
            <p className="carInfoWrapper__carInfo__content__item__label">
              {tc.color}
            </p>
            <p>{props.color ? props.color : tc.dataMissing}</p>
          </div>
          <div className="carInfoWrapper__carInfo__content__item">
            <p className="carInfoWrapper__carInfo__content__item__label">
              {tc.brand}
            </p>
            <p>{props.brand ? props.brand : tc.dataMissing}</p>
          </div>
          <div className="carInfoWrapper__carInfo__content__item">
            <p className="carInfoWrapper__carInfo__content__item__label">
              {tc.realModel}
            </p>
            <p>{props.real_model ? props.real_model : tc.dataMissing}</p>
          </div>
          <div className="carInfoWrapper__carInfo__content__item">
            <p className="carInfoWrapper__carInfo__content__item__label">
              {tc.realTradeName}
            </p>
            <p>
              {props.real_trade_name ? props.real_trade_name : tc.dataMissing}
            </p>
          </div>
          <div className="carInfoWrapper__carInfo__content__item">
            <p className="carInfoWrapper__carInfo__content__item__label">
              {tc.amountUsersSinceInTrafic}
            </p>
            <p>
              {props.history && props.history.length > 0
                ? props.history.length + 1
                : 1}
            </p>
          </div>
          <div className="carInfoWrapper__carInfo__content__item">
            <p className="carInfoWrapper__carInfo__content__item__label">
              {tc.vehicleStatus}
            </p>
            <div className="editable">
              {props.car_status ? props.car_status : tc.dataMissing}
              <div className="editable">
                <Tooltip tooltipContent={tc.inspection}>
                  <Icon val="inspection" />
                </Tooltip>
                {props.date_inspection_valid
                  ? _formatDate(props.date_inspection_valid)
                  : tc.dataMissing}
              </div>
            </div>
          </div>
          <div className="carInfoWrapper__carInfo__content__item">
            <p className="carInfoWrapper__carInfo__content__item__label">
              {tc.vehicleManufactured}
            </p>
            <p>
              {props.date_car_year
                ? _formatDate(props.date_car_year)
                : tc.dataMissing}
            </p>
          </div>
          <div className="carInfoWrapper__carInfo__content__item">
            <p className="carInfoWrapper__carInfo__content__item__label">
              {tc.vehicleRegistered}
            </p>
            <p>
              {props.date_registered
                ? _formatDate(props.date_registered)
                : tc.dataMissing}
            </p>
          </div>
          {props.supertemp?.url ? (
            <div className="carInfoWrapper__carInfo__content__item">
              <p className="carInfoWrapper__carInfo__content__item__label">
                {tc.supertemp}
              </p>
              <p>
                <a target="_blank" href={props.supertemp.url}>
                  {tc.supertempAd}
                </a>
              </p>
            </div>
          ) : null}
          <div className="carInfoWrapper__carInfo__content__item">
            <p className="carInfoWrapper__carInfo__content__item__label">
              {tc.partOfDeals}
            </p>
            {props.deals?.length ? (
              <div className="companyInfoWrapper__companyInfo__content__item__dealsHolder">
                {props.deals.map((num, i) => {
                  if (num.id) {
                    if (i === props.deals.length - 1) {
                      return (
                        <span className="dealLink" key={i}>
                          <NavLink exact to={'/affar/' + num.id}>
                            {num.name ? num.name : tc.deal}
                          </NavLink>
                        </span>
                      );
                    } else {
                      return (
                        <span className="dealLink" key={i}>
                          <NavLink exact to={'/affar/' + num.id}>
                            {num.name ? num.name : tc.deal}
                          </NavLink>
                          ,
                        </span>
                      );
                    }
                  } else {
                    return null;
                  }
                })}
              </div>
            ) : (
              <p>
                <span className="italic">{tc.noDeals}</span>
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarInfo;
