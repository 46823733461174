import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { idbHelper } from 'helpers';
import LanguageContext from 'language-context';
import _ from 'underscore';
import Loading from 'components/loading';
import Menu from 'components/menu';
import PageHeader from 'components/page_header';
import moment from 'moment';
import SalesView from './sales_view';
import VehicleView from './vehicle_view';
import CarHelper from 'shared_helpers/car_helper';

const Analyse = (state) => {
  const tc = useContext(LanguageContext);

  const [fromDate, setFromDate] = useState(
    new Date(moment(new Date(Date.now())).subtract(1, 'year').startOf('year'))
  );
  const [toDate, setToDate] = useState(new Date(Date.now()));
  const [selectedSegments, setSelectedSegments] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [selectedAreaPresets, setSelectedAreaPresets] = useState([]);
  const [selectedAreaPresetGroups, setSelectedAreaPresetGroups] = useState([]);
  const [displayMode, setDisplayMode] = useState('sales');
  const [brands, setBrands] = useState(null);
  const [segments, setSegments] = useState(null);
  const [internalOrgSelectorValue, setInternalOrgSelectorValue] = useState('');
  const [orgnumber, setOrgnumber] = useState(null);
  const [selectedMeasures, setSelectedMeasures] = useState([]);
  const [orderBy, setOrderBy] = useState([]);
  const [orderByChoices, setOrderByChoices] = useState([]);

  const vehicleSegments = CarHelper.allTypes;

  const avaliableMeasures = {
    sales: [
      'Sale.count',
      'Sale.newCar',
      'Sale.usedCar',
      'Sale.newCarLeasing',
      'Sale.newCarNoLeasing',
      'Sale.usedCarRetailSeller',
      'Sale.usedCarPrivateSeller',
    ],
    cars: ['Car.count', 'Car.privateUsers', 'Car.retailUsers'],
  };

  useEffect(() => {
    const newOrderByChoices = selectedMeasures.map((m) => ({
      active: orderBy.includes(m),
      label: tc[m],
      val: m,
      onClick: () => {
        toggleFilter([m], orderBy, setOrderBy, true, true);
      },
    }));

    setOrderByChoices(newOrderByChoices);
  }, [selectedMeasures, orderBy]);

  useEffect(() => {
    if (state.user?.info?.orgNr) {
      setOrgnumber(state.user.info.orgNr.toString());
    }
  }, [state.user?.info?.orgNr]);

  useEffect(() => {
    if (state.prospect.backendDataIsLoaded) {
      const setData = async () => {
        const data = await idbHelper.getStoreData({ store: 'brands' });
        setBrands(data);
      };
      setData();
    }
  }, [state.prospect.backendDataIsLoaded]);

  useEffect(() => {
    const segmentsData = vehicleSegments.map((x) => ({
      active: selectedSegments.includes(x),
      label: tc[x.toLowerCase()],
      val: x,
      onClick: () =>
        toggleFilter(x.toUpperCase(), selectedSegments, setSelectedSegments),
    }));
    setSegments(segmentsData);
  }, [selectedSegments]);

  const toggleFilter = (
    value,
    state,
    setter,
    exclusiveValue,
    mustHaveSelected
  ) => {
    let isActive;

    if (exclusiveValue) {
      isActive = value.every((x) => state.includes(x));
    } else {
      isActive = state.includes(value);
    }
    if (isActive) {
      if (exclusiveValue) {
        if (mustHaveSelected) {
          return;
        }

        setter([]);
        return;
      }

      if (mustHaveSelected && state.filter((x) => x !== value).length === 0) {
        return;
      }

      setter(state.filter((x) => x !== value));
    } else {
      if (exclusiveValue) {
        setter(value);
        return;
      }

      setter([...state, value]);
    }
  };
  const setDateRange = ({ from, to }) => {
    setFromDate(from);
    setToDate(to);
  };

  const measureChoices = avaliableMeasures[displayMode].map((m) => ({
    active: selectedMeasures.includes(m),
    label: tc[m],
    val: m,
    onClick: () => {
      toggleFilter(m, selectedMeasures, setSelectedMeasures, false, true);
    },
  }));

  const filters = [
    {
      member: displayMode === 'cars' ? 'Car.type' : 'Sale.type',
      operator: 'equals',
      values: selectedSegments,
    },
    {
      member: displayMode === 'cars' ? 'Car.brand' : 'Sale.brand',
      operator: 'equals',
      values: selectedBrands,
    },
  ];

  const salesFilters = [
    {
      member: 'Buyers.postort',
      operator: 'equals',
      values: selectedAreaPresets,
    },
  ];

  const handleSearch = async (value) => {
    if (value === null || !brands) {
      return;
    }
    if (!value) {
      const data = brands.filter((x) =>
        selectedSegments.length ? selectedSegments.includes(x.val) : true
      );
      const flattenedData = data
        .reduce((acc, item) => acc.concat(item.children), [])
        .map((x) => ({ val: x.val, text: x.text, active: x.active }));
      const uniqueValues = _.uniq(flattenedData, 'val');
      const filteredData = uniqueValues.filter((b) =>
        selectedBrands.includes(b.val)
      );
      const toggleActive = filteredData.map((b) =>
        selectedBrands.includes(b.val) ? { ...b, active: true } : b
      );
      setSearchSuggestions(toggleActive);
      return;
    }

    const data = brands.filter((x) =>
      selectedSegments.length ? selectedSegments.includes(x.val) : true
    );
    const flattenedData = data
      .reduce((acc, item) => acc.concat(item.children), [])
      .map((x) => ({ val: x.val, text: x.text, active: x.active }));
    const uniqueValues = _.uniq(flattenedData, 'val');
    const regex = new RegExp(value, 'ig');
    const filteredData = uniqueValues.filter(
      (b) => regex.test(b.text) || selectedBrands.includes(b.val)
    );
    const toggleActive = filteredData.map((b) =>
      selectedBrands.includes(b.val) ? { ...b, active: true } : b
    );
    setSearchSuggestions(toggleActive);
  };

  const handleSelect = (value) => {
    if (selectedBrands.includes(value)) {
      setSelectedBrands(selectedBrands.filter((x) => x !== value));
      setSearchSuggestions(
        searchSuggestions.map((x) =>
          x.val === value ? { ...x, active: false } : x
        )
      );
      return;
    }

    setSelectedBrands([...selectedBrands, value]);
    setSearchSuggestions(
      searchSuggestions.map((x) =>
        x.val === value ? { ...x, active: !x.active } : x
      )
    );
  };

  return (
    <div className="analyseWrapper">
      <div className="analyseWrapper__analyse">
        <div className="analyseWrapper__analyse__header">
          <div className="analyseWrapper__analyse__header__top">
            <PageHeader headlineSub={tc.analyseInfo} />
          </div>
          <div className="analyseWrapper__analyse__header__bottom">
            <Menu
              skipSort={true}
              items={[
                {
                  dateFrom: fromDate,
                  dateTo: toDate,
                  onChange: (date) => {
                    setDateRange({ from: date.from, to: date.to });
                  },
                  showTime: false,
                  showYear: true,
                  type: 'date',
                },
                segments
                  ? {
                      checkboxes: true,
                      label: tc.vehicleType,
                      items: segments,
                      type: 'dropdown',
                    }
                  : {
                      element: <Loading small={true} />,
                      type: 'element',
                    },
                state.prospect.backendDataIsLoaded
                  ? {
                      label: tc.brand,
                      onChange: handleSearch,
                      onSelect: handleSelect,
                      suggestions: searchSuggestions,
                      type: 'search',
                    }
                  : {
                      element: <Loading small={true} />,
                      type: 'element',
                    },
                {
                  checkboxes: true,
                  label: 'Mätvärden',
                  items: measureChoices,
                  type: 'dropdown',
                },
                {
                  checkboxes: true,
                  label: 'Sortera',
                  items: orderByChoices,
                  type: 'dropdown',
                },

                {
                  element: (
                    <>
                      <input
                        type="text"
                        value={internalOrgSelectorValue}
                        onChange={(e) =>
                          setInternalOrgSelectorValue(e.target.value)
                        }
                        onKeyDown={(e) => {
                          if (e.keyCode !== 13 || !e.target.value) {
                            return;
                          }
                          setOrgnumber(internalOrgSelectorValue);
                        }}
                        placeholder={tc.orgNr}
                      />
                    </>
                  ),
                  type: 'element',
                },

                // state.user?.info?.dealer_id === 5141
                //   ? {
                //       element: (
                //         <>
                //           <input
                //             type="text"
                //             value={internalOrgSelectorValue}
                //             onChange={(e) =>
                //               setInternalOrgSelectorValue(e.target.value)
                //             }
                //             onKeyDown={(e) => {
                //               if (e.keyCode !== 13 || !e.target.value) {
                //                 return;
                //               }
                //               setOrgnumber(internalOrgSelectorValue);
                //             }}
                //             placeholder={tc.orgNr}
                //           />
                //         </>
                //       ),
                //       type: "element",
                //     }
                //   : null,
              ]}
            />
            <Menu
              skipSort={true}
              centered={true}
              type="floatFree"
              items={[
                {
                  active: displayMode === 'sales',
                  label: tc.salesView,
                  onClick: () => {
                    setDisplayMode('sales');
                  },
                  type: 'button',
                },
                {
                  active: displayMode === 'cars',
                  label: tc.vehiclesView,
                  onClick: () => {
                    setDisplayMode('cars');
                  },
                  type: 'button',
                },
              ]}
            />
          </div>
        </div>
        <div className="analyseWrapper__analyse__content">
          {displayMode === 'cars' && (
            <VehicleView
              filters={filters}
              fromDate={fromDate}
              toDate={toDate}
              selectedAreaPresets={selectedAreaPresets}
              selectedBrands={selectedBrands}
              selectedMeasures={selectedMeasures}
              orderBy={orderBy}
              setDefaultMeasures={setSelectedMeasures}
              setDefaultOrderBy={setOrderBy}
            />
          )}
          {displayMode === 'sales' && (
            <SalesView
              salesFilters={salesFilters}
              filters={filters}
              fromDate={fromDate}
              toDate={toDate}
              orgnumber={orgnumber}
              selectedAreaPresets={selectedAreaPresets}
              selectedBrands={selectedBrands}
              selectedMeasures={selectedMeasures}
              orderBy={orderBy}
              setDefaultMeasures={(m) => setSelectedMeasures(m)}
              setDefaultOrderBy={(o) => setOrderBy(o)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const MapStateToProps = (state) => {
  return {
    user: state.user,
    prospect: state.prospect,
    analyse: state.analyse,
  };
};

export default connect(MapStateToProps)(Analyse);
