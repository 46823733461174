import React, { useEffect, useRef, useState } from 'react';
import Icon from 'components/icon';
import MenuButton from './menu_button';
import MenuElement from './menu_element';
import MenuDatepicker from './menu_datepicker';
import MenuDropdown from './menu_dropdown';
import MenuSearch from './menu_search';

/**
 * Render a menu that is fixed, used below PageHeader.
 *
 * Item types can be: 'button' | 'dropdown' | 'element' | 'search'.
 * Possibility exists to provide an icon to item.
 * Possibility exists to provide a 'disabled' property for buttons that will be shown, but disabled.
 * Possibility exists to provide an 'active' property for buttons that will be shown as active.
 * Possibility exists to provide an 'labelSub' property for buttons in 'large' type.
 * We can display dropdowns with or without checkboxes.
 * Type 'element' is for special cases where we want to inject a jsx element directly, most often Loading component.
 *
 * @param props.skipSort - boolean (optional) - Default is to sort items alphabetically, set this to true if not.
 * @param props.items - array - Examples: [
 *      {
 *          disabled: bool, (optional),
 *          label: 'Menu option 2',
 *          onClick: func,
 *          type: 'button',
 *      },
 *      {
 *          disabled: bool, (optional),
 *          label: 'Menu option 3',
 *          items: [
 *            {active: bool, disabled: bool, label: 'Dropdown_item 1', onClick: func},
 *            {active: bool, disabled: bool, label: 'Dropdown_item 2', onClick: func}
 *           ],
 *          type: 'dropdown',
 *      },
 *      {
 *          element: <div>My element</div>,
 *          type: 'element',
 *      },
 *      {
 *          disabled: bool, (optional),
 *          label: 'Menu option 4',
 *          suggestions: [
 *            {
 *              active: false,
 *              val: 'volvo',
 *              text: 'Volvo'
 *            },
 *            {
 *              active: true,
 *              val: 'saab',
 *              text: 'Saab'
 *            },
 *          ],
 *          onSelect: func (select a row),
 *          onChange: func (query input value),
 *          type: 'search',
 *      },
 *      {
 *       dateFrom: state.activity.filter.date.from,
 *       dateTo: state.activity.filter.date.to,
 *       onChange: (date) => {
 *         _setDate({ from: date.from, to: date.to });
 *       },
 *       showTime: false,
 *       showYear: true,
 *       type: "date",
 *     },
 * ]
 */
const Menu = (props) => {
  const [items, setItems] = useState([]);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  // const [showMenu, setShowMenu] = useState(false); // Used when switched to responsive version, not used atm.
  const menuRegularRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      _checkScroll();
      const menu = document.querySelector('.menuWrapper__menu');
      if (menu) {
        menu.addEventListener('scroll', _checkScroll);
      }
    }, 3000);

    window.addEventListener('resize', _checkScroll);
    return () => {
      clearTimeout(timer);
      window.removeEventListener('resize', _checkScroll);
      const menu = document.querySelector('.menuWrapper__menu');
      if (menu) {
        menu.removeEventListener('scroll', _checkScroll);
      }
    };
  }, []);

  useEffect(() => {
    _checkScroll();
  }, [canScrollLeft, canScrollRight, props.items]);

  useEffect(() => {
    // Sort alphabetically initially.
    if (props.items?.length) {
      if (props.skipSort) {
        setItems(props.items.filter((num) => num));
      } else {
        setItems(
          props.items
            .filter((num) => num)
            .sort((a, b) => {
              if (a.label < b.label) {
                return -1;
              } else if (a.label > b.label) {
                return 1;
              } else {
                return 0;
              }
            })
        );
      }
    }
  }, [props.items, props.skipSort]);

  /**
   * Set if user is able to scroll left and/or right.
   */
  const _checkScroll = () => {
    if (menuRegularRef?.current) {
      setCanScrollLeft(menuRegularRef.current.scrollLeft > 0);
      setCanScrollRight(
        !(
          menuRegularRef.current.getBoundingClientRect().width +
            menuRegularRef.current.scrollLeft >=
          menuRegularRef.current.scrollWidth
        )
      );
    }
  };

  const _renderItems = () => {
    return items.map((num, i) => {
      if (num.type === 'button') {
        return (
          <MenuButton
            active={num.active}
            disabled={num.disabled}
            icon={num.icon}
            key={i}
            label={num.label}
            onClick={() => {
              if (!num.disabled && typeof num.onClick === 'function') {
                num.onClick();
                // setShowMenu(false); // When responsive version, not used atm.
              }
            }}
          />
        );
      } else if (num.type === 'dropdown') {
        return (
          <MenuDropdown
            active={num.active}
            checkboxes={num.checkboxes}
            disabled={num.disabled}
            icon={num.icon}
            items={num.items}
            key={i}
            label={num.label}
            autoClose={props.autoClose}
          />
        );
      } else if (num.type === 'element') {
        return (
          <MenuElement
            active={num.active}
            disabled={num.disabled}
            element={num.element}
            key={i}
          />
        );
      } else if (num.type === 'search') {
        return (
          <MenuSearch
            active={num.active}
            disabled={num.disabled}
            key={i}
            label={num.label}
            item={num}
            onChange={num.onChange}
            onSelect={num.onSelect}
            suggestions={num.suggestions}
          />
        );
      } else if (num.type === 'date') {
        return (
          <MenuDatepicker
            active={num.active}
            disabled={num.disabled}
            dateFrom={num.dateFrom}
            dateTo={num.dateTo}
            key={i}
            maxDate={num.maxDate}
            minDate={num.minDate}
            onChange={num.onChange}
            showTime={num.showTime}
            showYear={num.showYear}
          />
        );
      } else {
        return null;
      }
    });
  };

  const _scrollLeft = () => {
    const menu = document.querySelector('.menuWrapper__menu');
    if (menu) {
      menu.scrollLeft -= 100;
    }
  };

  const _scrollRight = () => {
    const menu = document.querySelector('.menuWrapper__menu');
    if (menu) {
      menu.scrollLeft += 100;
    }
  };

  return (
    <div className="menuWrapper">
      <div className="menuWrapper__menu" ref={menuRegularRef}>
        {canScrollLeft ? (
          <div
            className="menuWrapper__menu__scrollIndicatorLeft"
            onClick={_scrollLeft}
          >
            <Icon val="navigatePrevious" />
          </div>
        ) : null}
        <div className="menuWrapper__menu__items">{_renderItems()}</div>
        {canScrollRight ? (
          <div
            className="menuWrapper__menu__scrollIndicatorRight"
            onClick={_scrollRight}
          >
            <Icon val="navigateNext" />
          </div>
        ) : null}
      </div>
      <div className="menuWrapper__border" />
    </div>
  );
};

export default Menu;
