import { agileHelper } from 'helpers';
import { NavLink } from 'react-router-dom';
import Id from 'valid-objectid';
import carHelper from 'shared_helpers/car_helper';
import companyHelper from 'shared_helpers/company_helper';
import React from 'react';
import * as text from 'text-content';
import { store } from 'store';
import moment from 'moment';
import id from 'valid-objectid';

export const activityHelper = {
  /**
   * This cluster of an if statement is just for converting an activity to a nice readable sentence, such as:
   * "Moved Kvinna, 36, BERGSHAMRA from column1 to column2"
   * "Meeting with Man, 58 SJÖBO"
   * "Offert gällande Man, 60, STOCKHOLM"
   * etc...
   *
   * Where appropriate add a link to the target (which can be a deal, prospect or vehicle).
   *
   * @param target - string (optional) - When provided this should be deal id, prospect id or vehicle reg number.
   *     This is the target we are rendering activities for, so on the deal page this is a deal id.
   *     When we are rendering activities for the Activity page by filters instead of a specific target, this should be null.
   *     This param helps to determine if link to a deal/prospect/vehicle should be displayed.
   * @param activity - obj - Activity object.
   */
  getActionJSX: (target, activity) => {
    const tc = store?.getState()?.user?.info?.lang
      ? store.getState().user.info.lang === 'en'
        ? text.english
        : text.swedish
      : text.swedish;

    if (!activity) {
      return null;
    }

    let action;

    target = target ? target.toString() : null;
    const targetIsSameAsActivityTarget =
      target &&
      (target === activity.deal_id ||
        target === activity.deal?._id ||
        target === activity.target);

    if (targetIsSameAsActivityTarget) {
      // Target is same as activity target, skip link to target.
      if (activity.action === 'move') {
        // A "move" action from deal_actions mongo collection, add column names.
        action = (
          <div>
            {activityHelper.getReadableActivity(activity.action)}{' '}
            {tc.deal.toLowerCase()} {tc.from.toLowerCase()}{' '}
            <strong>
              {agileHelper.buildPhaseToColumnTitle(activity.phase)}
            </strong>{' '}
            {tc.to.toLowerCase()}{' '}
            <strong>
              {agileHelper.buildPhaseToColumnTitle(activity.target)}
            </strong>
          </div>
        );
      } else if (activity.action) {
        // Other actions from deal_actions mongo collection that is not "move".
        action = (
          <div>{activityHelper.getReadableActivity(activity.action)}</div>
        );
      } else if (!activity.action && activity.id && activity.comment) {
        // Comment from sql comments table.
        action = <div>{activityHelper.getReadableActivity('comment')}</div>;
      }
    }

    if (!targetIsSameAsActivityTarget) {
      // Target is not same as activity target, add link to target.
      if (activity.action === 'move') {
        // A "move" action from deal_actions mongo collection, add column names.
        if (activity.phase && activity.target && activity.deal_id) {
          const name = activity.deal?.name?.length
            ? activity.deal.name
            : activity.name
            ? activity.name
            : tc.deal.toLowerCase();

          action = (
            <div>
              {activityHelper.getReadableActivity(activity.action)}{' '}
              <NavLink exact to={'/affar/' + activity.deal_id}>
                {name}
              </NavLink>{' '}
              {tc.from.toLowerCase()}{' '}
              <strong>
                {agileHelper.buildPhaseToColumnTitle(activity.phase)}
              </strong>{' '}
              {tc.to.toLowerCase()}{' '}
              <strong>
                {agileHelper.buildPhaseToColumnTitle(activity.target)}
              </strong>
            </div>
          );
        } else {
          // Guard dog, should never happen.
          action = (
            <div>
              {activityHelper.getReadableActivity(activity.action)}{' '}
              {tc.deal.toLowerCase()}
            </div>
          );
        }
      } else if (activity.action) {
        // Other actions from deal_actions mongo collection that is not "move".
        if (activity.deal_id || activity.deal || activity.dealId) {
          // Deal
          const dealId = activity.deal?._id
            ? activity.deal._id
            : activity.deal_id
            ? activity.deal_id
            : activity.dealId;

          action = (
            <div>
              {activityHelper.getReadableActivity(activity.action)}{' '}
              {activityHelper.getPreposition(activity.action).toLowerCase()}{' '}
              <NavLink exact to={'/affar/' + dealId}>
                {activity.deal?.name
                  ? activity.deal.name
                  : activity.name
                  ? activity.name
                  : tc.deal.toLowerCase()}
              </NavLink>
            </div>
          );
        } else if (activity.target) {
          if (companyHelper.isValidOrgNr(activity.target)) {
            // Company.
            action = (
              <div>
                {activityHelper.getReadableActivity(activity.action)}{' '}
                {tc.on.toLowerCase()}{' '}
                <NavLink exact to={'/foretag/' + activity.target}>
                  {activity.name ? activity.name : tc.oneProspect.toLowerCase()}
                </NavLink>
              </div>
            );
          } else if (carHelper.isValidRegNumber(activity.target)) {
            // Vehicle.
            action = (
              <div>
                {activityHelper.getReadableActivity(activity.action)}{' '}
                {tc.on.toLowerCase()}{' '}
                <NavLink exact to={'/fordon/' + activity.target}>
                  {activity.name ? activity.name : tc.vehicle.toLowerCase()}
                </NavLink>
              </div>
            );
          } else {
            // Person.
            action = (
              <div>
                {activityHelper.getReadableActivity(activity.action)}{' '}
                {tc.on.toLowerCase()}{' '}
                <NavLink exact to={'/person/' + activity.target}>
                  {activity.name ? activity.name : tc.oneProspect.toLowerCase()}
                </NavLink>
              </div>
            );
          }
        }
      } else if (!activity.action && activity.id && activity.comment) {
        // Comment from sql comments table.
        if (activity.deal || activity.deal_id || activity.dealId) {
          // Deal
          const dealId = activity.deal?._id
            ? activity.deal._id
            : activity.deal_id
            ? activity.deal_id
            : activity.dealId;

          action = (
            <div>
              {activityHelper.getReadableActivity('comment')}{' '}
              {activityHelper.getPreposition(activity.action).toLowerCase()}{' '}
              <NavLink exact to={'/affar/' + dealId}>
                {activity.deal?.name
                  ? activity.deal.name
                  : activity.name
                  ? activity.name
                  : tc.deal.toLowerCase()}
              </NavLink>
            </div>
          );
        } else if (
          !activity.deal &&
          activity.target &&
          !Id.isValid(activity.target)
        ) {
          // No deal, add prospect/vehicle link.
          if (companyHelper.isValidOrgNr(activity.target)) {
            action = (
              <div>
                {activityHelper.getReadableActivity('comment')}{' '}
                {tc.on.toLowerCase()}{' '}
                <NavLink exact to={'/foretag/' + activity.target}>
                  {activity.name ? activity.name : tc.oneProspect.toLowerCase()}
                </NavLink>
              </div>
            );
          } else {
            if (carHelper.isValidRegNumber(activity.target)) {
              action = (
                <div>
                  {activityHelper.getReadableActivity('comment')}{' '}
                  {tc.on.toLowerCase()}{' '}
                  <NavLink exact to={'/fordon/' + activity.target}>
                    {activity.name ? activity.name : tc.vehicle.toLowerCase()}
                  </NavLink>
                </div>
              );
            } else {
              action = (
                <div>
                  {activityHelper.getReadableActivity('comment')}{' '}
                  {tc.on.toLowerCase()}{' '}
                  <NavLink exact to={'/person/' + activity.target}>
                    {activity.name
                      ? activity.name
                      : tc.oneProspect.toLowerCase()}
                  </NavLink>
                </div>
              );
            }
          }
        } else if (
          !activity.deal &&
          activity.target &&
          Id.isValid(activity.target)
        ) {
          // Target is deal, but we don't have deal name.
          action = (
            <div>
              {activityHelper.getReadableActivity('comment')}{' '}
              {tc.on.toLowerCase()}{' '}
              <NavLink exact to={'/affar/' + activity.target}>
                {tc.deal.toLowerCase()}
              </NavLink>
            </div>
          );
        } else {
          // Catch comments without deal link or prospect link.
          action = <div>{activityHelper.getReadableActivity('comment')}</div>;
        }
      } else {
        // Should never happen.
        action = tc.dataMissing;
      }
    }

    return action;
  },
  /**
   * @description Returns an object with arrays of active and inactive activity types.
   *  Note - only returns activities that can be scheduled (see getActionTypes()).
   */
  getDefaultActionTypes: () => {
    return {
      active: ['mail', 'meeting', 'testride', 'call', 'dealsWon'],
      inactive: [
        'analysis',
        'skip',
        'lift',
        'visit',
        'contractCustomerNewSign',
        'contractCustomerReSign',
        'offer',
        'valuation',
        'other',
      ],
    };
  },
  /**
   *
   * @param {boolean | void} onlyScheduledActivities optional - only return the activities that can be scheduled.
   * @description
   * If new, schedulable types are added, be sure to update getDefaultActionTypes() as well as the types on the backend.
   * The backend default types can be found in AgileModel.getActivityTypes().
   * @returns {string[]} array of action types, such as "analysis", "mail", "meeting", "call"
   */
  getActionTypes: (onlyScheduledActivities) => {
    // Try and return them in alphabetical order by swedish translation, so we won't have to handle this everywhere.
    // skip should not be in "onlyScheduledActivities" - we need to come up with a better structure here in the long term.
    if (onlyScheduledActivities)
      return [
        'call',
        'mail',
        'share',
        'meeting',
        'lift',
        'visit',
        'offer',
        'contractCustomerNewSign',
        'contractCustomerReSign',
        'analysis',
        'skip',
        'testride',
        'valuation',
        'other',
        'dealsWon',
      ];
    else
      return [
        'call',
        'analysis',
        'mailings',
        'share',
        'mail',
        'move',
        'lift',
        'visit',
        'comment',
        'meeting',
        'contractCustomerNewSign',
        'contractCustomerReSign',
        'meetingPlanned',
        'offer',
        'testride',
        'valuation',
        'other',
        'dealsWon',
      ];
  },
  getActionsThatCannotBePlanned: () => {
    return [
      'lift',
      // "contractCustomerNewSign",
      // "contractCustomerReSign",
      'move',
      'meetingPlanned',
      'share',
    ];
  },
  getDealTypes: () => {
    return ['vehicle', 'contractCustomerNewSign', 'contractCustomerReSign'];
  },
  getCurrentMonthDateObj: () => {
    const now = new Date();
    const dateFrom = new Date(now.getFullYear(), now.getMonth(), 1);
    dateFrom.setHours(0, 0, 0);
    const dateTo = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    dateTo.setHours(23, 59, 59);

    return {
      from: dateFrom,
      to: dateTo,
    };
  },
  getCurrentWeekDateObj: () => {
    let startOfWeek = moment().startOf('week').toDate();
    let endOfWeek = moment().endOf('week').toDate();

    return {
      from: new Date(startOfWeek),
      to: new Date(endOfWeek),
    };
  },
  getPastThirtyDaysObj: () => {
    const from = moment()
      .subtract(30, 'days')
      .set({ hour: 0, minute: 0, second: 0 })
      .toDate();
    const to = moment().set({ hour: 23, minute: 59, second: 59 }).toDate();
    return { to, from };
  },
  getPast365DaysObj: () => {
    const from = moment()
      .subtract(365, 'days')
      .set({ hour: 0, minute: 0, second: 1 })
      .toDate();
    const to = moment().set({ hour: 23, minute: 59, second: 59 }).toDate();
    return { to, from };
  },
  /**
   * For activity widgets where no "from" date is provided, we return this default value representing years.
   * So "from" date is set to twenty years ago.
   */
  getDefaultFromYears: () => {
    return 20;
  },
  /**
   *
   * @param date - Date string that can be converted to date object.
   * @param adjustForSameDay - bool (optional) - Set true if we send in a date without timestamp (like: 2021/09/01)
   * and want to return 'withinOneDay' instead of 'passed' for that whole day.
   */
  getEventClass: (date, adjustForSameDay) => {
    const diff = moment(new Date(date), 'DD/MM/YYYY').diff(new Date(), 'hours');
    const eventPassed = diff < 0;
    const withinOneDay = diff < 24 && diff >= 0;

    if (withinOneDay) {
      return 'withinOneDay';
    } else if (eventPassed) {
      if (adjustForSameDay) {
        const isToday = moment(new Date(date), 'DD/MM/YYYY').isSame(
          new Date(),
          'day'
        );

        return isToday ? 'withinOneDay' : 'passed';
      } else {
        return 'passed';
      }
    } else {
      return 'event';
    }
  },
  getPipelinePathFromKey: (key) => {
    switch (key) {
      case 'all':
        return 'alla';
      case 'active':
        return 'aktiva';
      case 'lost':
        return 'forlorade';
      case 'trash':
        return 'borttagna';
      case 'won':
        return 'vunna';
      default:
        return null;
    }
  },
  getPipelineTitleFromKey: (key) => {
    const tc = store?.getState()?.user?.info?.lang
      ? store.getState().user.info.lang === 'en'
        ? text.english
        : text.swedish
      : text.swedish;

    switch (key) {
      case 'all':
        return tc.all;
      case 'active':
        return tc.dealsActive;
      case 'lost':
        return tc.dealsLost;
      case 'trash':
        return tc.dealsRemoved;
      case 'won':
        return tc.dealsWon;
      default:
        return null;
    }
  },
  getPreposition: (activity) => {
    const tc = store?.getState()?.user?.info?.lang
      ? store.getState().user.info.lang === 'en'
        ? text.english
        : text.swedish
      : text.swedish;

    switch (activity) {
      case 'call':
        return tc.to;
      case 'did_call':
        return tc.to;
      case 'will_call':
        return tc.to;
      case 'mail':
        return tc.to;
      case 'did_mail':
        return tc.to;
      case 'will_mail':
        return tc.to;
      case 'email':
        return tc.to;
      case 'will_email':
        return tc.to;
      case 'did_post':
        return tc.to;
      case 'will_post':
        return tc.to;
      case 'ofofer':
        return tc.to;
      case 'meeting':
        return tc.with;
      case 'testrider':
        return tc.with;
      case 'analysis':
        return tc.for;
      case 'valuation':
        return tc.for;
      case 'visit':
        return tc.at;
      case 'dealsWon':
        return '';
      default:
        return tc.regarding;
    }
  },
  getPreviousMonthDateObj: () => {
    const now = new Date();
    const dateTo = new Date(now.getFullYear(), now.getMonth() - 1, 1);
    const dateFrom = new Date(now.getFullYear(), now.getMonth(), 0);

    return {
      from: dateTo,
      to: dateFrom,
    };
  },
  getPreviousWeekDateObj: () => {
    const dateFrom = new Date(moment().subtract(1, 'weeks').startOf('isoWeek'));
    const dateTo = new Date(moment().subtract(1, 'weeks').endOf('isoWeek'));

    return {
      from: dateFrom,
      to: dateTo,
    };

    // const thisMorning = new Date();
    // const first = thisMorning.getDate() - (thisMorning.getDay() - 1);
    // const last = first + 6;
    // const dateTo = new Date(thisMorning.setDate(last));
    // const dateFrom = new Date(thisMorning.setDate(first));
    // return {
    //   from: moment(dateFrom).startOf("day"),
    //   to: moment(dateTo).endOf("day"),
    // };
  },
  /**
   *
   * @param {*} activity
   * @param {boolean} isPlanned - determines some specific circumstances where you may want different text displayed, like in planned activities you shouldn't say "Utfört möte", just "Möte"
   * @returns {string} The text appropriate for the current activity
   */
  getReadableActivity: (activity, isPlanned = false) => {
    const tc = store?.getState()?.user?.info?.lang
      ? store.getState().user.info.lang === 'en'
        ? text.english
        : text.swedish
      : text.swedish;

    switch (activity) {
      case 'analysis':
        return tc.analysis;
      case 'call':
        return tc.call;
      case 'comment':
        return tc.comment;
      case 'did_call':
        return tc.didCall;
      case 'did_email':
        return tc.didEmail;
      case 'did_lose_custom_reason':
        return tc.didLoseCustomReason;
      case 'did_lose_price':
        return tc.didLosePrice;
      case 'did_lose_unknown':
        return tc.didLoseUnknown;
      case 'did_lose_window':
        return tc.didLoseWindow;
      case 'did_lose_product':
        return tc.didLoseProduct;
      case 'did_meeting':
        return tc.didMeeting;
      case 'did_post':
        return tc.didPost;
      case 'did_win_custom_reason':
        return tc.didWinCustomReason;
      case 'lift':
        return tc.lift;
      case 'lost':
        return tc.lost;
      case 'mail':
        return tc.mail;
      case 'meeting':
        return isPlanned ? tc.meeting : tc.meetingFinished;
      case 'meetingPlanned':
        return tc.meetingPlanned;
      case 'move':
        return tc.moved;
      case 'offer':
        return tc.offert;
      case 'other':
        return tc.other;
      case 'owner':
        return tc.changedOwner;
      case 'skip':
        return tc.skip;
      case 'testride':
        return tc.testride;
      case 'valuation':
        return tc.valuation;
      case 'visit':
        return tc.visit;
      case 'will_call':
        return tc.willCall;
      case 'will_mail':
        return tc.willEmail;
      case 'will_email':
        return tc.willEmail;
      case 'will_meeting':
        return tc.willMeeting;
      case 'will_post':
        return tc.willPost;
      case 'won':
        return tc.won;
      case 'dealsWon':
        return tc.dealWon;
      default:
        return tc[activity] ? tc[activity] : activity;
    }
  },
  /**
   *
   * @param {{activityTypes: {active: string[], inactive: string[], isDefault: boolean | undefined}}} payload
   * @description Checks whether the active and inactive activity types are arrays, and if it's the default.
   *  Default signals that it's a new user, and so in that case the data will get sent back to the server to update the database.
   * @returns {boolean}
   */
  dataIsDefault: (payload) => {
    if (
      payload &&
      Array.isArray(payload.activityTypes?.active) &&
      Array.isArray(payload.activityTypes?.inactive) &&
      payload.activityTypes?.isDefault
    ) {
      return true;
    }
    return false;
  },
  getValidRowsForType: (data, typeOfRow, wantDeals) => {
    if (!Array.isArray(data)) return [];
    if (!wantDeals) {
      return data.filter((num) => num?.user?.id && num?.user?.dealer_id);
    }

    return data.reduce((acc, row) => {
      if (
        row?.user?.id &&
        row?.user?.dealer_id &&
        ((row.target && id.isValid(row.target)) ||
          (row.dealId && id.isValid(row.dealId)) ||
          (row.deal?._id && id.isValid(row.deal._id)) ||
          (row.deal_id && id.isValid(row.deal_id)))
      ) {
        // Only display where target is a deal.
        // It's a bit inconsistent where we store the deal id, hence the check for different properties.
        // Add a property to store target deal id for further use.
        const targetDeal = row.deal?._id
          ? row.deal._id
          : row.deal_id
          ? row.deal_id
          : row.target
          ? row.target
          : row.dealId;
        return acc.concat({ ...row, targetDeal });
      } else return acc;
    }, []);
  },
};
