import React, { useContext } from 'react';
import { connect } from 'react-redux';
import LanguageContext from 'language-context';
import WidgetHeader from 'components/widget_header';
import { widgetHelper } from 'helpers/widget_helper';
/**
 * @param isMinimized - A boolean indicating whether the component is minimized or not.
 * @param onMinimizeChange - Callback function invoked when the minimize state changes.
 */
const ActivitiesHeader = ({
  view,
  setView,
  minimize,
  setMinimize,
  updateWidgets,
  setShowAddToDashboard,
  showSaveToDashboard,
  setShowExcelluttag,
  widgets,
  widget,
  onMinimizeChange,
  isCompanyPage,
}) => {
  const tc = useContext(LanguageContext);

  return (
    <div className="activitiesWrapper__activities__header">
      <WidgetHeader
        dashboardItems={
          isCompanyPage
            ? null
            : [
                {
                  disabled: !minimize,
                  icon: 'maximize',
                  hideWhenResponsive: true,
                  label: tc.maximize,
                  onClick: () => {
                    setMinimize(false);
                    if (Array.isArray(widgets?.data?.widgets)) {
                      // Save widget state to db.
                      updateWidgets({
                        widgets: widgets.data.widgets.map((widgetObj) => {
                          if (
                            widget?._id === widgetObj._id &&
                            widgetObj?.dashboards?.dashboard
                          ) {
                            widgetObj.dashboards.dashboard.minimized = false;
                          }
                          return widgetObj;
                        }),
                      });
                    }
                  },
                },
                {
                  active: view === 'groups',
                  disabled: minimize,
                  icon: 'groups',
                  label: `${tc.displayMode} ${tc.activityGroups.toLowerCase()}`,
                  onClick: () => {
                    setView('groups');
                    if (Array.isArray(widgets?.data?.widgets)) {
                      // Save widget state to db.
                      updateWidgets({
                        widgets: widgets.data.widgets.map((widgetObj) => {
                          if (
                            widget?._id === widgetObj._id &&
                            widgetObj?.dashboards?.dashboard
                          ) {
                            widgetObj.dashboards.dashboard.view = 'groups';
                          }
                          return widgetObj;
                        }),
                      });
                    }
                  },
                  selectable: true,
                },
                {
                  active: view === 'types',
                  disabled: minimize,
                  icon: 'actionTypes',
                  label: `${tc.displayMode} ${tc.table.toLowerCase()}`,
                  onClick: () => {
                    setView('types');
                    if (Array.isArray(widgets?.data?.widgets)) {
                      // Save widget state to db.
                      updateWidgets({
                        widgets: widgets.data.widgets.map((widgetObj) => {
                          if (
                            widget?._id === widgetObj._id &&
                            widgetObj?.dashboards?.dashboard
                          ) {
                            widgetObj.dashboards.dashboard.view = 'types';
                          }
                          return widgetObj;
                        }),
                      });
                    }
                  },
                  selectable: true,
                },
                {
                  active: view === 'flow',
                  disabled: minimize,
                  icon: 'flow',
                  label: `${tc.displayMode} ${tc.activitiesFlow.toLowerCase()}`,
                  onClick: () => {
                    setView('flow');
                    if (Array.isArray(widgets?.data?.widgets)) {
                      // Save widget state to db.
                      updateWidgets({
                        widgets: widgets.data.widgets.map((widgetObj) => {
                          if (
                            widget?._id === widgetObj._id &&
                            widgetObj?.dashboards?.dashboard
                          ) {
                            widgetObj.dashboards.dashboard.view = 'flow';
                          }
                          return widgetObj;
                        }),
                      });
                    }
                  },
                  selectable: true,
                },
                !widget && showSaveToDashboard
                  ? {
                      disabled: !(!widget && showSaveToDashboard) || minimize,
                      icon: 'dashboard',
                      label: tc.addToDashboard,
                      onClick: () => {
                        setShowAddToDashboard(true);
                      },
                    }
                  : null,
                !widget && showSaveToDashboard
                  ? {
                      disabled: minimize,
                      icon: 'file',
                      label: tc.excelOutput,
                      onClick: () => {
                        setShowExcelluttag(true);
                      },
                    }
                  : null,
                {
                  disabled: minimize,
                  icon: 'minimize',
                  hideWhenResponsive: true,
                  label: tc.minimize,
                  onClick: () => {
                    setMinimize(true);
                    if (onMinimizeChange) {
                      onMinimizeChange(true);
                    }
                    if (Array.isArray(widgets?.data?.widgets)) {
                      // Save widget state to db.
                      updateWidgets({
                        widgets: widgets.data.widgets.map((widgetObj) => {
                          if (
                            widget?._id === widgetObj._id &&
                            widgetObj?.dashboards?.dashboard
                          ) {
                            widgetObj.dashboards.dashboard.minimized = true;
                          }
                          return widgetObj;
                        }),
                      });
                    }
                  },
                },
              ]
        }
        headline={widget?.name ? widget.name : tc.activity}
        headlineSub={
          view === 'types'
            ? tc.activityTable
            : view === 'groups'
            ? tc.activityGroups
            : tc.activitiesFlow
        }
        headlineSub2={
          widget?.data ? widgetHelper.parseWidgetDate(widget?.data) : null
        }
      />
    </div>
  );
};

const MapStateToProps = (state) => {
  return {
    widgets: state.widgets,
  };
};

export default connect(MapStateToProps)(ActivitiesHeader);
