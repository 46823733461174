import React, { useContext, useState, useRef } from "react";
import LanguageContext from "language-context";
import {
  uploadLeads,
  resetUpload,
} from "store/leads/tasks";
import ReactS3Uploader from "react-s3-uploader";
import { showFlashMessage } from "store/flash_messages/tasks";
import Loading from "components/loading";
import WidgetFooter from "components/widget_footer";
import LeadsUploadExample from "images/leads_upload_example.png";

/**
 * Upload file.
 *
 * @param props.close - func
 */
const LeadsUploadUploader = (props) => {
  const tc = useContext(LanguageContext);
  const maxSize = 1024 * 1024 * 2;
  const acceptedFileTypes = ["csv", "xls", "xlsx", "ods"];
  const acceptedMimeTypes = ["text/csv", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.oasis.opendocument.spreadsheet"];

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const inputRef = useRef(null);

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];

    if (!file) {
      setError('Nåt gick fel...');
      return;
    }

    const validated = _validateFile(file);

    if (validated.success === false) {
      setError(validated.msg);
      return
    }

    setError('');
    setLoading(true);

    const res = await uploadLeads(file);

    if (!res.success) {
      setLoading(false);
      _uploadError()
    }
  }

  const _handleFileUploadClick = () => {
    if (inputRef) {
      inputRef.current.click();
    }
  };

  const _uploadError = () => {
    showFlashMessage(tc.leadsUploadFailed, "fail");
    resetUpload();
  };

  const _validateFile = (file) => {
    const splits = file.name.split(".");
    const fileType = splits[splits.length - 1];

    if (file.size > maxSize) {
      return {
        success: false,
        msg: `${tc.leadsUploadFileExceedsMaxSize}2 MB.`,
      }
    } else if (!acceptedFileTypes.includes(fileType) || !acceptedMimeTypes.includes(file.type)) {
      return {
        success: false,
        msg: `${tc.leadsUploadUnsupportedFileType} ${tc.supportedFiletypesIs}: ${acceptedFileTypes.join(", ")}.`
      }
    }

    return {
      success: true,
      msg: null,
    }
  };

  return (
    <div className="leadsUploadWrapper__leadsUpload__content">
      <input
        type="file"
        onChange={handleFileUpload}
        ref={inputRef}
        className="hidden"
      />
      {loading ? (
        <Loading />
      ) : (
        <>
          <p>{tc.leadsUploadDescription1}</p>
          <p>{tc.leadsUploadDescription2}</p>
          <p>
            <img src={LeadsUploadExample} alt={tc.uploadLeads} />
          </p>
          {error && (
            <p className="leadsUploadWrapper__leadsUpload__content__error">
              {error}
            </p>
          )}
          <WidgetFooter
            buttonOneText={tc.uploadLeads}
            buttonOneFunc={_handleFileUploadClick}
            buttonTwoFunc={() => {
              if (typeof props.close === "function") {
                props.close();
              }
            }}
            buttonTwoText={tc.cancel}
          />
        </>
      )}
    </div>
  );
};

export default LeadsUploadUploader;
