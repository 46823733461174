import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import LanguageContext from "language-context";
import { NavLink } from "react-router-dom";
import { updateDeal } from "store/deal/tasks";
import Icon from "components/icon";
import Info from "components/info";
import Loading from "components/loading";
import Popup from "components/popup";
import Search from "components/search";
import Tooltip from "components/tooltip";
import WidgetFooter from "components/widget_footer";
import WidgetHeader from "components/widget_header";

/**
 * This component renders a list of cars, based on store.deal.deal.cars.
 *
 * @param state.props.isRemovable - bool
 */
const DealCars = (state) => {
  const [carRows, setCarRows] = useState(null);
  const [removeCarFromDeal, setRemoveCarFromDeal] = useState(null);
  const [minimize, setMinimize] = useState(false);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    const _renderCars = () => {
      let data = state.deal.deal.cars;

      if (!data || (data && data.length === 0)) {
        setMinimize(true);
        return setCarRows(
          <Info>
            <h4>{tc.noVehicles}</h4>
            <p>{tc.carsHowTo}</p>
          </Info>
        );
      }

      setMinimize(false);

      setCarRows(
        data.map((num, i) => {
          return <React.Fragment key={i}>{_renderCarItem(num)}</React.Fragment>;
        })
      );
    };

    const _renderCarItem = (regNr) => {
      return (
        <div
          className={
            state.props.isRemovable
              ? "dealCarsWrapper__dealCars__content__cars__item"
              : "dealCarsWrapper__dealCars__content__cars__itemNoRemove"
          }
        >
          <div className="dealCarsWrapper__dealCars__content__cars__item__icon">
            <div className="dealCarsWrapper__dealCars__content__cars__item__icon__visible">
              {<Icon val="car" />}
            </div>
            <div className="dealCarsWrapper__dealCars__content__cars__item__icon__hidden">
              <Tooltip tooltipContent={tc.removeCar}>
                <Icon
                  val="remove"
                  onClick={() => {
                    setRemoveCarFromDeal(regNr);
                  }}
                />
              </Tooltip>
            </div>
          </div>
          <NavLink exact to={"/fordon/" + regNr} key={regNr}>
            <div className="dealCarsWrapper__dealCars__content__cars__item__infoHolder">
              <p>{tc.regNumber}:</p>
              <p className="dealCarsWrapper__dealCars__content__cars__item__infoHolder__regNum">
                {regNr}
              </p>
            </div>
            <div className="dealCarsWrapper__dealCars__content__cars__item__linkHolder">
              <Tooltip tooltipContent={tc.navigateToCar}>
                <Icon val="navigateNext" />
              </Tooltip>
            </div>
          </NavLink>
        </div>
      );
    };

    _renderCars();
  }, [state.deal.deal.cars]);

  const _addCars = async () => {
    let cars = state.search.selectedCars.map((num) => num.id.toString());
    if (Array.isArray(state.deal.deal.cars)) {
      cars = state.deal.deal.cars.concat(cars);
    } else {
      cars = [].concat(cars);
    }

    return await updateDeal({ cars: cars });
  };

  const _removeCar = async (id) => {
    const cars = state.deal.deal.cars.filter((num) => num !== id);
    return await updateDeal({ cars: cars });
  };

  const _stateCheck = () => {
    return !!(state && state.deal && state.deal.deal && state.deal.deal.cars);
  };

  return (
    <div className="dealCarsWrapper">
      <div className="dealCarsWrapper__dealCars">
        <div className="dealCarsWrapper__dealCars__header">
          <WidgetHeader
            dashboardItems={
              _stateCheck()
                ? [
                    {
                      disabled: !minimize,
                      icon: "maximize",
                      label: tc.maximize,
                      onClick: () => {
                        setMinimize(false);
                      },
                    },
                    {
                      disabled: minimize,
                      icon: "minimize",
                      label: tc.minimize,
                      onClick: () => {
                        setMinimize(true);
                      },
                    },
                  ]
                : null
            }
            headline={tc.cars}
            headlineSub={
              _stateCheck() ? tc.handleCars : <Loading small={true} />
            }
          />
        </div>
        {_stateCheck() ? (
          <div
            className={
              minimize
                ? "dealCarsWrapper__dealCars__content minimize"
                : "dealCarsWrapper__dealCars__content"
            }
          >
            <div className="dealCarsWrapper__dealCars__content__search">
              <div className="dealCarsWrapper__dealCars__content__search__top">
                <span>{tc.connectCars}:</span>
              </div>
              <div className="dealCarsWrapper__dealCars__content__search__bottom">
                <Search type="cars" save={_addCars} />
              </div>
            </div>
            <div className="dealCarsWrapper__dealCars__content__cars">
              {carRows}
            </div>
          </div>
        ) : null}
      </div>
      {removeCarFromDeal ? (
        <Popup
          close={() => {
            setRemoveCarFromDeal(null);
          }}
          size="small"
        >
          <div className="genericPopupContentWrapper">
            <div className="genericPopupContentWrapper__genericPopupContent">
              <div className="genericPopupContentWrapper__genericPopupContent__header">
                <WidgetHeader headline={tc.removeCar} />
              </div>
              <div className="genericPopupContentWrapper__genericPopupContent__content">
                <p>{tc.ensure}</p>
              </div>
              <div className="genericPopupContentWrapper__genericPopupContent__footer">
                <WidgetFooter
                  buttonOneFunc={async () => {
                    setRemoveCarFromDeal(null);
                    await _removeCar(removeCarFromDeal);
                  }}
                  buttonOneText={tc.remove}
                  buttonTwoFunc={() => {
                    setRemoveCarFromDeal(null);
                  }}
                  buttonTwoText={tc.cancel}
                />
              </div>
            </div>
          </div>
        </Popup>
      ) : null}
    </div>
  );
};

const MapStateToProps = (state, props) => {
  return {
    deal: state.deal,
    props: props,
    search: state.search,
    user: state.user,
  };
};

export default connect(MapStateToProps)(DealCars);
