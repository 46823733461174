import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LanguageContext from "language-context";
import { textHelper } from "helpers";
import { getFleetAnalysis, setFleetDisplayHistoric } from "store/fleet/tasks";
import { getUserAccess } from "store/user/tasks";
import { showFlashMessage } from "store/flash_messages/tasks";
import { connect } from "react-redux";
import Excel from "components/excel";
import FleetWidget from "components/fleet_widget";
import FleetAnalysis from "./fleet_analysis";
import Loading from "components/loading";
import Menu from "components/menu";
import PageHeader from "components/page_header";

/**
 * Display fleet page with Fleet widget and FleetAnalysis widget.
 */
const Fleet = (state) => {
  let { id } = useParams();
  id = textHelper.removeDashFromOrgnr(id);
  const koncernMode = !!window.location.pathname.includes("/vagnpark/koncern/");
  const [showExcel, setShowExcel] = useState(false);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    getFleetAnalysis({
      koncern: koncernMode,
      historic: false,
      prospectId: id,
    });
    getFleetAnalysis({
      koncern: koncernMode,
      historic: true,
      prospectId: id,
    });
  }, [id, koncernMode]);

  const _stateCheck = () => {
    return !!(
      state.fleet &&
      state.fleet.fleetAnalysis &&
      state.fleet.fleetAnalysisHistoric &&
      Object.keys(state.fleet.fleetAnalysis).length &&
      Object.keys(state.fleet.fleetAnalysisHistoric).length
    );
  };

  return _stateCheck() ? (
    <div className="fleetWrapper">
      <div className="fleetWrapper__fleet">
        <div className="fleetWrapper__fleet__header">
          <div className="fleetWrapper__fleet__header__top">
            <PageHeader
              headline={state.fleet.fleetAnalysis.target.name}
              headlineSub={koncernMode ? tc.koncernFleet : ""}
            />
          </div>
          <div className="fleetWrapper__fleet__header__bottom">
            <Menu
              items={[
                !getUserAccess().blockExcel
                  ? {
                      disabled: state.fleet.displayHistoric,
                      label: tc.excelOutput,
                      onClick: () => {
                        let rows;

                        if (state.fleet.displayHistoric) {
                          rows =
                            state.fleet.fleetHistoric &&
                            state.fleet.fleetHistoric.data &&
                            state.fleet.fleetHistoric.data.length
                              ? state.fleet.fleetHistoric.data.length
                              : 0;
                        } else {
                          rows =
                            state.fleet.fleet &&
                            state.fleet.fleet.data &&
                            state.fleet.fleet.data.length
                              ? state.fleet.fleet.data.length
                              : 0;
                        }

                        if (
                          getUserAccess().bigExcel ||
                          (!getUserAccess().bigExcel && rows < 5000)
                        ) {
                          setShowExcel(true);
                        } else {
                          showFlashMessage(tc.excelRowsLimit, "info");
                        }
                      },
                      type: "button",
                    }
                  : null,
              ]}
            />
          </div>
        </div>
        <div className="fleetWrapper__fleet__content">
          <div className="fleetWrapper__fleet__content__menu">
            <Menu
              skipSort={true}
              centered={true}
              type="floatFree"
              items={[
                {
                  active: !state.fleet.displayHistoric,
                  label: tc.fleetCurrent,
                  onClick: () => {
                    setFleetDisplayHistoric(false);
                  },
                  type: "button",
                },
                // {
                // HISTORISK VAGNPARK
                //   active: state.fleet.displayHistoric,
                //   label: tc.fleetHistoric,
                //   onClick: () => {
                //     setFleetDisplayHistoric(true);
                //   },
                //   type: "button",
                // },
              ]}
            />
          </div>
          <div className="fleetWrapper__fleet__content__item">
            <FleetAnalysis
              data={
                state.fleet.displayHistoric
                  ? state.fleet.fleetAnalysisHistoric
                  : state.fleet.fleetAnalysis
              }
              historic={state.fleet.displayHistoric}
            />
          </div>
          <div className="fleetWrapper__fleet__content__item">
            <FleetWidget
              koncern={koncernMode}
              historic={state.fleet.displayHistoric}
              prospectId={id}
            />
          </div>
          {showExcel ? (
            <Excel
              close={() => {
                setShowExcel(false);
              }}
              koncern={koncernMode}
              prospectId={id}
              origin="fleet"
            />
          ) : null}
        </div>
      </div>
    </div>
  ) : (
    <Loading />
  );
};

const MapStateToProps = (state) => {
  return {
    fleet: state.fleet,
  };
};

export default connect(MapStateToProps)(Fleet);
