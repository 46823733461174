import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import LanguageContext from 'language-context';
import { getPerson } from 'store/person/tasks';
import Activities from 'components/activities';
import CreateDeal from 'components/create_deal';
import Events from 'components/events';
import FleetWidget from 'components/fleet_widget';
import Loading from 'components/loading';
import Menu from 'components/menu';
import PageHeader from 'components/page_header';
import PersonInfo from './person_info';
import SaveToList from 'components/save_to_list';
import AddActivityToProspectPopup from '../activities/add_activity_to_prospect';

/**
 * Render person view.
 */
const Person = (state) => {
  const { id } = useParams();
  const [showCreateDeal, setShowCreateDeal] = useState(false);
  const [showSaveToList, setShowSaveToList] = useState(false);
  const [showAddActivity, setShowAddActivity] = useState(false);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    const getData = async () => {
      getPerson({ id: id });
    };

    getData();
  }, [id]);

  const _stateCheck = () => {
    return !!(
      state.person?.person &&
      Object.keys(state.person?.person).length &&
      state.person?.responsible &&
      state.person?.deals
    );
  };

  return _stateCheck() ? (
    <div className="personWrapper">
      <div className="personWrapper__person">
        <div className="personWrapper__person__header">
          <div className="personWrapper__person__header__top">
            <PageHeader headline={state.person.person.name} />
          </div>
          <div className="personWrapper__person__header__bottom">
            <Menu
              skipSort={true}
              items={[
                {
                  label: tc.saveToList,
                  onClick: () => {
                    setShowSaveToList(true);
                  },
                  type: 'button',
                },
                {
                  label: tc.createNewDeal,
                  onClick: () => {
                    setShowCreateDeal(true);
                  },
                  type: 'button',
                },
                {
                  label: tc.addActivity,
                  onClick: () => {
                    setShowAddActivity(true);
                  },
                  type: 'button',
                },
              ]}
            />
          </div>
        </div>
        <div className="personWrapper__person__content">
          <div className="personWrapper__person__content__item">
            <PersonInfo />
          </div>
          <div className="personWrapper__person__content__item">
            <Events onlyShowFlow={true} target={id} type="target" view="flow" />
          </div>
          <div className="personWrapper__person__content__item">
            <FleetWidget prospectId={id} />
          </div>
          {/* HISTORISK VAGNPARK
          <div className="personWrapper__person__content__item">
            <FleetWidget historic={true} prospectId={id} />
          </div> */}
          <div className="personWrapper__person__content__item">
            <Activities target={id} targetType="person" view="flow" />
          </div>
          {showCreateDeal ? (
            <CreateDeal
              close={async (dealCreated) => {
                // If deal is created we reload person data.
                if (dealCreated) {
                  setShowCreateDeal(false);
                  return await getPerson({ id: id });
                } else {
                  return setShowCreateDeal(false);
                }
              }}
              headline={
                tc.with +
                ' ' +
                tc.connection.toLowerCase() +
                ' ' +
                tc.to.toLowerCase() +
                ' ' +
                state.person.person.name
              }
              koncern={false}
              prospects={[{ id: id, name: state.person.person.name }]}
              target={id}
            />
          ) : null}
          {showSaveToList ? (
            <SaveToList
              close={() => {
                setShowSaveToList(false);
              }}
              prospects={[id]}
            />
          ) : null}
          {showAddActivity ? (
            <AddActivityToProspectPopup
              close={() => {
                setShowAddActivity(false);
              }}
              prospectId={id}
            />
          ) : null}
        </div>
      </div>
    </div>
  ) : (
    <Loading />
  );
};

const MapStateToProps = (state) => {
  return {
    person: state.person,
  };
};

export default connect(MapStateToProps)(Person);
