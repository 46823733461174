import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import LanguageContext from 'language-context';
import { createListOrder } from 'store/orders/tasks';
import costHelper from 'shared_helpers/cost_helper';
import listHelper from 'shared_helpers/list_helper';
import CreateListSubscription from 'components/create_list_subscription/create_list_subscription';
import Icon from 'components/icon';
import Info from 'components/info';
import Loading from 'components/loading';
import WidgetFooter from 'components/widget_footer';
import WidgetHeader from 'components/widget_header';
import { miscHelper } from 'helpers';

const OrdersName = (state) => {
  const { list } = state.props;
  const haveProspects =
    list.prospectInformation &&
    list.prospectInformation.privatePersons &&
    list.prospectInformation.privatePersons > 0;
  //const nameIsPending = list?.orderHistory?.name?.isPending;
  const nameIsDelivered = list?.orderHistory?.name?.isDelivered;
  const nameIsFresh = nameIsDelivered && list.orderHistory.name.isAvailable;
  const orderIsPending = miscHelper.hasPendingOrder(list);

  const [orderPhone, setOrderPhone] = useState(false);
  const [isOrdering, setIsOrdering] = useState(false);
  const [showCreateListSubscription, setShowCreateListSubscription] =
    useState(false);
  const tc = useContext(LanguageContext);

  return list && list.prospectInformation ? (
    <div className="ordersInformationWrapper">
      <div className="ordersInformationWrapper__ordersInformation">
        <div className="ordersInformationWrapper__ordersInformation__content">
          {haveProspects && !orderIsPending ? (
            <div className="ordersInformationWrapper__ordersInformation__content__section">
              <div className="ordersInformationWrapper__ordersInformation__content__section__title">
                <WidgetHeader headline={tc.orderName} />
              </div>
              <div className="ordersInformationWrapper__ordersInformation__content__section__block">
                <span className="label">{tc.deliveryTime}</span>
                <p>{tc.deliveryTime1}</p>
                <p>{tc.deliveryTime2}</p>
                <p>{tc.deliveryTime3}</p>
                <p>{tc.deliveryTime4}</p>
              </div>
              <div className="ordersInformationWrapper__ordersInformation__content__section__block">
                <span className="label">{tc.prices}</span>
                <div className="flexParent">
                  <p
                    className="includeValue"
                    onClick={() => {
                      setOrderPhone(!orderPhone);
                    }}
                  >
                    <Icon val={orderPhone ? 'check' : 'checkbox'} />
                    {tc.includePhone}
                  </p>
                </div>
                <p>
                  <span className="lineThrough">
                    {tc.priceStart}:{' '}
                    {costHelper.getStartCost(
                      list.prospectInformation.privatePersons,
                      orderPhone
                    )}{' '}
                    {tc.swedishCrowns.toLowerCase()}
                  </span>
                </p>
                <p>
                  {tc.priceStartPhone}:{' '}
                  {costHelper.getStartCostPhone(
                    list.prospectInformation.privatePersons,
                    orderPhone
                  )}{' '}
                  {tc.swedishCrowns.toLowerCase()}
                </p>
                <p>
                  {tc.priceName}:{' '}
                  {costHelper.getRawCost(
                    list.prospectInformation.privatePersons,
                    orderPhone
                  )}{' '}
                  {tc.swedishCrowns.toLowerCase()}
                </p>
                <p>
                  {tc.pricePhone}:{' '}
                  {costHelper.getPhoneCost(
                    list.prospectInformation.privatePersons,
                    orderPhone
                  )}{' '}
                  {tc.swedishCrowns.toLowerCase()}
                </p>
                <p className="priceSum">
                  {tc.priceSum}:{' '}
                  <strong className="highlight">
                    {
                      // costHelper.getStartCost(list.prospectInformation.privatePersons, orderPhone) +
                      costHelper.getStartCostPhone(
                        list.prospectInformation.privatePersons,
                        orderPhone
                      ) +
                        costHelper.getRawCost(
                          list.prospectInformation.privatePersons,
                          orderPhone
                        ) +
                        costHelper.getPhoneCost(
                          list.prospectInformation.privatePersons,
                          orderPhone
                        )
                    }{' '}
                    {tc.swedishCrowns.toLowerCase()}
                  </strong>
                </p>
                {nameIsFresh ? (
                  <p>
                    <strong>{tc.noteThatDataIsFresh}</strong>
                  </p>
                ) : null}
              </div>
            </div>
          ) : (
            <>
              {orderIsPending ? (
                <>
                  <Info>
                    <h4>{tc.orderIsPending}</h4>
                    <p>{tc.cannotOrderIsPending}</p>
                  </Info>
                </>
              ) : null}
              {!haveProspects && !orderIsPending ? (
                <>
                  <Info>
                    <h4>{tc.noPrivatePersons}</h4>
                    <p>{tc.noOrderNeededCompanyDataIsAvailable}</p>
                  </Info>
                </>
              ) : null}
            </>
          )}
        </div>
        {haveProspects && !orderIsPending ? (
          <div className="ordersInformationWrapper__ordersInformation__footer">
            <div className="minHeight40">
              {isOrdering ? (
                <>
                  <p>{tc.creatingOrder}...</p>
                  {isOrdering ? <Loading small={true} /> : null}
                </>
              ) : null}
            </div>
            <WidgetFooter
              buttonOneFunc={async () => {
                setIsOrdering(true);
                await createListOrder({
                  orderName: true,
                  orderPhone: orderPhone,
                });
              }}
              buttonOneText={tc.placeOrder}
              disableButtonOne={orderIsPending || isOrdering}
              buttonTwoFunc={() => {
                setShowCreateListSubscription(true);
              }}
              buttonTwoText={tc.automateOrder}
              disableButtonTwo={orderIsPending || isOrdering}
              noBorder={true}
            />
          </div>
        ) : null}
      </div>
      {showCreateListSubscription ? (
        <CreateListSubscription
          close={() => {
            setShowCreateListSubscription(false);
          }}
          lists={[state.orders?.list]}
          source="orders"
          subscribeFlag={
            orderPhone
              ? listHelper.subscriptionFlags.excludeProspects ^
                listHelper.subscriptionFlags.name ^
                listHelper.subscriptionFlags.phone
              : listHelper.subscriptionFlags.excludeProspects ^
                listHelper.subscriptionFlags.name
          }
        />
      ) : null}
    </div>
  ) : (
    <Loading />
  );
};

const MapStateToProps = (state, props) => {
  return {
    orders: state.orders,
    props: props,
  };
};

export default connect(MapStateToProps)(OrdersName);
