import moment from 'moment';
import React, { useContext } from 'react';
import languageContext from 'language-context';

const ContactItem = ({ contact }) => {
  const tc = useContext(languageContext);
  return (
    <div
      className="agileBoardPopupWrapper__agilePopup__content__block__content__contact"
      key={contact._id}
    >
      <div className="agileBoardPopupWrapper__agilePopup__content__block__content__contact__content">
        <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow">
          <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__left">
            {tc.name}:
          </div>
          <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__right">
            <p className="capitalize">
              {contact.name?.toLowerCase() || tc.missing}
            </p>
          </div>
        </div>
        {contact.tele?.length ? (
          <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow">
            <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__left">
              {tc.phone}:
            </div>
            <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__right">
              {contact.tele.map((tel, i) => {
                return (
                  <React.Fragment key={tel}>
                    <a href={'tel:+46' + tel}>{tel}</a>
                    <span>{i !== contact.tele.length - 1 ? ', ' : ''}</span>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        ) : null}
        {contact.email?.length ? (
          <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow">
            <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__left">
              {tc.email}:
            </div>
            <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__right">
              {contact.email.map((num, i) => {
                if (i === contact.email.length - 1) {
                  return <p key={num + i}>{num}</p>;
                } else {
                  return <p key={num + i}>{num}, </p>;
                }
              })}
            </div>
          </div>
        ) : null}
        {contact.comment?.length ? (
          <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow">
            <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__left">
              {tc.comment}:
            </div>
            <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__right">
              {contact.comment}
            </div>
          </div>
        ) : null}
        {contact.updated && contact.updated !== '-1' ? (
          <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow">
            <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__left">
              {tc.updated}:
            </div>
            <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__right">
              {moment(new Date(contact.updated), 'DD/MM/YYYY').fromNow()}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ContactItem;
