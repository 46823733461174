import { markAsRead, setItemToDisplay } from "store/news/tasks";
import DefaultNewsImage from "../../images/home_page/header_graphic.svg";
import moment from "moment";
import history from "router-history";

const NewsItem = ({ item }) => {
  return (
    <div
      className="newsWidgetWrapper__newsWidget__content__archive__items__item"
      key={item.id}
    >
      <div className="newsWidgetWrapper__newsWidget__content__archive__items__item__top">
        <div className="newsWidgetWrapper__newsWidget__content__archive__items__item__top__img">
          <img
            className={item.img ? "" : "default"}
            src={item.img ? item.img : DefaultNewsImage}
            alt={item.headline}
          />
        </div>
      </div>
      <div className="newsWidgetWrapper__newsWidget__content__archive__items__item__bottom">
        <div
          className="newsWidgetWrapper__newsWidget__content__archive__items__item__bottom__headline"
          onClick={() => {
            if (!item.userHasRead) markAsRead(item.id);
            setItemToDisplay(item);
            history.push("/nyheter");
          }}
        >
          {item.headline}
        </div>
        <div className="newsWidgetWrapper__newsWidget__content__archive__items__item__bottom__dateAndName">
          <div className="newsWidgetWrapper__newsWidget__content__archive__items__item__bottom__dateAndName__date">
            {moment(new Date(item.updated)).format("LL")}
          </div>
          {item.author?.name ? (
            <div className="newsWidgetWrapper__newsWidget__content__archive__items__item__bottom__dateAndName__name">
              {item.author.name}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default NewsItem;
