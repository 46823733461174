import SingleRowNew from "./single_row_new";
import TableHeader from "./table_header";
import Icon from "components/icon";

/**
 *
 * @param {{data: {lift: number, call: number, meeting: number, deals: {}, }, user: string, types: string[], hideInspect: () => void}} props - data sent from parent
 * @param props.data - has key value pairs of how many actions there were of each type, corresponds to types array, plus a deals object with information about each deal. Note that keys in documentation are just examples
 * @param props.user - name of the user being inspected
 * @param props.types - array of actions that will be displayed
 * @param props.hideInspect - function to call when you want to go back to overview table
 */
const ActivityTypesTableInspect = ({ data, user, types, hideInspect }) => {
  return (
    <div className="activityTypesTableWrapper cancelDrag">
      <div className="activityTypesTableWrapper__activityTypesTable">
        <div
          style={{ marginTop: "0" }}
          className="activityTypesTableWrapper__activityTypesTable__content"
        >
          <button
            style={{
              position: "sticky",
              left: "0",
              top: "0",
              marginBottom: "20px",
            }}
            className="backIcon"
            onClick={hideInspect}
          >
            <Icon val="arrowBack" />
          </button>
          <TableHeader types={types} />
          <div className="activityTypesTableWrapper__activityTypesTable__content__table">
            <div className="activityTypesTableWrapper__activityTypesTable__content__table__rowsHolder">
              <div className="activityTypesTableWrapper__activityTypesTable__content__table__rowsHolder__rows">
                {/*keep displaying clicked user's row when inspecting a user's deals*/}
                <SingleRowNew
                  key={user}
                  name={user}
                  data={data}
                  keys={types}
                  totals={data}
                />
                {Object.keys(data.deals).map((deal) => {
                  return (
                    <SingleRowNew
                      key={deal}
                      name={deal}
                      keys={types}
                      data={data.deals[deal]}
                      totals={data}
                      isDeal={true}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityTypesTableInspect;
