import React, { useContext, useEffect, useRef, useState } from 'react';
import { miscHelper } from 'helpers';
import cssVariables from '../../styles/_exported_variables.module.scss';
import LanguageContext from 'language-context';
import ReactDOM from 'react-dom';
import Icon from 'components/icon';
import Popper from 'components/popper';

/**
 * Dropdown component, should be used with <DropdownItem>.
 *
 * We render this with Popper component which means we have to set some specific styling for the dropdown list.
 * We use Popper (react-portal) so we can display the dropdown list outside of parents that have overflow: hidden/scroll etc, like Menu component.
 *
 * @param props.children - Should be array of <DropdownItem>.
 * @param props.closeOnItemClick - bool (optional) - If we want to close dropdown every time we click an item.
 * @param props.onClose - function (optional) - Function to call when dropdown is closed.
 * @param props.displayValue - string - Value to be displayed at top (such as active value, placeholder etc...)
 * @param props.headerElement - element (optional) - Replace header with this.
 * @param props.source - 'menu' (optional) - Right now this is only relevant for when dropdown is used in Menu component, where we need to adjust dropdown style in lower screen resolutions.
 * @param props.styling.adjustToHeader - bool (optional) - If dropdown should be same width as the header/wrapper.
 * @param props.styling.alignRight - bool (optional) - If dropdown should be right aligned, special cases.
 * @param props.styling.extraWidth - number (optional) - Represents pixels.
 * @param props.styling.offsetLeft - number (optional) - Represents pixels.
 * @param props.styling.offsetTop - number (optional) - Represents pixels.
 * @param props.transparent - boolean (optional) - If we want the header(displayValue) to be transparent.
 * @param props.closeDropdown - bool (optional) - If provided and true, the dropdown will be closed externally.
 * @param props.headerColor - color of thea dropdown header - string of color code: example #fffff
 * @param props.textColor - color of header text - White or Black
 * @param props.fontSize - size of fonts - string and choose between white or black
 * @param props.headerHeight - size of header element, use small, medium, big and XL
 */
export const Dropdown = (props) => {
  const [styling, setStyling] = useState({
    maxHeight: '0',
    top: 0,
    left: props.alignRight ? 'unset' : 0,
    right: props.alignRight ? 0 : 'unset',
    minWidth: '0',
    maxWidth: '0',
    width: '0',
  });
  const [showDropdown, setShowDropdown] = useState(false);
  const [kids, setKids] = useState(props.children);
  let dropdownRef = useRef(null);
  let dropdownContentRef = useRef(null);
  const tc = useContext(LanguageContext);
  const { onClose } = props;
  const dropdownHasOpened = useRef(false);

  function handleClick(cb) {
    return () => {
      setShowDropdown(false);
      cb();
    };
  }
  useEffect(() => {
    if (props.closeDropdown) {
      setShowDropdown(false);
    }
  }, [props.closeDropdown]);
  useEffect(() => {
    const timer = setTimeout(() => {
      _adjustStyling(true);
    }, 700);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    _adjustStyling();
  }, [showDropdown]);

  useEffect(() => {
    if (showDropdown) return;
    if (!dropdownHasOpened.current) return;
    if (onClose && typeof onClose === 'function') {
      onClose();
      // infinitely loops otherwise
      dropdownHasOpened.current = false;
    }
  }, [showDropdown, onClose]);

  /**
   * We use this function to wrap the callback functions so we can close the dropdown immediately... the missing feature!
   */
  useEffect(() => {
    function handleClick(cb) {
      return () => {
        setShowDropdown(false);
        cb();
      };
    }
    if (Array.isArray(props.children)) {
      let offspring = props.autoClose
        ? props.children.map((child) => ({
            ...child,
            props: {
              ...props,
              ...child.props,
              onClick: handleClick(child.props.onClick),
            },
          }))
        : props.children;
      setKids(offspring);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.children]);

  useEffect(() => {
    const navigationFooter = document.querySelector(
      '.navigationWrapper__navigation__footer'
    );
    if (navigationFooter) {
      navigationFooter.addEventListener('mousedown', _adjustStyling);
    }
    window.addEventListener('resize', _adjustStyling);
    window.addEventListener('scroll', _adjustStyling);
    return () => {
      const navigationFooter = document.querySelector(
        '.navigationWrapper__navigation__footer'
      );
      if (navigationFooter) {
        navigationFooter.removeEventListener('mousedown', _adjustStyling);
      }
      window.removeEventListener('resize', _adjustStyling);
      window.removeEventListener('scroll', _adjustStyling);
    };
  }, []);

  useEffect(() => {
    /**
     * When clicking outside dropdown box, close it.
     */
    const _unmountDropdown = (e) => {
      if (
        miscHelper.clickUnmount(e, dropdownContentRef, false, true, dropdownRef)
      ) {
        setShowDropdown(false);
      }
    };

    window.addEventListener('mousedown', _unmountDropdown);
    return () => window.removeEventListener('mousedown', _unmountDropdown);
  }, []);

  /**
   * Set position top and left in correlation to dropdownWrapper (needs to have position relative),
   * with possible offsets in props.
   * Set width in correlation to dropdownWrapper, with possible extra width in props.
   * Make sure we dont go outside viewport.
   * */
  const _adjustStyling = (firstRender = false) => {
    if (dropdownRef?.current && (showDropdown || firstRender)) {
      const node = ReactDOM.findDOMNode(dropdownRef.current);
      if (node) {
        const maxWidthMaxValue = 420;
        const minWidthMinValue = 120;
        const extraWidth = Number.isFinite(props.styling?.extraWidth)
          ? props.styling.extraWidth
          : 0;
        const offsetLeft = Number.isFinite(props.styling?.offsetLeft)
          ? props.styling.offsetLeft
          : 0;
        const offsetTop = Number.isFinite(props.styling?.offsetTop)
          ? props.styling.offsetTop
          : 2;

        let left =
          window.pageXOffset + node.getBoundingClientRect().left + offsetLeft;

        let right =
          window.innerWidth -
          node.getBoundingClientRect().right -
          node.getBoundingClientRect().width;

        const navigation = document.querySelector('.navigationWrapper');
        if (navigation) {
          // Should never cover our main navigation to the left.
          if (left < navigation.getBoundingClientRect().width) {
            left = navigation.getBoundingClientRect().width + 1;
          }
        }

        let top =
          window.pageYOffset +
          node.getBoundingClientRect().top +
          node.getBoundingClientRect().height +
          offsetTop;

        let maxWidth = props.alignRight
          ? window.innerWidth -
            right -
            +cssVariables.paddingGrid.replace('px', '') -
            1
          : window.innerWidth -
            left -
            +cssVariables.paddingGrid.replace('px', '') -
            1;

        if (maxWidth > maxWidthMaxValue && !props.styling?.adjustToHeader) {
          maxWidth = maxWidthMaxValue;
        }

        let minWidth = node.getBoundingClientRect().width + extraWidth;

        if (minWidth > maxWidth) {
          minWidth = maxWidth;
        }

        let width = props.styling?.adjustToHeader ? minWidth : maxWidth;

        if (width < minWidthMinValue) {
          // When dropdown doesn't get enough room to at least minWidthMinValue (because screen width is running out).
          // Adjust width and move dropdown to the left;
          const diff = minWidthMinValue - width;
          width = minWidthMinValue;
          minWidth = minWidthMinValue;
          left = left - diff;
          if (maxWidth < minWidth) {
            maxWidth = minWidth;
          }
        }

        if (props.isForTableColumnFilters) {
          width = width - 60;
        }

        setStyling({
          maxHeight: `calc(100vh - ${
            node.getBoundingClientRect().top +
            node.getBoundingClientRect().height +
            12 +
            offsetTop
          }px)`,
          top: top,
          left: props.alignRight ? 'unset' : left,
          right: props.alignRight ? right : 'unset',
          minWidth: `${minWidth}px`,
          maxWidth: `${maxWidth}px`,
          width: `${width}px`,
        });
      }
    }
  };
  const toggleDropdown = () => {
    if (showDropdown) {
      setShowDropdown(false);
    } else {
      dropdownHasOpened.current = true;
      setShowDropdown(true);
    }
  };
  const colFilterClass = props.isForTableColumnFilters
    ? ' tableColumnFilters'
    : '';

  return (
    <div
      className={`dropdownWrapper ${
        props.headerElement ? 'customHeaderElement' : ''
      }`}
      ref={dropdownRef}
    >
      <div className="dropdownWrapper__dropdown">
        {props.headerElement ? (
          <div
            onClick={() => {
              toggleDropdown();
            }}
          >
            {props.headerElement}
          </div>
        ) : (
          <div
            className={`dropdownWrapper__dropdown__header ${
              props.transparent ? 'transparent' : ''
            } ${props.headerHeight ? props.headerHeight : ''}`}
            style={{
              background: props.headerColor,
            }}
            onClick={() => {
              if (showDropdown) {
                setShowDropdown(false);
              } else {
                dropdownHasOpened.current = true;
                setShowDropdown(true);
              }
            }}
          >
            <p
              className={`dropdownWrapper__dropdown__header__text__${props.textColor}`}
              style={{ fontSize: props.fontSize ? props.fontSize : '' }}
            >
              {props.displayValue}
            </p>
            {showDropdown ? <Icon val="minimize" /> : <Icon val="maximize" />}
          </div>
        )}
        <Popper>
          <div
            className={
              showDropdown
                ? props.source === 'menu'
                  ? 'dropdownWrapper__dropdown__content sourceMenu' +
                    colFilterClass
                  : 'dropdownWrapper__dropdown__content' + colFilterClass
                : 'dropdownWrapper__dropdown__content minimize' + colFilterClass
            }
            ref={dropdownContentRef}
            style={styling}
            onClick={() => {
              if (props.closeOnItemClick) {
                setShowDropdown(false);
              }
            }}
          >
            {/* {kids} */}
            {Array.isArray(props.children)
              ? props.autoClose
                ? props.children.map((child) => ({
                    ...child,
                    props: {
                      ...props,
                      ...child.props,
                      onClick: handleClick(child.props.onClick),
                    },
                  }))
                : props.children
              : null}
          </div>
        </Popper>
      </div>
    </div>
  );
};
