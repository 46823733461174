import React from "react";

const MenuElement = (props) => {
  return (
    <div className="menuElementWrapper">
      <div
        className={
          props.disabled
            ? "menuElementWrapper__menuElement disabled"
            : props.active
            ? "menuElementWrapper__menuElement active"
            : "menuElementWrapper__menuElement"
        }
      >
        {props.element}
      </div>
    </div>
  );
};

export default MenuElement;
