import { store } from 'store';
import { activityHelper, request } from 'helpers';
import { activitiesActionTypes } from './actions';
import { showFlashMessage } from 'store/flash_messages/tasks';
import * as text from 'text-content';
import { event } from 'tracking';
import { widgetHelper } from 'helpers/widget_helper';
// import { debounce } from "debounce";

/**
 * Get activities main func.
 *
 * @param payload.type - string - Can be 'filter' | 'last' | 'target'.
 * @pparam payload.target  - string - Only required/working when type === 'target'. Can be prospect id, car reg number, deal id or lead id.
 */
export const getActivities = async (payload) => {
  if (payload.type !== 'last') {
    // Save search.
    store.dispatch({
      type: activitiesActionTypes.ACTIVITIES_SET_PREVIOUS_SEARCH,
      payload: payload,
    });
  }

  switch (payload.type) {
    case 'filter':
      return await getActivitiesByFilter();
    case 'last':
      const previousSearch = store.getState().activities?.previousSearch
        ? store.getState().activities.previousSearch
        : null;
      if (previousSearch) {
        return await getActivities(previousSearch);
      } else {
        return;
      }
    case 'target':
      return await getActivitiesByTarget({
        koncern: payload.koncern,
        target: payload.target,
        targetType: payload.targetType,
      });
    default:
      return await getActivitiesByFilter();
  }
};

/**
 * Retrieve activities based on filter.
 */
const getActivitiesByFilter = async () => {
  try {
    const filter = store.getState().activity.filter;

    event({
      category: 'activity',
      action: 'activity_activities_filter',
    });

    const data = await request({
      data: {
        date: filter.date,
        lists: filter.lists,
        users: filter.users,
        prospectTypes: filter.prospectTypes,
      },
      method: 'post',
      url: '/activity/getActivityByDateListsUsers/',
    });

    let result = data?.length && !(data instanceof Error) ? data : [];

    return store.dispatch({
      type: activitiesActionTypes.ACTIVITIES_SET_ACTIVITIES_BY_FILTER,
      payload: result,
    });
  } catch (err) {
    return console.error('Error in getActivitiesByFilter', err);
  }
};

export const activitiesByFilterToExcel = async (
  type,
  groupByDealers,
  types
) => {
  try {
    const state = store.getState();
    const filter = state.activity.filter;
    const allUsers = state.user.connections
      .reduce((acc, item) => acc.concat(item.users), [])
      .concat(state.user.colleagues);
    const goalUsers = filter.users
      .map((user) => allUsers.find((u) => u.id === user))
      .filter((user) => user && user.id && user.dealer_id)
      .map((user) => ({ userId: user.id, dealerId: user.dealer_id }));

    const data = await request({
      data: {
        date: filter.date,
        lists: filter.lists,
        users: filter.users,
        type,
        types,
        groupByDealers,
        prospectTypes: filter.prospectTypes,
        goalUsers,
      },
      method: 'post',
      url: '/activity/activityByDateListsUsersToExcel',
    });

    if (!data.url) throw new Error('Bad response');

    return data;
  } catch (err) {
    console.error('Error in getActivitiesByFilter', err);
    return { success: false, error: true };
  }
};

/**
 * Retrieve activities based on target id.
 *
 * @param payload.koncern - bool - When retrieving activities for the whole koncern that target is a part of.
 * @param payload.target - string - Can be a prospect id or a deal id.
 * @param payload.targetType - string - "deal", "prospect", "vehicle"
 */
export const getActivitiesByTarget = async (payload) => {
  try {
    if (!payload || (payload && !payload.target)) {
      return console.error('Missing target in getActivitiesByTarget');
    }

    store.dispatch({
      type: activitiesActionTypes.ACTIVITIES_SET_ACTIVITIES_BY_TARGET,
      payload: null,
    });

    const data = await request({
      data: {
        koncern: !!payload.koncern,
        targetType: payload.targetType,
      },
      method: 'get',
      url: '/activity/getActivityByTarget/' + payload.target,
    });

    let activities = data?.length && !(data instanceof Error) ? data : [];

    store.dispatch({
      type: activitiesActionTypes.ACTIVITIES_SET_ACTIVITIES_BY_TARGET,
      payload: activities,
    });
    return activities;
  } catch (err) {
    console.error('Error in getActivitiesByTarget:', err);
  }
};

export const resetActivitiesByTarget = () => {
  store.dispatch({
    type: activitiesActionTypes.ACTIVITIES_SET_ACTIVITIES_BY_TARGET,
    payload: null,
  });
};

/**
 * Retrieve data for an activities subscription.

 * We use search criterias from a dashboard widget object to make a search and then we save the result on the id for that widget object.
 * A user can have multiple pipeline subscriptions, so every search result is saved to its unique id.
 *
 * @param payload.currentMonth - bool - If currentWeek or currentMonth is true, date is irrelevant.
 * @param payload.currentWeek - bool - If currentWeek or currentMonth is true, date is irrelevant.
 * @param payload.date - obj - {from: ISOdate, to: ISOdate}
 * @param payload.id - string - Dashboard widget id.
 * @param payload.listIds - string (optional) - Dashboard widget id.
 * @param payload.userIds - string - Dashboard widget id.
 */
export const getActivitiesSubscriptionData = async (payload) => {
  try {
    if (!payload?.id || !payload?.userIds?.length) {
      return console.error(
        'Missing params in getActivitiesSubscriptionData:',
        payload
      );
    }

    store.dispatch({
      type: activitiesActionTypes.ACTIVITIES_SET_SUBSCRIPTION_LOADING,
      payload: {
        id: payload.id,
        type: 'add',
      },
    });

    let date = widgetHelper.parseWidgetDate(payload);

    const data = await request({
      data: {
        date: date,
        lists: payload.listIds,
        users: payload.userIds,
      },
      method: 'post',
      url: '/activity/getActivityByDateListsUsers/',
    });

    if (data instanceof Error) {
      console.error('Error in getActivitiesSubscriptionData', data);
      store.dispatch({
        type: activitiesActionTypes.ACTIVITIES_SET_SUBSCRIPTION_LOADING,
        payload: {
          id: payload.id,
          type: 'remove',
        },
      });

      return store.dispatch({
        type: activitiesActionTypes.ACTIVITIES_SET_ACTIVITIES_SUBSCRIPTION,
        payload: {
          id: payload.id,
          data: [],
        },
      });
    }

    let result = Array.isArray(data) ? data : [];

    store.dispatch({
      type: activitiesActionTypes.ACTIVITIES_SET_SUBSCRIPTION_LOADING,
      payload: {
        id: payload.id,
        type: 'remove',
      },
    });

    return store.dispatch({
      type: activitiesActionTypes.ACTIVITIES_SET_ACTIVITIES_SUBSCRIPTION,
      payload: {
        id: payload.id,
        data: result,
      },
    });
  } catch (err) {
    return console.error('Error in getActivitiesSubscriptionData:', err);
  }
};

export const getActivitiesSubscriptionDataCount = async (payload) => {
  try {
    const date = widgetHelper.parseWidgetDate(payload);

    const data = await request({
      method: 'get',
      url:
        '/activity/getActivityCount' +
        '?' +
        new URLSearchParams({
          ...payload,
          ...date,
        }),
    });

    if (data instanceof Error) {
      console.error('Error in getActivitiesSubscriptionDataCount', data);
    }

    let result = typeof data === 'object' ? data : {};
    return result;
  } catch (err) {
    return console.error('Error in getActivitiesSubscriptionData:', err);
  }
};

// export const getActivitiesSubscriptionData = debounce(
//   getActivitiesSubscriptionDataDebounced,
//   2000
// );

/**
 * Remove a historic activity/deal action.
 *
 * @param payload.id - string - Activity id (deal_action _id).
 */
export const removeActivity = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (!payload?.id) {
      return console.error('Missing params in removeActivity', payload);
    }

    const data = await request({
      data: payload,
      method: 'delete',
      url: '/deals/actions/',
    });

    if (data instanceof Error) {
      console.error('Error in removeActivity', data);
      return showFlashMessage(tc.genericFailMessage, 'fail');
    }

    showFlashMessage(tc.activityWasRemoved, 'success');
    getActivities({ type: 'last' });
  } catch (err) {
    console.error('Error in removeActivity', err);
    showFlashMessage(tc.genericFailMessage, 'fail');
  }
};

export const addActivityToProspect = async (payload: {
  prospectId: string;
  action: string;
  comment: string;
  date: Date;
}) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;
  try {
    const { prospectId, action, comment, date } = payload;

    if (!prospectId || !action || !date) {
      console.error(
        'Invalid arguments provided to addActivityToProspect: ',
        payload
      );
      showFlashMessage(tc.addActivityFail, 'fail');
      return;
    }

    const data = await request({
      data: { prospectId, action, comment, date },
      method: 'post',
      url: '/prospectActions',
    });

    if (!data || data instanceof Error) {
      showFlashMessage(tc.addActivityFail, 'fail');
      return;
    }
    showFlashMessage(tc.activityHasBeenSaved, 'success');
  } catch (e) {
    console.error('Error in addActivityToProspect: ', e);
    showFlashMessage(tc.addActivityFail, 'fail');
    return;
  }
};

/**
 * Update a historic activity/deal action.
 * Possibility to update action or comment. Note that comment can be an empty string.
 *
 * @param payload.action - string (optional) - When updating activity action.
 * @param payload.comment - string (optional) - When updating activity comment.
 * @param payload.id - string - Activity id (deal_action _id).
 */
export const updateActivity = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (
      !payload?.id ||
      (typeof payload.action !== 'string' &&
        typeof payload.comment !== 'string')
    ) {
      return console.error('Missing params in updateActivity', payload);
    }

    const data = await request({
      data: payload,
      method: 'put',
      url: '/deals/actions/',
    });

    if (data instanceof Error) {
      console.error('Error in updateActivity', data);
      return showFlashMessage(tc.genericFailMessage, 'fail');
    }

    showFlashMessage(tc.activityWasUpdated, 'success');
    getActivities({ type: 'last' });
  } catch (err) {
    console.error('Error in updateActivity', err);
    showFlashMessage(tc.genericFailMessage, 'fail');
  }
};

export const getActivityByQuery = async (payload) => {
  let date = widgetHelper.parseWidgetDate(payload);

  const data = await request({
    method: 'GET',
    url:
      '/activity/getActivityPaginated' +
      '?' +
      new URLSearchParams({ ...payload, ...date }),
  });
  return data;
};

export const getUserActivityByQuery = async (payload) => {
  let date = widgetHelper.parseWidgetDate(payload);

  const data = await request({
    method: 'GET',
    url:
      '/activity/getActivityCountByUser' +
      '?' +
      new URLSearchParams({ ...payload, ...date }),
  });
  return data;
};

export const getActivityGoals = async (payload) => {
  try {
    if (
      !payload ||
      !payload.users ||
      (payload.users && !payload.users.length)
    ) {
      const userInfo = store.getState().user.info;
      return await request({
        method: 'post',
        url: `/activity/getActivityGoalsById`,
        data: {
          users: [{ userId: userInfo.id, dealerId: userInfo.dealer_id }],
        },
      });
    }

    const data = await request({
      method: 'post',
      url: `/activity/getActivityGoalsById`,
      data: {
        users: payload.users,
      },
    });

    if (data instanceof Error) {
      return console.error('Error in getActivityGoals', data);
    }

    return data;
  } catch (err) {
    return console.error('Error in getActivityGoals: ', err);
  }
};

export const setActivityGoals = async (payload) => {
  try {
    if (!payload && !payload.goals && !payload.id) {
      return console.error('Missing params in setActivityGoals', payload);
    }

    const data = await request({
      data: {
        goals: payload.goals,
        userId: payload.userId,
        dealerId: payload.dealerId,
      },
      method: 'put',
      url: '/activity/setActivityGoals',
    });

    if (data instanceof Error) {
      throw new Error(`Error in getActivityGoals: ${data}`);
    }
  } catch (err) {
    console.error(err);
  }
};
