import React, { useContext } from 'react';
import moment from 'moment';
import languageContext from 'language-context';
import Icon from 'components/icon';
import Loading from 'components/loading';
import { dealHelper, agileHelper } from 'helpers';
import { connect } from 'react-redux';
import { updateDeal } from 'store/deal/tasks';
import FoldableSection from './foldable_section';

const AgileBoardPopupDealInfo = ({
  hideBlocks,
  previewData,
  getColumnsData,
  getPreviewData,
  state,
  setPreviewData,
  editPotential,
  setEditPotential,
  selectedPotential,
  setSelectedPotential,
  toggleSection = () => {},
}) => {
  const tc = useContext(languageContext);
  const isDeal = previewData?.item?.type === 'deal';

  return (
    <FoldableSection
      toggleSection={toggleSection}
      active={!hideBlocks.includes('dealInfo')}
      label={'Affärsinformation'}
      renderNothing={!isDeal}
    >
      <div className="agileBoardPopupWrapper__agilePopup__content__block">
        <div className="agileBoardPopupWrapper__agilePopup__content__block__content">
          <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow">
            <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__left">
              {tc.owner}:
            </div>
            <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__right">
              {previewData.item.user?.name
                ? previewData.item.user.name
                : tc.unknown}
            </div>
          </div>
          <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow">
            <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__left">
              {tc.belongsToList}:
            </div>
            <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__right">
              {previewData.item.listName ? (
                previewData.item.listName
              ) : (
                <Loading small={true} />
              )}
            </div>
          </div>
          {isDeal ? (
            <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow">
              <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__left">
                {tc.potential}:
              </div>
              <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__right noFlexWrap">
                {editPotential ? (
                  <>
                    <input
                      onChange={(e) => {
                        let potential = selectedPotential;
                        if (Number.isFinite(+e.target.value)) {
                          // Make sure potential is a monetary value (int).
                          potential = +e.target.value;
                        }

                        setSelectedPotential(potential);
                      }}
                      type="text"
                      value={selectedPotential}
                      className="agilePopupWrapper__agilePopup__content__block__content__input"
                    />
                    <Icon
                      val="clear"
                      onClick={() => {
                        setEditPotential(false);
                        setSelectedPotential(previewData.item.potential);
                      }}
                    />
                    <Icon
                      val="save"
                      onClick={async () => {
                        setEditPotential(false);
                        // Set value in state directly before retrieving updated data from backend, it's nicer for the user.
                        setPreviewData({
                          ...previewData,
                          item: {
                            ...previewData.item,
                            potential: selectedPotential,
                          },
                        });
                        await updateDeal({
                          id: previewData.item.id,
                          potential: +selectedPotential,
                        });
                        await getColumnsData({});
                        await getPreviewData({
                          id: previewData.item.id,
                          getSettings: true,
                        });
                      }}
                    />
                  </>
                ) : (
                  <>
                    {previewData.item.potential}
                    <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__right__icons noFlexWrap">
                      {previewData.item &&
                      previewData.prospectInformation &&
                      previewData.contacts &&
                      state.activities.activitiesByTarget ? (
                        // Wait for all data to be loaded to avoid accidental edit of wrong deal.
                        <Icon
                          val="edit"
                          onClick={() => {
                            setEditPotential(true);
                          }}
                        />
                      ) : null}
                    </div>
                  </>
                )}
              </div>
            </div>
          ) : null}
          {isDeal ? (
            <>
              <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow">
                <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__left">
                  {tc.phase}:
                </div>
                <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__right">
                  {agileHelper.buildPhaseToColumnTitle(previewData.item.phase)}
                </div>
              </div>
            </>
          ) : null}
          {isDeal ? (
            <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow">
              <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__left">
                {tc.created}:
              </div>
              <div className="agileBoardPopupWrapper__agilePopup_content__block__content__infoRow__right">
                {moment(
                  new Date(previewData.item.created),
                  'DD/MM/YYYY'
                ).format('LL HH:mm')}
              </div>
            </div>
          ) : null}
          {isDeal ? (
            <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow">
              <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__left">
                {tc.updated}:
              </div>
              <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__right">
                {moment(
                  new Date(previewData.item.updated),
                  'DD/MM/YYYY'
                ).format('LL HH:mm')}
              </div>
            </div>
          ) : null}
        </div>
        {/* ) : null} */}
      </div>
    </FoldableSection>
  );
};

const mapStateToProps = (state, props) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(AgileBoardPopupDealInfo);
