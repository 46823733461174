import React, { useContext } from 'react';
import LanguageContext from 'language-context';
import history from 'router-history';
import Icon from 'components/icon';
import Loading from 'components/loading';
import Tooltip from 'components/tooltip';

const AgileBoardPopupVehicleInfo = ({ previewData }) => {
  const tc = useContext(LanguageContext);

  if (
    previewData?.item?.type === 'prospect' ||
    (previewData?.item?.type === 'deal' && !previewData?.item?.cars?.length)
  ) {
    return null;
  }

  return Array.isArray(previewData.vehicleInformation) ? (
    <div className="agileBoardPopupWrapper__agilePopup__content__block">
      <div className="agileBoardPopupWrapper__agilePopup__content__block__content">
        {previewData.vehicleInformation.map((vehicle) => {
          if (!vehicle || !vehicle?.reg_number) {
            return null;
          }

          return (
            <div
              className="agileBoardPopupWrapper__agilePopup__content__block__content__prospectOrCar"
              key={vehicle.reg_number}
            >
              <Tooltip tooltipContent={tc.navigateToCar}>
                <div
                  className="agileBoardPopupWrapper__agilePopup__content__block__content__prospectOrCar__header"
                  onClick={() => {
                    history.push('/fordon/' + vehicle.reg_number);
                  }}
                >
                  <div className="agileBoardPopupWrapper__agilePopup__content__block__content__prospectOrCar__header__left">
                    <Icon val="car" />
                    <h5 className="capitalize">{`${vehicle.brand} - ${vehicle.model} (${vehicle.reg_number})`}</h5>
                  </div>
                  <div className="agileBoardPopupWrapper__agilePopup__content__block__content__prospectOrCar__header__right">
                    <Icon val="navigateNext" />
                  </div>
                </div>
              </Tooltip>
              <div className="agileBoardPopupWrapper__agilePopup__content__block__content__prospectOrCar__content">
                <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow">
                  <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__left">
                    {tc.vehicleType}:
                  </div>
                  <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__right">
                    {tc[vehicle.type.toLowerCase()]}
                  </div>
                </div>
                <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow">
                  <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__left">
                    {tc.vehicleStatus}:
                  </div>
                  <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__right">
                    {vehicle.car_status}
                  </div>
                </div>
                <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow">
                  <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__left">
                    {tc.vehicleUsageType}:
                  </div>
                  <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__right">
                    {vehicle.usage}
                  </div>
                </div>
                <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow">
                  <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__left">
                    {tc.vehicleManufactured}:
                  </div>
                  <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__right">
                    {vehicle.date_car_year}
                  </div>
                </div>
                <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow">
                  <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__left">
                    {tc.vehicleRegistered}:
                  </div>
                  <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__right">
                    {vehicle.date_registered}
                  </div>
                </div>
                <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow">
                  <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__left">
                    {tc.fuel}:
                  </div>
                  <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__right">
                    {vehicle.fuel}
                  </div>
                </div>
                <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow">
                  <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__left">
                    {tc.climateClassification}:
                  </div>
                  <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__right">
                    {vehicle.climate_classification}
                  </div>
                </div>
                <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow">
                  <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__left">
                    {tc.climateClass}:
                  </div>
                  <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__right">
                    {vehicle.climate_class}
                  </div>
                </div>
                <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow">
                  <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__left">
                    {tc.engineStrengthHk}:
                  </div>
                  <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__right">
                    {vehicle.engine_strength}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  ) : (
    <div className="agileBoardPopupWrapper__agilePopup__content__block">
      <Loading />
    </div>
  );
};
export default AgileBoardPopupVehicleInfo;
