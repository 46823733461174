import React, { useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  addItemsToArray,
  addItemToArray,
  addOrRemoveItemFromArray,
  addObjectToObject,
  searchLists,
  searchStore,
  setArrayToEmpty,
  setProspectDataRegionFromPreset,
  updateData,
  updateSelectedUnit,
  searchColleagues,
  saveRegionPreset,
} from 'store/prospect/tasks';
import {
  setShowSettings,
  setSettingsActivePageOrSection,
} from 'store/user/tasks';
import LanguageContext from 'language-context';
import { idbHelper, miscHelper, request, textHelper } from 'helpers';
import { Dropdown, DropdownItem } from 'components/dropdown';
import Icon from 'components/icon';
import Info from 'components/info';
import Slider from '@mui/material/Slider';
import Loading from 'components/loading';
import ProspectSearchDateTooltip from './prospect_search_date_tooltip';
import ProspectSearchExcludeSwitch from './prospect_search_exclude_switch';
import ProspectSearchValueObj from './prospect_search_value_obj';
import SaveRegionPresetPopup from './prospect_search_save_region_preset';
import SearchRowSuggestion from './prospect_search_row_search_row_suggestion';
import { debouncedCompanyDealers } from 'store/company/tasks';
import { getLeasingCompaniesDebounced } from 'store/search/tasks';

/**
 * Render a row for range object (slider)
 * Or one/multiple rows for list of value objects (buttons).
 * Also render rows for some special cases.
 *
 * For value object arrays:
 * Since value object arrays can be nested in the data object, this component can include/exclude
 * children arrays. Children arrays gets inserted directly after its parent.
 *
 * @param state.props.data - array | obj - Array with value objects or a range object.
 * @param state.props.dataSource - array | obj - If we need to use data that isn't main data (I.E. data source that doesn't match keyPath).
 * @param state.props.includeChildren - bool (optional) - Only applies to value object arrays. If we also want to return rows for the children of each object.
 * @param state.props.keyPath - array - Key path to update data object.
 * @param state.props.loading - bool (optional) - If data for this row is loading.
 * @param state.props.step - number (optional) - If slider should increase by certain values.
 * @param state.props.title - string (optional) - Title for row.
 * @param state.props.type - string - Determines what we should render.
 */
const ProspectSearchRow = (state) => {
  const [freeTextBrandVal, setFreeTextBrandVal] = useState('');
  const [freeTextModelVal, setFreeTextModelVal] = useState('');
  const [freeTextKarossCodes, setFreeTextKarrossCode] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [
    selectedBrandForBrandPercentages,
    setSelectedBrandForBrandPercentages,
  ] = useState('');
  const [selectedBrandForModels, setSelectedBrandForModels] = useState('');
  const [selectedLänForRegions, setSelectedLänForRegions] = useState(null);
  const [selectedKommunForRegions, setSelectedKommunForRegions] =
    useState(null);
  const [showSaveRegionPresetPopup, setShowSaveRegionPresetPopup] =
    useState(false);

  const [showSearchDropDown, setShowSearchDropdown] = useState(false);
  const searchHolderRef = useRef(null);
  const {
    data,
    dataSource,
    includeChildren = false,
    keyPath,
    loading,
    step,
    title,
    type,
    unique,
    isForSniCodes,
    isForLists,
    isForColleagues,
    showExclude,
    excludeActive,
    noSort,
  } = state.props;
  const rows = [];
  const tc = useContext(LanguageContext);
  const [sniCodes, setSniCodes] = useState([]);

  useEffect(() => {
    if (!isForSniCodes) return;
    async function loadSniCodes() {
      const data = await request({ url: '/prospect/sniCodes', method: 'GET' });
      setSniCodes(data);
    }
    loadSniCodes();
  }, [isForSniCodes]);

  useEffect(() => {
    /**
     * When clicking outside search suggestions box, close it.
     */
    const _unmountSearchDropdown = (e) => {
      if (miscHelper.clickUnmount(e, searchHolderRef, false)) {
        setShowSearchDropdown(false);
      }
    };

    window.addEventListener('mousedown', _unmountSearchDropdown);
    return () =>
      window.removeEventListener('mousedown', _unmountSearchDropdown);
  }, []);

  useEffect(() => {
    const _setSelectedBrandForModels = () => {
      if (type === 'models' && dataSource && !selectedBrandForModels) {
        // If user hasn't selected any brand in models row...
        const obj = dataSource.find(
          (num) => !!(num.active && num.children.find((x) => x.active))
        );
        if (obj) {
          // ..find the first brand that has active models (when recreating search)...
          setSelectedBrandForModels(obj.val);
        } else {
          // ...or just find and set the first brand in data source.
          const obj = dataSource.find((num) => num);
          setSelectedBrandForModels(obj.val);
        }
      }
    };

    _setSelectedBrandForModels();
  }, [dataSource, selectedBrandForModels, type]);

  useEffect(() => {
    const _setSelectedBrandForBrandPercentages = () => {
      // If user hasn't selected any brand in brandPercentages row...
      if (
        type === 'brandPercentages' &&
        data &&
        !selectedBrandForBrandPercentages &&
        !state.prospect.recreateSearchLoading
      ) {
        const obj = Object.keys(data).find(
          (num) => data[num].from || data[num].to
        );

        if (obj) {
          // ...find the first one that has set values (when recreating a search)...
          setSelectedBrandForBrandPercentages(obj);
        } else {
          // ...or find and set the first brand in data source.
          const obj = dataSource.find((num) => num);
          setSelectedBrandForBrandPercentages(obj.val);
          addObjectToObject({
            keyPath: keyPath,
            key: obj.val,
            object: {
              from: null,
              max: 100,
              min: 0,
              text: obj.text,
              to: null,
              type: 'range',
              unit: '%',
            },
            skipUpdateCount: true,
          });
        }
      }
    };

    _setSelectedBrandForBrandPercentages();
  }, [
    data,
    dataSource,
    keyPath,
    selectedBrandForBrandPercentages,
    state.prospect.recreateSearchLoading,
    type,
  ]);

  const _checkHiddenFields = (key) => {
    let hit = false;
    key.forEach((val) => {
      for (const prop in state.prospect.hideFields) {
        if (
          state.prospect.hideFields[prop].active &&
          state.prospect.hideFields[prop].fields.includes(val)
        ) {
          hit = true;
        }
      }
    });

    return !hit;
  };

  const _handleSearchInput = async (val) => {
    let result;

    if (keyPath[2] === 'sniCodes') {
      function findNames(text) {
        return sniCodes.filter((code) => {
          return code.name.toLowerCase().includes(text.toLowerCase());
        });
      }
      function findCodes(text) {
        return sniCodes.filter((code) => {
          return code.code.toLowerCase().startsWith(text.toLowerCase());
        });
      }

      function createResultFromCode(code) {
        return {
          active: state.prospect.data.prospect.company.sniCodes?.some(
            (c) => code.code === c.code
          ),
          text: `${code.code} - ${code.name}`,
          type: 'value',
          val: code.code,
        };
      }

      if (val === '') {
        // when a user clicks the search box we want to show all sni codes by default
        result = sniCodes.map(createResultFromCode);
      } else {
        result = findCodes(val)
          .concat(findNames(val))
          .map(createResultFromCode);
      }
      setShowSearchDropdown(!!result.length);
      setSearchResult(result);
      return;
    }

    if (keyPath[1] === 'excludeSavedLists') {
      result = searchLists(val).map((l) => {
        return {
          ...l,
          active: state.prospect.data.combineWithData?.excludeSavedLists.some(
            (list) => l.val === list.val
          ),
          type: 'value',
        };
      });
    } else if (keyPath[1] === 'exclusiveMyCustomers') {
      result = searchColleagues(val).map((c) => {
        return {
          ...c,
          active:
            state.prospect.data.combineWithData?.exclusiveMyCustomers.some(
              (user) => c.val === user.val
            ),
          type: 'value',
        };
      });
    } else if (keyPath[1] === 'sellers') {
      if (val) {
        result = await debouncedCompanyDealers({ q: val });
      } else {
        result = [];
      }
    } else if (keyPath[1] === 'leasingOwners') {
      if (val) {
        result = await getLeasingCompaniesDebounced({ q: val });
      } else {
        result = [];
      }
    } else {
      result = await searchStore({
        query: val,
        store: keyPath[keyPath.length - 1],
      });
    }

    setShowSearchDropdown(!!result.length);
    setSearchResult(result);
  };

  /**
   * This is specific for data.car.brandPercentages.
   * A row with radio buttons and a slider.
   */
  const _renderBrandPercentagesRow = (data, brands, key, rowName, step) => {
    // Check fields to hide before render row.
    if (!_checkHiddenFields(key)) {
      return;
    }

    // Find selected brand in brandPercentages object.
    let brandPercentageObjectKey;
    let brandPercentageObject;
    if (data) {
      Object.keys(data).forEach((num) => {
        if (num === selectedBrandForBrandPercentages) {
          brandPercentageObject = data[num];
          brandPercentageObjectKey = key.concat([num]);
        }
      });
    }

    rows.push(
      <div className="prospectSearchRowWrapper" key={key.join()}>
        <div className="prospectSearchRowWrapper__prospectSearchRow">
          <div className="prospectSearchRowWrapper__prospectSearchRow__content">
            <div
              className={`prospectSearchRowWrapper__prospectSearchRow__content__left ${
                !rowName ? 'empty' : ''
              }`}
            >
              {rowName}
            </div>
            <div className="prospectSearchRowWrapper__prospectSearchRow__content__right">
              {brands.map((num) => {
                const keyPathItem = key.concat([num.val]);
                return (
                  <div
                    className={`prospectSearchRowWrapper__prospectSearchRow__content__right__radioHolder ${
                      selectedBrandForBrandPercentages === num.val
                        ? 'active'
                        : ''
                    }`}
                    key={keyPathItem}
                    onClick={() => {
                      setSelectedBrandForBrandPercentages(num.val);
                      if (!data[num.val]) {
                        addObjectToObject({
                          keyPath: key,
                          key: num.val,
                          object: {
                            from: null,
                            max: 100,
                            min: 0,
                            text: num.text,
                            to: null,
                            type: 'range',
                            unit: '%',
                          },
                          skipUpdateCount: true,
                        });
                      }
                    }}
                  >
                    {brands.length > 1 ? (
                      <Icon
                        val={
                          num.val === selectedBrandForBrandPercentages
                            ? 'radioChecked'
                            : 'radio'
                        }
                      />
                    ) : null}
                    {textHelper.formatText(num.text, true)}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );

    // When a brand is selected, render slider for that brand.
    if (brandPercentageObject) {
      _renderRangeRow(
        brandPercentageObject,
        brandPercentageObjectKey,
        '',
        step
      );
    }
  };

  const _renderBrandSearchRow = (term, key, rowName) => {
    const _handleSubmit = () => {
      let sanitizedText = textHelper.sanitizeUserInput(freeTextBrandVal);
      let payload = {
        new: true,
        text: sanitizedText,
        val: sanitizedText,
      };

      const item = {
        text: payload.val,
        val: payload.val,
        active: true,
        type: 'value',
      };

      const keyPath = ['car', 'freeTextBrands'];

      addOrRemoveItemFromArray({ item, keyPath });
      setFreeTextBrandVal('');
    };
    rows.push(
      <React.Fragment key={key.join()}>
        <div className="prospectSearchRowWrapper">
          <div className="prospectSearchRowWrapper__prospectSearchRow">
            <div className="prospectSearchRowWrapper__prospectSearchRow__content">
              <div
                className={`prospectSearchRowWrapper__prospectSearchRow__content__left ${
                  !rowName ? 'empty' : ''
                }`}
              >
                {rowName}
              </div>
              <div className="prospectSearchRowWrapper__prospectSearchRow__content__right">
                <div className="prospectSearchRowWrapper__prospectSearchRow__content__right__searchHolder">
                  <div className="prospectSearchRowWrapper__prospectSearchRow__content__right__searchHolder__left">
                    <div className="prospectSearchRowWrapper__prospectSearchRow__content__right__searchHolder__left__input__smaller">
                      <input
                        type="search"
                        placeholder="enter..."
                        onChange={(e) => {
                          setFreeTextBrandVal(e.target.value);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            if (freeTextBrandVal.length === 0) return;
                            _handleSubmit();
                          }
                        }}
                        value={freeTextBrandVal}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const _renderModelSearchRow = (term, key, rowName) => {
    const _handleSubmit = () => {
      let sanitizedText = textHelper.sanitizeUserInput(freeTextModelVal);
      let payload = {
        new: true,
        text: sanitizedText,
        val: sanitizedText,
      };

      const item = {
        text: payload.val,
        val: payload.val,
        active: true,
        type: 'value',
      };

      const keyPath = ['car', 'freeTextModels'];

      addOrRemoveItemFromArray({ item, keyPath });
      setFreeTextModelVal('');
    };
    rows.push(
      <React.Fragment key={key.join()}>
        <div className="prospectSearchRowWrapper">
          <div className="prospectSearchRowWrapper__prospectSearchRow">
            <div className="prospectSearchRowWrapper__prospectSearchRow__content">
              <div
                className={`prospectSearchRowWrapper__prospectSearchRow__content__left ${
                  !rowName ? 'empty' : ''
                }`}
              >
                {rowName}
              </div>
              <div className="prospectSearchRowWrapper__prospectSearchRow__content__right">
                <div className="prospectSearchRowWrapper__prospectSearchRow__content__right__searchHolder">
                  <div className="prospectSearchRowWrapper__prospectSearchRow__content__right__searchHolder__left">
                    <div className="prospectSearchRowWrapper__prospectSearchRow__content__right__searchHolder__left__input__smaller">
                      <input
                        type="search"
                        placeholder="enter..."
                        onChange={(e) => {
                          setFreeTextModelVal(e.target.value);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            if (freeTextModelVal.length === 0) return;
                            _handleSubmit();
                          }
                        }}
                        value={freeTextModelVal}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const _renderFreeTextKarossCode = (term, key, rowName) => {
    const _handleKarossSubmit = () => {
      let sanitizedText = textHelper.sanitizeUserInput(freeTextKarossCodes);

      addItemToArray({
        item: {
          active: true,
          type: 'value',
          text: sanitizedText,
          val: sanitizedText,
        },
        keyPath: keyPath,
      });
      setFreeTextKarrossCode('');
    };
    rows.push(
      <React.Fragment key={key.join()}>
        <div className="prospectSearchRowWrapper">
          <div className="prospectSearchRowWrapper__prospectSearchRow">
            <div className="prospectSearchRowWrapper__prospectSearchRow__content">
              <div
                className={`prospectSearchRowWrapper__prospectSearchRow__content__left ${
                  !rowName ? 'empty' : ''
                }`}
              >
                {rowName}
              </div>
              <div className="prospectSearchRowWrapper__prospectSearchRow__content__right">
                <div className="prospectSearchRowWrapper__prospectSearchRow__content__right__searchHolder">
                  <div className="prospectSearchRowWrapper__prospectSearchRow__content__right__searchHolder__left">
                    <div className="prospectSearchRowWrapper__prospectSearchRow__content__right__searchHolder__left__input__smaller">
                      <input
                        type="search"
                        placeholder="enter..."
                        onChange={(e) => {
                          setFreeTextKarrossCode(e.target.value);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            if (freeTextKarossCodes.length === 0) return;
                            _handleKarossSubmit();
                          }
                        }}
                        value={freeTextKarossCodes}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const _renderContent = () => {
    if (type === 'brandPercentages') {
      _renderBrandPercentagesRow(data, dataSource, keyPath, title, step);
      return rows;
    } else if (type === 'modelSearch') {
      _renderModelSearchRow(data, keyPath, title);
      return rows;
    } else if (type === 'brandSearch') {
      _renderBrandSearchRow(data, keyPath, title);
      return rows;
    } else if (type === 'karossCodeSearch') {
      _renderFreeTextKarossCode(data, keyPath, title);
      return rows;
    } else if (type === 'län') {
      _renderLänRow(data, keyPath, title);
      return rows;
    } else if (type === 'models') {
      _renderModelsRow(dataSource, keyPath, title);
      return rows;
    } else if (type === 'operation') {
      _renderOperationRow(data, keyPath, title);
      return rows;
    } else if (type === 'range') {
      _renderRangeRow(data, keyPath, title, step);
      return rows;
    } else if (type === 'rangeUnbounded') {
      _renderRangeUnboundedRow(data, keyPath, title, step);
      return rows;
    } else if (type === 'regionPreset') {
      _renderRegionPresetRow(data, title);
      return rows;
    } else if (type === 'search') {
      _renderSearchRow(data, keyPath, title);
      return rows;
    } else if (type === 'valueArray') {
      _renderValueArrayRows(data, includeChildren, keyPath, title, unique);
      return rows;
    }
  };

  /**
   * This is specific for kommuner, which are found in each region objects children array.
   * A row with radio buttons and regular buttons. It also renders postorter.
   */
  const _renderKommunerRow = (kommuner, keyPath, rowName) => {
    // Check fields to hide before render row.

    if (!kommuner || (kommuner && !kommuner.length)) {
      return;
    }

    // Find selected brand.
    // let kommunerData = state.prospect.data.regions.regions.filter(region=>region.active === true);
    // if (kommunerData.length > 0) {
    //   _renderKommunerRow(kommunerData, keyPath, tc.townships);
    // }
    // activeLän is the selected
    // selectedLänForRegions is a state hook value for the val of which
    // of the lan name should be the active rad
    // if (kommuner.length===1 ) setSelectedLänForRegions(kommuner[0].val)
    const activeLän =
      kommuner.find((item) => item.val === selectedLänForRegions) ||
      kommuner[0];
    const activeLänKey = activeLän ? keyPath.concat([activeLän.val]) : null;
    let whichLän = selectedLänForRegions || activeLän.val;
    rows.push(
      <div className="prospectSearchRowWrapper" key={keyPath.join()}>
        <div className="prospectSearchRowWrapper__prospectSearchRow">
          <div className="prospectSearchRowWrapper__prospectSearchRow__content">
            <div
              className={`prospectSearchRowWrapper__prospectSearchRow__content__left ${
                !rowName ? 'empty' : ''
              }`}
            >
              {rowName}
            </div>
            <div className="prospectSearchRowWrapper__prospectSearchRow__content__right">
              {kommuner.map((item) => {
                const keyPathItem = keyPath.concat(['kommuner', item.val]);
                return (
                  <div
                    className={
                      item.val === whichLän
                        ? 'prospectSearchRowWrapper__prospectSearchRow__content__right__radioHolder active'
                        : 'prospectSearchRowWrapper__prospectSearchRow__content__right__radioHolder'
                    }
                    key={keyPathItem}
                    onClick={() => {
                      setSelectedLänForRegions(item.val);
                    }}
                  >
                    {kommuner.length > 1 ? (
                      <Icon
                        val={item.val === whichLän ? 'radioChecked' : 'radio'}
                      />
                    ) : null}
                    {textHelper.formatText(item.text, true)}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );

    if (activeLän) {
      _renderValueArrayRow(activeLän.children, activeLänKey, '');

      // const activeLän = kommuner.find(
      //   (item) => item.val === selectedLänForRegions
      // ) || kommuner[0];
      // const activeLänKey = activeLän ? keyPath.concat([activeLän.val]) : null;
      // let whichLän = selectedLänForRegions || activeLän.val;

      let activeKommuner = activeLän.children.filter(
        (kommun) => kommun.active === true
      );
      // console.log(`Postorter radio labels (kommuner selected) are ${JSON.stringify(radioLabels)}`)
      // console.log(`there are ${activeKommuner.length} active kommuner in this län`);
      let activeKommun =
        activeKommuner.find((item) => item.val === selectedKommunForRegions) ||
        activeKommuner[0];
      let whichKommun = activeKommun?.val || selectedKommunForRegions;
      if (activeKommuner.length > 0) {
        rows.push(
          <div
            className="prospectSearchRowWrapper"
            key={'localities' + keyPath.join()}
          >
            <div className="prospectSearchRowWrapper__prospectSearchRow">
              <div className="prospectSearchRowWrapper__prospectSearchRow__content">
                <div className="prospectSearchRowWrapper__prospectSearchRow__content__left">
                  {tc.localities}
                </div>
                <div className="prospectSearchRowWrapper__prospectSearchRow__content__right">
                  {activeKommuner.map((item) => {
                    const itemKey = keyPath.concat(['kommuner', item.val]);
                    return (
                      <div
                        className={
                          item.val === whichKommun
                            ? 'prospectSearchRowWrapper__prospectSearchRow__content__right__radioHolder active'
                            : 'prospectSearchRowWrapper__prospectSearchRow__content__right__radioHolder'
                        }
                        key={'l_' + itemKey}
                        onClick={() => {
                          setSelectedKommunForRegions(item.val);
                        }}
                      >
                        {activeKommuner.length > 1 ? (
                          <Icon
                            val={
                              item.val === whichKommun
                                ? 'radioChecked'
                                : 'radio'
                            }
                          />
                        ) : null}
                        {textHelper.formatText(item.text, true)}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        );
        // here we should render the row group for the selectedKommunForRegions
        //let activeKommun = activeKommuner.find( kommun => kommun.val === selectedKommunForRegions);
        if (activeKommun && activeKommun?.children?.length > 0) {
          let activeKommunKey = activeLänKey.concat([activeKommun.val]);
          _renderValueArrayRow(activeKommun.children, activeKommunKey, ' ');
        }
      }
    }
  };

  const _renderLänRow = (arr, keyPath, rowName) => {
    // Check fields to hide before render row.
    if (!_checkHiddenFields(keyPath)) {
      return;
    }

    let values;
    if (!loading && arr?.length) {
      arr.sort((a, b) => {
        return a.text < b.text ? -1 : 1;
      });
      values = arr.map((num) => {
        const keyPathItem = keyPath.concat([num.val]);
        return (
          <ProspectSearchValueObj
            key={keyPathItem.join()}
            obj={num}
            keyPath={keyPathItem}
          />
        );
      });
    }

    const showSavePresetButton =
      (state?.prospect?.data?.regions?.regions || []).filter(
        (reg) => reg.active === true
      ).length > 0 && !state?.prospect?.regionStatus?.activePreset;

    rows.push(
      <div className="prospectSearchRowWrapper" key={'län' + keyPath.join()}>
        <div className="prospectSearchRowWrapper__prospectSearchRow">
          <div className="prospectSearchRowWrapper__prospectSearchRow__content">
            <div
              className={`prospectSearchRowWrapper__prospectSearchRow__content__left ${
                !rowName ? 'empty' : ''
              }`}
            >
              {textHelper.formatText(rowName)}
            </div>
            {!loading ? (
              <>
                <div className="prospectSearchRowWrapper__prospectSearchRow__content__right">
                  {values}
                </div>
                {showSavePresetButton && (
                  <div className="prospectSearchRowWrapper__prospectSearchRow">
                    <div className="prospectSearchRowWrapper__prospectSearchRow__content"></div>
                    <div
                      className="prospectSearchHeaderWrapper__prospectSearchHeader__content__buttons__search"
                      onClick={() => setShowSaveRegionPresetPopup(true)}
                    >
                      <Icon val="save" />
                      <span>{tc.groupsRegionSave}</span>
                    </div>
                  </div>
                )}
                {showSaveRegionPresetPopup && (
                  <SaveRegionPresetPopup
                    close={() => setShowSaveRegionPresetPopup(false)}
                    save={saveRegionPreset}
                  />
                )}
              </>
            ) : (
              <div className="prospectSearchRowWrapper__prospectSearchRow__content__right">
                <Loading small={true} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
    // now let's check for active län. if so, add the kommuner row
    let kommunerData = state.prospect.data?.regions?.regions
      ? state.prospect.data.regions.regions.filter(
          (region) => region.active === true
        )
      : [];
    if (kommunerData.length > 0) {
      _renderKommunerRow(kommunerData, keyPath, tc.townships);
    }
  };

  /**
   * This is specific for models, which are found in each brand objects children array.
   * A row with radio buttons and regular buttons.
   */
  const _renderModelsRow = (brands, key, rowName) => {
    // Check fields to hide before render row.
    if (!_checkHiddenFields(key)) {
      return;
    }

    if (!brands || (brands && !brands.length)) {
      return;
    }

    // Find selected brand.
    const activeBrand = brands.find(
      (num) => num.val === selectedBrandForModels
    );
    const activeBrandKey = activeBrand ? key.concat([activeBrand.val]) : null;

    rows.push(
      <div className="prospectSearchRowWrapper" key={key.join()}>
        <div className="prospectSearchRowWrapper__prospectSearchRow">
          <div className="prospectSearchRowWrapper__prospectSearchRow__content">
            <div
              className={`prospectSearchRowWrapper__prospectSearchRow__content__left ${
                !rowName ? 'empty' : ''
              }`}
            >
              {rowName}
            </div>
            <div className="prospectSearchRowWrapper__prospectSearchRow__content__right">
              {brands.map((num) => {
                const keyPathItem = key.concat(['models', num.val]);
                return (
                  <div
                    className={
                      selectedBrandForModels === num.val
                        ? 'prospectSearchRowWrapper__prospectSearchRow__content__right__radioHolder active'
                        : 'prospectSearchRowWrapper__prospectSearchRow__content__right__radioHolder'
                    }
                    key={keyPathItem}
                    onClick={() => {
                      setSelectedBrandForModels(num.val);
                    }}
                  >
                    {brands.length > 1 ? (
                      <Icon
                        val={
                          num.val === selectedBrandForModels
                            ? 'radioChecked'
                            : 'radio'
                        }
                      />
                    ) : null}
                    {textHelper.formatText(num.text, true)}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );

    // When a brand is selected, render models for that brand.
    if (activeBrand) {
      _renderValueArrayRow(activeBrand.children, activeBrandKey, '');
    }
  };

  /**
   * This is specific for prospect.operation.
   */
  const _renderOperationRow = (obj, key, rowName) => {
    // Check fields to hide before render row.
    if (!_checkHiddenFields(key)) {
      return;
    }

    let values = [];

    for (const prop in obj) {
      values.push(
        <div
          className={
            obj[prop][1].active ? 'prospectValue active' : 'prospectValue'
          }
          key={prop}
          onClick={async () => {
            // Since these have both 'include' and 'exclude' values, we need to toggle both.
            await updateData({
              keyPath: key.concat([prop, 0]),
              type: 'value',
              value: !obj[prop][0].active,
            });

            await updateData({
              keyPath: key.concat([prop, 1]),
              type: 'value',
              value: !obj[prop][1].active,
            });
          }}
        >
          {tc[prop]}
        </div>
      );
    }

    values = values.sort((a, b) => {
      if (
        !a ||
        !b ||
        !a.props ||
        !b.props ||
        !a.props.children ||
        !b.props.children
      ) {
        return 0;
      }

      return a.props.children.localeCompare(b.props.children, 'sv'); // Sort alphabetically.
    });

    rows.push(
      <div className="prospectSearchRowWrapper" key={key.join()}>
        <div className="prospectSearchRowWrapper__prospectSearchRow">
          <div className="prospectSearchRowWrapper__prospectSearchRow__content">
            <div
              className={`prospectSearchRowWrapper__prospectSearchRow__content__left ${
                !rowName ? 'empty' : ''
              }`}
            >
              {rowName}
            </div>
            <div className="prospectSearchRowWrapper__prospectSearchRow__content__right">
              {values}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const _renderRangeObj = (obj, key, step) => {
    return (
      <div className="prospectRangeWrapper">
        <div
          className={
            (obj.from && obj.from !== obj.min) || (obj.to && obj.to !== obj.max)
              ? 'prospectRangeWrapper__prospectRange active'
              : 'prospectRangeWrapper__prospectRange'
          }
        >
          <div className="prospectRangeWrapper__prospectRange__content">
            <div className="prospectRangeWrapper__prospectRange__content__left">
              <Slider
                max={obj.max}
                min={obj.min}
                onChange={(e, val) => {
                  updateData({
                    keyPath: key,
                    type: obj.type,
                    value: {
                      from: val[0],
                      to: val[1],
                    },
                  });
                }}
                size="small"
                step={step}
                value={[
                  +obj.from ? +obj.from : obj.min,
                  +obj.to ? +obj.to : obj.max,
                ]}
              />
            </div>
            <div
              className={
                obj.from || obj.to
                  ? 'prospectRangeWrapper__prospectRange__content__center active'
                  : 'prospectRangeWrapper__prospectRange__content__center'
              }
            >
              <div className="prospectRangeWrapper__prospectRange__content__center__left">
                <input
                  type="text"
                  value={obj.from !== null ? obj.from : obj.min}
                  onChange={(e, val) => {
                    updateData({
                      keyPath: key,
                      type: obj.type,
                      value: {
                        from: +e.currentTarget.value,
                        to: obj.to ? obj.to : obj.max,
                      },
                      skipMinMaxCheck: true, // Let users finish typing input before adjusting values.
                      skipUpdateCount: true, // Let users finish typing input before adjusting values.
                    });
                  }}
                  onBlur={(e) => {
                    updateData({
                      keyPath: key,
                      type: obj.type,
                      value: {
                        from: +e.currentTarget.value,
                        to: obj.to ? obj.to : obj.max,
                      },
                    });
                  }}
                />
              </div>
              <div className="prospectRangeWrapper__prospectRange__content__center__center">
                {tc.to.toLowerCase()}
              </div>
              <div className="prospectRangeWrapper__prospectRange__content__center__right">
                <input
                  type="text"
                  value={obj.to !== null ? obj.to : obj.max}
                  onChange={(e, val) => {
                    updateData({
                      keyPath: key,
                      type: obj.type,
                      value: {
                        from: obj.from ? obj.from : obj.min,
                        to: +e.currentTarget.value,
                      },
                      skipMinMaxCheck: true, // Let users finish typing input before adjusting values.
                      skipUpdateCount: true, // Let users finish typing input before adjusting values.
                    });
                  }}
                  onBlur={(e) => {
                    updateData({
                      keyPath: key,
                      type: obj.type,
                      value: {
                        from: obj.from ? obj.from : obj.min,
                        to: +e.currentTarget.value,
                      },
                    });
                  }}
                />
              </div>
            </div>
            <div className="prospectRangeWrapper__prospectRange__content__right">
              {obj.options ? (
                <>
                  <Dropdown
                    closeOnItemClick={true}
                    displayValue={tc[obj.selected] ?? obj.selected}
                  >
                    {obj.options.map((option) => {
                      return (
                        <DropdownItem
                          key={option}
                          active={obj.selected === option}
                          label={tc[option] ?? option}
                          onClick={() => {
                            updateSelectedUnit({
                              keyPath: key,
                              selected: option,
                            });
                          }}
                        >
                          {option}
                        </DropdownItem>
                      );
                    })}
                  </Dropdown>
                  <ProspectSearchDateTooltip
                    options={obj.options}
                    object={obj}
                  />
                </>
              ) : (
                obj.unit?.toLowerCase()
              )}
            </div>
            <div
              className="prospectRangeWrapper__prospectRange__content__reset"
              onClick={() => {
                if (obj.from || obj.to) {
                  updateData({
                    keyPath: key,
                    type: obj.type,
                    value: {
                      from: null,
                      to: null,
                    },
                  });
                }
              }}
            >
              {obj.from || obj.to ? <Icon val="clear" /> : null}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const _renderRangeRow = (obj, key, rowName, step) => {
    // Check fields to hide before render row.
    if (!_checkHiddenFields(key)) {
      return;
    }

    rows.push(
      <div className="prospectSearchRowWrapper" key={key.join()}>
        <div className="prospectSearchRowWrapper__prospectSearchRow">
          <div className="prospectSearchRowWrapper__prospectSearchRow__content">
            <div
              className={`prospectSearchRowWrapper__prospectSearchRow__content__left ${
                !rowName ? 'empty' : ''
              }`}
            >
              {rowName}
            </div>
            <div className="prospectSearchRowWrapper__prospectSearchRow__content__right">
              {_renderRangeObj(obj, key, step)}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const _renderRangeUnboundedObj = (obj, key, step) => {
    return (
      <div className="prospectRangeWrapper">
        <div
          className={
            (obj.from && obj.from !== obj.min) || (obj.to && obj.to !== obj.max)
              ? 'prospectRangeWrapper__prospectRange active'
              : 'prospectRangeWrapper__prospectRange'
          }
        >
          <div className="prospectRangeWrapper__prospectRange__content">
            <div className="prospectRangeWrapper__prospectRange__content__left"></div>
            <div
              className={
                obj.from || obj.to
                  ? 'prospectRangeWrapper__prospectRange__content__center active'
                  : 'prospectRangeWrapper__prospectRange__content__center'
              }
            >
              <div className="prospectRangeWrapper__prospectRange__content__center__left">
                <input
                  type="number"
                  value={obj.from !== null ? obj.from : obj.min}
                  style={{ minWidth: '50px' }}
                  onChange={(e, val) => {
                    const { to } = obj;
                    const from = parseInt(e.currentTarget.value);
                    if (from > 100000) return;
                    updateData({
                      keyPath: key,
                      type: obj.type,
                      value: { from, to: to ? (from > to ? from : to) : null },
                      skipMinMaxCheck: true, // Let users finish typing input before adjusting values.
                      skipUpdateCount: true, // Let users finish typing input before adjusting values.
                    });
                  }}
                  onBlur={(e) => {
                    const { to } = obj;
                    const from = parseInt(e.currentTarget.value);
                    if (from > 100000) return;
                    updateData({
                      keyPath: key,
                      type: obj.type,
                      value: {
                        from,
                        to: to ? (from > to ? from : to) : null,
                      },
                    });
                  }}
                />
                <span>min</span>
              </div>
              <div className="prospectRangeWrapper__prospectRange__content__center__center">
                {tc.to.toLowerCase()}
              </div>
              <div className="prospectRangeWrapper__prospectRange__content__center__right">
                <input
                  type="number"
                  value={obj.to !== null ? obj.to : ''}
                  onChange={(e, val) => {
                    const newValue = parseInt(e.currentTarget.value);
                    const from = obj.from ? obj.from : obj.min;
                    if (newValue > 100000) return;
                    updateData({
                      keyPath: key,
                      type: obj.type,
                      value: {
                        from: from,
                        to: newValue,
                      },
                      skipMinMaxCheck: true, // Let users finish typing input before adjusting values.
                      skipUpdateCount: true, // Let users finish typing input before adjusting values.
                    });
                  }}
                  onBlur={(e) => {
                    const newValue = parseInt(e.currentTarget.value);
                    const from = obj.from ? obj.from : obj.min;
                    if (newValue > 100000) return;
                    updateData({
                      keyPath: key,
                      type: obj.type,
                      value: {
                        from,
                        to: newValue,
                      },
                    });
                  }}
                />
                <span>max</span>
              </div>
            </div>
            <div className="prospectRangeWrapper__prospectRange__content__right">
              {obj.options ? (
                <>
                  <Dropdown
                    closeOnItemClick={true}
                    displayValue={tc[obj.selected] ?? obj.selected}
                  >
                    {obj.options.map((option) => {
                      return (
                        <DropdownItem
                          key={option}
                          active={obj.selected === option}
                          label={tc[option] ?? option}
                          onClick={() => {
                            updateSelectedUnit({
                              keyPath: key,
                              selected: option,
                            });
                          }}
                        >
                          {option}
                        </DropdownItem>
                      );
                    })}
                  </Dropdown>
                  <ProspectSearchDateTooltip
                    options={obj.options}
                    object={obj}
                  />
                </>
              ) : (
                obj.unit?.toLowerCase()
              )}
            </div>
            <div
              className="prospectRangeWrapper__prospectRange__content__reset"
              onClick={() => {
                if (obj.from || obj.to) {
                  updateData({
                    keyPath: key,
                    type: obj.type,
                    value: {
                      from: null,
                      to: null,
                    },
                  });
                }
              }}
            >
              {obj.from || obj.to ? <Icon val="clear" /> : null}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const _renderRangeUnboundedRow = (obj, key, rowName, step) => {
    // Check fields to hide before render row.
    if (!_checkHiddenFields(key)) {
      return;
    }

    rows.push(
      <div className="prospectSearchRowWrapper" key={key.join()}>
        <div className="prospectSearchRowWrapper__prospectSearchRow">
          <div className="prospectSearchRowWrapper__prospectSearchRow__content">
            <div
              className={`prospectSearchRowWrapper__prospectSearchRow__content__left ${
                !rowName ? 'empty' : ''
              }`}
            >
              {rowName}
            </div>
            <div className="prospectSearchRowWrapper__prospectSearchRow__content__right">
              {_renderRangeUnboundedObj(obj, key, step)}
            </div>
          </div>
        </div>
      </div>
    );
  };

  /**
   *
   * @param {*} data
   */
  const _renderRegionPresetRow = ({ regionData, postortPresets }, rowName) => {
    let values = postortPresets.reduce((acc, item) => {
      let itemActive = item._id === state.prospect.regionStatus.activePreset;
      let newItem = (
        <div
          className={itemActive ? 'prospectValue active' : 'prospectValue'}
          key={item._id}
          onClick={() => {
            setProspectDataRegionFromPreset(
              item.pmap,
              item._id,
              itemActive,
              item.data
            );
          }}
        >
          {item.name}
        </div>
      );
      return [...acc, newItem];
    }, []);

    rows.push(
      <div className="prospectSearchRowWrapper" key="regionPresets">
        <div className="prospectSearchRowWrapper__prospectSearchRow">
          <div className="prospectSearchRowWrapper__prospectSearchRow__content">
            <div
              className={`prospectSearchRowWrapper__prospectSearchRow__content__left ${
                !rowName ? 'empty' : ''
              }`}
            >
              {rowName}
            </div>
            <div className="prospectSearchRowWrapper__prospectSearchRow__content__right">
              {values.length > 0 ? (
                values
              ) : (
                <Info inline={true}>
                  <h4>{tc.groupsMissing}</h4>
                  <p>{tc.groupsMissingInfo}</p>
                  <div
                    className="buttonLinkStyledWithIcon"
                    onClick={() => {
                      setShowSettings(true);
                      setSettingsActivePageOrSection({
                        page: 'prospectSearch',
                        section: 'groupsPostcodes',
                      });
                    }}
                  >
                    <Icon val="add" />
                    <span>{tc.addGroup}</span>
                  </div>
                </Info>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const _renderSearchRow = (arr, key, rowName) => {
    rows.push(
      <React.Fragment key={key.join()}>
        <div className="prospectSearchRowWrapper">
          <div className="prospectSearchRowWrapper__prospectSearchRow">
            <div className="prospectSearchRowWrapper__prospectSearchRow__content">
              <div
                className={`prospectSearchRowWrapper__prospectSearchRow__content__left ${
                  !rowName ? 'empty' : ''
                }`}
              >
                {rowName}
              </div>
              <div className="prospectSearchRowWrapper__prospectSearchRow__content__right">
                {!loading ? (
                  <>
                    <div className="prospectSearchRowWrapper__prospectSearchRow__content__right__searchHolder">
                      <div className="prospectSearchRowWrapper__prospectSearchRow__content__right__searchHolder__left">
                        {showSearchDropDown ? (
                          <div
                            className="prospectSearchRowWrapper__prospectSearchRow__content__right__searchHolder__left__inputActive"
                            ref={searchHolderRef}
                          >
                            <input
                              type="search"
                              placeholder={tc.placeholderSearch}
                              onChange={(e) => {
                                _handleSearchInput(e.target.value);
                              }}
                            />
                            <>
                              {Array.isArray(searchResult) ? (
                                <div className="prospectSearchRowWrapper__prospectSearchRow__content__right__searchHolder__left__inputActive__selectRow">
                                  <div
                                    className="prospectSearchRowWrapper__prospectSearchRow__content__right__searchHolder__left__inputActive__selectRow__item"
                                    onClick={() => {
                                      addItemsToArray({
                                        keyPath: keyPath,
                                        items: searchResult,
                                      });
                                    }}
                                  >
                                    <strong>{tc.selectAllSearchRows}</strong>
                                  </div>
                                </div>
                              ) : null}
                              <div className="prospectSearchRowWrapper__prospectSearchRow__content__right__searchHolder__left__inputActive__suggestionsHolder">
                                {[...searchResult]
                                  .sort((a, b) => {
                                    return a.text.localeCompare(b.text); // Sort alphabetically based on the 'text' property
                                  })
                                  .map((result) => {
                                    return (
                                      <SearchRowSuggestion
                                        item={result}
                                        data={data}
                                        keyPath={keyPath}
                                        key={result.val}
                                      />
                                    );
                                  })}
                              </div>
                            </>
                          </div>
                        ) : (
                          <div
                            className="prospectSearchRowWrapper__prospectSearchRow__content__right__searchHolder__left__input"
                            ref={searchHolderRef}
                          >
                            <input
                              type="search"
                              placeholder={tc.placeholderSearch}
                              onChange={(e) => {
                                _handleSearchInput(e.target.value);
                              }}
                              onFocus={(e) => {
                                _handleSearchInput(e.target.value);
                              }}
                              onBlur={() => {
                                setSearchResult([]);
                                setShowSearchDropdown(false);
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <div
                        className={
                          showSearchDropDown
                            ? 'prospectSearchRowWrapper__prospectSearchRow__content__right__searchHolder__right marginLeft'
                            : 'prospectSearchRowWrapper__prospectSearchRow__content__right__searchHolder__right'
                        }
                      >
                        {keyPath[1] === 'excludeSavedLists' ||
                        keyPath[1] === 'exclusiveMyCustomers' ||
                        keyPath[1] === 'dealerSalesmen' ? (
                          <div
                            className="prospectSearchRowWrapper__prospectSearchRow__content__right__searchHolder__right__button"
                            onClick={async () => {
                              if (keyPath[1] === 'excludeSavedLists') {
                                let lists = state.lists.lists.map((list) => {
                                  return {
                                    active: false,
                                    text: list.name,
                                    type: 'value',
                                    val: list.id,
                                  };
                                });

                                addItemsToArray({
                                  keyPath: keyPath,
                                  items: lists,
                                });
                              } else if (keyPath[1] === 'dealerSalesmen') {
                                let salesmen = await idbHelper.getStoreData({
                                  store: 'dealerSalesmen',
                                });
                                salesmen = salesmen.map((num) => {
                                  num.active = true;
                                  return num;
                                });

                                addItemsToArray({
                                  keyPath: keyPath,
                                  items: salesmen,
                                });
                              } else if (
                                keyPath[1] === 'exclusiveMyCustomers'
                              ) {
                                const colleagues = state.user.colleagues.map(
                                  (userObj) => ({
                                    val: userObj.id,
                                    active: false,
                                    text: userObj.name,
                                    type: 'value',
                                  })
                                );

                                addItemsToArray({
                                  keyPath: keyPath,
                                  items: colleagues,
                                });
                              }
                            }}
                          >
                            <strong>{tc.selectAll}</strong>
                          </div>
                        ) : null}
                        {Array.isArray(data) &&
                        data.filter((num) => num.active).length &&
                        showExclude ? (
                          <>
                            <div
                              className="prospectSearchRowWrapper__prospectSearchRow__content__right__searchHolder__right__button"
                              onClick={() => {
                                setArrayToEmpty({
                                  keyPath: keyPath,
                                });
                              }}
                            >
                              <strong>{tc.unselectAll}</strong>
                            </div>
                            <div className="prospectSearchRowWrapper__prospectSearchRow__content__right__searchHolder__right__button">
                              <ProspectSearchExcludeSwitch
                                active={
                                  isForLists ? !excludeActive : excludeActive
                                }
                                onClick={() => {
                                  const fixedPath = isForSniCodes
                                    ? keyPath
                                        .slice(0, 2)
                                        .concat('excludeSniCodes')
                                    : isForLists
                                    ? keyPath
                                        .slice(0, 1)
                                        .concat('excludeListsInverted')
                                    : isForColleagues
                                    ? keyPath
                                        .slice(0, 1)
                                        .concat('excludeMyCustomers')
                                    : [];

                                  updateData({
                                    keyPath: fixedPath,
                                    type: 'value',
                                    value: !excludeActive,
                                  });
                                }}
                                disabled={false}
                                label={tc.exclude}
                              />
                            </div>
                          </>
                        ) : null}
                        {Array.isArray(data) && !showExclude ? (
                          <div
                            className="prospectSearchRowWrapper__prospectSearchRow__content__right__searchHolder__right__button"
                            onClick={() => {
                              setArrayToEmpty({
                                keyPath: keyPath,
                              });
                            }}
                          >
                            <strong>{tc.unselectAll}</strong>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </>
                ) : (
                  <Loading small={true} />
                )}
              </div>
            </div>
          </div>
        </div>
        {arr.length ? (
          <div className="prospectSearchRowWrapper">
            <div className="prospectSearchRowWrapper__prospectSearchRow">
              <div className="prospectSearchRowWrapper__prospectSearchRow__content">
                <div className="prospectSearchRowWrapper__prospectSearchRow__content__left empty" />
                <div className="prospectSearchRowWrapper__prospectSearchRow__content__right">
                  <div className="prospectSearchRowWrapper__prospectSearchRow__content__right__valueHolder">
                    {arr
                      .sort((a, b) => {
                        if (a.text?.toLowerCase() < b.text?.toLowerCase()) {
                          return -1;
                        } else if (
                          a.text?.toLowerCase() > b.text?.toLowerCase()
                        ) {
                          return 1;
                        } else {
                          return 0;
                        }
                      })
                      .map((num) => {
                        const keyPathItem = key.concat([num.val]);
                        if (isForSniCodes) {
                          if (!sniCodes.length) return null;
                          const found = sniCodes.find(
                            (c) => c.code === num.val
                          );
                          if (!found) {
                            return null;
                          } else {
                            return (
                              <ProspectSearchValueObj
                                key={keyPathItem.join()}
                                obj={{
                                  ...num,
                                  text: `${found.code} - ${found.name}`,
                                }}
                                keyPath={keyPathItem}
                              />
                            );
                          }
                        }
                        return (
                          <ProspectSearchValueObj
                            key={keyPathItem.join()}
                            obj={num}
                            keyPath={keyPathItem}
                          />
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </React.Fragment>
    );
  };

  const _renderValueArrayRow = (arr, key, rowName, unique, noSort) => {
    // Check fields to hide before render row.
    if (!_checkHiddenFields(key)) {
      return;
    }

    if (!arr || (arr && !arr.length)) {
      return;
    }

    const array = noSort
      ? arr
      : arr.sort((a, b) => {
          if (a.text.toLowerCase() < b.text.toLowerCase()) {
            return -1;
          } else if (a.text.toLowerCase() > b.text.toLowerCase()) {
            return 1;
          } else {
            return 0;
          }
        });

    // When in responsive mode and there is more than 11 buttons, switch to dropdown
    // (13 is max carTypes, preferably display these as buttons).
    const values =
      state.user.responsiveMode && arr.length > 13 ? (
        <Dropdown
          displayValue={textHelper.formatText(arr[0].text, true)}
          transparent={true}
          styling={{ adjustToHeader: true }}
        >
          {array.map((num) => {
            return (
              <DropdownItem
                active={num.active}
                key={num.val}
                label={textHelper.formatText(num.text, true)}
                onClick={() => {
                  const keyPathItem = key.concat([num.val]);
                  updateData({
                    keyPath: keyPathItem,
                    type: num.type,
                    value: !num.active,
                  });
                }}
              />
            );
          })}
        </Dropdown>
      ) : (
        array.map((num) => {
          const keyPathItem = key.concat([num.val]);
          return (
            <ProspectSearchValueObj
              key={keyPathItem.join()}
              obj={num}
              keyPath={keyPathItem}
              unique={unique}
            />
          );
          //return _renderValueObj(num, keyPathItem, unique);
        })
      );

    rows.push(
      <div className="prospectSearchRowWrapper" key={key.join()}>
        <div className="prospectSearchRowWrapper__prospectSearchRow">
          <div className="prospectSearchRowWrapper__prospectSearchRow__content">
            <div
              className={`prospectSearchRowWrapper__prospectSearchRow__content__left ${
                !rowName ? 'empty' : ''
              }`}
            >
              {textHelper.formatText(rowName)}
            </div>
            {!loading ? (
              <div className="prospectSearchRowWrapper__prospectSearchRow__content__right">
                {values}
              </div>
            ) : (
              <div className="prospectSearchRowWrapper__prospectSearchRow__content__right">
                <Loading small={true} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const _renderValueArrayRows = (arr, renderChildren, key, rowName, unique) => {
    if (!arr?.length) {
      // For userIds groups we return an info box when array is empty.
      if (
        key.length &&
        key.length === 2 &&
        (key[1] === 'excludeUserIdsGroups' ||
          key[1] === 'exclusiveUserIdsGroups')
      ) {
        rows.push(
          <div className="prospectSearchRowWrapper" key={key.join()}>
            <div className="prospectSearchRowWrapper__prospectSearchRow">
              <div className="prospectSearchRowWrapper__prospectSearchRow__content">
                <div
                  className={`prospectSearchRowWrapper__prospectSearchRow__content__left ${
                    !rowName ? 'empty' : ''
                  }`}
                >
                  {textHelper.formatText(rowName)}
                </div>
                <div className="prospectSearchRowWrapper__prospectSearchRow__content__right">
                  <Info inline={true}>
                    <h4>{tc.groupsMissing}</h4>
                    <p>{tc.groupsMissingInfo}</p>
                  </Info>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return;
      }
    }

    if (renderChildren) {
      const recursive = (array, parentKey, name, lvl) => {
        _renderValueArrayRow(array, parentKey, name, noSort);
        if (!lvl) {
          lvl = 1;
        }
        array.forEach((num) => {
          if (
            num.active &&
            num.children &&
            Array.isArray(num.children) &&
            num.children.length
          ) {
            const childrenKey = parentKey.concat([num.val]);
            const lev = lvl + 1;
            recursive(num.children, childrenKey, num.text, lev);
          }
        });
      };

      recursive(arr, key, rowName);
    } else {
      _renderValueArrayRow(arr, key, rowName, unique, noSort);
    }
  };

  return <>{_renderContent()}</>;
};

const MapStateToProps = (state, props) => {
  return {
    props: props,
    lists: state.lists,
    prospect: state.prospect,
    user: state.user,
  };
};

export default connect(MapStateToProps)(ProspectSearchRow);
