import React, { useContext } from 'react';
import languageContext from 'language-context';
import ActionButton from 'components/action_button';
const AgileBoardPopupCompleteDeal = ({
  previewData,
  setComment,
  setShowLostDeal,
  setShowWonDeal,
}) => {
  const tc = useContext(languageContext);
  if (previewData.item.type != 'deal') {
    return null;
  } else
    return (
      <div className="agileBoardPopupWrapper__agilePopup__content__block">
        <div className="agileBoardPopupWrapper__agilePopup__content__block__content__center">
          <div className="agileBoardPopupWrapper__agilePopup__content__block__content__completeDeal">
            <ActionButton
              class="completeDealAsLost"
              icon="lost"
              label={tc.lost}
              onClick={() => {
                setComment('');
                setShowLostDeal(true);
              }}
              type="regular"
            />
            <ActionButton
              class="completeDealAsWon"
              icon="won"
              label={tc.won}
              onClick={() => {
                setComment('');
                setShowWonDeal(true);
              }}
              type="regular"
            />
          </div>
        </div>
      </div>
    );
};

export default AgileBoardPopupCompleteDeal;
