import { textHelper } from "helpers";
import { updateData } from "store/prospect/tasks";

const ProspectSearchValueObj = ({ obj, keyPath, unique }) => {
  const text =
    keyPath.includes("brands") || keyPath.includes("dealerSalesmen")
      ? textHelper.formatText(obj.text, true)
      : textHelper.formatText(obj.text);
  return (
    <div
      className={obj.active ? "prospectValue active" : "prospectValue"}
      onClick={() => {
        updateData({
          keyPath: keyPath,
          type: obj.type ?? "value",
          value: !obj.active,
          unique,
        });
      }}
    >
      <p>{text}</p>
    </div>
  );
};

export default ProspectSearchValueObj;
