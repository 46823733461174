import { tableActionTypes } from './actions';

interface TableState {
  activeFilters: object;
  isInitialized: boolean;
}

const initialState: TableState = {
  activeFilters: {},
  isInitialized: false,
};

export const tableReducer = (state = initialState, action) => {
  switch (action.type) {
    case tableActionTypes.TABLE_SET_ACTIVE_FILTERS: {
      return { ...state, activeFilters: action.payload.data };
    }
    case tableActionTypes.TABLE_SET_IS_INITIALIZED: {
      return { ...state, isInitialized: action.payload.data };
    }
    default: {
      return state;
    }
  }
};
