import React from "react";

/**
 * Render empty day for EventsCalendar component.
 *
 * props.text - string - This component can hold a text string, used to show weekday names.
 * props.weekday - bool
 */
const EventsCalendarDayEmpty = (props) => {
  return (
    <div
      className={`eventsCalendarDayWrapper empty ${
        props.weekday ? "weekday" : ""
      }`}
    >
      <div className="eventsCalendarDayWrapper__eventsCalendarDay">
        <div className="eventsCalendarDayWrapper__eventsCalendarDay__date">
          {props.text}
        </div>
      </div>
    </div>
  );
};

export default EventsCalendarDayEmpty;
