import React, { useContext, useEffect, useRef, useState } from 'react';
import LanguageContext from 'language-context';
import { showFlashMessage } from 'store/flash_messages/tasks';
import { mergeLists } from 'store/lists/tasks';
import Popup from 'components/popup';
import WidgetFooter from 'components/widget_footer';
import WidgetHeader from '../widget_header';

const MergeLists = (props) => {
  const tc = useContext(LanguageContext);
  const [listName, setListName] = useState('');
  const mergeListsNameInputRef = useRef(null);

  useEffect(() => {
    mergeListsNameInputRef &&
      mergeListsNameInputRef.current &&
      mergeListsNameInputRef.current.focus();
  }, []);

  const _mergeLists = async () => {
    if (listName.length) {
      await mergeLists({
        listIds: props.selectedLists.map((list) => list.id),
        name: listName,
      });
      props.close();
      return setListName('');
    } else {
      return showFlashMessage(tc.nameCannotBeEmpty, 'info');
    }
  };

  return props.selectedLists?.length ? (
    <Popup close={props.close} size="small">
      <div className="listsGenericWrapper">
        <div className="listsGenericWrapper__listsGeneric">
          <div className="listsGenericWrapper__listsGeneric__header">
            <WidgetHeader
              headline={tc.mergeLists}
              headlineSub={tc.mergeListsInfo}
            />
          </div>
          <div className="listsGenericWrapper__listsGeneric__content">
            <p className="noWrap">{tc.nameNewList}:</p>
            <input
              onChange={(e) => {
                setListName(e.target.value);
              }}
              ref={mergeListsNameInputRef}
            />
          </div>
          <div className="listsGenericWrapper__listsGeneric__footer">
            <WidgetFooter
              buttonOneFunc={_mergeLists}
              buttonOneText={tc.mergeLists}
              buttonTwoFunc={props.close}
              buttonTwoText={tc.cancel}
            />
          </div>
        </div>
      </div>
    </Popup>
  ) : null;
};

export default MergeLists;
