import React from 'react';
import Icon from 'components/icon';

/**
 * Item for component Dropdown.
 * When you want a delimiter for groups of different items in dropdown, with checkbox.
 * Checkbox is normally used to select all items in group.
 *
 * @param props.active - bool
 * @param props.label - string
 * @param props.onClick - func
 */
export const DropdownItemDelimiterWithCheckbox = (props) => {
    return (
        <div className='dropdownItemDelimiterWithCheckboxWrapper' onClick={props.onClick}>
            <div className={(props.active) ?
                'dropdownItemDelimiterWithCheckboxWrapper__dropdownItemDelimiterWithCheckboxActive' :
                'dropdownItemDelimiterWithCheckboxWrapper__dropdownItemDelimiterWithCheckbox'}
            >
                <div className='dropdownItemDelimiterWithCheckboxWrapper__dropdownItemDelimiterWithCheckbox__left'>
                    {props.label}
                </div>
                <div className='dropdownItemDelimiterWithCheckboxWrapper__dropdownItemDelimiterWithCheckbox__right'>
                    {(props.active) ? <Icon val='check'/> : <Icon val='checkbox'/>}
                </div>
            </div>
        </div>
    );
};
