import React, { useContext, useEffect, useState } from 'react';
import LanguageContext from 'language-context';
import { connect } from 'react-redux';
import {
  createMailingsOrder,
  createMailingsPreview,
  getListMailingsPrice,
  getMailingsPrice,
  mailingsIsUntouched,
  resetMailings,
  setMailingsInput,
  setMailingsIsReady,
  setMailingsPostage,
  setMailingsType,
} from 'store/orders/tasks';
import { convertPdfUrlToBlob, getUserPdfs } from 'store/pdf/tasks';
import { showFlashMessage } from 'store/flash_messages/tasks';
import costHelper from 'shared_helpers/cost_helper';
import listHelper from 'shared_helpers/list_helper';
import ActionButton from 'components/action_button';
import CreateListSubscription from 'components/create_list_subscription';
import Icon from 'components/icon';
import IconStyled from 'components/icon_styled';
import Info from 'components/info';
import Loading from 'components/loading';
import PdfArchiveManageFiles from 'components/pdf_archive_manage_files';
import PdfPreview from 'components/pdf_preview';
import Popup from 'components/popup';
import WidgetFooter from 'components/widget_footer';
import WidgetHeader from 'components/widget_header';
import { miscHelper } from 'helpers';

/**
 * Create a mailings order.
 *
 * User have to select PDF file to create a list order.
 * The have to use a file from their pdf archive.
 * They can preview a file that isn't validated, but they cannot save the list order.
 * A PDF file gets validated by Bilprospekt support in admin portal.
 *
 * Note that we (for now) have a page size check on frontend as well as on backend.
 * If backend returns size error state.orders.mailingsPdfPreview.blob.sizeError is set to true.
 */
const OrdersOrderMailings = (state) => {
  const [fileIsUserApproved, setFileIsUserApproved] = useState(false); // We mostly use state.orders.mailingsIsReady flag to see if the file is validated, this is just an extra flag for when user manually clicks to approve the file.

  const [previewError, setPreviewError] = useState(null);
  const [showCreateListSubscription, setShowCreateListSubscription] =
    useState(false);
  const [showPdfPreview, setShowPdfPreview] = useState(false);
  const [sumPrice, setSumPrice] = useState(0);
  const [priceIsLoading, setPriceIsLoading] = useState(false);
  const [isOrdering, setIsOrdering] = useState(false);
  const tc = useContext(LanguageContext);

  const { list } = state.props;

  const companies = list?.prospectInformation?.companies ?? 0;
  const nameIsFresh =
    list?.orderHistory?.name?.isDelivered &&
    list?.orderHistory?.name?.isAvailable;
  const orderIsPending = miscHelper.hasPendingOrder(list);
  const privatePersons = list?.prospectInformation?.privatePersons ?? 0;
  const amount = companies + privatePersons;
  const { mailingsVehicleError } = state;

  useEffect(() => {
    getUserPdfs({
      type: 'mailings',
    });
  }, []);

  useEffect(() => {
    setMailingsIsReady(false);
  }, []);

  useEffect(() => {
    if (state.orders.mailingsPdfPreview?.blob?.sizeError) {
      setPreviewError(tc.mailingsPreviewSizeWarning);
    }
  }, [state.orders.mailingsPdfPreview.blob]);

  useEffect(() => {
    if (state.orders.mailingsType === 'car') return;
    let sum = 0;
    if (amount > 0) {
      if (!nameIsFresh) {
        // sum += costHelper.getStartCost(privatePersons, false);
        sum += costHelper.getRawCost(privatePersons, false);
      }
      sum += Math.round(
        costHelper.getCostForMailings(
          privatePersons + companies,
          state.orders.mailingsPrice
        )
      );
    }

    setSumPrice(sum);
  }, [
    companies,
    privatePersons,
    state.orders.mailingsPrice,
    amount,
    nameIsFresh,
    state.orders.mailingsType,
  ]);

  useEffect(() => {
    async function updatePriceForMailing() {
      if (state.orders.mailingsType !== 'car') return;
      setPriceIsLoading(true);
      const price = await getListMailingsPrice({
        listId: state.props.list?.id,
        price: state.orders.mailingsPrice,
        type: 'mailings',
      });
      if (price === 'Går inte att beställa') {
        setPriceIsLoading(false);
        setSumPrice(0);
        return;
      }
      if (!price || !typeof price === 'number') {
        let sum = 0;
        if (amount > 0) {
          if (!nameIsFresh) {
            // sum += costHelper.getStartCost(privatePersons, false);
            sum += costHelper.getRawCost(privatePersons, false);
          }
          sum += Math.round(
            costHelper.getCostForMailings(
              privatePersons + companies,
              state.orders.mailingsPrice
            )
          );
        }

        setSumPrice(sum);
        return;
      }
      let sum = 0;
      if (amount > 0 && !nameIsFresh)
        sum += costHelper.getRawCost(privatePersons, false);
      if (amount > 0) sum += price;
      setPriceIsLoading(false);
      setSumPrice(Math.round(sum));
    }
    updatePriceForMailing();
  }, [
    state.props.list,
    state.orders.mailingsPrice,
    state.orders.mailingsType,
    amount,
    nameIsFresh,
    privatePersons,
    companies,
  ]);

  useEffect(() => {
    // Set price according to changes in postage options.
    getMailingsPrice();
  }, [state.orders.mailingsPostage]);

  /**
   * Handle file input.
   * We've disabled images, logotypes etc. Only handles pdf archive s3 url.
   *
   * @param s3SignedUrl - string - When using archive instead of input field. This should be signed url (temporary and accessible).
   * @param s3Key - string - When using archive instead of input field. This should be static url not signed url.
   * @param name - string
   */
  const _handleFileInputChange = async (s3SignedUrl, s3Key, name) => {
    let fileToUpload;
    if (!s3SignedUrl || !s3Key) {
      return console.error('Missing params in _handleFileInputChange');
    }

    // When url to pdf is provided, convert to blob.
    fileToUpload = await convertPdfUrlToBlob(s3SignedUrl);

    const fileReader = new FileReader();

    if (fileReader && fileToUpload) {
      // 8 MB limit.
      if (fileToUpload.size / 1000000 > 5) {
        return showFlashMessage(tc.fileIsTooBig5Mb, 'fail');
      }

      fileReader.readAsDataURL(fileToUpload);

      fileReader.onload = () => {
        // Pdf archive.
        if (fileReader.result.indexOf('data:application/pdf;base64,') === -1) {
          return showFlashMessage(tc.wrongFileOnlyPdf, 'fail');
        }

        setMailingsInput({
          name: name,
          pdf: fileReader.result.replace('data:application/pdf;base64,', ''),
          s3Key: s3Key,
          s3SignedUrl: s3SignedUrl,
        });
      };
    }
  };

  const _reset = () => {
    resetMailings();
    setFileIsUserApproved(false);
    setPreviewError(null);
  };

  const _stateCheck = () => {
    return (
      state.orders?.mailingsPostage &&
      state.orders?.mailingsPrice > 0 &&
      Number.isFinite(amount)
    );
  };

  return _stateCheck() ? (
    <div className="ordersInformationWrapper">
      <div className="ordersInformationWrapper__ordersInformation">
        <div className="ordersInformationWrapper__ordersInformation__content">
          {amount > 0 && !orderIsPending ? (
            <>
              <div className="ordersInformationWrapper__ordersInformation__content__section">
                <div className="ordersInformationWrapper__ordersInformation__content__section__title">
                  <WidgetHeader headline={tc.orderMailings} />
                </div>
                <div className="ordersInformationWrapper__ordersInformation__content__section__block">
                  <span className="label">1. {tc.chooseMailingsMode}</span>
                  <div className="flexParent">
                    <ActionButton
                      active={state.orders.mailingsPostage.type === 'envelope'}
                      icon={
                        state.orders.mailingsPostage.type === 'envelope'
                          ? 'check'
                          : null
                      }
                      label={tc.envelope}
                      onClick={() => {
                        setMailingsPostage({
                          type: 'envelope',
                        });
                        setPreviewError(null);
                        setFileIsUserApproved(false);
                      }}
                      type="regular"
                    />
                    <ActionButton
                      active={state.orders.mailingsPostage.type === 'postcard'}
                      icon={
                        state.orders.mailingsPostage.type === 'postcard'
                          ? 'check'
                          : null
                      }
                      label={tc.postcard}
                      onClick={() => {
                        setMailingsPostage({
                          type: 'postcard',
                        });
                        setPreviewError(null);
                        setFileIsUserApproved(false);
                      }}
                      type="regular"
                    />
                  </div>
                  <>
                    <p style={{ margin: '10px 0 5px' }}>
                      {tc.carMailingsTypeExplanation}
                    </p>
                  </>
                  <div className="flexParent">
                    <ActionButton
                      active={state.orders.mailingsType === 'user'}
                      icon={
                        state.orders.mailingsType === 'user' ? 'check' : null
                      }
                      label={tc.everyUser}
                      onClick={() => {
                        setMailingsType({ mailingsType: 'user' });
                        setPreviewError(null);
                        setFileIsUserApproved(false);
                      }}
                      type="regular"
                    />
                    <ActionButton
                      disabled={!state.props.list?.meta?.criterias}
                      active={state.orders.mailingsType === 'car'}
                      icon={
                        state.orders.mailingsType === 'car' ? 'check' : null
                      }
                      label={tc.everyVehicle}
                      onClick={() => {
                        setMailingsType({ mailingsType: 'car' });
                        setPreviewError(null);
                        setFileIsUserApproved(false);
                      }}
                      type="regular"
                    />
                  </div>
                </div>
                <div className="ordersInformationWrapper__ordersInformation__content__section__block">
                  <span className="label">2. {tc.selectFile}</span>
                  <p>{tc.orderMailingsInstructionsInfoPdfValidation}</p>
                  <div className="marginTop">
                    <PdfArchiveManageFiles
                      fullWidth={true}
                      onSelect={(pdf) => {
                        setFileIsUserApproved(false);
                        setPreviewError(null);
                        if (state.orders?.mailingsInput?.s3Key === pdf.s3Key) {
                          setMailingsInput({
                            name: '',
                            pdf: '',
                            s3Key: '',
                            s3SignedUrl: '',
                          });
                        } else {
                          _handleFileInputChange(
                            pdf.s3SignedUrl,
                            pdf.s3Key,
                            pdf.name
                          );
                        }
                      }}
                      preview={false}
                      share={false}
                      selectedS3Key={state.orders?.mailingsInput?.s3Key}
                    />
                  </div>
                </div>
                <div className="ordersInformationWrapper__ordersInformation__content__section__block">
                  <span className="label">3. {tc.customizeOrder}</span>
                  <div className="flexParent">
                    <div
                      className={`includeValue ${
                        state.orders.mailingsPostage.type === 'postcard'
                          ? 'disabled'
                          : ''
                      }`}
                      onClick={() => {
                        setMailingsPostage({ postage: 'A' });
                      }}
                    >
                      <Icon
                        val={
                          state.orders.mailingsPostage.postage === 'A'
                            ? 'check'
                            : 'checkbox'
                        }
                      />
                      {tc.postageA}
                    </div>
                    <div
                      className={`includeValue ${
                        state.orders.mailingsPostage.type === 'postcard'
                          ? 'disabled'
                          : ''
                      }`}
                      onClick={() => {
                        setMailingsPostage({ postage: 'B' });
                      }}
                    >
                      <Icon
                        val={
                          state.orders.mailingsPostage.postage === 'B'
                            ? 'check'
                            : 'checkbox'
                        }
                      />
                      {tc.postageB}
                    </div>
                  </div>
                  <div className="flexParent">
                    <div
                      className="includeValue"
                      onClick={() => {
                        setMailingsPostage({
                          color: !state.orders.mailingsPostage.color,
                        });
                      }}
                    >
                      <Icon
                        val={
                          state.orders.mailingsPostage.color
                            ? 'check'
                            : 'checkbox'
                        }
                      />
                      {tc.printInColor}
                    </div>
                  </div>
                  {state.orders.mailingsPostage.type === 'envelope' ? (
                    <div className="flexParent">
                      <div
                        className="includeValue"
                        onClick={() => {
                          setMailingsPostage({
                            simplexDuplex:
                              state.orders.mailingsPostage.simplexDuplex === 'D'
                                ? 'S'
                                : 'D',
                          });
                        }}
                      >
                        <Icon
                          val={
                            state.orders.mailingsPostage.simplexDuplex === 'D'
                              ? 'check'
                              : 'checkbox'
                          }
                        />
                        {tc.doubleSided}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="ordersInformationWrapper__ordersInformation__content__section__block">
                  <span className="label">4. {tc.checkResult}</span>
                  <div>
                    <p>{tc.checkResultToCreateListOrder}</p>
                    <div className="flexParent marginTop">
                      <ActionButton
                        disabled={mailingsIsUntouched()}
                        label={tc.reset}
                        onClick={() => {
                          _reset();
                        }}
                        type="regular"
                      />
                      <ActionButton
                        disabled={!state.orders.mailingsInput?.pdf?.length}
                        label={tc.preview}
                        onClick={() => {
                          setMailingsIsReady(false);
                          setFileIsUserApproved(false);
                          setPreviewError(null);
                          createMailingsPreview();
                          setShowPdfPreview(true);
                        }}
                        type="regular"
                      />
                      <ActionButton
                        disabled={
                          !state.orders?.mailingsInput?.pdf?.length ||
                          previewError ||
                          !state.orders.mailingsIsReady ||
                          fileIsUserApproved
                        }
                        label={tc.saveFileForMailings}
                        onClick={() => {
                          setFileIsUserApproved(true);
                        }}
                        type="highlight"
                      />
                    </div>
                  </div>
                </div>
                {previewError ? (
                  <div className="ordersInformationWrapper__ordersInformation__content__section__block">
                    <Info>
                      <h4>{tc.mailingsPreviewInvalidPDF}</h4>
                      <p>{previewError}</p>
                    </Info>
                  </div>
                ) : state.orders.mailingsIsReady && fileIsUserApproved ? (
                  <div className="ordersInformationWrapper__ordersInformation__content__section__block approved">
                    <IconStyled iconVal="check" class="successFill" />
                    <p>
                      {tc.validFile}: {state.orders.mailingsInput.name}
                    </p>
                  </div>
                ) : null}
                <div className="ordersInformationWrapper__ordersInformation__content__section__block">
                  <span className="label">{tc.deliveryTime}</span>
                  <p>{tc.deliveryTimeMailings1}</p>
                  <p>{tc.deliveryTimeMailings2}</p>
                </div>
                <div className="ordersInformationWrapper__ordersInformation__content__section__block">
                  <span className="label">{tc.prices}</span>
                  {!nameIsFresh ? (
                    <p>
                      <span className="lineThrough">
                        {tc.priceStart}:{' '}
                        {costHelper.getStartCost(privatePersons, false)}{' '}
                        {tc.swedishCrowns.toLowerCase()}
                      </span>
                    </p>
                  ) : null}
                  {!nameIsFresh ? (
                    <p>
                      {tc.priceName}:{' '}
                      {sumPrice === 0
                        ? 0
                        : costHelper.getRawCost(privatePersons, false)}{' '}
                      {tc.swedishCrowns.toLowerCase()}
                    </p>
                  ) : null}
                  <p>
                    {tc.priceMailings}:{' '}
                    {sumPrice === 0
                      ? 0
                      : priceIsLoading
                      ? tc.calculatingPrice
                      : !nameIsFresh
                      ? Math.round(
                          sumPrice -
                            costHelper.getRawCost(privatePersons, false)
                        )
                      : Math.round(sumPrice)}
                    {/* {priceIsLoading
                      ? tc.calculatingPrice
                      : !nameIsFresh
                      ? Math.round(
                          sumPrice -
                            costHelper.getRawCost(privatePersons, false)
                        )
                      : Math.round(sumPrice)}{" "} */}
                    {/* {Math.round(
                      costHelper.getCostForMailings(
                        privatePersons + companies,
                        state.orders.mailingsPrice
                      )
                    )}{" "} */}
                    {priceIsLoading ? null : tc.swedishCrowns.toLowerCase()}
                  </p>
                  <p className="priceSum">
                    {tc.priceSum}:{' '}
                    <strong className="highlight">
                      {priceIsLoading ? (
                        tc.calculatingPrice
                      ) : (
                        <>
                          {sumPrice} {tc.swedishCrowns.toLowerCase()}
                        </>
                      )}
                    </strong>
                  </p>
                </div>
              </div>
            </>
          ) : (
            <>
              {orderIsPending ? (
                <>
                  <Info>
                    <h4>{tc.orderIsPending}</h4>
                    <p>{tc.cannotOrderIsPending}</p>
                  </Info>
                </>
              ) : null}
              {amount === 0 && !orderIsPending ? (
                <>
                  <Info>
                    <h4>{tc.listIsEmpty}</h4>
                    <p>{tc.cannotOrderEmptyList}</p>
                  </Info>
                </>
              ) : null}
            </>
          )}
        </div>
        {!orderIsPending && amount > 0 ? (
          <div className="ordersInformationWrapper__ordersInformation__footer">
            {state.orders.mailingsIsReady && fileIsUserApproved ? (
              <>
                {mailingsVehicleError && state.orders.mailingsType === 'car' ? (
                  <p>{tc.mailingsVehicleLimitError}</p>
                ) : null}
                {mailingsVehicleError &&
                state.orders.mailingsType === 'user' ? (
                  <p>{tc.mailingsParameterNotice}</p>
                ) : null}
                <div className="minHeight40">
                  {isOrdering ? <Loading small={true} /> : null}
                </div>
                <WidgetFooter
                  buttonOneFunc={async () => {
                    if (isOrdering) return;
                    setIsOrdering(true);
                    await createMailingsOrder({ orderName: !nameIsFresh });
                  }}
                  disableButtonOne={
                    (mailingsVehicleError &&
                      state.orders.mailingsType === 'car') ||
                    isOrdering
                      ? true
                      : false
                  }
                  buttonOneText={tc.placeOrder}
                  buttonTwoFunc={() => {
                    setShowCreateListSubscription(true);
                  }}
                  disableButtonTwo={
                    mailingsVehicleError && state.orders.mailingsType === 'car'
                      ? true
                      : false
                  }
                  buttonTwoText={tc.automateOrder}
                  noBorder={true}
                />
              </>
            ) : null}
          </div>
        ) : null}
        {showCreateListSubscription ? (
          <CreateListSubscription
            close={() => {
              setShowCreateListSubscription(false);
            }}
            lists={[state.orders?.list]}
            mailings={{
              color: state.orders?.mailingsPostage?.color,
              postage: state.orders?.mailingsPostage?.postage,
              s3Key: state.orders?.mailingsInput?.s3Key,
              s3SignedUrl: state.orders?.mailingsInput?.s3SignedUrl,
              simplexDuplex: state.orders?.mailingsPostage?.simplexDuplex,
              type: state.orders?.mailingsPostage?.type,
            }}
            source="orders"
            subscribeFlag={
              listHelper.subscriptionFlags.excludeProspects ^
              listHelper.subscriptionFlags.name ^
              listHelper.subscriptionFlags.mailings
            }
          />
        ) : null}
        {showPdfPreview &&
        state.orders.mailingsPdfPreview?.blob &&
        !state.orders.mailingsPdfPreview?.blob?.sizeError ? (
          <Popup
            size="xl"
            close={() => {
              setShowPdfPreview(false);
            }}
          >
            <div className="genericPopupContentWrapper">
              <div className="genericPopupContentWrapper__genericPopupContent">
                <div className="genericPopupContentWrapper__genericPopupContent__header">
                  <WidgetHeader
                    headline={tc.previewResult}
                    headlineSub={tc.namesAreExamples}
                  />
                </div>
                <div className="genericPopupContentWrapper__genericPopupContent__content">
                  <PdfPreview
                    source={state.orders.mailingsPdfPreview.blob}
                    onLoadDocument={(totalPages) => {
                      if (totalPages < 2) {
                        setMailingsPostage({
                          simplexDuplex: 'S',
                        });
                      } else if (
                        totalPages > 2 &&
                        state.orders.mailingsPostage.type === 'postcard'
                      ) {
                        setShowPdfPreview(false);
                        setPreviewError(tc.mailingsPreviewPagesWarning);
                      }
                    }}
                    // onLoadPage={(obj) => {
                    //   // We already have a size check if the preview file is coming from our mailings backend.
                    //   // But we might want to preview files not generated from mailings backend, so do a size check here as well.

                    //   // Convert points to mm and check for correct size (A4: 210 x 297 mm, A5: 216 x 154 mm).
                    //   const pointToMM = 0.352777778; // 1 point = 0.352777778 mm
                    //   obj.width = obj.width * pointToMM;
                    //   obj.height = obj.height * pointToMM;
                    //   if (
                    //     (state.orders.mailingsPostage.type === "envelope" &&
                    //       (obj.width > 211 ||
                    //         obj.width < 209 ||
                    //         obj.height > 298 ||
                    //         obj.height < 296)) ||
                    //     (state.orders.mailingsPostage.type === "postcard" &&
                    //       (obj.width > 217 ||
                    //         obj.width < 215 ||
                    //         obj.height > 155 ||
                    //         obj.height < 153))
                    //   ) {
                    //     setShowPdfPreview(false);
                    //     setPreviewError(tc.mailingsPreviewSizeWarning);
                    //   }
                    // }}
                  />
                </div>
                <div className="genericPopupContentWrapper__genericPopupContent__footer">
                  <WidgetFooter
                    buttonTwoFunc={() => {
                      setShowPdfPreview(false);
                      if (!previewError) {
                        const foundPdfFromArchive = Array.isArray(
                          state.pdf.userPdfs
                        )
                          ? state.pdf.userPdfs.find(
                              (num) =>
                                num.s3Key === state.orders.mailingsInput.s3Key
                            )
                          : null;

                        if (foundPdfFromArchive?.validated) {
                          // Check that the selected PDF is validated.
                          setMailingsIsReady(true);
                        }
                      }
                    }}
                    buttonTwoText={tc.closePreview}
                  />
                </div>
              </div>
            </div>
          </Popup>
        ) : null}
      </div>
    </div>
  ) : (
    <Loading />
  );
};

const MapStateToProps = (state, props) => {
  return {
    orders: state.orders,
    pdf: state.pdf,
    props: props,
  };
};

export default connect(MapStateToProps)(OrdersOrderMailings);
