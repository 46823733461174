import React, { useContext, useEffect, useState } from 'react';
import ActionButton from 'components/action_button/action_button';
import { showFlashMessage } from 'store/flash_messages/tasks';
import LanguageContext from 'language-context';

const SettingsHubspot = () => {
  const [pipeline, setPipeline] = useState('');
  const [dealStage, setDealstage] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [hasExistingKey, setHasExistingKey] = useState(false);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    async function checkForExistingKey() {
      const response = await fetch('/integration/hubspot', {
        method: 'GET',
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setHasExistingKey(data.available);
      }
    }
    checkForExistingKey();
  }, []);

  const handleSubmit = async () => {
    try {
      if (!apiKey || !dealStage || !pipeline) {
        return showFlashMessage(tc.hubspotFillAllFieldsBeforeSave, 'fail');
      }

      const response = await fetch('/integration/hubspot', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ apiKey, dealStage, pipeline }),
      });
      if (!response.ok || response.status !== 200) {
        showFlashMessage(tc.hubspotErrorWhenSavingInfo, 'fail');
      } else {
        showFlashMessage(tc.hubspotInfoSaved, 'success');
        setHasExistingKey(true);
        setApiKey('');
        setPipeline('');
        setDealstage('');
      }
    } catch (err) {
      showFlashMessage(tc.hubspotErrorWhenSavingInfo, 'fail');
    }
  };
  const handleDeleteCurrent = async () => {
    try {
      if (!hasExistingKey) return;
      await fetch('/integration/hubspot', {
        method: 'DELETE',
        credentials: 'include',
      });
      setHasExistingKey(false);
    } catch (err) {
      console.error('Error when deleting current hubspot info: ', err);
    }
  };
  return (
    <div className="settingsPageWrapper">
      <div className="settingsPageWrapper__settingsPage">
        <div
          className="settingsPageWrapper__settingsPage__section"
          id="toggleWidgetsAnalyse"
        >
          <div className="settingsPageWrapper__settingsPage__section__header">
            {tc.hubspotAddInfo}
          </div>
          <div className="settingsPageWrapper__settingsPage__section__content">
            {tc.hubspotAddInfoDescription}
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
            >
              <div>
                <label htmlFor="pipeline">{tc.hubspotPipelineId}</label>
                <input
                  id="pipeline"
                  name="pipeline"
                  value={pipeline}
                  onChange={(e) => setPipeline(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="dealStage">{tc.hubspotDealstageId}</label>
                <input
                  id="dealStage"
                  name="dealStage"
                  value={dealStage}
                  onChange={(e) => setDealstage(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="apikey">{tc.hubspotApiKey}</label>
                <input
                  id="apikey"
                  name="apikey"
                  value={apiKey}
                  onChange={(e) => setApiKey(e.target.value)}
                />
              </div>
              {hasExistingKey ? (
                <>
                  <p>{tc.hubspotAvailableApiKey}</p>
                  <ActionButton
                    label={tc.remove}
                    onClick={() => {
                      handleDeleteCurrent();
                    }}
                    disabled={!hasExistingKey}
                  />
                </>
              ) : null}

              <ActionButton
                label={tc.save}
                onClick={() => {
                  handleSubmit();
                }}
                disabled={!apiKey || !dealStage || !pipeline}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsHubspot;
