import moment from 'moment';

export const getWeeks = (filters) => {
  const {
    previousMonth,
    previousWeek,
    currentMonth,
    currentWeek,
    date,
  } = filters;
  const { from, to } = date;

  const weeks = (previousMonth || currentMonth)
    ? 4
    : (previousWeek || currentWeek)
      ? 1
      : moment(to).diff(moment(from), 'weeks')

  return weeks === 0 ? 1 : weeks;
}

export const aggregateGoals = (goals, weeks) => {
  const skipProps = ['id', 'user_id', 'dealer_id'];
  const aggregatedGoals = goals.reduce((acc, item, index) => {
    Object.keys(item).map((prop) => {
      if (skipProps.includes(prop)) {
        return;
      }
      const propNumber = !isNaN(item[prop]) ? Number(item[prop]) : 0;
      const accNumber = !isNaN(acc[prop]) ? Number(acc[prop]) : 0;
      acc[prop] = propNumber + accNumber;
    })

    if (index === goals.length - 1) {
      for (const prop in acc) {
        if (!skipProps.includes(prop)) {
          acc[prop] = acc[prop] * weeks;
        }
      }
    }
    return acc;
  }, {})
  return aggregatedGoals;
} 

export const calculateGoals = (goals, weeks) => {
  const skipProps = ['id', 'user_id', 'dealer_id'];

  return goals.map((goal) => {
    const newObj = {};

    for (const prop in goal) {
      if (skipProps.includes(prop)) {
        newObj[prop] = goal[prop];
      } else {
        newObj[prop] = weeks * Number(goal[prop])
      }
    }

    return newObj;
  })

}

