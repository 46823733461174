import React, { useContext, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import LanguageContext from "language-context";
import { showFlashMessage } from "store/flash_messages/tasks";
import { agileHelper } from "helpers";
import {
  checkIfPhaseHasDeals,
  getColumnsData,
  updateColumnStructure,
  updateColumnTitle,
} from "store/agile/tasks";
import ActionButton from "components/action_button";
import Icon from "components/icon";
import Loading from "components/loading";
import ToggleOption from "components/toggle_option";
import SettingsAgileActivityTypes from "components/settings/settings_agile_activity_types";

/**
 * Agile settings section.
 */
const SettingsAgile = (state) => {
  const [agileColumnTitleToEdit, setAgileColumnTitleToEdit] = useState({
    id: null,
    title: null,
  });
  const [newAgileColumnName, setNewAgileColumnName] = useState(null);
  const [removeAgileColumn, setRemoveAgileColumn] = useState({
    id: null,
    title: null,
  });
  const [showAddAgileColumn, setShowAddAgileColumn] = useState(false);
  const agileColumnTitleInputRef = useRef(null);
  const newAgileColumnNameInputRef = useRef(null);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    getColumnsData({});
  }, []);

  useEffect(() => {
    if (agileColumnTitleToEdit.id) {
      agileColumnTitleInputRef &&
        agileColumnTitleInputRef.current &&
        agileColumnTitleInputRef.current.focus();
    }
  }, [agileColumnTitleToEdit]);

  useEffect(() => {
    if (showAddAgileColumn) {
      newAgileColumnNameInputRef &&
        newAgileColumnNameInputRef.current &&
        newAgileColumnNameInputRef.current.focus();
    }
  }, [showAddAgileColumn]);

  const _addAgileColumn = async () => {
    if (
      !newAgileColumnName ||
      (newAgileColumnName && newAgileColumnName.length < 2)
    ) {
      return showFlashMessage(tc.nameTooShort, "info");
    }

    // This is the static values for deal phases.
    const forbiddenValues = ["idle", "lost", "prospect", "trash", "won"];
    if (forbiddenValues.includes(newAgileColumnName.toLowerCase())) {
      return showFlashMessage(tc.cannotUseThatColumnName, "info");
    }

    if (state.agile.columns.length > 19) {
      return showFlashMessage(tc.tooManyColumns, "info");
    }

    let id = agileHelper.buildColumnTitleToPhase(newAgileColumnName);
    if (!id) {
      return showFlashMessage(tc.genericFailMessage, "fail");
    }

    // If id already exists add integer.
    const _createUniqueId = (str, i) => {
      if (!state.agile.columns.find((num) => num.id === str)) {
        return (id = str);
      } else if (!state.agile.columns.find((num) => num.id === str + "-" + i)) {
        return (id = str + "-" + i);
      } else {
        return _createUniqueId(str, i + 1);
      }
    };

    _createUniqueId(id, 0);

    const newAgileColumns = JSON.parse(JSON.stringify(state.agile.columns));
    newAgileColumns.push({
      id: id,
      title: newAgileColumnName.replace(/[^a-zåäö0-9 -]/gi, "").trim(), // Replace special characters and trim.
      items: [],
      visible: true,
    });

    if (newAgileColumnNameInputRef?.current) {
      newAgileColumnNameInputRef.current.value = "";
    }

    await updateColumnStructure(newAgileColumns);
    setNewAgileColumnName(null);
    setShowAddAgileColumn(false);

    // Scroll a bit to the right to display new column.
    setTimeout(() => {
      const agileKanbanBoardContainer = document.querySelector(
        "#agileKanbanBoardContainer"
      );
      if (agileKanbanBoardContainer) {
        document.querySelector("#agileKanbanBoardContainer").scroll(5000, 0);
      }
    }, 1000);

    return showFlashMessage(tc.columnHasBeenAdded, "success");
  };

  const _removeAgileColumn = async () => {
    if (!removeAgileColumn?.id) {
      return showFlashMessage(tc.genericFailMessage, "fail");
    }

    const hasDeals = await checkIfPhaseHasDeals({
      phase: removeAgileColumn.id,
    });

    if (hasDeals) {
      setRemoveAgileColumn({
        id: null,
        title: null,
      });
      return showFlashMessage(tc.columnHasDeals, "info");
    } else {
      let newAgileColumns = JSON.parse(JSON.stringify(state.agile.columns)); // Clone columns.
      newAgileColumns = newAgileColumns.filter(
        (column) => column.id !== removeAgileColumn.id
      );
      setRemoveAgileColumn({
        id: null,
        title: null,
      });
      setAgileColumnTitleToEdit({
        id: null,
        title: null,
      });
      await updateColumnStructure(newAgileColumns);
      return showFlashMessage(tc.columnHasBeenRemoved, "success");
    }
  };

  const _saveNewAgileColumnTitle = async () => {
    if (agileColumnTitleToEdit.title.length < 2) {
      return showFlashMessage(tc.nameTooShort, "info");
    } else {
      updateColumnTitle({
        id: agileColumnTitleToEdit.id,
        title: agileColumnTitleToEdit.title,
      });

      showFlashMessage(tc.changesSaved, "success");
      setAgileColumnTitleToEdit({
        id: null,
        title: null,
      });
    }
  };

  const _stateCheck = () => {
    return !!Array.isArray(state.agile?.columns);
  };

  const _toggleVisibility = (id) => {
    let columnsUpdated = JSON.parse(JSON.stringify(state.agile.columns));
    columnsUpdated.map((column, i) => {
      if (column.id === id) {
        column.visible = !column.visible;
      }
      return column;
    });

    updateColumnStructure(columnsUpdated);
  };

  return _stateCheck() ? (
    <div className="settingsPageWrapper">
      <div className="settingsPageWrapper__settingsPage">
        <div
          className="settingsPageWrapper__settingsPage__section"
          id="manageColumns"
        >
          <div className="settingsPageWrapper__settingsPage__section__header">
            {tc.manageColumns}
          </div>
          <div className="settingsPageWrapper__settingsPage__section__content">
            <p>{tc.manageColumnsInfo1}</p>
            <p>{tc.manageColumnsInfo2}</p>
            {!removeAgileColumn.id && !agileColumnTitleToEdit.id ? (
              <div className="settingsPageWrapper__settingsPage__section__content__holder">
                {showAddAgileColumn ? (
                  <div className="inputHolder">
                    <div className="inputHolder__left">
                      {tc.nameNewColumn}:
                      <input
                        onChange={(e) => {
                          setNewAgileColumnName(e.target.value);
                        }}
                        ref={newAgileColumnNameInputRef}
                        type="text"
                      />
                    </div>
                    <div className="inputHolder__right">
                      <ActionButton
                        label={tc.cancel}
                        onClick={() => {
                          setShowAddAgileColumn(false);
                          if (newAgileColumnNameInputRef?.current) {
                            newAgileColumnNameInputRef.current.value = "";
                          }
                          setNewAgileColumnName(null);
                        }}
                        type="regular"
                      />
                      <ActionButton
                        label={tc.save}
                        onClick={_addAgileColumn}
                        type="highlight"
                      />
                    </div>
                  </div>
                ) : (
                  <div className="inputHolder">
                    <div className="inputHolder__left">
                      <div
                        className="linkWithIcon"
                        onClick={() => {
                          setShowAddAgileColumn(true);
                        }}
                      >
                        <Icon val="add" />
                        <span>{tc.addColumn}</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : null}
            <div className="settingsPageWrapper__settingsPage__section__content__holder">
              {removeAgileColumn.id &&
              typeof removeAgileColumn.title === "string" ? (
                <div className="inputHolder">
                  <div className="inputHolder__left">
                    {tc.removeColumn}:{" "}
                    <strong>{removeAgileColumn.title}</strong>
                  </div>
                  <div className="inputHolder__right">
                    <ActionButton
                      label={tc.cancel}
                      onClick={() => {
                        setRemoveAgileColumn({
                          id: null,
                          title: null,
                        });
                      }}
                      type="regular"
                    />
                    <ActionButton
                      label={tc.remove}
                      onClick={_removeAgileColumn}
                      type="highlight"
                    />
                  </div>
                </div>
              ) : agileColumnTitleToEdit.id &&
                typeof agileColumnTitleToEdit.title === "string" ? (
                <div key={agileColumnTitleToEdit.id} className="inputHolder">
                  <div className="inputHolder__left">
                    <input
                      onChange={(e) => {
                        setAgileColumnTitleToEdit({
                          ...agileColumnTitleToEdit,
                          title: e.target.value,
                        });
                      }}
                      ref={agileColumnTitleInputRef}
                      type="text"
                      value={agileColumnTitleToEdit.title}
                    />
                  </div>
                  <div className="inputHolder__right">
                    <ActionButton
                      label={tc.cancel}
                      onClick={() => {
                        setAgileColumnTitleToEdit({
                          id: null,
                          title: null,
                        });
                      }}
                      type="regular"
                    />
                    <ActionButton
                      label={tc.remove}
                      onClick={() => {
                        setRemoveAgileColumn({
                          id: agileColumnTitleToEdit.id,
                          title: agileColumnTitleToEdit.title,
                        });
                      }}
                      type="highlight"
                    />
                    <ActionButton
                      label={tc.save}
                      onClick={_saveNewAgileColumnTitle}
                      type="highlight"
                    />
                  </div>
                </div>
              ) : !showAddAgileColumn ? (
                <div className="toggleOptionsHolder">
                  {JSON.parse(JSON.stringify(state.agile.columns))
                    .sort((a, b) => {
                      if (a?.title && b?.title) {
                        return a.title > b.title ? 1 : -1;
                      } else {
                        return 0;
                      }
                    })
                    .map((column) => {
                      if (!column?.id) {
                        return null;
                      }

                      return (
                        <div
                          key={column.id}
                          className="toggleOptionsHolder__item"
                        >
                          <div className="toggleOptionsHolder__item__left">
                            {column.id !== "prospects" &&
                            column.id !== "idle" ? (
                              <div className="iconHolder">
                                <Icon
                                  val="edit"
                                  onClick={() => {
                                    setAgileColumnTitleToEdit({
                                      id: column.id,
                                      title: column.title,
                                    });
                                  }}
                                />
                              </div>
                            ) : null}
                          </div>
                          <div className="toggleOptionsHolder__item__right">
                            <ToggleOption
                              active={column.visible !== false}
                              label={column.title}
                              onClick={() => {
                                _toggleVisibility(column.id);
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <SettingsAgileActivityTypes />
      </div>
    </div>
  ) : (
    <Loading />
  );
};

const MapStateToProps = (state, props) => {
  return {
    agile: state.agile,
    props: props,
  };
};

export default connect(MapStateToProps)(SettingsAgile);
