import { pdfActionTypes } from "./actions";

interface PdfState {
  userPdfs: Array<object> | null;
}

const initialState: PdfState = {
  userPdfs: null,
};

export const pdfReducer = (state = initialState, action) => {
  switch (action.type) {
    case pdfActionTypes.PDF_SET_USER_PDFS: {
      return {
        ...state,
        userPdfs: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
