import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import LanguageContext from 'language-context';
import { getCar } from 'store/car/tasks';
import Activities from 'components/activities';
import CarInfo from './car_info';
import CarUser from './car_user';
import CarUserHistory from './car_user_history';
import CarVehicleData from './car_vehicle_data';
import Contacts from 'components/contacts';
import CreateDeal from 'components/create_deal';
import Loading from 'components/loading';
import Menu from 'components/menu';
import PageHeader from 'components/page_header';

const Car = (state) => {
  const { regNr } = useParams();
  const [showCreateDeal, setShowCreateDeal] = useState(false);
  const [vehicleName, setVehicleName] = useState('');
  const tc = useContext(LanguageContext);

  useEffect(() => {
    getCar({ regNr: regNr });
  }, [regNr]);

  useEffect(() => {
    if (state.car.car) {
      setVehicleName(
        state.car.car.brand +
          ' ' +
          state.car.car.model +
          '(' +
          state.car.car.reg_number +
          ')'
      );
    }
  }, [state.car.car]);

  const _stateCheck = () => {
    return !!(state.car?.car && state.car?.responsible);
  };

  return _stateCheck() ? (
    <div className="carWrapper">
      <div className="carWrapper__car">
        <div className="carWrapper__car__header">
          <div className="carWrapper__car__header__top">
            <PageHeader headline={vehicleName} />
          </div>
          <div className="carWrapper__car__header__bottom">
            <Menu
              items={[
                {
                  label: tc.createNewDeal,
                  onClick: () => {
                    setShowCreateDeal(true);
                  },
                  type: 'button',
                },
              ]}
            />
          </div>
        </div>
        <div className="carWrapper__car__content">
          <div className="carWrapper__car__content__item">
            <CarInfo
              deals={state.car.deals}
              responsible={state.car.responsible}
              {...state.car.car}
            />
          </div>
          <div className="carWrapper__car__content__item">
            <CarUser {...state.car.car} />
          </div>
          <div className="carWrapper__car__content__item">
            <CarUserHistory data={state.car.car.history} />
          </div>
          <div className="carWrapper__car__content__item">
            <CarVehicleData {...state.car.car} />
          </div>
          <div className="carWrapper__car__content__item">
            <Contacts
              companyId={state.car.car.user_id}
              entityId={regNr}
              entityName={vehicleName}
              entityType="car"
            />
          </div>
          <div className="carWrapper__car__content__item">
            <Activities target={regNr} targetType="vehicle" view="flow" />
          </div>
          {showCreateDeal ? (
            <CreateDeal
              cars={[{ id: regNr, name: vehicleName }]}
              close={async (dealCreated) => {
                // If deal is created we reload data.
                if (dealCreated) {
                  setShowCreateDeal(false);
                  return await getCar({ regNr: regNr });
                } else {
                  return setShowCreateDeal(false);
                }
              }}
              headline={
                tc.with +
                ' ' +
                tc.connection.toLowerCase() +
                ' ' +
                tc.to.toLowerCase() +
                ' ' +
                vehicleName
              }
              target={regNr}
            />
          ) : null}
        </div>
      </div>
    </div>
  ) : (
    <Loading />
  );
};

const MapStateToProps = (state) => {
  return {
    car: state.car,
  };
};

export default connect(MapStateToProps)(Car);
