import { activityHelper } from "helpers";
import { useContext, useEffect } from "react";
import { connect } from "react-redux";
import {
  getAgileActivityTypes,
  updateAgileActivityTypes,
} from "store/agile/tasks";
import ToggleOption from "components/toggle_option";
import LanguageContext from "language-context";

/**
 *
 * @param {{activeTypes: string[], inactiveTypes: string[]}} props
 * @description Component for displaying and changing which activity types
 *  you want to be available for selection when adding an activity.
 */
const SettingsAgileActivityTypes = ({
  activeTypes = [],
  inactiveTypes = [],
}) => {
  const tc = useContext(LanguageContext);

  useEffect(() => {
    async function getTypes() {
      const data = await getAgileActivityTypes();
      if (activityHelper.dataIsDefault(data)) {
        updateAgileActivityTypes({
          activityTypes: activityHelper.getDefaultActionTypes(),
        });
      }
    }
    getTypes();
  }, []);

  const toggleType = (type) => {
    let newTypes = {
      active: [],
      inactive: [],
    };
    if (activeTypes.includes(type)) {
      newTypes.active = activeTypes.filter((activeType) => activeType !== type);
      newTypes.inactive = inactiveTypes.concat(type);
    } else {
      newTypes.active = activeTypes.concat(type);
      newTypes.inactive = inactiveTypes.filter(
        (inactiveType) => inactiveType !== type
      );
    }
    updateAgileActivityTypes({ activityTypes: newTypes });
  };

  return (
    <div
      id="manageActivityTypes"
      className="settingsPageWrapper__settingsPage__section"
    >
      <div className="settingsPageWrapper__settingsPage__section__header">
        {tc.manageActivityTypes}
      </div>
      <div className="settingsPageWrapper__settingsPage__section__content">
        <p>{tc.manageActivityTypesDescription}</p>
      </div>
      <div className="settingsPageWrapper__settingsPage__section__content">
        <div className="toggleOptionsHolder">
          {activityHelper.getActionTypes(true).map((type) => {
            return (
              <div key={type} className="toggleOptionsHolder__item">
                <ToggleOption
                  active={activeTypes?.includes(type)}
                  label={activityHelper.getReadableActivity(type)}
                  onClick={() => toggleType(type)}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    activeTypes: state.agile?.activityTypes?.active,
    inactiveTypes: state.agile?.activityTypes?.inactive,
  };
};

export default connect(mapStateToProps)(SettingsAgileActivityTypes);
