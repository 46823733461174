import { store } from 'store';
import { request } from 'helpers';
import { companyActionTypes } from './actions';
import { showFlashMessage } from 'store/flash_messages/tasks';
import { event } from 'tracking';
import * as text from 'text-content';
import { miscHelper } from 'helpers';

export const getDeals = async (payload) => {
  try {
    if (!payload || (payload && !payload.id)) {
      throw new Error('Missing params in getDeals');
    }

    store.dispatch({
      type: companyActionTypes.SET_COMPANY_DEALS,
      payload: null,
    });

    const data = await request({
      method: 'GET',
      url: `/company/refactor/deals/${payload.id}`,
    });

    store.dispatch({
      type: companyActionTypes.SET_COMPANY_DEALS,
      payload: data,
    });

    return data;
  } catch (err) {
    console.error('Error in deals: ', err);
  }
};

export const getBasicCompanyInfo = async (payload) => {
  try {
    if (!payload || (payload && !payload.id)) {
      throw new Error('Missing params in getBasicCompanyInfo');
    }

    store.dispatch({
      type: companyActionTypes.SET_COMPANY_INFO,
      payload: null,
    });

    const data = await request({
      method: 'GET',
      url: `/company/refactor/basic-info/${payload.id}`,
    });

    store.dispatch({
      type: companyActionTypes.SET_COMPANY_INFO,
      payload: data,
    });

    return data;
  } catch (err) {
    console.error('Error in getBasicCompanyInfo: ', err);
  }
};

export const getBasicKoncernInfo = async (payload) => {
  try {
    if (!payload || (payload && !payload.id)) {
      throw new Error('Missing params in getBasicKoncernInfo');
    }

    store.dispatch({
      type: companyActionTypes.SET_COMPANY_KONCERN,
      payload: null,
    });

    const data = await request({
      method: 'GET',
      url: `/company/refactor/koncern-info/${payload.id}`,
    });

    store.dispatch({
      type: companyActionTypes.SET_COMPANY_KONCERN,
      payload: data,
    });

    return data;
  } catch (err) {
    console.error('Error in getBasicKoncernInfo: ', err);
  }
};

export const getBasicContactInfo = async (payload) => {
  try {
    if (!payload || (payload && !payload.id)) {
      throw new Error('Missing params in getBasicContactInfo');
    }

    store.dispatch({
      type: companyActionTypes.SET_COMPANY_EMAILS,
      payload: null,
    });

    store.dispatch({
      type: companyActionTypes.SET_COMPANY_PHONENUMBERS,
      payload: null,
    });

    const data = await request({
      method: 'GET',
      url: `/company/refactor/contact-info/${payload.id}`,
    });

    store.dispatch({
      type: companyActionTypes.SET_COMPANY_EMAILS,
      payload: data.emails,
    });

    store.dispatch({
      type: companyActionTypes.SET_COMPANY_PHONENUMBERS,
      payload: data.phoneNumbers,
    });

    return data;
  } catch (err) {
    console.error('Error in getBasicContactInfo: ', err);
  }
};

export const getResponsible = async (payload) => {
  try {
    if (!payload || (payload && !payload.id)) {
      throw new Error('Missing params in getResponsible');
    }

    store.dispatch({
      type: companyActionTypes.SET_COMPANY_RESPONSIBLE,
      payload: null,
    });

    const data = await request({
      method: 'get',
      url: '/responsibility/' + payload.id,
    });

    store.dispatch({
      type: companyActionTypes.SET_COMPANY_RESPONSIBLE,
      payload: data,
    });

    return data;
  } catch (err) {
    console.error('Error in getResponsible: ', err);
  }
};

/**
 * Deprecated?
 *
 * Retrieve company information.
 *
 * @param payload.id
 */
// export const getCompany = async (payload) => {
//   try {
//     if (!payload || (payload && !payload.id)) {
//       return console.error("Missing params in getCompany", payload);
//     }

//     store.dispatch({ type: companyActionTypes.SET_COMPANY, payload: {} });
//     store.dispatch({
//       type: companyActionTypes.SET_COMPANY_RESPONSIBLE,
//       payload: null,
//     });

//     const company = [
//       await request({
//         method: "get",
//         url: "/company/" + payload.id,
//       }),
//     ];

//     const responsible = [
//       await request({
//         method: "get",
//         url: "/responsibility/" + payload.id,
//       }),
//     ];

//     const data = await Promise.all(company.concat(responsible));

//     if (!data || data instanceof Error) {
//       console.error("No data in getCompany", data);
//       store.dispatch({ type: companyActionTypes.SET_COMPANY, payload: {} });
//       return store.dispatch({
//         type: companyActionTypes.SET_COMPANY_RESPONSIBLE,
//         payload: null,
//       });
//     }

//     // Sort deals on name.
//     if (data[0] && data[0].deals) {
//       data[0].deals = data[0].deals.sort((a: any, b: any) => {
//         if (!a.name || a.name.length === 0 || !b.name) {
//           return -1;
//         } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
//           return -1;
//         } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
//           return 1;
//         } else {
//           return 0;
//         }
//       });
//     }

//     store.dispatch({ type: companyActionTypes.SET_COMPANY, payload: data[0] });
//     return store.dispatch({
//       type: companyActionTypes.SET_COMPANY_RESPONSIBLE,
//       payload: data[1],
//     });
//   } catch (err) {
//     return console.error("Error in getCompany", err);
//   }
// };

/**
 * Get data for koncern view.
 *
 * @param payload.id
 */
// export const getKoncern = async (payload) => {
//   try {
//     if (!payload || (payload && !payload.id)) {
//       return console.error("Missing params in getKoncern", payload);
//     }

//     console.log('this runs???')
//     store.dispatch({ type: companyActionTypes.SET_COMPANY, payload: {} });
//     store.dispatch({
//       type: companyActionTypes.SET_COMPANY_RESPONSIBLE,
//       payload: null,
//     });

//     const company = [
//       await request({
//         method: "get",
//         url: "/company/" + payload.id,
//       }),
//     ];

//     const koncern = [
//       await request({
//         method: "get",
//         url: "/api/koncern/" + payload.id,
//       }),
//     ];

//     const data = await Promise.all(company.concat(koncern));

//     if (!data || data instanceof Error) {
//       console.error("No data in getKoncern", data);
//       store.dispatch({
//         type: companyActionTypes.SET_COMPANY,
//         payload: {},
//       });
//       return store.dispatch({
//         type: companyActionTypes.SET_COMPANY_RESPONSIBLE,
//         payload: null,
//       });
//     }

//     if (data[0].koncern && typeof data[0].koncern === "object") {
//       data[0].koncern = Object.assign(data[0].koncern, data[1]);
//     }

//     // Sort deals on name.
//     if (data[0] && data[0].deals) {
//       data[0].deals = data[0].deals.sort((a: any, b: any) => {
//         if (!a.name || a.name.length === 0 || !b.name) {
//           return -1;
//         } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
//           return -1;
//         } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
//           return 1;
//         } else {
//           return 0;
//         }
//       });
//     }

//     let responsible;
//     if (data[0].koncern?.mother?.id) {
//       // Get responsible for koncern org nr.
//       responsible = await request({
//         method: "get",
//         url: "/responsibility/" + data[0].koncern.mother.id,
//       });
//     }

//     store.dispatch({
//       type: companyActionTypes.SET_COMPANY_RESPONSIBLE,
//       payload: responsible,
//     });
//     store.dispatch({ type: companyActionTypes.SET_COMPANY, payload: data[0] });
//   } catch (err) {
//     return console.error("Error in getKoncern", err);
//   }
// };

/**
 * Set responsible user for company.
 *
 * @param payload.entityId
 * @param payload.responsibleUserId
 */
export const setResponsibility = async (payload) => {
  try {
    if (!payload?.entityId || !payload?.responsibleUserId) {
      return console.error('Missing params in setResponsibility', payload);
    }

    event({
      category: 'company',
      action: 'company_set_responsibility',
    });

    const data = await request({
      data: {
        entityId: payload.entityId.toString(),
        responsibleUserId: Number(payload.responsibleUserId),
      },
      method: 'post',
      url: '/responsibility/',
    });

    if (data instanceof Error) {
      return console.error('Could not change responsibility', data);
    }

    return store.dispatch({
      type: companyActionTypes.SET_COMPANY_RESPONSIBLE,
      payload: data,
    });
  } catch (err) {
    return console.error('Error in setResponsibility', err);
  }
};

/**
 * Add, edit or delete values in a company's phone or email data.
 *
 * (We only change the values that have an id.
 * I.E. we only change/add values from db table 'dealer_custom_data', which is data the users themselves have put in via input fields.
 * And values from db table 'dealer_data_customer, which is values from data integration.
 * We never change values from db table 'company_data', and the data from this table does not include id when sent from backend.)
 *
 * @param payload.action - string - 'add' | 'delete' | 'edit'.
 * @param payload.id - number - Not needed when action is 'add'.
 * @param payload.prospectId - number - Org nr.
 * @param payload.type - string - 'email' | 'phone'.
 * @param payload.value - string - The value.
 */
export const updateCompanyInformation = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    // Check that params is correct.
    if (
      !payload ||
      (payload.type !== 'email' && payload.type !== 'phone') ||
      !payload.action ||
      !payload.prospectId ||
      !payload.hasOwnProperty('value')
    ) {
      return console.error(
        'Missing params in updateCompanyInformation',
        payload
      );
    }
    if (
      (payload.action === 'delete' || payload.action === 'edit') &&
      !payload.id
    ) {
      return console.error(
        'Missing params in updateCompanyInformation',
        payload
      );
    }

    event({
      category: 'company',
      action: 'company_update_information',
    });

    let existingData =
      payload.type === 'email'
        ? store.getState().company.company.emails
        : store.getState().company.company.phoneNumbers;
    if (!Array.isArray(existingData)) {
      existingData = [];
    }

    // If value already exists, abort.
    if (
      payload.action !== 'delete' &&
      existingData &&
      existingData.length &&
      existingData.find((num) => num.value === payload.value)
    ) {
      return showFlashMessage(tc.valueAlreadyExists, 'info');
    }

    const data = await request({
      data: payload,
      method: 'post',
      url: '/information/',
    });

    if (!data || data instanceof Error) {
      return console.error('Could not update company data', data);
    }

    // Adjust data in store state directly to prevent new backend call.
    if (payload.action === 'add') {
      existingData.push({
        id: data[0],
        value: payload.value,
      });
    }
    if (payload.action === 'edit') {
      existingData.map((num) => {
        if (num.id === payload.id) {
          num.value = payload.value;
        }
        return num;
      });
    }
    if (payload.action === 'delete') {
      existingData = existingData.filter((num) => num.id !== payload.id);
    }

    showFlashMessage(tc.changesSaved, 'success');

    if (payload.type === 'email') {
      store.dispatch({
        type: companyActionTypes.SET_COMPANY_EMAILS,
        payload: existingData,
      });
    } else if (payload.type === 'phone') {
      store.dispatch({
        type: companyActionTypes.SET_COMPANY_PHONENUMBERS,
        payload: existingData,
      });
    }
  } catch (err) {
    return console.error('Error in updateCompanyInformation', err);
  }
};

export const getCompanyInLists = async (payload) => {
  try {
    if (!payload || (payload && !payload.id)) {
      throw new Error('Missing params in getBasicCompanyInfo');
    }

    store.dispatch({
      type: companyActionTypes.SET_COMPANY_IN_LISTS,
      payload: null,
    });

    const data = await request({
      method: 'GET',
      url: `/company/company-in-lists/${payload.id}`,
    });

    store.dispatch({
      type: companyActionTypes.SET_COMPANY_IN_LISTS,
      payload: data,
    });
  } catch (err) {
    console.error('Error in getCompanyInLists: ', err);
  }
};

export const getCompanyContracts = async (payload) => {
  try {
    if (!payload || !payload.ids) {
      throw new Error('Missing params in getCompanyContracts');
    }

    const data = await request({
      method: 'POST',
      url: `/company/contracts`,
      data: {
        companyIds: payload.ids,
      },
    });

    const current = store.getState().company.contracts;

    let foundIds = {};

    const allContracts = data?.contracts?.reduce((acc, contract) => {
      foundIds[contract.prospect_id] = foundIds[contract.prospect_id] ?? {};
      foundIds[contract.prospect_id][contract.contract_customer_id] = true;
      if (acc[contract.prospect_id]) {
        const currentItem = acc[contract.prospect_id] ?? [];
        return {
          ...acc,
          [contract.prospect_id]: !currentItem.some(
            (c) => c.contract_customer_id === contract.contract_customer_id
          )
            ? [...currentItem, contract]
            : [...currentItem],
        };
      }
      return { ...acc, [contract.prospect_id]: [contract] };
    }, current);

    store.dispatch({
      type: companyActionTypes.SET_COMPANY_CONTRACTS,
      payload: allContracts,
    });
  } catch (e) {
    console.error('Error in getCompanyContracts: ', e);
  }
};

export const clearCompanyContracts = async () => {
  store.dispatch({
    type: companyActionTypes.SET_COMPANY_CONTRACTS,
    payload: {},
  });
};
/**
 *
 * @param payload
 * @returns dealers - from company_new_test table
 * we used to have a static table from dealers_list sql
 */
const getCompanyDealers = async (payload) => {
  try {
    if (!payload || !payload.q) {
      throw new Error('error in getCompanyDealers');
    }
    const response = await request({
      method: 'get',
      url: `/company/getCompanyDealers`,
      data: {
        query: payload.q,
        limit: 20,
      },
    });

    const dealers = response.dealers || [];

    const dealersWithExtraFields = dealers.map((dealer) => ({
      ...dealer,
      active: true,
      type: 'value',
    }));

    return dealersWithExtraFields;
  } catch (error) {
    console.error('Error fetching company dealers:', error);
    throw error;
  }
};

export const debouncedCompanyDealers = miscHelper.debounce(
  getCompanyDealers,
  700
);
