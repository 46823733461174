export const excelActionTypes = {
  SET_EXCEL_DOWNLOAD_URL: "SET_EXCEL_DOWNLOAD_URL",
  SET_EXCEL_ROWS_ORDERED_MANAGEMENT: "SET_EXCEL_ROWS_ORDERED_MANAGEMENT",
  SET_EXCEL_ROWS_ORDERED_NAME: "SET_EXCEL_ROWS_ORDERED_NAME",
  SET_EXCEL_ROWPRICE_PROSPECT: "SET_EXCEL_ROWPRICE_PROSPECT",
  SET_EXCEL_ROWPRICE_VEHICLE: "SET_EXCEL_ROWPRICE_VEHICLE",
  SET_EXCEL_ROWS_PROSPECTS: "SET_EXCEL_ROWS_PROSPECTS",
  SET_EXCEL_ROWS_VEHICLES: "SET_EXCEL_ROWS_VEHICLES",
  SET_EXCEL_TOTALCOST: "SET_EXCEL_TOTALCOST",
};
