import { Responsive } from "react-grid-layout";
import { connect } from "react-redux";
import Item from "./item";
import { setShowSettings } from "store/user/tasks";
import Loading from "components/loading";
import { chartHelper } from "helpers";
import { withSize } from "react-sizeme";
import "react-resizable/css/styles.css";
import { widgetHelper } from "helpers/widget_helper";

/**
 * breakpoints set where we change from lg, md, sm, xs.
 * Fun fact! you can get the current breakpoint with Responsive.utils.getBreakpointFromWidth(breakpoints, width)
 */
const breakpoints = widgetHelper.breakpoints;
/**
 * the number of columns in the grid by breakpoints.
 */
const cols = widgetHelper.cols;

/**
 * We need to wrap this component with an HOC so that we can pass it the size of the container.
 * @param {props} props
 * @param {object} widgets
 * @param {string} configuration
 * @param {{width: number, height: null}} size
 * @returns
 */
const GridViewNoWidth = ({
  widgets,
  gridViews,
  configuration,
  size,
  dealerId,
  handleLayoutsChange,
  activeChildren,
  activeLayouts,
}) => {
  const width = size.width;
  const cancelDraggableClasses = [
    ".map",
    ".dropdownWrapper__dropdown__content",
    ".popupWrapper",
    ".widgetHeaderWrapper__widgetHeader__right",
    ".MuiTooltip-popper",
    ".cancelDrag",
  ];

  return (
    <div>
      {/* {dealerId === 5141 && (
        <div
          style={{
            color: "blue",
            padding: "1em",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <span style={{ fontVariant: "all-small-caps" }}>debug info</span>
            <br />
            <span>
              active widgets:{" "}
              {activeChildren?.length ? activeChildren.length : "none"}
            </span>
            <br />
            <span>configuration (the prop): {configuration}</span>
            <br />
            <span>
              view width: {width} breakpoint:
              {Responsive.utils.getBreakpointFromWidth(breakpoints, width)}
            </span>
          </div>
        </div>
      )} */}

      {Array.isArray(widgets) && Array.isArray(gridViews) ? (
        activeChildren?.length > 0 ? (
          <Responsive
            className="layout"
            layouts={activeLayouts}
            breakpoints={breakpoints}
            cols={cols}
            rowHeight={20}
            resizeHandles={["se"]}
            onLayoutChange={(layout, layouts) => {
              handleLayoutsChange(layout, layouts);
            }}
            //onWidthChange={(width) => setWidth(width)}
            // onBreakpointChange= {(breakpoint) => console.log(`breakpoint: ${breakpoint}`)}
            draggableCancel={cancelDraggableClasses.join(",")} // if you need more elements to be clickable without activating the dragging effect, add them to cancelDraggableClasses array.
            children={activeChildren.map((widget) => {
              if (!widgetHelper.widgetTypes.includes(widget.type)) return null;
              return (
                <Item
                  type={widget.type}
                  name={widget.name}
                  data={widget.data}
                  _id={widget._id}
                  key={widget._id}
                  widget={widget.type === "notifications" ? true : null} // needed because the events component expects to be passed a {widget} prop
                  // needed in activities component
                  widgets={widgets.filter(
                    (num) =>
                      num?.dashboards?.hasOwnProperty(configuration) &&
                      num.dashboards[configuration].active
                  )}
                  // .sort((a, b) =>
                  //   a.dashboards.dashboard.position >
                  //   b.dashboards.dashboard.position
                  //     ? 1
                  //     : -1
                  // )}
                />
              );
            })}
            width={width}
          />
        ) : (
          <div style={{ padding: "1em" }}>
            You've not enabled any widgets for this view.{" "}
            <span
              onClick={() => setShowSettings(true)}
              style={{ cursor: "pointer", textDecoration: "underline" }}
            >
              Do it!
            </span>
          </div>
        )
      ) : (
        <Loading />
      )}
    </div>
  );
};

const MapStateToProps = (state, props) => {
  return {
    dealerId: state.user.info.dealer_id,
    widgets: state.widgets?.data?.widgets,
    gridViews: state.widgets?.data?.gridViews,
    configuration: props.configuration,
    handleLayoutsChange: props.handleLayoutsChange,
    activeLayouts: props.activeLayouts,
    activeChildren: props.activeChildren,
  };
};

const GridView = withSize({ monitorWidth: true })(GridViewNoWidth);

export default connect(MapStateToProps)(GridView);
