import React, { useContext } from 'react';
import LanguageContext from 'language-context';
import WidgetHeader from 'components/widget_header';

const ActivityDealsHeader = ({
  minimize,
  setMinimize,
  showSaveToDashboard,
  setShowAddToDashboard,
  widget,
}) => {
  const tc = useContext(LanguageContext);

  return (
    <div className="activitiesWrapper__activities__header">
      <WidgetHeader
        dashboardItems={[
          {
            disabled: !minimize,
            icon: 'maximize',
            hideWhenResponsive: true,
            label: tc.maximize,
            onClick: () => setMinimize(false),
          },
          !widget && !showSaveToDashboard
            ? {
                disabled: !(!widget && !showSaveToDashboard) || minimize,
                icon: 'dashboard',
                label: tc.addToDashboard,
                onClick: () => setShowAddToDashboard(true),
              }
            : null,
          {
            disabled: minimize,
            icon: 'minimize',
            hideWhenResponsive: true,
            label: tc.minimize,
            onClick: () => setMinimize(true),
          },
        ]}
        headline={widget?.name ? widget.name : tc.processingRateHot}
        headlineSub={tc.activityDeals}
      />
    </div>
  );
};

export default ActivityDealsHeader;
