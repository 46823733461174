import { activitiesActionTypes } from "./actions";

/*
Reducer for activities that is handled in Activities component.
Separate this from activity reducer, which handles the activity page.
 */

interface ActivitiesState {
  activitiesByFilter: Array<object> | null;
  activitiesByTarget: Array<object> | null;
  previousSearch: object | null;
  activitiesSubscriptions: object;
  subscriptionsLoading: Array<string>;
}

const initialState: ActivitiesState = {
  activitiesByFilter: null,
  activitiesByTarget: null,
  previousSearch: null,
  activitiesSubscriptions: {},
  subscriptionsLoading: [], // Subscription ids that is currently loading data.
};

export const activitiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case activitiesActionTypes.ACTIVITIES_SET_ACTIVITIES_BY_FILTER: {
      return {
        ...state,
        activitiesByFilter: action.payload,
      };
    }
    case activitiesActionTypes.ACTIVITIES_SET_ACTIVITIES_BY_TARGET: {
      return {
        ...state,
        activitiesByTarget: action.payload,
      };
    }
    case activitiesActionTypes.ACTIVITIES_SET_ACTIVITIES_SUBSCRIPTION: {
      return {
        ...state,
        activitiesSubscriptions: {
          ...state.activitiesSubscriptions,
          [action.payload.id]: action.payload.data,
        },
      };
    }
    case activitiesActionTypes.ACTIVITIES_SET_SUBSCRIPTION_LOADING: {
      const newArr =
        action.payload.type === "remove"
          ? state.subscriptionsLoading.filter(
              (num) => num !== action.payload.id
            )
          : state.subscriptionsLoading.concat(action.payload.id);

      return {
        ...state,
        subscriptionsLoading: newArr,
      };
    }
    case activitiesActionTypes.ACTIVITIES_SET_PREVIOUS_SEARCH: {
      return {
        ...state,
        previousSearch: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
