export const agileActionTypes = {
  SET_ACTIVITY_TYPES: "SET_ACTIVITY_TYPES",
  SET_ALL_PROSPECTS_RECEIVED: "SET_ALL_PROSPECTS_RECEIVED",
  SET_COLUMNS: "SET_COLUMNS",
  SET_FILTER: "SET_FILTER",
  SET_LOADING: "SET_LOADING",
  SET_PREVIEW_DATA: "SET_PREVIEW_DATA",
  SET_PREVIEW_ID: "SET_PREVIEW_ID",
  SET_SORT: "SET_SORT",
};
