import { useContext, useEffect, useState } from "react";
import ActionButton from "components/action_button";
import { activityHelper } from "helpers";
import { getDealTypeById, updateDealType } from "store/agile/tasks";
import LanguageContext from "language-context";
import Icon from "components/icon";
import Loading from "components/loading";
import { Dropdown, DropdownItem } from "../../dropdown";
/**
 *
 * @param {{dealId: string; hideBlocks: string[]; setHideBlocks: () => {}}} props
 * @returns JSX.Component
 */
const AgileBoardPreviewDealType = ({ dealId, hideBlocks, setHideBlocks }) => {
  const [previewItemDealType, setPreviewItemDealType] = useState(null);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    async function getDealType() {
      const dealType = await getDealTypeById(dealId);
      setPreviewItemDealType(dealType);
    }
    if (dealId) getDealType();
  }, [dealId]);

  return (
    <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block">
      <div
        className="agileBoardPreviewWrapper__agileBoardPreview__content__block__header"
        onClick={() => {
          if (hideBlocks.includes("dealType")) {
            setHideBlocks(hideBlocks.filter((num) => num !== "dealType"));
          } else {
            setHideBlocks(hideBlocks.concat("dealType"));
          }
        }}
      >
        <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__header__title">
          <p>{tc.typeOfDeal}</p>

          {hideBlocks.includes("dealType") ? (
            <Icon val="maximize" />
          ) : (
            <Icon val="minimize" />
          )}
        </div>
      </div>
      {hideBlocks.includes("dealType") ? null : (
        <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content">
          <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__dealType">
            {previewItemDealType !== null ? (
              <Dropdown
                closeOnItemClick={true}
                displayValue={tc[previewItemDealType]}
                children={activityHelper.getDealTypes().map((dealType) => {
                  return (
                    <DropdownItem
                      key={dealType}
                      hideCheckbox={true}
                      active={previewItemDealType === dealType}
                      label={tc[dealType]}
                      onClick={async () => {
                        await updateDealType(dealId, dealType);
                        setPreviewItemDealType(dealType);
                      }}
                    />
                  );
                })}
              />
            ) : (
              <Loading />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AgileBoardPreviewDealType;
