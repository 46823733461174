import { useContext, useEffect, useState } from 'react';
import { activityHelper } from 'helpers';
import { getDealTypeById, updateDealType } from 'store/agile/tasks';
import LanguageContext from 'language-context';
import Loading from 'components/loading';
import { Dropdown, DropdownItem, NewDropdown } from 'components/dropdown';
/**
 *
 * @param {{dealId: string; hideBlocks: string[]; setHideBlocks: () => {}}} props
 * @returns JSX.Component
 */
const AgileBoardPreviewDealType = ({ dealId }) => {
  const [previewItemDealType, setPreviewItemDealType] = useState(null);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    async function getDealType() {
      const dealType = await getDealTypeById(dealId);
      setPreviewItemDealType(dealType);
    }
    if (dealId) getDealType();
  }, [dealId]);

  return (
    <div className="agileBoardPopupWrapper__agilePopup__content__block__dropdown">
      <div className="agileBoardPopupWrapper__agilePopup__content__block__header__custom"></div>

      <div className="agileBoardPopupWrapper__agilePopup__content__block__content__dropdown">
        <div className="agileBoardPopupWrapper__agilePopup__content__block__content__dealType">
          {previewItemDealType !== null ? (
            <NewDropdown
              header={{ label: tc[previewItemDealType] }}
              align={'bottom-left'}
            >
              {activityHelper.getDealTypes().map((dealType) => {
                return (
                  <DropdownItem
                    key={dealType}
                    active={previewItemDealType === dealType}
                    label={tc[dealType]}
                    onClick={async () => {
                      await updateDealType(dealId, dealType);
                      setPreviewItemDealType(dealType);
                    }}
                  />
                );
              })}
            </NewDropdown>
          ) : (
            <Loading />
          )}
        </div>
      </div>
    </div>
  );
};

export default AgileBoardPreviewDealType;
