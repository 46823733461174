import React from "react";
import HomeContact from "./home_contact";
import HomeFlow from "./home_flow";
import HomeHeader from "./home_header";
import HomeInfo from "./home_info";
import HomeReviews from "./home_reviews";
import HomeTeam from "./home_team";

const Home = () => {
  return (
    <div className="homeWrapper">
      <div className="homeWrapper__home">
        <header
          className="homeWrapper__home__header"
          id="home-page-section-header"
        >
          <HomeHeader />
        </header>
        <main className="homeWrapper__home__content">
          <div className="homeWrapper__home__content__sections">
            <div
              className="homeWrapper__home__content__sections__section"
              id="home-page-section-info"
            >
              <HomeInfo />
            </div>
            <div
              className="homeWrapper__home__content__sections__section"
              id="home-page-section-flow"
            >
              <HomeFlow />
            </div>
            <div
              className="homeWrapper__home__content__sections__section"
              id="home-page-section-reviews"
            >
              <HomeReviews />
            </div>
            <div
              className="homeWrapper__home__content__sections__section"
              id="home-page-section-team"
            >
              <HomeTeam />
            </div>
            <div
              className="homeWrapper__home__content__sections__section"
              id="home-page-section-contact"
            >
              <HomeContact />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Home;
