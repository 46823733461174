/**
 * @type {object} - Used to controll behavior of useSummary hook
 */
export const summarySettings = {
  /**
   * @property {string} interval What timespan the summary will be between "year", "month" or "week"
   * @property {string} interval What timespan the summary will be between "year", "month" or "week"
   */
  interval: "year",
  timing: "weekly",
  modifier: 0,
};
