import React, { useContext, useEffect, useRef, useState } from "react";
import LanguageContext from "language-context";
import { miscHelper } from "helpers";
import EventsCalendarDay from "./events_calendar_day";
import EventsCalendarDayEmpty from "./events_calendar_day_empty";
import Icon from "components/icon";

/**
 * Render a calendar that holds planned events.
 *
 * @param props.activityTypesToDisplay
 * @param props.data
 * @param props.stepBack
 * @param props.stepForward
 */
const EventsCalendar = (props) => {
  const [responsiveClassWidth, setResponsiveClassWidth] = useState("");
  const eventsCalendarRef = useRef(null);
  const observer = useRef(null);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    _setResponsiveClass();
    observer.current = new ResizeObserver(_setResponsiveClass);
    observer.current.observe(eventsCalendarRef.current);

    const ref = eventsCalendarRef.current;

    return () => observer.current.unobserve(ref);
  }, []);

  const _renderDays = () => {
    const days = [];

    // Add weekday names.
    miscHelper.calendarWeekdays().forEach((num, i) => {
      days.push(
        <React.Fragment key={num}>
          <EventsCalendarDayEmpty text={num} weekday={true} />
        </React.Fragment>
      );
    });

    if (!Object.keys(props.data.month).length) {
      // Should never happen. Will never happen.
      return;
    }

    // First, push empty days to calendar so weekdays get in right columns.
    const firstDay = props.data.month[Object.keys(props.data.month)[0]];
    let emptyDays = 0;
    switch (firstDay.date.weekday) {
      case tc.tuesday.toLowerCase():
        emptyDays = 1;
        break;
      case tc.wednesday.toLowerCase():
        emptyDays = 2;
        break;
      case tc.thursday.toLowerCase():
        emptyDays = 3;
        break;
      case tc.friday.toLowerCase():
        emptyDays = 4;
        break;
      case tc.saturday.toLowerCase():
        emptyDays = 5;
        break;
      case tc.sunday.toLowerCase():
        emptyDays = 6;
        break;
      default:
        emptyDays = 0;
    }
    // Add empty boxes to adjust placement of first day.
    for (let index = 0; index < emptyDays; index++) {
      days.push(
        <React.Fragment key={index + 100}>
          {<EventsCalendarDayEmpty />}
        </React.Fragment>
      );
    }

    const dateObj = new Date();
    const month = dateObj.getUTCMonth() + 1;
    let day = dateObj.getUTCDate();
    if (day.toString().length === 1) {
      day = "0" + day;
    }
    const year = dateObj.getUTCFullYear();
    const today = `${year}/${month}/${day}`;

    // Second, push real days. Also add position for hover box.
    let i = 1 + emptyDays;
    for (const day in props.data.month) {
      days.push(
        <React.Fragment key={i}>
          {
            <EventsCalendarDay
              date={props.data.month[day].date}
              events={props.data.month[day].events}
              day={day}
              key={day}
              today={day === today}
            />
          }
        </React.Fragment>
      );
      i++;
    }

    return days;
  };

  const _setResponsiveClass = () => {
    if (!eventsCalendarRef?.current) {
      return;
    }

    const width = eventsCalendarRef.current.getBoundingClientRect().width;
    const breakpoint1 = 400;
    const breakpoint2 = 314;
    const breakpoint3 = 280;

    if (width <= breakpoint3) {
      setResponsiveClassWidth("eventsCalendarResponsiveWidth3");
    } else if (width <= breakpoint2 && width > breakpoint3) {
      setResponsiveClassWidth("eventsCalendarResponsiveWidth2");
    } else if (width <= breakpoint1 && width > breakpoint2) {
      setResponsiveClassWidth("eventsCalendarResponsiveWidth1");
    } else if (width > breakpoint1) {
      setResponsiveClassWidth("");
    }
  };

  const _stateCheck = () => {
    return !!props.data?.month;
  };

  return _stateCheck() ? (
    <div
      className={`eventsCalendarWrapper ${responsiveClassWidth}`}
      ref={eventsCalendarRef}
    >
      <div className="eventsCalendarWrapper__eventsCalendar">
        <div className="eventsCalendarWrapper__eventsCalendar__content">
          <div className="eventsCalendarWrapper__eventsCalendar__content__title">
            <Icon
              class="cancelDrag"
              val="navigatePrevious"
              onClick={props.stepBack}
            />
            {miscHelper.calendarMonths()[props.data.monthInScope] +
              " " +
              props.data.yearInScope}
            <Icon
              class="cancelDrag"
              val="navigateNext"
              onClick={props.stepForward}
            />
          </div>
          <div className="eventsCalendarWrapper__eventsCalendar__content__days cancelDrag">
            {_renderDays()}
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default EventsCalendar;
