import Icon from 'components/icon';

const FoldableSection = ({
  toggleSection = () => {},
  label,
  active,
  children,
  renderNothing = false,
}) => {
  if (renderNothing) return null;
  return (
    <div className={`foldable-section${active ? ' active' : ''}`}>
      <div
        className="agileBoardPopupWrapper__agilePopup__content__block__header"
        onClick={toggleSection}
      >
        <div className="agileBoardPopupWrapper__agilePopup__content__block__header__title">
          <p>{label}</p>
          {!active ? (
            <Icon
              val="maximize"
              class="agileBoardPopupWrapper__agilePopup__content__block__header__icon"
            />
          ) : (
            <Icon
              val="minimize"
              class="agileBoardPopupWrapper__agilePopup__content__block__header__icon"
            />
          )}
        </div>
      </div>
      {active ? children : null}
    </div>
  );
};

export default FoldableSection;
