import { newsActionTypes } from './actions';
import { showFlashMessage } from 'store/flash_messages/tasks';
import * as text from 'text-content';
import { store } from 'store';
import { request } from 'helpers';
import { event } from 'tracking';

/**
 * Get news.
 */
export const getNews = async () => {
  try {
    let items = await request({
      data: {
        limit: 100, // Endpoint offers limit possibility, but note that news page use filters and these shouldn't interfere with each other.
      },
      method: 'get',
      url: '/news',
    });

    if (items instanceof Error) {
      console.error('Error in getNews', items);
      return store.dispatch({
        type: newsActionTypes.NEWS_SET_ITEMS,
        payload: [],
      });
    }

    return store.dispatch({
      type: newsActionTypes.NEWS_SET_ITEMS,
      payload: items,
    });
  } catch (err) {
    console.error('Error in getNews', err);
  }
};

/**
 * Mark a news item as read for logged in user.
 *
 * @param id - string - Id of news item.
 */
export const markAsRead = async (id) => {
  try {
    if (!id) {
      return console.error('Missing params in markAsRead');
    } else {
      event({
        category: 'news',
        action: 'news_read',
      });

      const data = await request({
        data: {
          id: id,
        },
        method: 'post',
        url: '/news/markAsRead',
      });

      if (data instanceof Error) {
        return console.error('Error in markAsRead', data);
      }
    }
  } catch (err) {
    console.error('Error in markAsRead', err);
  }
};

/**
 * Remove a news item.
 *
 * @param id
 */
export const removeNewsItem = async (id) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (!id) {
      return console.error('Missing params in removeNewsItem', id);
    }

    const data = await request({
      data: {
        id: id,
      },
      method: 'delete',
      url: '/news',
    });

    if (data instanceof Error) {
      console.error('Error in removeNewsItem', data);
      return showFlashMessage(tc.genericFailMessage, 'fail');
    }

    showFlashMessage(tc.newsItemRemoved, 'success');
    return getNews();
  } catch (err) {
    console.error('Error in removeNewsItem', err);
    showFlashMessage(tc.genericFailMessage, 'fail');
  }
};

/**
 * Save new or update existing news item.
 *
 * @param payload
 */
export const saveNewsItem = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (payload.published?.length) {
      event({
        category: 'news',
        action: 'news_publish',
      });
    }

    const data = await request({
      data: payload,
      method: 'post',
      skipStripHtml: true, // Important
      url: '/news',
    });

    if (data instanceof Error) {
      console.error('Error in saveNewsItem', data);
      return showFlashMessage(tc.genericFailMessage, 'fail');
    }

    showFlashMessage(tc.newsItemSaved, 'success');
    return getNews();
  } catch (err) {
    console.error('Error in saveNewsItem', err);
    showFlashMessage(tc.genericFailMessage, 'fail');
  }
};

/**
 * Set id of news item to view.
 *
 * @param item
 */
export const setItemToDisplay = (item) => {
  return store.dispatch({
    type: newsActionTypes.NEWS_SET_DISPLAY_ITEM,
    payload: item,
  });
};

/**
 * Set id of news item to edit.
 *
 * @param id
 */
export const setEditId = (id) => {
  return store.dispatch({
    type: newsActionTypes.NEWS_SET_EDIT_ID,
    payload: id,
  });
};

export const uploadNewsImage = async (file: FormData) => {
  if (!file) return console.error('No file found: ', file);

  const data = await fetch('/news/uploadImage', {
    method: 'POST',
    body: file,
  });

  const json = await data.json();

  if (!json || !json.location || json instanceof Error) {
    return { location: '' };
  }

  return json.location;
};
export const getUnreadNews = async () => {
  try {
    let items = await request({
      data: {
        limit: 100, // Endpoint offers limit possibility, but note that news page use filters and these shouldn't interfere with each other.
        filterRead: true,
        notificationsCleared: false,
      },
      method: 'get',
      url: '/news',
    });
    return items;
  } catch (err) {
    console.error(err);
  }
};

export const clearNotification = async (id) => {
  try {
    if (!id) {
      return console.error('Missing params in clearNotification');
    }

    const data = {
      id: id,
      limit: 100,
    };

    const response = await request({
      data: data, // Use the 'data' property for parameters
      method: 'post',
      url: '/news/clearNotification',
    });

    if (response instanceof Error) {
      return console.error('Error in clearNotification', response);
    }
  } catch (err) {
    console.error('Error in clearNotification', err);
  }
};
