import React from "react";
import ReactDOM from "react-dom";
import { store } from "store";
import { Provider } from "react-redux";
import AppHolder from "./app_holder";
import { Router } from "react-router-dom";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
// import reportWebVitals from "./reportWebVitals";
import history from "router-history";
import moment from "moment";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import sv from "date-fns/locale/sv";
registerLocale("sv", sv);

// Setup moment to use swedish time.
require("moment/locale/sv");
moment.updateLocale("sv", {
  relativeTime: {
    past: "%s sedan",
    future: "Om %s",
    s: "mindre än 1min",
    m: "1min",
    mm: "%dmin",
    h: "1h",
    hh: "%dh",
    d: "1 dag",
    dd: "%d dagar",
    M: "1 månad",
    MM: "%d månader",
    y: "1 år",
    yy: "%d år",
  },
});

/**
 * Application starting point.
 *
 * Provider gives access to redux store.
 * Router controls url paths.
 */
const app = (
  <Provider store={store}>
    <Router history={history}>
      <AppHolder />
    </Router>
  </Provider>
);

ReactDOM.render(app, document.querySelector("#root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
//serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
