import React, { useContext } from "react";
import LanguageContext from "language-context";
import { resetProspectData } from "store/prospect/tasks";
import history from "router-history";
import Icon from "components/icon";

const ProspectSearchHeader = (props) => {
  const tc = useContext(LanguageContext);

  return (
    <div className="prospectSearchHeaderWrapper">
      <div className="prospectSearchHeaderWrapper__prospectSearchHeader">
        <div className="prospectSearchHeaderWrapper__prospectSearchHeader__content">
          <div className="prospectSearchHeaderWrapper__prospectSearchHeader__content__count">
            {props.count.hasOwnProperty("total") &&
            props.count.total !== null &&
            props.count.hasOwnProperty("users") &&
            props.count.users !== null ? (
              <>
                <div className="prospectSearchHeaderWrapper__prospectSearchHeader__content__count__item">
                  <strong>{props.count.total ? props.count.total : 0}</strong>
                  {`${tc.vehicles.toLowerCase()} ${tc.with.toLowerCase()}`}
                </div>
                <div className="prospectSearchHeaderWrapper__prospectSearchHeader__content__count__item">
                  <strong>{props.count.users ? props.count.users : 0}</strong>
                  {`${tc.vehicleUsers.toLowerCase()}`}
                </div>
              </>
            ) : null}
          </div>
          <div className="prospectSearchHeaderWrapper__prospectSearchHeader__content__buttons">
            <div
              className="prospectSearchHeaderWrapper__prospectSearchHeader__content__buttons__reset"
              onClick={() => {
                props.onReset();
                resetProspectData();
              }}
            >
              <Icon val="clear" />
              <span>{tc.setToZero}</span>
            </div>
            <div
              className="prospectSearchHeaderWrapper__prospectSearchHeader__content__buttons__search"
              onClick={() => {
                history.push("/prospektera/resultat");
              }}
            >
              <Icon val="search" />
              <span>{tc.prospectSearch}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProspectSearchHeader;
