import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import LanguageContext from "language-context";
import { updateDeal } from "store/deal/tasks";
import ReactS3Uploader from "react-s3-uploader";
import Info from "components/info";
import ItemWithButtons from "components/item_with_buttons";
import Loading from "components/loading";
import Popup from "components/popup";
import WidgetFooter from "components//widget_footer";
import WidgetHeader from "components//widget_header";

/**
 * This component renders a list of files, based on store.deal.deal.files.
 * Also an upload button.
 */
const DealFiles = (state) => {
  const amountIncrease = 10;
  const [fileRows, setFileRows] = useState(null);
  const [fileToRemove, setFileToRemove] = useState(null);
  const [dataLength, setDataLength] = useState(null); // Used to know when we have rendered all rows.
  const [showAmount, setShowAmount] = useState(amountIncrease);
  const [minimize, setMinimize] = useState(false);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    const _renderFiles = () => {
      let data = state.deal.deal.meta.files;

      if (!data || (data && !data.length)) {
        setMinimize(true);
        return setFileRows(
          <Info>
            <h4>{tc.noFiles}</h4>
            <p>{tc.filesHowTo}</p>
          </Info>
        );
      }

      setMinimize(false);

      // Set data length before slice.
      setDataLength(data.length);

      // Show more rows every time user click load icon.
      data = data.slice(0, showAmount);

      setFileRows(
        data.map((num, i) => {
          return (
            <React.Fragment key={i}>{_renderFileItem(num)}</React.Fragment>
          );
        })
      );
    };

    const _renderFileItem = (file) => {
      return (
        <div
          className="dealFilesWrapper__dealFiles__content__files__file"
          key={file.original_name}
        >
          <ItemWithButtons
            buttons={[
              {
                icon: "download",
                href: `https://s3.eu-central-1.amazonaws.com/bilp-test/${file.s3_filename}`,
                tooltip: tc.download,
              },
              {
                disabled: state.user.info.id !== state.deal.deal.user_id,
                icon: "remove",
                onClick: () => {
                  if (state.user.info.id === state.deal.deal.user_id) {
                    setFileToRemove(file);
                  }
                },
                tooltip: tc.remove,
              },
            ]}
            icon="file"
            label={tc.file}
            name={file.original_name}
          />
        </div>
      );
    };

    _renderFiles();
  }, [showAmount, state.deal.deal, state.user]);

  const _finishUpload = async (e, f) => {
    let files;
    if (Array.isArray(state.deal.deal.meta.files)) {
      files = state.deal.deal.meta.files.concat([
        {
          s3_filename: e.filename,
          original_name: f.name,
        },
      ]);
    } else {
      files = [
        {
          s3_filename: e.filename,
          original_name: f.name,
        },
      ];
    }

    return await updateDeal({ files: files });
  };

  const _startUpload = () => {
    document.querySelector("#s3Uploader").click();
  };

  const _stateCheck = () => {
    return !!(state.deal?.deal?.meta && state.user?.info);
  };

  const _uploadError = (message) => {
    console.error("S3 file upload error:", message);
  };

  return (
    <div className="dealFilesWrapper">
      <div className="dealFilesWrapper__dealFiles">
        <div className="dealFilesWrapper__dealFiles__header">
          <WidgetHeader
            dashboardItems={
              _stateCheck()
                ? [
                    {
                      disabled: !minimize,
                      icon: "maximize",
                      label: tc.maximize,
                      onClick: () => {
                        setMinimize(false);
                      },
                    },
                    {
                      disabled:
                        !(state.user.info.id === state.deal.deal.user_id) ||
                        minimize,
                      icon: "add",
                      label: tc.uploadFile,
                      onClick: _startUpload,
                    },
                    {
                      disabled: !(showAmount > amountIncrease) || minimize,
                      icon: "reset",
                      label: tc.reset,
                      onClick: () => {
                        setShowAmount(amountIncrease);
                      },
                    },
                    {
                      disabled: showAmount < dataLength || minimize,
                      icon: "load",
                      label: tc.load,
                      onClick: () => {
                        setShowAmount(showAmount + amountIncrease);
                      },
                    },
                    {
                      disabled: minimize,
                      icon: "minimize",
                      label: tc.minimize,
                      onClick: () => {
                        setMinimize(true);
                      },
                    },
                  ]
                : null
            }
            headline={tc.files}
            headlineSub={
              _stateCheck() ? tc.handleFiles : <Loading small={true} />
            }
          />
        </div>
        {_stateCheck() ? (
          <div
            className={
              minimize
                ? "dealFilesWrapper__dealFiles__content minimize"
                : "dealFilesWrapper__dealFiles__content"
            }
          >
            <div className="dealFilesWrapper__dealFiles__content__files">
              {fileRows}
            </div>
            {fileToRemove ? (
              <Popup
                close={() => {
                  setFileToRemove(null);
                }}
                size="small"
              >
                <div className="genericPopupContentWrapper">
                  <div className="genericPopupContentWrapper__genericPopupContent">
                    <div className="genericPopupContentWrapper__genericPopupContent__header">
                      <WidgetHeader
                        headline={tc.removeFile}
                        headlineSub={fileToRemove.original_name}
                      />
                    </div>
                    <div className="genericPopupContentWrapper__genericPopupContent__content">
                      <p>{tc.ensure}</p>
                    </div>
                    <div className="genericPopupContentWrapper__genericPopupContent__footer">
                      <WidgetFooter
                        buttonOneText={tc.remove}
                        buttonOneFunc={async () => {
                          const files = state.deal.deal.meta.files.filter(
                            (num) =>
                              num.s3_filename !== fileToRemove.s3_filename
                          );
                          await updateDeal({ files: files });
                          setFileToRemove(null);
                        }}
                        buttonTwoText={tc.cancel}
                        buttonTwoFunc={() => {
                          setFileToRemove(null);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Popup>
            ) : null}
          </div>
        ) : null}
        <div className="hidden">
          <ReactS3Uploader
            id="s3Uploader"
            signingUrl="/s3/sign"
            onFinish={_finishUpload}
            onError={_uploadError}
            contentDisposition="auto"
            scrubFilename={(filename) => filename.replace(/[^\w\d_\-.]+/gi, "")}
            uploadRequestHeaders={{ "x-amz-acl": "private" }}
          />
        </div>
      </div>
    </div>
  );
};

const MapStateToProps = (state) => {
  return {
    deal: state.deal,
    user: state.user,
  };
};

export default connect(MapStateToProps)(DealFiles);
