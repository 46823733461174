import React, { useContext, useRef, useState } from 'react';
import { connect } from 'react-redux';
import LanguageContext from 'language-context';
import { saveComment } from 'store/comment/tasks';
import ActionButton from 'components/action_button';

const CommentNew = (state) => {
  const [text, setText] = useState('');
  const commentTextRef = useRef(null);
  const tc = useContext(LanguageContext);

  const _onChange = () => {
    if (commentTextRef?.current) {
      setText(commentTextRef.current.value);
    }
  };

  const _saveComment = () => {
    if (text?.length) {
      saveComment({
        comment: text,
        target: state.props.target,
        targetType: state.props.targetType,
      });
      setText('');
    }
  };

  return (
    <div className="commentWrapper">
      <div className="commentWrapper__comment">
        <div className="commentWrapper__comment__content">
          <div
            className={
              state.props.embedded
                ? 'commentWrapper__comment__content__input embedded'
                : 'commentWrapper__comment__content__input'
            }
          >
            <textarea
              onChange={_onChange}
              placeholder={tc.comment}
              ref={commentTextRef}
              value={text}
            />
            <div className="commentWrapper__comment__content__input__buttons">
              <ActionButton
                disabled={!text?.length}
                label={tc.saveComment}
                onClick={_saveComment}
                type="highlight"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const MapStateToProps = (state, props) => {
  return {
    comment: state.comment,
    props: props,
  };
};

export default connect(MapStateToProps)(CommentNew);
