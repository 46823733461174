export const activityActionTypes = {
  ACTIVITY_RESET_FILTER: 'ACTIVITY_RESET_FILTER',
  ACTIVITY_SET_DEFAULT_FILTER: 'ACTIVITY_SET_DEFAULT_FILTER',
  ACTIVITY_SET_FILTER_DATE: 'ACTIVITY_SET_FILTER_DATE',
  ACTIVITY_SET_FILTER_LISTS: 'ACTIVITY_SET_FILTER_LISTS',
  ACTIVITY_SET_FILTER_PROSPECTTYPES: 'ACTIVITY_SET_FILTER_PROSPECTTYPES',
  ACTIVITY_SET_FILTER_USERS: 'ACTIVITY_SET_FILTER_USERS',
  ACTIVITY_SET_INSPECT_BY_FILTER: 'ACTIVITY_SET_INSPECT_BY_FILTER',
  ACTIVITY_SET_PIPELINE_BY_FILTER: 'ACTIVITY_SET_PIPELINE_BY_FILTER',
  ACTIVITY_SET_PIPELINE_SUBSCRIPTION: 'ACTIVITY_SET_PIPELINE_SUBSCRIPTION',
  ACTIVITY_SET_SUBSCRIPTION_LOADING: 'ACTIVITY_SET_SUBSCRIPTION_LOADING',
};
