import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import LanguageContext from 'language-context';
import carHelper from 'shared_helpers/car_helper';
import companyHelper from 'shared_helpers/company_helper';
import {
  addEntityToContacts,
  getContacts,
  removeContact,
  removeEntityFromContact,
  saveNewContact,
  updateContact,
} from 'store/contacts/tasks';
import ContactItem from './contact_item';
import ContactCreateItem from './contact_create_item';
import ContactEditItem from './contact_edit_item';
import Info from 'components/info';
import Loading from 'components/loading';
import Search from 'components/search';
import WidgetHeader from 'components/widget_header';

/**
 * Render contacts for an entityId (can be deal id, car reg number or company org number).
 *
 * Example, using this component on /foretag/id: <Contacts entityId='5566448899' entityName='Company name' entityType='company'/>
 * Example, using this component on /car/regNum: <Contacts companyId='5566889944' entityName='Audi Avant 6 (JHE261)' entityId='abc123' entityType='car'/>
 * Example, using this component on /deal/id: <Contacts entityId='5ea98dae085c6bbfa842e860' entityType='deal'/>
 *
 * @param state.props.companyId - string - When props.entityType === 'car' this should be provided, this is the user id for the car.
 * @param entityId - string - Always provided.
 * @param state.props.entityName - string - Wen props.entityType === 'company' this should be provided as company name. When props.entityType === 'car', this should be provided as 'brand  model (regnum)'.
 * @param state.props.entityType - string - Always provided. 'car'/ 'company' / 'deal'
 * @param state.props.isMinimized - A boolean indicating whether the component is minimized or not.
 * @param state.props.onMinimizeChange - Callback function invoked when the minimize state changes.
 */
const Contacts = (state) => {
  const amountIncrease = 6;
  const [minimize, setMinimize] = useState(false);
  const [dataLength, setDataLength] = useState(null); // Used to know when we have rendered all rows.
  const [contactRows, setContactRows] = useState(null); // Holds JSX content.
  const [editContact, setEditContact] = useState(null); // Id for contact being edited.
  const [showAmount, setShowAmount] = useState(amountIncrease);
  const [createContact, setCreateContact] = useState(false);
  const tc = useContext(LanguageContext);

  const { entityId, entityType } = state.props;

  useEffect(() => {
    setMinimize(state.props.isMinimized);
  }, [state.props.isMinimized]);
  useEffect(() => {
    getContacts({ entityId, entityType });
  }, [entityId, entityType]);

  useEffect(() => {
    /**
     * Set contact rows to render.
     */
    const _renderContacts = () => {
      let data = state.contacts.contacts;

      if ((!data || (data && data.length === 0)) && !createContact) {
        setDataLength(null);
        setMinimize(true);
        return setContactRows(
          <Info>
            <h4>{tc.noContacts}</h4>
            <p>{tc.contactHowTo}</p>
          </Info>
        );
      }

      setMinimize(false);

      // Set data length before slice.
      setDataLength(data.length);

      // Sort sticky contacts first.
      data = data.sort((a, b) => {
        return a.sticky ? -1 : 1;
      });

      // Show more rows every time user click load icon.
      data = state.contacts.contacts.slice(0, showAmount);

      let rows = [];

      if (createContact) {
        rows.push(
          <React.Fragment key={data.length + 1}>
            <ContactCreateItem
              cancelCreate={() => {
                setCreateContact(false);
              }}
              saveContact={_saveNewContact}
            />
          </React.Fragment>
        );
      }

      rows = rows.concat(
        data.map((contact) => {
          if (editContact === contact.id) {
            return (
              <React.Fragment key={contact.id}>
                <ContactEditItem
                  cancelEdit={() => {
                    setEditContact(null);
                  }}
                  contact={contact}
                  removeContact={_removeContact}
                  removeEntityFromContact={_removeEntityFromContact}
                  saveChanges={_updateContact}
                />
              </React.Fragment>
            );
          } else {
            return (
              <React.Fragment key={contact.id}>
                <ContactItem
                  contact={contact}
                  editContact={() => {
                    setEditContact(contact.id);
                  }}
                  sticky={contact.sticky}
                />
              </React.Fragment>
            );
          }
        })
      );

      setContactRows(rows);
    };

    /**
     * Save new contact from form.
     */
    const _saveNewContact = async (contact) => {
      setCreateContact(false);
      setEditContact(null);

      // Add current entity to the new contact.
      if (carHelper.isValidRegNumber(entityId)) {
        contact.savedTo = [
          {
            companyId: state.props.companyId,
            entityId: entityId,
            entityName: state.props.entityName
              ? state.props.entityName
              : entityId,
            entityType: entityType,
          },
        ];
      } else if (companyHelper.isValidOrgNr(entityId)) {
        contact.savedTo = [
          {
            companyId: entityId,
            entityId: entityId,
            entityName: state.props.entityName,
            entityType: entityType,
          },
        ];
      } else {
        contact.savedTo = [
          {
            entityId: entityId,
            entityType: entityType,
          },
        ];
      }

      const saved = await saveNewContact(contact);
      await getContacts({ entityId, entityType });
      return saved;
    };

    _renderContacts();
  }, [
    createContact,
    editContact,
    showAmount,
    state.contacts.contacts,
    state.props.companyId,
    entityId,
    state.props.entityName,
    entityType,
  ]);

  /**
   * Add current entity to contacts selected in search box.
   */
  const _addEntityToContacts = async () => {
    if (
      !state.search.selectedContacts ||
      !state.search.selectedContacts.length
    ) {
      return;
    }

    const contacts = await addEntityToContacts({
      contacts: state.search.selectedContacts,
      entityId: entityId,
      entityName: state.props.entityName,
      entityType: entityType,
    });
    await getContacts({ entityType, entityId });
    return contacts;
  };

  const _stateCheck = () => {
    return !!state?.contacts?.contacts;
  };

  /**
   * Completely remove a contact.
   */
  const _removeContact = async (id) => {
    const removed = await removeContact({ id: id });
    await getContacts({ entityId, entityType });
    return removed;
  };

  /**
   * Remove an entity from a contact.
   */
  const _removeEntityFromContact = async (payload) => {
    return await removeEntityFromContact({
      id: payload.id,
      entityId: payload.entityId,
      entityType,
    });
  };

  /**
   * Save changes for contact that is being edited.
   */
  const _updateContact = async (contact) => {
    setCreateContact(false);
    setEditContact(null);
    const updated = await updateContact({ id: contact.id, data: contact });
    await getContacts({ entityId, entityType });
    return updated;
  };

  return (
    <div
      className="contactsWrapper"
      style={{
        width: state.props.isCompanyPage ? '100%' : 'auto',
      }}
    >
      <div className="contactsWrapper__contacts">
        <div className="contactsWrapper__contacts__header">
          <WidgetHeader
            dashboardItems={
              state.props.isCompanyPage
                ? null
                : _stateCheck()
                  ? [
                      {
                        disabled: !minimize,
                        icon: 'maximize',
                        label: tc.maximize,
                        onClick: () => {
                          setMinimize(false);
                        },
                      },
                      {
                        icon: 'add',
                        disabled: createContact || minimize,
                        label: tc.createNewContact,
                        onClick: () => {
                          setCreateContact(true);
                        },
                      },
                      {
                        disabled: !(showAmount > amountIncrease) || minimize,
                        icon: 'reset',
                        label: tc.reset,
                        onClick: () => {
                          setShowAmount(amountIncrease);
                        },
                      },
                      {
                        disabled: !(showAmount < dataLength) || minimize,
                        icon: 'load',
                        label: tc.load,
                        onClick: () => {
                          setShowAmount(showAmount + amountIncrease);
                        },
                      },
                      {
                        disabled: minimize,
                        icon: 'minimize',
                        label: tc.minimize,
                        onClick: () => {
                          setMinimize(true);
                          if (state.props.onMinimizeChange) {
                            state.props.onMinimizeChange(true);
                          }
                        },
                      },
                    ]
                  : null
            }
            headline={tc.contacts}
            headlineSub={
              _stateCheck() ? tc.handleContacts : <Loading small={true} />
            }
          />
        </div>
        {_stateCheck() ? (
          <div
            className={
              minimize
                ? 'contactsWrapper__contacts__content minimize'
                : 'contactsWrapper__contacts__content'
            }
          >
            <div className="contactsWrapper__contacts__content__search">
              <div className="contactsWrapper__contacts__content__search__top">
                <span>{tc.connectContacts}:</span>
              </div>
              <div className="contactsWrapper__contacts__content__search__top">
                <Search type="contacts" save={_addEntityToContacts} />
              </div>
              <h4 onClick={() => setCreateContact(true)}>
                {'+ ' + tc.createNewContact}
              </h4>
            </div>
            <div
              className={
                !dataLength && !createContact
                  ? 'contactsWrapper__contacts__content__contacts info'
                  : 'contactsWrapper__contacts__content__contacts'
              }
            >
              {contactRows}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const MapStateToProps = (state, props) => {
  return {
    contacts: state.contacts,
    props: props,
    search: state.search,
  };
};

export default connect(MapStateToProps)(Contacts);
