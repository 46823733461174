export const ordersActionTypes = {
  ORDERS_RESET_MAILINGS: "ORDERS_RESET_MAILINGS",
  ORDERS_SET_ACTIVE_ORDER_STAGE: "ORDERS_SET_ACTIVE_ORDER_STAGE",
  ORDERS_SET_ACTIVE_ORDER_TYPE: "ORDERS_SET_ACTIVE_ORDER_TYPE",
  ORDERS_SET_LIST: "ORDERS_SET_LIST",
  ORDERS_SET_MAILINGS_INPUT: "ORDERS_SET_MAILINGS_INPUT",
  ORDERS_SET_MAILINGS_IS_READY: "ORDERS_SET_MAILINGS_IS_READY",
  ORDERS_SET_MAILINGS_POSTAGE: "ORDERS_SET_MAILINGS_POSTAGE",
  ORDERS_SET_MAILINGS_PDF_PREVIEW: "ORDERS_SET_MAILINGS_PDF_PREVIEW",
  ORDERS_SET_MAILINGS_PRICE: "ORDERS_SET_MAILINGS_PRICE",
  ORDERS_SET_MAILINGS_TYPE: "ORDERS_SET_MAILINGS_TYPE",
};
