import React from "react";
//import { NotificationForm } from "components/notification/notificationForm";
import SetActivityGoals from 'components/settings/settings_set_activity_goals';

const SettingsAdmin = () => {
  return (
    <div className="settingsPageWrapper">
      {/* <NotificationForm /> */}
      <SetActivityGoals />
    </div>
  );
};

export default SettingsAdmin;
