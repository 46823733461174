import React, { useEffect, useRef, useState } from "react";
import Loading from "components/loading";

/**
 * Render values in at neat manner.
 *
 * @param props.from - obj - {text: '', title: '', val: 123}
 * @param props.to - obj - {text: '', title: '', val: 123}
 */
const DisplayValues = (props) => {
  const [percentage, setPercentage] = useState(0);
  const [responsiveClassWidth, setResponsiveClassWidth] = useState("");
  const displayValuesRef = useRef(null);
  const observer = useRef(null);

  useEffect(() => {
    _setResponsiveClass();
    observer.current = new ResizeObserver(_setResponsiveClass);
    observer.current.observe(displayValuesRef.current);

    const ref = displayValuesRef.current;

    return () => observer.current.unobserve(ref);
  }, []);

  useEffect(() => {
    const value =
      Number.isFinite(props.from.val) && Number.isFinite(props.to.val)
        ? (+props.from.val / +props.to.val) * 100
        : 0;

    setPercentage(value === 0 ? 1 : value);
  }, [props.from.val, props.to.val]);

  const _setResponsiveClass = () => {
    if (!displayValuesRef?.current) {
      return;
    }

    const width = displayValuesRef.current.getBoundingClientRect().width;
    const breakpoint1 = 270;
    const breakpoint2 = 200;

    if (width <= breakpoint2) {
      setResponsiveClassWidth("displayValuesResponsiveWidth2");
    } else if (width <= breakpoint1 && width > breakpoint2) {
      setResponsiveClassWidth("displayValuesResponsiveWidth1");
    } else if (width > breakpoint1) {
      setResponsiveClassWidth("");
    }
  };

  return (
    <div
      className={`displayValuesWrapper ${responsiveClassWidth}`}
      ref={displayValuesRef}
    >
      <div className="displayValuesWrapper__displayValues">
        <div className="displayValuesWrapper__displayValues__content">
          <div className="displayValuesWrapper__displayValues__content__values">
            <div className="displayValuesWrapper__displayValues__content__values__item">
              {props.from.val === "loading" ? (
                <Loading small={true} />
              ) : (
                <span>{props.from.val}</span>
              )}
            </div>
            <div className="displayValuesWrapper__displayValues__content__values__item">
              {props.to.val === "loading" ? (
                <Loading small={true} />
              ) : (
                <span>{props.to.val}</span>
              )}
            </div>
          </div>
          <div className="displayValuesWrapper__displayValues__content__barHolder">
            <div className="displayValuesWrapper__displayValues__content__barHolder__bar">
              <div
                className="displayValuesWrapper__displayValues__content__barHolder__bar__dynamic"
                style={{ width: percentage.toString() + "%" }}
              />
            </div>
          </div>
          <div className="displayValuesWrapper__displayValues__content__information">
            <div className="displayValuesWrapper__displayValues__content__information__item">
              <h4>{props.from.title}</h4>
              <p>{props.from.text}</p>
            </div>
            <div className="displayValuesWrapper__displayValues__content__information__item">
              <h4>{props.to.title}</h4>
              <p>{props.to.text}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisplayValues;
