import { store } from "store";
import { prospectHelper, request } from "helpers";
import { getWidgets } from "store/widgets/tasks";
import { supertempActionTypes } from "./actions";
import moment from "moment";
import { showFlashMessage } from "../flash_messages/tasks";
import { event } from "tracking";
import * as text from "text-content";

/**
 * Retrieve data for a supertemp subscription.
 *
 * We use search criterias from a dashboard widget object to make a search and then we save the result on the id for that widget object in supertemp reducer.
 * A user can have multiple supertemp subscriptions, so every search result is saved to its unique id.
 *
 * @param payload.id - string - Dashboard widget id.
 * @param payload.latestBatch - bool - If we want to get the value for last 3 days, this is used in SupertempWidget but not in Supertemp.
 * @param payload.page - number
 * @param payload.pageSize - number
 * @param payload.sort - object
 * @param payload.type - 'cars' | 'prospects'
 */
export const getSupertempSubscriptionData = async (payload) => {
  try {
    if (!payload?.id) {
      return console.error(
        "Missing params in getSupertempSubscriptionData",
        payload
      );
    }

    store.dispatch({
      type: supertempActionTypes.SUPERTEMP_SET_SUBSCRIPTION_LOADING,
      payload: {
        id: payload.id,
        type: "add",
      },
    });

    if (
      !store.getState().widgets?.data?.data ||
      store.getState().widgets?.data?.data?.length
    ) {
      // Probably lost dashboard data because of page reload.
      await getWidgets();
    }

    const supertempMonitoring = store
      .getState()
      .widgets.data.widgets.find((num) => num._id === payload.id);

    if (!supertempMonitoring || !supertempMonitoring?.data?.criterias) {
      return console.error("No valid supertempMonitoring", payload);
    }

    // Create backend query.
    let query = prospectHelper.createQueryObjectFromDataObject({
      data: supertempMonitoring.data.criterias,
      skipRebuildToBackendObjectStructure: true,
    });

    query.extraFilters = {};
    query.pageSize = payload.pageSize ? payload.pageSize : 10;
    query.page = payload.page ? payload.page : 0;
    query.sort = payload.sort ? payload.sort : null;

    let data = await request({
      data: query,
      method: "post",
      url: payload?.type === "prospects" ? "/prospect/" : "/prospect/car",
    });

    if (data instanceof Error) {
      return console.error("Error in getSupertempSubscriptionData", data);
    }

    const result: any = {
      id: payload.id,
      data: {
        items: data.data && data.data.length ? data.data : [],
        latestBatch: payload.latestBatch ? "loading" : null,
        name: supertempMonitoring.name,
        total: data.total,
      },
    };

    store.dispatch({
      type: supertempActionTypes.SUPERTEMP_SET_SUBSCRIPTION_LOADING,
      payload: {
        id: payload.id,
        type: "remove",
      },
    });

    store.dispatch({
      type: supertempActionTypes.SUPERTEMP_SET_SUPERTEMP_SUBSCRIPTION,
      payload: result,
    });

    if (payload.latestBatch) {
      // New search for latest 3 days, only used in SupertempWidget so search for prospects.
      let latestBatchCriterias = JSON.parse(JSON.stringify(query));
      delete latestBatchCriterias.page;
      delete latestBatchCriterias.search;
      delete latestBatchCriterias.sort;
      latestBatchCriterias.diverse.supertempInterval = {
        from: moment().subtract(72, "hours").format("YYYY-MM-DD HH:mm:ss"),
      };

      result.data.latestBatch = await request({
        data: latestBatchCriterias,
        method: "post",
        url: "/prospect/numberOfResults/",
      });

      return store.dispatch({
        type: supertempActionTypes.SUPERTEMP_SET_SUPERTEMP_SUBSCRIPTION,
        payload: result,
      });
    }
  } catch (err) {
    return console.error("Error in getSupertempSubscriptionData", err);
  }
};

/**
 * Save selected prospects from a supertemp monitoring to list.
 * If no selected all matching prospects for query ends up in list.
 * This is basically the same as saveToList in prospects tasks,
 * but here we provide a query instead of using prospect data object.
 *
 * @param payload.id - string - Id for supertemp monitoring.
 * @param payload.listIds - array - When saving to existing lists.
 * @param payload.listName - string - When creating a new list.
 * @param payload.prospects - array - When saving selected prospect ids.
 */
export const saveToList = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === "en"
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (!payload || (!payload?.listIds && !payload?.listName)) {
      return console.error("Missing params in saveToList", payload);
    }

    event({
      category: "supertemp",
      action: "supertemp_save_to_list",
    });

    if (
      !store.getState().widgets?.data?.data ||
      store.getState().widgets?.data?.data?.length
    ) {
      // Probably lost dashboard data because of page reload.
      await getWidgets();
    }

    const supertempMonitoring = store
      .getState()
      .widgets.data.widgets.find((num) => num._id === payload.id);
    const query = supertempMonitoring
      ? supertempMonitoring.data?.criterias
      : null;

    let data;
    if (payload.listIds) {
      data = await request({
        data: {
          data: query,
          ids: payload.listIds,
          prospects: payload.prospects ? payload.prospects : null,
        },
        method: "post",
        url: "/prospect/saveToExistingList/",
      });
    } else if (payload.listName) {
      if (payload.prospects) {
        query.extraFilters = {
          user_id: payload.prospects.map((id) => {
            return {
              value: id.toString(),
            };
          }),
        };
      }

      data = await request({
        data: {
          data: query,
          name: payload.listName,
        },
        method: "post",
        url: "/prospect/createList",
      });
    }

    if (data instanceof Error) {
      return console.error("Error in saveToList", data);
    }

    return showFlashMessage(tc.prospectsHaveBeenSavedToList, "success");
  } catch (err) {
    showFlashMessage(tc.couldNotSaveToList, "fail");
    return console.error("Error in saveToList", err);
  }
};
