import { flashMessagesActionTypes } from "./actions";

interface flashMessagesState {
  message: string;
  showMessage: boolean;
  type: "info" | "fail" | "success";
}

const initialState: flashMessagesState = {
  message: "",
  showMessage: false,
  type: "info",
};

export const flashMessagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case flashMessagesActionTypes.SET_MESSAGE: {
      return {
        ...state,
        message: action.payload.message,
        type: action.payload.type,
      };
    }
    case flashMessagesActionTypes.SET_SHOW_MESSAGE: {
      return {
        ...state,
        showMessage: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
