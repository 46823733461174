import LanguageContext from 'language-context';
import { useContext } from 'react';
import Icon from 'components/icon';

const OrdersHubspotOrder = ({
  nameCheckboxStatus,
  nameCheckboxLabel,
  phoneCheckboxStatus,
  phoneCheckboxLabel,
  orderSettings,
  setOrderSettings,
  nameIsFresh,
}) => {
  const tc = useContext(LanguageContext);
  return (
    <div className="ordersInformationWrapper__ordersInformation__content__section__block">
      <span className="label">1. {tc.hubspotChooseSettings}</span>
      <p>{tc.hubspotExtraData}</p>
      <p>{tc.hubspotExtraDataDescription}</p>
      <p>{tc.hubspotNoExtraDataDescription}</p>
      <div className="flexParent">
        <div
          className="includeValue"
          onClick={() => {
            if (nameCheckboxStatus === 'clear') return;
            if (nameIsFresh) {
              setOrderSettings((current) => {
                return { phone: current.phone, name: false };
              });
              return;
            } else {
              setOrderSettings((current) => {
                return {
                  phone: current.name ? false : current.phone,
                  name: !current.name,
                };
              });
              return;
            }
          }}
        >
          <Icon val={nameCheckboxStatus} />
          {nameCheckboxLabel}
        </div>
      </div>
      <div className="flexParent">
        <div
          className="includeValue"
          onClick={() => {
            if (nameIsFresh) {
              setOrderSettings((current) => {
                return { ...current, phone: !current.phone };
              });
              return;
            }
            if (!orderSettings.name) return;
            if (phoneCheckboxStatus === 'clear') return;
            setOrderSettings((current) => {
              return { ...current, phone: !current.phone };
            });
          }}
        >
          <Icon val={phoneCheckboxStatus} />
          {phoneCheckboxLabel}
        </div>
      </div>
    </div>
  );
};

export default OrdersHubspotOrder;
