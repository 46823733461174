import React, { useContext } from "react";
import { connect } from "react-redux";
import { updateSettings } from "store/user/tasks";
import LanguageContext from "language-context";
import Loading from "components/loading";
import ProspectGroups from "components/prospect_groups";
import ProspectPresets from "components/prospect_presets";
import ToggleOption from "components/toggle_option";
import ProspectPostortPresets from "../prospect_postort_presets";

/**
 * Prospect settings section.
 */
const SettingsProspect = (state) => {
  const tc = useContext(LanguageContext);

  const _stateCheck = () => {
    return !!state.user?.settings?.settings;
  };

  /**
   * Toggle vehicle types.
   */
  const _toggleVehicleType = async (key) => {
    let settings = state.user.settings.settings;
    settings.prospect.vehicleTypes[key] = !settings.prospect.vehicleTypes[key];
    return await updateSettings({ settings: settings });
  };

  return _stateCheck() ? (
    <div className="settingsPageWrapper">
      <div className="settingsPageWrapper__settingsPage">
        <div
          className="settingsPageWrapper__settingsPage__section"
          id="toggleVehicleTypes"
        >
          <div className="settingsPageWrapper__settingsPage__section__header">
            {tc.toggleVehicleTypes}
          </div>
          <div className="settingsPageWrapper__settingsPage__section__content">
            <div className="settingsPageWrapper__settingsPage__section__content__holder">
              <div className="toggleOptionsHolder">
                {Object.keys(state.user.settings.settings.prospect.vehicleTypes)
                  .sort((a, b) =>
                    tc[a.toLowerCase()] < tc[b.toLowerCase()] ? -1 : 1
                  )
                  .map((key, i) => {
                    return (
                      <div key={key} className="toggleOptionsHolder__item">
                        <ToggleOption
                          active={
                            state.user.settings.settings.prospect.vehicleTypes[
                              key
                            ]
                          }
                          key={key}
                          label={tc[key.toLowerCase()]}
                          onClick={() => {
                            _toggleVehicleType(key);
                          }}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <div
          className="settingsPageWrapper__settingsPage__section"
          id="groupsPostcodes"
        >
          <div className="settingsPageWrapper__settingsPage__section__header">
            {tc.groupsPostcodes}
          </div>
          <div className="settingsPageWrapper__settingsPage__section__content">
            <ProspectPostortPresets />
          </div>
        </div>
        <div
          className="settingsPageWrapper__settingsPage__section"
          id="groupsDealer"
        >
          <div className="settingsPageWrapper__settingsPage__section__header">
            {tc.groupsDealer}
          </div>
          <div className="settingsPageWrapper__settingsPage__section__content">
            <ProspectGroups groupId="dealer" />
          </div>
        </div>
        <div
          className="settingsPageWrapper__settingsPage__section"
          id="groupsUserIdsShort"
        >
          <div className="settingsPageWrapper__settingsPage__section__header">
            {tc.groupsUserIds}
          </div>
          <div className="settingsPageWrapper__settingsPage__section__content">
            <ProspectGroups groupId="userIds" />
          </div>
        </div>
        <div
          className="settingsPageWrapper__settingsPage__section"
          id="prospectPresets"
        >
          <div className="settingsPageWrapper__settingsPage__section__header">
            {tc.prospectPresets}
          </div>
          <div className="settingsPageWrapper__settingsPage__section__content">
            <ProspectPresets />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Loading />
  );
};

const MapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(MapStateToProps)(SettingsProspect);
