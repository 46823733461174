import ReactGA from "react-ga";
ReactGA.initialize("UA-136207772-1", {
  titleCase: false,
});

/**
 * Track events.
 *
 * @param payload.action - string
 * @param payload.category - string
 * @param payload.label - string (optional)
 * @param payload.value - number (optional)
 */
export const event = (payload) => {
  if (process?.env?.NODE_ENV !== "development") {
    let args = {
      action: payload.action,
      category: payload.category,
    };

    if (payload.label) {
      args.label = payload.label;
    }

    if (payload.value) {
      args.value = payload.value;
    }

    ReactGA.event(args);
  }
};

/**
 * Track page views.
 *
 * @param payload.path - string
 */
export const pageView = (payload) => {
  if (process?.env?.NODE_ENV !== "development") {
    ReactGA.pageview(payload.path);
  }
};
