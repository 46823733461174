import { store } from 'store';
import { activityHelper, request } from 'helpers';
import { activityActionTypes } from './actions';
import { debounce } from 'debounce';
import { event } from 'tracking';
import { widgetHelper } from 'helpers/widget_helper';
import { showFlashMessage } from 'store/flash_messages/tasks';

/**
 * Get deals for certain phases. Based on set values in filter reducer.
 *
 * @param payload.excludePhases - array (optional) - Array with deal phases.
 * @param payload.includePhases - array (optional) - Array with deal phases.
 */

export const getActivityCompletionByFilter = async ({ userIds, date }) => {
  try {
    const data = await request({
      data: {
        userIds,
        date,
      },
      method: 'POST',
      url: '/deals/new/getActivityCompletionByFilter',
    });

    if (data instanceof Error) {
      return console.error('Error in getPipelineByFilter', data);
    }

    return data;
  } catch (err) {
    return console.error('Error in getPipelineByFilter:', err);
  }
};

export const getSharedDealStats = async ({ userIds, date }) => {
  try {
    const data = await request({
      data: {
        userIds,
        date,
      },
      method: 'POST',
      url: '/deals/getSharedDealStats',
    });

    if (data instanceof Error) {
      throw data;
    }

    return data;
  } catch (err) {
    console.error('Error in getSharedDealStats:', err);
    showFlashMessage(
      'Data för heta leads kunde inte hämtas. Var vänlig ladda om sidan eller kontakta support.',
      'fail'
    );
    return [];
  }
};

const getInspectByFilterDebounced = async (payload) => {
  try {
    if (!payload) {
      return console.error('Missing params in getInspectByFilter', payload);
    }

    event({
      category: 'activity',
      action: 'activity_inspect_filter',
    });

    store.dispatch({
      type: activityActionTypes.ACTIVITY_SET_INSPECT_BY_FILTER,
      payload: null,
    });
    const { filter } = store.getState().activity;
    let data = await request({
      data: {
        date: filter.date,
        excludePhases:
          payload.excludePhases && payload.excludePhases.length
            ? payload.excludePhases
            : [],
        includePhases:
          payload.includePhases && payload.includePhases.length
            ? payload.includePhases
            : [],
        listIds: filter.lists.length ? filter.lists : null,
        subscriptions: filter.subscriptions.length
          ? filter.subscriptions
          : null,
        phases: payload.phases,
        userIds: filter.users.length ? filter.users : [],
        prospectTypes: filter.prospectTypes.length ? filter.prospectTypes : [],
      },
      method: 'get',
      url: '/deals/new/getDealsByDatesListsPhasesUsersWithActivity',
    });

    if (data instanceof Error) {
      return console.error('Error in getInspectByFilter', data);
    }

    if (data && Array.isArray(data)) {
      // Limit to 500 rows.
      data = data.slice(0, 500);

      // Sort by updated.
      data = data.sort((a, b) => {
        if (new Date(a.updated) < new Date(b.updated)) {
          return 1;
        } else if (new Date(a.updated) > new Date(b.updated)) {
          return -1;
        } else {
          return 0;
        }
      });

      return store.dispatch({
        type: activityActionTypes.ACTIVITY_SET_INSPECT_BY_FILTER,
        payload: data,
      });
    } else {
      return store.dispatch({
        type: activityActionTypes.ACTIVITY_SET_INSPECT_BY_FILTER,
        payload: [],
      });
    }
  } catch (err) {
    return console.error('Error in getInspectByFilter:', err);
  }
};

export const getInspectByFilter = debounce(getInspectByFilterDebounced, 500);

/**
 * Get pipeline data based on set values in filter reducer.
 */
const getPipelineByFilterDebounced = async () => {
  try {
    store.dispatch({
      type: activityActionTypes.ACTIVITY_SET_PIPELINE_BY_FILTER,
      payload: null,
    });

    event({
      category: 'activity',
      action: 'activity_pipeline_filter',
    });

    const filter = store.getState().activity.filter;
    let data = await request({
      data: {
        date: filter.date,
        excludePhases: [],
        includePhases: [],
        listIds: filter.lists.length ? filter.lists : null,
        subscriptions: filter.subscriptions.length
          ? filter.subscriptions
          : null,
        userIds: filter.users.length ? filter.users : [],
        prospectTypes: filter.prospectTypes,
      },
      method: 'get',
      url: '/deals/new/getDealsByDatesListsPhasesUsers',
    });

    if (data instanceof Error) {
      return console.error('Error in getPipelineByFilter', data);
    }

    if (data && Array.isArray(data)) {
      const activeAmount = data.filter(
        (num) =>
          num.phase !== 'lost' && num.phase !== 'trash' && num.phase !== 'won'
      ).length;
      const lostAmount = data.filter((num) => num.phase === 'lost').length;
      const trashAmount = data.filter((num) => num.phase === 'trash').length;
      const wonAmount = data.filter((num) => num.phase === 'won').length;

      let pipeline = {
        all: {
          amount: data.length,
          percentage: 100,
        },
        active: {
          amount: activeAmount,
          percentage:
            !activeAmount || activeAmount === 0
              ? 0
              : Math.round((activeAmount / data.length) * 100),
        },
        trash: {
          amount: trashAmount,
          percentage:
            !trashAmount || trashAmount === 0
              ? 0
              : Math.round((trashAmount / data.length) * 100),
        },
        lost: {
          amount: lostAmount,
          percentage:
            !lostAmount || lostAmount === 0
              ? 0
              : Math.round((lostAmount / data.length) * 100),
        },
        won: {
          amount: wonAmount,
          percentage:
            !wonAmount || wonAmount === 0
              ? 0
              : Math.round((wonAmount / data.length) * 100),
        },
      };

      return store.dispatch({
        type: activityActionTypes.ACTIVITY_SET_PIPELINE_BY_FILTER,
        payload: pipeline,
      });
    } else {
      return store.dispatch({
        type: activityActionTypes.ACTIVITY_SET_PIPELINE_BY_FILTER,
        payload: {},
      });
    }
  } catch (err) {
    return console.error('Error in getPipelineByFilter:', err);
  }
};

export const getPipelineByFilter = debounce(getPipelineByFilterDebounced, 500);

/**
 * Retrieve data for a pipeline subscription.

 * We use search criterias from a dashboard widget object to make a search and then we save the result on the id for that widget object.
 * A user can have multiple pipeline subscriptions, so every search result is saved to its unique id.
 *
 * @param payload.currentMonth - bool - If currentWeek or currentMonth is true, date is irrelevant.
 * @param payload.currentWeek - bool - If currentWeek or currentMonth is true, date is irrelevant.
 * @param payload.pastThirtyDays - bool
 * @param payload.past365Days - bool
 * @param payload.date - obj - {from: ISOdate, to: ISOdate}
 * @param payload.id - string - Dashboard widget id.
 * @param payload.listIds - string (optional)
 * @param payload.userIds - string
 */
export const getPipelineSubscriptionData = async (payload) => {
  try {
    if (!payload?.id) {
      return console.error(
        'Missing params in getPipelineSubscriptionData:',
        payload
      );
    }

    store.dispatch({
      type: activityActionTypes.ACTIVITY_SET_SUBSCRIPTION_LOADING,
      payload: {
        id: payload.id,
        type: 'add',
      },
    });

    event({
      category: 'activity',
      action: 'activity_pipeline_subscription',
    });

    const date = widgetHelper.parseWidgetDate(payload);

    let data = await request({
      data: {
        date: date,
        excludePhases: [],
        includePhases: [],
        listIds: payload.listIds?.length ? payload.listIds : null,
        userIds: payload.userIds?.length
          ? payload.userIds
              .map((num) => (num ? +num : null))
              .filter((num) => num)
          : [],
        subscriptions: payload.subscriptions?.length
          ? payload.subscriptions
          : null,
      },
      method: 'get',
      url: '/deals/new/getDealsByDatesListsPhasesUsers',
    });

    if (!data || data instanceof Error) {
      return console.error(
        'Could not get data in getPipelineSubscriptionData:',
        data
      );
    }

    if (data && Array.isArray(data)) {
      const activeAmount = data.filter(
        (num) =>
          num.phase !== 'lost' && num.phase !== 'trash' && num.phase !== 'won'
      ).length;
      const lostAmount = data.filter((num) => num.phase === 'lost').length;
      const trashAmount = data.filter((num) => num.phase === 'trash').length;
      const wonAmount = data.filter((num) => num.phase === 'won').length;

      let pipeline = {
        all: {
          amount: data.length,
          percentage: 100,
        },
        active: {
          amount: activeAmount,
          percentage:
            !activeAmount || activeAmount === 0
              ? 0
              : Math.round((activeAmount / data.length) * 100),
        },
        trash: {
          amount: trashAmount,
          percentage:
            !trashAmount || trashAmount === 0
              ? 0
              : Math.round((trashAmount / data.length) * 100),
        },
        lost: {
          amount: lostAmount,
          percentage:
            !lostAmount || lostAmount === 0
              ? 0
              : Math.round((lostAmount / data.length) * 100),
        },
        won: {
          amount: wonAmount,
          percentage:
            !wonAmount || wonAmount === 0
              ? 0
              : Math.round((wonAmount / data.length) * 100),
        },
      };

      store.dispatch({
        type: activityActionTypes.ACTIVITY_SET_SUBSCRIPTION_LOADING,
        payload: {
          id: payload.id,
          type: 'remove',
        },
      });

      return store.dispatch({
        type: activityActionTypes.ACTIVITY_SET_PIPELINE_SUBSCRIPTION,
        payload: {
          id: payload.id,
          data: pipeline,
        },
      });
    }
  } catch (err) {
    return console.error('Error in getPipelineSubscriptionData:', err);
  }
};

/**
 * Clear set filter.
 */
export const resetActivityFilter = () => {
  return store.dispatch({
    type: activityActionTypes.ACTIVITY_RESET_FILTER,
    payload: {},
  });
};

export const setDefaultActivityFilter = () => {
  return store.dispatch({
    type: activityActionTypes.ACTIVITY_SET_DEFAULT_FILTER,
    payload: { users: [store.getState().user.info.id] },
  });
};

/**
 * Set filter in store state.
 *
 * You can set date, lists and users.
 * You can add, remove or toggle values for lists and users. Default is toggle.
 * Values for lists and users can be a single id string or an array.
 * Value for date should object with 'from' and 'to' values in date format.
 *
 * @param payload.action - string (optional) - 'add' | 'addExclusive' | 'remove' | 'toggle'... addExclusive means remove existing values before add.
 * @param payload.type - string
 * @param payload.val - object | string | array
 */
export const setActivityFilter = (payload) => {
  try {
    if (!payload?.type || !payload?.val) {
      return console.error('Missing params in setActivityFilter', payload);
    }

    event({
      category: 'filter',
      action: 'filter_set_filter',
    });

    switch (payload.type) {
      case 'date':
        return store.dispatch({
          type: activityActionTypes.ACTIVITY_SET_FILTER_DATE,
          payload: payload.val,
        });
      case 'subscription':
        let subscriptions = store.getState().activity.filter.subscriptions;
        const { val: subscriptionId } = payload;
        if (subscriptions.includes(subscriptionId)) {
          return store.dispatch({
            type: activityActionTypes.ACTIVITY_SET_FILTER_SUBSCRIPTIONS,
            payload: subscriptions.filter(
              (subscription: string) => subscription !== subscriptionId
            ),
          });
        } else {
          return store.dispatch({
            type: activityActionTypes.ACTIVITY_SET_FILTER_SUBSCRIPTIONS,
            payload: subscriptions.concat(subscriptionId),
          });
        }
      case 'list':
        let newLists = JSON.parse(
          JSON.stringify(store.getState().activity.filter.lists)
        );

        if (payload.action === 'add') {
          // Add.
          if (Array.isArray(payload.val)) {
            payload.val.forEach((val) => {
              if (!newLists.find((num) => num === val)) {
                newLists.push(val);
              }
            });
          } else {
            newLists = newLists.push(payload.val);
          }
        } else if (payload.action === 'addExclusive') {
          // Add exclusive.
          if (Array.isArray(payload.val)) {
            newLists = payload.val;
          } else {
            newLists = [payload.val];
          }
        } else if (payload.action === 'remove') {
          // Remove.
          if (Array.isArray(payload.val)) {
            payload.val.forEach((val) => {
              newLists = newLists.filter((num) => num !== val);
            });
          } else {
            newLists = newLists.filter((num) => num !== payload.val);
          }
        } else {
          // Toggle.
          if (Array.isArray(payload.val)) {
            payload.val.forEach((val) => {
              if (newLists.includes(val)) {
                newLists = newLists.filter((num) => num !== val);
              } else {
                newLists.push(val);
              }
            });
          } else {
            if (newLists.includes(payload.val)) {
              newLists = newLists.filter((num) => num !== payload.val);
            } else {
              newLists.push(payload.val);
            }
          }
        }

        return store.dispatch({
          type: activityActionTypes.ACTIVITY_SET_FILTER_LISTS,
          payload: newLists,
        });
      case 'prospectType': {
        let newProspectTypes: any[];
        if (Array.isArray(store.getState().activity.filter.prospectTypes)) {
          newProspectTypes = JSON.parse(
            JSON.stringify(store.getState().activity.filter.prospectTypes)
          );
        } else {
          newProspectTypes = [];
        }

        if (payload.action === 'add') {
          if (Array.isArray(payload.val)) {
            payload.val.forEach((val) => {
              if (!newProspectTypes.includes((type) => type === val)) {
                newProspectTypes = newProspectTypes.concat(val);
              }
            });
          } else {
            newProspectTypes = newProspectTypes.concat(payload.val);
          }
        } else if (payload.action === 'addExclusive') {
          if (Array.isArray(payload.val)) {
            newProspectTypes = [...payload.val];
          } else {
            newProspectTypes = [payload.val];
          }
        } else if (payload.action === 'remove') {
          if (Array.isArray(payload.val)) {
            newProspectTypes = newProspectTypes.filter(
              (type) => !payload.val.includes(type)
            );
          } else {
            newProspectTypes = newProspectTypes.filter(
              (type) => type !== payload.val
            );
          }
        } else {
          // Toggling
          if (Array.isArray(payload.val)) {
            payload.val.forEach((val) => {
              if (newProspectTypes.includes(val)) {
                newProspectTypes = newProspectTypes.filter(
                  (type) => type !== val
                );
              } else {
                newProspectTypes = newProspectTypes.concat(val);
              }
            });
          } else {
            if (newProspectTypes.includes(payload.val)) {
              newProspectTypes = newProspectTypes.filter(
                (type) => type !== payload.val
              );
            } else {
              newProspectTypes = newProspectTypes.concat(payload.val);
            }
          }
        }

        return store.dispatch({
          type: activityActionTypes.ACTIVITY_SET_FILTER_PROSPECTTYPES,
          payload: newProspectTypes,
        });
      }
      case 'user':
        let newUsers = JSON.parse(
          JSON.stringify(store.getState().activity.filter.users)
        );

        if (payload.action === 'add') {
          // Add.
          if (Array.isArray(payload.val)) {
            payload.val.forEach((val) => {
              if (!newUsers.find((num) => +num === +val)) {
                newUsers.push(+val);
              }
            });
          } else {
            newUsers = newUsers.concat([+payload.val]);
          }
        } else if (payload.action === 'addExclusive') {
          // Add exclusive.
          if (Array.isArray(payload.val)) {
            newUsers = payload.val.map((num) => +num);
          } else {
            newUsers = [+payload.val];
          }
        } else if (payload.action === 'remove') {
          // Remove.
          if (Array.isArray(payload.val)) {
            payload.val.forEach((val) => {
              newUsers = newUsers.filter((num) => num !== +val);
            });
          } else {
            newUsers = newUsers.filter((num) => num !== +payload.val);
          }
        } else {
          // Toggle.
          if (Array.isArray(payload.val)) {
            payload.val.forEach((val) => {
              if (newUsers.includes(val)) {
                newUsers = newUsers.filter((num) => num !== +val);
              } else {
                newUsers.push(+val);
              }
            });
          } else {
            if (newUsers.includes(payload.val)) {
              newUsers = newUsers.filter((num) => num !== +payload.val);
            } else {
              newUsers.push(+payload.val);
            }
          }
        }

        return store.dispatch({
          type: activityActionTypes.ACTIVITY_SET_FILTER_USERS,
          payload: newUsers,
        });
      default:
        return null;
    }
  } catch (err) {
    return console.error('Error in setActivityFilter:', err);
  }
};
