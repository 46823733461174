import React, { useContext, useEffect, useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import LanguageContext from "language-context";
import IconStyled from "components/icon_styled";
import Loading from "components/loading";

/**
 * Display a PDF file, with pagination.
 * Also return some file data to parent component via onLoadPage and onLoadDocument.
 *
 * Make sure to use react-pdf@5.2.0 <---- !
 *
 * @param props.onLoadDocument - Returns total number of pages.
 * @param props.onLoadPage - Returns an object with page width and height.
 * @param props.source - PDF to display, can be url/blob.
 */
const PdfPreview = (props) => {
  const [previewPageNumber, setPreviewPageNumber] = useState(1);
  const [previewTotalPages, setPreviewTotalPages] = useState(0);
  const [previewWidth, setPreviewWidth] = useState(300);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    const timer = setTimeout(_adjustWidth, 1000);
    window.addEventListener("resize", _adjustWidth);
    return () => {
      window.removeEventListener("resize", _adjustWidth);
      clearTimeout(timer);
    };
  }, []);

  const _adjustWidth = () => {
    const el = document.querySelector(
      ".pdfPreviewWrapper__pdfPreview__document"
    );
    if (el && el.getBoundingClientRect()) {
      setPreviewWidth(el.getBoundingClientRect().width);
    }
  };

  return (
    <div className="pdfPreviewWrapper__pdfPreview">
      <div className="pdfPreviewWrapper__pdfPreview__document">
        <Document
          error={tc.genericFailMessage}
          file={props.source}
          loading={<Loading />}
          onLoadSuccess={(result) => {
            if (result?._pdfInfo?.numPages) {
              setPreviewTotalPages(result._pdfInfo.numPages);
              if (typeof props.onLoadDocument === "function") {
                props.onLoadDocument(result._pdfInfo.numPages);
              }
            }
          }}
          onLoadError={(err) => {
            console.error(err);
          }}
        >
          <Page
            loading={<Loading />}
            onLoadSuccess={(result) => {
              if (
                result?._pageInfo?.view &&
                result._pageInfo.view[2] &&
                result._pageInfo.view[3] &&
                typeof props.onLoadPage === "function"
              ) {
                props.onLoadPage({
                  width: result._pageInfo.view[2],
                  height: result._pageInfo.view[3],
                });
              }
            }}
            onLoadError={(err) => {
              console.error(err);
            }}
            pageNumber={previewPageNumber}
            width={previewWidth}
          />
        </Document>
      </div>
      {previewTotalPages > 1 ? (
        <div className="pdfPreviewWrapper__pdfPreview__pagination">
          <IconStyled
            class={previewPageNumber < 2 ? "disabled" : "link"}
            iconVal="navigatePrevious"
            onClick={() => {
              if (previewPageNumber > 1) {
                setPreviewPageNumber(previewPageNumber - 1);
              }
            }}
          />
          <IconStyled
            class={previewPageNumber >= previewTotalPages ? "disabled" : "link"}
            iconVal="navigateNext"
            onClick={() => {
              if (previewPageNumber < previewTotalPages) {
                setPreviewPageNumber(previewPageNumber + 1);
              }
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

export default PdfPreview;
