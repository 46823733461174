import PopupNew from 'components/popup/popup_new';
import { Table } from 'components/table';
import { tableHelper } from 'helpers';
import { useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { moveAllToList } from 'store/leads/tasks';
import { getLists } from 'store/lists/tasks';
import LanguageContext from 'language-context';

const MoveAllLeads = ({
  lists,
  close = () => {},
  totalLeads,
  onFinishMove = () => {},
}) => {
  const [selectedList, setSelectedList] = useState(null);
  const [mode, setMode] = useState('existing');
  const tc = useContext(LanguageContext);
  const initialFetched = useRef(false);
  useEffect(() => {
    if (initialFetched.current === true) return;
    getLists({}).then(() => {
      initialFetched.current = true;
    });
  }, []);

  return (
    <PopupNew close={close}>
      <div className="moveAllLeadsWrapper">
        <div className="typeSelectionWrapper">
          <button
            className={`button ${mode === 'existing' ? 'active' : ''}`}
            onClick={() => setMode('existing')}
          >
            {tc.existingList}
          </button>
          <button
            className={`button ${mode === 'new' ? 'active' : ''}`}
            onClick={() => setMode('new')}
          >
            {tc.contractListsNewLabel}
          </button>
        </div>
        {mode === 'existing' ? (
          <ExistingView
            selectedList={selectedList}
            lists={lists}
            close={close}
            setSelectedList={setSelectedList}
            totalLeads={totalLeads}
            onFinishMove={onFinishMove}
          />
        ) : (
          <NewView
            close={close}
            totalLeads={totalLeads}
            onFinishMove={onFinishMove}
          />
        )}
      </div>
    </PopupNew>
  );
};

const ExistingView = ({
  selectedList,
  lists,
  close,
  setSelectedList,
  totalLeads,
  onFinishMove,
}) => {
  const [moveInProgress, setMoveInProgress] = useState(false);
  const tc = useContext(LanguageContext);
  return (
    <div className="existingViewWrapper">
      <div className="textWrapper">
        {selectedList ? (
          <p>
            {tc.leadsYouAreMoving} {totalLeads} {tc.leadsYouAreMovingTo}{' '}
            <span className="listName">
              {lists.lists.find((list) => list.id === selectedList).name}
            </span>
            .
          </p>
        ) : (
          <p>
            {tc.leadsChooseAList} {totalLeads} {tc.leadsYouAreMovingTo}.
          </p>
        )}
        <div className="buttonWrapper">
          <ConfirmButton
            onClick={async () => {
              setMoveInProgress(true);
              await moveAllToList({ mode: 'existing', listId: selectedList });
              setMoveInProgress(false);
              close();
              onFinishMove();
            }}
            disabled={selectedList === null || moveInProgress === true}
          >
            {tc.leadsMoveAll}
          </ConfirmButton>
          <CancelButton
            onClick={() => {
              close();
            }}
          >
            {tc.cancel}
          </CancelButton>
        </div>
      </div>
      <div>
        <Table
          columns={tableHelper.getListsCompactColumns()}
          onSelect={(arr) => {
            setSelectedList(arr.find((list) => list !== selectedList) || null);
          }}
          rows={tableHelper.getListsCompactRows(
            lists?.lists?.length ? lists.lists : []
          )}
          selected={selectedList ? [selectedList] : []}
          hideSelectAll={true}
        />
      </div>
    </div>
  );
};

const NewView = ({ close, totalLeads, onFinishMove }) => {
  const [name, setName] = useState('');
  const [moveInProgress, setMoveInProgress] = useState(false);
  const tc = useContext(LanguageContext);
  const handleChange = (e) => {
    setName(e.target.value);
  };
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
      className="listForm"
    >
      <label htmlFor="listName">
        {tc.leadsChooseListName} {totalLeads} {tc.leadsYouAreMovingTo}.
      </label>
      <input
        placeholder={tc.listName}
        onChange={handleChange}
        value={name}
        name="listName"
      />
      <div className="buttonContainer">
        <ConfirmButton
          onClick={async () => {
            setMoveInProgress(true);
            await moveAllToList({ mode: 'new', name });
            setMoveInProgress(false);
            close();
            onFinishMove();
          }}
          disabled={!name && !moveInProgress}
        >
          {tc.leadsMoveAll}
        </ConfirmButton>
        <CancelButton
          onClick={() => {
            close();
          }}
        >
          {tc.cancel}
        </CancelButton>
      </div>
    </form>
  );
};

const ConfirmButton = ({ children, ...props }) => {
  return (
    <button className={`button confirm`} {...props}>
      {children}
    </button>
  );
};

const CancelButton = ({ children, ...props }) => {
  return (
    <button className={`button cancel`} {...props}>
      {children}
    </button>
  );
};

const mapStateToProps = (state, props) => {
  return { lists: state.lists, ...props };
};
export default connect(mapStateToProps)(MoveAllLeads);
