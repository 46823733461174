export const prospectActionTypes = {
  PROSPECT_SET_ACTIVE_PRESET: "PROSPECT_SET_ACTIVE_PRESET",
  PROSPECT_SET_BACKEND_DATA_IS_LOADED: "PROSPECT_SET_BACKEND_DATA_IS_LOADED",
  PROSPECT_SET_COUNT: "PROSPECT_SET_COUNT",
  PROSPECT_SET_DATA: "PROSPECT_SET_DATA",
  PROSPECT_SET_DATA_INITIAL: "PROSPECT_SET_DATA_INITIAL",
  PROSPECT_SET_DATA_SELLERS_GROUPS: "PROSPECT_SET_SELLERS_GROUPS",
  PROSPECT_SET_DATA_USER_IDS_GROUPS: "PROSPECT_SET_DATA_USER_IDS_GROUPS",
  PROSPECT_SET_DEALER_SALESMEN: "PROSPECT_SET_DEALER_SALESMEN",
  PROSPECT_SET_FREETEXT_BRANDS: "PROSPECT_SET_FREETEXT_BRANDS",
  PROSPECT_SET_GROUPS_DEALER_GROUPS: "PROSPECT_SET_GROUPS_DEALER_GROUPS",
  PROSPECT_SET_GROUPS_USER_IDS_GROUPS: "PROSPECT_SET_GROUPS_USER_IDS_GROUPS",
  PROSPECT_SET_MAP_GROUPS: "PROSPECT_SET_MAP_GROUPS",
  PROSPECT_SET_HIDE_FIELDS: "PROSPECT_SET_HIDE_FIELDS",
  PROSPECT_SET_LOADING: "PROSPECT_SET_LOADING",
  PROSPECT_SET_POSTORT_PRESETS: "PROSPECT_SET_POSTORT_PRESETS",
  PROSPECT_SET_PRESETS: "PROSPECT_SET_PRESETS",
  PROSPECT_SET_RECREATE_SEARCH_LOADING: "PROSPECT_SET_RECREATE_SEARCH_LOADING",
  PROSPECT_SET_REGIONS: "PROSPECT_SET_REGIONS",
  PROSPECT_SET_REGIONS_ACTIVE_PRESET: "PROSPECT_SET_REGIONS_ACTIVE_PRESET",
  PROSPECT_SET_SEARCH_RESULT: "PROSPECT_SET_SEARCH_RESULT",
  PROSPECT_SET_CONTRACTS: "PROSPECT_SET_CONTRACTS",
  PROSPECT_SET_ATLAS_REGIONS: "PROSPECT_SET_ATLAS_REGIONS",
};
