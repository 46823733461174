import React, { useContext, useEffect, useState } from "react";
import LanguageContext from "language-context";
import { Doughnut, Bar } from "react-chartjs-2";
import { chartHelper } from "helpers";
import Info from "components/info";
import WidgetHeader from "components/widget_header";

/**
 * Render some charts for fleet data.
 *
 * @param props.data - object - Fleet analysis data.
 * @param props.historic - bool - Historic or not.
 */
const FleetAnalysis = (props) => {
  const [minimize, setMinimize] = useState(false);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    setMinimize(props.data.total.total === 0);
  }, [props.data]);

  const _renderItem = (data, label) => {
    if (!data?.labels.length) {
      // return null;
      return (
        <div className="fleetAnalysisWrapper__fleetAnalysis__content__item">
          <Info>
            {/*<h4>{tc.noAnalyseData}</h4>*/}
            <h4>{label}</h4>
            <p>{tc.noAnalyseDataWhy}</p>
          </Info>
        </div>
      );
    } else if (data.labels.length < 7) {
      return (
        <div className="fleetAnalysisWrapper__fleetAnalysis__content__item">
          <h5>{label}</h5>
          <Doughnut data={data} options={chartHelper.optionsDoughnut} />
        </div>
      );
    } else {
      // Remove border for HorizontalBar.
      data.datasets.map((set) => {
        set.borderWidth = 0;
        return set;
      });
      return (
        <div className="fleetAnalysisWrapper__fleetAnalysis__content__item">
          <h5>{label}</h5>
          <Bar
            data={data}
            options={chartHelper.optionsBar({
              legend: false,
              horizontal: true,
            })}
          />
        </div>
      );
    }
  };

  return (
    <div className="fleetAnalysisWrapper">
      <div className="fleetAnalysisWrapper__fleetAnalysis">
        <div className="fleetAnalysisWrapper__fleetAnalysis__header">
          <WidgetHeader
            dashboardItems={[
              {
                disabled: !minimize,
                icon: "maximize",
                label: tc.maximize,
                onClick: () => {
                  setMinimize(false);
                },
              },
              {
                disabled: minimize,
                icon: "minimize",
                label: tc.minimize,
                onClick: () => {
                  setMinimize(true);
                },
              },
            ]}
            headline={
              props.historic
                ? tc.fleetAnalysisChartsHistoric
                : tc.fleetAnalysisCharts
            }
            headlineSub={
              props.data.total.total === 0
                ? tc.noVehicles
                : props.data.total.total === 1000 && props.historic
                ? `${
                    tc.showing
                  } ${tc.maximum.toLowerCase()} 1000 ${tc.vehicles.toLowerCase()}`
                : `${tc.total} ${
                    props.data.total.total
                  } ${tc.vehicles.toLowerCase()}`
            }
          />
        </div>
        <div
          className={
            minimize
              ? "fleetAnalysisWrapper__fleetAnalysis__content minimize"
              : "fleetAnalysisWrapper__fleetAnalysis__content"
          }
        >
          {_renderItem(props.data.carType, tc.carType)}
          {_renderItem(props.data.brands, tc.brands)}
          {_renderItem(props.data.models, tc.models)}
          {_renderItem(props.data.inService, tc.inService)}
          {_renderItem(props.data.new, tc.boughtNew)}
          {_renderItem(props.data.boughtPlace, tc.boughtPlace)}
          {_renderItem(props.data.dateAcquired, tc.possessionTime)}
          {_renderItem(props.data.regYear, tc.regYear)}
          {_renderItem(props.data.date_car_year, tc.carYear)}
          {_renderItem(props.data.finance, tc.finance)}
          {_renderItem(props.data.leasingOwner, tc.leasingOwner)}
          {_renderItem(props.data.fuel, tc.fuel)}
          {/*{_renderItem(props.data.kaross, tc.kaross)}*/}
          {_renderItem(props.data.climate_class, tc.climateClassification)}
          {_renderItem(props.data.engineStrength, tc.engineStrength)}
        </div>
      </div>
    </div>
  );
};

export default FleetAnalysis;
