import React, { useContext, useRef, useState, useEffect } from 'react';
import {
  getUnreadNews,
  markAsRead,
  setItemToDisplay,
  clearNotification,
} from 'store/news/tasks';
import Icon from 'components/icon';
import history from 'router-history';
import LanguageContext from 'language-context';
import Tooltip from 'components/tooltip';
import { NavLink } from 'react-router-dom';
/**
 *
 * @param amountDays - Amount days back from today
 *
 */
const PageHeaderNotifications = ({
  onNewsUpdate,
  amountDays,
  onNotificationsCleared,
  updateUnreadNewsCount,
  stateUserId,
  handleDropdownClose,
}) => {
  const [news, setNews] = useState([]);
  const isFetched = useRef(false);

  const tc = useContext(LanguageContext);

  const iconRef = useRef(null);

  useEffect(() => {
    let isMounted = true;
    if (isFetched.current) {
      return () => {
        isMounted = false;
      };
    }
    async function getNews() {
      try {
        isFetched.current = true;
        const items = await getUnreadNews();
        const currentDate = new Date();
        const pastDate = new Date();
        pastDate.setDate(currentDate.getDate() - amountDays);
        // Filter the news items for the set date and where the current user's ID is not in notificationsCleared
        const filteredNews = items.filter((newsItem) => {
          const createdDate = new Date(newsItem.created);
          return (
            !isNaN(createdDate.getTime()) &&
            createdDate >= pastDate &&
            !newsItem.notificationsCleared.includes(stateUserId)
          );
        });

        if (isMounted) {
          const hasNews = filteredNews.length > 0;
          onNewsUpdate(hasNews);
          //handleNewsCount(filteredNews.length);
          setNews(filteredNews);
          onNotificationsCleared(false);
          updateUnreadNewsCount(filteredNews.length);
        }
      } catch (error) {
        // Handle errors
        console.error('Error fetching news:', error);
      }
    }
    getNews();
    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, [
    onNewsUpdate,
    onNotificationsCleared,
    updateUnreadNewsCount,
    stateUserId,
    amountDays,
    handleDropdownClose,
  ]);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleClick = async (newsItem) => {
    try {
      handleDropdownClose(true);
      await markAsRead(newsItem.id);
      await setItemToDisplay(newsItem);
      onNewsUpdate(true);
      history.push('/nyheter');
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleClearNotifications = async (clickedNewsItem, userid, event) => {
    try {
      // Check if the notification for the clicked news item has not been cleared for the current user
      if (!clickedNewsItem.notificationsCleared.includes(userid)) {
        if (event) {
          event.persist();
        }

        await clearNotification(clickedNewsItem.id);
        // Update the state with the news items, marking the current one as cleared for the user
        const updatedNews = news.map((newsItem) =>
          newsItem.id === clickedNewsItem.id
            ? {
                ...newsItem,
                notificationsCleared: [
                  ...newsItem.notificationsCleared,
                  userid,
                ],
              }
            : newsItem
        );
        setNews([...updatedNews]);
        // Update counts and flags as needed
        // Check if there are still unread news items after the current one has been cleared
        const hasNews = updatedNews.some(
          (newsItem) => !newsItem.notificationsCleared.includes(userid)
        );
        // Update the parent component with the news status
        onNewsUpdate(hasNews);

        // Calculate the remaining count of unread notifications
        const remainingNotificationsCount = updatedNews.filter(
          (item) => !item.notificationsCleared.includes(userid)
        ).length;

        // Update the parent component with the remaining count of unread notifications
        updateUnreadNewsCount(remainingNotificationsCount);
        onNotificationsCleared(false);
      }
    } catch (error) {
      console.error('Error clearing notifications:', error);
    }
  };
  return (
    <>
      {news.length > 0 ? (
        news.map((newsItem, index) => (
          <div
            className={
              index === news.length - 1
                ? 'pageHeader__newsNotification__newsItemLast'
                : 'pageHeader__newsNotification__newsItem'
            }
            key={newsItem.id}
          >
            <Tooltip tooltipContent={tc.remove} inline={true}>
              <div ref={iconRef}>
                <Icon
                  val="markChatRead"
                  class="pageHeader_news_notification_icon"
                  onClick={(event) =>
                    handleClearNotifications(newsItem, stateUserId, event)
                  }
                />
              </div>
            </Tooltip>
            <div
              className="pageHeader__newsNotification__news_section"
              onClick={() => handleClick(newsItem)}
            >
              <div className="pageHeader__newsNotification__flex_container">
                <div className="pageHeader__newsNotification__text_container">
                  <strong>
                    <h5>{newsItem.headline}</h5>
                  </strong>
                  <p>{newsItem.ingress}</p>
                  <p>{formatDate(newsItem.created)}</p>
                </div>

                <Icon val="navigateNext"></Icon>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="pageHeader__newsNotification__no_news_section">
          <div className="pageHeader__newsNotification__no_news_flex_container">
            <div className="pageHeader__newsNotification__no_news_text_container">
              <h5>{tc.noUnreadNews}</h5>
            </div>
            <div className="pageHeader__newsNotification__no_news_bottom_text">
              <NavLink
                className="pageHeader__newsNotification__navigate_to_news"
                exact
                to={'/nyheter'}
              >
                <h6>{tc.showAllNews}</h6>
                <Icon val="navigateNext" />
              </NavLink>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PageHeaderNotifications;
