export const userActionTypes = {
  SET_USER_COLLEAGUES: "SET_USER_COLLEAGUES",
  SET_USER_CONNECTIONS: "SET_USER_CONNECTIONS",
  SET_USER_DATA_INTEGRATION: "SET_USER_DATA_INTEGRATION",
  SET_USER_INFO: "SET_USER_INFO",
  SET_USER_LANG: "SET_USER_LANG",
  SET_USER_MAP_CONTROL_ID: "SET_USER_MAP_CONTROL_ID",
  SET_USER_MODULE_MODE: "SET_USER_MODULE_MODE",
  SET_USER_NAVIGATION_STATUS: "SET_USER_NAVIGATION_STATUS",
  SET_USER_RESPONSIVE_MODE: "SET_USER_RESPONSIVE_MODE",
  SET_USER_SETTINGS: "SET_USER_SETTINGS",
  SET_USER_SETTINGS_ACTIVE_PAGE: "SET_USER_SETTINGS_ACTIVE_PAGE",
  SET_USER_SETTINGS_ACTIVE_SECTION: "SET_USER_SETTINGS_ACTIVE_SECTION",
  SET_USER_SHOW_CAMPAIGN: "SET_USER_SHOW_CAMPAIGN",
  SET_USER_SHOW_CAMPAIGN_INFO: "SET_USER_SHOW_CAMPAIGN_INFO",
  SET_USER_SHOW_SETTINGS: "SET_USER_SHOW_SETTINGS",
};
