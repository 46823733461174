import { agileActionTypes } from "./actions";

interface AgileState {
  activityTypes: string[] | null;
  allProspectsReceived: boolean;
  columns: Array<object> | null;
  filter: Array<object> | null;
  loading: boolean;
  previewData: object | null;
  previewId: string | null;
  sort: string | null;
}

const initialState: AgileState = {
  activityTypes: null,
  allProspectsReceived: true,
  columns: null,
  filter: null,
  loading: false,
  previewData: null,
  previewId: null,
  sort: null,
};

export const agileReducer = (state = initialState, action) => {
  switch (action.type) {
    case agileActionTypes.SET_ACTIVITY_TYPES: {
      return { ...state, activityTypes: action.payload };
    }
    case agileActionTypes.SET_ALL_PROSPECTS_RECEIVED: {
      return {
        ...state,
        allProspectsReceived: action.payload,
      };
    }
    case agileActionTypes.SET_COLUMNS: {
      return {
        ...state,
        columns: action.payload,
      };
    }
    case agileActionTypes.SET_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    case agileActionTypes.SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case agileActionTypes.SET_PREVIEW_DATA: {
      return {
        ...state,
        previewData: action.payload,
      };
    }
    case agileActionTypes.SET_PREVIEW_ID: {
      return {
        ...state,
        previewId: action.payload,
      };
    }
    case agileActionTypes.SET_SORT: {
      return {
        ...state,
        sort: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
