import {carActionTypes} from './actions';

interface CarState {
    car: object | null,
    deals: Array<object> | null,
    responsible: object | null,
}

const initialState: CarState = {
    car: null,
    deals: null,
    responsible: null,
};

export const carReducer = (state = initialState, action) => {
    switch(action.type) {
        case carActionTypes.CAR_SET_CAR: {
            return {
                ...state,
                car: action.payload,
            }
        }
        case carActionTypes.CAR_SET_DEALS: {
            return {
                ...state,
                deals: action.payload,
            }
        }
        case carActionTypes.CAR_SET_RESPONSIBLE: {
            return {
                ...state,
                responsible: action.payload,
            }
        }
        default: {
            return state;
        }
    }
};
