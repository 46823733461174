import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import companyHelper from "shared_helpers/company_helper";
import LanguageContext from "language-context";
import WidgetHeader from "components/widget_header";

const CarUser = (props) => {
  const tc = useContext(LanguageContext);

  const financedBy = () => {
    if (props.leasing === 1) {
      return tc.leasing;
    } else if (props.credit === 1) {
      return tc.credit;
    } else if (props.leasing === 1 && props.credit === 1){
      return tc.dataMissing
    } else {
      return tc.cash;
    }
  };

  return (
    <div className="carInfoWrapper">
      <div className="carInfoWrapper__carInfo">
        <div className="carInfoWrapper__carInfo__header">
          <WidgetHeader
            headline={tc.vehicleUser}
            headlineSub={tc.currentUser}
          />
        </div>
        <div className="carInfoWrapper__carInfo__content">
          <div className="carInfoWrapper__carInfo__content__item">
            <p className="carInfoWrapper__carInfo__content__item__label">
              {tc.name}
            </p>
            <p>
              {props.fullName ? (
                <NavLink
                  exact
                  to={
                    companyHelper.isValidOrgNr(props.user_id)
                      ? "/foretag/" + props.user_id
                      : "/person/" + props.user_id
                  }
                  key={props.user_id}
                >
                  {props.fullName}
                </NavLink>
              ) : (
                tc.dataMissing
              )}
            </p>
          </div>
          <div className="carInfoWrapper__carInfo__content__item">
            <p className="carInfoWrapper__carInfo__content__item__label">
              {tc.leasingOwner}
            </p>
            <p>
              {props.leasing_owner ? props.leasing_owner : tc.noLeasingOwner}
            </p>
          </div>
          <div className="carInfoWrapper__carInfo__content__item">
            <p className="carInfoWrapper__carInfo__content__item__label">
              {tc.financedBy}
            </p>
            <p>{financedBy()}</p>
          </div>
          <div className="carInfoWrapper__carInfo__content__item">
            <p className="carInfoWrapper__carInfo__content__item__label">
              {tc.boughtCondition}
            </p>
            <p>{props.new_or_old ? props.new_or_old : tc.dataMissing}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarUser;
