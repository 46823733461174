import { store } from "store";
import { searchResultsActionTypes } from "./actions";
import { request, tableHelper } from "helpers";
import { showFlashMessage } from "store/flash_messages/tasks";
import { debounce } from "debounce";
import * as text from "text-content";

/**
 * Get result for a search query.
 *
 * @param payload.allHits - bool (optional) - When we want to return all prospect ids for search.
 * @param payload.displayMode - 'cars' | 'prospects'
 * @param payload.query - string - Search term on which to get results.
 * @param payload.page - number (optional)
 * @param payload.pageSize - number (optional)
 * @param payload.sort - object (optional)
 * @param payload.tableSearchQuery - string (optional) - When user uses the table search function.
 * @param payload.total - number (optional) - When allHits === true we need to provide a total value to backend.
 */
export const getSearchResultsData = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === "en"
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (
      !payload ||
      (payload && !payload.query) ||
      (payload && !payload.displayMode)
    ) {
      return console.error("Missing params in getSearchResultsData", payload);
    }
    let query = payload.query;
    if (!payload.allHits) {
      store.dispatch({
        type: searchResultsActionTypes.SEARCH_RESULTS_SET_QUERY,
        payload: query,
      });
      store.dispatch({
        type: searchResultsActionTypes.SEARCH_RESULTS_SET_DATA,
        payload: null,
      });
    }

    const columns =
      payload.displayMode === "prospects"
        ? tableHelper.getProspectColumns({}).map((num) => num.id)
        : tableHelper.getVehicleColumns({}).map((num) => num.id);

    const url =
      payload.displayMode === "prospects"
        ? "/search/doSearch/" + encodeURIComponent(query)
        : "/search/doSearch/" + encodeURIComponent(query) + "/fleet";

    // if (payload.displayMode === "prospects") {
    // columns = ["activity", "fullName", "numberOfCars", "city", "abv_hgrupp"];
    // url = "/search/doSearch/" + encodeURIComponent(query);
    // } else if (payload.displayMode === "cars") {
    // columns = [
    //   "activity",
    //   "brand",
    //   "ts_model",
    //   "reg_number",
    //   "possession",
    //   "fullName",
    // ];
    // url = "/search/doSearch/" + encodeURIComponent(query) + "/fleet";
    // }

    const data = await request({
      data: {
        allHits: JSON.stringify(payload.allHits),
        columns: JSON.stringify(columns),
        displayMode: payload.displayMode,
        filters: JSON.stringify({}),
        page: payload.page ? payload.page : null,
        pageSize: payload.pageSize ? payload.pageSize : null,
        search: payload.tableSearchQuery ? payload.tableSearchQuery : null,
        sort:
          payload.sort && payload.sort.orderBy && payload.sort.order
            ? JSON.stringify({
                direction: payload.sort.order,
                type: payload.sort.orderBy,
              })
            : JSON.stringify(null),
        total:
          payload.allHits && payload.total
            ? JSON.stringify(payload.total)
            : null,
      },
      method: "get",
      url: url,
    });

    if (data instanceof Error) {
      return showFlashMessage(tc.couldNotGetData, "info");
    }

    // When allHits is true we only return ids, don't save anything to state.
    if (payload.allHits && data) {
      return data.map((num) => num._id);
    }

    // Create activityMapped array to use in tableHelper.
    if (Array.isArray(data?.data) && payload.displayMode === "prospects") {
      data.data.map((num) => {
        let act: any = [];

        if (Array.isArray(num?.activity_and_list)) {
          num.activity_and_list.forEach((activity) => {
            if (activity.taken_by) {
              if (act.find((num) => num.taken_by === activity.taken_by)) {
                return;
              } else {
                const colleagues = store.getState().user.colleagues;
                const connections = store.getState().user.connections;
                let user = colleagues.find((x) => x.id === activity.taken_by);
                if (!user) {
                  connections.forEach((dealer) => {
                    user = dealer.users.find((x) => x.id === activity.taken_by);
                  });
                }

                activity.taken_by_name = user ? user.name : "";

                act.push(activity);
              }
            } else {
              act.push(activity);
            }
          });
        }

        if (Array.isArray(num?.activities)) {
          num.activities.forEach((activity) => {
            if (activity.taken_by) {
              if (act.find((num) => num.taken_by === activity.taken_by)) {
                return;
              } else {
                const colleagues = store.getState().user.colleagues;
                const connections = store.getState().user.connections;
                let user = colleagues.find((x) => x.id === activity.taken_by);
                if (!user) {
                  connections.forEach((dealer) => {
                    user = dealer.users.find((x) => x.id === activity.taken_by);
                  });
                }

                activity.taken_by_name = user ? user.name : "";

                act.push(activity);
              }
            } else {
              act.push(activity);
            }
          });
        }

        num.activityMapped = act;

        return num;
      });
    }

    return store.dispatch({
      type: searchResultsActionTypes.SEARCH_RESULTS_SET_DATA,
      payload: data,
    });
  } catch (err) {
    return console.error("Error in getSearchResultsData", err);
  }
};

export const getSearchResultsDataMultiple = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === "en"
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (
      !payload ||
      (payload && !payload.displayMode)
    ) {
      return console.error("Missing params in getSearchResultsData", payload);
    }

    store.dispatch({
      type: searchResultsActionTypes.SEARCH_RESULTS_SET_DATA,
      payload: null,
    });

    const prospectColumns = ["activity", "fullName", "numberOfCars", "city", "abv_hgrupp"];
    const carColumns = ["activity", "brand", "ts_model", "reg_number", "possession", "fullName"];

    const data = await request({
      data: {
        ids: JSON.stringify(payload.ids),
        allHits: JSON.stringify(payload.allHits),
        columns: JSON.stringify(payload.displayMode === 'prospects' ? prospectColumns : carColumns),
        displayMode: payload.displayMode,
        filters: JSON.stringify({}),
        page: payload.page ? payload.page : null,
        pageSize: payload.pageSize ? payload.pageSize : null,
        search: payload.tableSearchQuery ? payload.tableSearchQuery : null,
        sort:
          payload.sort && payload.sort.orderBy && payload.sort.order
            ? JSON.stringify({
                direction: payload.sort.order,
                type: payload.sort.orderBy,
              })
            : JSON.stringify(null),
        total:
          payload.allHits && payload.total
            ? JSON.stringify(payload.total)
            : null,
      },
      method: "get",
      url: "/search/refactor/doSearch",
    });

    if (data instanceof Error) {
      return showFlashMessage(tc.couldNotGetData, "info");
    }

    // When allHits is true we only return ids, don't save anything to state.
    if (payload.allHits && data) {
      return data.map((num) => num._id);
    }

    // Create activityMapped array to use in tableHelper.
    if (Array.isArray(data?.data) && payload.displayMode === "prospects") {
      data.data.map((num) => {
        let act: any = [];

        if (Array.isArray(num?.activity_and_list)) {
          num.activity_and_list.forEach((activity) => {
            if (activity.taken_by) {
              if (act.find((num) => num.taken_by === activity.taken_by)) {
                return;
              } else {
                const colleagues = store.getState().user.colleagues;
                const connections = store.getState().user.connections;
                let user = colleagues.find((x) => x.id === activity.taken_by);
                if (!user) {
                  connections.forEach((dealer) => {
                    user = dealer.users.find((x) => x.id === activity.taken_by);
                  });
                }

                activity.taken_by_name = user ? user.name : "";

                act.push(activity);
              }
            } else {
              act.push(activity);
            }
          });
        }

        if (Array.isArray(num?.activities)) {
          num.activities.forEach((activity) => {
            if (activity.taken_by) {
              if (act.find((num) => num.taken_by === activity.taken_by)) {
                return;
              } else {
                const colleagues = store.getState().user.colleagues;
                const connections = store.getState().user.connections;
                let user = colleagues.find((x) => x.id === activity.taken_by);
                if (!user) {
                  connections.forEach((dealer) => {
                    user = dealer.users.find((x) => x.id === activity.taken_by);
                  });
                }

                activity.taken_by_name = user ? user.name : "";

                act.push(activity);
              }
            } else {
              act.push(activity);
            }
          });
        }

        num.activityMapped = act;

        return num;
      });
    }

    return store.dispatch({
      type: searchResultsActionTypes.SEARCH_RESULTS_SET_DATA,
      payload: data,
    });
  } catch (err) {
    return console.error("Error in getSearchResultsData", err);
  }
};

// Debounced (used for table search function).
export const getSearchResultsDataDebounced = debounce(
  getSearchResultsData,
  500
);

// Debounced (used for table search function).
export const getSearchResultsDataMultipleDebounced = debounce(
  getSearchResultsDataMultiple,
  500
);
