import React, { useEffect, useRef } from "react";
import { miscHelper } from "helpers";
import Popper from "components/popper";

/**
 * Used to render a popup.
 * Uses store to retrieve components and close/show.
 *
 * @param borderRadius - bool (optional) If we want border-radius on popup.
 * @param close - func - Function to close popup.
 * @param noPadding - bool (optional) - No padding.
 * @param noScroll - bool (optional) - No scroll.
 * @param onlyContent - bool (optional) - No generic popup style, just display the content with it's own background, scroll etc.
 * @param size - string (optional) - 'small' | 'medium' | 'big' | 'xl'
 * @param map - bool (optional) - special CSS for Mapbox needs
 * @param styles - styling (optional) - Not for use with map. In case you need special styling,
 * for example to display scripts without overlaying the AgileBoardPreview component.
 */
const Popup = ({
  borderRadius,
  close,
  noPadding,
  noScroll,
  onlyContent,
  size = "medium",
  map,
  children,
  classNames,
  overflow,
  center,
}) => {
  const popupRef = useRef(null);
  const popupMapRef = useRef(null);

  useEffect(() => {
    /**
     * When clicking outside popup components close it, if props.close() is provided.
     */
    const _closePopup = (e) => {
      if (typeof close === "function") {
        if (
          miscHelper.clickUnmount(e, map ? popupMapRef : popupRef, true, true)
        ) {
          // Handle som special cases. We don't want to close popup whenever these elements is clicked.
          const dropdowns = document.querySelectorAll(
            ".dropdownWrapper__dropdown__content"
          );
          const datePicker = document.querySelector(".react-datepicker");
          const map = document.querySelector(".mapPopupWrapper");
          let dropdownsClicked = false;
          if (dropdowns?.length) {
            dropdowns.forEach((node) => {
              if (node.contains(e.target)) {
                dropdownsClicked = true;
              }
            });
          }

          const exceptionsClicked =
            dropdownsClicked ||
            (datePicker && datePicker.contains(e.target)) ||
            (map && map.contains(e.target));

          if (!exceptionsClicked) {
            close();
          }
        }
      }
    };
    const _escapeClosePopup = (e) => {
      const key = e.key;
      if (key === "Escape") {
        close();
      }
    };

    window.addEventListener("mousedown", _closePopup);
    window.addEventListener("keydown", _escapeClosePopup);
    return () => {
      window.removeEventListener("mousedown", _closePopup);
      window.removeEventListener("keydown", _escapeClosePopup);
    };
  }, [close, map]);

  return (
    <Popper>
      {map ? (
        <div className="mapPopupWrapper">
          <div className="popupMap" ref={popupMapRef}>
            {children}
          </div>
        </div>
      ) : (
        <div
          className={`popupWrapper ${classNames ? classNames : ""} ${
            center ? "center" : ""
          }`}
        >
          <div
            className={`popupWrapper__popup ${size} ${
              noPadding ? "noPadding" : ""
            } ${noScroll ? "noScroll" : ""} ${
              borderRadius ? "borderRadius" : ""
            } 
            ${onlyContent ? "onlyContent" : ""}
            ${overflow ? "overflow" : ""}
            `}
            ref={popupRef}
          >
            <div className="popupWrapper__popup__content">{children}</div>
          </div>
        </div>
      )}
    </Popper>
  );
};

export default Popup;
