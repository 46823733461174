import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import LanguageContext from "language-context";
import { getPaginatedLeads } from "store/leads/tasks";
import { updateWidgets } from "store/widgets/tasks";
import { NavLink } from "react-router-dom";
import { tableHelper } from "helpers";
import { Table } from "components/table";
import DisplayValues from "components/display_values";
import Icon from "components/icon";
import Loading from "components/loading";
import WidgetHeader from "components/widget_header";

/**
 * Leads widget fool.
 */
const LeadsWidget = (state) => {
  const [data, setData] = useState(null);
  const [minimize, setMinimize] = useState(false);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    if (Array.isArray(state.widgets?.data?.widgets)) {
      const leadsWidget = state.widgets.data.widgets.find(
        (num) => num.type === "leads"
      );
      if (
        leadsWidget?.dashboards?.dashboard &&
        leadsWidget.dashboards.dashboard.hasOwnProperty("minimized")
      ) {
        setMinimize(leadsWidget.dashboards.dashboard.minimized);
      }
    }
  }, [state.widgets.data]);

  useEffect(() => {
    getPaginatedLeads({
      skip: 0,
      order: "desc",
      orderBy: "created_at",
      limit: 100,
    });
  }, []);

  useEffect(() => {
    if (state.leads?.data) {
      setData(state.leads.data);
    }
  }, [state.leads.data]);

  const _stateCheck = () => {
    return !!data;
  };

  return (
    <div className={`leadsWidgetWrapper`}>
      <div className="leadsWidgetWrapper__leadsWidget">
        <div className="leadsWidgetWrapper__leadsWidget__header">
          <WidgetHeader
            dashboardItems={
              _stateCheck()
                ? [
                    {
                      disabled: !minimize,
                      icon: "maximize",
                      label: tc.maximize,
                      onClick: () => {
                        setMinimize(false);
                        if (Array.isArray(state.widgets?.data?.widgets)) {
                          updateWidgets({
                            widgets: state.widgets.data.widgets.map((num) => {
                              if (
                                num.type === "leads" &&
                                num?.dashboards?.dashboard
                              ) {
                                num.dashboards.dashboard.minimized = false;
                              }
                              return num;
                            }),
                          });
                        }
                      },
                    },
                    {
                      disabled: minimize,
                      icon: "minimize",
                      label: tc.minimize,
                      onClick: () => {
                        setMinimize(true);
                        if (Array.isArray(state.widgets?.data?.widgets)) {
                          updateWidgets({
                            widgets: state.widgets.data.widgets.map((num) => {
                              if (
                                num.type === "leads" &&
                                num?.dashboards?.dashboard
                              ) {
                                num.dashboards.dashboard.minimized = true;
                              }
                              return num;
                            }),
                          });
                        }
                      },
                    },
                  ]
                : null
            }
            headline={tc.leads}
            headlineSub={
              _stateCheck() ? tc.leadsReceived : <Loading small={true} />
            }
          />
        </div>
        {_stateCheck() ? (
          <div
            className={`leadsWidgetWrapper__leadsWidget__content cancelDrag ${
              minimize ? "minimize" : ""
            }`}
          >
            <div className="leadsWidgetWrapper__leadsWidget__content__top">
              <div className="leadsWidgetWrapper__leadsWidget__content__top__info">
                <DisplayValues
                  from={{
                    text: tc.leadsWidgetNew,
                    title: tc.leadsWidgetNewTitle,
                    val: data.latestBatchCount ? data.latestBatchCount : 0,
                  }}
                  to={{
                    text: tc.leadsWidgetHandle,
                    title: tc.leadsWidgetHandleTitle,
                    val: data.totalCount ? data.totalCount : 0,
                  }}
                />
              </div>
              {data?.items?.length ? (
                <div className="leadsWidgetWrapper__leadsWidget__content__top__table">
                  <Table
                    columns={tableHelper.getLeadsWidgetColumns()}
                    //resizable={true}
                    adjustToContainer={true}
                    rows={tableHelper.getLeadsWidgetRows(data.items)}
                    rowsPerPage={5}
                  />
                </div>
              ) : null}
            </div>
            <div className="leadsWidgetWrapper__leadsWidget__content__bottom">
              <NavLink className="cancelDrag" exact to="leads">
                <h4>{tc.exploreLeads}</h4>
                <Icon val="navigateNext" />
              </NavLink>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const MapStateToProps = (state) => {
  return {
    leads: state.leads,
    widgets: state.widgets,
  };
};

export default connect(MapStateToProps)(LeadsWidget);
