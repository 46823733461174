import React from 'react';
import Loading from 'components/loading';
import Icon from 'components/icon';

const ReportsLibrary = ({ reports, setReportId }) => {
  return (
    <div className="pbiEmbeddWrapper">
      <div className="pbiEmbeddWrapper__pbiEmbedd">
        <div className="pbiEmbeddWrapper__pbiEmbedd__reportsLibrary">
          {reports?.length > 0 ? (
            reports.map((report) => (
              <div key={report.id} onClick={() => setReportId(report.id)}>
                <div className="pbiEmbeddWrapper__pbiEmbedd__reportsLibrary__item">
                  <div className="pbiEmbeddWrapper__pbiEmbedd__reportsLibrary__item__headers">
                    <h3>{report.name}</h3>
                  </div>
                  <div className="pbiEmbeddWrapper__pbiEmbedd__reportsLibrary__item__icon">
                    <Icon val="summarize" />
                  </div>
                </div>
              </div>
            ))
          ) : (
            <Loading />
          )}
        </div>
      </div>
    </div>
  );
};

export default ReportsLibrary;
