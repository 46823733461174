import {searchResultsActionTypes} from './actions';

interface SearchResultsState {
    data: object | null,
    query: string | null,
}

const initialState: SearchResultsState = {
    data: null,
    query: null,
};

export const searchResultsReducer = (state = initialState, action) => {
    switch(action.type) {
        case searchResultsActionTypes.SEARCH_RESULTS_SET_DATA: {
            return {
                ...state,
                data: action.payload,
            }
        }
        case searchResultsActionTypes.SEARCH_RESULTS_SET_QUERY: {
            return {
                ...state,
                query: action.payload,
            }
        }
        default: {
            return state;
        }
    }
};
