import { activityHelper } from "helpers";
import LanguageContext from "language-context";
import { useContext } from "react";

/**
 *
 * @param {{types: string[]}} props
 * @description renders a header cell for each column of the table
 */
const TableHeader = ({ types }) => {
  const tc = useContext(LanguageContext);

  return (
    <div className="activityTypesTableWrapper__activityTypesTable__content__tableColumnHeadersWrapper ">
      <div className="activityTypesTableWrapper__activityTypesTable__content__tableColumnHeadersWrapper__tableColumnHeaders">
        <div className="activityTypesTableWrapper__activityTypesTable__content__tableColumnHeadersWrapper__tableColumnHeaders__cell">
          {tc.user}
        </div>
        {types.map((type) => {
          return (
            <div
              key={type}
              className="activityTypesTableWrapper__activityTypesTable__content__tableColumnHeadersWrapper__tableColumnHeaders__cell"
            >
              {activityHelper.getReadableActivity(type, false)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TableHeader;
