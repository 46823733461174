import React, { useContext, useEffect, useRef, useState } from 'react';
import LanguageContext from 'language-context';
import { connect } from 'react-redux';
import { showFlashMessage } from 'store/flash_messages/tasks';
import { getLists } from 'store/lists/tasks';
import {
  addActivity,
  filterColumnsOnSearchQuery,
  getColumnsData,
  getAgileFilter,
  moveItem,
  sortColumns,
  updateAgileFilter,
  updateColumnStructure,
  setPreviewId,
  shareDeal,
} from 'store/agile/tasks';
import sharedAgileHelper from 'shared_helpers/agile_helper';
import { agileHelper, miscHelper } from 'helpers';
import AgileBoardAddActivity from './agile_board_add_activity';
import AgileKanbanBoard from './agile_kanban_board';
import CreateDeal from 'components/create_deal';
import Icon from 'components/icon';
import Info from 'components/info';
import Loading from 'components/loading';
import Menu from 'components/menu';
import PageHeader from 'components/page_header';
import AgileBoardPreview from './agile_board_preview';
import AgileBoardPopup from './popup/agile_board_popup';
import PopupNew from 'components/popup/popup_new';
import SubscriptionFilterDropdown from '../subscription_filter_dropdown';

const AgileBoard = (state) => {
  const [columns, setColumns] = useState(null);
  const [activeFilter, setActiveFilter] = useState([]);
  const [activeLists, setActiveLists] = useState([]);
  const [activeSubscriptions, setActiveSubscriptions] = useState([]);
  const [addActivityItem, setAddActivityItem] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [filtersMapped, setFiltersMapped] = useState(null);
  const [itemToMove, setItemToMove] = useState(null);
  const [listsMapped, setListsMapped] = useState(null);
  const [showCreateDeal, setShowCreateDeal] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const searchInputRef = useRef(null);
  const tc = useContext(LanguageContext);

  const { previewId } = state.agile;
  useEffect(() => {
    getAgileFilter().then(() => {
      getColumnsData({});
      getLists({});
    });
  }, []);

  useEffect(() => {
    // Get deals and prospects.

    // Set active filter.
    if (state.agile.filter && Array.isArray(state.agile.filter)) {
      const lists = [];
      const filter = [];
      state.agile.filter.forEach((num) => {
        if (num.meta.type === 'list' && num.active) {
          lists.push(num.id);
        } else if (num.active) {
          filter.push(num.id);
        }
      });

      setActiveLists(lists);
      setActiveFilter(filter);
    }
  }, [state.agile.filter]);

  useEffect(() => {
    if (state.agile.columns && Array.isArray(state.agile.columns)) {
      state.agile.columns.map((column) => {
        if (column.items) {
          return column.items.map((item) => {
            // Add a flag to adjust styling for current preview item.
            item.openInPreview =
              (item.id && item.id === state.agile.previewId) ||
              (item.prospectId &&
                `${item.prospectId}/${item.listId}` === state.agile.previewId);

            return item;
          });
        }
      });

      setColumns(state.agile.columns);
    }
  }, [state.agile.columns, state.agile.previewId]);

  useEffect(() => {
    const filters = sharedAgileHelper
      .getDefaultFilters()
      .filter((filter) => filter.id !== 'include_colleagues');

    const active = filters
      .filter((filter) => activeFilter.includes(filter.id))
      .sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        } else {
          return 0;
        }
      })
      .map((filter) => {
        return {
          active: true,
          label: filter.name,
          onClick: async () => {
            await updateAgileFilter({
              id: filter.id,
              name: filter.name,
              type: 'default',
            });
          },
        };
      });

    const unactive = filters
      .filter((filter) => !activeFilter.includes(filter.id))
      .sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        } else {
          return 0;
        }
      })
      .map((filter) => {
        return {
          active: false,
          label: filter.name,
          onClick: async () => {
            await updateAgileFilter({
              id: filter.id,
              name: filter.name,
              type: 'default',
            });
          },
        };
      });

    setFiltersMapped(active.concat(unactive));
  }, [activeFilter]);

  useEffect(() => {
    if (state.lists?.lists && Array.isArray(state.lists.lists)) {
      const active = state.lists.lists
        .filter((list) => activeLists.includes(list.id))
        .sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          } else {
            return 0;
          }
        })
        .map((list) => {
          return {
            active: true,
            label: list.name,
            onClick: async () => {
              await updateAgileFilter({
                id: list.id,
                name: list.name,
                type: 'list',
              });
            },
          };
        });
      const unactive = state.lists.lists
        .filter((list) => !activeLists.includes(list.id))
        .sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          } else {
            return 0;
          }
        })
        .map((list) => {
          return {
            active: false,
            label: list.name,
            onClick: async () => {
              await updateAgileFilter({
                id: list.id,
                name: list.name,
                type: 'list',
              });
            },
          };
        });

      setListsMapped(active.concat(unactive));
    }
  }, [state.lists, activeLists]);

  useEffect(() => {
    searchInputRef && searchInputRef.current && searchInputRef.current.focus();
  }, [showSearch]);

  useEffect(() => {
    /**
     * Close search when clicking outside input.
     */
    const _closeSearch = (e) => {
      if (miscHelper.clickUnmount(e, searchInputRef, false)) {
        setShowSearch(false);
        filterColumnsOnSearchQuery('');
      }
    };

    window.addEventListener('mousedown', _closeSearch);
    return () => {
      window.removeEventListener('mousedown', _closeSearch);
    };
  }, []);

  /**
   * Can be executed from <AgileBoardAddActivity/> or from _moveItem(), depending on if we are simultaneously moving an item and adding an activity.
   * @param payload.action
   * @param payload.comment
   * @param payload.dealId - string (optional) - When executed from _moveItem().
   * @param payload.event_date
   * @param payload.performed - bool - Historic/performed or planned activity.
   */
  const _addActivity = async (payload) => {
    if (!payload.performed && payload.action === 'meeting') {
      addActivity({
        action: 'meetingPlanned',
        comment: payload.comment,
        dealId: payload.dealId ? payload.dealId : addActivityItem,
        event_date: payload.event_date,
        performed: true,
      });
    }
    addActivity({
      action: payload.action,
      comment: payload.comment,
      dealId: payload.dealId ? payload.dealId : addActivityItem,
      event_date: payload.event_date,
      performed: payload.performed,
    });

    setAddActivityItem(null);
    setActiveItem(null);
    return;
  };

  /**
   * Set column to new index and update.
   */
  const _handleColumnDragEnd = async (removedIndex, addedIndex, payload) => {
    try {
      let allColumns = state.agile.columns;
      const originalIndex = allColumns.findIndex((x) => payload.id === x.id);
      allColumns.splice(originalIndex, 1);
      allColumns.splice(addedIndex, 0, payload);

      // No columns with same id, check.
      allColumns = columns.filter((column, i, self) => {
        const index = self.findIndex((x) => x.id === column.id);
        return i === index;
      });

      setColumns(allColumns);
      return await updateColumnStructure(allColumns);
    } catch (err) {
      console.error('Error when moving column', err);
      return showFlashMessage(tc.couldNotMoveColumn, 'fail');
    }
  };

  /**
   * Set item and trigger AgileBoardAddActivity which handles the item update via _moveItem().
   */
  const _handleItemDragEnd = async (item, source, target) => {
    if (!item || !target || !source || (!item.prospectId && !item.id)) {
      console.error('Missing params in _handleItemDragEnd: ', {
        item,
        target,
        source,
      });
      return showFlashMessage(tc.genericFailMessage, 'fail');
    }

    // Set item to move.
    setItemToMove({
      item: item,
      source: source,
      target: target,
    });
    // Prompts AgileBoardAddActivity popup.
    setAddActivityItem(item.prospectId ? item.prospectId.toString() : item.id);
    setActiveItem(item);
  };

  /**
   * Update the phase of a deal.
   * Executed from <AgileBoardAddActivity/>.
   *
   * @param payload.addActivity - bool - Set to true when also adding activity after move.
   * @param payload.addActivityObject - object - Sent forward to _addActivity() if addActivity is true.
   */
  const _moveItem = async (payload) => {
    try {
      let listId = null;
      if (itemToMove.item.listId) {
        listId = itemToMove.item.listId;
      } else if (itemToMove.item.meta && itemToMove.item.meta.moved_from_list) {
        listId = itemToMove.item.meta.moved_from_list;
      }

      let prospectId;
      if (payload.itemType === 'deal') prospectId = itemToMove.item.prospect_id;
      else if (payload.itemType === 'prospect')
        prospectId = itemToMove.item.prospectId;
      // if (
      //   itemToMove.item.prospect_id &&
      //   Array.isArray(itemToMove.item.prospects)
      // ) {
      //   prospectIds = itemToMove.item.prospects.join(",");
      // }
      const dealId = await moveItem({
        id: itemToMove.item.id
          ? itemToMove.item.id
          : itemToMove.item.prospectId.toString(),
        itemType: itemToMove.item.itemType,
        listId: listId,
        prospectId: prospectId,
        prospectSource: itemToMove.item.prospectSource || null,
        target: itemToMove.target,
        source: itemToMove.source,
      });

      if (payload.addActivity) {
        payload.addActivityObject.dealId = dealId; // If a prospect was moved, a deal has been created with a new id.
        await _addActivity(payload.addActivityObject);
      } else {
        setAddActivityItem(null);
        setActiveItem(null);
      }

      return setItemToMove(null);
    } catch (err) {
      return showFlashMessage(tc.couldNotMoveItem, 'fail');
    }
  };

  const subProp =
    state.agile.filter
      ?.filter((obj) => obj.active && obj.meta?.type === 'subscription')
      .map((obj) => obj.id) ?? [];

  const _stateCheck = () => {
    return !!(state && state.agile && columns);
  };

  return _stateCheck() ? (
    <div className="agileBoardWrapper">
      <div className="agileBoardWrapper__agileBoard">
        <div className="agileBoardWrapper__agileBoard__header">
          <div className="agileBoardWrapper__agileBoard__header__top">
            <PageHeader />
          </div>
          <div className="agileBoardWrapper__agileBoard__header__bottom">
            <Menu
              items={[
                filtersMapped
                  ? {
                      checkboxes: true,
                      label: tc.filter,
                      items: filtersMapped,
                      type: 'dropdown',
                    }
                  : {
                      element: <Loading small={true} />,
                      type: 'element',
                    },
                listsMapped
                  ? {
                      checkboxes: true,
                      label: tc.lists,
                      items: listsMapped,
                      type: 'dropdown',
                    }
                  : {
                      element: <Loading small={true} />,
                      type: 'element',
                    },
                {
                  element: (
                    <SubscriptionFilterDropdown
                      subscriptionFilters={subProp}
                      agile={true}
                    />
                  ),
                  type: 'element',
                },
                {
                  checkboxes: true,
                  label: tc.sort,
                  items: agileHelper.getColumnSortValues.map((num) => {
                    return {
                      active: state.agile.sort === num,
                      label: tc[num],
                      onClick: () => {
                        sortColumns({ sort: num }).then(() => {
                          getColumnsData({});
                        });
                      },
                    };
                  }),
                  type: 'dropdown',
                },
                {
                  label: tc.createNewDeal,
                  onClick: () => {
                    setShowCreateDeal(true);
                  },
                  type: 'button',
                },
              ]}
              skipSort={true}
            />
          </div>
        </div>
        <div className="agileBoardWrapper__agileBoard__content">
          <div className="agileBoardWrapper__agileBoard__content__left">
            <div className="agileBoardWrapper__agileBoard__content__left__top">
              <div className="agileBoardWrapper__agileBoard__content__left__top__colorExplanation">
                <div className="agileBoardWrapper__agileBoard__content__left__top__colorExplanation__item event">
                  <div className="circle" />
                  {tc.event}
                </div>
                <div className="agileBoardWrapper__agileBoard__content__left__top__colorExplanation__item eventWithinOneDay">
                  <div className="circle" />
                  {tc.eventWithinOneDay}
                </div>
                <div className="agileBoardWrapper__agileBoard__content__left__top__colorExplanation__item eventPassedDate">
                  <div className="circle" />
                  {tc.eventPassedDate}
                </div>
              </div>
              <div className="agileBoardWrapper__agileBoard__content__left__top__search">
                {showSearch ? (
                  <input
                    className={showSearch ? null : 'minimize'}
                    onChange={(e) => {
                      filterColumnsOnSearchQuery(e.target.value);
                    }}
                    ref={searchInputRef}
                    type="text"
                  />
                ) : null}
                <Icon
                  val="search"
                  onClick={() => {
                    setShowSearch(true);
                  }}
                />
              </div>
            </div>
            <div className="agileBoardWrapper__agileBoard__content__left__bottom">
              {columns.filter(
                (column) =>
                  !!(column && column.items && column.items.length).length > 0
              ) ? (
                <AgileKanbanBoard
                  addActivity={(id, item) => {
                    setAddActivityItem(id);
                    setActiveItem(item);
                  }}
                  allProspectsReceived={state.agile.allProspectsReceived}
                  columns={columns}
                  handleColumnDragEnd={_handleColumnDragEnd}
                  handleItemDragEnd={_handleItemDragEnd}
                />
              ) : (
                <div className="agileBoardWrapper__agileBoard__content__left__bottom__infobox">
                  <Info>
                    <h4>{tc.noAgile}</h4>
                    <p>{tc.noAgileWhy1}</p>
                    <p>{tc.noAgileWhy2}</p>
                  </Info>
                </div>
              )}
            </div>
          </div>
          <div>
            {/* {previewId ? (
              <AgileBoardPreview
                id={
                  // If preview id is set from prospect column, it's string in this format: "prospectId/listId".
                  // Only provide prospect id.
                  typeof previewId === 'string' && previewId.indexOf('/') > 0
                    ? +previewId.substring(0, previewId.indexOf('/'))
                    : previewId
                }
              />
            ) : null} */}
            {/* {itemOpenInPreview ? (
              <AgileBoardPreviewPopup
                close={() => setPreviewId(null)}
                id={
                  // If preview id is set from prospect column, it's string in this format: "prospectId/listId".
                  // Only provide prospect id.
                  typeof itemOpenInPreview === "string" &&
                  itemOpenInPreview.indexOf("/") > 0
                    ? +itemOpenInPreview.substring(
                        0,
                        itemOpenInPreview.indexOf("/")
                      )
                    : itemOpenInPreview
                }
                previewData={state.agile.previewData}
              />
            ) : null} */}
            {previewId ? (
              <AgileBoardPopup
                id={
                  // If preview id is set from prospect column, it's string in this format: "prospectId/listId".
                  // Only provide prospect id.
                  typeof previewId === 'string' && previewId.indexOf('/') > 0
                    ? +previewId.substring(0, previewId.indexOf('/'))
                    : previewId
                }
                previewData={state.agile.previewData}
              />
            ) : null}
            {/* {itemOpenInPreview ? (
              <Popup
                noPadding={false}
                close={() => setPreviewId(null)}
                size="big"
              >
                <AgileBoardPopup
                  id={
                    // If preview id is set from prospect column, it's string in this format: "prospectId/listId".
                    // Only provide prospect id.
                    typeof itemOpenInPreview === 'string' &&
                    itemOpenInPreview.indexOf('/') > 0
                      ? +itemOpenInPreview.substring(
                          0,
                          itemOpenInPreview.indexOf('/')
                        )
                      : itemOpenInPreview
                  }
                  previewData={state.agile.previewData}
                />
              </Popup>
            ) : null} */}
          </div>
        </div>
        {!previewId && showCreateDeal ? (
          <CreateDeal
            close={(dealCreated) => {
              // If deal is created we reload company data.
              if (dealCreated) {
                setShowCreateDeal(false);
                return getColumnsData({});
              } else {
                return setShowCreateDeal(false);
              }
            }}
            koncern={false}
          />
        ) : null}
        {!previewId && addActivityItem ? (
          <AgileBoardAddActivity
            allowPlanned={true}
            addActivity={_addActivity}
            close={() => {
              setAddActivityItem(null);
              setItemToMove(null);
              setActiveItem(null);
            }}
            isMoving={!!itemToMove}
            moveItem={_moveItem}
            activeItem={activeItem}
            shareDeal={async (payload) => {
              const { targetShareUser, action, comment, event_date } = payload;
              if (Boolean(itemToMove)) {
                const dealId = itemToMove.item?._id;
                const prospectId = itemToMove.item?.prospectId;
                const prospectSource = itemToMove.item?.prospectSource || null;
                const listId = itemToMove.item?.listId;

                await shareDeal({
                  dealId,
                  prospectId,
                  prospectSource,
                  newOwnerId: targetShareUser.userId,
                  newDealerId: targetShareUser.dealerId,
                  action,
                  comment,
                  event_date,
                  listId,
                  type: itemToMove.itemType,
                });
              } else {
                const dealId = activeItem._id;
                const listId = activeItem.meta?.moved_from_list || null;
                const prospectId = activeItem.prospects?.[0];
                const prospectSource = activeItem.prospectSource || null;
                await shareDeal({
                  dealId,
                  prospectId,
                  prospectSource,
                  newOwnerId: targetShareUser.userId,
                  newDealerId: targetShareUser.dealerId,
                  action,
                  comment,
                  event_date,
                  listId,
                  type: activeItem.itemType,
                });
              }

              setAddActivityItem(null);
              setItemToMove(null);
            }}
          />
        ) : null}
      </div>
    </div>
  ) : (
    <Loading />
  );
};

const MapStateToProps = (state) => {
  return {
    agile: state.agile,
    lists: state.lists,
    user: state.user,
    contracts: state.company.contracts,
  };
};

export default connect(MapStateToProps)(AgileBoard);
