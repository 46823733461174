import Dexie from 'dexie';
import { dbName, idb } from 'idb';
import { textHelper } from 'helpers';

export const idbHelper = {
  addDataToProspectStores: async (data) => {
    try {
      if (data && typeof data === 'object' && Object.keys(data).length) {
        await idb.open();

        if (!idb.isOpen()) {
          return console.error('Could not open idb in addDataToProspectStores');
        }

        await Promise.all(
          Object.keys(data).map(async (key) => {
            if (data[key]?.length) {
              await idb[key].clear();
              await idb[key].bulkAdd(data[key]).catch((e) => {
                let err = 'Error adding data in addDataToProspectStores.';
                if (
                  e?.failures?.length &&
                  data &&
                  data[key] &&
                  data[key].length
                ) {
                  err +=
                    ' No of failures: ' +
                    e.failures.length +
                    ' of total: ' +
                    data[key].length +
                    ' for ' +
                    key;
                }

                console.error(err);
              });
            }
          })
        );
      }
    } catch (err) {
      return console.error('Generic error in addDataToProspectStores', err);
    }
  },
  // Not used at the moment, but keep.
  // checkProspectStores: async () => {
  //   return new Promise((resolve, reject) => {
  //     Dexie.exists(dbName)
  //       .then(async (exists) => {
  //         if (!exists) {
  //           return resolve(false);
  //         }
  //         const stores = [
  //           "brands",
  //           "companyTypes",
  //           "dealerSalesmen",
  //           "leasingOwners",
  //           "regions",
  //           "sellers",
  //         ];
  //         // if dealer gets DI, then add the dealerSalesmen store to the array: stores
  //         if (getUserAccess().customerData) stores.push("dealerSalesmen");
  //         let storeMissing = false;
  //
  //         // Check that all stores exists and that we have data in them.
  //         await Promise.all(
  //           idb.tables.map(async (table) => {
  //             await new Promise((resolve, reject) => {
  //               table.count().then((val) => {
  //                 if (!stores.includes(table.name) || val < 9) {
  //                   storeMissing = true;
  //                 }
  //                 resolve();
  //               });
  //             });
  //           })
  //         );
  //
  //         resolve(!storeMissing);
  //       })
  //       .catch((err) => {
  //         console.error("Error in checkProspectStores", err);
  //         resolve(false);
  //       });
  //   });
  // },
  deleteDb: async () => {
    return new Promise((resolve, reject) => {
      Dexie.exists(dbName)
        .then(async (exists) => {
          if (!exists) {
            return resolve();
          }

          setTimeout(() => {
            resolve(Dexie.delete(dbName));
          }, 200);
        })
        .catch((err) => {
          console.error('Error in deleteDb', err);
          resolve(false);
        });
    });
  },
  getBrandsByTypes: async (types) => {
    try {
      if (!types?.length || !idb?.brands) {
        return [];
      }
      if (!idb.isOpen()) {
        return [];
      }

      const carTypes = [];
      for (let i = 0; i < types.length; i++) {
        const carType = await idb.brands.get(types[i].toUpperCase());
        carTypes.push(carType);
      }
      return carTypes;
    } catch (err) {
      return console.error('Error in getBrandsByTypes', err);
    }
  },
  getDealerSalesmen: async () => {
    try {
      if (!idb?.dealerSalesmen) {
        return [];
      }
      let result = await idb.dealerSalesmen.toArray();
      return Array.isArray(result) ? result : [];
    } catch (err) {
      console.error('Error in idb_helper.getDealerSalesmen', err);
      return [];
    }
  },
  getLkpTree: async () => {
    try {
      if (!idb?.regions) {
        return [];
      }
      let result = await idb.regions.toArray();
      return result;
    } catch (err) {
      return console.error('Error in idb_helper.getRegionLkp', err);
    }
  },
  getModelsByBrands: async (types, brands) => {
    try {
      if (!brands?.length || !idb?.brands || !idb.isOpen()) {
        return [];
      }

      const carTypes = [];
      for (let i = 0; i < types.length; i++) {
        const carType = await idb.brands.get(types[i].toUpperCase());
        carTypes.push(carType);
      }

      const models = carTypes.reduce((sum, num) => {
        const children = num.children.filter((x) => brands.includes(x.val));
        return sum.concat(children.map((x) => x.children));
      }, []);

      return models.flat();
    } catch (err) {
      console.error('Error in getModelsByBrands', err);
      return [];
    }
  },
  getStoreData: async (payload) => {
    try {
      if (!idb.isOpen()) {
        return [];
      }

      switch (payload.store) {
        case 'brands':
          if (!idb?.brands) {
            return [];
          }
          return await idb.brands.toArray();
        case 'companyTypes':
          if (!idb?.companyTypes) {
            return [];
          }
          return await idb.companyTypes.toArray();
        case 'dealerSalesmen':
          if (!idb?.dealerSalesmen) {
            return [];
          }
          return await idb.dealerSalesmen.toArray();
        case 'leasingOwners':
          if (!idb?.leasingOwners) {
            return [];
          }
          return await idb.leasingOwners.toArray();
        case 'regions':
          if (!idb?.regions) {
            return [];
          }
          return await idb.regions.toArray();
        case 'sellers':
          if (!idb?.sellers) {
            return [];
          }
          return await idb.sellers.toArray();
        default:
          return [];
      }
    } catch (err) {
      console.error('Error in searchStore', err);
      return [];
    }
  },
  getStoreObject: async (payload) => {
    try {
      if (!idb.isOpen()) {
        return [];
      }

      switch (payload.store) {
        case 'brands':
          if (!idb?.brands) {
            return [];
          }
          return await idb.brands.get(payload.val);
        case 'companyTypes':
          if (!idb?.companyTypes) {
            return [];
          }
          return await idb.companyTypes.get(payload.val);
        case 'dealerSalesmen':
          if (!idb?.dealerSalesmen) {
            return [];
          }
          return await idb.dealerSalesmen.get(payload.val);
        case 'leasingOwners':
          if (!idb?.leasingOwners) {
            return [];
          }
          return await idb.leasingOwners.get(payload.val);
        case 'regions':
          if (!idb?.regions) {
            return [];
          }
          return await idb.regions.get(payload.val);
        case 'sellers':
          if (!idb?.sellers) {
            return [];
          }
          return await idb.sellers.get(payload.val);
        default:
          return [];
      }
    } catch (err) {
      console.error('Error in getStoreObject', err);
      return null;
    }
  },
  /**
   * Return search results from store based on query.
   * Note that we base search on different properties in different stores.
   *
   * @param payload.store - string - Store to search
   * @param payload.query - string - Search query.
   */
  searchStore: async (payload) => {
    try {
      if (!idb.isOpen()) {
        return [];
      }

      const query = textHelper.removeDashFromOrgnr(payload.query);
      const regex = new RegExp(query, 'ig');
      const limit = 20;

      switch (payload.store) {
        case 'companyTypes':
          if (!idb?.companyTypes) {
            return [];
          }
          return await idb.companyTypes
            .filter((x) => regex.test(x.val))
            .limit(limit)
            .toArray();
        case 'dealerSalesmen':
          if (!idb?.dealerSalesmen) {
            return [];
          }
          return await idb.dealerSalesmen
            .filter((x) => regex.test(x.text))
            .limit(limit)
            .toArray();
        case 'leasingOwners':
          if (!idb?.leasingOwners) {
            return [];
          }
          return await idb.leasingOwners
            .filter((x) => regex.test(x.text))
            .limit(limit)
            .toArray();
        case 'regions':
          if (!idb?.regions) {
            return [];
          }
          return await idb.regions
            .filter((x) => regex.test(x.text))
            .limit(limit)
            .toArray();
        case 'sellers':
          if (!idb?.sellers) {
            return [];
          }
          return await idb.sellers
            .filter((x) => regex.test(x.text))
            .limit(limit)
            .toArray();
        default:
          return [];
      }
    } catch (err) {
      console.error('Error in searchStore', err);
      return [];
    }
  },
};
