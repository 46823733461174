import React from 'react';
import cssVariables from '../../styles/_exported_variables.module.scss';
import {
  Dropdown,
  DropdownItem,
  DropdownItemDelimiter,
  DropdownItemDelimiterWithCheckbox,
  DropdownItemWithDeleteButton,
} from 'components/dropdown';
import Icon from 'components/icon';

const MenuDropdown = (props) => {
  return (
    <div className="menuDropdownWrapper">
      <div
        className={
          props.disabled
            ? 'menuDropdownWrapper__menuDropdown disabled'
            : props.active
              ? 'menuDropdownWrapper__menuDropdown active'
              : 'menuDropdownWrapper__menuDropdown'
        }
      >
        {props.disabled ? (
          <>{props.label}</>
        ) : (
          <>
            <Dropdown
              displayValue={props.label}
              source="menu"
              styling={{
                extraWidth: cssVariables.paddingBig.replace('px', '') * 1.5 * 2,
                offsetLeft: -(
                  cssVariables.paddingBig.replace('px', '') * 1.5 -
                  2
                ),
                offsetTop: 10,
              }}
              transparent={true}
              autoClose={props.autoClose}
            >
              {props.items.map((item, i) => {
                if (item?.type === 'delimiter') {
                  return (
                    <DropdownItemDelimiter
                      key={item.label}
                      label={item.label}
                    />
                  );
                } else if (item?.type === 'delimiterWithCheckbox') {
                  return (
                    <DropdownItemDelimiterWithCheckbox
                      active={item.active}
                      key={item.label}
                      label={item.label}
                      onClick={() => {
                        item.onClick();
                      }}
                    />
                  );
                } else if (item?.type === 'withDeleteButton') {
                  return (
                    <DropdownItemWithDeleteButton
                      active={item.active}
                      key={item.label}
                      label={item.label}
                      onClick={() => {
                        item.onClick();
                      }}
                      onDelete={item.onDelete}
                      hideCheckbox={true}
                    />
                  );
                } else {
                  return (
                    <DropdownItem
                      active={item.active}
                      disabled={item.disabled}
                      key={i}
                      hideCheckbox={!props.checkboxes}
                      label={item.label}
                      onClick={() => {
                        if (!item.disabled) {
                          item.onClick();
                        }
                      }}
                    />
                  );
                }
              })}
            </Dropdown>
            {props.icon ? <Icon val={props.icon} /> : null}
          </>
        )}
      </div>
    </div>
  );
};

export default MenuDropdown;
