import React, { useContext, useEffect, useRef, useState } from "react";
import LanguageContext from "language-context";
import { activityHelper, miscHelper } from "helpers";
import { NavLink } from "react-router-dom";
import ActionButton from "components/action_button";
import EventsFlowItem from "./events_flow_item";
import Info from "../info";

/**
 * Render events in flow list.
 *
 * @param props.activityTypesToDisplay
 * @param props.amountIncrease
 * @param props.data
 * @param props.target
 * @param props.type
 * @param props.user - user object from user reducer.
 */
const EventsFlow = (props) => {
  const amountIncrease = props.amountIncrease ? props.amountIncrease : 5;
  const [dataLength, setDataLength] = useState(null); // Used to know when we have rendered all rows.
  const [displayAmountRows, setDisplayAmountRows] = useState(amountIncrease);
  const [events, setEvents] = useState(null);
  const [eventsHidden, setEventsHidden] = useState(null); // Used to render a hidden element to calculate heights.
  const [showInfo, setShowInfo] = useState(false);
  const [userTouched, setUserTouched] = useState(false);
  const observer = useRef(null);
  const eventsFlowRef = useRef(null);
  const eventsFlowItemsRef = useRef(null);
  const eventsFlowItemsHiddenRef = useRef(null);
  const eventsFlowItemsVisibleRef = useRef(null);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    _setResponsive();
    observer.current = new ResizeObserver(_setResponsive);
    observer.current.observe(eventsFlowRef.current);

    const ref = eventsFlowRef.current;

    return () => observer.current.unobserve(ref);
  }, [dataLength]);

  useEffect(() => {
    setShowInfo(!events?.length || !props.activityTypesToDisplay?.length);
  }, [events, props.activityTypesToDisplay]);

  useEffect(() => {
    if (!showInfo) {
      _setResponsiveDisplayAmountRows();
    }
  }, [eventsHidden, showInfo]);

  useEffect(() => {
    _setEvents();
  }, [displayAmountRows, props.data, props.user, props.activityTypesToDisplay]);

  const _renderEventItem = (event, i) => {
    if (!event.action || event.action === "") {
      return null;
    }

    // Action
    let action;
    if (props.target && props.target === event.dealId) {
      // Showing events for specific target, no link needed.
      action = (
        <div className="readableActivity">
          {activityHelper.getReadableActivity(event.action, true)}
        </div>
      );
    } else {
      // Add a link to action description.
      action = (
        <div className="readableActivity">
          {activityHelper.getReadableActivity(event.action, true)}{" "}
          {activityHelper.getPreposition(event.action).toLowerCase()}{" "}
          <NavLink exact to={"/affar/" + event.dealId} key="affar">
            {event.name ? event.name : tc.nameMissing}
          </NavLink>
        </div>
      );
    }

    // Comment
    const comment = event.comment ? event.comment : null;

    // Date
    const date = event.event_date ? event.event_date : null;

    // Deal id
    const dealId = event.deal_id;

    // Event id
    const eventId = event.event_id;

    // Icon
    // const icon = <Icon val={event.action} />;

    return (
      <EventsFlowItem
        action={action}
        actionType={event.action}
        comment={comment}
        date={date}
        dealId={dealId}
        eventId={eventId}
        // icon={icon}
        // index={i}
        isEditable={props.user.info.id === event.user_id}
        user={event.user}
      />
    );
  };

  const _setEvents = () => {
    if (!props.activityTypesToDisplay.length || !Array.isArray(props.data)) {
      return;
    }

    let data = props.data.filter((num) => {
      return !!props.activityTypesToDisplay.includes(num.action);
    });

    if (!data || !data?.length) {
      setDataLength(0);
      return setEvents([]);
    }

    // Set data length before slice.
    const _dataLength = data.length;
    setDataLength(_dataLength);

    let dataHidden = JSON.parse(JSON.stringify(data));
    data = data.slice(0, displayAmountRows);

    setEvents(
      data.map((num, i) => {
        return (
          <React.Fragment key={i}>{_renderEventItem(num, i)}</React.Fragment>
        );
      })
    );

    if (displayAmountRows < _dataLength) {
      setEventsHidden(
        dataHidden.map((num, i) => {
          return (
            <React.Fragment key={i}>{_renderEventItem(num, i)}</React.Fragment>
          );
        })
      );
    }
  };

  /**
   * Do adjustments based on parent element size.
   */
  const _setResponsive = () => {
    _setResponsiveDisplayAmountRows();
  };

  /**
   * Check height of content and adjust how many items we display.
   *
   * (Important that styling is set correctly for this to work. Content holder should stretch but not overflow.)
   */
  const _setResponsiveDisplayAmountRows = () => {
    if (userTouched) {
      // If user has clicked "Hämta fler"-button we should not adjust items.
      return;
    }

    const calculation = miscHelper.calculateHeightAndItems(
      eventsFlowItemsRef?.current,
      eventsFlowItemsVisibleRef?.current?.children,
      eventsFlowItemsHiddenRef?.current?.children
    );

    if (calculation.itemsExtra > 0) {
      const newValue = displayAmountRows + calculation.itemsExtra;
      setDisplayAmountRows(newValue > dataLength ? dataLength : newValue);
    } else if (calculation.itemsRemove > 0 && displayAmountRows > 1) {
      const newValue = displayAmountRows - calculation.itemsRemove;
      setDisplayAmountRows(newValue > 1 ? newValue : 1);
    }
  };

  return props.data ? (
    <div className="eventsFlowWrapper" ref={eventsFlowRef}>
      <div className="eventsFlowWrapper__eventsFlow">
        <div className="eventsFlowWrapper__eventsFlow__content">
          {showInfo ? (
            <Info>
              <h4>{tc.noEvents}</h4>
              <p>{tc.noEventsWhy}</p>
            </Info>
          ) : (
            <>
              <div
                className="eventsFlowWrapper__eventsFlow__content__top"
                ref={eventsFlowItemsRef}
              >
                <div
                  className="eventsFlowWrapper__eventsFlow__content__top__items"
                  ref={eventsFlowItemsVisibleRef}
                >
                  {events}
                </div>
              </div>
              <div
                className="eventsFlowWrapper__eventsFlow__content__top__itemsHidden"
                ref={eventsFlowItemsHiddenRef}
              >
                {eventsHidden}
              </div>
              {dataLength > amountIncrease ? (
                <div className="eventsFlowWrapper__eventsFlow__content__bottom">
                  {userTouched ? (
                    <ActionButton
                      icon="reset"
                      label={tc.reset}
                      onClick={() => {
                        setUserTouched(false);
                        setDisplayAmountRows(amountIncrease);
                      }}
                      type="regular"
                    />
                  ) : null}
                  {dataLength && displayAmountRows < dataLength ? (
                    <ActionButton
                      icon="load"
                      label={tc.getMore}
                      onClick={() => {
                        setUserTouched(true);
                        setDisplayAmountRows(
                          displayAmountRows + amountIncrease
                        );
                      }}
                      type="regular"
                    />
                  ) : null}
                </div>
              ) : null}
            </>
          )}
        </div>
      </div>
    </div>
  ) : null;
};

export default EventsFlow;
