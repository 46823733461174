import React, { useContext, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import LanguageContext from "language-context";
import { getWidgets, removeWidget, updateWidgets } from "store/widgets/tasks";
import ActionButton from "components/action_button";
import Icon from "components/icon";
import Info from "components/info";
import ToggleOption from "components/toggle_option";
import { showFlashMessage } from "store/flash_messages/tasks";
import { chartHelper } from "helpers";
import { widgetHelper } from "helpers/widget_helper";

/**
 * Settings analyse section.
 */
const SettingsAnalyse = (state) => {
  const [widgetToEdit, setWidgetToEdit] = useState(null);
  const [widgetToRemove, setWidgetToRemove] = useState(null);
  const [widgets, setWidgets] = useState(null);
  const editWidgetInputRef = useRef(null);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    getWidgets();
  }, []);

  useEffect(() => {
    if (Array.isArray(state.widgets?.data?.widgets)) {
      setWidgets(
        JSON.parse(JSON.stringify(state.widgets.data.widgets))
          .filter((num) => num.dashboards.hasOwnProperty("analyse"))
          .sort((a, b) => {
            if (a.name && b.name) {
              return a.name > b.name ? 1 : -1;
            } else {
              return 0;
            }
          })
      ); // Clone to avoid problems when sorting.
    } else {
      setWidgets(null);
    }
  }, [state.widgets.data]);

  useEffect(() => {
    if (widgetToEdit?.name) {
      editWidgetInputRef &&
        editWidgetInputRef.current &&
        editWidgetInputRef.current.focus();
    }
  }, [widgetToEdit]);

  const _renderWidgetRow = (widget) => {
    const active = widget.dashboards.analyse.active;
    let labelSub = tc[widget.type];

    switch (widget.type) {
      case "monitorList":
        labelSub = tc.defaultWidgetMonitorListName;
        break;
      case "news":
        labelSub = tc.defaultWidgetNewsName;
        break;
    }

    const isRemovable = !widgetHelper.defaultWidgetTypes.includes(widget.type);

    return (
      <div key={widget._id} className="toggleOptionsHolder__item">
        <div className="toggleOptionsHolder__item__left">
          {isRemovable ? (
            <div className="iconHolder">
              <Icon
                val="edit"
                onClick={() => {
                  setWidgetToEdit(widget);
                }}
              />
            </div>
          ) : null}
        </div>
        <div className="toggleOptionsHolder__item__right">
          <ToggleOption
            active={active}
            label={widget.name}
            labelSub={labelSub}
            onClick={() => {
              _showOrHideWidget(widget);
            }}
          />
        </div>
      </div>
    );
  };

  const _saveEditWidget = async () => {
    let newWidgets = JSON.parse(JSON.stringify(state.widgets.data.widgets));
    newWidgets = newWidgets.map((num) => {
      if (num._id === widgetToEdit._id) {
        num.name = widgetToEdit.name;
      }

      return num;
    });

    await updateWidgets({ widgets: newWidgets });
    setWidgetToEdit(null);
    showFlashMessage(tc.changesSaved, "success");
  };

  const _showOrHideWidget = async (payload) => {
    let newWidgets = JSON.parse(JSON.stringify(state.widgets.data.widgets));

    newWidgets = newWidgets.map((num) => {
      if (num._id === payload._id) {
        if (num.dashboards.hasOwnProperty("analyse")) {
          num.dashboards.analyse.active = !num.dashboards.analyse.active;
          num.dashboards.analyse.position = state.widgets?.data?.widgets?.length
            ? state.widgets.data.widgets.filter((num) =>
                num.dashboards.hasOwnProperty("analyse")
              ).length
            : 0; // Place last to avoid duplicate position values.
        } else {
          // If widget isn't part of this dashboard, add it.
          num.dashboards.analyse = {
            active: true,
            minimized: false,
            position: state.widgets?.data?.widgets?.length
              ? state.widgets.data.widgets.filter((num) =>
                  num.dashboards.hasOwnProperty("analyse")
                ).length
              : 0, // Place last to avoid duplicate position values.
          };
        }
      }

      return num;
    });

    await updateWidgets({ widgets: newWidgets });
  };

  const _stateCheck = () => {
    return Array.isArray(widgets);
  };

  return _stateCheck() ? (
    <div className="settingsPageWrapper">
      <div className="settingsPageWrapper__settingsPage">
        <div
          className="settingsPageWrapper__settingsPage__section"
          id="toggleWidgetsAnalyse"
        >
          <div className="settingsPageWrapper__settingsPage__section__header">
            {tc.toggleWidgets}
          </div>
          <div className="settingsPageWrapper__settingsPage__section__content">
            {widgets.length ? (
              <div className="settingsPageWrapper__settingsPage__section__content__holder marginBottom">
                <p>{tc.manageWidgetsGeneric}</p>
                <p>{tc.manageAnalyseWidgets}</p>
              </div>
            ) : null}
            {widgets.length ? (
              widgetToRemove ? (
                <div className="settingsPageWrapper__settingsPage__section__content__holder">
                  <div className="inputHolder">
                    <div className="inputHolder__left">
                      {tc.removeWidget}: <strong>{widgetToRemove.name}</strong>
                    </div>
                    <div className="inputHolder__right">
                      <ActionButton
                        label={tc.cancel}
                        onClick={() => {
                          setWidgetToRemove(null);
                        }}
                        type="regular"
                      />
                      <ActionButton
                        label={tc.remove}
                        onClick={async () => {
                          await removeWidget({ id: widgetToRemove._id });
                          setWidgetToRemove(null);
                          setWidgetToEdit(null);
                        }}
                        type="highlight"
                      />
                    </div>
                  </div>
                </div>
              ) : widgetToEdit?._id && widgetToEdit?.name ? (
                <div className="settingsPageWrapper__settingsPage__section__content__holder">
                  <div className="inputHolder">
                    <div className="inputHolder__left">
                      <input
                        onChange={(e) => {
                          setWidgetToEdit({
                            ...widgetToEdit,
                            name: e.target.value,
                          });
                        }}
                        ref={editWidgetInputRef}
                        type="text"
                        value={widgetToEdit.name}
                      />
                    </div>
                    <div className="inputHolder__right">
                      <ActionButton
                        label={tc.cancel}
                        onClick={() => {
                          setWidgetToEdit(null);
                        }}
                        type="regular"
                      />
                      <ActionButton
                        label={tc.remove}
                        onClick={() => {
                          setWidgetToRemove(widgetToEdit);
                        }}
                        type="highlight"
                      />
                      <ActionButton
                        label={tc.save}
                        onClick={_saveEditWidget}
                        type="highlight"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="toggleOptionsHolder">
                  {widgets.map((num) => _renderWidgetRow(num))}
                </div>
              )
            ) : (
              <Info>
                <h4>{tc.noWidgets}</h4>
                <p>{tc.noWidgetsAnalyseInfo}</p>
              </Info>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

const MapStateToProps = (state) => {
  return {
    widgets: state.widgets,
  };
};

export default connect(MapStateToProps)(SettingsAnalyse);
