import Icon from "components/icon";

const ProspectSearchExcludeSwitch = ({ active, onClick, disabled, label }) => {
  return (
    <div className="toggleOptionWrapper excludeToggleOption">
      <div
        className={`toggleOptionWrapper__toggleOption ${
          disabled ? "disabled" : ""
        }`}
      >
        <div className="toggleOptionWrapper__toggleOption__label">
          <div className="toggleOptionWrapper__toggleOption__label__main">
            {label}
          </div>
        </div>
        <div
          className={`toggleOptionWrapper__toggleOption__icon ${
            active ? "active" : ""
          }`}
          onClick={() => {
            if (!disabled && typeof onClick === "function") {
              onClick();
            }
          }}
        >
          <Icon val={active ? "toggleOn" : "toggleOff"} />
        </div>
      </div>
    </div>
  );
};

export default ProspectSearchExcludeSwitch;
