import React, {
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import LanguageContext from "language-context";
import _ from "underscore";
import Activities from "components/activities";
import Icon from "components/icon";
import Info from "components/info";
import Popup from "components/popup";
import WidgetHeader from "components/widget_header";
import { activityHelper, miscHelper } from "helpers";

/**
 * Render activity types as groups.
 * Receive activities data as props.
 * This is supposed to be used for Activities component as well as Events component.
 *
 * @param props.activityTypesToDisplay - array - ["analysis", "mail", "visit" ...]
 * @param props.data - Array with activity rows.
 * @param props.historic boolean - If historic activity or planned activity(events).
 * @param props.target - string - Deal id | prospect id | vehicle reg num | lead id. If target exists, hide displayMode Menu and set mode to user.
 */
const ActivityTypesGroups = (props) => {
  const [popupActivities, setPopupActivities] = useState([]); // Activities to display in popup.
  const [responsiveClassWidth, setResponsiveClassWidth] = useState("");
  const [showInfo, setShowInfo] = useState(false); // Show info box, no data.
  const activityTypesGroupsRef = useRef(null);
  const observer = useRef(null);
  const tc = useContext(LanguageContext);

  const grouped = useMemo(() => {
    const rows = activityHelper.getValidRowsForType(props.data, "user", false);

    if (!rows?.length) {
      return setShowInfo(true);
    }

    const grouped = miscHelper.groupBy(
      rows.filter(
        (row) => row.action && props.activityTypesToDisplay.includes(row.action)
      ),
      "action"
    ); // This will exclude comments, since comment objects doesn't have an 'action' property.
    return grouped;
  }, [props.data, props.activityTypesToDisplay]);

  useEffect(() => {
    setShowInfo(!props.activityTypesToDisplay?.length || !props.data?.length);
  }, [props.activityTypesToDisplay, props.data]);

  useEffect(() => {
    _setResponsiveClass();
    observer.current = new ResizeObserver(_setResponsiveClass);
    observer.current.observe(activityTypesGroupsRef.current);

    const ref = activityTypesGroupsRef.current;

    return () => observer.current.unobserve(ref);
  }, []);

  /**
   * Render activity in groups.
   *
   * Feel free to rewrite this in a nicer way.
   */
  const _renderGroups = () => {
    const start = new Date().getTime();
    let types = {};
    props.activityTypesToDisplay.forEach((key) => {
      types[key] = 0;
    });

    let total = 0;
    for (const prop in types) {
      if (grouped[prop]) {
        total = total + grouped[prop].length;
        types[prop] = grouped[prop].length;
      }
    }

    if (total === 0) {
      return setShowInfo(true);
    }

    // Sort activities on amount.
    const tempArr = Object.keys(types)
      .map((key) => {
        return {
          name: key,
          val: types[key],
        };
      })
      .sort((a, b) => {
        return a.val > b.val ? -1 : 1;
      });

    let groupedSorted = {};
    tempArr.forEach((num) => {
      groupedSorted[num.name] = num.val;
    });

    let totalSum = 0;
    Object.keys(groupedSorted).forEach((key) => {
      totalSum = totalSum + groupedSorted[key];
    });

    const end = new Date().getTime();

    return (
      <>
        {Object.keys(groupedSorted).map((key) => {
          const percentage =
            Number.isFinite(totalSum) && groupedSorted[key] > 0
              ? (groupedSorted[key] / totalSum) * 100
              : 0;

          const disabled = percentage < 1;

          return (
            <div
              className={`activityTypesGroupsWrapper__activityTypesGroups__content__groups__group ${
                disabled ? "disabled" : ""
              }`}
              key={key}
              onClick={() => {
                if (!disabled) {
                  let filtered = props.data.filter((num) => num.action === key);

                  setPopupActivities(
                    activityHelper.getValidRowsForType(
                      filtered.slice(
                        0,
                        filtered.length > 100 ? 100 : filtered.length
                      ),
                      "deal",
                      true
                    )
                    // activityHelper
                    //   .getValidRowsForType(props.data, "deal", true)
                    //   .filter((num) => num.action === key)
                  );
                }
              }}
            >
              <div className="activityTypesGroupsWrapper__activityTypesGroups__content__groups__group__icon">
                <Icon val={key} />
              </div>
              <div className="activityTypesGroupsWrapper__activityTypesGroups__content__groups__group__activity">
                <div className="activityTypesGroupsWrapper__activityTypesGroups__content__groups__group__activity__label">
                  {tc[key]}
                </div>
                <div className="activityTypesGroupsWrapper__activityTypesGroups__content__groups__group__activity__value">
                  {groupedSorted[key]}{" "}
                  <span className="extraText">{`${tc.of.toLowerCase()} ${tc.total.toLowerCase()} ${totalSum} ${tc.activities.toLowerCase()}`}</span>
                </div>
              </div>
              <div className="activityTypesGroupsWrapper__activityTypesGroups__content__groups__group__barHolder">
                <div
                  className="bar"
                  style={{
                    width: percentage < 1 ? "0%" : percentage.toString() + "%",
                  }}
                />
              </div>
            </div>
          );
        })}
      </>
    );
  };

  const _setResponsiveClass = () => {
    if (!activityTypesGroupsRef?.current) {
      return;
    }

    const width = activityTypesGroupsRef.current.getBoundingClientRect().width;
    const breakpoint1 = 1400;
    const breakpoint2 = 1050;
    const breakpoint3 = 600;
    const breakpoint4 = 400;
    const breakpoint5 = 250;
    const breakpoint6 = 200;

    if (width <= breakpoint6) {
      setResponsiveClassWidth("activityTypesGroupsResponsiveWidth6");
    } else if (width <= breakpoint5 && width > breakpoint6) {
      setResponsiveClassWidth("activityTypesGroupsResponsiveWidth5");
    } else if (width <= breakpoint4 && width > breakpoint5) {
      setResponsiveClassWidth("activityTypesGroupsResponsiveWidth4");
    } else if (width <= breakpoint3 && width > breakpoint4) {
      setResponsiveClassWidth("activityTypesGroupsResponsiveWidth3");
    } else if (width <= breakpoint2 && width > breakpoint3) {
      setResponsiveClassWidth("activityTypesGroupsResponsiveWidth2");
    } else if (width <= breakpoint1 && width > breakpoint2) {
      setResponsiveClassWidth("activityTypesGroupsResponsiveWidth1");
    } else if (width > breakpoint1) {
      setResponsiveClassWidth("");
    }
  };

  return props.data ? (
    <div className="activityTypesGroupsWrapper" ref={activityTypesGroupsRef}>
      <div className="activityTypesGroupsWrapper__activityTypesGroups">
        <div className="activityTypesGroupsWrapper__activityTypesGroups__content">
          {showInfo ? (
            <Info>
              <h4>{tc.noActivity}</h4>
              <p>{tc.noActivityTypesGroupsWhy}</p>
            </Info>
          ) : (
            <>
              <div
                className={`activityTypesGroupsWrapper__activityTypesGroups__content__groups ${responsiveClassWidth}`}
              >
                {_renderGroups()}
              </div>
            </>
          )}
        </div>
      </div>
      {popupActivities.length ? (
        <Popup
          size="medium"
          close={() => {
            setPopupActivities([]);
          }}
        >
          <div className="genericPopupContentWrapper">
            <div className="genericPopupContentWrapper__genericPopupContent">
              <div className="genericPopupContentWrapper__genericPopupContent__header">
                <WidgetHeader headline={tc.activities} />
              </div>
              <div className="genericPopupContentWrapper__genericPopupContent__content noPadding">
                <Activities
                  activities={popupActivities}
                  allRows={true}
                  hideCommentInput={true}
                  hideEdit={true}
                  view="embedded"
                />
              </div>
            </div>
          </div>
        </Popup>
      ) : null}
    </div>
  ) : null;
};

export default ActivityTypesGroups;
