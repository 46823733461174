import React, { useContext } from 'react';
import LanguageContext from 'language-context';
import { tableHelper } from 'helpers';
import { Table } from 'components/table';
import Info from 'components/info';
import Loading from 'components/loading';

/**
 * Component that renders a list of deals based on store activity reducer inspect data.
 * Can display won, lost or active deals, and adjust component accordingly.
 */
const ActivityInspect = (props) => {
  const tc = useContext(LanguageContext);

  const _stateCheck = () => {
    return !!props.data;
  };

  return (
    <div className="activityInspectWrapper">
      <div className="activityInspectWrapper__activityInspect">
        <div className="activityInspectWrapper__activityInspect__content">
          {_stateCheck() ? (
            <>
              {props.data.length ? (
                <Table
                  adjustToContainer={true}
                  columns={tableHelper.getInspectColumns()}
                  onSelect={(arr) => {
                    props.setSelected(
                      props.data.filter((num) => arr.includes(num.id))
                    );
                  }}
                  rows={tableHelper.getInspectRows(props.data)}
                  selected={props.selected.map((num) => num.id)}
                />
              ) : (
                <Info>
                  <h4>{tc.noDeals}</h4>
                  <p>{tc.noActivityInspectWhy}</p>
                </Info>
              )}
            </>
          ) : (
            <Loading />
          )}
        </div>
      </div>
    </div>
  );
};

export default ActivityInspect;
