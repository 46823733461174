import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import LanguageContext from "language-context";
import { connect } from "react-redux";
import { miscHelper } from "helpers";
import { saveNewsItem, setEditId } from "store/news/tasks";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import stripHtml from "string-strip-html";
import { Dropdown, DropdownItem } from "components/dropdown";
import ActionButton from "components/action_button";
import Icon from "components/icon";
import Loading from "components/loading";
import Popup from "components/popup";
import ReactS3Uploader from "react-s3-uploader";
import WidgetFooter from "components/widget_footer";
import WidgetHeader from "components/widget_header";
import { uploadNewsImage } from "store/news/tasks";
import { showFlashMessage } from "store/flash_messages/tasks";

/**
 * Either create a new news item or edit an existing one.
 */
const CreateNewsItem = (state) => {
  const [item, setItem] = useState(null);
  const [showPublishItem, setShowPublishItem] = useState(false);
  const tc = useContext(LanguageContext);
  const quillRef = useRef(null);
  const inputFileRef = useRef(null);

  useEffect(() => {
    if (state.news?.editId && Array.isArray(state.news?.items)) {
      let found = state.news.items.find((num) => state.news.editId === num.id);
      if (found) {
        found.validated = true;
        setItem(found);
      }
    } else {
      setItem({
        content: "",
        created: null,
        headline: "",
        img: null,
        ingress: "",
        newsType: [],
        published: null,
        updated: null,
        validated: false,
      });
    }
  }, [state.news.editId, state.news.items]);

  useEffect(() => {
    return () => {
      document.querySelector("#image__handler__input__news")?.remove();
    };
  }, []);

  const _close = () => {
    setShowPublishItem(false);
    setItem(null);
    setEditId(null);

    if (typeof state.props.close === "function") {
      state.props.close();
    }
  };

  const modules = useMemo(() => {
    const imageHandler = () => {
      inputFileRef?.current?.click();
    };

    return {
      toolbar: {
        container: [
          [{ header: [1, 2, false] }],
          [
            "bold",
            "italic",
            "underline",
            { list: "bullet" },
            { indent: "+1" },
            "link",
            "image",
          ],
          ["clean"],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    };
  }, []);

  const _finishUpload = async (e, f) => {
    const url = `https://s3.eu-central-1.amazonaws.com/bilp-test/${e.filename}`;
    setItem({
      ...item,
      img: url,
    });
  };

  const _onChange = (content, deltaOps, source) => {
    setItem({
      ...item,
      content: content,
      validated: !!(
        item.headline?.length &&
        stripHtml(content)?.length &&
        item.newsType?.length &&
        item.ingress?.length
      ),
    });
  };

  const _publish = async () => {
    if (item.validated) {
      let payload = JSON.parse(JSON.stringify(item));
      const now = new Date();
      if (!payload.hasOwnProperty("id")) {
        payload.created = now.toISOString();
      }
      payload.published = now.toISOString(); // When publishing an item, set date value here.
      payload.updated = now.toISOString();
      delete payload.validated;
      await saveNewsItem(payload);
      _close();
    }
  };

  const _saveDraft = async () => {
    if (item.validated) {
      let payload = JSON.parse(JSON.stringify(item));
      const now = new Date();
      if (!payload.hasOwnProperty("id")) {
        payload.created = now.toISOString();
      }
      payload.updated = now.toISOString();
      delete payload.validated;
      payload.published = null; // When saving as draft, always remove from published.
      await saveNewsItem(payload);
      _close();
    }
  };

  const _startUpload = () => {
    document.querySelector("#s3Uploader").click();
  };

  const _stateCheck = () => {
    return !!item;
  };

  const _uploadError = (message) => {
    console.error("S3 file upload error:", message);
  };

  return (
    <Popup close={_close} size="big">
      <div className="createNewsItemWrapper">
        <div className="createNewsItemWrapper__createNewsItem">
          <div className="createNewsItemWrapper__createNewsItem__header">
            <WidgetHeader
              headline={state.news.editId ? tc.editNewsItem : tc.addNewsItem}
            />
          </div>
          {_stateCheck() ? (
            <>
              <div className="createNewsItemWrapper__createNewsItem__content">
                <div className="createNewsItemWrapper__createNewsItem__content__item half">
                  <div className="createNewsItemWrapper__createNewsItem__content__item__label">
                    {tc.headline}
                  </div>
                  <div className="createNewsItemWrapper__createNewsItem__content__item__content">
                    <form autoComplete="off">
                      <input
                        onChange={(e) => {
                          setItem({
                            ...item,
                            headline: e.target.value,
                            validated: !!(
                              e.target.value?.length &&
                              item.content?.length &&
                              item.newsType?.length &&
                              item.ingress?.length
                            ),
                          });
                        }}
                        type="text"
                        value={item.headline}
                      />
                    </form>
                  </div>
                </div>
                <div className="createNewsItemWrapper__createNewsItem__content__item half">
                  <div className="createNewsItemWrapper__createNewsItem__content__item__label">
                    {tc.newsType}
                  </div>
                  <div className="createNewsItemWrapper__createNewsItem__content__item__content">
                    <Dropdown
                      displayValue={tc[item.newsType[0]]}
                      transparent={true}
                      styling={{ adjustToHeader: true }}
                    >
                      {miscHelper.newsTypes.map((num) => {
                        return (
                          <DropdownItem
                            active={item.newsType.includes(num)}
                            key={num}
                            label={tc[num]}
                            onClick={() => {
                              if (item.newsType.includes(num)) {
                                setItem({
                                  ...item,
                                  newsType: item.newsType.filter(
                                    (x) => x !== num
                                  ),
                                  validated: !!(
                                    item.headline?.length &&
                                    item.content?.length &&
                                    item.ingress?.length
                                  ),
                                });
                              } else {
                                setItem({
                                  ...item,
                                  newsType: item.newsType.concat(num),
                                  validated: !!(
                                    item.headline?.length &&
                                    item.content?.length &&
                                    item.ingress?.length
                                  ),
                                });
                              }
                            }}
                          />
                        );
                      })}
                    </Dropdown>
                  </div>
                </div>
                <div className="createNewsItemWrapper__createNewsItem__content__item">
                  <div className="createNewsItemWrapper__createNewsItem__content__item__label">
                    {tc.uploadImage}
                  </div>
                  <div className="createNewsItemWrapper__createNewsItem__content__item__content">
                    {item.img ? (
                      <>
                        <a
                          href={item.img}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item.img}
                        </a>
                        <Icon
                          class="clearImage"
                          val="clear"
                          onClick={() => {
                            setItem({ ...item, img: null });
                            const s3Input =
                              document.querySelector("#s3Uploader");
                            if (s3Input) {
                              s3Input.value = null;
                            }
                          }}
                        />
                      </>
                    ) : (
                      <ActionButton
                        label={tc.selectFile}
                        onClick={_startUpload}
                        type="regular"
                      />
                    )}
                  </div>
                </div>
                <div className="createNewsItemWrapper__createNewsItem__content__item">
                  <div className="createNewsItemWrapper__createNewsItem__content__item__label">
                    {tc.ingress}
                  </div>
                  <div className="createNewsItemWrapper__createNewsItem__content__item__content">
                    <textarea
                      value={item.ingress}
                      onChange={(e) => {
                        setItem({
                          ...item,
                          ingress: e.target.value,
                          validated: !!(
                            item.headline?.length &&
                            item.content?.length &&
                            item.newsType?.length &&
                            e.target?.value?.length
                          ),
                        });
                      }}
                    />
                  </div>
                </div>
                {item.newsType?.length ? (
                  <div className="createNewsItemWrapper__createNewsItem__content__item">
                    <div className="createNewsItemWrapper__createNewsItem__content__item__label">
                      {tc.newsType}
                    </div>
                    <div className="createNewsItemWrapper__createNewsItem__content__item__content">
                      <div className="createNewsItemWrapper__createNewsItem__content__item__content__newsType">
                        {item.newsType.map((num) => (
                          <span key={num} className="tag">
                            {tc[num]}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="createNewsItemWrapper__createNewsItem__content__item">
                  <div className="createNewsItemWrapper__createNewsItem__content__item__label">
                    {tc.content}
                  </div>
                  <div className="createNewsItemWrapper__createNewsItem__content__item__content">
                    <div className="createNewsItemWrapper__createNewsItem__content__item__content__editor">
                      <ReactQuill
                        ref={quillRef}
                        onChange={_onChange}
                        theme="snow"
                        modules={modules}
                        style={{
                          height: "300px",
                        }}
                        value={item.content}
                      />
                      <input
                        style={{ display: "none" }}
                        type="file"
                        accept="image/*"
                        id="image__handler__input__news"
                        ref={inputFileRef}
                        onChange={async (e) => {
                          const file = e.target.files?.[0] || null;

                          if (!file) return;
                          const formData = new FormData();
                          formData.append("file", file);
                          let quillObj = quillRef.current.getEditor();
                          const range = quillObj.getSelection();
                          const location = await uploadNewsImage(formData);
                          if (!location) {
                            showFlashMessage(tc.imageUploadFail, "fail");
                          } else
                            quillObj.editor.insertEmbed(
                              range.index,
                              "image",
                              location
                            );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="createNewsItemWrapper__createNewsItem__footer">
                <WidgetFooter
                  buttonOneFunc={() => {
                    setShowPublishItem(true);
                  }}
                  buttonOneText={tc.publish}
                  disableButtonOne={!(item.validated && state.news?.editId)} // Need to save as draft before publish
                  buttonTwoFunc={_saveDraft}
                  buttonTwoText={tc.saveDraft}
                  disableButtonTwo={!item.validated || item.published?.length}
                  buttonThreeFunc={_close}
                  buttonThreeText={tc.cancel}
                  noBorder={true}
                />
              </div>
            </>
          ) : (
            <Loading />
          )}
        </div>
        {showPublishItem ? (
          <Popup
            close={() => {
              setShowPublishItem(false);
            }}
            size="small"
          >
            <div className="genericPopupContentWrapper">
              <div className="genericPopupContentWrapper__genericPopupContent">
                <div className="genericPopupContentWrapper__genericPopupContent__header">
                  <WidgetHeader headline={tc.publish} />
                </div>
                <div className="genericPopupContentWrapper__genericPopupContent__content">
                  <p>{tc.ensure}</p>
                </div>
                <div className="genericPopupContentWrapper__genericPopupContent__footer">
                  <WidgetFooter
                    buttonOneFunc={_publish}
                    buttonOneText={tc.publish}
                    buttonTwoFunc={() => {
                      setShowPublishItem(false);
                    }}
                    buttonTwoText={tc.cancel}
                  />
                </div>
              </div>
            </div>
          </Popup>
        ) : null}
      </div>
      <div className="hidden">
        <ReactS3Uploader
          id="s3Uploader"
          signingUrl="/s3/sign"
          onFinish={_finishUpload}
          onError={_uploadError}
          contentDisposition="auto"
          scrubFilename={(filename) => filename.replace(/[^\w\d_\-.]+/gi, "")}
          uploadRequestHeaders={{ "x-amz-acl": "private" }}
        />
      </div>
    </Popup>
  );
};

const MapStateToProps = (state, props) => {
  return {
    news: state.news,
    props: props,
    user: state.user,
  };
};

export default connect(MapStateToProps)(CreateNewsItem);
