import React, { useContext, useEffect, useRef, useState } from 'react';
import { getEvents } from 'store/events/tasks';
import { connect } from 'react-redux';
import LanguageContext from 'language-context';
import { activityHelper } from 'helpers';
import { updateWidgets } from 'store/widgets/tasks';
import { Dropdown, DropdownItem } from 'components/dropdown';
import ActivityTypesGroups from 'components/activity_types_groups/activity_types_groups';
import ActivityTypesTable from 'components/activity_types_table';
import EventsCalendar from './events_calendar';
import EventsFlow from './events_flow';
import Loading from 'components/loading';
import WidgetHeader from 'components/widget_header';

/**
 * Render events (planned activities).
 * Can display all events.
 * Can also filter events on a target, or by filter.
 *
 * @param state.props.amountIncrease - number (optional) - How many rows we show initially and add every click, only for flow.
 * @param state.props.onlyShowFlow (optional) - bool - When we don't want the possibility to switch to calendar/summary view.
 * @param state.props.showCalendarView (optional) - bool - When we want to display the calendar view option (probably only want the calendar option on dashboard).
 * @param state.props.target (optional) - string - When displaying events for specific deal/prospect.
 * @param state.props.type - string - Determines if we get events based on target or filter.
 * @param state.props.view (optional) - string - 'calendar' | 'flow' | 'groups' | 'types'. Defaults to flow.
 * @param state.props.isWidget (optional) - bool - When in widget mode.
 * @param state.props.isMinimized - A boolean indicating whether the component is minimized or not.
 * @param state.props.onMinimizeChange - Callback function invoked when the minimize state changes.
 */
const Events = (state) => {
  const [activityTypesToDisplay, setActivityTypesToDisplay] = useState([]);
  const [minimize, setMinimize] = useState(false);
  const [responsiveClassWidth, setResponsiveClassWidth] = useState('');
  const [view, setView] = useState('flow');
  const eventsRef = useRef(null);
  const observer = useRef(null);
  const tc = useContext(LanguageContext);
  useEffect(() => {
    setMinimize(state.props.isMinimized);
  }, [state.props.isMinimized]);
  useEffect(() => {
    _setResponsiveClass();
    observer.current = new ResizeObserver(_setResponsiveClass);
    observer.current.observe(eventsRef.current);

    const variable = eventsRef.current;

    return () => observer.current.unobserve(variable);
  }, []);

  useEffect(() => {
    getEvents({
      target: state.props.target ? state.props.target : null,
      type: state.props.type,
    });
  }, [state.props.type, state.props.target]);

  useEffect(() => {
    function createObjectFromType(type, active) {
      return {
        active,
        label: activityHelper.getReadableActivity(type, true),
        val: type,
      };
    }
    if (Array.isArray(state.widgets?.data?.widgets) && state.props.isWidget) {
      const eventsWidget = state.widgets.data.widgets.find(
        (num) => num.type === 'notifications'
      );

      if (
        eventsWidget?.dashboards?.dashboard &&
        Array.isArray(
          eventsWidget.dashboards['grid_default']?.activityTypesToDisplay
        )
      ) {
        // Event widget is found and have activityTypesToDisplay, see which types that is active.
        setActivityTypesToDisplay(
          activityHelper
            .getActionTypes(true)
            .filter((type) => type !== 'meetingPlanned')
            .map((type) =>
              createObjectFromType(
                type,
                !!eventsWidget.dashboards[
                  'grid_default'
                ]?.activityTypesToDisplay.includes(type)
              )
            )
        );
      } else {
        // All types, all active.
        setActivityTypesToDisplay(
          activityHelper
            .getActionTypes(true)
            .filter((type) => type !== 'meetingPlanned')
            .map((type) => createObjectFromType(type, true))
        );
      }
    } else {
      setActivityTypesToDisplay(
        activityHelper
          .getActionTypes(true)
          .filter((type) => type !== 'meetingPlanned')
          .map((type) => createObjectFromType(type, true))
      );
    }
  }, []);

  useEffect(() => {
    // Set minimize and view.

    let eventsWidget;
    if (Array.isArray(state.widgets?.data?.widgets) && state.props.isWidget) {
      eventsWidget = state.widgets.data.widgets.find(
        (num) => num.type === 'notifications'
      );
    }

    if (state.props.onlyShowFlow) {
      setView('flow');
    } else if (
      state.props.isWidget &&
      eventsWidget?.dashboards?.['grid_default']?.view
    ) {
      setView(eventsWidget.dashboards['grid_default']?.view);
    } else if (state.props.view) {
      setView(state.props.view);
    }

    if (
      state.props.isWidget &&
      eventsWidget?.dashboards?.['grid_default'] &&
      eventsWidget.dashboards['grid_default']?.hasOwnProperty('minimized')
    ) {
      setMinimize(eventsWidget.dashboards['grid_default']?.minimized);
    } else {
      setMinimize(!state.events?.events?.length);
    }
  }, [
    state.props.onlyShowFlow,
    state.props.isWidget,
    state.props.view,
    state.widgets.data,
    state.events.events,
  ]);

  const _calendarStepBack = async () => {
    await getEvents({
      calendar: {
        month:
          state.events.eventsByMonth.monthInScope === 1
            ? 12
            : state.events.eventsByMonth.monthInScope - 1,
        year:
          state.events.eventsByMonth.monthInScope === 1
            ? state.events.eventsByMonth.yearInScope - 1
            : state.events.eventsByMonth.yearInScope,
      },
      target: state.props.target,
      type: state.props.type,
    });
  };

  const _calendarStepForward = async () => {
    await getEvents({
      calendar: {
        month:
          state.events.eventsByMonth.monthInScope === 12
            ? 1
            : state.events.eventsByMonth.monthInScope + 1,
        year:
          state.events.eventsByMonth.monthInScope === 12
            ? state.events.eventsByMonth.yearInScope + 1
            : state.events.eventsByMonth.yearInScope,
      },
      target: state.props.target,
      type: state.props.type,
    });
  };

  const _renderContent = () => {
    switch (view) {
      case 'calendar':
        return (
          <EventsCalendar
            data={state.events.eventsByMonth}
            stepBack={_calendarStepBack}
            stepForward={_calendarStepForward}
          />
        );
      case 'flow':
        return (
          <EventsFlow
            activityTypesToDisplay={activityTypesToDisplay
              .filter((num) => num.active)
              .map((num) => num.val)}
            amountIncrease={state.props.amountIncrease}
            data={state.events.events}
            target={state.props.target}
            type={state.props.type}
            user={state.user}
          />
        );
      case 'groups':
        return (
          <ActivityTypesGroups
            activityTypesToDisplay={activityTypesToDisplay
              .filter((num) => num.active)
              .map((num) => num.val)}
            data={state.events.events}
            target={state.props.target}
            type={state.props.type}
          />
        );
      case 'types':
        return (
          <ActivityTypesTable
            activityTypesToDisplay={activityTypesToDisplay
              .filter((num) => num.active)
              .map((num) => num.val)}
            data={state.events.events}
            target={state.props.target}
            type={state.props.type}
          />
        );
      default:
        return (
          <EventsFlow
            activityTypesToDisplay={activityTypesToDisplay
              .filter((num) => num.active)
              .map((num) => num.val)}
            amountIncrease={state.props.amountIncrease}
            data={state.events.events}
            target={state.props.target}
            type={state.props.type}
            user={state.user}
          />
        );
    }
  };

  /**
   *
   * @param {*} fieldToUpdate "minimized" | "view" currently used
   * @param {*} value
   * @description handler function for updating the widgets' state in the db.
   */
  const handleUpdateWidgets = (fieldToUpdate, value) => {
    if (Array.isArray(state.widgets?.data?.widgets) && state.props.isWidget) {
      // Save widget state to db.
      updateWidgets({
        widgets: state.widgets.data.widgets.map((num) => {
          if (num.type === 'notifications' && num?.dashboards?.dashboard) {
            num.dashboards.dashboard[fieldToUpdate] = value;
          }
          return num;
        }),
      });
    }
  };

  const _setResponsiveClass = () => {
    if (!eventsRef?.current) {
      return;
    }

    const width = eventsRef.current.getBoundingClientRect().width;
    const breakpoint1 = 400;

    if (width <= breakpoint1) {
      setResponsiveClassWidth('eventsResponsiveWidth1');
    } else if (width > breakpoint1) {
      setResponsiveClassWidth('');
    }
  };

  const _stateCheck = () => {
    return !!state.events.events;
  };

  return (
    <div className="eventsWrapper" ref={eventsRef}>
      <div className="eventsWrapper__events">
        <div className="eventsWrapper__events__header">
          <WidgetHeader
            dashboardItems={
              state.props.isCompanyPage
                ? null
                : _stateCheck()
                ? [
                    {
                      disabled: !minimize,
                      icon: 'maximize',
                      label: tc.maximize,
                      onClick: () => {
                        setMinimize(false);
                        handleUpdateWidgets('minimized', false);
                      },
                    },
                    {
                      disabled: minimize,
                      icon: 'minimize',
                      label: tc.minimize,
                      onClick: () => {
                        setMinimize(true);
                        handleUpdateWidgets('minimized', true);
                        if (state.props.onMinimizeChange) {
                          state.props.onMinimizeChange(true);
                        }
                      },
                    },
                    !state.props.onlyShowFlow
                      ? {
                          active: view === 'groups',
                          disabled: state.props.onlyShowFlow || minimize,
                          icon: 'groups',
                          label: `${
                            tc.displayMode
                          } ${tc.activityGroups.toLowerCase()}`,
                          onClick: () => {
                            setView('groups');
                            handleUpdateWidgets('view', 'groups');
                          },
                          selectable: true,
                        }
                      : null,
                    !state.props.onlyShowFlow
                      ? {
                          active: view === 'types',
                          disabled: state.props.onlyShowFlow || minimize,
                          icon: 'actionTypes',
                          label: `${
                            tc.displayMode
                          } ${tc.activityTable.toLowerCase()}`,
                          onClick: () => {
                            setView('types');
                            handleUpdateWidgets('view', 'types');
                          },
                          selectable: true,
                        }
                      : null,
                    !state.props.onlyShowFlow
                      ? {
                          active: view === 'flow',
                          disabled: state.props.onlyShowFlow || minimize,
                          icon: 'flow',
                          label: `${
                            tc.displayMode
                          } ${tc.eventsFlow.toLowerCase()}`,
                          onClick: () => {
                            setView('flow');
                            handleUpdateWidgets('view', 'flow');
                          },
                          selectable: true,
                        }
                      : null,
                    !state.props.onlyShowFlow && state.props.showCalendarView
                      ? {
                          active: view === 'calendar',
                          disabled:
                            !(
                              !state.props.onlyShowFlow &&
                              state.props.showCalendarView
                            ) || minimize,
                          icon: 'eventsCalendar',
                          label: `${
                            tc.displayMode
                          } ${tc.eventsCalendar.toLowerCase()}`,
                          onClick: () => {
                            setView('calendar');
                            handleUpdateWidgets('view', 'calendar');
                          },
                          selectable: true,
                        }
                      : null,
                  ]
                : null
            }
            headline={
              state.props?.widget?.name ? state.props.isWidget.name : tc.events
            }
            headlineSub={
              !state.events.events ? (
                <Loading small={true} />
              ) : view === 'types' ? (
                tc.activityTable
              ) : view === 'groups' ? (
                tc.activityGroups
              ) : view === 'calendar' ? (
                tc.eventsCalendar
              ) : (
                tc.eventsFlow
              )
            }
          />
        </div>
        {_stateCheck() ? (
          <div
            className={`eventsWrapper__events__content ${responsiveClassWidth} ${
              minimize ? 'minimize' : ''
            }`}
          >
            {view === 'calendar' ? null : (
              <div className="eventsWrapper__events__content__top">
                <div className="eventsWrapper__events__content__top__right">
                  {!state.props.hideSelectActivityTypes &&
                  !state.props.embedded ? (
                    <div className="eventsWrapper__events__content__top__right__selectActivities">
                      <Dropdown
                        displayValue={`${
                          tc.show
                        } / ${tc.hide.toLowerCase()} ${tc.activities.toLowerCase()}`}
                        transparent={true}
                        styling={{ adjustToHeader: true }}
                      >
                        {activityTypesToDisplay.map((num) => {
                          return (
                            <DropdownItem
                              active={num.active}
                              key={num.val}
                              label={num.label}
                              onClick={() => {
                                const newActivityTypesToDisplay =
                                  activityTypesToDisplay.map((x) => {
                                    if (x.val === num.val) {
                                      x.active = !x.active;
                                    }

                                    return x;
                                  });

                                setActivityTypesToDisplay(
                                  newActivityTypesToDisplay
                                );

                                if (
                                  Array.isArray(state.widgets?.data?.widgets) &&
                                  state.props.isWidget
                                ) {
                                  // Save widget state to db.
                                  // Only save text values for active types in widget activityTypesToDisplay array, not objects.
                                  updateWidgets({
                                    widgets: state.widgets.data.widgets.map(
                                      (num) => {
                                        if (
                                          num.type === 'notifications' &&
                                          num?.dashboards?.dashboard
                                        ) {
                                          num.dashboards.dashboard.activityTypesToDisplay =
                                            newActivityTypesToDisplay
                                              .filter((num) => num.active)
                                              .map((num) => num.val);
                                        }

                                        return num;
                                      }
                                    ),
                                  });
                                }
                              }}
                            />
                          );
                        })}
                      </Dropdown>
                    </div>
                  ) : null}
                </div>
              </div>
            )}
            <div className="eventsWrapper__events__content__middle">
              {_renderContent()}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const MapStateToProps = (state, props) => {
  return {
    events: state.events,
    props: props,
    user: state.user,
    widgets: state.widgets,
  };
};

export default connect(MapStateToProps)(Events);
