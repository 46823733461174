import React, { useContext } from "react";
import LanguageContext from "language-context";
import Highlight from "components/highlight";

const MailingsParams = () => {
  const tc = useContext(LanguageContext);

  return (
    <div className="mailingsParamsWrapper">
      <div className="mailingsParamsWrapper__mailingsParams">
        <div className="mailingsParamsWrapper__mailingsParams__content">
          <Highlight text="@Namn" />
          <p className="parameterInfo">{tc.parameterNamn}</p>
          <Highlight text="@Efternamn" />
          <p className="parameterInfo">{tc.parameterEfternamn}</p>
          <Highlight text="@Postort" />
          <p className="parameterInfo">{tc.parameterPostort}</p>
          <Highlight text="@Regnr" />
          <p className="parameterInfo">{tc.parameterRegnr}</p>
        </div>
      </div>
    </div>
  );
};

export default MailingsParams;
