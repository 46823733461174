import Tooltip from "components/tooltip";
import LanguageContext from "language-context";
import { useContext } from "react";

const SingleRow = ({ row, setDisplayDealsUserId, dealerId, rows }) => {
  const tc = useContext(LanguageContext);
  const shouldRenderDealerRow = false;
  return (
    <div
      style={{ marginBottom: "-44px" }}
      className={`activityTypesTableWrapper__activityTypesTable__content__table__rowsHolder__rows__rowHolder`}
      key={row.userId}
      onClick={() => {
        // Toggle row holder that holds deal rows for this user.
        // setDisplayDealsUserIds(
        //   displayDealsUserIds.includes(row.userId)
        //     ? displayDealsUserIds.filter((num) => num !== row.userId)
        //     : displayDealsUserIds.concat(row.userId)
        // );
        // setDisplayDealsUserIds({
        //   userId: row.userId,
        //   dealerId,
        //   row,
        // });
      }}
    >
      <div
        className={`activityTypesTableWrapper__activityTypesTable__content__table__rowsHolder__rows__rowHolder__row`}
      >
        {Object.keys(row).map((num) => {
          if (num === "userId" || num === "userName" || num === "rows") {
            return null;
          }

          const numberValue = Number.isFinite(+row[num]);
          const value = numberValue ? +row[num] : row[num];
          const dealerTotalSum = Number.isFinite(+row[num])
            ? rows.filter((row) => {
                return (
                  +row.user.dealer_id === +dealerId &&
                  ((!row.action && num === "comment" && row.comment) ||
                    row.action === num)
                );
              }).length
            : null;
          const percentage =
            Number.isFinite(dealerTotalSum) && value > 0
              ? (value / dealerTotalSum) * 100
              : 0;

          return (
            <div
              className={`activityTypesTableWrapper__activityTypesTable__content__table__rowsHolder__rows__rowHolder__row__cell`}
              key={num}
            >
              {numberValue ? (
                !shouldRenderDealerRow ? (
                  <>
                    <div className="activityTypesTableWrapper__activityTypesTable__content__table__rowsHolder__rows__rowHolder__row__cell__value">
                      <span>{value}</span>
                    </div>
                    <div className="activityTypesTableWrapper__activityTypesTable__content__table__rowsHolder__rows__rowHolder__row__cell__barHolder">
                      <div
                        className="bar"
                        style={{
                          width:
                            percentage < 1 ? "0%" : percentage.toString() + "%",
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <Tooltip
                    tooltipContent={`${value} ${tc.of.toLowerCase()} ${tc.total.toLowerCase()} ${dealerTotalSum} ${tc.for.toLowerCase()} ${tc.facility.toLowerCase()}`}
                  >
                    <div className="activityTypesTableWrapper__activityTypesTable__content__table__rowsHolder__rows__rowHolder__row__cell__value">
                      <span>{value}</span>
                    </div>
                    <div className="activityTypesTableWrapper__activityTypesTable__content__table__rowsHolder__rows__rowHolder__row__cell__barHolder">
                      <div
                        className="bar"
                        key={num}
                        style={{
                          width:
                            percentage < 1 ? "0%" : percentage.toString() + "%",
                        }}
                      />
                    </div>
                  </Tooltip>
                )
              ) : (
                <div
                  className={`activityTypesTableWrapper__activityTypesTable__content__table__rowsHolder__rows__rowHolder__row__cell__value`}
                >
                  <span>{value}</span>
                </div>
              )}
            </div>
          );
        })}
      </div>
      {/*Render deal rows holder for this user, provide user id as param to know if it should be open/closed.*/}
      {/* {level === 0
            ? _renderTableRows("deal", row.userId, dealerId, 1)
            : null} */}
    </div>
  );
};

export default SingleRow;
