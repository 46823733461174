import React, { useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import LanguageContext from 'language-context';
import moment from 'moment';
import { agileHelper, miscHelper } from 'helpers';
import carHelper from 'shared_helpers/car_helper';
import companyHelper from 'shared_helpers/company_helper';
import { deleteLeads, moveToAgile, moveToList } from 'store/leads/tasks';
import Activities from 'components/activities';
import ColleaguesDropdown from 'components/colleagues_dropdown';
import Icon from 'components/icon';
import Popup from 'components/popup';
import SaveToList from 'components/save_to_list';
import Tooltip from 'components/tooltip';
import WidgetHeader from 'components/widget_header';
import WidgetFooter from 'components/widget_footer';

/**
 * Render a lead preview.
 *
 * @param state.props.close - func
 * @param state.props.lead - object
 * @param state.props.onUserChange - func
 */
const LeadsPreview = (state) => {
  const [hideBlocks, setHideBlocks] = useState([]);
  const [moveToAgileUser, setMoveToAgileUser] = useState({
    id: null,
    name: '',
  });
  const [showMoveToAgile, setShowMoveToAgile] = useState(false);
  const [showMoveToList, setShowMoveToList] = useState(false);
  const [showRemoveItem, setShowRemoveItem] = useState(false);
  const leadsPreviewRef = useRef(null);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    /**
     * Close preview when clicking outside.
     */
    const _closePreview = (e) => {
      if (typeof state.props.close === 'function') {
        if (miscHelper.clickUnmount(e, leadsPreviewRef, false)) {
          state.props.close();
        }
      }
    };

    window.addEventListener('mousedown', _closePreview);
    return () => window.removeEventListener('mousedown', _closePreview);
  }, []);

  useEffect(() => {
    if (state.user?.info?.id) {
      setMoveToAgileUser({
        id: state.user.info.id,
        name: state.user.info.name,
      });
    }
  }, [state.user.info]);

  const _moveToAgile = () => {
    moveToAgile({
      rows: [state.props.lead.sid],
      userId: moveToAgileUser.id,
    });
    state.props.close();
  };

  const _removeItem = () => {
    deleteLeads({
      rows: [state.props.lead.id],
    });
    state.props.close();
  };

  const _renderActivities = () => {
    return (
      <div className="leadsPreviewWrapper__leadsPreview__content__block">
        <div
          className="leadsPreviewWrapper__leadsPreview__content__block__header"
          onClick={() => {
            if (hideBlocks.includes('activities')) {
              setHideBlocks(hideBlocks.filter((num) => num !== 'activities'));
            } else {
              setHideBlocks(hideBlocks.concat('activities'));
            }
          }}
        >
          <div className="leadsPreviewWrapper__leadsPreview__content__block__header__title">
            {hideBlocks.includes('activities') ? (
              <Icon val="maximize" />
            ) : (
              <Icon val="minimize" />
            )}
            <p>{tc.activity}</p>
          </div>
        </div>
        {!hideBlocks.includes('activities') ? (
          <div className="leadsPreviewWrapper__leadsPreview__content__block__content">
            <Activities target={state.props.lead.id} view="embedded" />
          </div>
        ) : null}
      </div>
    );
  };

  const _renderIcons = () => {
    return (
      <div className="leadsPreviewWrapper__leadsPreview__header__bottom">
        {(state.props.lead.orgNr &&
          companyHelper.isValidOrgNr(state.props.lead.orgNr)) ||
        (state.props.lead.regNr &&
          carHelper.isValidRegNumber(state.props.lead.regNr)) ? (
          <div className="leadsPreviewWrapper__leadsPreview__header__bottom__item">
            <Tooltip tooltipContent={tc.moveToList}>
              <Icon
                onClick={() => {
                  setShowMoveToList(true);
                }}
                val="lists"
              />
            </Tooltip>
          </div>
        ) : null}
        <div className="leadsPreviewWrapper__leadsPreview__header__bottom__item">
          <Tooltip tooltipContent={tc.moveToAgile}>
            <Icon
              onClick={() => {
                setShowMoveToAgile(true);
              }}
              val="agile"
            />
          </Tooltip>
        </div>
        <div className="leadsPreviewWrapper__leadsPreview__header__bottom__item">
          <Tooltip tooltipContent={tc.removeLead}>
            <Icon
              onClick={() => {
                setShowRemoveItem(true);
              }}
              val="remove"
            />
          </Tooltip>
        </div>
      </div>
    );
  };

  const _renderInformation = () => {
    const rows = [];
    const renderTheseProperties = agileHelper.getLeadsUploadColumns
      .filter((num) => num !== 'name')
      .concat(['createdAt']);

    if (!hideBlocks.includes('information')) {
      Object.keys(state.props.lead).forEach((key) => {
        if (renderTheseProperties.includes(key) && state.props.lead[key]) {
          let value;

          if (
            key === 'createdAt' ||
            key === 'expireDate' ||
            key === 'lastContact'
          ) {
            value = moment(
              new Date(state.props.lead[key]),
              'DD/MM/YYYY'
            ).format('LL');
          } else if (key === 'phone' || key === 'contactPhone') {
            value = (
              <a href={'tel:+46' + state.props.lead[key]}>
                {state.props.lead[key]}
              </a>
            );
          } else {
            value = state.props.lead[key];
          }

          rows.push(
            <div
              className="leadsPreviewWrapper__leadsPreview__content__block__content__infoRow"
              key={key}
            >
              <div className="leadsPreviewWrapper__leadsPreview__content__block__content__infoRow__left">
                {tc[key]}:
              </div>
              <div className="leadsPreviewWrapper__leadsPreview__content__block__content__infoRow__right">
                {value}
              </div>
            </div>
          );
        }
      });
    }

    return (
      <div className="leadsPreviewWrapper__leadsPreview__content__block">
        <div
          className="leadsPreviewWrapper__leadsPreview__content__block__header"
          onClick={() => {
            if (hideBlocks.includes('information')) {
              setHideBlocks(hideBlocks.filter((num) => num !== 'information'));
            } else {
              setHideBlocks(hideBlocks.concat('information'));
            }
          }}
        >
          <div className="leadsPreviewWrapper__leadsPreview__content__block__header__title">
            {hideBlocks.includes('information') ? (
              <Icon val="maximize" />
            ) : (
              <Icon val="minimize" />
            )}
            <p>{tc.leadsInformation}</p>
          </div>
        </div>
        {!hideBlocks.includes('information') ? (
          <div className="leadsPreviewWrapper__leadsPreview__content__block__content">
            {rows}
          </div>
        ) : null}
      </div>
    );
  };

  return state.props.lead ? (
    <div className="leadsPreviewWrapper" ref={leadsPreviewRef}>
      <div className="leadsPreviewWrapper__leadsPreview">
        <div className="leadsPreviewWrapper__leadsPreview__header">
          <div className="leadsPreviewWrapper__leadsPreview__header__top">
            <div className="leadsPreviewWrapper__leadsPreview__header__top__left">
              <WidgetHeader
                headline={state.props.lead.name}
                headlineSub={tc.lead}
              />
            </div>
            <div
              className="leadsPreviewWrapper__leadsPreview__header__top__right"
              onClick={() => {
                state.props.close();
              }}
            >
              <Icon val="clear" />
            </div>
          </div>
          {_renderIcons()}
        </div>
        <div className="leadsPreviewWrapper__leadsPreview__content">
          {_renderInformation()}
          {_renderActivities()}
        </div>
        {showRemoveItem ? (
          <Popup
            close={() => {
              setShowRemoveItem(false);
            }}
            size="small"
          >
            <div className="leadsPopupWrapper">
              <div className="leadsPopupWrapper__leadsPopup">
                <div className="leadsPopupWrapper__leadsPopup__header">
                  <WidgetHeader headline={tc.removeLead} />
                </div>
                <div className="leadsPopupWrapper__leadsPopup__content">
                  <p>{tc.deleteLeadsPrompt}</p>
                </div>
                <div className="leadsPopupWrapper__leadsPopup__footer">
                  <WidgetFooter
                    buttonOneFunc={_removeItem}
                    buttonOneText={tc.remove}
                    buttonTwoFunc={() => {
                      setShowRemoveItem(false);
                    }}
                    buttonTwoText={tc.cancel}
                  />
                </div>
              </div>
            </div>
          </Popup>
        ) : null}
        {showMoveToAgile ? (
          <Popup
            close={() => {
              setShowMoveToAgile(false);
            }}
            size="small"
          >
            <div className="genericPopupContentWrapper">
              <div className="genericPopupContentWrapper__genericPopupContent">
                <div className="genericPopupContentWrapper__genericPopupContent__header">
                  <WidgetHeader headline={tc.moveToAgile} />
                </div>
                <div className="genericPopupContentWrapper__genericPopupContent__content">
                  <p>{tc.moveLeadsToAgileInfo}</p>
                  <p>{tc.moveLeadsToAgileInfo2}</p>
                  <div className="genericPopupContentWrapper__genericPopupContent__content__flexHolder centered marginTop">
                    <div className="genericPopupContentWrapper__genericPopupContent__content__flexHolder__left">
                      <p>
                        {`${
                          tc.move
                        } ${tc.leads.toLowerCase()} ${tc.to.toLowerCase()}`}
                        :
                      </p>
                    </div>
                    <div className="genericPopupContentWrapper__genericPopupContent__content__flexHolder__right">
                      <ColleaguesDropdown
                        activeId={moveToAgileUser.id}
                        activeIds={[]}
                        activeName={moveToAgileUser.name}
                        transparent={true}
                        onClick={(id, name) => {
                          setMoveToAgileUser({
                            id: id,
                            name: name,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="genericPopupContentWrapper__genericPopupContent__footer">
                  <WidgetFooter
                    buttonOneFunc={_moveToAgile}
                    buttonOneText={tc.move}
                    buttonTwoFunc={() => {
                      setShowMoveToAgile(false);
                    }}
                    buttonTwoText={tc.cancel}
                  />
                </div>
              </div>
            </div>
          </Popup>
        ) : null}
        {showMoveToList ? (
          <SaveToList
            close={() => {
              setShowMoveToList(false);
            }}
            text={tc.moveLeadsToListInfo}
            save={async (payload) => {
              setShowMoveToList(false);
              state.props.close();
              if (payload.lists) {
                await moveToList({
                  rows: [state.props.lead.id],
                  mode: 'savedLists',
                  value: payload.lists,
                  data: state.props.lead.data,
                });
              } else {
                await moveToList({
                  rows: [state.props.lead.id],
                  mode: 'newList',
                  value: payload.name,
                  data: state.props.lead.data,
                });
              }
            }}
          />
        ) : null}
      </div>
    </div>
  ) : null;
};

const MapStateToProps = (state, props) => {
  return {
    props: props,
    user: state.user,
  };
};

export default connect(MapStateToProps)(LeadsPreview);
