import moment from 'moment';
import { useEffect, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getActivities } from 'store/activities/tasks';
import { setActivityFilter } from 'store/activity/tasks';
import LanguageContext from 'language-context';
import { summarySettings } from '../summarySettings';
import { countActionTypes, dummyData, generateSummaryMessage, sortByOrder } from '../utils/utils';

//Global variables to handle onlyOnce-behavior and static values
let filterSet = false; //Used to handle filter setting
let initialActivitiesFetched = false; //Used to handle getting of activites
const useDummyData = false; // Used for testing

/**
 * Handles behind the scenes logic using settings
 * "interval" determins the timeperiod for the summary
 * "modifier" determins how long ago.
 * If interval = "week" & modifier = 1
 * then we show summary for the previous week.
 *
 * @returns {show, close, setSettings, filteredActivities, message, tc }
 */
export function useSummary() {
  //Moved settings from props to separate file - for perfomance
  const { interval, modifier } = summarySettings;

  //App state Context/redux
  const tc = useContext(LanguageContext); //Used to generate correct summary message
  const { name } = useSelector((state) => state.user.info); //User reference
  const activities = useSelector((state) => state.activities.activitiesByFilter);

  //Set the filter for activities
  useEffect(() => {
    if (name && interval) {
      //Check if filters are set
      if (!filterSet) {
        setReduxFilter(interval, modifier); //Set the filter values
      }

      //Check if filters are set and if yes get activities
      if (filterSet && !initialActivitiesFetched) {
        getActivities({ type: 'filter' }); //Get activities according to the filter set in state
        initialActivitiesFetched = true; //Will prevent additional gets
      }
    }
  }, [name]);

  //Reduce activities to {<action>: number}
  const filteredActivities = useMemo(() => {
    if (activities?.length) {
      return countActionTypes(sortByOrder(activities));
    }
  }, [activities]);

  //Generate message
  const message = useMemo(() => {
    if (name && tc) {
      return generateSummaryMessage(interval, modifier, tc, name);
    }
  }, [name, tc, interval, modifier]);

  //Get activity total
  const total = useMemo(() => {
    return activities?.length > 0 ? sortByOrder(activities).length : 0;
  }, [activities]);

  function setReduxFilter(interval, modifier) {
    if (interval === 'week' || interval === 'month' || interval === 'year') {
      //Configure date to be set in filter
      const from = moment()
        .subtract(modifier, interval)
        .startOf(interval === 'week' ? 'isoWeek' : interval);
      const to = moment()
        .subtract(modifier, interval)
        .endOf(interval === 'week' ? 'isoWeek' : interval);

      //Set the filters for date, userId should already be set
      setActivityFilter({ type: 'date', val: { from, to } });

      filterSet = true; //Prevent filters from being set again
      return;
    }
    console.error('Missing parameter interval');
    return;
  }

  return { filteredActivities, total, message, tc };
}
