import LanguageContext from "language-context";
import { useContext, useEffect } from "react";
import ActionButton from "../../action_button";

const EditItem = ({
  updateValue,
  value,
  inputRef,
  itemToEdit,
  setItemToEdit,
  handleItemSave,
  handleItemRemove,
}) => {
  const tc = useContext(LanguageContext);

  useEffect(() => {
    inputRef && inputRef.current && inputRef.current.focus();
  }, [inputRef]);

  return (
    <div className="inputHolder">
      <div className="inputHolder__left">
        <label htmlFor="item-to-edit-input">{tc.editName}: </label>
        <input
          id="item-to-edit-input"
          onChange={(e) => {
            updateValue(e.target.value);
          }}
          ref={inputRef}
          type="text"
          value={value}
        />
      </div>
      <div className="inputHolder__right">
        <ActionButton
          label={tc.cancel}
          onClick={() => {
            setItemToEdit(null);
          }}
          type="regular"
        />
        <ActionButton
          label={tc.remove}
          onClick={() => {
            handleItemRemove();
          }}
          type="highlight"
        />
        <ActionButton
          label={tc.save}
          onClick={() => {
            handleItemSave(itemToEdit);
          }}
          type="highlight"
        />
      </div>
    </div>
  );
};

export default EditItem;
