import moment from "moment";
import { useRef, useEffect } from "react";

export const arraysEqual = (a, b) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

export const iconTranslations = {
  bar: "bar_chart",
  line: "line_chart",
  table: "table_chart",
  map: "map_chart",
};

export const granularities = [undefined, "year", "month", "day"];

export const getDrillDownQuery = (el, data, options, setters) => {
  if (!el.length) {
    return;
  }

  if (options.drillType === "dimension") {
    if (
      options.drillLevel + options.granularity ===
      options.drillDownOptions.length - 1
    ) {
      return;
    }
  } else if (options.drillType === "time") {
    if (
      options.drillLevel + options.granularity ===
      options.granularities.length - 1
    ) {
      return;
    }
  }
  const { datasets, resultSet } = data;

  const xValues = datasets[el[0].datasetIndex].x[el[0].index];
  const yValues = datasets[el[0].datasetIndex].y;

  const drillQuery = resultSet.drillDown({
    xValues: [xValues],
    yValues,
  });

  const newQueries = [
    ...options.queries,
    {
      query: {
        ...drillQuery,
        measures: drillQuery.filters
          .filter((f) => f.operator === "measureFilter")
          .map((x) => x.member),
        dimensions: options.drillDownOptions?.[
          options.drillLevel + 1 + options.granularity
        ]?.dimension
          ? [
              options.drillDownOptions[
                options.drillLevel + 1 + options.granularity
              ].dimension,
            ]
          : [],
        filters: drillQuery.filters.filter(
          (f) => f.operator !== "measureFilter"
        ),
        timeDimensions: drillQuery.timeDimensions.map((tdim) => ({
          ...tdim,
          granularity:
            options.drillType === "time"
              ? options.granularities[
                  options.drillLevel + options.granularity + 1
                ]
              : null,
        })),
        limit: options.query.limit,
      },
    },
  ];

  if (options.drillType === "time") {
    setters.setDrillValues([
      ...options.drillValues,
      options.granularities[options.drillLevel + options.granularity + 1],
    ]);
  } else if (options.drillType === "dimension") {
    setters.setDrillValues([
      ...options.drillValues,
      options.drillDownOptions[
        options.drillLevel + options.granularity + 1
      ].dimension.split(".")[1],
    ]);
  }

  setters.setDrillLevel(options.drillLevel + 1);
  setters.setQueries(newQueries);
};

export const drillBack = (reset, i, options, setters) => {
  if (options.drillLevel === 0) {
    return;
  }

  if (reset) {
    setters.setDrillLevel(0);
    setters.setDrillValues([]);
    setters.setQueries([options.queries[0]]);
    return;
  }

  if (i !== undefined) {
    setters.setDrillLevel(i);
    setters.setDrillValues(options.drillValues.slice(0, i));
    return;
  }

  setters.setDrillValues(options.drillValues.slice(0, options.drillLevel - 1));
  setters.setDrillLevel(options.drillLevel - 1);
  setters.setQueries(options.queries.slice(0, options.drillLevel));
};

export const getDynamicDateRange = (range) => {
  const today = new Date(Date.now());
  const format = "YYYY-MM-DD";

  const ranges = {
    thisMonth: [
      moment(today).startOf("month").format(format),
      moment(today).endOf("month").format(format),
    ],
    previousMonth: [
      moment(today).subtract(1, "month").startOf("month").format(format),
      moment(today).subtract(1, "month").endOf("month").format(format),
    ],
    thisQuarter: [
      moment(today).startOf("quarter").format(format),
      moment(today).endOf("quarter").format(format),
    ],
    previousQuarter: [
      moment(today).subtract(1, "quarter").startOf("quarter").format(format),
      moment(today).subtract(1, "quarter").endOf("quarter").format(format),
    ],
    thisYear: [
      moment(today).startOf("year").format(format),
      moment(today).endOf("year").format(format),
    ],
    previousYear: [
      moment(today).subtract(1, "year").startOf("year").format(format),
      moment(today).subtract(1, "year").endOf("year").format(format),
    ],
  };

  return ranges[range];
};

export function useIsMounted() {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => (isMounted.current = false);
  }, []);

  return isMounted;
}
