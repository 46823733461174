import React, { useContext, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import LanguageContext from "language-context";
import history from "router-history";
import companyHelper from "shared_helpers/company_helper";
import personHelper from "shared_helpers/person_helper";
import { activityHelper, miscHelper } from "helpers";
import { completeEvent, getEvents, removeEvent } from "store/events/tasks";
import Activities from "components/activities";
import Icon from "components/icon";
import IconStyled from "components/icon_styled";
import Tooltip from "components/tooltip";
import WidgetHeader from "components/widget_header";

const ProspectResultPreview = (state) => {
  const [hideBlocks, setHideBlocks] = useState([]);
  const prospectResultPreviewRef = useRef(null);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    /**
     * Close preview when clicking outside.
     */
    const _closePreview = (e) => {
      if (typeof state.props.close === "function") {
        if (miscHelper.clickUnmount(e, prospectResultPreviewRef, false)) {
          state.props.close();
        }
      }
    };

    window.addEventListener("mousedown", _closePreview);
    return () => window.removeEventListener("mousedown", _closePreview);
  }, []);

  useEffect(() => {
    getEvents({
      target: state.props.data.reg_number
        ? state.props.data.reg_number
        : state.props.data.user_id,
      type: "target",
    });
  }, [state.props.data]);

  const _getName = (onlyUser) => {
    if (state.props.data.reg_number && !onlyUser) {
      return `${state.props.data.brand} ${state.props.data.model} (${state.props.data.reg_number})`;
    } else {
      if (state.props.data.name?.fullName) {
        return state.props.data.name.fullName;
      } else {
        if (companyHelper.isValidOrgNr(state.props.data.user_id)) {
          return "";
        } else {
          let gender = "E";
          if (state.props.data.gender === "Kvinna") {
            gender = "K";
          }
          if (state.props.data.gender === "Man") {
            gender = "M";
          }
          return personHelper.buildPersonDefaultName(
            gender,
            state.props.data.birthYear,
            state.props.data.zipMuncipality
          );
        }
      }
    }
  };

  const _renderActivities = () => {
    return (
      <div className="prospectResultPreviewWrapper__prospectResultPreview__content__block">
        <div
          className="prospectResultPreviewWrapper__prospectResultPreview__content__block__header"
          onClick={() => {
            if (hideBlocks.includes("activities")) {
              setHideBlocks(hideBlocks.filter((num) => num !== "activities"));
            } else {
              setHideBlocks(hideBlocks.concat("activities"));
            }
          }}
        >
          <div className="prospectResultPreviewWrapper__prospectResultPreview__content__block__header__title">
            {hideBlocks.includes("activities") ? (
              <Icon val="maximize" />
            ) : (
              <Icon val="minimize" />
            )}
            <p>{tc.activity}</p>
          </div>
        </div>
        {!hideBlocks.includes("activities") ? (
          <div className="prospectResultPreviewWrapper__prospectResultPreview__content__block__content">
            <Activities
              target={
                state.props.data.reg_number
                  ? state.props.data.reg_number
                  : state.props.data.user_id
              }
              view="embedded"
            />
          </div>
        ) : null}
      </div>
    );
  };

  const _renderActivityNotifications = () => {
    return state.props.data.activityMapped.map((num, i) => {
      if (num.taken_by) {
        // Colleague working on same prospect.
        return (
          <div
            className="prospectResultPreviewWrapper__prospectResultPreview__content__block__content__row"
            key={i}
          >
            <div className="prospectResultPreviewWrapper__prospectResultPreview__content__block__content__row__left">
              <IconStyled class="info" iconVal="exclamation" />
              <div className="prospectResultPreviewWrapper__prospectResultPreview__content__block__content__row__left__content">
                {`${
                  state.props.data.reg_number ? tc.vehicleUser : tc.prospect
                } ${tc.appearsIn.toLowerCase()} ${tc.deal.toLowerCase()} ${tc.at.toLowerCase()}`}
                <span className="highlight marginLeft">{num.taken_by}</span>
              </div>
            </div>
          </div>
        );
      } else {
        // This prospect has been turned into a deal in another own list.
        return (
          <div
            className="prospectResultPreviewWrapper__prospectResultPreview__content__block__content__row"
            key={i}
          >
            <div className="prospectResultPreviewWrapper__prospectResultPreview__content__block__content__row__left">
              <IconStyled class="info" iconVal="list" />
              <div className="prospectResultPreviewWrapper__prospectResultPreview__content__block__content__row__left__content">
                {`${
                  state.props.data.reg_number ? tc.vehicleUser : tc.prospect
                } ${tc.appearsIn.toLowerCase()} ${tc.deal.toLowerCase()} ${tc.belongingTo.toLowerCase()} ${tc.list.toLowerCase()}`}
                <span className="highlight marginLeft marginRight">
                  {num.list}
                </span>
              </div>
            </div>
          </div>
        );
      }
    });
  };

  const _renderAlertsBlock = () => {
    if (
      state.events?.events?.length ||
      (Array.isArray(state.props.data.activityMapped) &&
        state.props.data.activityMapped.length)
    ) {
      return (
        <div className="prospectResultPreviewWrapper__prospectResultPreview__content__block">
          <div
            className="prospectResultPreviewWrapper__prospectResultPreview__content__block__header"
            onClick={() => {
              if (hideBlocks.includes("alerts")) {
                setHideBlocks(hideBlocks.filter((num) => num !== "alerts"));
              } else {
                setHideBlocks(hideBlocks.concat("alerts"));
              }
            }}
          >
            <div className="prospectResultPreviewWrapper__prospectResultPreview__content__block__header__title">
              <p>{tc.alerts}</p>
              {hideBlocks.includes("alerts") ? (
                <Icon val="maximize" />
              ) : (
                <Icon val="minimize" />
              )}
            </div>
          </div>
          {!hideBlocks.includes("alerts") ? (
            <div className="prospectResultPreviewWrapper__prospectResultPreview__content__block__content">
              {state.events?.events?.length ? _renderEventRows() : null}
              {Array.isArray(state.props.data.activityMapped) &&
              state.props.data.activityMapped.length
                ? _renderActivityNotifications()
                : null}
            </div>
          ) : null}
        </div>
      );
    } else {
      return null;
    }
  };

  const _renderEventRows = () => {
    return (
      <>
        {state.events.events.map((event) => {
          return (
            <div
              className="prospectResultPreviewWrapper__prospectResultPreview__content__block__content__row"
              key={event._id}
            >
              <div className="prospectResultPreviewWrapper__prospectResultPreview__content__block__content__row__left">
                <IconStyled
                  class={activityHelper.getEventClass(event.event_date)}
                  iconVal={event.action}
                />
                <div className="prospectResultPreviewWrapper__prospectResultPreview__content__block__content__row__left__content">
                  <span className="highlight marginRight">
                    {tc[event.action]}
                  </span>
                  {`${moment(new Date(event.event_date), "DD/MM/YYYY").format(
                    "LL HH:mm"
                  )}`}
                  {event.comment ? (
                    <div className="prospectResultPreviewWrapper__prospectResultPreview__content__block__content__row__center__additionalLine">
                      <span className="lightAndItalic">{event.comment}</span>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="prospectResultPreviewWrapper__prospectResultPreview__content__block__content__row__right">
                <Tooltip tooltipContent={tc.completeEvent}>
                  <IconStyled
                    class="link"
                    iconVal="complete"
                    onClick={async () => {
                      await completeEvent({
                        action: event.action,
                        comment: event.comment,
                        dealId: event.dealId,
                        eventId: event._id,
                      });
                    }}
                  />
                </Tooltip>
                <Tooltip tooltipContent={tc.removeEvent}>
                  <IconStyled
                    class="link"
                    iconVal="remove"
                    onClick={async () => {
                      await removeEvent({
                        dealId: event.dealId,
                        eventId: event._id,
                      });
                    }}
                  />
                </Tooltip>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  const _renderInformation = () => {
    const rows = [];
    let dataToRender;
    if (state.props.data.reg_number) {
      // Vehicle.
      const dateAcquired = state.props.data.date_acquired.toString();
      const acquiredDateString =
        dateAcquired.substring(0, 4) +
        "-" +
        dateAcquired.substring(4, 6) +
        "-" +
        dateAcquired.substring(6, 8);
      const supertempAd = state.props.data.supertemp?.url ? (
        <a href={state.props.data.supertemp.url}>{tc.link}</a>
      ) : null;
      const supertempPrice = state.props.data.supertemp?.price
        ? `${state.props.data.supertemp.price} ${tc.swedishCrowns}`
        : null;

      dataToRender = {
        vehicleUser: _getName(true),
        brand: state.props.data.brand,
        model: state.props.data.model,
        regNumberShort: state.props.data.reg_number,
        status: state.props.data.car_status,
        possessionTime: `${moment(new Date(), "DD/MM/YYYY").diff(
          moment(new Date(acquiredDateString), "DD/MM/YYYY"),
          "months"
        )} ${tc.monthsShort.toLowerCase()}`,
        carYear: state.props.data.date_car_year,
        finance: state.props.data.financed_by,
        climateClassification: state.props.data.climate_class,
        fuel: state.props.data.fuel,
        supertempAd: supertempAd,
        supertempPrice: supertempPrice,
      };
    } else {
      // Person/company.
      dataToRender = {
        name: _getName(),
        address: `${state.props.data.address}, ${state.props.data.zip}, ${state.props.data.zipMuncipality}`,
        age: state.props.data.age
          ? `${state.props.data.age} ${tc.years.toLowerCase()}`
          : null,
        phone: Array.isArray(state.props.data.phone)
          ? state.props.data.phone.map((num, i) => {
              if (i + 1 === state.props.data.phone.length) {
                return (
                  <a key={num + i} href={"tel:+46" + num}>
                    {num}
                  </a>
                );
              } else {
                return (
                  <a key={num + i} href={"tel:+46" + num}>
                    {num},{" "}
                  </a>
                );
              }
            })
          : null,
        email: Array.isArray(state.props.data.email)
          ? state.props.data.email.join(", ")
          : null,
        numberOfCars: `${
          state.props.data.numberOfCars
        } ${tc.aPiece.toLowerCase()}`,
        amountMatchingVehicles: `${
          state.props.data.numberOfMatchingCars
        } ${tc.aPiece.toLowerCase()}`,
      };
    }

    if (!hideBlocks.includes("information")) {
      Object.keys(dataToRender).map((key) => {
        if (!dataToRender[key]) {
          return;
        }

        rows.push(
          <div
            className="prospectResultPreviewWrapper__prospectResultPreview__content__block__content__infoRow"
            key={key}
          >
            <div className="prospectResultPreviewWrapper__prospectResultPreview__content__block__content__infoRow__left">
              {tc[key]}:
            </div>
            <div className="prospectResultPreviewWrapper__prospectResultPreview__content__block__content__infoRow__right">
              {dataToRender[key]}
            </div>
          </div>
        );
      });
    }

    return (
      <div className="prospectResultPreviewWrapper__prospectResultPreview__content__block">
        <div
          className="prospectResultPreviewWrapper__prospectResultPreview__content__block__header"
          onClick={() => {
            if (hideBlocks.includes("information")) {
              setHideBlocks(hideBlocks.filter((num) => num !== "information"));
            } else {
              setHideBlocks(hideBlocks.concat("information"));
            }
          }}
        >
          <div className="prospectResultPreviewWrapper__prospectResultPreview__content__block__header__title">
            {hideBlocks.includes("information") ? (
              <Icon val="maximize" />
            ) : (
              <Icon val="minimize" />
            )}
            <p>
              {state.props.data.reg_number
                ? tc.vehicleInformation
                : tc.prospectInformation}
            </p>
          </div>
        </div>
        {!hideBlocks.includes("information") ? (
          <div className="prospectResultPreviewWrapper__prospectResultPreview__content__block__content">
            {rows}
          </div>
        ) : null}
      </div>
    );
  };

  return state.props.data ? (
    <div
      className="prospectResultPreviewWrapper"
      ref={prospectResultPreviewRef}
    >
      <div className="prospectResultPreviewWrapper__prospectResultPreview">
        <div className="prospectResultPreviewWrapper__prospectResultPreview__header">
          <div
            className="prospectResultPreviewWrapper__prospectResultPreview__header__top"
            onClick={() => {
              if (state.props.data.reg_number) {
                history.push("/fordon/" + state.props.data.reg_number);
              } else if (companyHelper.isValidOrgNr(state.props.data.user_id)) {
                history.push("/foretag/" + state.props.data.user_id);
              } else {
                history.push("/person/" + state.props.data.user_id);
              }
            }}
          >
            <div className="prospectResultPreviewWrapper__prospectResultPreview__header__top__left">
              <WidgetHeader
                headline={_getName()}
                headlineSub={
                  state.props.data.reg_number
                    ? tc.vehicle
                    : companyHelper.isValidOrgNr(state.props.data.user_id)
                    ? tc.company
                    : tc.oneProspect
                }
              />
            </div>
            <div
              className="prospectResultPreviewWrapper__prospectResultPreview__header__top__right"
              onClick={(e) => {
                e.stopPropagation();
                state.props.close();
              }}
            >
              <Icon val="clear" />
            </div>
          </div>
        </div>
        <div className="prospectResultPreviewWrapper__prospectResultPreview__content">
          {_renderAlertsBlock()}
          {_renderInformation()}
          {_renderActivities()}
        </div>
      </div>
    </div>
  ) : null;
};

const MapStateToProps = (state, props) => {
  return {
    events: state.events,
    props: props,
  };
};

export default connect(MapStateToProps)(ProspectResultPreview);
