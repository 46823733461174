import React from "react";
import { Switch, Route } from "react-router-dom";
import Activity from "components/activity";
import AgileBoard from "./agile_board";

const Agile = () => {
  return (
    <Switch>
      <Route exact path="/bearbeta" component={AgileBoard} />
      <Route exact path="/bearbeta/aktivitet" component={Activity} />
      <Route exact path="/bearbeta/aktivitet/:status" component={Activity} />
    </Switch>
  );
};

export default Agile;
