import LanguageContext from "language-context";
import { useContext } from "react";
import ActionButton from "../../action_button";

/**
 *
 * @param {object} props.itemToRemove widget or gridView to remove
 * @param {string} props.type "widget" | "gridView"
 * @param {function} props.handleItemRemove handler function for item removal
 * @param {function} props.handleCancelRemove handler function for cancelling item removal
 * @description component used when confirming widget or gridView removal
 */
const RemoveItem = ({ itemToRemove, handleItemRemove, handleCancelRemove }) => {
  const tc = useContext(LanguageContext);

  return (
    <div className="settingsPageWrapper__settingsPage__section__content__holder">
      <div className="inputHolder">
        <div className="inputHolder__left">
          {itemToRemove.type === "widget" ? tc.removeWidget : "Ta bort vy"}:{" "}
          <strong>{itemToRemove.item.name}</strong>
        </div>
        <div className="inputHolder__right">
          <ActionButton
            label={tc.cancel}
            onClick={handleCancelRemove}
            type="regular"
          />
          <ActionButton
            label={tc.remove}
            onClick={() =>
              handleItemRemove(itemToRemove.type, itemToRemove.item)
            }
            type="highlight"
          />
        </div>
      </div>
    </div>
  );
};

export default RemoveItem;
