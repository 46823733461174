import AgileBoardAddActivity from 'components/agile/agile_board/agile_board_add_activity';
import { connect } from 'react-redux';
import { addActivityToProspect } from 'store/activities/tasks';
import { shareDeal } from 'store/agile/tasks';

const AddActivityToProspectPopup = ({ prospectId, close, user }) => {
  return (
    <AgileBoardAddActivity
      close={close}
      addActivity={async (payload) => {
        const { action, comment, event_date: date } = payload;
        await addActivityToProspect({ prospectId, action, comment, date });
        close();
      }}
      shareDeal={async (payload) => {
        const { event_date, action, comment, targetShareUser } = payload;
        await shareDeal({
          type: 'prospect',
          prospectId,
          action,
          event_date,
          comment,
          newOwnerId: targetShareUser.userId,
          newDealerId: targetShareUser.dealerId,
        });
        close();
      }}
    />
  );
};

export default AddActivityToProspectPopup;
