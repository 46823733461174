import {leadsActionTypes} from './actions';

interface leadsState {
    data: {
        items: [],
        latestBatchCount: number,
        totalCount: number,
    } | null,
    upload: {
        leadsUploadFilename: string | null,
        leadsUploadColumns: Array<string> | null,
        leadsUploadRows: Array<Array<string>> | null,
        leadsUploadChoosenColumns: any,
        leadsAreUploaded: boolean,
        leadsUploadCount: null | number,
    },
    previewData: {
        activities: Array<object> | null,
        lead: object | null,
    },
    selectedMethod: string | null,
    selectedContractList: string | null,
    contractListName: string | null,
    
}

const initialState: leadsState = {
    data: null,
    upload: {
        leadsUploadFilename: null,
        leadsUploadColumns: null,
        leadsUploadRows: null,
        leadsUploadChoosenColumns: null,
        leadsAreUploaded: false,
        leadsUploadCount: null,
    },
    previewData: {
        activities: null,
        lead: null,
    },
    selectedMethod: null,
    selectedContractList: null,
    contractListName: null,
};

export const leadsReducer = (state = initialState, action) => {
    switch(action.type) {
        case leadsActionTypes.LEADS_SET_LEADS: {
            return {
                ...state,
                data: action.payload,
            }
        }
        case leadsActionTypes.LEADS_SET_PREVIEW_DATA: {
            return {
                ...state,
                previewData: {
                    activities: action.payload.activities,
                    lead: action.payload.lead,
                }
            }
        }
        case leadsActionTypes.LEADS_UPLOAD_SET_DATA: {
            return {
                ...state,
                upload: {
                    leadsUploadFilename: action.payload.filename,
                    leadsUploadColumns: action.payload.headers,
                    leadsUploadChoosenColumns: action.payload.headers.map(() => ''),
                    leadsUploadRows: action.payload.rows,
                    leadsUploadCount: action.payload.count,
                    leadsAreUploaded: true,
                },
            }
        }
        case leadsActionTypes.LEADS_UPLOAD_SET_CHOOSEN_COLUMNS: {
            return {
                ...state,
                upload: {
                    ...state.upload,
                    leadsUploadChoosenColumns: [].concat(
                        state.upload.leadsUploadChoosenColumns.slice(0, action.payload.index),
                        action.payload.value,
                        state.upload.leadsUploadChoosenColumns.slice(action.payload.index + 1))
                },
            }
        }
        case leadsActionTypes.LEADS_UPLOAD_RESET_DATA: {
            return {
                ...state,
                upload: {
                    leadsUploadUploadId: null,
                    leadsUploadColumns: null,
                    leadsUploadRows: null,
                    leadsUploadChoosenColumns: null,
                    leadsAreUploaded: false,
                    leadsUploadCount: null
                },
                selectedMethod: null,
                selectedContractList: null,
                contractListName: null,
            }
        }
        case leadsActionTypes.LEADS_UPLOAD_SET_ATTRIBUTES: {
            return {
                ...state,
                selectedMethod: action.payload.selectedMethod,
                selectedContractList: action.payload.selectedContractList,
                contractListName: action.payload.contractListName,
            }
        }
        default: {
            return state;
        }
    }
};
