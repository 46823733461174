import { analyseActionTypes } from "./actions";

interface AnalyseState {
  areaPresets: Array<object> | null;
  fromDate: Date | string;
  toDate: Date | string;
}

const initialState: AnalyseState = {
  areaPresets: null,
  fromDate: '',
  toDate: '',
};

export const analyseReducer = (state = initialState, action) => {
  switch (action.type) {
    case analyseActionTypes.ANALYSE_SET_AREA_PRESETS: {
      return {
        ...state,
        areaPresets: action.payload.filter((x) => x.postorter.length < 501),
      };
    }
    case analyseActionTypes.ANALYSE_SET_FROM_DATE: {
      return {
        ...state,
        fromDate: action.payload.date
      };
    }
    case analyseActionTypes.ANALYSE_SET_TO_DATE: {
      return {
        ...state,
        toDate: action.payload.date
      };
    }
    default: {
      return state;
    }
  }
};
