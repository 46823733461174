import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ScriptManager } from 'store/scripts/tasks';
// import Popup from "components/popup";
import ScriptEditView from './script_edit_view';
import ScriptReadView from './script_read_view';

/**
 *
 * @param {{user?: {info?: {id: number}}, setShowScriptView: () => void,
 *  scripts: {title: string, text: string, id: string}[]}} props
 * @description Renders a Popup for reading, editing or adding scripts.
 */
const ScriptView = ({ user, setShowScriptView, scripts = [] }) => {
  const [editMode, setEditMode] = useState(false);
  const [activeScriptId, setActiveScriptId] = useState(
    scripts?.[0]?.id || null
  );

  useEffect(() => {
    if (user?.info?.id) {
      ScriptManager.getScripts().then((scripts) => {
        setActiveScriptId(scripts?.[0]?.id || null);
      });
    }
  }, [user?.info?.id]);

  const handleScriptSubmission = async (script) => {
    if (!script.id) {
      const newScript = await ScriptManager.createScript({ script });
      setActiveScriptId(newScript.id);
      ScriptManager.setScriptsLocal(scripts.concat(newScript));
    } else {
      ScriptManager.updateScript(script);
      setActiveScriptId(script.id);
      ScriptManager.setScriptsLocal(
        scripts.filter((s) => s.id !== script.id).concat(script)
      );
    }

    setEditMode(false);
  };

  const handleEditCancel = () => {
    setEditMode(false);
  };

  const handleNewClick = () => {
    setEditMode(true);
    setActiveScriptId(null);
  };

  const deleteScript = async (scriptId) => {
    const newScripts = scripts.filter((script) => script.id !== scriptId);
    ScriptManager.setScriptsLocal(newScripts);
    setActiveScriptId(newScripts[0]?.id || null);
    await ScriptManager.deleteScript(scriptId);
  };

  return (
    <>
      <div className="scriptWrapper">
        {editMode ? (
          <ScriptEditView
            scripts={scripts}
            activeScriptId={activeScriptId}
            setActiveScriptId={setActiveScriptId}
            setEditMode={setEditMode}
            handleSubmit={handleScriptSubmission}
            handleCancel={handleEditCancel}
            handleDelete={deleteScript}
          />
        ) : (
          <ScriptReadView
            scripts={scripts}
            activeScriptId={activeScriptId}
            setActiveScriptId={setActiveScriptId}
            setEditMode={setEditMode}
            handleNewClick={handleNewClick}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.user,
    setShowScriptView: props.setShowScriptView,
    scripts: state.scripts?.scripts,
  };
};

export default connect(mapStateToProps)(ScriptView);
