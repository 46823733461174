import React from "react";
import { useParams } from "react-router-dom";
import { textHelper } from "helpers";
import { connect } from "react-redux";
import Activities from "components/activities";
import Contacts from "components/contacts";
import Events from "components/events";
import FleetWidget from "components/fleet_widget";
import KoncernInfo from "./koncern_info";
import KoncernStructure from "./koncern_structure";
import Loading from "components/loading";
import PageHeader from "components/page_header";

/**
 * Render a koncern page.
 *
 */
const Koncern = ({ info }) => {
  let { id } = useParams();
  id = textHelper.removeDashFromOrgnr(id);

  return (
    <div className="companyWrapper">
      <div className="companyWrapper__company">
        <div className="companyWrapper__company__header">
          <PageHeader headline={info ? info.name : <Loading small={true} />} />
        </div>
        <div className="companyWrapper__company__content">
          <div className="companyWrapper__company__content__item">
            <KoncernInfo id={id} />
          </div>
          <div className="companyWrapper__company__content__item">
            <KoncernStructure id={id} />
          </div>
          <div className="companyWrapper__company__content__item">
            <Events onlyShowFlow={true} target={id} type="target" view="flow" />
          </div>
          <div className="companyWrapper__company__content__item">
            {info ? (
              <Contacts
                entityId={id}
                entityName={info.name}
                entityType="company"
              />
            ) : (
              <Loading small={true} />
            )}
          </div>
          <div className="companyWrapper__company__content__item">
            <FleetWidget koncern={true} prospectId={id} />
          </div>
          {/* HISTORISK VAGNPARK 
          <div className="companyWrapper__company__content__item">
            <FleetWidget
              historic={true}
              koncern={true}
              prospectId={id}
            />
          </div> */}
          <div className="companyWrapper__company__content__item">
            <Activities koncern={true} target={id} view="flow" />
          </div>
        </div>
      </div>
    </div>
  );
};

const MapStateToProps = (state) => {
  return {
    info: state.koncern.info,
    user: state.user,
  };
};

export default connect(MapStateToProps)(Koncern);
