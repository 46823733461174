import React, { useContext } from "react";
import LanguageContext from "language-context";
import graphic1 from "images/home_page/temp9.jpeg";
import graphic2 from "images/home_page/temp7.jpeg";
import graphic3 from "images/home_page/temp11.jpeg";

const HomeInfo = () => {
  const tc = useContext(LanguageContext);

  return (
    <div className="homeInfoWrapper">
      <div className="homeInfoWrapper__homeInfo">
        <div className="homeInfoWrapper__homeInfo__section">
          <div className="imageHolder">
            <img src={graphic1} alt="Bilprospekt" />
          </div>
          <div className="textHolder">
            <h3>{tc.homeService1}</h3>
            <p>{tc.homeService2}</p>
            <p>{tc.homeService3}</p>
          </div>
        </div>
        <div className="homeInfoWrapper__homeInfo__section">
          <div className="textHolder">
            <h3>{tc.homeService4}</h3>
            <p>{tc.homeService5}</p>
            <p>{tc.homeService6}</p>
          </div>
          <div className="imageHolder">
            <img src={graphic2} alt="Bilprospekt" />
          </div>
        </div>
        <div className="homeInfoWrapper__homeInfo__section">
          <div className="imageHolder">
            <img src={graphic3} alt="Bilprospekt" />
          </div>
          <div className="textHolder">
            <h3>{tc.homeService7}</h3>
            <p>{tc.homeService8}</p>
            <p>{tc.homeService9}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeInfo;
