import React, { useContext } from "react";
import LanguageContext from "language-context";
import WidgetHeader from 'components/widget_header';

const InfoBox = (props) => {
  const tc = useContext(LanguageContext);

  return (
    <div className="analyseChartWrapper__info">
      <div className="analyseChartWrapper__info__header">
        <WidgetHeader
          headline={tc.info}
          headlineSub="Inställningar och filter för widget"
        />
      </div>
      <div className="analyseChartWrapper__info__content">
        <div className="analyseChartWrapper__info__content__section">
          <h4>{tc.widget}</h4>
          <div className="analyseChartWrapper__info__content__section__subsection">
            <div className="analyseChartWrapper__info__content__section__subsection__header">
              <span>{`${tc.name}: `}</span>
            </div>
            <div className="analyseChartWrapper__info__content__section__subsection__value">
              <span>{props.data.title}</span>
            </div>
          </div>
          <div className="analyseChartWrapper__info__content__section__subsection">
            <div className="analyseChartWrapper__info__content__section__subsection__header">
              <span>{`${tc.typeOfWidget}: `}</span>
            </div>
            <div className="analyseChartWrapper__info__content__section__subsection__value">
              <span>{tc[props.data.selectedChartStyle]}</span>
            </div>
          </div>
        </div>
        <div className="analyseChartWrapper__info__content__section">
          <h4>{tc.data}</h4>
          <div className="analyseChartWrapper__info__content__section__subsection">
            <div className="analyseChartWrapper__info__content__section__subsection__header">
              <span>{`${tc.measures}: `}</span>
            </div>
            <div className="analyseChartWrapper__info__content__section__subsection__value">
              {props.data.measures.map((x) => (<span key={x}>{tc[x]}</span>))}
            </div>
          </div>
          <div className="analyseChartWrapper__info__content__section__subsection">
            <div className="analyseChartWrapper__info__content__section__subsection__header">
              <span>{`${tc.dimensions}: `}</span>
            </div>
            <div className="analyseChartWrapper__info__content__section__subsection__value">
              {props.data.dimensions.map((x) => (<span key={x}>{tc[x]}</span>))}
            </div>
          </div>
        </div>
        <div className="analyseChartWrapper__info__content__section">
          <h4>Tid</h4>
          <div className="analyseChartWrapper__info__content__section__subsection">
            <div className="analyseChartWrapper__info__content__section__subsection__header">
              <span>{`${tc.timeRange}: `}</span>
            </div>
            <div className="analyseChartWrapper__info__content__section__subsection__value">
              <span>
                {props.data.dateRange
                  ? `${props.data.dateRange[0]} - ${props.data.dateRange[1]}`
                  : tc[props.data.dynamicDateRange]
                }
              </span>
            </div>
          </div>
          <div className="analyseChartWrapper__info__content__section__subsection">
            <div className="analyseChartWrapper__info__content__section__subsection__header">
              <span>{`${tc.granularity}: `}</span>
            </div>
            <div className="analyseChartWrapper__info__content__section__subsection__value">
              <span>{tc[props.data.granularity]}</span>
            </div>
          </div>
        </div>
        <div className="analyseChartWrapper__info__content__section">
          {props.data.filters.map((x) => x.values && x.values.length
            ? (
                <>
                <h4>{tc.filter}</h4>
                  <div 
                    className="analyseChartWrapper__info__content__section__subsection"
                    key={x.member}
                  >
                    <div className="analyseChartWrapper__info__content__section__subsection__header">
                      <span>{`${tc[x.member]}: `}</span>
                    </div>
                    <div className="analyseChartWrapper__info__content__section__subsection__value">
                      {x.values.map((y) => (
                        <span
                          key={x.member + y}
                        >
                          {y}
                        </span>
                      ))}
                    </div>
                  </div>
                </>
              )
            : null
          )}
        </div>
      </div>
    </div>
  )
}

export default InfoBox;
