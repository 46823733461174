import React, { useContext } from "react";
import LanguageContext from "language-context";
import Icon from "components/icon";
import IconStyled from "components/icon_styled";
import Tooltip from "components/tooltip";

/**
 * Render an item with buttons.
 *
 * @param props.buttons - array - Array with objects, example:
 *  [
 *    {
 *      active: false, // optional
 *      icon: "share",
 *      onClick: () => {shareItem()}, // onClick or href property should be provided to each button.
 *      tooltip: "Share item",
 *    },
 *    {
 *      disabled: false, // optional
 *      icon: "car",
 *      href: "/fordon/abc123", // This works as a link.
 *      tooltip: "Visit car",
 *    },
 *  ]
 * @param props.name - string
 * @param props.onSelect - func (optional) - If this prop is provided the item can be selected and returns the item via onSelect.
 * @param props.selectDisabled - bool (optional) - If we want to display select functionality but have it disabled (like when a user PDF isn't validated).
 * @param props.selectDisabledText - string (optional) - If select is disabled, provide a tooltip text for user.
 * @param props.selected - bool (optional) - If we should display this item as selected. Probably used together with onSelect.
 * @param props.warning - bool (optional) - Is displayed and selectable as normal, but is showing a warning
 * @param props.warningText - bool (optional) - If warning is true, provide a tooltip text for user.
 */
const ItemWithButtons = (props) => {
  const tc = useContext(LanguageContext);

  return (
    <div className="itemWithButtonsWrapper">
      <div
        className={`itemWithButtonsWrapper__itemWithButtons ${
          typeof props.onSelect === "function" ? "selectable" : ""
        } ${props.selectDisabled ? "selectDisabled" : ""}`}
        onClick={() => {
          if (!props.selectDisabled && typeof props.onSelect === "function") {
            props.onSelect();
          }
        }}
      >
        {typeof props.onSelect === "function" ? (
          <div
            className={`itemWithButtonsWrapper__itemWithButtons__left ${
              props.selected ? "selected" : ""
            }`}
          >
            <div className="itemWithButtonsWrapper__itemWithButtons__left__icon">
              <div className="itemWithButtonsWrapper__itemWithButtons__left__icon__content">
                {props.selectDisabled && props.selectDisabledText ? (
                  <Tooltip tooltipContent={props.selectDisabledText}>
                    <Icon val={props.selected ? "check" : "checkbox"} />
                  </Tooltip>
                ) : (
                  <Icon val={props.selected ? "check" : "checkbox"} />
                )}
              </div>
            </div>
          </div>
        ) : null}
        <div className="itemWithButtonsWrapper__itemWithButtons__right">
          <div className="itemWithButtonsWrapper__itemWithButtons__right__name">
            {props.warning ? (
              <Tooltip tooltipContent={props.warningText}>
                <Icon class="warning" val="alerts" />
              </Tooltip>
            ) : null}
            {props.label ? (
              <span className="itemLabel">{props.label}: </span>
            ) : null}
            {props.name}
          </div>
          <div className="itemWithButtonsWrapper__itemWithButtons__right__buttons">
            {props.buttons.map((num) => {
              return (
                <Tooltip tooltipContent={num.tooltip} key={num.tooltip}>
                  {num.href ? (
                    <a
                      href={!num.disabled ? num.href : ""}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IconStyled
                        class={
                          num.disabled
                            ? "disabled"
                            : num.active
                            ? "active"
                            : "link"
                        }
                        iconVal={num.icon}
                        onClick={() => {
                          if (
                            !num.disabled &&
                            typeof num.onClick === "function"
                          ) {
                            num.onClick();
                          }
                        }}
                      />
                    </a>
                  ) : (
                    <IconStyled
                      class={
                        num.disabled
                          ? "disabled"
                          : num.active
                          ? "active"
                          : "link"
                      }
                      iconVal={num.icon}
                      key={num.tooltip}
                      onClick={() => {
                        if (
                          !num.disabled &&
                          typeof num.onClick === "function"
                        ) {
                          num.onClick();
                        }
                      }}
                    />
                  )}
                </Tooltip>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemWithButtons;
