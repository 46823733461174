import React, { useEffect, useState } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { pageView } from 'tracking';
import { connect } from 'react-redux';
import {
  setModuleMode,
  setNavigationStatus,
  setResponsiveMode,
} from 'store/user/tasks';
import cssVariables from '../../styles/_exported_variables.module.scss';
import Agile from 'components/agile/';
import Analyse from 'components/analyse/';
import CampaignInfo from 'components/campaign_info/';
import Car from 'components/car/';
import Company from 'components/company';
import Deal from 'components/deal/';
import FlashMessages from 'components/flash_messages';
import Fleet from 'components/fleet';
import Gdpr from 'components/gdpr';
import Grid from 'components/grid';
import Home from 'components/home';
import Koncern from 'components/koncern/';
import Leads from 'components/leads';
import List from 'components/list';
import Lists from 'components/lists';
import Navigation from 'components/navigation';
import News from 'components/news';
import Notification from 'components/notification';
import Person from 'components/person/';
import Preview from 'components/preview';
import Prospect from 'components/prospect';
import ResetPassword from 'components/reset_password';
import SearchResults from 'components/search_results';
import AnalyseDashboard from 'components/analyse/analyse_dashboard';
import Reports from 'components/reports/reports';
import ReportsExport from 'components/reports/reports_export';

/**
 * Root component, handles routing.
 *
 * Note that any changes to routes or new routes require a change in our frontend_routes file in backend.
 */
const Router = (state) => {
  const [routerRightStyle, setRouterRightStyle] = useState({});
  const [showPreview, setShowPreview] = useState(false);
  const location = useLocation();
  useEffect(() => {
    function initHelpCrunchOnReady() {
      window.HelpCrunch('onReady', () => {
        window.HelpCrunch('hideChatWidget');
      });
    }
    // setTimeout is necessary because history.push("") is called
    // on a 500ms timeout when user logs out
    if (!state.user.info) {
      setTimeout(() => {
        initHelpCrunchOnReady();
      }, 500);
    }
    initHelpCrunchOnReady();
  }, [state.user, location.pathname]);

  useEffect(() => {
    try {
      if (window.self !== window.top) {
        // Check if we are within iframe. If so set moduleMode flag, which allow us to
        // render site in module version (hide navigation, hide logout button etc).
        setModuleMode(true);
      }
    } catch (err) {
      console.error('Error when check for iframe', err);
    }
  }, []);

  useEffect(() => {
    const _setNavigationStatus = () => {
      const width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;

      if (width < +cssVariables.breakpoint6.replace('px', '')) {
        // Hide navigation (we add navigation toggle button to page header).
        setNavigationStatus({
          val: 'hidden',
          skipUpdateSettings: true,
        });
        setResponsiveMode(true);
      } else if (width < +cssVariables.breakpoint1.replace('px', '')) {
        // Set navigation to minimized.
        setNavigationStatus({
          val: 'minimize',
          skipUpdateSettings: true,
        });
        setResponsiveMode(false);
      } else {
        // Regular navigation.
        setNavigationStatus({
          val: state.user?.settings?.settings?.navigation?.status
            ? state.user.settings.settings.navigation.status
            : 'regular',
          skipUpdateSettings: true,
        });
        setResponsiveMode(false);
      }
    };

    if (state.user.info) {
      _setNavigationStatus();
    }
    window.addEventListener('resize', _setNavigationStatus);
    return () => {
      window.removeEventListener('resize', _setNavigationStatus);
    };
  }, [state.user.info, state.user.settings]);

  useEffect(() => {
    pageView({
      path: location.pathname + window.location.search,
    });

    const _setShowPreview = () => {
      [
        '/bearbeta/aktivitet',
        '/bearbeta/aktivitet/alla',
        '/bearbeta/aktivitet/aktiva',
        '/bearbeta/aktivitet/borttagna',
        '/bearbeta/aktivitet/forlorade',
        '/bearbeta/aktivitet/vunna',
        '/prospektera',
      ].includes(location.pathname.toLowerCase())
        ? setShowPreview(true)
        : setShowPreview(false);
    };

    _setShowPreview();
  }, [location]);

  // useEffect(() => {
  //   if (
  //     state.user.navigationStatus === "regular" &&
  //     state.user.responsiveMode
  //   ) {
  //     window.HelpCrunch("hideChatWidget");
  //   } else {
  //     window.HelpCrunch("showChatWidget");
  //   }
  // }, [state.user.navigationStatus, state.user.responsiveMode]);

  useEffect(() => {
    // Hide preview button when navigation is full screen.
    if (
      state.user.navigationStatus === 'regular' &&
      state.user.responsiveMode
    ) {
      setRouterRightStyle({
        zIndex: 'unset',
      });
    } else {
      setRouterRightStyle({
        zIndex: '7',
      });
    }
  }, [state.user.navigationStatus, state.user.responsiveMode]);

  return state.user.info?.id ? (
    <div className="routerWrapper">
      <div className="routerWrapper__router">
        <div
          className={`routerWrapper__router__left ${
            state.user.navigationStatus
          } ${state.user.responsiveMode ? 'responsiveMode' : ''} ${
            state.user.moduleMode ? 'moduleMode' : ''
          }`}
        >
          <Navigation />
        </div>
        <div
          className={`routerWrapper__router__center ${
            state.user.navigationStatus
          } ${state.user.responsiveMode ? 'responsiveMode' : ''} ${
            state.user.moduleMode ? 'moduleMode' : ''
          }`}
        >
          <Switch>
            <Route exact path="/affar/:id" component={Deal} />
            <Route exact path="/analysera" component={Analyse} />
            <Route
              exact
              path="/analysera/instrumentpanel"
              component={AnalyseDashboard}
            />
            <Route exact path="/bearbeta" component={Agile} />
            <Route exact path="/bearbeta/aktivitet" component={Agile} />
            <Route exact path="/bearbeta/aktivitet/:status" component={Agile} />
            {/* <Route exact path="/bevakning/:id" component={Supertemp} /> */}
            <Route exact path="/grid" component={Grid} />
            <Route exact path="/leads" component={Leads} />
            <Route exact path="/fordon/:regNr" component={Car} />
            <Route exact path="/foretag/:id" component={Company} />
            <Route exact path="/informationssakerhet" component={Gdpr} />
            <Route exact path="/koncern/:id" component={Koncern} />
            <Route exact path="/lista/:id" component={List} />
            <Route exact path="/listor" component={Lists} />
            <Route exact path="/listor/avtal" component={Lists} />
            <Route exact path="/listor/arkiverade" component={Lists} />
            <Route exact path="/listor/bestalla" component={Lists} />
            <Route exact path="/listor/prenumerationer" component={Lists} />
            <Route
              exact
              path="/listor/prenumerationslistor"
              component={Lists}
            />
            <Route exact path="/nyheter" component={News} />
            <Route exact path="/person/:id" component={Person} />
            <Route exact path="/prospektera" component={Prospect} />
            <Route exact path="/prospektera/forval" component={Prospect} />
            <Route exact path="/prospektera/resultat" component={Prospect} />
            <Route exact path="/sok" component={SearchResults} />
            <Route exact path="/sok/:q" component={SearchResults} />
            <Route exact path="/vagnpark/:id" component={Fleet} />
            <Route exact path="/vagnpark/koncern/:id" component={Fleet} />
            <Route exact path="/reports/exports" component={ReportsExport} />
            <Route exact path="/reports" component={Reports} />
            <Route component={Grid} />
          </Switch>
          {state.user.showCampaignInfo ? <CampaignInfo /> : null}
        </div>
        {showPreview ? (
          <div
            className="routerWrapper__router__right"
            style={routerRightStyle}
          >
            <Preview />
          </div>
        ) : null}
        {/* {state.user.mapControlId ? ( */}
        {/*   <Popup */}
        {/*     close={() => { */}
        {/*       setMapControlId(null); */}
        {/*     }} */}
        {/*     map={true} */}
        {/*   > */}
        {/*     <ControlComponent */}
        {/*       id={state.user.mapControlId} */}
        {/*       close={() => { */}
        {/*         setMapControlId(null); */}
        {/*       }} */}
        {/*     /> */}
        {/*   </Popup> */}
        {/* ) : null} */}
        <Notification />
        <FlashMessages />
      </div>
    </div>
  ) : (
    <div className="routerWrapper">
      <div className="routerWrapper__router">
        <Switch>
          <Route exact path="/aterstall/:link" component={ResetPassword} />
          <Route component={Home} />
        </Switch>
        <FlashMessages />
      </div>
    </div>
  );
};

const MapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(MapStateToProps)(Router);
