import React, { useContext, useState } from "react";
import LanguageContext from "language-context";
import Icon from "components/icon";
import WidgetHeader from "components/widget_header";

const CarVehicleData = (props) => {
  const [hideBlocks, setHideBlocks] = useState([]);
  const tc = useContext(LanguageContext);

  const _renderEngineData = () => {
    return (
      <div className="carVehicleDataWrapper__carVehicleData__content__block">
        <div
          className="carVehicleDataWrapper__carVehicleData__content__block__header"
          onClick={() => {
            if (hideBlocks.includes("engineData")) {
              setHideBlocks(hideBlocks.filter((num) => num !== "engineData"));
            } else {
              setHideBlocks(hideBlocks.concat("engineData"));
            }
          }}
        >
          <div className="carVehicleDataWrapper__carVehicleData__content__block__header__title">
            {hideBlocks.includes("engineData") ? (
              <Icon val="maximize" />
            ) : (
              <Icon val="minimize" />
            )}
            <p>{tc.engineData}</p>
          </div>
        </div>
        {!hideBlocks.includes("engineData") ? (
          <div className="carVehicleDataWrapper__carVehicleData__content__block__content">
            <div className="carVehicleDataWrapper__carVehicleData__content__block__content__item">
              <p className="carVehicleDataWrapper__carVehicleData__content__block__content__item__label">
                {tc.fuel}
              </p>
              <p>{props.fuel ? props.fuel : tc.dataMissing}</p>
            </div>
            <div className="carVehicleDataWrapper__carVehicleData__content__block__content__item">
              <p className="carVehicleDataWrapper__carVehicleData__content__block__content__item__label">
                {tc.fourwheel}
              </p>
              <p>{props.fwd ? props.fwd : tc.dataMissing}</p>
            </div>
            <div className="carVehicleDataWrapper__carVehicleData__content__block__content__item">
              <p className="carVehicleDataWrapper__carVehicleData__content__block__content__item__label">
                {tc.climateClassification}
              </p>
              <p>
                {props.climate_class ? props.climate_class : tc.dataMissing}
              </p>
            </div>
            <div className="carVehicleDataWrapper__carVehicleData__content__block__content__item">
              <p className="carVehicleDataWrapper__carVehicleData__content__block__content__item__label">
                {tc.fuelDepletionUsage}
              </p>
              <p>
                {props.fuel_depletion_usage1
                  ? props.fuel_depletion_usage1
                  : tc.dataMissing}
              </p>
            </div>
            <div className="carVehicleDataWrapper__carVehicleData__content__block__content__item">
              <p className="carVehicleDataWrapper__carVehicleData__content__block__content__item__label">
                {tc.co2Usage}
              </p>
              <p>{props.co2_usage1 ? props.co2_usage1 : tc.dataMissing}</p>
            </div>
            <div className="carVehicleDataWrapper__carVehicleData__content__block__content__item">
              <p className="carVehicleDataWrapper__carVehicleData__content__block__content__item__label">
                {tc.engineStrengthHk}
              </p>
              <p>
                {props.engine_strength_hk
                  ? props.engine_strength_hk
                  : tc.dataMissing}
              </p>
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  const _renderVehicleIdentity = () => {
    return (
      <div className="carVehicleDataWrapper__carVehicleData__content__block">
        <div
          className="carVehicleDataWrapper__carVehicleData__content__block__header"
          onClick={() => {
            if (hideBlocks.includes("vehicleIdentity")) {
              setHideBlocks(
                hideBlocks.filter((num) => num !== "vehicleIdentity")
              );
            } else {
              setHideBlocks(hideBlocks.concat("vehicleIdentity"));
            }
          }}
        >
          <div className="carVehicleDataWrapper__carVehicleData__content__block__header__title">
            {hideBlocks.includes("vehicleIdentity") ? (
              <Icon val="maximize" />
            ) : (
              <Icon val="minimize" />
            )}
            <p>{tc.vehicleIdentity}</p>
          </div>
        </div>
        {!hideBlocks.includes("vehicleIdentity") ? (
          <div className="carVehicleDataWrapper__carVehicleData__content__block__content">
            <div className="carVehicleDataWrapper__carVehicleData__content__block__content__item">
              <p className="carVehicleDataWrapper__carVehicleData__content__block__content__item__label">
                {tc.chassiNo}
              </p>
              <p>{props.chassi ? props.chassi : tc.dataMissing}</p>
            </div>
            <div className="carVehicleDataWrapper__carVehicleData__content__block__content__item">
              <p className="carVehicleDataWrapper__carVehicleData__content__block__content__item__label">
                {tc.status}
              </p>
              <p>{props.car_status ? props.car_status : tc.dataMissing}</p>
            </div>
            <div className="carVehicleDataWrapper__carVehicleData__content__block__content__item">
              <p className="carVehicleDataWrapper__carVehicleData__content__block__content__item__label">
                {tc.vehicleRegistered}
              </p>
              <p>
                {props.date_registered
                  ? props.date_registered.toString().substring(0, 4) +
                    "-" +
                    props.date_registered.toString().substring(4, 6) +
                    "-" +
                    props.date_registered.toString().substring(6, 8)
                  : tc.dataMissing}
              </p>
            </div>
            <div className="carVehicleDataWrapper__carVehicleData__content__block__content__item">
              <p className="carVehicleDataWrapper__carVehicleData__content__block__content__item__label">
                {tc.import}
              </p>
              <p>{props.import ? props.import : tc.dataMissing}</p>
            </div>
            <div className="carVehicleDataWrapper__carVehicleData__content__block__content__item">
              <p className="carVehicleDataWrapper__carVehicleData__content__block__content__item__label">
                {tc.vehicleUsageType}
              </p>
              <p>{props.usage ? props.usage : tc.dataMissing}</p>
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  const _renderVehicleMisc = () => {
    return (
      <div className="carVehicleDataWrapper__carVehicleData__content__block">
        <div
          className="carVehicleDataWrapper__carVehicleData__content__block__header"
          onClick={() => {
            if (hideBlocks.includes("vehicleMisc")) {
              setHideBlocks(hideBlocks.filter((num) => num !== "vehicleMisc"));
            } else {
              setHideBlocks(hideBlocks.concat("vehicleMisc"));
            }
          }}
        >
          <div className="carVehicleDataWrapper__carVehicleData__content__block__header__title">
            {hideBlocks.includes("vehicleMisc") ? (
              <Icon val="maximize" />
            ) : (
              <Icon val="minimize" />
            )}
            <p>{tc.vehicleSpecificationsMisc}</p>
          </div>
        </div>
        {!hideBlocks.includes("vehicleMisc") ? (
          <div className="carVehicleDataWrapper__carVehicleData__content__block__content">
            <div className="carVehicleDataWrapper__carVehicleData__content__block__content__item">
              <p className="carVehicleDataWrapper__carVehicleData__content__block__content__item__label">
                {tc.carBody}
              </p>
              <p>{props.kaross ? props.kaross : tc.dataMissing}</p>
            </div>
            <div className="carVehicleDataWrapper__carVehicleData__content__block__content__item">
              <p className="carVehicleDataWrapper__carVehicleData__content__block__content__item__label">
                {tc.service_weight}
              </p>
              <p>
                {props.service_weight
                  ? props.service_weight + " ( kg)"
                  : tc.dataMissing}
              </p>
            </div>
            <div className="carVehicleDataWrapper__carVehicleData__content__block__content__item">
              <p className="carVehicleDataWrapper__carVehicleData__content__block__content__item__label">
                {tc.maxLoadWeight}
              </p>
              <p>
                {props.max_load_weight
                  ? props.max_load_weight + " (kg)"
                  : tc.dataMissing}
              </p>
            </div>
            <div className="carVehicleDataWrapper__carVehicleData__content__block__content__item">
              <p className="carVehicleDataWrapper__carVehicleData__content__block__content__item__label">
                {tc.totalWeight}
              </p>
              <p>{props.weight ? props.weight + " (kg)" : tc.dataMissing}</p>
            </div>
            <div className="carVehicleDataWrapper__carVehicleData__content__block__content__item">
              <p className="carVehicleDataWrapper__carVehicleData__content__block__content__item__label">
                {tc.pulley}
              </p>
              <p>{props.pulley ? props.pulley : tc.dataMissing}</p>
            </div>
            <div className="carVehicleDataWrapper__carVehicleData__content__block__content__item">
              <p className="carVehicleDataWrapper__carVehicleData__content__block__content__item__label">
                {tc.seats}
              </p>
              <p>{props.passengers ? props.passengers : tc.dataMissing}</p>
            </div>
            <div className="carVehicleDataWrapper__carVehicleData__content__block__content__item">
              <p className="carVehicleDataWrapper__carVehicleData__content__block__content__item__label">
                {tc.submission}
              </p>
              <p>{props.submission ? props.submission : tc.dataMissing}</p>
            </div>
            <div className="carVehicleDataWrapper__carVehicleData__content__block__content__item">
              <p className="carVehicleDataWrapper__carVehicleData__content__block__content__item__label">
                {tc.modelSeries}
              </p>
              <p>{props.model_series ? props.model_series : tc.dataMissing}</p>
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <div className="carVehicleDataWrapper">
      <div className="carVehicleDataWrapper__carVehicleData">
        <div className="carVehicleDataWrapper__carVehicleData__header">
          <WidgetHeader
            headline={tc.vehicleSpecifications}
            headlineSub={tc.forVehicleWithRegNumber + " " + props.reg_number}
          />
        </div>
        <div className="carVehicleDataWrapper__carVehicleData__content">
          {_renderVehicleIdentity()}
          {_renderEngineData()}
          {_renderVehicleMisc()}
        </div>
      </div>
    </div>
  );
};

export default CarVehicleData;
