import React from "react";
function SummaryMessage(props) {
  const { message } = props;
  return (
    <div className="summaryWrapper__message">
      <h2>{message.welcomeMessage ? message.welcomeMessage : null}!</h2>
      <p>{message.summaryMessage ? message.summaryMessage : null}</p>
    </div>
  );
}

export default React.memo(SummaryMessage);
