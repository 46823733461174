import React, { useContext } from "react";
import LanguageContext from "language-context";

const GranularitySelector = (props) => {
  const tc = useContext(LanguageContext);

  if (props.drillType === 'time') {
    return (
      <>
        <span>Fördelning</span>
        {props.granularities.map((x, i) => (
            <span 
              className={i === props.granularity ? "highlight granularity" : "granularity"}
              key={`${props.title}${props.subTitle}granularity${i}`}
              onClick={() => {
                props.setGranularity(i)
                props.setDrillLevel(0)
                props.setDrillValues([])
              }}
            >
              {(x === undefined || !x) ? "Ingen" : tc[x]}
            </span>
        ))}
      </>
    )
  }

  if (props.drillType === 'dimension') {
    return (
      <>
        <span>Fördelning</span>
        {props.drillDownOptions.map((x, i) => (
            <span 
              className={i === props.granularity ? "highlight granularity" : "granularity"}
              key={`${props.title}${props.subTitle}granularity${i}`}
              onClick={() => {
                props.setGranularity(i)
                props.setDrillLevel(0)
                props.setDrillValues([])
              }}
            >
              {tc[x.dimension.split('.')[1]]}
            </span>
        ))}
      </>
    )
  }
}

export default GranularitySelector;
