import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const customerReviews = [
  //{
    //dealer: "Möller Bil AB",
    //user: "Niclas Jansson",
    //text: [
      //"Jag har arbetat i Bilprospekt i snart 8 år och det underlättar fortfarande mitt jobb varje dag.",
      //"Det unika med Bilprospekt är att det är så användarvänligt och otroligt lätt att dela information vidare till kollegor.",
    //],
  //},
  {
    dealer: "Sven Almevik Bil AB",
    user: "Robert Almevik",
    text: [
      "Bilprospekt är ett enkelt system att manövrera i och hjälper mig, på ett snabbt och smidigt sätt, att få tag i rätt kunder på rätt tid!",
    ],
  },
];

const slideSettings = {
  autoplay: true,
  dots: false,
  infinite: true,
  speed: 2000,
  autoplaySpeed: 9000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const HomeReviews = () => {
  return (
    <div className="homeReviewsWrapper">
      <div className="homeReviewsWrapper__homeReviews">
        <div className="homeReviewsWrapper__homeReviews__content">
          <div className="homeReviewsWrapper__homeReviews__content__items">
            <Slider {...slideSettings}>
              {customerReviews.map((review, i) => {
                return (
                  <div
                    className="homeReviewsWrapper__homeReviews__content__items__itemWrapper"
                    key={i}
                  >
                    <div className="homeReviewsWrapper__homeReviews__content__items__itemWrapper__item">
                      <div className="homeReviewsWrapper__homeReviews__content__items__itemWrapper__item__quote">
                        &ldquo;
                      </div>
                      <div className="homeReviewsWrapper__homeReviews__content__items__itemWrapper__item__text">
                        {review.text.map((paragraph, index) => (
                          <div
                            className="homeReviewsWrapper__homeReviews__content__items__itemWrapper__item__text__row"
                            key={index}
                          >
                            <p>
                              <span>{paragraph}</span>
                            </p>
                          </div>
                        ))}
                      </div>
                      <div className="homeReviewsWrapper__homeReviews__content__items__itemWrapper__item__reviewer">
                        {review.user ? (
                          <div className="homeReviewsWrapper__homeReviews__content__items__itemWrapper__item__reviewer__user">
                            {review.user}
                          </div>
                        ) : null}
                        {review.dealer ? (
                          <div className="homeReviewsWrapper__homeReviews__content__items__itemWrapper__item__reviewer__dealer">
                            {review.dealer}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeReviews;
