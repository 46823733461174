import axios from 'axios';
import stripHtml from 'string-strip-html';
import qs from 'qs';

export const fetchRequest = async (url, options) => {
  try {
    const res = await fetch(url, {
      ...options,
      credentials: 'include',
    });

    if (res.status !== 200) {
      throw new Error(res);
    }

    const contentType = res.headers.get('Content-Type');

    if (contentType && contentType.indexOf('application/json') !== -1) {
      return {
        status: res.status,
        data: await res.json(),
      };
    }

    return {
      status: res.status,
      data: res.text(),
    };
  } catch (err) {
    throw err;
  }
};

/**
 * Helper to make requests.
 *
 * @param payload.contentType (optional) - Note string "undefined" sets content type to undefined.
 * @param payload.data (optional)
 * @param payload.method
 * @param payload.skipStripHtml (optional)
 * @param payload.url
 */
export const request = async (payload) => {
  try {
    let params = {
      url: payload.url,
      method: payload.method,
      headers: {
        'Content-Type':
          payload.contentType === 'undefined'
            ? undefined
            : payload.contentType
              ? payload.contentType
              : 'application/json',
        Cache: 'no-cache',
        'X-Requested-With': 'XMLHttpRequest',
      },
      withCredentials: true, // Important for authentication to backend.
    };

    if (payload.data && Object.keys(payload.data).length) {
      if (!payload.skipStripHtml) {
        // Strip out html elements from strings.
        for (const prop in payload.data) {
          if (typeof payload.data[prop] === 'string') {
            payload.data[prop] = stripHtml(payload.data[prop]);
          }
        }
      }

      if (payload.method === 'get' || payload.method === 'delete') {
        Object.assign(params, { params: payload.data });
      } else {
        if (
          payload.contentType &&
          payload.contentType.includes('application/x-www-form-urlencoded')
        ) {
          Object.assign(params, { data: qs.stringify(payload.data) });
        } else {
          Object.assign(params, { data: payload.data });
        }
      }
    }

    const res = await axios(params);

    if (res && res.data) {
      return res.data;
    } else {
      return null;
    }
  } catch (err) {
    return err;
  }
};

/**
 * Where you know you have to send a body rather than query, you can use this.
 *
 * @@param payload.contentType (optional) - Note string "undefined" sets content type to undefined.
 * @param payload.data (optional)
 * @param payload.method
 * @param payload.url
 */
export const requestBody = async (payload) => {
  try {
    let params = {
      url: payload.url,
      method: payload.method,
      headers: {
        'Content-Type':
          payload.contentType === 'undefined'
            ? undefined
            : payload.contentType
              ? payload.contentType
              : 'application/json',
        Cache: 'no-cache',
        'X-Requested-With': 'XMLHttpRequest',
      },
      withCredentials: true, // Important for authentication to backend.
    };

    if (payload.data && Object.keys(payload.data).length) {
      // Strip out html elements from strings.
      for (const prop in payload.data) {
        if (typeof payload.data[prop] === 'string') {
          payload.data[prop] = stripHtml(payload.data[prop]);
        }
      }

      if (
        payload.contentType &&
        payload.contentType.includes('application/x-www-form-urlencoded')
      ) {
        Object.assign(params, { data: qs.stringify(payload.data) });
      } else {
        Object.assign(params, { data: payload.data });
      }
    }

    const res = await axios(params);

    if (res && res.data) {
      return res.data;
    } else {
      return null;
    }
  } catch (err) {
    return err;
  }
};
