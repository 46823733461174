import Patrik from 'images/home_page/team/PatrikForslund.png';
import Olle from 'images/home_page/team/olle-axelsson.jpg';
import Jakob from 'images/home_page/team/jakob-thorsteinsson.jpg';
import { connect } from 'react-redux';
import LanguageContext from 'language-context';
import { useContext } from 'react';
const sellerInfo = [
  {
    userId: 7153,
    name: 'Patrik Forslund',
    phone: '031 - 382 17 10',
    email: 'patrik.forslund@bilvision.se',
    image: Patrik,
  },
  {
    userId: 9408,
    name: 'Olle Axelsson',
    phone: '031 - 383 17 33',
    email: 'olle.axelsson@bilvision.se',
    image: Olle,
  },
  {
    userId: 9624,
    name: 'Jakob Thorsteinsson',
    phone: '031 - 383 17 36',
    email: 'jakob.thorsteinsson@bilvision.se',
    image: Jakob,
  },
];
const SettingsResponsibleSeller = ({ dealerOwnerId }) => {
  const tc = useContext(LanguageContext);
  const seller = sellerInfo.find((seller) => seller.userId === dealerOwnerId);
  if (!seller) return null;

  return (
    <div className="responsibleSellerWrapper">
      <img src={seller.image} alt="Din kontaktperson" />
      <p>
        {tc.ifYouNeedHelpContact} {seller.name} {tc.on.toLowerCase()}
        <span> {seller.phone}</span> {tc.or.toLowerCase()}
        <span> {seller.email}</span>.
      </p>
    </div>
  );
};
const mapStateToProps = (state, props) => {
  return { dealerOwnerId: state.user?.info?.dealerOwnerId };
};
export default connect(mapStateToProps)(SettingsResponsibleSeller);
