import React from 'react';
import CommentEdit from './comment_edit';
import CommentNew from './comment_new';
import CommentRemove from './comment_remove';

/**
 * Render component for new comment, edit comment or remove comment.
 *
 * @param props.close - func
 * @param props.edit - bool
 * @param props.editId - string
 * @param props.headline - string
 * @param props.removeId - number
 * @param props.target - number
 */
const Comment = (props) => {
  switch (props.type) {
    case 'edit':
      return <CommentEdit close={props.close} headline={props.headline} />;
    case 'new':
      if (props.target) {
        return (
          <CommentNew
            embedded={props.embedded}
            target={props.target}
            targetType={props.targetType}
          />
        );
      } else {
        return console.error('Missing props.target for Comment');
      }
    case 'remove':
      return <CommentRemove close={props.close} headline={props.headline} />;
    default:
      if (props.target) {
        return (
          <CommentNew
            embedded={props.embedded}
            target={props.target}
            targetType={props.targetType}
          />
        );
      } else {
        return console.error('Missing props.target for Comment');
      }
  }
};

export default Comment;
