import React, { useContext } from 'react';
import languageContext from 'language-context';
import IconStyled from 'components/icon_styled/icon_styled';
import Tooltip from 'components/tooltip';
import moment from 'moment';
import { activityHelper } from 'helpers';
import { connect } from 'react-redux';

const AgileBoardPopupEvents = ({
  previewData,
  state,
  getColumnsData,
  completeEvent,
  setPreviewData,
  removeEvent,
}) => {
  const tc = useContext(languageContext);

  if (!previewData.item?.events?.length) {
    return null;
  }

  return (
    <div className="agileBoardPopupWrapper__agilePopup__content__block">
      <div className="agileBoardPopupWrapper__agilePopup__content__block__content">
        {previewData.item.events.map((event) => (
          <div
            className="agileBoardPopupWrapper__agilePopup__content__block__content__row"
            key={event.id}
          >
            <div className="agileBoardPopupWrapper__agilePopup__content__block__content__row__left">
              <IconStyled
                class={activityHelper.getEventClass(event.event_date)}
                iconVal={event.action}
              />
              <div className="agileBoardPopupWrapper__agilePopup__content__block__content__row__left__content">
                <span className="highlight marginRight">
                  {tc[event.action]}
                </span>
                {`${moment(new Date(event.event_date), 'DD/MM/YYYY').format(
                  'LL HH:mm'
                )}`}
                {event.comment && (
                  <div className="agileBoardPopupWrapper__agilePopup__content__block__content__row__center__additionalLine">
                    <span className="lightAndItalic">{event.comment}</span>
                  </div>
                )}
              </div>
            </div>
            <div className="agileBoardPopupWrapper__agilePopup__content__block__content__row__right">
              {previewData.item &&
                previewData.prospectInformation &&
                previewData.contacts &&
                state.activities.activitiesByTarget && (
                  <>
                    <Tooltip tooltipContent={tc.completeEvent}>
                      <IconStyled
                        class="link"
                        iconVal="complete"
                        onClick={async () => {
                          await completeEvent({
                            action: event.action,
                            comment: event.comment,
                            dealId:
                              previewData.item.itemType === 'deal' &&
                              previewData.item.id,
                            eventId: event.id,
                          });
                          const cloned = JSON.parse(
                            JSON.stringify(previewData)
                          );
                          if (Array.isArray(cloned?.item?.events)) {
                            cloned.item.events = cloned.item.events.filter(
                              (num) => num.id !== event.id
                            );
                          }
                          setPreviewData(cloned);
                          await getColumnsData({});
                        }}
                      />
                    </Tooltip>
                    <Tooltip tooltipContent={tc.removeEvent}>
                      <IconStyled
                        class="link"
                        iconVal="remove"
                        onClick={async () => {
                          await removeEvent({
                            dealId:
                              previewData.item.itemType === 'deal' &&
                              previewData.item.id,
                            eventId: event.id,
                          });
                          const cloned = JSON.parse(
                            JSON.stringify(previewData)
                          );
                          if (Array.isArray(cloned?.item?.events)) {
                            cloned.item.events = cloned.item.events.filter(
                              (num) => num.id !== event.id
                            );
                          }
                          setPreviewData(cloned);
                          await getColumnsData({});
                        }}
                      />
                    </Tooltip>
                  </>
                )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(AgileBoardPopupEvents);
