import React, { useContext, useRef, useEffect, useState } from 'react';
import LanguageContext from 'language-context';
import Loading from 'components/loading';
import { Bar, Line } from 'react-chartjs-2';
import { chartHelper } from 'helpers';
import { Table } from 'components/table';
import Info from 'components/info';
import { getDrillDownQuery } from 'helpers/analysera_helper';
// import MapChart from './MapChart';

const ChartRenderer = ({
  resultSet,
  error,
  selectedChartStyle,
  queries,
  drillLevel,
  granularity,
  drillDownOptions,
  granularities,
  drillValues,
  drillType,
  query,
  setDrillLevel,
  setDrillValues,
  setQueries,
  showLegend,
  mapDrillQuery,
  setMapObj,
  initialMapState,
}) => {
  const tc = useContext(LanguageContext);

  if (!resultSet) {
    if (error) {
      return (
        <Info>
          <h4>{tc.error}</h4>
          <p>{tc.genericFailMessage}</p>
        </Info>
      );
    }

    return <Loading />;
  }

  if (selectedChartStyle === 'table') {
    const rows = resultSet.tablePivot().map((row) => {
      const newObj = {};
      Object.keys(row).map(
        (col) => (newObj[col] = chartHelper.formatXLabels(row[col]))
      );
      return newObj;
    });
    const columns = resultSet.tableColumns().map((col) => ({
      id: col.dataIndex,
      editable: false,
      numeric: false,
      label: tc[col.key],
      visible: true,
    }));
    return <Table columns={columns} rows={rows} rowsPerPage={5} />;
  }

  // if (selectedChartStyle === 'map') {
  //   if (queries[drillLevel].query.measures.length > 1) {
  //     return (
  //       <Info>
  //         <h4>{tc.error}</h4>
  //         <p>{tc.mapWidgetError}</p>
  //       </Info>
  //     )
  //   }
  //   const data = resultSet.tablePivot();

  //   const dataValues = Object.keys(data[0]);
  //   const postortKey = queries[drillLevel].query.dimensions ? queries[drillLevel].query.dimensions[0] : null;
  //   const quantityKey = queries[drillLevel].query.measures ? queries[drillLevel].query.measures[0] : null;
  //   const isEqual = postortKey === dataValues[0] && quantityKey === dataValues[1];

  //   return (
  //     <>
  //       {postortKey && quantityKey && isEqual && (postortKey === 'Buyers.postort' || postortKey === 'Sellers.postort' || postortKey === 'Users.postort')
  //         ? <MapChart
  //             data={data}
  //             options={{
  //               postortKey,
  //               quantityKey,
  //               fullscreenOption: true,
  //               colorGradient: "YlGn",
  //               showLegend,
  //               mapDrillQuery,
  //               setMapObj,
  //               initialZoom: initialMapState && initialMapState.zoom ? Number(initialMapState.zoom) : false, // 4...7 something like that
  //               initialView: initialMapState && initialMapState.lng && initialMapState.lat ? `${initialMapState.lng}, ${initialMapState.lat}` : false, //"16.599226176296327,59.62217419148823" "longitude,latitude" as a comma separated string
  //               getMapDrillQuery: (postort) => {
  //                 return ({
  //                   ...mapDrillQuery,
  //                   filters: mapDrillQuery.filters.concat([{"member": postortKey, "operator": "equals", "values": [postort]}])
  //                 })
  //               }
  //             }}
  //           />
  //         : null
  //       }
  //     </>
  //   )
  // }

  const series = resultSet.series();

  if (!series.length) {
    return <div>{tc.noResults}</div>;
  }
  const numMeasures = series.length;
  const numDatasets = series[0].series.length;

  const colors = chartHelper.generateChartColors(20, true, false, true);

  const datasets = resultSet.series().map((s, index) => ({
    label: chartHelper.formatXLabels(s.key),
    data: s.series.map((r) => r.value),
    x: s.series.map((r) => r.x),
    y: resultSet.seriesNames()[index].yValues,
    backgroundColor: numMeasures < 2 ? colors : colors[index],
    borderColor: selectedChartStyle === 'line' ? colors[index] : undefined,
    pointBackgroundColor:
      selectedChartStyle === 'line' ? colors[index] : undefined,
  }));

  const labels =
    resultSet.queryType === 'compareDateRangeQuery'
      ? resultSet
          .categories()
          .map((c) =>
            chartHelper.formatXLabelsCompareDateRanges(
              c.x,
              granularities[drillLevel]
            )
          )
      : resultSet.categories().map((c) => chartHelper.formatXLabels(c.x));
  const data = { labels, datasets, resultSet };

  if (numMeasures === 1 && numDatasets === 1) {
    return (
      <div
        className="analyseChartWrapper__content__chart__elementContainer__number"
        onClick={() =>
          getDrillDownQuery(
            [{ datasetIndex: 0, index: 0 }],
            data,
            {
              queries,
              drillLevel,
              granularity,
              drillDownOptions,
              granularities,
              drillValues,
              drillType,
              query,
            },
            { setDrillLevel, setDrillValues, setQueries }
          )
        }
      >
        <p>{series[0].series[0].x}</p>
        <span>{series[0].series[0].value}</span>
      </div>
    );
  }

  let drillable;

  if (drillType === 'dimension') {
    if (drillLevel === drillDownOptions.length - 1) {
      drillable = false;
    } else {
      drillable = true;
    }
  } else if (drillType === 'time') {
    if (drillLevel === granularities.length - 1) {
      drillable = false;
    } else {
      drillable = true;
    }
  }

  return selectedChartStyle === 'bar' ? (
    <Bar
      type="bar"
      data={data}
      getElementAtEvent={(el) =>
        getDrillDownQuery(
          el,
          data,
          {
            queries,
            drillLevel,
            granularity,
            drillDownOptions,
            granularities,
            drillValues,
            drillType,
            query,
          },
          { setDrillLevel, setDrillValues, setQueries }
        )
      }
      options={chartHelper.optionsBarAnalysera({
        legend: showLegend,
        drillable,
      })}
      redraw={false}
    />
  ) : selectedChartStyle === 'line' ? (
    <Line
      type="line"
      data={data}
      getElementAtEvent={(el) =>
        getDrillDownQuery(
          el,
          data,
          {
            queries,
            drillLevel,
            granularity,
            drillDownOptions,
            granularities,
            drillValues,
            drillType,
            query,
          },
          { setDrillLevel, setDrillValues, setQueries }
        )
      }
      options={chartHelper.optionsLine({ legend: showLegend, drillable })}
    />
  ) : null;
};

export default ChartRenderer;
