import React, { useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { getUserColleagues, getUserConnections } from 'store/user/tasks';
import { getLists } from 'store/lists/tasks';
import { resetActivityFilter, setActivityFilter } from 'store/activity/tasks';
import history from 'router-history';
import { activityHelper } from 'helpers';
import moment from 'moment';
import LanguageContext from 'language-context';
import Collapsible from 'components/collapsible';
import Icon from 'components/icon';
import Loading from 'components/loading';

/**
 * Just render a bunch of filters in text form.
 *
 * @param state.props.recreateActivityFilters - bool - If we should display a "recreate activity filters" button.
 * @param state.props.filters - array - Example: [
 *   {label: 'Användare', type: 'users', values: [1234, 7894, 8974]},
 *   {label: 'Listor', type: 'lists', values: ["5bf672c48900bd742198dec2c", "5bf6742c4800bd742198dec2c"]},
 *   {label: 'Datum', type: 'date', values: {from: "", to: ""}},
 *   {label: 'Märken', type: 'brands', values: ["Bmw", "Honda", "Saab"]},
 * ]
 * We expect a label string, type string and a values array in filters objects. Note that users, lists and date is special cases.
 * Date expect an object as values.
 * Lists expect an array with list ids where we retrieve the list names.
 * Users expect an array with user ids where we retrieve the user names.
 * We want the ids as values because we want to be able to recreate filters.
 */
const FilterBox = (state) => {
  const [filters, setFilters] = useState([]); // Hold mapped values arrays.
  const [loading, setLoading] = useState(false);
  const tc = useContext(LanguageContext);
  const isLoadingRef = useRef(false);

  useEffect(() => {
    const _mapDate = (values) => {
      const result = [];
      if (values.from) {
        result.push(`${tc.from} ${moment(new Date(values.from)).format('LL')}`);
      }

      if (values.to) {
        result.push(
          `${values.from ? tc.to.toLowerCase() : tc.to} ${moment(
            new Date(values.to)
          ).format('LL')}`
        );
      }

      return result;
    };

    const _mapLists = async (values) => {
      if (Array.isArray(state.lists.lists)) {
        return values.map((list) => {
          let found = state.lists.lists.find((x) => x.id === list);
          if (!found) {
            if (Array.isArray(state.lists.listsArchived)) {
              found = state.lists.listsArchived.find((x) => x.id === list);
            }
          }

          return found ? found.name : tc.unknown;
        });
      } else {
        // Retrieve lists before map.
        setLoading(true);
        await getLists({
          orderInformation: false,
        });
        setLoading(false);

        if (Array.isArray(state.lists.lists)) {
          return values.map((list) => {
            let found = state.lists.lists.find((x) => x.id === list);
            if (!found) {
              if (Array.isArray(state.lists.listsArchived)) {
                found = state.lists.listsArchived.find((x) => x.id === list);
              }
            }

            return found ? found.name : tc.unknown;
          });
        } else {
          return values.map(() => tc.unknown);
        }
      }
    };

    const _mapUsers = async (values) => {
      if (Array.isArray(state.user.colleagues)) {
        return values.map((num) => {
          // Find user name in colleagues and connections.
          let user;
          let found = state.user.colleagues.find(
            (colleague) => +colleague.id === +num
          );
          user = found ? found.name : null;

          if (!user) {
            let found;
            if (Array.isArray(state.user.connections)) {
              state.user.connections.forEach((dealer) => {
                if (!found && Array.isArray(dealer.users)) {
                  found = dealer.users.find(
                    (colleague) => +colleague.id === +num
                  );
                }
              });
            }

            user = found ? found.name : tc.unknown;
          }

          return user;
        });
      } else {
        if (isLoadingRef.current) return;
        // Should already exist in reducer state, but for edge cases retrieve user colleagues before map.
        isLoadingRef.current = true;
        setLoading(true);
        await getUserColleagues(); // Get colleagues
        await getUserConnections(); // Get colleagues at connected dealers
        setLoading(false);
        isLoadingRef.current = false;
        if (Array.isArray(state.user.colleagues)) {
          return values.map((num) => {
            // Find user name in colleagues and connections.
            let user;
            let found = state.user.colleagues.find(
              (colleague) => +colleague.id === +num
            );
            user = found ? found.name : null;

            if (!user) {
              let found;
              if (Array.isArray(state.user.connections)) {
                state.user.connections.forEach((dealer) => {
                  if (!found && Array.isArray(dealer.users)) {
                    found = dealer.users.find(
                      (colleague) => +colleague.id === +num
                    );
                  }
                });
              }

              user = found ? found.name : tc.unknown;
            }

            return user;
          });
        } else {
          return values.map(() => tc.unknown);
        }
      }
    };

    const _mapFilters = async () => {
      if (Array.isArray(state.props.filters)) {
        const filterMapped = JSON.parse(
          JSON.stringify(state.props.filters.filter((num) => num))
        ); // Important to clone, we use state.props.filters for recreateActivityFilters.

        return await Promise.all(
          filterMapped.map(async (filter) => {
            if (filter.type === 'date') {
              filter.values = _mapDate(filter.values);
            } else if (filter.type === 'lists') {
              filter.values = await _mapLists(filter.values);
            } else if (filter.type === 'users') {
              filter.values = await _mapUsers(filter.values);
            } else if (filter.type === 'currentWeek') {
              filter.values = _mapDate(activityHelper.getCurrentWeekDateObj());
            } else if (filter.type === 'currentMonth') {
              filter.values = _mapDate(activityHelper.getCurrentMonthDateObj());
            } else if (filter.type === 'previousWeek') {
              filter.values = _mapDate(activityHelper.getPreviousWeekDateObj());
            } else if (filter.type === 'previousMonth') {
              filter.values = _mapDate(
                activityHelper.getPreviousMonthDateObj()
              );
            } else if (filter.type === 'pastThirtyDays') {
              filter.values = _mapDate(activityHelper.getPastThirtyDaysObj());
            } else if (filter.type === 'past365Days') {
              filter.values = _mapDate(activityHelper.getPast365DaysObj());
            }

            return filter;
          })
        );
      }
    };

    const _setFilters = async () => {
      const filtersMapped = await _mapFilters();
      setFilters(filtersMapped);
    };

    _setFilters();
  }, [state.props.filters, state.lists, state.user]);

  const _recreateActivityFilters = () => {
    resetActivityFilter();

    const usersFilter = state.props.filters.find(
      (num) => num?.type === 'users'
    );
    if (usersFilter?.values?.length) {
      setActivityFilter({
        action: 'add',
        type: 'user',
        val: usersFilter.values,
      });
    }

    const listsFilter = state.props.filters.find(
      (num) => num?.type === 'lists'
    );
    if (listsFilter?.values?.length) {
      setActivityFilter({
        action: 'add',
        type: 'list',
        val: listsFilter.values,
      });
    }

    const subscriptionFilter = state.props.filters.find(
      (num) => num?.type === 'subscriptions'
    );
    if (subscriptionFilter?.values?.length) {
      subscriptionFilter.values.forEach((subId) => {
        setActivityFilter({
          type: 'subscription',
          val: subId,
        });
      });
    }

    const dateFilter = state.props.filters.find((num) => num?.type === 'date');
    if (dateFilter?.values?.from?.length || dateFilter?.values?.to?.length) {
      const now = new Date();

      setActivityFilter({
        type: 'date',
        val: {
          from: dateFilter?.values?.from?.length
            ? new Date(dateFilter.values.from)
            : new Date(
                now.setFullYear(
                  now.getFullYear() - activityHelper.getDefaultFromYears()
                )
              ).toISOString(), // If no "from" date provided, set to default value.
          to: dateFilter?.values?.to?.length
            ? new Date(dateFilter.values.to)
            : new Date(), // If no "to" date provided, set to today.
        },
      });
    } else {
      const now = new Date();

      setActivityFilter({
        type: 'date',
        val: {
          from: new Date(
            now.setFullYear(
              now.getFullYear() - activityHelper.getDefaultFromYears()
            )
          ).toISOString(), // If no "from" date provided, set to default value.
          to: new Date(), // If no "to" date provided, set to today.
        },
      });
    }

    const currentWeekFilter = state.props.filters.find(
      (num) => num?.type === 'currentWeek'
    );

    if (currentWeekFilter) {
      const date = activityHelper.getCurrentWeekDateObj();
      setActivityFilter({
        type: 'date',
        val: {
          from: date.from,
          to: date.to,
        },
      });
    }

    const currentMonthFilter = state.props.filters.find(
      (num) => num?.type === 'currentMonth'
    );

    if (currentMonthFilter) {
      const date = activityHelper.getCurrentMonthDateObj();
      setActivityFilter({
        type: 'date',
        val: {
          from: date.from,
          to: date.to,
        },
      });
    }

    const previousWeekFilter = state.props.filters.find(
      (num) => num?.type === 'previousWeek'
    );

    if (previousWeekFilter) {
      const date = activityHelper.getPreviousWeekDateObj();
      setActivityFilter({
        type: 'date',
        val: {
          from: date.from,
          to: date.to,
        },
      });
    }

    const previousMonthFilter = state.props.filters.find(
      (num) => num?.type === 'previousMonth'
    );

    if (previousMonthFilter) {
      const date = activityHelper.getPreviousMonthDateObj();
      setActivityFilter({
        type: 'date',
        val: {
          from: date.from,
          to: date.to,
        },
      });
    }

    const pastThirtyDaysFilter = state.props.filters.find(
      (filter) => filter?.type === 'pastThirtyDays'
    );

    if (pastThirtyDaysFilter) {
      const date = activityHelper.getPastThirtyDaysObj();
      setActivityFilter({
        type: 'date',
        val: {
          from: date.from,
          to: date.to,
        },
      });
    }

    const past365DaysFilter = state.props.filters.find(
      (filter) => filter?.type === 'past365Days'
    );

    if (past365DaysFilter) {
      const date = activityHelper.getPast365DaysObj();
      setActivityFilter({
        type: 'date',
        val: {
          from: date.from,
          to: date.to,
        },
      });
    }

    history.push('/bearbeta/aktivitet');
  };

  const _renderFilters = () => {
    return filters.map((filter) => {
      if (!Array.isArray(filter.values) || !filter.values?.length) {
        return null;
      }

      return (
        <div
          className="filterBoxWrapper__filterBox__content__section"
          key={filter.label}
        >
          <div className="filterBoxWrapper__filterBox__content__section__left">
            <span className="label">{filter.label}</span>
          </div>
          <div className="filterBoxWrapper__filterBox__content__section__right">
            {Array.isArray(filter.values) ? filter.values.join(', ') : null}
          </div>
        </div>
      );
    });
  };

  const content = (
    <div className="filterBoxWrapper">
      <div className="filterBoxWrapper__filterBox">
        <div className="filterBoxWrapper__filterBox__content">
          {loading ? <Loading small={true} /> : _renderFilters()}
          {state.props.recreateActivityFilters ? (
            <div
              className="filterBoxWrapper__filterBox__content__recreate"
              onClick={_recreateActivityFilters}
            >
              <h4>{tc.recreate}</h4>
              <Icon val="navigateNext" />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );

  return filters?.length ? (
    <Collapsible
      content={content}
      header={`${tc.savedMultiple} ${tc.filter.toLowerCase()}`}
      minimized={true}
    />
  ) : null;
};

const MapStateToProps = (state, props) => {
  return {
    lists: state.lists,
    props: props,
    user: state.user,
  };
};

export default connect(MapStateToProps)(FilterBox);
