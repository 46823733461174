import React, { useContext, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import LanguageContext from "language-context";
import {
  getWidgets,
  updateGridLayouts,
  removeWidget,
  updateWidgets,
  deleteGridView,
  toggleGridWidget,
  fixNewUserWidgets,
} from "store/widgets/tasks";
import Icon from "components/icon";
import { showFlashMessage } from "store/flash_messages/tasks";
import { chartHelper } from "helpers";
import AddGridView from "./add_grid_view";
import RemoveItem from "./remove_item";
import WidgetRow from "./widget_row";
import ViewButton from "./view_button";
import EditItem from "./edit_item";
import MenuDropdown from "../../menu/menu_dropdown";
import { widgetHelper } from "helpers/widget_helper";

/**
 * @param {{widgets: any[], gridViews: any[]}} props
 * Settings grid section.
 */
const SettingsGrid = ({ widgets, gridViews }) => {
  const tc = useContext(LanguageContext);
  const [selectedViewId, setSelectedViewId] = useState(null);
  const [addGridView, setAddGridView] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isToggling, setIsToggling] = useState(false);
  const editWidgetInputRef = useRef(null);

  const [itemToEdit, setItemToEdit] = useState(null);
  const [itemToRemove, setItemToRemove] = useState(null);
  const editItemInputRef = useRef(null);

  const maxButtonsToDisplay = 3;

  // To fix an issue where a view apparently had no data and the name field as null, unclear how that happened
  // useEffect(() => {
  //   if (Array.isArray(gridViews)) {
  //     const filteredViews = gridViews.filter((view) => view?.name !== null);
  //     if (filteredViews.length !== gridViews.length)
  //       updateGridLayouts(filteredViews, null, null, true);
  //   }
  // }, [gridViews]);

  useEffect(() => {
    if (itemToEdit?.item?.name) {
      editItemInputRef &&
        editItemInputRef.current &&
        editItemInputRef.current.focus();
    }
  }, [itemToEdit]);

  useEffect(() => {
    if (itemToEdit && itemToEdit.name) {
      setInputValue(itemToEdit.name);
    }
  }, [itemToEdit]);

  useEffect(() => {
    if (!widgets) {
      getWidgets();
      return;
    }
    if (!gridViews) {
      // const defaultLayout = chartHelper.generateLayoutForNewUser(widgets);
      // const layout = {
      //   lg: defaultLayout,
      //   md: defaultLayout,
      //   sm: defaultLayout,
      //   xs: defaultLayout,
      //   xxs: defaultLayout,
      // };
      async function createNewUserLayouts() {
        fixNewUserWidgets(widgets);
      }

      createNewUserLayouts();
    }
    if (
      Array.isArray(gridViews) &&
      gridViews.length > 0 &&
      selectedViewId === null
    ) {
      setSelectedViewId(
        gridViews.find((view) => view.active === true)?.id ||
          widgetHelper.DEFAULT_GRID_VIEW_ID
      );
    }
  }, [widgets, gridViews, selectedViewId]);

  const updateInputValue = (value) => {
    setInputValue(value);
  };

  const handleRemoveWidget = async (widgetToRemove) => {
    await removeWidget({ id: widgetToRemove._id });
    setItemToRemove(null);
    setItemToEdit(null);
  };

  const deleteView = async (id) => {
    // this should never be the case, but safeguarded nevertheless
    if (id === widgetHelper.DEFAULT_GRID_VIEW_ID) {
      showFlashMessage("Standardvyn kan inte tas bort", "info");
      return;
    }

    await deleteGridView({ id, gridViews, widgets });
    showFlashMessage("Vyn har tagits bort", "success");
  };

  const handleGridViewSave = (gridView) => {
    if (!gridView) {
      showFlashMessage("Grid view could not be saved", "info");
      return;
    }
    if (gridView.id === widgetHelper.DEFAULT_GRID_VIEW_ID) return;

    const updatedViews = gridViews.reduce((acc, view) => {
      if (view.id === gridView.id) {
        return [...acc, { ...view, name: inputValue }];
      } else {
        return [...acc, { ...view }];
      }
    }, []);
    updateGridLayouts(updatedViews, null, null, true);
  };

  const handleInitialRemoveClick = () => {
    setItemToRemove(itemToEdit);
    setItemToEdit(null);
    setInputValue("");
  };

  /**
   *
   * @param {*} item.type "widget" | "gridView"
   * @param {*} item.item widget to remove or gridView to remove
   * @description deletes selected widget or gridView
   */
  const handleItemRemove = async (item) => {
    if (item.type === "widget") {
      await handleRemoveWidget(item.item);
      setItemToRemove(null);
    } else if (item.type === "gridView") {
      await deleteView(item.item.id);
      setItemToRemove(null);
      //set selected view to a view that is not the one we're removing
      let filtered = gridViews.filter((view) => view.id !== item.item.id);
      setSelectedViewId(
        filtered[filtered.length - 1].id || widgetHelper.DEFAULT_GRID_VIEW_ID
      );
    }
  };

  const handleItemSave = async (itemToSave) => {
    if (inputValue?.length < 1) {
      showFlashMessage(tc.nameTooShort, "info");
      return;
    }
    const { type, item } = itemToSave;
    if (type === "widget") {
      const updatedWidgets = widgets.reduce((acc, widget) => {
        if (widget._id === item._id) {
          return [...acc, { ...widget, name: inputValue }];
          // } else if (widget._id === "4a1ee700-3a4f-11ed-82c2-b97b00932b7d") {
          //   return [...acc, { ...widget, name: "Externa Leads" }];
          // } else if (widget._id === "4a1c4ef0-3a4f-11ed-82c2-b97b00932b7d") {
          //   return [...acc, { ...widget, name: "Planerade Aktiviteter" }];
          // } else if (widget._id === "4a1b3d80-3a4f-11ed-82c2-b97b00932b7d") {
          //   return [...acc, { ...widget, name: "Nyheter" }];
        } else {
          return [...acc, { ...widget }];
        }
      }, []);
      await updateWidgets({ widgets: updatedWidgets });
    } else if (type === "gridView") {
      if (inputValue.length > 32) {
        showFlashMessage("32 or fewer characters please!", "info");
        return;
      }
      handleGridViewSave(itemToSave.item);
    }
    setInputValue("");
    setItemToEdit(null);
  };

  const _stateCheck = widgets && gridViews;

  return _stateCheck ? (
    <div className="settingsPageWrapper">
      <div className="settingsPageWrapper__settingsPage">
        <div
          className="settingsPageWrapper__settingsPage__section"
          id="manageWidgets"
        >
          <div className="settingsPageWrapper__settingsPage__section__header">
            {/* {tc.gridSettingsEditViews} */}
            {tc.manageWidgets}
          </div>
          <div className="settingsPageWrapper__settingsPage__section__content">
            <div className="settingsPageWrapper__settingsPage__section__content__holder marginBottom">
              {/* <p>{tc.gridSettingsDescriptionMain}</p>
              <p>
                Kanske inkludera den tidigare beskrivningen om hur man skapar
                widgets, decide later.
              </p> */}
              <p>{tc.manageWidgetsGeneric}</p>
              <p>{tc.manageDashboardWidgets}</p>
            </div>
            <>
              {itemToEdit ? (
                <EditItem
                  type="gridView"
                  itemToEdit={itemToEdit}
                  inputRef={editWidgetInputRef}
                  value={inputValue}
                  updateValue={updateInputValue}
                  setItemToEdit={setItemToEdit}
                  handleItemRemove={handleInitialRemoveClick}
                  handleItemSave={handleItemSave}
                />
              ) : itemToRemove ? (
                <RemoveItem
                  itemToRemove={itemToRemove}
                  handleItemRemove={() => {
                    handleItemRemove(itemToRemove);
                  }}
                  handleCancelRemove={() => setItemToRemove(null)}
                  type="gridView"
                />
              ) : null}

              {/*
            Uncomment {tc.gridSettingsEditViews} and the code below when it's time to allow users to have multiple views.
            */}
              {/* <div>
                <div
                  className={`${
                    gridViews.length > maxButtonsToDisplay
                      ? "genericFlexboxParent__row"
                      : "flexHolder"
                  }`}
                >
                  {!itemToRemove && !itemToEdit ? (
                    Array.isArray(gridViews) &&
                    gridViews.length > maxButtonsToDisplay ? (
                      <MenuDropdown
                        label={
                          gridViews.find((view) => view.id === selectedViewId)
                            ?.name || "Standardvy"
                        }
                        skipSort={true}
                        autoClose={true}
                        items={gridViews.map((view) => {
                          return {
                            active: selectedViewId === view.id,
                            label: view.name,
                            disabled: false,
                            onClick: () => {
                              setSelectedViewId(view.id);
                            },
                          };
                        })}
                      />
                    ) : (
                      gridViews.map((view) => {
                        return (
                          <ViewButton
                            key={view.id}
                            view={view}
                            selectedViewId={selectedViewId}
                            handleClick={() => setSelectedViewId(view.id)}
                            setViewToEdit={() => {
                              setItemToEdit({ type: "gridView", item: view });
                            }}
                          />
                        );
                      })
                    )
                  ) : null}
                </div>
                <div className="settingsPageWrapper__settingsPage__section__content__holder">
                  <div className="flexHolder marginBottomBig">
                    {addGridView ? (
                      <AddGridView
                        setAddGridView={setAddGridView}
                        gridViews={gridViews}
                        updateValue={updateInputValue}
                        inputValue={inputValue}
                        setSelectedViewId={setSelectedViewId}
                      />
                    ) : itemToEdit ? (
                      <>
                        <EditItem
                          type="gridView"
                          itemToEdit={itemToEdit}
                          inputRef={editWidgetInputRef}
                          value={inputValue}
                          updateValue={updateInputValue}
                          setItemToEdit={setItemToEdit}
                          handleItemRemove={handleInitialRemoveClick}
                          handleItemSave={handleItemSave}
                        />
                      </>
                    ) : itemToRemove ? (
                      <RemoveItem
                        itemToRemove={itemToRemove}
                        handleItemRemove={() => {
                          handleItemRemove(itemToRemove);
                        }}
                        handleCancelRemove={() => setItemToRemove(null)}
                        type="gridView"
                      />
                    ) : (
                      <div className="inputHolder">
                        <div className="inputHolder__left">
                          <div
                            className="linkWithIcon"
                            onClick={() => {
                              setAddGridView(true);
                            }}
                          >
                            <Icon val="add" />
                            <span>Add new view</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div> */}
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    marginTop: "22px",
                  }}
                >
                  {selectedViewId !== widgetHelper.DEFAULT_GRID_VIEW_ID ? (
                    <Icon
                      val="edit"
                      onClick={() => {
                        updateInputValue(
                          gridViews.find((view) => view.id === selectedViewId)
                            ?.name || ""
                        );
                        setItemToEdit({
                          type: "gridView",
                          item: gridViews.find(
                            (view) => view.id === selectedViewId
                          ),
                        });
                      }}
                    />
                  ) : null}
                  <h1>
                    {gridViews.find((view) => view.id === selectedViewId)?.name}
                  </h1>
                </div>
                <hr style={{ marginBottom: "14px" }}></hr>
              </div>
              <div className="toggleOptionsHolder">
                {widgets.map((widget) => (
                  <WidgetRow
                    key={widget._id}
                    widget={widget}
                    selectedViewId={selectedViewId}
                    setWidgetToEdit={(type, item) => {
                      setInputValue(item?.name);
                      setItemToEdit({ type, item });
                    }}
                    setIsToggling={setIsToggling}
                    isToggling={isToggling}
                  />
                ))}
              </div>
            </>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

const MapStateToProps = (state) => {
  return {
    widgets: state.widgets?.data?.widgets,
    gridViews: state.widgets?.data?.gridViews,
  };
};

export default connect(MapStateToProps)(SettingsGrid);
