import React, { useContext } from "react";
import { connect } from "react-redux";
import LanguageContext from "language-context";
import { setShowCampaignInfo } from "store/user/tasks";
import Popup from "components/popup";
import WidgetFooter from "components/widget_footer";

/**
 * This is displayed/hidden in router.js
 * The thought is that this component can be reusable.
 * Use the showCampaignInfo property in user reducer and adjust the content
 * inside this component for new campaigns.
 */
const CampaignInfo = (state) => {
  const tc = useContext(LanguageContext);

  return (
    <Popup
      borderRadius={true}
      close={() => {
        setShowCampaignInfo(false);
      }}
      size="medium"
    >
      <div className="genericPopupContentWrapper">
        <div className="genericPopupContentWrapper__genericPopupContent">
          <div className="genericPopupContentWrapper__genericPopupContent__header"></div>
          <div className="genericPopupContentWrapper__genericPopupContent__content"></div>
          <div className="genericPopupContentWrapper__genericPopupContent__footer">
            <WidgetFooter
              buttonOneFunc={async () => {
                setShowCampaignInfo(false);
              }}
              buttonOneText={tc.ok}
            />
          </div>
        </div>
      </div>
    </Popup>
  );
};
const MapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(MapStateToProps)(CampaignInfo);
