import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import LanguageContext from 'language-context';
import WidgetFooter from 'components/widget_footer';
import Loading from 'components/loading';
import { activityHelper } from 'helpers';
import { showFlashMessage } from 'store/flash_messages/tasks';
import { getActivityGoals, setActivityGoals } from 'store/activities/tasks';
import ColleaguesDropdown from 'components/colleagues_dropdown';

const SetActivityGoals = (state) => {
  const tc = useContext(LanguageContext);

  const [goals, setGoals] = useState(null);
  const [user, setUser] = useState({ userId: null, dealerId: null, name: tc.chooseUser });
  const [validationErrors, setValidationErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  const activityTypes = [
    'won',
    'call',
    'meeting',
    'mail',
    'testride',
    'valuation',
    'offer',
    'analysis',
    'visit',
    'lift',
    'meetingPlanned',
  ];

  const handleSetData = async () => {
    try {
      const mappedGoals = { ...goals };
      delete mappedGoals.user_id;
      delete mappedGoals.dealer_id;
      delete mappedGoals.id;

      setActivityGoals({ goals: mappedGoals, userId: user.userId, dealerId: user.dealerId });

      showFlashMessage(tc.activityGoalsUpdate, 'success');
    } catch (err) {
      showFlashMessage(tc.setActivityGoalsError, 'fail');
    }
  };

  const validateInput = (input) => /^[0-9]+$/.test(input);

  useEffect(() => {
    (async () => {
      if (user.userId && user.dealerId) {
        setLoading(true);
        const data = await getActivityGoals({
          users: [{ userId: user.userId, dealerId: user.dealerId }],
        });

        if (data[0]) {
          setGoals(data[0]);
          setLoading(false);
        }
      }
    })();
  }, [user]);

  return (
    <div className="settingsPageWrapper">
      <div className="settingsPageWrapper__settingsPage">
        <div className="settingsPageWrapper__settingsPage__section" id="toggleWidgetsAnalyse">
          <div className="settingsPageWrapper__settingsPage__section__header">
            {'Redigera aktivitetsmål'}
          </div>
          <div className="settingsPageWrapper__settingsPage__section__content">
            {tc.editActivityGoalsInfo}
            <div className="settingsPageWrapper__settingsPage__section__content__admin__colleaguesHolder">
              <ColleaguesDropdown
                activeId={user.userId}
                activeIds={[]}
                activeName={user.name}
                transparent={false}
                onClick={(userId, name, dealerId) => {
                  setUser({
                    userId,
                    dealerId,
                    name,
                  });
                }}
              />
            </div>
            {loading && <Loading size="big" />}
            {goals && !loading && (
              <>
                <div className="settingsPageWrapper__settingsPage__section__content">
                  <div className="inputOptionsHolder">
                    {activityTypes.map((type) => (
                      <div className="inputOptionsHolder__item" key={type}>
                        <div className="inputOptionsHolder__item__left">
                          {activityHelper.getReadableActivity(type)}
                        </div>
                        <div className="inputOptionsHolder__item__right">
                          <input
                            type="text"
                            onChange={(e) => {
                              setGoals({
                                ...goals,
                                [type]: e.target.value,
                              });

                              const isValid = validateInput(e.target.value);

                              if (isValid) {
                                setValidationErrors(validationErrors.filter((err) => err !== type));
                              } else {
                                setValidationErrors([...validationErrors, type]);
                              }
                            }}
                            value={!isNaN(goals[type]) ? goals[type] : ''}
                          />
                          {validationErrors.includes(type) && (
                            <div className="validationError">{tc.validationErrorNotNumber}</div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                  <WidgetFooter
                    buttonOneText={tc.save}
                    buttonOneFunc={() => handleSetData()}
                    disableButtonOne={validationErrors.length > 0}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const MapStateToProps = (state) => {
  return {
    widgets: state.widgets,
  };
};

export default connect(MapStateToProps)(SetActivityGoals);
