import SingleCell from './single_cell';
import { NavLink } from 'react-router-dom';

/**
 *
 * @param {{name: string, keys: string[], data: {}, totals: {}, isDeal: boolean, onClick: () => void}} props
 * @param name - name of user or deal depending on use case, if it's a deal you can click the name to view the entire deal
 * @param keys - actions to display
 * @param data - object with data for action totals for current user/deal
 * @description renders a row of cells with data on its corresponding action types
 */
const SingleRowNew = ({
  name,
  keys,
  data,
  totals = {},
  isDeal = false,
  onClick,
  goals,
}) => {
  return (
    <div
      key={name}
      className="activityTypesTableWrapper__activityTypesTable__content__table__rowsHolder__rows__rowHolder__row"
    >
      <div className="activityTypesTableWrapper__activityTypesTable__content__table__rowsHolder__rows__rowHolder__row__cell">
        <div className="activityTypesTableWrapper__activityTypesTable__content__table__rowsHolder__rows__rowHolder__row__cell__value">
          {isDeal ? (
            <NavLink to={`/affar/${data.id ? data.id : data._id}`}>
              {name}
            </NavLink>
          ) : (
            <button
              style={{
                border: 'none',
                backgroundColor: 'transparent',
                cursor: 'pointer',
              }}
              onClick={onClick}
            >
              <span>{name}</span>
            </button>
          )}
        </div>
      </div>
      {keys.map((type) => {
        return (
          <SingleCell
            key={type}
            value={data[type]}
            total={totals[type]}
            goal={goals ? goals[type] : null}
          />
        );
      })}
    </div>
  );
};

export default SingleRowNew;
