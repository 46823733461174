import React, { useContext, useState } from 'react';
import LanguageContext from 'language-context';
import Ludvig from 'images/home_page/team/LudvigAstrand.jpg';
import Patrik from 'images/home_page/team/PatrikForslund.png';
import Sebastian from 'images/home_page/team/sebbe.jpg';
import Fredrik from 'images/home_page/team/FredrikBackman.jpg';
import Olle from 'images/home_page/team/olle-axelsson.jpg';
import Jacob from 'images/home_page/team/jakob-thorsteinsson.jpg';
import Icon from 'components/icon';

const HomeTeam = () => {
  const [showEmail, setShowEmail] = useState([]);
  const [showPhone, setShowPhone] = useState([]);
  const tc = useContext(LanguageContext);

  const _renderItem = (name, position, email, phone, image) => {
    return (
      <div className={'homeTeamWrapper__homeTeam__content__item'}>
        <div className="homeTeamWrapper__homeTeam__content__item__image">
          <img src={image} alt={name} />
        </div>
        <div className="homeTeamWrapper__homeTeam__content__item__information">
          <div className="homeTeamWrapper__homeTeam__content__item__information__row">
            <p className="position">{position}</p>
          </div>
          <div className="homeTeamWrapper__homeTeam__content__item__information__row">
            <h3>{name}</h3>
          </div>
          <div className="homeTeamWrapper__homeTeam__content__item__information__row flexRow">
            <div
              className={`homeTeamWrapper__homeTeam__content__item__information__row__icon ${
                showPhone.includes(name) ? 'active' : ''
              }`}
              onClick={() => {
                setShowEmail(showEmail.filter((num) => num !== name));

                if (showPhone.includes(name)) {
                  setShowPhone(showPhone.filter((num) => num !== name));
                } else {
                  setShowPhone(showPhone.concat([name]));
                }
              }}
            >
              <Icon val="phone" />
            </div>
            <div
              className={`homeTeamWrapper__homeTeam__content__item__information__row__icon ${
                showEmail.includes(name) ? 'active' : ''
              }`}
              onClick={() => {
                setShowPhone(showPhone.filter((num) => num !== name));

                if (showEmail.includes(name)) {
                  setShowEmail(showEmail.filter((num) => num !== name));
                } else {
                  setShowEmail(showEmail.concat([name]));
                }
              }}
            >
              <Icon val="mail" />
            </div>
          </div>
          <div
            className={`homeTeamWrapper__homeTeam__content__item__information__row infoRow ${
              showPhone.includes(name) ? '' : 'minimize'
            }`}
          >
            {phone}
          </div>
          <div
            className={`homeTeamWrapper__homeTeam__content__item__information__row infoRow ${
              showEmail.includes(name) ? '' : 'minimize'
            }`}
          >
            {email}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="homeTeamWrapper">
      <div className="homeTeamWrapper__homeTeam">
        <div className="homeTeamWrapper__homeTeam__header">
          <h2>{tc.homeTeam}</h2>
          <h4>{tc.homeTeamHeaderSub}</h4>
        </div>
        <div className="homeTeamWrapper__homeTeam__content">
          {_renderItem(
            'Ludvig Åstrand',
            'Projektledare',
            'ludvig.astrand@bilvision.se',
            '031 - 382 17 00',
            Ludvig
          )}
          {_renderItem(
            'Sebastian Alexandersson',
            'Utvecklare',
            'sebastian.alexandersson@bilvision.se',
            '031 - 382 17 00',
            Sebastian
          )}
          {_renderItem(
            'Fredrik Bäckman',
            'Utvecklare',
            'fredrik.backman@bilvision.se',
            '031 - 382 17 00',
            Fredrik
          )}
          {_renderItem(
            'Olle Axelsson',
            'Sales',
            'Olle.axelsson@bilvision.se',
            '031 - 383 17 33',
            Olle
          )}
          {_renderItem(
            'Jakob Thorsteinsson',
            'Sales',
            'Jakob.thorsteinsson@bilvision.se',
            '031 - 383 17 36',
            Jacob
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeTeam;
