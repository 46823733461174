import React from 'react';
import Tooltip from '@mui/material/Tooltip';

/**
 * Tooltip component.
 * Wrap anything inside this component and it will work as a tooltip.
 *
 * @param props.inline - bool (optional) - When used inside <p> element etc.
 * @param props.placement -  string - See component API for alternatives.
 * @param props.tooltipContent - string | element - Content of tooltip.
 * @param props.followCursor - bool - should the tooltip follow the cursor
 * @param props.widthType - chooose between max-content, min-content and auto
 */
const TooltipComponent = (props) => {
  let tooltipClasses = `tooltipWrapper ${props.inline ? 'inlineTooltip' : ''}`;
  if (props.widthType) {
    tooltipClasses += ` ${props.widthType}-width`;
  }

  return (
    <div className={tooltipClasses}>
      <div className="tooltipWrapper__tooltip">
        <Tooltip
          aria-label={props.tooltipContent}
          arrow
          disableFocusListener={true}
          placement={props.placement ? props.placement : 'top'}
          title={props.tooltipContent}
          followCursor={props.followCursor}
        >
          <div
            className="tooltipWrapper__tooltip__indicator"
            aria-label={props.tooltipContent}
          >
            {props.children}
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default TooltipComponent;
