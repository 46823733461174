import { chartHelper } from "helpers";
import LanguageContext from "language-context";
import { useContext, useEffect } from "react";
import { removeWidget, toggleGridWidget } from "store/widgets/tasks";
import Icon from "components/icon";
import ToggleOption from "components/toggle_option";
import Tooltip from "components/tooltip";
import { widgetHelper } from "helpers/widget_helper";
import { showFlashMessage } from "store/flash_messages/tasks";

const WidgetRow = ({
  widget,
  selectedViewId,
  setWidgetToEdit,
  isToggling,
  setIsToggling,
}) => {
  const tc = useContext(LanguageContext);
  // We allow widgets that still aren't part of dashboard (like analyse widgets).
  // If we toggle them to active then we add the selectedView object to "dashboards".
  const active = widget.dashboards?.[selectedViewId]?.active;

  const getLabelSub = (widgetType) => {
    switch (widgetType) {
      case "monitorList":
        return tc.defaultWidgetMonitorListName;
      case "news":
        return tc.defaultWidgetNewsName;
      default:
        return tc[widgetType];
    }
  };

  useEffect(() => {
    if (!widgetHelper.widgetTypes.includes(widget.type)) {
      removeWidget({ ...widget, id: widget._id }, false);
    }
  }, [widget]);

  // No check for this in backend, so important to keep.
  const isRemovable = !widgetHelper.defaultWidgetTypes.includes(widget.type);

  return widgetHelper.widgetTypes.includes(widget.type) ? (
    <div key={widget._id} className="toggleOptionsHolder__item">
      <div className="toggleOptionsHolder__item__left">
        {isRemovable ? (
          <div className="iconHolder">
            <Tooltip tooltipContent={tc.editWidget}>
              <Icon
                val="edit"
                onClick={() => setWidgetToEdit("widget", widget)}
              />
            </Tooltip>
          </div>
        ) : null}
      </div>
      <div className="toggleOptionsHolder__item__right">
        <ToggleOption
          active={active}
          label={widget.name}
          labelSub={getLabelSub(widget.type)}
          onClick={async () => {
            if (!isToggling) {
              setIsToggling(true);
              await toggleGridWidget(widget, selectedViewId);
              setIsToggling(false);
            }
          }}
        />
      </div>
    </div>
  ) : null;
};

export default WidgetRow;
