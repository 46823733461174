import { activityActionTypes } from './actions';
import moment from 'moment';

/*
Reducer for activity page, which holds different components.
Separate this from activities reducer, handles activities in Activities component.
 */

interface ActivityState {
  filter: {
    date: {
      from: Date;
      to: Date;
    };
    lists: Array<object>;
    subscriptions: Array<object>;
    users: Array<number>;
    prospectTypes: Array<string>;
  };
  inspectByFilter: Array<object> | null;
  pipelineByFilter: {
    all: object;
    active: object;
    trash: object;
    lost: object;
    won: object;
  } | null;
  pipelineSubscriptions: object;
  subscriptionsLoading: Array<string>;
}

const initialFilterState = {
  date: {
    from: moment(new Date().setHours(23, 59, 59)).subtract(1, 'month').toDate(),
    to: moment(new Date().setHours(23, 59, 59)).toDate(),
  },
  lists: [],
  subscriptions: [],
  users: [],
  prospectTypes: [],
};

const initialState: ActivityState = {
  filter: initialFilterState,
  inspectByFilter: null,
  pipelineByFilter: null,
  pipelineSubscriptions: {},
  subscriptionsLoading: [], // Subscription ids that is currently loading data.
};

export const activityReducer = (state = initialState, action) => {
  switch (action.type) {
    case activityActionTypes.ACTIVITY_RESET_FILTER: {
      return {
        ...state,
        filter: initialFilterState,
      };
    }
    case activityActionTypes.ACTIVITY_SET_DEFAULT_FILTER: {
      return {
        ...state,
        filter: {
          ...initialFilterState,
          users: action.payload.users,
        },
      };
    }
    case activityActionTypes.ACTIVITY_SET_FILTER_DATE: {
      return {
        ...state,
        filter: {
          ...state.filter,
          date: action.payload,
        },
      };
    }
    case activityActionTypes.ACTIVITY_SET_FILTER_LISTS: {
      return {
        ...state,
        filter: {
          ...state.filter,
          lists: action.payload,
        },
      };
    }
    case activityActionTypes.ACTIVITY_SET_FILTER_SUBSCRIPTIONS: {
      return {
        ...state,
        filter: { ...state.filter, subscriptions: action.payload },
      };
    }
    case activityActionTypes.ACTIVITY_SET_FILTER_PROSPECTTYPES: {
      return {
        ...state,
        filter: { ...state.filter, prospectTypes: action.payload },
      };
    }
    case activityActionTypes.ACTIVITY_SET_FILTER_USERS: {
      return {
        ...state,
        filter: {
          ...state.filter,
          users: action.payload,
        },
      };
    }
    case activityActionTypes.ACTIVITY_SET_INSPECT_BY_FILTER: {
      return {
        ...state,
        inspectByFilter: action.payload,
      };
    }
    case activityActionTypes.ACTIVITY_SET_PIPELINE_BY_FILTER: {
      return {
        ...state,
        pipelineByFilter: action.payload,
      };
    }
    case activityActionTypes.ACTIVITY_SET_PIPELINE_SUBSCRIPTION: {
      return {
        ...state,
        pipelineSubscriptions: {
          ...state.pipelineSubscriptions,
          [action.payload.id]: action.payload.data,
        },
      };
    }
    case activityActionTypes.ACTIVITY_SET_SUBSCRIPTION_LOADING: {
      const newArr =
        action.payload.type === 'remove'
          ? state.subscriptionsLoading.filter(
              (num) => num !== action.payload.id
            )
          : state.subscriptionsLoading.concat(action.payload.id);

      return {
        ...state,
        subscriptionsLoading: newArr,
      };
    }
    default: {
      return state;
    }
  }
};
