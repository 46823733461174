import React, { useContext, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import LanguageContext from "language-context";
import Icon from "components/icon";
import Info from "components/info";
import NewsItem from "components/news_widget/news_item";
import { getNews } from "store/news/tasks";

const NewsWidget = ({ news, user, amount }) => {
  const newsWidgetRef = useRef(null);
  const newsWidgetItemsRef = useRef(null);
  const tc = useContext(LanguageContext);
  const DISPLAY_AMOUNT = amount;
  
  useEffect(() => {
    getNews();
  }, []);

  return (
    <div className={`newsWidgetWrapper`} ref={newsWidgetRef}>
      <div className="newsWidgetWrapper__newsWidget cancelDrag">
        {news?.items?.length < 1 ? (
          <Info>
            <h4>{tc.noNewsCurrently}</h4>
            <p>{tc.noNewsCurrentlyInfo}</p>
          </Info>
        ) : (
          <>
            <div
              className="newsWidgetWrapper__newsWidget__content"
              style={{ overflow: "hidden" }}
            >
              <div
                className="newsWidgetWrapper__newsWidget__content__archive"
                ref={newsWidgetItemsRef}
                style={{ overflow: "auto" }}
              >
                <div
                  className="newsWidgetWrapper__newsWidget__content__archive__items"
                  //ref={newsWidgetItemsVisibleRef}
                  style={{ overflow: "auto" }}
                >
                  {news.items?.slice(0, DISPLAY_AMOUNT).map((item) => {
                    return (
                      <React.Fragment key={item.id}>
                        <NewsItem item={item} />
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        )}
        <div className="newsWidgetWrapper__newsWidget__footer">
          <div className="newsWidgetWrapper__newsWidget__footer__navigate">
            <NavLink className="cancelDrag" exact to={"/nyheter"}>
              <h4>{tc.news}</h4>
              <Icon val="navigateNext" />
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const { news, user } = state;
  return {
    news,
    user,
  };
};

export default connect(mapStateToProps)(NewsWidget);
