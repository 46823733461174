import { scriptsActionTypes } from "./actions";

interface ScriptsState {
  scripts: any[];
}

const initialState: ScriptsState = {
  scripts: [],
};

export const scriptsReducer = (state = initialState, action) => {
  switch (action.type) {
    case scriptsActionTypes.SET_SCRIPTS_DATA: {
      return { ...state, scripts: action.payload };
    }
    default: {
      return state;
    }
  }
};
