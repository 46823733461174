import React, { useState, useEffect, useRef } from 'react';
import Icon from 'components/icon';
import Loading from 'components/loading';

/**
 * Dropdown component with customizable header.
 * @param {Function} onClick - Function to handle option selection.
 * @param {Array} children - Array of dropdown Items
 * @param {string} iconVal - Icon string for the dropdown items.(optional)
 * @param {Object}  header - Object containing properties for the dropdown header.
 * @param {string}  header.label        - label: string (required) - Text to display in the header.
 * @param {string}  header.icon         - icon: string (optional) - Icon string for the header icon.
 * @param {string}  header.size         - size: string (optional) - Size class for the header.
 * @param {boolean} header.noBorder     - noBorder: boolean (optinal) - Border around header.
 * @param {boolean} header.transparent  - transparent: boolean (optional) - If we want the header(displayValue) to be transparent.
 * @param {boolean} header.noPadding    - noPadding:  boolean (optinal) - no padding in header
 * @param {boolean} onClose - Function to be called when dropdown closes (optional)
 * @param {boolean} closeOnItemClick - If the dropdown should stay open when clicking on dropdown items (optional)
 * @param {boolean} closeDropdown - Close the dropdown (optional)
 * @returns {JSX.Element} - JSX element representing the dropdown.
 */
export const NewDropdown = ({
  onClick = () => {},
  iconVal,
  header,
  children,
  align = 'bottom-left',
  onClose,
  closeOnItemClick = true,
  closeDropdown = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        if (onClose && typeof onClose === 'function') {
          onClose();
        }
      }
    };

    if (isOpen || closeDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, closeDropdown, onClose]);

  const toggleDropdown = () => {
    setIsOpen((prevIsOpen) => {
      if (prevIsOpen && onClose && typeof onClose === 'function') {
        onClose();
      }
      return !prevIsOpen;
    });
  };

  const handleSelectOption = (option) => {
    if (onClick) {
      onClick(option);
    }
    if (!closeOnItemClick) {
      return;
    }
    setIsOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const createAlignment = () => {
    let classes = ['dropdown-menu'];
    if (isOpen) {
      classes.push('open');
    }
    if (align) {
      const [vertical, horizontal] = align.split('-');
      if (vertical === 'top' || vertical === 'bottom') {
        classes.push(vertical);
      }
      if (horizontal === 'left' || horizontal === 'right') {
        classes.push(horizontal);
      }
    }
    return classes.join(' ');
  };

  return (
    <div
      ref={dropdownRef}
      className={`dropdown ${header?.noBorder ? 'noBorder' : ''}`}
    >
      <div
        className={`dropdown__toggle__header ${header?.size || ''} ${
          header.transparent ? 'transparent' : ''
        } ${header?.noBorder ? 'noBorder' : ''} ${
          header?.noPadding ? 'noPadding' : ''
        }`}
        onClick={toggleDropdown}
      >
        {header?.label}
        {header?.icon ? (
          <Icon val={header.icon} />
        ) : (
          <Icon val={isOpen ? 'minimize' : 'maximize'} />
        )}
      </div>

      {isOpen && children && (
        <div className={createAlignment()}>
          {children.map((child, index) => (
            <div
              key={index}
              className="dropdown-item"
              onClick={() => handleSelectOption()}
            >
              {children && child ? child : <Loading small={true}></Loading>}
              {iconVal && <Icon val={iconVal}></Icon>}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
