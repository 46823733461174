import React from "react";
import { Switch, Route } from "react-router-dom";
import ListsActive from "./lists_active";
import ListsArchived from "./lists_archived";
import Orders from "components/orders";
import ListsSubscriptions from "./lists_subscriptions";
import ListsContracts from "./lists_contracts";
import SubscriptionLists from "./lists_subscriptionLists";

const Lists = () => {
  return (
    <Switch>
      <Route exact path="/listor" component={ListsActive} />
      <Route exact path="/listor/avtal" component={ListsContracts} />
      <Route exact path="/listor/arkiverade" component={ListsArchived} />
      <Route
        exact
        path="/listor/prenumerationer"
        component={ListsSubscriptions}
      />
      <Route exact path="/listor/bestalla" component={Orders} />
      <Route
        exact
        path="/listor/prenumerationslistor"
        component={SubscriptionLists}
      />
    </Switch>
  );
};

export default Lists;
