import React, { useEffect, useState } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { getToken } from 'store/pbi/tasks';

function PBIEmbedd({ reportData, userId, setReport, filterInUse }) {
  const [token, setToken] = useState('');
  const [embedUrl, setEmbedUrl] = useState('');

  useEffect(() => {
    const getTokenAndSetState = async () => {
      const json = await getToken(reportData, userId);

      if (
        json &&
        json.embedToken &&
        json.embedToken.token &&
        json.embedReport &&
        json.embedReport[0]
      ) {
        setToken(json.embedToken.token);
        setEmbedUrl(json.embedReport[0].embedUrl);
      }
    };

    if (reportData && reportData.reportId) getTokenAndSetState();
  }, [reportData, userId]);

  return (
    reportData?.reportId &&
    embedUrl &&
    token && (
      <PowerBIEmbed
        embedConfig={{
          type: 'report', // Supported types: report, dashboard, tile, visual, qna and paginated report
          id: reportData.reportId,
          embedUrl: embedUrl,
          accessToken: token, // Keep as empty string, null or undefined
          tokenType: models.TokenType.Embed,
          settings: {
            layoutType: models.LayoutType.Custom,
            customLayout: {
              displayOption: models.DisplayOption.FitToPage,
            },
          },
          filters: filterInUse,
        }}
        getEmbeddedComponent={(embedObject) => {
          setReport(embedObject);
        }}
        cssClassName="embed"
      />
    )
  );
}

export default PBIEmbedd;
