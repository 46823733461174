import LanguageContext from 'language-context';
import { useContext } from 'react';

const ProspectSource = ({ source }) => {
  const sourcesToRender = [
    'prospect_search',
    'leads',
    'upload',
    'shared',
    'API provköra',
  ];
  const tc = useContext(LanguageContext);

  if (!source || !sourcesToRender.includes(source)) return null;

  const convertSourceToCardClass = (source) => {
    return source === 'prospect_search'
      ? 'purple'
      : source === 'leads' || source === 'upload'
      ? 'brown'
      : source === 'shared'
      ? 'orange'
      : source === 'API provköra'
      ? 'green'
      : '';
  };

  const convertSourceToCardText = (source) => {
    return source === 'prospect_search'
      ? tc.prospectSearch
      : source === 'leads'
      ? tc.leads
      : source === 'upload'
      ? tc.createPdfByUpload
      : source === 'shared'
      ? tc.sharedWith.split(' ')[0]
      : source === 'API provköra'
      ? tc.testRideService
      : null;
  };

  return (
    <div>
      <p className={`card-source-tag ${convertSourceToCardClass(source)}`}>
        {convertSourceToCardText(source)}
      </p>
    </div>
  );
};

export default ProspectSource;
