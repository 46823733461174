import React, { forwardRef } from 'react';
import Activities from 'components/activities';
import ActivityPipeline from 'components/activity_pipeline';
import ActivityCompletion from 'components/activity_completion';
import DataVisualisation from 'components/analyse/data_visualisation';
import Events from 'components/events';
import LeadsWidget from 'components/leads_widget';
import NewsWidget from 'components/news_widget';
import SupertempWidget from 'components/supertemp_widget';
import ActivityDeals from 'components/activity_deals';

/**
 * Render a dashboard widget
 */
const Item = (props) => {
  const _renderWidget = () => {
    switch (
      props.type.toLowerCase() // <-- Note type in lower case :)
    ) {
      case 'activities':
        return (
          <Activities
            amountIncrease={3}
            hideCommentInput={true}
            hideSelectActivityTypes={false}
            showSaveToDashboard={true}
            view="flow"
            widget={props.widgets?.find((widget) => {
              return widget._id === props._id;
            })}
          />
        );
      case 'analysecar':
        return <DataVisualisation {...props.data} title={props.name} />;
      case 'analysesales':
        return <DataVisualisation {...props.data} title={props.name} />;
      case 'notifications':
        return (
          <Events
            amountIncrease={3}
            showCalendarView={true}
            type="all"
            view="calendar"
            isWidget={props.widget}
            widgets={props.widgets}
          />
        );
      case 'leads':
        return <LeadsWidget />;
      case 'monitorlist':
        return <SupertempWidget {...props} widget={props} />;
      case 'pipeline':
        return <ActivityPipeline widget={props} />;
      case 'news':
        return <NewsWidget amount={5} />;
      case 'activitycompletion':
        return (
          <ActivityCompletion
            {...props}
            widget={props.widgets?.find((widget) => widget._id === props._id)}
            rowsPerPage={5}
          />
        );
      case 'activitydeals':
        return (
          <ActivityDeals
            {...props}
            widget={props.widgets?.find((widget) => widget._id === props._id)}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {_renderWidget()}
      {props.children}
    </>
  );
};

export default forwardRef(({ ...props }, ref) => {
  return (
    <div
      className={[
        'widgetKanbanBoardItemWrapper__widgetKanbanBoardItem',
        props.className,
      ].join(' ')}
      style={{ ...props.style }}
      key={props._id}
      onMouseDown={props.onMouseDown}
      onMouseUp={props.onMouseUp}
      ref={ref}
    >
      <Item {...props} />
    </div>
  );
});
