import React, { useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import LanguageContext from 'language-context';
import { activityHelper } from 'helpers';
import { getActivities } from 'store/activities/tasks';
import { setCommentEditId, setCommentRemoveId } from 'store/comment/tasks';
import { updateWidgets } from 'store/widgets/tasks';
import SaveActivityWidget from 'components/save_activity_widget';
import SaveToExcel from 'components/activities/activities_save_to_excel';
import ActivitiesHeader from 'components/activities/activities_header';
import ActivitiesContentTop from 'components/activities/activities_content_top';
import ActivitiesContentMiddle from 'components/activities/activities_content_middle';
import ActivitiesContentBottom from 'components/activities/activities_content_bottom';

/**
 * Render activities.
 *
 * The data rendered can come from different sources:
 * Either a target is provided and we retrieve activities for that target.
 * Or this component is used as a widget and filters is sent via props, we retrieve data based on those filters.
 * Or activities is provided directly via 'activities' prop.
 * If neither of above is provided, we retrieve data based on activity filters.
 *
 * Can be viewed as a flow, embedded or types version.
 * Flow and embedded is basically the same, but embedded view is meant to be used when just displaying
 * activity rows inside another component, rather than a whole component/widget itself.
 *
 * @param state.props.activities - array (optional) - Array with activities, when these are already provided and we dont have to get them from backend.
 * @param state.props.allRows - bool (optional) - When we want to display all activities without "Get more" button.
 * @param state.props.amountIncrease - number (optional) - How many rows we show initially and add every "Get more"click.
 * @param state.props.hideCommentInput - bool (optional) - Hide the comment input, applicable for flow and embedded.
 * @param state.props.hideEdit - bool (optional) - Hide edit buttons.
 * @param state.props.hideSelectActivityTypes - bool (optional) - Hide the activity types dropdown, applicable for flow and embedded.
 * @param state.props.koncern - bool (optional) - If target is a company, set to true when getting activities for the whole koncern.
 * @param state.props.showSaveToDashboard - bool (optional)
 * @param state.props.target - string (optional) - When showing activities for a specific target. Deal id, leads id, prospect id or vehicle reg number.
 * @param state.props.view - 'flow' | 'embedded' | 'groups' | 'types' - Defaults to flow.
 * @param state.props.widget - object (optional) - Widget object from dashboard, when used as widget.
 * @param isMinimized - A boolean passed down to activites header to indicate whether the component is minimized or not.
 * @param onMinimizeChange - Callback function invoked when the minimize state changes.
 */
const Activities = ({
  activities,
  allRows,
  amountIncrease,
  hideCommentInput,
  hideEdit,
  hideSelectActivityTypes,
  koncern,
  showSaveToDashboard,
  target,
  targetType,
  widget,
  view,
  filter,
  isMinimized,
  onMinimizeChange,
  isCompanyPage,
}) => {
  const [activityTypesToDisplay, setActivityTypesToDisplay] = useState([]);
  const [showAddToDashboard, setShowAddToDashboard] = useState(false);
  const [minimize, setMinimize] = useState(false);
  const [responsiveClassWidth, setResponsiveClassWidth] = useState('');
  const [_view, setView] = useState('flow');
  const [showExceluttag, setShowExcelluttag] = useState(false);
  const activitiesRef = useRef(null);
  const observer = useRef(null);
  const tc = useContext(LanguageContext);

  const memoizedTypes = activityTypesToDisplay.reduce((acc, type) => {
    if (type.active) return [...acc, type.val];
    return acc;
  }, []);
  useEffect(() => {
    setMinimize(isMinimized);
  }, [isMinimized]);
  useEffect(() => {
    _setResponsiveClass();
    observer.current = new ResizeObserver(_setResponsiveClass);
    observer.current.observe(activitiesRef.current);

    const ref = activitiesRef.current;

    return () => observer.current.unobserve(ref);
  }, []);

  useEffect(() => {
    // Set view.
    if (widget) {
      if (widget.dashboards && widget.dashboards.dashboard) {
        if (widget.dashboards.dashboard.view) {
          setView(widget.dashboards.dashboard.view);
        }

        if (widget.dashboards.dashboard.hasOwnProperty('minimized')) {
          setMinimize(widget.dashboards.dashboard.minimized);
        }
      }
    } else if (view) {
      setView(view);
      if (view === 'embedded') {
        setMinimize(false);
      }
    }
  }, [view, widget]);

  useEffect(() => {
    // We've decided to hide comments for types and groups view since comments isn't only a deal activity.
    const actionTypes =
      view === 'types' || view === 'groups'
        ? activityHelper
            .getActionTypes(false)
            .filter((num) => num !== 'comment')
        : activityHelper.getActionTypes(false);

    // Set activityTypesToDisplay.
    if (widget) {
      if (widget.dashboards && widget.dashboards.dashboard) {
        if (Array.isArray(widget.dashboards.dashboard.activityTypesToDisplay)) {
          // Widget activityTypesToDisplay array exists, see which types that is active.
          setActivityTypesToDisplay(
            actionTypes.map((type) => ({
              active:
                !!widget.dashboards.dashboard.activityTypesToDisplay.includes(
                  type
                ),
              label: activityHelper.getReadableActivity(type, false),
              val: type,
            }))
          );
        }
      }
    } else {
      // All types, all active.
      setActivityTypesToDisplay(
        actionTypes.map((type) => ({
          active: true,
          label: activityHelper.getReadableActivity(type, false),
          val: type,
        }))
      );
    }
  }, [widget, view, tc, filter]);
  useEffect(() => {
    if (target && !widget && !activities) {
      // Not used as a widget and activities is not provided so get data for target.
      getActivities({
        koncern: !!koncern,
        target: target,
        type: 'target',
        targetType: targetType,
      });
    } else if (!widget && !activities) {
      // Not used as widget and activities is not provided so get data by activity filter.
      getActivities({ type: 'filter' });
    }
  }, [activities, target, widget, koncern, targetType]);
  const _setResponsiveClass = () => {
    if (!activitiesRef?.current) {
      return;
    }

    const width = activitiesRef.current.getBoundingClientRect().width;
    const breakpoint1 = 400;

    if (width <= breakpoint1) {
      setResponsiveClassWidth('activitiesResponsiveWidth1');
    } else if (width > breakpoint1) {
      setResponsiveClassWidth('');
    }
  };

  return (
    <div className="activitiesWrapper" ref={activitiesRef}>
      <div className="activitiesWrapper__activities">
        {view !== 'embedded' ? (
          <ActivitiesHeader
            view={_view}
            setView={setView}
            minimize={minimize}
            setMinimize={setMinimize}
            onMinimizeChange={onMinimizeChange}
            updateWidgets={updateWidgets}
            setShowAddToDashboard={setShowAddToDashboard}
            showSaveToDashboard={showSaveToDashboard}
            setShowExcelluttag={setShowExcelluttag}
            widget={widget}
            isCompanyPage={isCompanyPage}
          />
        ) : null}
        {
          <div
            className={`activitiesWrapper__activities__content cancelDrag ${responsiveClassWidth} ${
              minimize ? 'minimize' : ''
            }`}
          >
            <ActivitiesContentTop
              setCommentEditId={setCommentEditId}
              setCommentRemoveId={setCommentRemoveId}
              activityTypesToDisplay={activityTypesToDisplay}
              setActivityTypesToDisplay={setActivityTypesToDisplay}
              updateWidgets={updateWidgets}
              target={target}
              targetType={targetType}
              view={_view}
            />
            <ActivitiesContentMiddle
              view={_view}
              memoizedTypes={memoizedTypes}
              activityTypesToDisplay={activityTypesToDisplay}
              widget={widget}
              target={target}
              allRows={allRows}
              amountIncrease={amountIncrease}
              hideEdit={hideEdit}
              targetType={targetType}
            />
            <ActivitiesContentBottom widget={widget} />
          </div>
        }
        {showAddToDashboard ? (
          <SaveActivityWidget
            close={() => {
              setShowAddToDashboard(false);
            }}
            type="activities"
            view={_view}
            widgetSpecificProperties={{
              activityTypesToDisplay: activityTypesToDisplay
                .filter((num) => num.active)
                .map((num) => num.val),
            }}
          />
        ) : null}
        {showExceluttag ? (
          <SaveToExcel
            setShowExcelluttag={setShowExcelluttag}
            activityTypesToDisplay={activityTypesToDisplay}
          />
        ) : null}
      </div>
    </div>
  );
};

const MapStateToProps = (state, props) => {
  return {
    activities: state.activities,
    activity: state.activity,
    comment: state.comment,
    props: props,
    user: state.user,
    widgets: state.widgets,
    filter: state.activity.filter,
    target: props.target,
    targetType: props.targetType,
  };
};

export default connect(MapStateToProps)(Activities);
