import React, { useContext, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import LanguageContext from "language-context";
import { getSupertempSubscriptionData } from "store/supertemp/tasks";
import { updateWidgets } from "store/widgets/tasks";
import { NavLink } from "react-router-dom";
import { tableHelper } from "helpers";
import { Table } from "components/table";
import DisplayValues from "components/display_values";
import Icon from "components/icon";
import Loading from "components/loading";
import Tooltip from "components/tooltip";
import WidgetHeader from "components/widget_header";

/**
 * Render a supertemp widget.
 *
 * @param state.props.widget
 */
export const SupertempWidget = (state) => {
  const [data, setData] = useState(null);
  const [minimize, setMinimize] = useState(false);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    if (
      state.props?.widget?.dashboards?.dashboard &&
      state.props.widget?.dashboards.dashboard.hasOwnProperty("minimized")
    ) {
      setMinimize(state.props.widget.dashboards.dashboard.minimized);
    }
  }, [state.props.widget]);

  useEffect(() => {
    // If we already have data for subscription in state, widgets have probably only moved position, so no backend call necessary.
    if (
      state.props.widget?._id &&
      !state.supertemp.subscriptions.hasOwnProperty(state.props.widget._id) &&
      !state.supertemp.subscriptionsLoading.includes(state.props.widget._id)
    ) {
      getSupertempSubscriptionData({
        id: state.props.widget._id,
        latestBatch: true,
        pageSize: 50, // For widget we just retrieve 50 total and skip backend calls for pagination.
        sort: {
          type: "published",
          direction: "DESC",
        },
        type: "cars",
      });
    }
  }, [
    state.props.widget,
    state.supertemp.subscriptions,
    state.supertemp.subscriptionsLoading,
  ]);

  useEffect(() => {
    if (
      state.props.widget?._id &&
      state.supertemp.subscriptions.hasOwnProperty(state.props.widget._id) &&
      !state.supertemp.subscriptionsLoading.includes(state.props.widget._id)
    ) {
      setData(state.supertemp.subscriptions[state.props.widget._id]);
    }
  }, [
    state.props.widget,
    state.supertemp.subscriptions,
    state.supertemp.subscriptionsLoading,
  ]);

  const _stateCheck = () => {
    return !!data;
  };

  return (
    <div className={`supertempWidgetWrapper`}>
      <div className="supertempWidgetWrapper__supertempWidget">
        <div className="supertempWidgetWrapper__supertempWidget__header">
          <WidgetHeader
            dashboardItems={
              _stateCheck()
                ? [
                    {
                      disabled: !minimize,
                      icon: "maximize",
                      label: tc.maximize,
                      onClick: () => {
                        setMinimize(false);
                        if (Array.isArray(state.widgets?.data?.widgets)) {
                          updateWidgets({
                            widgets: state.widgets.data.widgets.map((num) => {
                              if (
                                state.props?.widget?._id === num._id &&
                                num?.dashboards?.dashboard
                              ) {
                                num.dashboards.dashboard.minimized = false;
                              }
                              return num;
                            }),
                          });
                        }
                      },
                    },
                    {
                      disabled: minimize,
                      icon: "minimize",
                      label: tc.minimize,
                      onClick: () => {
                        setMinimize(true);
                        if (Array.isArray(state.widgets?.data?.widgets)) {
                          updateWidgets({
                            widgets: state.widgets.data.widgets.map((num) => {
                              if (
                                state.props?.widget?._id === num._id &&
                                num?.dashboards?.dashboard
                              ) {
                                num.dashboards.dashboard.minimized = true;
                              }
                              return num;
                            }),
                          });
                        }
                      },
                    },
                  ]
                : null
            }
            headline={tc.supertemp}
            headlineSub={
              _stateCheck() ? (
                `${tc.supertempMonitoring}: ${
                  state.props.widget?.name ? state.props.widget.name : ""
                }`
              ) : (
                <Loading small={true} />
              )
            }
          />
        </div>
        {_stateCheck() ? (
          <div
            className={
              minimize
                ? "supertempWidgetWrapper__supertempWidget__content minimize"
                : "supertempWidgetWrapper__supertempWidget__content"
            }
          >
            <div className="supertempWidgetWrapper__supertempWidget__content__top">
              <div className="supertempWidgetWrapper__supertempWidget__content__top__info">
                <DisplayValues
                  from={{
                    text: tc.supertempWidgetNew,
                    title: tc.supertempWidgetNewTitle,
                    val:
                      data?.latestBatch === "loading"
                        ? "loading"
                        : data.latestBatch &&
                          data.latestBatch.count &&
                          data.latestBatch.count.total
                        ? data.latestBatch.count.total
                        : 0,
                  }}
                  to={{
                    text: tc.supertempWidgetHandle,
                    title: tc.supertempWidgetHandleTitle,
                    val: data.total.total,
                  }}
                />
              </div>
              <div className="supertempWidgetWrapper__supertempWidget__content__top__table">
                <Table
                  columns={tableHelper.getSupertempWidgetColumns()}
                  resizable={true}
                  rows={tableHelper.getSupertempWidgetRows(data.items)}
                  rowsPerPage={5}
                />
              </div>
            </div>
            <div className="supertempWidgetWrapper__supertempWidget__content__bottom">
              <NavLink exact to={`/bevakning/${state.props.widget._id}`}>
                <h4>{tc.exploreProspects}</h4>
                <Icon val="navigateNext" />
              </NavLink>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const MapStateToProps = (state, props) => {
  return {
    props: props,
    supertemp: state.supertemp,
    widgets: state.widgets,
  };
};

export default connect(MapStateToProps)(SupertempWidget);
