import React, { useContext, useEffect, useState } from 'react';
import { tableHelper } from 'helpers';
import LanguageContext from 'language-context';
import carHelper from 'shared_helpers/car_helper';
import companyHelper from 'shared_helpers/company_helper';
import { deleteLeads, moveToAgile, moveToList } from 'store/leads/tasks';
import { getPaginatedLeads } from 'store/leads/tasks';
import { connect } from 'react-redux';
import Wayke from 'images/wayke_help_webhook_field.png';
import ColleaguesDropdown from 'components/colleagues_dropdown';
import LeadsPreview from './leads_preview';
import LeadsUpload from 'components/leads/leads_upload/leads_upload';

import Loading from 'components/loading';
import Menu from 'components/menu';
import PageHeader from 'components/page_header';
import Popup from 'components/popup';
import SaveToList from 'components/save_to_list';
import { TablePropsManaged } from 'components/table';
import WidgetHeader from 'components/widget_header';
import WidgetFooter from 'components/widget_footer';
import MoveAllLeads from './move_all_leads';

const Leads = (state) => {
  const [isMovableToList, setIsMovableToList] = useState(false);
  const [moveToAgileUser, setMoveToAgileUser] = useState({
    id: null,
    name: '',
  });
  const [movingLeads, setMovingLeads] = useState(false);
  const [page, setPage] = useState(0);
  const [previewLead, setPreviewLead] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [query, setQuery] = useState('');
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showExternalLeadsPopup, setShowExternalLeadsPopup] = useState(false);
  const [showMoveToAgilePopup, setShowMoveToAgilePopup] = useState(false);
  const [showMoveToListPopup, setShowMoveToListPopup] = useState(false);
  const [showMoveAll, setShowMoveAll] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [sort, setSort] = useState({
    order: 'desc',
    orderBy: '',
  });
  const tc = useContext(LanguageContext);

  useEffect(() => {
    getPaginatedLeads({
      limit: rowsPerPage,
      skip: rowsPerPage * page,
      order: sort.order,
      orderBy: sort.orderBy,
      query,
    });
  }, [rowsPerPage, sort, page, query]);

  useEffect(() => {
    if (state.user?.info?.id) {
      setMoveToAgileUser({
        id: state.user.info.id,
        name: state.user.info.name,
      });
    }
  }, [state.user.info]);

  useEffect(() => {
    // Check if any of the selected leads can be moved to list.
    const validArr = selectedLeads
      .map((num) => {
        return {
          orgNr: num.orgNr,
          regNr: num.regNr,
        };
      })
      .filter((num) => {
        return (
          num &&
          (carHelper.isValidRegNumber(num.regNr) ||
            companyHelper.isValidOrgNr(num.orgNr))
        );
      });

    setIsMovableToList(!!validArr.length);
  }, [selectedLeads]);

  const _reloadLeads = () => {
    getPaginatedLeads({
      limit: rowsPerPage,
      skip: rowsPerPage * page,
      order: sort.order,
      orderBy: sort.orderBy,
      query,
    });
  };

  const _setPreviewLead = (id) => {
    const lead = state.leads.data.items.find((num) => num.id === id);

    if (lead) {
      setPreviewLead(lead);
    } else {
      setPreviewLead(null);
    }
  };

  const _stateCheck = () => {
    return !!(
      state.leads.data &&
      state.leads.data.items &&
      state.leads.data.hasOwnProperty('totalCount')
    );
  };

  return _stateCheck() ? (
    <div className="leadsWrapper">
      {movingLeads ? (
        <div className="leadsWrapper__movingLeads">
          <div className="leadsWrapper__movingLeads__content">
            <Loading />
          </div>
        </div>
      ) : null}
      <div className="leadsWrapper__leads">
        <div className="leadsWrapper__leads__header">
          <PageHeader
            headlineSub={`${tc.total} ${
              state.leads.data.totalCount
            } ${tc.leads.toLowerCase()}`}
          />
        </div>
        <div className="leadsWrapper__leads__content">
          <div className="leadsWrapper__leads__content__left">
            <div className="leadsWrapper__leads__content__left__top">
              <Menu
                items={[
                  {
                    label: tc.uploadLeads,
                    onClick: () => {
                      setShowUpload(true);
                    },
                    type: 'button',
                  },
                  {
                    disabled: !selectedLeads.length,
                    type: 'button',
                    label: tc.remove,
                    onClick: () => setShowDeletePopup(true),
                  },
                  {
                    disabled: !selectedLeads.length,
                    type: 'button',
                    label: tc.moveToAgile,
                    onClick: () => {
                      setShowMoveToAgilePopup(true);
                    },
                  },
                  {
                    disabled: !(selectedLeads.length && isMovableToList),
                    type: 'button',
                    label: tc.moveToList,
                    onClick: () => setShowMoveToListPopup(true),
                  },
                  {
                    // icon: "infoOutlined",
                    type: 'button',
                    label: tc.externalLeads,
                    onClick: () => setShowExternalLeadsPopup(true),
                  },
                  {
                    disabled: !(state.leads.data.items?.length > 0),
                    type: 'button',
                    label: tc.leadsMoveAll,
                    onClick: () => {
                      setShowMoveAll(true);
                    },
                  },
                ]}
              />
            </div>
            <div className="leadsWrapper__leads__content__left__bottom">
              <TablePropsManaged
                adjustToContainer={true}
                columns={tableHelper.getLeadsColumns(state.leads.data.items)}
                rows={tableHelper.getLeadsRows(
                  state.leads.data.items,
                  _setPreviewLead
                )}
                total={state.leads.data.totalCount}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageChange={(val) => {
                  setRowsPerPage(val);
                }}
                order={sort.order}
                orderBy={sort.orderBy}
                query={query}
                pageChange={(val) => {
                  setPage(val);
                }}
                search={(val) => {
                  setQuery(val);
                }}
                sort={(val) => {
                  setSort(val);
                }}
                onSelect={(arr) => {
                  setSelectedLeads(
                    state.leads.data.items.filter((num) => arr.includes(num.id))
                  );
                }}
                selected={selectedLeads.map((num) => num.id)}
              />
            </div>
          </div>
          {previewLead ? (
            <div
              className={
                previewLead
                  ? 'leadsWrapper__leads__content__right'
                  : 'leadsWrapper__leads__content__right minimize'
              }
            >
              <LeadsPreview
                close={() => {
                  setPreviewLead(null);
                }}
                lead={previewLead}
              />
            </div>
          ) : null}
        </div>
        {showUpload ? (
          <Popup
            close={() => {
              setShowUpload(false);
            }}
            size="big"
          >
            <LeadsUpload reload={_reloadLeads} setShowUpload={setShowUpload} />
          </Popup>
        ) : null}

        {showDeletePopup ? (
          <Popup
            close={() => {
              setShowDeletePopup(false);
            }}
            size="small"
          >
            <div className="genericPopupContentWrapper">
              <div className="genericPopupContentWrapper__genericPopupContent">
                <div className="genericPopupContentWrapper__genericPopupContent__header">
                  <WidgetHeader headline={tc.deleteLeads} />
                </div>
                <div className="genericPopupContentWrapper__genericPopupContent__content">
                  <p>{tc.deleteLeadsPrompt}</p>
                </div>
                <div className="genericPopupContentWrapper__genericPopupContent__footer">
                  <WidgetFooter
                    buttonOneText={tc.remove}
                    buttonOneFunc={async () => {
                      setShowDeletePopup(false);
                      setMovingLeads(true);
                      await deleteLeads({
                        rows: selectedLeads.map((num) => num.id),
                      });
                      setMovingLeads(false);
                      setSelectedLeads([]);
                    }}
                    buttonTwoText={tc.cancel}
                    buttonTwoFunc={() => {
                      setShowDeletePopup(false);
                    }}
                  />
                </div>
              </div>
            </div>
          </Popup>
        ) : null}
        {showExternalLeadsPopup ? (
          <Popup
            close={() => {
              setShowExternalLeadsPopup(false);
            }}
            size="medium"
          >
            <div className="externalLeadsWrapper">
              <div className="externalLeadsWrapper__externalLeads">
                <div className="externalLeadsWrapper__externalLeads__header">
                  <WidgetHeader
                    headline={tc.externalLeads}
                    headlineSub={tc.externalLeadsIntegration}
                  />
                </div>
                <div className="externalLeadsWrapper__externalLeads__content">
                  <div className="externalLeadsWrapper__externalLeads__content__section">
                    <h4>{tc.wayke}</h4>
                    <p>{tc.waykeInfo}</p>
                    <p>
                      {tc.waykeUrl}
                      <span>{`${tc.waykeUrl2}${state.user.info.dealer_id}`}</span>
                    </p>
                    <div className="externalLeadsWrapper__externalLeads__content__section__imgContainer">
                      <img src={Wayke} alt={tc.waykeImgAltText} />
                    </div>
                  </div>
                  <div className="externalLeadsWrapper__externalLeads__content__section">
                    <h4>{tc.autoUncle}</h4>
                    <p>{tc.autoUncleInfo}</p>
                  </div>
                </div>
                <div className="externalLeadsWrapper__externalLeads__footer">
                  <WidgetFooter
                    buttonOneText={tc.ok}
                    buttonOneFunc={() => {
                      setShowExternalLeadsPopup(false);
                    }}
                  />
                </div>
              </div>
            </div>
          </Popup>
        ) : null}
        {showMoveToAgilePopup ? (
          <Popup
            close={() => {
              setShowMoveToAgilePopup(false);
            }}
            size="small"
          >
            <div className="genericPopupContentWrapper">
              <div className="genericPopupContentWrapper__genericPopupContent">
                <div className="genericPopupContentWrapper__genericPopupContent__header">
                  <WidgetHeader headline={tc.moveToAgile} />
                </div>
                <div className="genericPopupContentWrapper__genericPopupContent__content">
                  <p>{tc.moveLeadsToAgileInfo}</p>
                  <p>{tc.moveLeadsToAgileInfo2}</p>
                  <div className="genericPopupContentWrapper__genericPopupContent__content__flexHolder centered marginTop">
                    <div className="genericPopupContentWrapper__genericPopupContent__content__flexHolder__left">
                      <p>
                        {`${
                          tc.move
                        } ${tc.leads.toLowerCase()} ${tc.to.toLowerCase()}`}
                        :
                      </p>
                    </div>
                    <div className="genericPopupContentWrapper__genericPopupContent__content__flexHolder__right">
                      <ColleaguesDropdown
                        activeId={moveToAgileUser.id}
                        activeIds={[]}
                        activeName={moveToAgileUser.name}
                        transparent={true}
                        onClick={(id, name) => {
                          setMoveToAgileUser({
                            id: id,
                            name: name,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="genericPopupContentWrapper__genericPopupContent__footer">
                  <WidgetFooter
                    buttonOneText={tc.move}
                    buttonOneFunc={async () => {
                      setShowMoveToAgilePopup(false);
                      setMovingLeads(true);
                      await moveToAgile({
                        rows: selectedLeads.map((num) => num.id),
                        userId: moveToAgileUser.id,
                      });
                      setSelectedLeads([]);
                      setMovingLeads(false);
                    }}
                    buttonTwoText={tc.cancel}
                    buttonTwoFunc={() => {
                      setShowMoveToAgilePopup(false);
                    }}
                  />
                </div>
              </div>
            </div>
          </Popup>
        ) : null}
        {showMoveToListPopup ? (
          <SaveToList
            close={() => {
              setShowMoveToListPopup(false);
              setSelectedLeads([]);
            }}
            text={tc.moveLeadsToListInfo}
            save={async (payload) => {
              setShowMoveToListPopup(false);
              setMovingLeads(true);
              if (payload.lists) {
                await moveToList({
                  rows: selectedLeads.map((num) => num.id),
                  mode: 'savedLists',
                  value: payload.lists,
                });
              } else {
                await moveToList({
                  rows: selectedLeads.map((num) => num.id),
                  mode: 'newList',
                  value: payload.name,
                });
              }

              setSelectedLeads([]);
              setMovingLeads(false);
            }}
          />
        ) : null}
        {showMoveAll && state.leads.data?.totalCount > 0 && (
          <MoveAllLeads
            close={() => {
              setShowMoveAll(false);
            }}
            totalLeads={state.leads.data.totalCount}
            onFinishMove={() => {
              getPaginatedLeads({
                limit: rowsPerPage,
                skip: rowsPerPage * page,
                order: sort.order,
                orderBy: sort.orderBy,
                query,
              });
            }}
          />
        )}
      </div>
    </div>
  ) : (
    <Loading />
  );
};

const MapStateToProps = (state) => {
  return {
    leads: state.leads,
    user: state.user,
  };
};

export default connect(MapStateToProps)(Leads);
