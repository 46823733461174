import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ActivitiesFlow from './activities_flow';
import ActivitiesFlowNew from 'components/activities/activities_flow_new';
import ActivityTypesTableNew from 'components/activity_types_table/activity_types_table_new';
import ActivityTypesGroups from 'components/activity_types_groups';
import { getActivitiesByTarget } from 'store/activities/tasks';
const ActivitiesContentMiddle = ({
  activities,
  user,
  filter,
  widget,
  target,
  view,
  memoizedTypes,
  allRows,
  amountIncrease,
  hideEdit,
  targetType,
}) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    if (activities && !target) {
      setData(activities);
    } else if (widget && !target) {
      setData(
        activities.subscriptionsLoading.includes(widget._id)
          ? null
          : activities.activitiesSubscriptions[widget._id]
      );
    } else {
      setData(
        target ? activities.activitiesByTarget : activities.activitiesByFilter
      );
    }
  }, [activities, target, widget]);

  return (
    <div className="activitiesWrapper__activities__content__middle">
      {view === 'embedded' && (
        <ActivitiesFlow
          activityTypesToDisplay={memoizedTypes}
          allRows={allRows}
          amountIncrease={amountIncrease}
          data={data}
          fetchOwnActivities={data && Array.isArray(data) ? false : true}
          embedded={true}
          hideEdit={hideEdit}
          target={target}
          targetType={targetType}
          user={user}
        />
      )}
      {view === 'flow' &&
        (target && !widget?._id ? (
          <ActivitiesFlow
            activityTypesToDisplay={memoizedTypes}
            allRows={allRows}
            amountIncrease={amountIncrease}
            data={
              Array.isArray(data)
                ? data.slice(0, data.length > 200 ? 200 : data.length)
                : data
            }
            fetchOwnActivities={data && Array.isArray(data) ? false : true}
            embedded={false}
            hideEdit={hideEdit}
            target={target}
            targetType={targetType}
            user={user}
          />
        ) : (
          <ActivitiesFlowNew
            activityTypesToDisplay={
              widget?.dashboards?.dashboard?.activityTypesToDisplay ??
              memoizedTypes
            }
            type="all"
            queryData={widget?.data ?? filter}
          />
        ))}
      {view === 'groups' && (
        <ActivityTypesGroups
          activityTypesToDisplay={memoizedTypes}
          data={data}
          historic={true}
          target={target}
          widget={widget ? widget : null}
        />
      )}
      {view === 'types' && (
        <ActivityTypesTableNew
          activityTypesToDisplay={memoizedTypes}
          historic={true}
          target={target}
          queryData={widget?.data ? widget.data : filter}
        />
      )}
      {view !== 'embedded' &&
        view !== 'flow' &&
        view !== 'groups' &&
        view !== 'types' && (
          <ActivitiesFlow
            fetchOwnActivities={data && Array.isArray(data) ? false : true}
            activityTypesToDisplay={memoizedTypes}
            allRows={allRows}
            amountIncrease={amountIncrease}
            data={data}
            embedded={false}
            hideEdit={hideEdit}
            target={target}
            targetType={targetType}
            user={user}
          />
        )}
    </div>
  );
};

const MapStateToProps = (state, props) => {
  return {
    activities: state.activities,
    user: state.user,
    filter: state.activity.filter,
    targetType: props.targetType,
  };
};
export default connect(MapStateToProps)(ActivitiesContentMiddle);
