import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, useParams } from 'react-router-dom';
import LanguageContext from 'language-context';
import moment from 'moment';
import { setPreview } from 'store/preview/tasks';
import { getLists } from 'store/lists/tasks';
import { moveItem } from 'store/agile/tasks';
import { getActivities } from 'store/activities/tasks';
import {
  getInspectByFilter,
  getPipelineByFilter,
  setDefaultActivityFilter,
  setActivityFilter,
} from 'store/activity/tasks';
import { getEvents } from 'store/events/tasks';
import { restoreDeal } from 'store/deal/tasks';
import Activities from 'components/activities';
import ActivityInspect from './activity_inspect';
import ActivityPipeline from 'components/activity_pipeline';
import ActivityCompletion from 'components/activity_completion';
import Events from 'components/events';
import Loading from 'components/loading';
import Menu from 'components/menu';
import PageHeader from 'components/page_header';
import ActivityDeals from 'components/activity_deals';
import SubscriptionFilterDropdown from 'components/agile/subscription_filter_dropdown';

const Activity = (state) => {
  const { status } = useParams();
  const [colleaguesMapped, setColleaguesMapped] = useState([]);
  const [headlineSub, setHeadlineSub] = useState(null);
  const [inspectSelected, setInspectSelected] = useState([]);
  const [listsMapped, setListsMapped] = useState([]);
  const tc = useContext(LanguageContext);

  const possibleProspectFilters = [
    { name: 'Privatperson' },
    { name: 'Företag' },
  ];

  useEffect(() => {
    getLists({});
  }, []);

  useEffect(() => {
    // Set colleagues and connection colleagues in state array, since it requires some iteration.
    let colleaguesAndConnections = [];
    if (Array.isArray(state.user.colleagues)) {
      if (Array.isArray(state.user.connections)) {
        // When both colleagues and connections exist, add a Select all button.

        // All users in colleagues and connections selected?
        let active = true;
        state.user.colleagues.forEach((user) => {
          if (active === false) {
            return;
          }
          if (!state.activity.filter.users.includes(user.id)) {
            active = false;
          }
        });
        state.user.connections.forEach((dealer) => {
          dealer.users.forEach((user) => {
            if (active === false) {
              return;
            }
            if (!state.activity.filter.users.includes(user.id)) {
              active = false;
            }
          });
        });

        colleaguesAndConnections.push({
          active: active,
          type: 'delimiterWithCheckbox',
          label: active ? tc.unselectAll : tc.selectAll,
          onClick: () => {
            setActivityFilter({
              action: active ? 'remove' : 'add',
              type: 'user',
              val: state.user.colleagues
                .map((user) => {
                  return !!(
                    state.activity?.filter?.lists?.length &&
                    !state.activity.filter.lists.filter((id) => {
                      const list = state.lists.lists.find(
                        (num) => num.id === id
                      );
                      return list && list.users.find((id) => +id === +user.id);
                    }).length
                  )
                    ? null
                    : user.id;
                })
                .concat(
                  state.user.connections.map((dealer) => {
                    return dealer.users.map((user) => {
                      return !!(
                        state.activity?.filter?.lists?.length &&
                        !state.activity.filter.lists.filter((id) => {
                          const list = state.lists.lists.find(
                            (list) => list.id === id
                          );
                          return (
                            list && list.users.find((id) => +id === +user.id)
                          );
                        }).length
                      )
                        ? null
                        : user.id;
                    });
                  })
                )
                .flat()
                .filter((num) => num),
            });
          },
        });
      }

      // All colleagues selected?
      let active = true;
      state.user.colleagues.forEach((user) => {
        if (active === false) {
          return;
        }
        if (!state.activity.filter.users.includes(user.id)) {
          active = false;
        }
      });

      colleaguesAndConnections.push({
        active: active,
        type: 'delimiterWithCheckbox',
        label: state.user.info.dealerName,
        onClick: () => {
          setActivityFilter({
            action: active ? 'remove' : 'add',
            type: 'user',
            val: state.user.colleagues
              .map((user) => {
                return !!(
                  state.activity?.filter?.lists?.length &&
                  !state.activity.filter.lists.filter((id) => {
                    const list = state.lists.lists.find(
                      (list) => list.id === id
                    );
                    return list && list.users.find((id) => +id === +user.id);
                  }).length
                )
                  ? null
                  : user.id;
              })
              .filter((num) => num),
          });
        },
      });

      state.user.colleagues
        .sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          } else {
            return 0;
          }
        })
        .forEach((user) => {
          colleaguesAndConnections.push({
            active: state.activity.filter.users.includes(user.id),
            disabled: !!(
              state.activity?.filter?.lists?.length &&
              !state.activity.filter.lists.filter((id) => {
                const list = state.lists.lists.find((list) => list.id === id);
                return list && list.users.find((id) => +id === +user.id);
              }).length
            ),
            label: user.name,
            onClick: async () => {
              await setActivityFilter({ type: 'user', val: user.id });
            },
          });
        });
    }

    if (Array.isArray(state.user.connections)) {
      state.user.connections.forEach((dealer) => {
        // All users within dealer selected?
        let active = true;
        dealer.users.forEach((user) => {
          if (active === false) {
            return;
          }
          if (!state.activity.filter.users.includes(user.id)) {
            active = false;
          }
        });

        colleaguesAndConnections.push({
          active: active,
          type: 'delimiterWithCheckbox',
          label: dealer.name,
          onClick: () => {
            setActivityFilter({
              action: active ? 'remove' : 'add',
              type: 'user',
              val: dealer.users
                .map((user) => {
                  return !!(
                    state.activity?.filter?.lists?.length &&
                    !state.activity.filter.lists.filter((id) => {
                      const list = state.lists.lists.find(
                        (list) => list.id === id
                      );
                      return list && list.users.find((id) => +id === +user.id);
                    }).length
                  )
                    ? null
                    : user.id;
                })
                .filter((num) => num),
            });
          },
        });

        dealer.users
          .sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            } else {
              return 0;
            }
          })
          .forEach((user) => {
            colleaguesAndConnections.push({
              active: state.activity.filter.users.includes(user.id),
              disabled: !!(
                state.activity?.filter?.lists?.length &&
                !state.activity.filter.lists.filter((id) => {
                  const list = state.lists.lists.find((list) => list.id === id);
                  return list && list.users.find((id) => +id === +user.id);
                }).length
              ),
              label: user.name,
              onClick: async () => {
                await setActivityFilter({ type: 'user', val: user.id });
              },
            });
          });
      });
    }

    setColleaguesMapped(colleaguesAndConnections);
  }, [
    state.user,
    state.activity.filter.users,
    state.activity.filter.lists,
    state.lists.lists,
    tc,
  ]);

  useEffect(() => {
    if (Array.isArray(state.lists.lists) && state.lists.lists.length) {
      // Map lists, put active on top.
      const active = state.lists.lists
        .filter((list) => state.activity.filter.lists.includes(list.id))
        .sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          } else {
            return 0;
          }
        })
        .map((list) => {
          return {
            active: true,
            disabled: !!(
              state.activity.filter.users &&
              state.activity.filter.users.length &&
              !list.users.find((id) =>
                state.activity.filter.users.includes(+id)
              )
            ),
            label: list.name,
            onClick: async () => {
              await setActivityFilter({
                type: 'list',
                val: list.id,
              });
            },
          };
        });

      const unactive = state.lists.lists
        .filter((list) => !state.activity.filter.lists.includes(list.id))
        .sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          } else {
            return 0;
          }
        })
        .map((list) => {
          return {
            active: false,
            disabled: !!(
              state.activity.filter.users &&
              state.activity.filter.users.length &&
              !list.users.find((id) =>
                state.activity.filter.users.includes(+id)
              )
            ),
            label: list.name,
            onClick: async () => {
              await setActivityFilter({
                type: 'list',
                val: list.id,
              });
            },
          };
        });

      setListsMapped(active.concat(unactive));
    } else {
      setListsMapped([]);
    }
  }, [
    state.user,
    state.activity.filter.users,
    state.activity.filter.lists,
    state.lists.lists,
  ]);

  useEffect(() => {
    // Build preview data object.
    let dataObj = {};

    // Set date.
    dataObj.dates = {
      from: [
        {
          label: moment(state.activity.filter.date.from).format('LL'),
        },
      ],
      to: [
        {
          label: moment(state.activity.filter.date.to).format('LL'),
        },
      ],
    };

    if (state.activity.filter.users.length) {
      // Set users.
      dataObj.users = {};

      state.activity.filter.users.forEach((id) => {
        let hit = false;

        if (state.user.info.id === id) {
          hit = true;
          if (Array.isArray(dataObj.users[state.user.info.dealerName])) {
            dataObj.users[state.user.info.dealerName] = dataObj.users[
              state.user.info.dealerName
            ].concat([{ label: state.user.info.name }]);
          } else {
            dataObj.users[state.user.info.dealerName] = [
              { label: state.user.info.name },
            ];
          }
        }

        if (Array.isArray(state.user.colleagues) && !hit) {
          const user = state.user.colleagues.find((num) => num.id === id);
          if (
            Array.isArray(dataObj.users[state.user.info.dealerName]) &&
            user
          ) {
            hit = true;
            dataObj.users[state.user.info.dealerName] = dataObj.users[
              state.user.info.dealerName
            ].concat([{ label: user.name }]);
          } else if (user) {
            hit = true;
            dataObj.users[state.user.info.dealerName] = [{ label: user.name }];
          }
        }

        if (Array.isArray(state.user.connections) && !hit) {
          state.user.connections.forEach((dealer) => {
            if (Array.isArray(dealer.users) && !hit) {
              const user = dealer.users.find((num) => num.id === id);
              if (Array.isArray(dataObj.users[dealer.name]) && user) {
                hit = true;
                dataObj.users[dealer.name] = dataObj.users[dealer.name].concat([
                  { label: user.name },
                ]);
              } else if (user) {
                hit = true;
                dataObj.users[dealer.name] = [{ label: user.name }];
              }
            }
          });
        }
      });
    }

    if (state.activity.filter.lists.length) {
      // Set lists.
      dataObj.lists = {};

      state.activity.filter.lists.forEach((id) => {
        const list = state.lists.lists.find((list) => list.id === id);

        if (list) {
          if (Array.isArray(dataObj.lists.lists)) {
            dataObj.lists.lists = dataObj.lists.lists.concat([
              { label: list.name },
            ]);
          } else {
            dataObj.lists.lists = [{ label: list.name }];
          }
        }
      });
    }

    setPreview({
      data: dataObj,
      icons: ['dates']
        .concat(dataObj.users ? ['users'] : [])
        .concat(dataObj.lists ? ['lists'] : []),
      type: 'activity',
    });
  }, [
    state.user,
    state.activity.filter.date,
    state.activity.filter.users,
    state.activity.filter.lists,
    state.lists.lists,
  ]);

  useEffect(() => {
    const loadInspectData = async () => {
      if (status === 'aktiva') {
        await getInspectByFilter({ excludePhases: ['lost', 'trash', 'won'] });
      } else if (status === 'alla') {
        await getInspectByFilter({});
      } else if (status === 'borttagna') {
        let includePhases = ['trash'];
        await getInspectByFilter({ includePhases: includePhases });
      } else if (status === 'forlorade') {
        let includePhases = ['lost'];
        await getInspectByFilter({ includePhases: includePhases });
      } else if (status === 'vunna') {
        let includePhases = ['won'];
        await getInspectByFilter({ includePhases: includePhases });
      }
    };

    const loadActivityData = async () => {
      getActivities({ type: 'filter' });
      getEvents({ type: 'filter' });
      getPipelineByFilter();
    };

    // Reload data when filter changes.
    if (window.location.pathname === '/bearbeta/aktivitet') {
      loadActivityData();
    } else {
      loadInspectData();
    }
  }, [status, state.activity.filter]);

  useEffect(() => {
    if (!state.activity?.inspectByFilter && status) {
      setHeadlineSub(null);
    } else {
      // Set sub headline accordingly.
      let amount = state.activity?.inspectByFilter?.length
        ? state.activity.inspectByFilter.length
        : 0;

      switch (status) {
        case 'aktiva':
          setHeadlineSub(
            `${amount} ${tc.deals.toLowerCase()} ${tc.total.toLowerCase()}`
          );
          break;
        case 'alla':
          setHeadlineSub(
            `${amount} ${tc.deals.toLowerCase()} ${tc.total.toLowerCase()}`
          );
          break;
        case 'borttagna':
          setHeadlineSub(
            `${amount} ${tc.deals.toLowerCase()} ${tc.total.toLowerCase()}`
          );
          break;
        case 'forlorade':
          setHeadlineSub(
            `${amount} ${tc.deals.toLowerCase()} ${tc.total.toLowerCase()}`
          );
          break;
        case 'vunna':
          setHeadlineSub(
            `${amount} ${tc.deals.toLowerCase()} ${tc.total.toLowerCase()}`
          );
          break;
        default:
          setHeadlineSub('');
          break;
      }
    }
  }, [status, state.activity.inspectByFilter, tc]);

  const _inspectRemove = async () => {
    // Move every selected deal to trash.
    await Promise.all(
      inspectSelected.map(async (num) => {
        await moveItem({
          comment: tc.restoreDeal,
          id: num.id,
          listId: num.meta.moved_from_list,
          prospectIds: num.prospect_id ? [num.prospect_id] : [],
          reason: null,
          target: 'trash',
          source: num.phase,
        });
      })
    );

    // Reload inspect data.
    await _loadInspectData();

    setInspectSelected([]);
  };

  const _inspectRestore = async () => {
    // Restore every selected deal.
    await Promise.all(
      inspectSelected.map(async (num) => {
        await restoreDeal({ id: num.id });
      })
    );

    // Reload inspect data.
    await _loadInspectData();

    setInspectSelected([]);
  };

  const _loadInspectData = async () => {
    if (status === 'aktiva') {
      return await getInspectByFilter({
        excludePhases: ['lost', 'trash', 'won'],
      });
    } else if (status === 'alla') {
      return await getInspectByFilter({});
    } else if (status === 'borttagna') {
      return await getInspectByFilter({ includePhases: ['trash'] });
    } else if (status === 'forlorade') {
      return await getInspectByFilter({ includePhases: ['lost'] });
    } else if (status === 'vunna') {
      return await getInspectByFilter({ includePhases: ['won'] });
    }
  };

  const _setDate = (date) => {
    if (date.from && date.to) {
      setActivityFilter({
        type: 'date',
        val: { from: date.from, to: date.to },
      });
    } else if (date.from && !date.to) {
      setActivityFilter({
        type: 'date',
        val: { from: date.from, to: state.activity.filter.date.to },
      });
    } else if (date.to && !date.from) {
      setActivityFilter({
        type: 'date',
        val: { from: state.activity.filter.date.from, to: date.to },
      });
    }
  };

  const _stateCheck = () => {
    return !!(
      state.activity.filter &&
      state.activity.filter.lists &&
      state.activity.filter.users &&
      state.activity.filter.date
    );
  };

  return _stateCheck() ? (
    <div className="activityWrapper">
      <div className="activityWrapper__activity">
        <div className="activityWrapper__activity__header">
          <div className="activityWrapper__activity__header__top">
            <PageHeader headlineSub={headlineSub} />
          </div>
          <div className="activityWrapper__activity__header__bottom">
            <Menu
              items={[
                {
                  dateFrom: state.activity.filter.date.from,
                  dateTo: state.activity.filter.date.to,
                  onChange: (date) => {
                    _setDate({ from: date.from, to: date.to });
                  },
                  showTime: false,
                  showYear: true,
                  type: 'date',
                },
                {
                  checkboxes: true,
                  label: tc.colleagues,
                  items: colleaguesMapped,
                  type: 'dropdown',
                },
                {
                  element: <SubscriptionFilterDropdown activity={true} />,
                  type: 'element',
                },
                listsMapped
                  ? {
                      checkboxes: true,
                      label: tc.lists,
                      items: listsMapped,
                      type: 'dropdown',
                    }
                  : {
                      element: <Loading small={true} />,
                      type: 'element',
                    },
                {
                  label: tc.prospectType,
                  type: 'dropdown',
                  items: possibleProspectFilters.map((filter) => {
                    return {
                      active: state.activity.filter.prospectTypes?.includes(
                        filter.name
                      ),
                      label:
                        filter.name === 'Privatperson'
                          ? tc.privatePerson
                          : tc.company,
                      onClick: () => {
                        setActivityFilter({
                          val: filter.name,
                          type: 'prospectType',
                        });
                      },
                    };
                  }),
                  checkboxes: true,
                },
                {
                  label: tc.resetFilter,
                  onClick: setDefaultActivityFilter,
                  type: 'button',
                },
                window.location.pathname === '/bearbeta/aktivitet/borttagna' ||
                window.location.pathname === '/bearbeta/aktivitet/forlorade' ||
                window.location.pathname === '/bearbeta/aktivitet/vunna' ||
                window.location.pathname === '/bearbeta/aktivitet/alla' ||
                window.location.pathname === '/bearbeta/aktivitet/aktiva'
                  ? {
                      disabled: inspectSelected.length === 0,
                      label:
                        inspectSelected.length > 1
                          ? tc.restoreDeals
                          : tc.restoreDeal,
                      onClick: _inspectRestore,
                      type: 'button',
                    }
                  : null,
                window.location.pathname === '/bearbeta/aktivitet/forlorade' ||
                window.location.pathname === '/bearbeta/aktivitet/vunna' ||
                window.location.pathname === '/bearbeta/aktivitet/alla' ||
                window.location.pathname === '/bearbeta/aktivitet/aktiva'
                  ? {
                      disabled:
                        inspectSelected.length === 0 ||
                        inspectSelected.find((num) => num.phase === 'trash'),
                      label:
                        inspectSelected.length > 1
                          ? tc.removeDeals
                          : tc.removeDeal,
                      onClick: _inspectRemove,
                      type: 'button',
                    }
                  : null,
              ]}
              skipSort={true}
            />
          </div>
        </div>
        <div className="activityWrapper__activity__content">
          <Switch>
            <Route
              exact
              path="/bearbeta/aktivitet"
              render={() => (
                <>
                  <div className="activityWrapper__activity__content__item">
                    <ActivityCompletion showSaveToDashboard={true} />
                  </div>
                  <div className="activityWrapper__activity__content__item">
                    <Activities
                      hideCommentInput={true}
                      showSaveToDashboard={true}
                      view="groups"
                    />
                  </div>
                  <div className="activityWrapper__activity__content__item">
                    <ActivityDeals showSaveToDashboard={true} />
                  </div>
                  <div className="activityWrapper__activity__content__item">
                    <Events type="filter" view="flow" />
                  </div>
                  <div className="activityWrapper__activity__content__item">
                    <ActivityPipeline data={state.activity.pipelineByFilter} />
                  </div>
                </>
              )}
            />
            <Route
              exact
              path="/bearbeta/aktivitet/:status"
              render={() => (
                <ActivityInspect
                  data={state.activity.inspectByFilter}
                  selected={inspectSelected}
                  setSelected={setInspectSelected}
                />
              )}
            />
          </Switch>
        </div>
      </div>
    </div>
  ) : (
    <Loading />
  );
};

const MapStateToProps = (state) => {
  return {
    activity: state.activity,
    lists: state.lists,
    user: state.user,
  };
};

export default connect(MapStateToProps)(Activity);
