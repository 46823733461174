import React, { useContext } from 'react';
import LanguageContext from 'language-context';
import { activityHelper, agileHelper, dealHelper } from 'helpers';
import moment from 'moment';
import BilprospektLogoPrint from '../../images/bilprospekt_logo_print.png';
import Icon from 'components/icon';

/**
 * Render a printable view for AgilePreview, either deal or prospect.
 *
 * @param props.activities - array
 * @param props.data - object - Should be same as previewData in AgilePreview.
 * @param props.target - string
 */
const PrintAgilePreview = (props) => {
  const activities = props.activities ? props.activities : [];
  const dealItem = props.data.item.type === 'deal' ? props.data.item : null;
  const cars =
    props.data.item.type === 'deal' &&
    props.data.item.cars &&
    props.data.item.cars.length
      ? props.data.item.cars
      : null;
  const contacts = props.data.contacts ? props.data.contacts : [];
  const prospectItem =
    props.data.item.type === 'prospect' ? props.data.item : null;
  const prospectInformation = props.data.prospectInformation
    ? props.data.prospectInformation
    : [];
  const tc = useContext(LanguageContext);

  const _renderActivities = () => {
    return (
      <div className="printWrapper__print__content__section">
        <div className="printWrapper__print__content__section__header">
          <h5>{tc.activities}</h5>
        </div>
        <div className="printWrapper__print__content__section__content noFlex">
          {activities.map((num) => _renderActivitiesRow(num))}
        </div>
      </div>
    );
  };

  const _renderActivitiesRow = (activity) => {
    // Action
    const action = activityHelper.getActionJSX(props.target, activity);

    // Comment
    const comment = activity.comment ? activity.comment : null;

    // Date
    const date = activity.date_created
      ? moment(new Date(activity.date_created), 'DD/MM/YYYY').format('LL HH:mm')
      : null;

    // Id.
    let id;
    if (activity._id) {
      id = activity._id;
    } else if (activity.id) {
      id = activity.id;
    }

    // Icon
    let icon;
    if (activity.action) {
      icon = <Icon val={activity.action} />;
    } else if (!activity.action && activity.comment) {
      icon = <Icon val="comment" />;
    }

    // Reason
    const reason =
      activity.reason && activity.reason.length
        ? activityHelper.getReadableActivity(activity.reason)
        : null;

    // User
    const user =
      activity.user?.name && activity.user?.name !== ''
        ? activity.user.name
        : tc.unknown;

    return (
      <div
        className="activitiesPrintWrapper__activitiesPrint__content__itemWrapper"
        key={id}
      >
        <div className="activitiesPrintWrapper__activitiesPrint__content__itemWrapper__item">
          <div className="activitiesPrintWrapper__activitiesPrint__content__itemWrapper__item__left">
            <div className="activitiesPrintWrapper__activitiesPrint__content__itemWrapper__item__left__icon">
              {icon}
            </div>
          </div>
          <div className="activitiesPrintWrapper__activitiesPrint__content__itemWrapper__item__center">
            {action ? (
              <span className="highlight marginRight">{action}</span>
            ) : null}
            {date}
            {comment || reason ? (
              <div className="activitiesPrintWrapper__activitiesPrint__content__itemWrapper__item__center__additionalLine">
                {reason ? (
                  <span className="lightAndItalic">{reason}</span>
                ) : null}
                {comment ? (
                  <span className="lightAndItalic">{comment}</span>
                ) : null}
              </div>
            ) : null}
          </div>
          <div className="activitiesPrintWrapper__activitiesPrint__content__bottom__itemWrapper__item__right">
            {user}
          </div>
        </div>
      </div>
    );
  };

  const _renderCars = () => {
    return (
      <div className="printWrapper__print__content__section">
        <div className="printWrapper__print__content__section__header">
          <h5>{tc.vehiclesPartOfDeal}</h5>
        </div>
        <div className="printWrapper__print__content__section__content">
          <div className="printWrapper__print__content__section__content__halfRow">
            <div className="printWrapper__print__content__section__content__halfRow__left">
              {tc.regNumber}:
            </div>
            <div className="printWrapper__print__content__section__content__halfRow__right">
              {cars.map((regNum, i) => {
                if (i === cars.length - 1) {
                  return <span key={regNum}>{regNum}</span>;
                } else {
                  return <span key={regNum}>{regNum}, </span>;
                }
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const _renderContacts = () => {
    return (
      <div className="printWrapper__print__content__section">
        <div className="printWrapper__print__content__section__header">
          <h5>{tc.contacts}</h5>
        </div>
        <div className="printWrapper__print__content__section__content">
          {contacts.map((contact) => {
            return (
              <React.Fragment key={contact.id}>
                {contact.name?.length ? (
                  <div className="printWrapper__print__content__section__content__halfRow">
                    <div className="printWrapper__print__content__section__content__halfRow__left">
                      {tc.phone}:
                    </div>
                    <div className="printWrapper__print__content__section__content__halfRow__right">
                      {contact.name}
                    </div>
                  </div>
                ) : null}
                {contact.tele?.length ? (
                  <div className="printWrapper__print__content__section__content__halfRow">
                    <div className="printWrapper__print__content__section__content__halfRow__left">
                      {tc.phone}:
                    </div>
                    <div className="printWrapper__print__content__section__content__halfRow__right">
                      {contact.tele.map((num, i) => {
                        if (i === contact.tele.length - 1) {
                          return <span key={num + i}>{num}</span>;
                        } else {
                          return <span key={num + i}>{num}, </span>;
                        }
                      })}
                    </div>
                  </div>
                ) : null}
                {contact.email?.length ? (
                  <div className="printWrapper__print__content__section__content__halfRow">
                    <div className="printWrapper__print__content__section__content__halfRow__left">
                      {tc.email}:
                    </div>
                    <div className="printWrapper__print__content__section__content__halfRow__right">
                      {contact.email.map((num, i) => {
                        if (i === contact.email.length - 1) {
                          return <span key={num + i}>{num}</span>;
                        } else {
                          return <span key={num + i}>{num}, </span>;
                        }
                      })}
                    </div>
                  </div>
                ) : null}
                {contact.comment?.length ? (
                  <div className="printWrapper__print__content__section__content__halfRow">
                    <div className="printWrapper__print__content__section__content__halfRow__left">
                      {tc.comment}:
                    </div>
                    <div className="printWrapper__print__content__section__content__halfRow__right">
                      {contact.comment}
                    </div>
                  </div>
                ) : null}
                {contact.updated ? (
                  <div className="printWrapper__print__content__section__content__halfRow">
                    <div className="printWrapper__print__content__section__content__halfRow__left">
                      {tc.updated}:
                    </div>
                    <div className="printWrapper__print__content__section__content__halfRow__right">
                      {moment(new Date(contact.updated), 'DD/MM/YYYY').format(
                        'LL HH:mm'
                      )}
                    </div>
                  </div>
                ) : null}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    );
  };

  const _renderDeal = () => {
    return (
      <div className="printWrapper__print__content__section">
        <div className="printWrapper__print__content__section__header">
          <h5>{tc.deal}</h5>
        </div>
        <div className="printWrapper__print__content__section__content">
          {dealItem.user?.name ? (
            <div className="printWrapper__print__content__section__content__halfRow">
              <div className="printWrapper__print__content__section__content__halfRow__left">
                {tc.owner}:
              </div>
              <div className="printWrapper__print__content__section__content__halfRow__right">
                {dealItem.user.name}
              </div>
            </div>
          ) : null}
          {dealItem.listName && dealItem.listName.length ? (
            <div className="printWrapper__print__content__section__content__halfRow">
              <div className="printWrapper__print__content__section__content__halfRow__left">
                {tc.listName}:
              </div>
              <div className="printWrapper__print__content__section__content__halfRow__right">
                {dealItem.listName}
              </div>
            </div>
          ) : null}
          {dealItem.phase ? (
            <div className="printWrapper__print__content__section__content__halfRow">
              <div className="printWrapper__print__content__section__content__halfRow__left">
                {tc.phase}:
              </div>
              <div className="printWrapper__print__content__section__content__halfRow__right">
                {agileHelper.buildPhaseToColumnTitle(dealItem.phase)}
              </div>
            </div>
          ) : null}
          {dealItem.description && dealItem.description.length ? (
            <div className="printWrapper__print__content__section__content__halfRow">
              <div className="printWrapper__print__content__section__content__halfRow__left">
                {tc.description}:
              </div>
              <div className="printWrapper__print__content__section__content__halfRow__right">
                {dealItem.description}
              </div>
            </div>
          ) : null}
          {dealItem.created ? (
            <div className="printWrapper__print__content__section__content__halfRow">
              <div className="printWrapper__print__content__section__content__halfRow__left">
                {tc.created}:
              </div>
              <div className="printWrapper__print__content__section__content__halfRow__right">
                {moment(new Date(dealItem.created), 'DD/MM/YYYY').format(
                  'LL HH:mm'
                )}
              </div>
            </div>
          ) : null}
          {dealItem.updated ? (
            <div className="printWrapper__print__content__section__content__halfRow">
              <div className="printWrapper__print__content__section__content__halfRow__left">
                {tc.lastUpdate}:
              </div>
              <div className="printWrapper__print__content__section__content__halfRow__right">
                {moment(new Date(dealItem.updated), 'DD/MM/YYYY').format(
                  'LL HH:mm'
                )}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const _renderFleetRows = (fleet) => {
    let typesArray = [];
    for (const prop in fleet) {
      typesArray.push(
        <div
          className="printWrapper__print__content__section__content__fleetHolder__type"
          key={prop}
        >
          <div className="printWrapper__print__content__section__content__fleetHolder__type__header">
            <span className="listBullet">&#8226;</span>
            <p>{tc[prop.toLowerCase()]}</p>
          </div>
          <div className="printWrapper__print__content__section__content__fleetHolder__type__content">
            {Array.isArray(fleet[prop]?.hits)
              ? fleet[prop].hits.map((vehicle, i) => {
                  if (i > 4) {
                    return null;
                  }

                  return (
                    <div
                      className="printWrapper__print__content__section__content__fleetHolder__type__content__vehicle"
                      key={vehicle.chassi}
                    >
                      <div className="printWrapper__print__content__section__content__fleetHolder__type__content__vehicle__left">
                        <span className="highlight marginRight">
                          {vehicle.reg_number}
                        </span>
                        <span className="capitalize marginRight">{`${vehicle.brand.toLowerCase()} (${vehicle.model.toLowerCase()})`}</span>
                        {moment(
                          new Date(vehicle.registration_date),
                          'DD/MM/YYYY'
                        ).fromNow()}
                      </div>
                    </div>
                  );
                })
              : null}
            {fleet[prop].total > 5 ? (
              <div className="printWrapper__print__content__section__content__fleetHolder__type__content__italic">
                {`...${fleet[prop].total - 5} ${tc.moreVehicles.toLowerCase()}`}
              </div>
            ) : null}
          </div>
        </div>
      );
    }

    return (
      <div className="printWrapper__print__content__section__content__fleetHolder">
        {typesArray}
      </div>
    );
  };

  const _renderProspectInformation = () => {
    return prospectInformation.map((num) => {
      return (
        <div
          className="printWrapper__print__content__section"
          key={num.user_id}
        >
          <div className="printWrapper__print__content__section__header">
            <h5>{tc.prospectsPartOfDeal}</h5>
          </div>
          <div className="printWrapper__print__content__section__content">
            {num.name ? (
              <div className="printWrapper__print__content__section__content__halfRow">
                <div className="printWrapper__print__content__section__content__halfRow__left">
                  {tc.name}:
                </div>
                <div className="printWrapper__print__content__section__content__halfRow__right">
                  {num.name}
                </div>
              </div>
            ) : null}
            {prospectItem && prospectItem.listName ? (
              <div className="printWrapper__print__content__section__content__halfRow">
                <div className="printWrapper__print__content__section__content__halfRow__left">
                  {tc.listName}:
                </div>
                <div className="printWrapper__print__content__section__content__halfRow__right">
                  {prospectItem.listName}
                </div>
              </div>
            ) : null}
            {num.address && num.zip && num.zipMuncipality ? (
              <div className="printWrapper__print__content__section__content__halfRow">
                <div className="printWrapper__print__content__section__content__halfRow__left">
                  {tc.address}:
                </div>
                <div className="printWrapper__print__content__section__content__halfRow__right capitalize">
                  {`${num.address.toLowerCase()}, ${
                    num.zip
                  }, ${num.zipMuncipality.toLowerCase()}`}
                </div>
              </div>
            ) : null}
            {num.phoneNumbers && num.phoneNumbers.length ? (
              <div className="printWrapper__print__content__section__content__halfRow">
                <div className="printWrapper__print__content__section__content__halfRow__left">
                  {tc.phoneNumber}:
                </div>
                <div className="printWrapper__print__content__section__content__halfRow__right">
                  {num.phoneNumbers.map((val, i) => {
                    if (i === num.phoneNumbers.length - 1) {
                      return <span key={i}>{val.value}</span>;
                    } else {
                      return <span key={i}>{val.value}, </span>;
                    }
                  })}
                </div>
              </div>
            ) : null}
            {num.emails && num.emails.length ? (
              <div className="printWrapper__print__content__section__content__halfRow">
                <div className="printWrapper__print__content__section__content__halfRow__left">
                  {tc.email}:
                </div>
                <div className="printWrapper__print__content__section__content__halfRow__right">
                  {num.emails.map((val, i) => {
                    if (i === num.emails.length - 1) {
                      return <span key={i}>{val.value}</span>;
                    } else {
                      return <span key={i}>{val.value}, </span>;
                    }
                  })}
                </div>
              </div>
            ) : null}
            {num.fleet && Object.keys(num.fleet).length
              ? _renderFleetRows(num.fleet)
              : null}
          </div>
        </div>
      );
    });
  };

  return (
    <div className="printWrapper">
      <div className="printWrapper__print">
        <div className="printWrapper__print__header">
          {props.data.item.name}
          <img src={BilprospektLogoPrint} alt={tc.imgTextLogo} />
        </div>
        <div className="printWrapper__print__content">
          {dealItem ? _renderDeal() : null}
          {contacts?.length ? _renderContacts() : null}
          {prospectInformation?.length ? _renderProspectInformation() : null}
          {cars?.length ? _renderCars() : null}
          {activities?.length ? _renderActivities() : null}
        </div>
        <div className="printWrapper__print__footer">
          <div className="printWrapper__print__footer__1">
            {tc.printFooter1}
          </div>
          <div className="printWrapper__print__footer__2">
            {tc.printFooter2}
          </div>
          <div className="printWrapper__print__footer__3">
            {tc.printFooter3}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrintAgilePreview;
