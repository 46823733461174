import React, { useContext, useEffect, useRef, useState } from 'react';
import LanguageContext from 'language-context';
import { setCommentEditId, setCommentRemoveId } from 'store/comment/tasks';
import { removeActivity, updateActivity } from 'store/activities/tasks';
import moment from 'moment';
import { activityHelper } from 'helpers';
import { Dropdown, DropdownItem, NewDropdown } from 'components/dropdown';
import ActionButton from 'components/action_button';
import Popup from 'components/popup';
import UserImage from 'components/user_image';
import WidgetFooter from 'components/widget_footer';
import WidgetHeader from 'components/widget_header';
import ActivitiesFlowItemMailingsAction from './activities_flow_item_mailings_action';
import PopupNew from 'components/popup/popup_new';

/**
 * Render an activity row for ActivitiesFlow component.
 */
const ActivitiesFlowItem = (props) => {
  const [newActivityType, setNewActivityType] = useState(props.type);
  const [newActivityComment, setNewActivityComment] = useState(
    props.comment ? props.comment : ''
  );
  const [responsiveClassWidth, setResponsiveClassWidth] = useState('');
  const [showEdit, setShowEdit] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const activitiesFlowItemRef = useRef(null);
  const observer = useRef(null);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    // Adjust styling based on parent element size.
    _setResponsiveClass();
    observer.current = new ResizeObserver(_setResponsiveClass);
    observer.current.observe(activitiesFlowItemRef.current);

    const ref = activitiesFlowItemRef.current;

    return () => observer.current.unobserve(ref);
  }, []);

  useEffect(() => {
    setNewActivityType(props.type);
    setNewActivityComment(props.comment);
  }, [props.type, props.comment]);

  const _renderEditIcons = () => {
    return (
      <div className="activitiesFlowItemWrapper__activitiesFlowItem__right__right__edit">
        <ActionButton
          label={tc.edit}
          onClick={() => {
            if (props.scheduledActivity) {
              // Scheduled activity such as meeting, phone calls etc.
              setShowEdit(true);
            } else {
              // Comment.
              setCommentEditId(props.activityId);
            }
          }}
        />
        <ActionButton
          label={tc.remove}
          onClick={() => {
            if (props.scheduledActivity) {
              // Scheduled activity such as meeting, phone calls etc.
              setShowRemove(true);
            } else {
              // Comment.
              setCommentRemoveId(props.activityId);
            }
          }}
        />
      </div>
    );
  };

  const _setResponsiveClass = () => {
    if (!activitiesFlowItemRef?.current) {
      return;
    }

    const width = activitiesFlowItemRef.current.getBoundingClientRect().width;
    const breakpoint1 = 344; // Might be a good idea to keep this consistent with EventsFlowItem.

    if (width <= breakpoint1) {
      setResponsiveClassWidth('activitiesFlowItemResponsiveWidth1');
    } else if (width > breakpoint1) {
      setResponsiveClassWidth('');
    }
  };

  return (
    <div
      className={`activitiesFlowItemWrapper cancelDrag${
        props.isEditable && props.embedded ? 'embedded' : ''
      } ${responsiveClassWidth}`}
      ref={activitiesFlowItemRef}
    >
      <div
        className={`activitiesFlowItemWrapper__activitiesFlowItem ${responsiveClassWidth}`}
      >
        <div className="activitiesFlowItemWrapper__activitiesFlowItem__left">
          <div className="activitiesFlowItemWrapper__activitiesFlowItem__left__icon ">
            <UserImage
              img={props.user?.img}
              name={props.user?.name ? props.user.name : tc.unknown}
            />
          </div>
        </div>
        <div className="activitiesFlowItemWrapper__activitiesFlowItem__right">
          <div className="activitiesFlowItemWrapper__activitiesFlowItem__right__left">
            <div className="activitiesFlowItemWrapper__activitiesFlowItem__right__left__action">
              {typeof props.action === 'string' ? (
                props.action === 'mailings' ? (
                  <ActivitiesFlowItemMailingsAction {...props} />
                ) : (
                  activityHelper.getActionJSX(props.target, props.activity)
                )
              ) : (
                props.action
              )}
              {props.event_date ? (
                <p className="activitiesFlowItemWrapper__activitiesFlowItem__right__left__action__eventDate">
                  den{' '}
                  {moment(new Date(props.event_date), 'DD/MM/YYYY').format(
                    'LL HH:mm'
                  )}
                </p>
              ) : null}
            </div>
            {props.comment?.length || props.reason ? (
              <div className="activitiesFlowItemWrapper__activitiesFlowItem__right__left__text">
                {props.reason ? (
                  <div className="activitiesFlowItemWrapper__activitiesFlowItem__right__left__text__reason">
                    <span className="marginRight">{tc.reason}:</span>
                    <span className="comment">{props.reason}</span>
                  </div>
                ) : null}
                {props.comment ? (
                  <div className="activitiesFlowItemWrapper__activitiesFlowItem__right__left__text__comment">
                    <span className="marginRight">{tc.comment}:</span>
                    <span className="comment">{props.comment}</span>
                  </div>
                ) : null}
              </div>
            ) : null}
            <div className="activitiesFlowItemWrapper__activitiesFlowItem__right__left__footer">
              {props.date ? (
                <div className="activitiesFlowItemWrapper__activitiesFlowItem__right__left__footer__date">
                  <span>
                    {moment(new Date(props.date), 'DD/MM/YYYY').format(
                      'LL HH:mm'
                    )}
                    ,
                  </span>
                </div>
              ) : null}
              {props.user?.name ? (
                <div className="activitiesFlowItemWrapper__activitiesFlowItem__right__left__footer__user">
                  {props.user.name}
                </div>
              ) : null}
            </div>
          </div>
          {props.isEditable && !props.embedded ? (
            <div className="activitiesFlowItemWrapper__activitiesFlowItem__right__right">
              {_renderEditIcons()}
            </div>
          ) : null}
        </div>
        {showRemove ? (
          <PopupNew
            close={() => {
              setShowRemove(false);
            }}
            mini={true}
          >
            <div className="activitiesFlowItemWrapper__activitiesFlowItem__popup">
              <div className="activitiesFlowItemWrapper__activitiesFlowItem__popup__header">
                <WidgetHeader headline={tc.removeActivity} />
              </div>
              <div className="activitiesFlowItemWrapper__activitiesFlowItem__popup__content">
                {tc.removeActivityInfo} {tc.ensure}
              </div>
              <div className="activitiesFlowItemWrapper__activitiesFlowItem__popup__footer">
                <WidgetFooter
                  className="cancelDrag"
                  buttonOneFunc={() => {
                    removeActivity({
                      id: props.activityId,
                    });
                    setShowRemove(false);
                  }}
                  buttonOneText={tc.remove}
                  buttonTwoFunc={() => {
                    setShowRemove(false);
                  }}
                  buttonTwoText={tc.cancel}
                />
              </div>
            </div>
          </PopupNew>
        ) : null}
        {showEdit ? (
          <PopupNew
            close={() => {
              setShowEdit(false);
            }}
            mini={true}
          >
            <div className="activitiesFlowItemWrapper__activitiesFlowItem__popup">
              <div className="activitiesFlowItemWrapper__activitiesFlowItem__popup__header">
                <WidgetHeader headline={tc.editActivity} />
              </div>
              <div className="activitiesFlowItemWrapper__activitiesFlowItem__popup__content">
                <div className="activitiesFlowItemWrapper__activitiesFlowItem__popup__content__row">
                  <span>{tc.actionType}:</span>
                  <NewDropdown
                    header={{ label: tc[newActivityType] }}
                    displayValue={tc[newActivityType]}
                    transparent={true}
                    styling={{ adjustToHeader: true }}
                  >
                    {activityHelper
                      .getActionTypes(true)
                      .map((num) => {
                        if (num === 'skip') return null;
                        return (
                          <DropdownItem
                            active={num === newActivityType}
                            key={num}
                            label={tc[num]}
                            onClick={() => {
                              setNewActivityType(num);
                            }}
                          />
                        );
                      })
                      .filter(Boolean)}
                  </NewDropdown>
                </div>
                <div className="activitiesFlowItemWrapper__activitiesFlowItem__popup__content__row">
                  <span>{tc.comment}:</span>
                  <input
                    onChange={(e) => {
                      setNewActivityComment(e.target.value);
                    }}
                    placeholder={tc.addComment}
                    value={newActivityComment ? newActivityComment : ''}
                  />
                </div>
              </div>
              <div className="activitiesFlowItemWrapper__activitiesFlowItem__popup__footer">
                <WidgetFooter
                  buttonOneFunc={() => {
                    updateActivity({
                      id: props.activityId,
                      action: newActivityType,
                      comment: newActivityComment?.length
                        ? newActivityComment
                        : null,
                    });
                    setShowEdit(false);
                  }}
                  buttonOneText={tc.save}
                  buttonTwoFunc={() => {
                    setShowEdit(false);
                  }}
                  buttonTwoText={tc.cancel}
                  noBorder={true}
                />
              </div>
            </div>
          </PopupNew>
        ) : null}
      </div>
      {props.isEditable && props.embedded ? (
        <div className="activitiesFlowItemWrapper__editBox">
          <ActionButton
            label={tc.edit}
            onClick={() => {
              if (props.scheduledActivity) {
                // Scheduled activity such as meeting, phone calls etc.
                setShowEdit(true);
              } else {
                // Comment.
                setCommentEditId(props.activityId);
              }
            }}
            type="regular"
          />
          <ActionButton
            label={tc.remove}
            onClick={() => {
              if (props.scheduledActivity) {
                // Scheduled activity such as meeting, phone calls etc.
                setShowRemove(true);
              } else {
                // Comment.
                setCommentRemoveId(props.activityId);
              }
            }}
            type="highlight"
          />
        </div>
      ) : null}
    </div>
  );
};

export default ActivitiesFlowItem;
