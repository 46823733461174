import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getWidgets, updateWidgets } from "store/widgets/tasks";
import LanguageContext from "language-context";
import settingsHelper from "shared_helpers/settings_helper";
import Info from "components/info";
import Loading from "components/loading";
import PageHeader from "components/page_header";
import WidgetKanbanBoard from "components/widget_kanban_board";

const AnalyseDashboard = (state) => {
  const [hasDashboardStatus, setHasDashboardStatus] = useState(false);
  const [widgets, setWidgets] = useState(null);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    getWidgets();
  }, []);

  useEffect(() => {
    // Temp check. Remove check on user level and keep check on dealerStatus.
    setHasDashboardStatus(
      !!(
        settingsHelper.dealerStatus.analyse & state.user?.info?.dealerStatus
      ) && !!(1 & state.user?.info.status)
    );
  }, [state.user.info]);

  useEffect(() => {
    if (Array.isArray(state?.widgets?.data?.widgets)) {
      const data = JSON.parse(JSON.stringify(state.widgets.data.widgets))
        .filter(
          (num) =>
            num.dashboards.hasOwnProperty("analyse") &&
            num.dashboards.analyse.active
        )
        .sort((a, b) =>
          a.dashboards.analyse.position > b.dashboards.analyse.position ? 1 : -1
        );

      setWidgets(data);
    }
  }, [state.widgets.data]);

  /**
   * Widgets should already be updated in param arr, so just send to db.
   */
  const _handleDragEnd = async (arr) => {
    await updateWidgets({ widgets: arr });
  };

  const _stateCheck = () => {
    return Array.isArray(widgets);
  };

  return _stateCheck() ? (
    <div className="analyseDashboardWrapper">
      <div className="analyseDashboardWrapper__analyseDashboard">
        <div className="analyseDashboardWrapper__analyseDashboard__header">
          <div className="analyseDashboardWrapper__analyseDashboard__header__top">
            <PageHeader />
          </div>
        </div>
        <div className="analyseDashboardWrapper__analyseDashboard__content">
          {hasDashboardStatus ? (
            <WidgetKanbanBoard
              dashboard="analyse"
              handleDragEnd={_handleDragEnd}
              widgets={widgets}
            />
          ) : (
            <div className="analyseDashboardWrapper__analyseDashboard__content__info">
              <Info>
                <h4>{tc.noAnalyseDashboardAccess}</h4>
                <p>{tc.noAnalyseDashboardAccessWhy}</p>
              </Info>
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <Loading />
  );
};

const MapStateToProps = (state) => {
  return {
    news: state.news,
    user: state.user,
    widgets: state.widgets,
  };
};

export default connect(MapStateToProps)(AnalyseDashboard);
