import React, { useContext, useEffect, useRef, useState } from "react";
import { getActivityByQuery } from "store/activities/tasks";
import ActivitiesFlowItem from "components/activities/activities_flow_item";
import Loading from "components/loading";
import Icon from "components/icon";
import LanguageContext from "language-context";

/**
 *
 * @param {{type: string, queryData: {}, activityTypesToDisplay: string[]}}} props
 * @example // for example of queryData structure, see ActivityTypesTable
 * @description renders a list of activities, currently up to 50 at a time. These activities are fetched by simply
 * passing the data along to the backend, which involves pagination and all sorts of filtering depending on the saved query data.
 */
const ActivitiesFlowNew = ({ type, queryData, activityTypesToDisplay }) => {
  const [loading, setLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [data, setData] = useState({ total: 0, items: [] });
  const AMOUNT_TO_FETCH = 50;
  const fetchIndex = useRef(0);

  const containerRef = useRef(null);

  const tc = useContext(LanguageContext);

  useEffect(() => {
    setInitialized(false);
    fetchIndex.current = 0;
  }, [queryData]);

  useEffect(() => {
    if (initialized || loading) return;
    if (!activityTypesToDisplay) return;

    fetchIndex.current = 0;
    setLoading(true);
    setInitialized(true);

    async function loadInitialActivities() {
      const data = await getActivityByQuery({
        amount: AMOUNT_TO_FETCH,
        numberToSkip: fetchIndex.current,
        action: type,
        activityTypesToDisplay:
          Array.isArray(activityTypesToDisplay) &&
          activityTypesToDisplay.length > 0
            ? activityTypesToDisplay
            : null,
        ...queryData,
      });
      fetchIndex.current += AMOUNT_TO_FETCH;
      setLoading(false);
      setData(data);
    }

    loadInitialActivities();
  }, [type, queryData, loading, initialized, activityTypesToDisplay]);

  return loading ? (
    <Loading />
  ) : data && Array.isArray(data.items) && data.items.length > 0 ? (
    <div
      ref={containerRef}
      className="activitiesFlowWrapper__activitiesFlow__content__middle__items cancelDrag"
    >
      <div>
        {data.items
          .filter((activity) =>
            activityTypesToDisplay.includes(activity.action)
          )
          .map((activity) => {
            const activityId = activity._id ? activity._id : activity.id;
            return (
              <ActivitiesFlowItem
                key={
                  activityId?.toString() +
                  activity.action +
                  activity.date_created
                }
                {...activity}
                date={activity.date_created}
                activity={activity}
                type={type}
                activityId={activityId}
                target={activityId}
              />
            );
          })}
      </div>

      <div className="buttonContainer">
        <button
          className={`backIcon ${
            fetchIndex.current <= AMOUNT_TO_FETCH ? "disabled" : ""
          }`}
          disabled={fetchIndex.current <= AMOUNT_TO_FETCH}
          onClick={async () => {
            setLoading(true);
            const data = await getActivityByQuery({
              amount: AMOUNT_TO_FETCH,
              numberToSkip:
                fetchIndex.current > 0
                  ? fetchIndex.current - 2 * AMOUNT_TO_FETCH
                  : 0,
              action: type,
              ...queryData,
            });
            if (fetchIndex.current > 0) {
              fetchIndex.current -= AMOUNT_TO_FETCH;
            } else {
              fetchIndex.current = 0;
            }
            setLoading(false);
            setData(data);
          }}
        >
          <Icon val="arrowBack" />
        </button>
        <span>
          {Math.floor((fetchIndex.current + 1) / AMOUNT_TO_FETCH) +
            ` ${tc.of.toLowerCase()} ` +
            (Math.floor(data.total / AMOUNT_TO_FETCH) + 1)}
        </span>
        <button
          className={`forwardIcon ${
            data.total === undefined || data.total <= fetchIndex.current
              ? "disabled"
              : ""
          }`}
          disabled={
            data.total === undefined || data.total <= fetchIndex.current
          }
          onClick={async () => {
            setLoading(true);
            const data = await getActivityByQuery({
              amount: AMOUNT_TO_FETCH,
              numberToSkip: fetchIndex.current,
              action: type,
              ...queryData,
            });
            fetchIndex.current = fetchIndex.current + AMOUNT_TO_FETCH;
            setLoading(false);
            setData(data);
          }}
        >
          <Icon val="arrowForward" />
        </button>
      </div>
    </div>
  ) : null;
};

export default ActivitiesFlowNew;
