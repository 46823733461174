import React, { useContext, useState } from 'react';
import LanguageContext from 'language-context';
import { createListOrder } from 'store/orders/tasks';
import costHelper from 'shared_helpers/cost_helper';
import Info from 'components/info';
import Loading from 'components/loading';
import WidgetFooter from 'components/widget_footer';
import WidgetHeader from 'components/widget_header';
import { miscHelper } from 'helpers';

const OrdersPhone = (props) => {
  const { list } = props;
  const haveProspects =
    list.prospectInformation &&
    list.prospectInformation.privatePersons &&
    list.prospectInformation.privatePersons > 0;
  // const nameIsPending = list?.orderHistory?.name?.isPending;
  const nameIsDelivered = list?.orderHistory?.name?.isDelivered;
  const nameIsOld = nameIsDelivered && !list.orderHistory.name.isAvailable;
  // const phoneIsPending = list?.orderHistory?.phone?.isPending;
  const orderIsPending = miscHelper.hasPendingOrder(list);
  const tc = useContext(LanguageContext);
  const [isOrdering, setIsOrdering] = useState(false);

  return list && list.prospectInformation ? (
    <div className="ordersInformationWrapper">
      <div className="ordersInformationWrapper__ordersInformation">
        <div className="ordersInformationWrapper__ordersInformation__content">
          {nameIsDelivered && haveProspects && !orderIsPending && !nameIsOld ? (
            <div className="ordersInformationWrapper__ordersInformation__content__section">
              <div className="ordersInformationWrapper__ordersInformation__content__section__title">
                <WidgetHeader headline={tc.orderPhone} />
              </div>
              <div className="ordersInformationWrapper__ordersInformation__content__section__block">
                <span className="label">{tc.info}</span>
                <p>{tc.orderMarketingInfo}</p>
              </div>
              <div className="ordersInformationWrapper__ordersInformation__content__section__block">
                <span className="label">{tc.deliveryTime}</span>
                <p>{tc.deliveryTime1}</p>
                <p>{tc.deliveryTime2}</p>
                <p>{tc.deliveryTime3}</p>
                <p>{tc.deliveryTime4}</p>
              </div>
              <div className="ordersInformationWrapper__ordersInformation__content__section__block">
                <span className="label">{tc.prices}</span>
                <p>
                  <span className="lineThrough">
                    {tc.priceStart}:{' '}
                    {costHelper.getStartCostPhone(
                      list.prospectInformation.privatePersons,
                      true
                    )}{' '}
                    {tc.swedishCrowns.toLowerCase()}
                  </span>
                </p>
                <p>
                  {tc.pricePhone}:{' '}
                  {costHelper.getPhoneCost(
                    list.prospectInformation.privatePersons,
                    true
                  )}{' '}
                  {tc.swedishCrowns.toLowerCase()}
                </p>
                <p className="priceSum">
                  {tc.priceSum}:{' '}
                  <strong className="highlight">
                    {
                      // costHelper.getStartCostPhone(list.prospectInformation.privatePersons, true) +
                      costHelper.getPhoneCost(
                        list.prospectInformation.privatePersons,
                        true
                      )
                    }{' '}
                    {tc.swedishCrowns.toLowerCase()}
                  </strong>
                </p>
              </div>
            </div>
          ) : (
            <>
              {orderIsPending ? (
                <>
                  <Info>
                    <h4>{tc.orderIsPending}</h4>
                    <p>{tc.cannotOrderIsPending}</p>
                  </Info>
                </>
              ) : null}
              {!haveProspects && !orderIsPending ? (
                <>
                  <Info>
                    <h4>{tc.noPrivatePersons}</h4>
                    <p>{tc.noOrderNeededCompanyDataIsAvailable}</p>
                  </Info>
                </>
              ) : null}
              {nameIsOld && !orderIsPending ? (
                <>
                  <Info>
                    <h4>{tc.orderDataIsOld}</h4>
                    <p>{tc.cannotOrderNameIsOld}</p>
                  </Info>
                </>
              ) : null}
              {haveProspects && !nameIsDelivered && !orderIsPending ? (
                <>
                  <Info>
                    <h4>{tc.missingName}</h4>
                    <p>{tc.cannotOrderDataMissingName}</p>
                  </Info>
                </>
              ) : null}
            </>
          )}
        </div>
        {haveProspects && nameIsDelivered && !orderIsPending && !nameIsOld ? (
          <div className="ordersInformationWrapper__ordersInformation__footer">
            <div className="minHeight40">
              {isOrdering ? (
                <>
                  <p>{tc.creatingOrder}...</p>
                  {isOrdering ? <Loading small={true} /> : null}
                </>
              ) : null}
            </div>
            <WidgetFooter
              buttonOneFunc={async () => {
                setIsOrdering(true);
                await createListOrder({
                  orderPhone: true,
                });
              }}
              buttonOneText={tc.placeOrder}
              disableButtonOne={isOrdering || orderIsPending}
              noBorder={true}
            />
          </div>
        ) : null}
      </div>
    </div>
  ) : (
    <Loading />
  );
};

export default OrdersPhone;
