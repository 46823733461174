import MenuDropdown from 'components/menu/menu_dropdown';
import { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { getListsSubscriptions } from 'store/lists/tasks';
import LanguageContext from 'language-context';
import { setActivityFilter } from 'store/activity/tasks';
import { updateAgileFilter } from 'store/agile/tasks';

const SubscriptionFilterDropdown = ({
  subscriptions,
  subscriptionFilters,
  agileSubscriptionFilters,
  agile = false,
  activity = false,
}) => {
  const tc = useContext(LanguageContext);
  useEffect(() => {
    if (!subscriptions) getListsSubscriptions();
  }, [subscriptions]);

  const filters = agile
    ? agileSubscriptionFilters
    : activity
    ? subscriptionFilters
    : [];
  return (
    <MenuDropdown
      label={tc.listsSubscriptions}
      checkboxes={true}
      disabled={!subscriptions?.length}
      items={
        subscriptions
          ? subscriptions.map((sub) => {
              return {
                disabled: false,
                active: filters.includes(sub.id),
                label: sub.prefix_name,
                onClick: () => {
                  if (activity) {
                    setActivityFilter({ type: 'subscription', val: sub.id });
                  } else if (agile) {
                    updateAgileFilter({
                      id: sub.id,
                      type: 'subscription',
                      name: sub.prefix_name,
                    });
                  }
                },
              };
            })
          : []
      }
    />
  );
};

const mapStateToProps = (state, props) => {
  return {
    subscriptions: state.lists.listsSubscriptions,
    subscriptionFilters: state.activity.filter.subscriptions,
    agileSubscriptionFilters: props.subscriptionFilters,
  };
};
export default connect(mapStateToProps)(SubscriptionFilterDropdown);
