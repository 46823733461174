import React, { useEffect, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getRestoreLinkData, savePasswordFromReset } from 'store/user/tasks';
import history from 'router-history';
import LanguageContext from 'language-context';
import ActionButton from 'components/action_button';
import Icon from 'components/icon';
import Loading from 'components/loading';

const ResetPassword = () => {
  const { link } = useParams();
  const [hint, setHint] = useState(null);
  const [newPassword1, setNewPassword1] = useState(null);
  const [newPassword2, setNewPassword2] = useState(null);
  const [linkIsValid, setLinkIsValid] = useState(false);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    const _getUser = async () => {
      try {
        const data = await getRestoreLinkData({
          link: link,
        });

        if (data) {
          setLinkIsValid(true);
        } else {
          // No valid user id for link.
          history.push('/');
        }
      } catch (err) {
        console.error(err);
        history.push('/');
      }
    };

    _getUser();
  }, [link]);

  useEffect(() => {
    const _validatePassword = () => {
      if (newPassword1 !== newPassword2) {
        return setHint(tc.settingsPasswordHintNotMatching);
      } else if (
        (newPassword1 && newPassword1.length < 6) ||
        (newPassword2 && newPassword2.length < 6)
      ) {
        return setHint(tc.settingsPasswordHintTooShort);
      } else {
        return setHint(null);
      }
    };

    _validatePassword();
  }, [newPassword1, newPassword2]);

  const _saveNewPassword = async () => {
    try {
      await savePasswordFromReset({
        link: link,
        password: newPassword1,
      });

      history.push('/');
    } catch (err) {
      history.push('/');
    }
  };

  return (
    <div className="resetPasswordWrapper">
      <div className="resetPasswordWrapper__resetPassword">
        {/*<div className="resetPasswordWrapper__resetPassword__particleWrapper">*/}
        {/*  <div className="particle" />*/}
        {/*  <div className="particle" />*/}
        {/*  <div className="particle" />*/}
        {/*  <div className="particle" />*/}
        {/*  <div className="particle" />*/}
        {/*  <div className="particle" />*/}
        {/*</div>*/}
        {linkIsValid ? (
          <>
            <div className="resetPasswordWrapper__resetPassword__header">
              <h2>{tc.resetPassword}</h2>
            </div>
            <div className="resetPasswordWrapper__resetPassword__content">
              <div className="resetPasswordWrapper__resetPassword__content__info">
                <span className="label">{`${tc.chooseNewPassword}`}</span>
              </div>
              <div className="resetPasswordWrapper__resetPassword__content__inputs">
                <div className="resetPasswordWrapper__resetPassword__content__inputs__row">
                  <input
                    type="password"
                    onChange={(e) => {
                      setNewPassword1(e.target.value);
                    }}
                    placeholder={tc.writePassword}
                  />
                  <div className="resetPasswordWrapper__resetPassword__content__inputs__row__icon">
                    {hint ? null : newPassword1 && newPassword1 ? <Icon val="check" /> : null}
                  </div>
                </div>
                <div className="resetPasswordWrapper__resetPassword__content__inputs__row">
                  <input
                    type="password"
                    onChange={(e) => {
                      setNewPassword2(e.target.value);
                    }}
                    placeholder={tc.repeatPassword}
                  />
                  <div className="resetPasswordWrapper__resetPassword__content__inputs__row__icon">
                    {hint ? null : newPassword1 && newPassword1 ? <Icon val="check" /> : null}
                  </div>
                </div>
                <div className="resetPasswordWrapper__resetPassword__content__hint">{hint}</div>
              </div>
            </div>
            <div className="resetPasswordWrapper__resetPassword__footer">
              <ActionButton
                label={tc.backToHomepage}
                onClick={() => {
                  history.push('/');
                }}
                type="regular"
              />
              <ActionButton
                disabled={hint || (!newPassword1 && !newPassword2)}
                label={tc.savePassword}
                onClick={_saveNewPassword}
                type="highlight"
              />
            </div>
          </>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
