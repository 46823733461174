import React, { useContext, useEffect, useState } from 'react';
import LanguageContext from 'language-context';
import { shareLists } from 'store/lists/tasks';
import ColleaguesRows from 'components/colleagues_rows';
import Popup from 'components/popup';
import WidgetFooter from 'components/widget_footer';
import WidgetHeader from 'components/widget_header';

/**
 * Component that helps user to split a list.
 * If a user manually adjusts the value for rows, we automatically calculate average value for the other rows.
 *
 * @param props.close - func
 * @param props.selectedList - obj
 */
const ShareLists = (props) => {
  const [existingUsers, setExistingUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    if (props.selectedList) {
      setExistingUsers(props.selectedList.users);
    }
  }, [props.selectedList]);

  const _shareLists = async (userIds) => {
    await shareLists({
      listIds: [props.selectedList.id],
      userIds: userIds,
    });
    props.close();
  };

  return props.selectedList ? (
    <Popup close={props.close} size="big">
      <div className="listsGenericWrapper">
        <div className="listsGenericWrapper__listsGeneric">
          <div className="listsGenericWrapper__listsGeneric__header">
            <WidgetHeader
              headline={tc.shareList}
              headlineSub={tc.shareListInfo}
            />
          </div>
          <div className="listsGenericWrapper__listsGeneric__content">
            <div className="listsGenericWrapper__listsGeneric__content__listHolder">
              <div className="listsGenericWrapper__listsGeneric__content__listHolder__left">
                {tc.list}:
              </div>
              <div className="listsGenericWrapper__listsGeneric__content__listHolder__right">
                <p>
                  {props.selectedList.name}
                  <span className="bullet">&#8226;</span>
                </p>
              </div>
            </div>
            <ColleaguesRows
              existingUsers={existingUsers}
              onChange={(arr) => {
                setUsers(arr);
              }}
            />
          </div>
          <div className="listsGenericWrapper__listsGeneric__footer">
            <WidgetFooter
              buttonOneFunc={() => {
                if (users.length) {
                  return _shareLists(users.map((num) => num.id));
                }
              }}
              buttonOneText={tc.shareList}
              buttonTwoFunc={props.close}
              buttonTwoText={tc.cancel}
              disableButtonOne={!users.length}
            />
          </div>
        </div>
      </div>
    </Popup>
  ) : null;
};

export default ShareLists;
