import React, { useContext, useEffect } from "react";
import moment from "moment";
import LanguageContext from "language-context";
import DataVisualisation from "./data_visualisation";

const VehicleView = ({
  filters,
  toDate,
  fromDate,
  selectedAreaPresets,
  selectedBrands,
  selectedMeasures,
  orderBy,
  setDefaultMeasures,
  setDefaultOrderBy,
}) => {
  const tc = useContext(LanguageContext);

  useEffect(() => {
    setDefaultMeasures([]);
    setDefaultOrderBy([]);
  }, []);

  useEffect(() => {
    if (!selectedMeasures.length) {
      setDefaultMeasures(["Car.count"]);
    }

    if (!orderBy.length) {
      setDefaultOrderBy(["Car.count"]);
    }

    if (!selectedMeasures.some((m) => orderBy.includes(m))) {
      setDefaultOrderBy([selectedMeasures[0]]);
    }
  }, [selectedMeasures, orderBy]);

  return (
    <div className="analyseWrapper__analyse__content__cars">
      {orderBy[0] !== undefined && (
        <>
          <DataVisualisation
            query={{
              measures: selectedMeasures,
              timeDimensions: [{ dimension: "Car.date_registered" }],
              order: { [orderBy[0]]: "desc" },
            }}
            filters={[
              {
                member: "Users.postort",
                operator: "equals",
                values: selectedAreaPresets,
              },
            ].concat(filters)}
            subTitle=""
            title={tc.dividedByVehicleRegistrationDate}
            dateRange={[
              moment(fromDate).format("YYYY-MM-DD"),
              moment(toDate).format("YYYY-MM-DD"),
            ]}
            defaultGranularity={2}
            drillType="time"
            type="analyseCar"
          />

          <DataVisualisation
            query={{
              measures: selectedMeasures,
              timeDimensions: [{ dimension: "Car.date_registered" }],
              order: { [orderBy[0]]: "desc" },
              limit: 10,
            }}
            filters={[
              {
                member: "Users.postort",
                operator: "equals",
                values: selectedAreaPresets,
              },
            ].concat(filters)}
            title={tc.dividedByVehicleRegistrationDateAndVehicle}
            subTitle=""
            dateRange={[
              moment(fromDate).format("YYYY-MM-DD"),
              moment(toDate).format("YYYY-MM-DD"),
            ]}
            defaultGranularity={1}
            drillType="dimension"
            drillDownOptions={[
              { type: "bar", dimension: "Car.type" },
              { type: "bar", dimension: "Car.brand" },
              { type: "bar", dimension: "Car.model" },
            ]}
            type="analyseCar"
          />

          <DataVisualisation
            query={{
              measures: selectedMeasures,
              timeDimensions: [{ dimension: "Car.date_registered" }],
              order: { [orderBy[0]]: "desc" },
              limit: 10,
            }}
            filters={[{"member": "Users.gender", "operator": "set"}]
              .concat(filters)}
            title={tc.dividedByUser}
            subTitle=""
            dateRange={[
              moment(fromDate).format("YYYY-MM-DD"),
              moment(toDate).format("YYYY-MM-DD"),
            ]}
            defaultGranularity={1}
            drillType="dimension"
            drillDownOptions={[
              { type: "bar", dimension: "Users.gender" },
              { type: "bar", dimension: "Users.age" },
            ]}
            type="analyseCar"
          />
        </>
      )}
    </div>
  );
};

export default VehicleView;
