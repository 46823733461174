import { useEffect, useMemo, useRef, useState } from "react";
import {
  getUserActivityByQuery,
  getActivityGoals,
} from "store/activities/tasks";
import { connect } from "react-redux";
import Loading from "components/loading";
import ActivityTypesTableInspect from "./activity_types_table_inspect";
import TableHeader from "./table_header";
import TableContent from "./table_content";
import { getWeeks, calculateGoals } from "components/activities/utils";

/**
 *
 * @param {{activityTypesToDisplay: string[], queryData: {}}} props
 * @example // query data structure
 * queryData: {
 * "currentMonth": false,
 * "currentWeek": false,
 * "previousMonth": false,
 * "previousWeek": false,
 * "date": { "from": "2022-11-14T22:59:59.935Z",
 *           "to": "2022-12-20T10:08:12.896Z" },
 * "lists": [1234],
 * "users": [9525, 9257, 8979],
 * "prospectTypes":["Företag"],
 * }
 * @description by default renders a TableHeader, as well as the TableContent.
 * If a user's name is clicked, it will instead render the ActivityTypesTableInspect component.
 *
 * */
const ActivityTypesTableNew = ({
  activityTypesToDisplay,
  queryData,
  connections,
  colleagues,
}) => {
  const [data, setData] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);
  const [goals, setGoals] = useState(null);
  const [inspect, setInspect] = useState({
    show: false,
    user: { name: "", data: {} },
  });

  const loaded = useRef(false);
  const loading = useRef(false);

  const totals = useMemo(() => {
    const getTotal = (key, data) => {
      const result = Object.keys(data)
        .map((userName) => data[userName]?.[key] ?? 0)
        .reduce((acc, val) => acc + val, 0);
      return result === 0 ? 1 : result;
    };

    return activityTypesToDisplay.reduce((acc, type) => {
      return { ...acc, [type]: getTotal(type, data) };
    }, {});
  }, [data, activityTypesToDisplay]);

  useEffect(() => {
    loaded.current = false;
  }, [queryData]);

  useEffect(() => {
    (async () => {
      if (colleagues && connections && queryData && queryData.users) {
        if (loaded.current || loading.current) return;
        loading.current = true;
        const result = await getUserActivityByQuery({ ...queryData });

        const allUsers = connections
          .reduce((acc, item) => [...acc, ...item.users], [])
          .concat(colleagues);
        const mappedUsers = queryData.users
          .map((id) => allUsers.find((user) => user.id === id))
          .filter((u) => Boolean(u))
          .map((user) => ({ userId: user.id, dealerId: user.dealer_id }))
          .filter((user) => user.userId && user.dealerId);

        const goalsData = await getActivityGoals({ users: mappedUsers });
        const weeks = getWeeks(queryData);
        const calculatedGoals = calculateGoals(goalsData, weeks);

        setGoals(calculatedGoals);

        loaded.current = true;
        setData(result);
        loaded.current = true;
        loading.current = false;
        setDataLoaded(true);
      }
    })();
  }, [queryData, colleagues, connections]);

  const hideInspect = () => {
    setInspect({ show: false, user: { name: "", data: {} } });
  };

  return dataLoaded ? (
    inspect.show ? (
      <ActivityTypesTableInspect
        data={inspect.user.data}
        user={inspect.user.name}
        types={activityTypesToDisplay}
        hideInspect={hideInspect}
      />
    ) : (
      <div className="activityTypesTableWrapper cancelDrag">
        <div className="activityTypesTableWrapper__activityTypesTable">
          <div className="activityTypesTableWrapper__activityTypesTable__content">
            <TableHeader types={activityTypesToDisplay} />
            <TableContent
              setInspect={setInspect}
              data={data}
              activityTypesToDisplay={activityTypesToDisplay}
              goals={goals}
            />
          </div>
        </div>
      </div>
    )
  ) : (
    <Loading />
  );
};

const mapStateToProps = (state, props) => {
  return {
    ...props,
    colleagues: state.user.colleagues,
    connections: state.user.connections,
  };
};

export default connect(mapStateToProps)(ActivityTypesTableNew);
