import { Button, Stack } from "@mui/material";
import React from "react";

export const Generic = ({ data, setURL }) => {
  const { header, paragraph, img, url, buttonText } = data;

  function _handleButtonPress() {
    setURL(url);
  }

  return (
    <Stack
      sx={{
        width: "100%",
        height: "100%",
        backgroundColor: "white",
      }}
      alignItems="center"
      justifyContent="center"
      className="genericWrapper"
    >
      <div className="summaryWrapper__message">
        <h2>{header ? header : null}</h2>
        <p>{paragraph ? paragraph : null}</p>
      </div>
      <Stack
        sx={{ height: "100%", gap: "1rem", paddingTop: "1rem" }}
        justifyContent="center"
        alignItems="center"
      >
        <img src={img ? img : null} alt="" />
        {url ? (
          <>
            <Button variant="contained" onClick={_handleButtonPress}>
              {buttonText ? buttonText : "Visa mig"}
            </Button>
          </>
        ) : null}
      </Stack>
    </Stack>
  );
};
