import React, { useEffect, useState } from "react";
import Collapsible from "components/collapsible";
import Icon from "components/icon";

/**
 * Render a section.
 * Can be rendered with or without header.
 *
 * @param props.content - element | Array<element>
 * @param props.id - string - For automatic scroll purposes.
 * @param props.header - obj (optional)
 */
const ProspectSearchSection = (props) => {
  const { content, header } = props;
  // const [minimize, setMinimize] = useState(false);
  const [marginBottom, setMarginBottom] = useState(0);

  useEffect(() => {
    // Calculate and set bottom margin for last section so scroll is working nicely.
    const _setMarginBottom = () => {
      const section = document.querySelector("#" + props.id);
      const allSections = document.querySelectorAll(
        ".prospectSearchSectionWrapper"
      );
      if (
        !section ||
        !allSections ||
        (section &&
          allSections &&
          section !== allSections[allSections.length - 1])
      ) {
        // Only adjust last section.
        return setMarginBottom(0);
      }

      const prospectContentBottom = document.querySelector(
        ".prospectSearchWrapper__prospectSearch__content__bottom"
      );

      if (prospectContentBottom) {
        const viewHeight =
          document.documentElement.clientHeight || window.innerHeight || 0;

        const sectionHeight = section.getBoundingClientRect()
          ? section.getBoundingClientRect().height ||
            section.offsetHeight ||
            section.clientHeight
          : section.offsetHeight || section.clientHeight;

        const prospectContentBottomTop = prospectContentBottom.getBoundingClientRect()
          ? prospectContentBottom.getBoundingClientRect().top ||
            prospectContentBottom.offsetTop
          : prospectContentBottom.offsetTop;

        const marginBottom =
          viewHeight - sectionHeight - prospectContentBottomTop + 44;
        setMarginBottom(marginBottom > 0 ? marginBottom : 0);
      }
    };

    const timer = setTimeout(_setMarginBottom, 500);
    window.addEventListener("resize", _setMarginBottom);
    return () => {
      window.removeEventListener("resize", _setMarginBottom);
      clearTimeout(timer);
    };
  }, []);

  return (
    <div
      className="prospectSearchSectionWrapper"
      id={props.id}
      style={{ marginBottom: `${marginBottom}px` }}
    >
      <div className="prospectSearchSectionWrapper__prospectSearchSection">
        <Collapsible
          content={
            <div className="prospectSearchSectionWrapper__prospectSearchSection__content">
              {content}
            </div>
          }
          header={header.text}
        />
        {/*<>*/}
        {/*  {header ? (*/}
        {/*    <div*/}
        {/*      className="prospectSearchSectionWrapper__prospectSearchSection__header"*/}
        {/*      onClick={() => {*/}
        {/*        setMinimize(!minimize);*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      <div className="prospectSearchSectionWrapper__prospectSearchSection__header__left">*/}
        {/*        <Icon val={header.icon} />*/}
        {/*      </div>*/}
        {/*      <div className="prospectSearchSectionWrapper__prospectSearchSection__header__centerBorder" />*/}
        {/*      <div className="prospectSearchSectionWrapper__prospectSearchSection__header__right">*/}
        {/*        {header.text}*/}
        {/*        {minimize ? <Icon val="maximize" /> : <Icon val="minimize" />}*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  ) : null}*/}
        {/*</>*/}
        {/*<div*/}
        {/*  className={*/}
        {/*    minimize*/}
        {/*      ? "prospectSearchSectionWrapper__prospectSearchSection__content minimize"*/}
        {/*      : "prospectSearchSectionWrapper__prospectSearchSection__content"*/}
        {/*  }*/}
        {/*>*/}
        {/*  {content}*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default ProspectSearchSection;
