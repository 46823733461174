import React, { useState, useContext } from "react";
import WidgetHeader from "components/widget_header";
import WidgetFooter from "components/widget_footer";
import { connect } from "react-redux";
import { createWidget } from "store/widgets/tasks";
import DataVisualisation from "./data_visualisation.js";
import Icon from "components/icon";
import LanguageContext from "language-context";

const RadioSelector = ({ label, options, setters }) => {
  const [selectedValue, setSelectedValue] = useState(options[0]);

  return (
    <div className="analyseOptionsWrapper__content__option">
      <div className="analyseOptionsWrapper__content__option__label">
        <span>{label}:</span>
      </div>
      <div className="analyseOptionsWrapper__content__option__selector">
        <div className="analyseOptionsWrapper__content__option__selector__radio">
          {options.map((opt, i) => (
            <div
              className="analyseOptionsWrapper__content__option__selector__radio__option"
              key={opt.label + i}
            >
              <span>{opt.label}</span>
              <Icon
                val={
                  selectedValue.label === opt.label ? "radioChecked" : "radio"
                }
                onClick={() => {
                  setSelectedValue(opt);
                  setters.setSelectedDateRange(opt.val);
                  setters.setDefaultDate(opt.defaultDate);
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const TextSelector = ({ label, placeholder, setter }) => {
  const [text, setText] = useState("");

  return (
    <div className="analyseOptionsWrapper__content__option">
      <div className="analyseOptionsWrapper__content__option__label">
        <span>{label}:</span>
      </div>
      <div className="analyseOptionsWrapper__content__option__selector">
        <input
          value={text}
          onChange={(e) => {
            setText(e.target.value);
            setter(e.target.value);
          }}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

const PreviewWindow = ({ myChart, label, mapDisclaimer }) => {
  return (
    <div className="analyseOptionsWrapper__content__preview">
      <div className="analyseOptionsWrapper__content__option__label">
        <span>{label}:</span>
        {mapDisclaimer && (<span>{mapDisclaimer}</span>)}
      </div>
      <DataVisualisation {...myChart} />
    </div>
  );
};

const DataVisualisationOptions = ({
  analyse,
  defaults,
  myChart,
  close,
  type,
  widgets,
}) => {
  const [headline, setHeadline] = useState("");
  const [selectedDateRange, setSelectedDateRange] = useState(myChart.dateRange);
  const [defaultDate, setDefaultDate] = useState(true);
  const [currentState, setCurrentState] = useState({});
  const [currentMapData, setCurrentMapData] = useState(null);

  const tc = useContext(LanguageContext);

  const mapDisclaimer = myChart.defaultChartType === 'map' ? tc.changesMadeToMapLocationWillBeReflectedInWidget : null;

  return (
    <div className="analyseOptionsWrapper">
      <div className="analyseOptionsWrapper__header">
        <WidgetHeader headline={tc.saveWidgetToDashboard} />
      </div>
      <div className="analyseOptionsWrapper__content">
        <TextSelector
          label={tc.name}
          setter={setHeadline}
          placeholder={tc.writeNameHere}
        />
        <RadioSelector
          label={tc.selectTimeRange}
          setters={{ setSelectedDateRange, setDefaultDate }}
          options={[
            {
              label: `${myChart.dateRange[0]} - ${myChart.dateRange[1]}`,
              val: myChart.dateRange,
              defaultDate: true,
            },
            { label: tc["thisMonth"], val: "thisMonth", defaultDate: false },
            {
              label: tc["thisQuarter"],
              val: "thisQuarter",
              defaultDate: false,
            },
            { label: tc["thisYear"], val: "thisYear", defaultDate: false },
            {
              label: tc["previousMonth"],
              val: "previousMonth",
              defaultDate: false,
            },
            {
              label: tc["previousQuarter"],
              val: "previousQuarter",
              defaultDate: false,
            },
            {
              label: tc["previousYear"],
              val: "previousYear",
              defaultDate: false,
            },
          ]}
        />
        <PreviewWindow
          myChart={{
            ...myChart,
            dateRange: defaultDate ? selectedDateRange : null,
            dynamicDateRange: !defaultDate ? selectedDateRange : null,
            title: headline,
            noButtons: true,
            getCurrentState: setCurrentState,
            getCurrentMapData: setCurrentMapData,
            preview: true,
          }}
          label={tc.preview}
          mapDisclaimer={mapDisclaimer}
        />
      </div>
      <WidgetFooter
        buttonOneText={tc.save}
        buttonOneFunc={() => {
          createWidget({
            dashboards: {
              analyse: {
                active: true,
                minimized: false,
                position: widgets?.data?.widgets?.length
                  ? widgets.data.widgets.filter((num) =>
                      num.dashboards.hasOwnProperty("analyse")
                    ).length
                  : 0,
              },
            },
            data: {
              ...myChart,
              dateRange: defaultDate ? selectedDateRange : null,
              dynamicDateRange: !defaultDate ? selectedDateRange : null,
              title: headline,
              noButtons: true,
              defaultGranularity: currentState.granularity,
              preview: false,
              fullWidth: true,
              initialMapState: currentMapData,
            },
            name: headline,
            type: type,
          });

          close();
        }}
        buttonOneDisabled={!headline}
        buttonTwoText={tc.cancel}
        buttonTwoFunc={() => close()}
      />
    </div>
  );
};

const MapStateToProps = (state) => {
  return {
    user: state.user,
    prospect: state.prospect,
    analyse: state.analyse,
    widgets: state.widgets,
  };
};

export default connect(MapStateToProps)(DataVisualisationOptions);
