import React, { useContext } from "react";
import LanguageContext from "language-context";
import { connect } from "react-redux";
import WidgetHeader from "components/widget_header";
import LeadsUploadUploader from "./leads_upload_uploader";
import LeadsUploadList from "./leads_upload_list";

const LeadsUpload = ({ leads, props }) => {
  const tc = useContext(LanguageContext);

  return (
    <div className="leadsUploadWrapper">
      <div className="leadsUploadWrapper__leadsUpload">
        <div className="leadsUploadWrapper__leadsUpload__header">
          <WidgetHeader headline={tc.uploadLeads} />
        </div>
        {leads.upload.leadsAreUploaded ? (
          <LeadsUploadList
            reload={props.reload}
            setShowUpload={props.setShowUpload}
          />
        ) : (
          <LeadsUploadUploader
            close={() => {
              props.setShowUpload(false);
            }}
          />
        )}
      </div>
    </div>
  );
};

const MapStateToProps = (state, props) => {
  return {
    leads: state.leads,
    props: props,
  };
};

export default connect(MapStateToProps)(LeadsUpload);
