import Dexie from 'dexie';

export const dbName = 'BPDB';
export const idb = new Dexie(dbName);

// For prospect stores, keep store names the same as prospect data object property names.
idb.version(2).stores({
    brands: 'val', // key
    companyTypes: 'val',
    dealerSalesmen: 'val, text', // key, index
    leasingOwners: 'val, text',
    regions: 'val, text',
    sellers: 'val, text',
});
