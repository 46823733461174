import React from "react";
import { Switch, Route } from "react-router-dom";
import ProspectResult from "./prospect_result";
import ProspectSearch from "./prospect_search";

const Prospect = () => {
  return (
    <Switch>
      <Route exact path="/prospektera" component={ProspectSearch} />
      <Route exact path="/prospektera/resultat" component={ProspectResult} />
    </Switch>
  );
};
export default Prospect;
