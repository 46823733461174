import React, { useContext } from "react";
import LanguageContext from "language-context";
import { connect } from "react-redux";
import WidgetHeader from "components/widget_header";
import AvtalskunderUploadUploader from "components/leads/avtalskunder/avtalskunder_upload_uploader";
import AvtalskunderUploadList from "components/leads/avtalskunder/avtalskunder_upload_list";

const AvtalskunderUpload = ({ leads, props }) => {
  const tc = useContext(LanguageContext);

  return (
    <div className="leadsUploadWrapper">
      <div className="leadsUploadWrapper__avtalskunder">
        <div className="leadsUploadWrapper__avtalskunder__header">
          <WidgetHeader headline={"Ladda upp avtalskunder"} />
        </div>
        <div className="leadsUploadWrapper__avtalskunder__content">
          {leads.upload.leadsAreUploaded ? (
            <AvtalskunderUploadList
              handleSuccess={props.handleSuccess}
              reload={props.reload}
              setShowUpload={props.setShowUpload}
            />
          ) : (
            <AvtalskunderUploadUploader
              close={() => {
                props.setShowUpload(false);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const MapStateToProps = (state, props) => {
  return {
    leads: state.leads,
    props: props,
  };
};

export default connect(MapStateToProps)(AvtalskunderUpload);
