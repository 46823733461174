import React, { useContext, useEffect, useState } from 'react';
import LanguageContext from 'language-context';
import { createListOrder, getListWaystarPrice } from 'store/orders/tasks';
import costHelper from 'shared_helpers/cost_helper';
import Info from 'components/info';
import { connect } from 'react-redux';
import Loading from 'components/loading';
import WidgetFooter from 'components/widget_footer';
import WidgetHeader from 'components/widget_header';
import { miscHelper } from 'helpers';

const OrdersWaystar = (state) => {
  const { props } = state;
  const { list } = props;

  const orderIsPending = miscHelper.hasPendingOrder(list);
  const tc = useContext(LanguageContext);

  const [sumPrice, setSumPrice] = useState(0);
  const [priceIsLoading, setPriceIsLoading] = useState(false);
  const [isOrdering, setIsOrdering] = useState(false);

  useEffect(() => {
    async function updatePriceForMailing() {
      setPriceIsLoading(true);
      const price = await getListWaystarPrice({
        listId: state.props.list?.id,
        price: null,
        type: 'waystar',
      });
      setPriceIsLoading(false);
      setSumPrice(Math.round(price));
    }
    updatePriceForMailing();
  }, [list]);

  return list && list.prospectInformation ? (
    <div className="ordersInformationWrapper">
      <div className="ordersInformationWrapper__ordersInformation">
        <div className="ordersInformationWrapper__ordersInformation__content">
          {!orderIsPending ? (
            <div className="ordersInformationWrapper__ordersInformation__content__section">
              <div className="ordersInformationWrapper__ordersInformation__content__section__title">
                <WidgetHeader headline={tc.orderWaystar} />
              </div>
              <div className="ordersInformationWrapper__ordersInformation__content__section__block">
                <span className="label">{'Information om Waystar'}</span>
                <p className="paddedBottom">{tc.waystarFlagDescription}</p>
                <p>
                  {tc.waystarFlagOnlyRed}
                  {tc.waystarFlagRed}
                </p>
                <p>
                  {tc.waystarFlagOnlyYellow}
                  {tc.waystarFlagYellow}
                </p>
                <p>
                  {tc.waystarFlagOnlyGreen}
                  {tc.waystarFlagGreen}
                </p>
              </div>
              <div className="ordersInformationWrapper__ordersInformation__content__section__block">
                <span className="label">{tc.deliveryTime}</span>
                <p>{tc.deliveryTime1}</p>
                <p>{tc.deliveryTime2}</p>
                <p>{tc.deliveryTime3}</p>
                <p>{tc.deliveryTime4}</p>
              </div>
              <div className="ordersInformationWrapper__ordersInformation__content__section__block">
                <span className="label">{tc.prices}</span>
                <p>
                  <span className="lineThrough">
                    {tc.priceStart}:{' '}
                    {costHelper.getStartCost(
                      list.prospectInformation.companies
                    )}{' '}
                    {tc.swedishCrowns.toLowerCase()}
                  </span>
                </p>
                <p>
                  {priceIsLoading
                    ? tc.calculatingPrice
                    : `${
                        tc.waystarFlagPrice
                      }: ${sumPrice} ${tc.swedishCrowns.toLowerCase()}`}
                </p>
                <p className="priceSum">
                  {priceIsLoading ? (
                    tc.calculatingPrice
                  ) : (
                    <strong className="highlight">{`${
                      tc.priceSum
                    }: ${sumPrice} ${tc.swedishCrowns.toLowerCase()}`}</strong>
                  )}
                </p>
              </div>
            </div>
          ) : (
            <>
              {orderIsPending ? (
                <>
                  <Info>
                    <h4>{tc.orderIsPending}</h4>
                    <p>{tc.cannotOrderIsPending}</p>
                  </Info>
                </>
              ) : null}
            </>
          )}
        </div>
        {!orderIsPending ? (
          <div className="ordersInformationWrapper__ordersInformation__footer">
            <div className="minHeight40">
              {isOrdering ? (
                <>
                  <p>{tc.creatingOrder}...</p>
                  {isOrdering ? <Loading small={true} /> : null}
                </>
              ) : null}
            </div>
            <WidgetFooter
              disableButtonOne={isOrdering}
              buttonOneFunc={async () => {
                setIsOrdering(true);
                await createListOrder({
                  orderWaystar: true,
                });
              }}
              buttonOneText={tc.placeOrder}
              noBorder={true}
            />
          </div>
        ) : null}
      </div>
    </div>
  ) : (
    <Loading />
  );
};

const MapStateToProps = (state, props) => {
  return {
    orders: state.orders,
    pdf: state.pdf,
    props: props,
  };
};

export default connect(MapStateToProps)(OrdersWaystar);
