import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import history from "router-history";
import companyHelper from "shared_helpers/company_helper";
import LanguageContext from "language-context";
import Info from "components/info";
import Icon from "components/icon";
import Tooltip from "components/tooltip";
import WidgetHeader from "components/widget_header";

/**
 * Basically render a small version of sale table data for a vehicle.
 */
const CarUserHistory = (props) => {
  const [minimize, setMinimize] = useState(false);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    if (!props.data || props.data.length === 0) {
      setMinimize(true);
    }
  }, [props.data]);

  const _formatTimeRange = (start, end) => {
    let startStr;
    let endStr;

    if (start && start !== 0) {
      startStr =
        start.toString().substring(0, 4) +
        "-" +
        start.toString().substring(4, 6) +
        "-" +
        start.toString().substring(6, 8);
    }

    if (end && end !== 0) {
      endStr =
        end.toString().substring(0, 4) +
        "-" +
        end.toString().substring(4, 6) +
        "-" +
        end.toString().substring(6, 8);
    }

    let str = startStr ? tc.from + " " + startStr : "";
    str += endStr ? " " + tc.to.toLowerCase() + " " + endStr : "";

    return str;
  };

  const _formatMonths = (start, end) => {
    let startStr;
    let endStr;

    if (start && start !== 0) {
      startStr =
        start.toString().substring(0, 4) +
        "-" +
        start.toString().substring(4, 6) +
        "-" +
        start.toString().substring(6, 8);
    }

    if (end && end !== 0) {
      endStr =
        end.toString().substring(0, 4) +
        "-" +
        end.toString().substring(4, 6) +
        "-" +
        end.toString().substring(6, 8);
    }

    if (start === "30000101" || start >= 30000101 || !startStr) {
      return tc.dataMissing;
    }

    if (endStr) {
      return (
        Math.abs(
          moment(new Date(endStr), "DD/MM/YYYY").diff(
            moment(new Date(startStr), "DD/MM/YYYY"),
            "months"
          )
        ) +
        " " +
        tc.months.toLowerCase()
      );
    } else {
      return (
        Math.abs(
          moment(new Date(), "DD/MM/YYYY").diff(
            moment(new Date(startStr), "DD/MM/YYYY"),
            "months"
          )
        ) +
        " " +
        tc.months.toLowerCase()
      );
    }
  };

  const _renderHistory = () => {
    return (
      <>
        <div className="carUserHistoryWrapper__carUserHistory__content__row">
          <div className="carUserHistoryWrapper__carUserHistory__content__row__leftLabel">
            {tc.vehicleUser}
          </div>
          <div className="carUserHistoryWrapper__carUserHistory__content__row__centerLabel">
            {tc.holdingPeriod}
          </div>
          <div className="carUserHistoryWrapper__carUserHistory__content__row__rightLabel">
            {tc.acquiredAges}
          </div>
        </div>
        {props.data.slice(0, 20).map((obj, i) => {
          return (
            <div
              className="carUserHistoryWrapper__carUserHistory__content__row"
              key={i}
            >
              <div
                className="carUserHistoryWrapper__carUserHistory__content__row__left"
                onClick={() => {
                  if (companyHelper.isValidOrgNr(obj.owner.id)) {
                    history.push("/foretag/" + obj.owner.id);
                  } else {
                    history.push("/person/" + obj.owner.id);
                  }
                }}
              >
                {obj.owner.name}
              </div>
              <div className="carUserHistoryWrapper__carUserHistory__content__row__center">
                {_formatTimeRange(obj.start, obj.end)}
              </div>
              <div className="carUserHistoryWrapper__carUserHistory__content__row__right">
                {_formatMonths(obj.start, obj.end)}
              </div>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div className="carUserHistoryWrapper">
      <div className="carUserHistoryWrapper__carUserHistory">
        <div className="carUserHistoryWrapper__carUserHistory__header">
          <WidgetHeader
            dashboardItems={[
              {
                disabled: !minimize,
                icon: "maximize",
                label: tc.maximize,
                onClick: () => {
                  setMinimize(false);
                },
              },
              {
                disabled: minimize,
                icon: "minimize",
                label: tc.minimize,
                onClick: () => {
                  setMinimize(true);
                },
              },
            ]}
            headline={tc.history}
            headlineSub={tc.userHistory}
          />
        </div>
        <div
          className={
            minimize
              ? "carUserHistoryWrapper__carUserHistory__content minimize"
              : "carUserHistoryWrapper__carUserHistory__content"
          }
        >
          {props.data && props.data.length ? (
            _renderHistory()
          ) : (
            <Info>
              <h4>{tc.noPreviousUsers}</h4>
              <p>{tc.noPreviousUsersInfo}</p>
            </Info>
          )}
        </div>
      </div>
    </div>
  );
};

export default CarUserHistory;
