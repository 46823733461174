/**
 *
 * @param {{value: number | undefined, total: number | undefined}} props
 * @description - renders a single cell to be used in a table overview
 */
const SingleCell = ({ value, total, goal }) => {
  return (
    <div className="activityTypesTableWrapper__activityTypesTable__content__table__rowsHolder__rows__rowHolder__row__cell">
      <div className="activityTypesTableWrapper__activityTypesTable__content__table__rowsHolder__rows__rowHolder__row__cell__value">
        <span>{goal ? `${value ?? 0}/${goal}` : value ?? 0}</span>
      </div>
      <div className="activityTypesTableWrapper__activityTypesTable__content__table__rowsHolder__rows__rowHolder__row__cell__barHolder">
        <div
          className="bar"
          style={{
            width: goal && value ? `${(value / goal) * 100}%`  : '0%'
          }}
        ></div>
      </div>
    </div>
  );
};

export default SingleCell;
