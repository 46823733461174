import { store } from "store";
import * as text from "text-content";

export const vehicleHelper = {
  getCouplingTyped: () => {
    const tc = store?.getState()?.user?.info?.lang
      ? store.getState().user.info.lang === "en"
        ? text.english
        : text.swedish
      : text.swedish;

    return [
      {
        active: false,
        text: tc.shackle,
        type: "value",
        val: 3,
      },
      {
        active: false,
        text: tc.detachableBall,
        type: "value",
        val: 10,
      },
      {
        active: false,
        text: tc.agricultular,
        type: "value",
        val: 9,
      },
      {
        active: false,
        text: tc.hook,
        type: "value",
        val: 2,
      },
      {
        active: false,
        text: tc.ball,
        type: "value",
        val: 1,
      },
      {
        active: false,
        text: tc.hitch,
        type: "value",
        val: 6,
      },
      {
        active: false,
        text: tc.pivot,
        type: "value",
        val: 5,
      },
      {
        active: false,
        text: tc.tap,
        type: "value",
        val: 8,
      },
      {
        active: false,
        text: tc.eyelet,
        type: "value",
        val: 7,
      },
      {
        active: false,
        text: tc.other,
        type: "value",
        val: 4,
      },
    ];
  },
  getLbSegmentTyped: () => {
    return [
      // Historically we've used english text values for these.
      { active: false, text: "Large", type: "value", val: "large" },
      { active: false, text: "Medium", type: "value", val: "medium" },
      { active: false, text: "Pickup", type: "value", val: "pickup" },
      { active: false, text: "Small", type: "value", val: "small" },
    ];
  },
  getSegmentPbTyped: () => {
    const tc = store?.getState()?.user?.info?.lang
      ? store.getState().user.info.lang === "en"
        ? text.english
        : text.swedish
      : text.swedish;

    return [
      { active: false, text: "AA - Sedan", type: "value", val: "AA" },
      { active: false, text: "AB - Halvkombi", type: "value", val: "AB" },
      { active: false, text: "AC - Stationsvagn (kombivagn)", type: "value", val: "AC" },
      { active: false, text: "AD - Kupé", type: "value", val: "AD" },
      { active: false, text: "AE - Cabriolet", type: "value", val: "AE" },
      { active: false, text: "AF Fordon avsett för flera ändamål", type: "value", val: "AF" },
      { active: false, text: "AG - Lastkombi", type: "value", val: "AG" },
    ];
  },
  getPbSegmentTyped: () => {
    const tc = store?.getState()?.user?.info?.lang
      ? store.getState().user.info.lang === "en"
        ? text.english
        : text.swedish
      : text.swedish;

    return [
      { active: false, text: tc.cabriolet, type: "value", val: "cabriolet" },
      { active: false, text: tc.combSUV, type: "value", val: "combSUV" },
      { active: false, text: tc.coupe, type: "value", val: "coupe" },
      { active: false, text: tc.hatchback, type: "value", val: "hatchback" },
      { active: false, text: tc.sedan, type: "value", val: "sedan" },
    ];
  },
  // getSegmentPbTyped: () => {
  //   const tc = store?.getState()?.user?.info?.lang
  //     ? store.getState().user.info.lang === "en"
  //       ? text.english
  //       : text.swedish
  //     : text.swedish;

  //   return [
  //     { active: false, text: tc.carsMini, type: "value", val: "A" },
  //     { active: false, text: tc.carsSmall, type: "value", val: "B" },
  //     { active: false, text: tc.carsMedium, type: "value", val: "C" },
  //     { active: false, text: tc.carsLarge, type: "value", val: "D" },
  //     { active: false, text: tc.carsExecutive, type: "value", val: "E" },
  //     { active: false, text: tc.carsLuxury, type: "value", val: "F" },
  //     { active: false, text: tc.carsSuv, type: "value", val: "J" },
  //     { active: false, text: tc.carsMpv, type: "value", val: "M" },
  //     { active: false, text: tc.carsPickup, type: "value", val: "P" },
  //     { active: false, text: tc.carsSport, type: "value", val: "S" },
  //     { active: false, text: tc.carsSuper, type: "value", val: "X" },
  //   ];
  // },
  getTlbSegmentTyped: () => {
    const tc = store?.getState()?.user?.info?.lang
      ? store.getState().user.info.lang === "en"
        ? text.english
        : text.swedish
      : text.swedish;

    return [
      // Distribution
      {
        active: false,
        children: [
          {
            active: false,
            children: [
              {
                active: false,
                text: tc.garbageTrucks,
                type: "value",
                val: "distributionAvfallshanteringSopbilar",
              },
              {
                active: false,
                text: tc.sewageTrucks,
                type: "value",
                val: "distributionAvfallshanteringSlambilar",
              },
            ],
            text: tc.garbageDisposal,
            type: "value",
            val: "distributionAvfallshantering",
          },
          {
            active: false,
            children: [
              {
                active: false,
                text: tc.vanTransport,
                type: "value",
                val: "distributionSkapbilarTransportskap",
              },
              {
                active: false,
                text: tc.temperatureControlledCargo,
                type: "value",
                val: "distributionSkapbilarTempereratGods",
              },
              {
                active: false,
                text: tc.other,
                type: "value",
                val: "distributionSkapbilarOvrigt",
              },
            ],
            text: tc.van,
            type: "value",
            val: "distributionSkapbilar",
          },
          {
            active: false,
            children: [
              {
                active: false,
                text: tc.vehicleTransport,
                type: "value",
                val: "distributionOvrigtFordonstransport",
              },
              {
                active: false,
                text: tc.animalTransport,
                type: "value",
                val: "distributionOvrigtDjurtransport",
              },
              {
                active: false,
                text: tc.fireVehicles,
                type: "value",
                val: "distributionOvrigtBrandfordon",
              },
              {
                active: false,
                text: tc.other,
                type: "value",
                val: "distributionOvrigtOvrigt",
              },
            ],
            text: tc.other,
            type: "value",
            val: "distributionOvrigt",
          },
          {
            active: false,
            text: tc.container,
            type: "value",
            val: "distributionContainer",
          },
        ],
        text: tc.distribution,
        type: "value",
        val: "distribution",
      },
      // Entrepenad
      {
        active: false,
        children: [
          {
            active: false,
            children: [
              {
                active: false,
                text: tc.liftDumper,
                type: "value",
                val: "entreprenadLastvaxlareLiftdumper",
              },
              {
                active: false,
                text: tc.hookLift,
                type: "value",
                val: "entreprenadLastvaxlareLastvaxlare",
              },
            ],
            text: tc.hookLift,
            type: "value",
            val: "entreprenadLastvaxlare",
          },
          {
            active: false,
            children: [
              {
                active: false,
                text: tc.sideBoards,
                type: "value",
                val: "entreprenadFlakbilarLammar",
              },
              {
                active: false,
                text: tc.dumpTrucks,
                type: "value",
                val: "entreprenadFlakbilarSchaktbilar",
              },
              {
                active: false,
                text: tc.other,
                type: "value",
                val: "entreprenadFlakbilarOvrigt",
              },
            ],
            text: tc.pickup,
            type: "value",
            val: "entreprenadFlakbilar",
          },
          {
            active: false,
            text: tc.timberTruck,
            type: "value",
            val: "entreprenadTimmerbilar",
          },
          {
            active: false,
            children: [
              {
                active: false,
                text: tc.fastLock,
                type: "value",
                val: "entreprenadOvrigtSnabblas",
              },
              {
                active: false,
                text: tc.concreteTrucks,
                type: "value",
                val: "entreprenadOvrigtBetongbilar",
              },
            ],
            text: tc.other,
            type: "value",
            val: "entreprenadOvrigt",
          },
        ],
        text: tc.entreprenad,
        type: "value",
        val: "entreprenad",
      },
      // Fjärr
      {
        active: false,
        children: [
          {
            active: false,
            text: tc.truckTractors,
            type: "value",
            val: "fjarrtrafikDragbilar",
          },
          {
            active: false,
            text: tc.container,
            type: "value",
            val: "fjarrtrafikContainer",
          },
          {
            active: false,
            children: [
              {
                active: false,
                text: tc.vanTransport,
                type: "value",
                val: "fjarrtrafikSkapbilarTransportskap",
              },
              {
                active: false,
                text: tc.temperatureControlledCargo,
                type: "value",
                val: "fjarrtrafikSkapbilarTempereratGods",
              },
              {
                active: false,
                text: tc.other,
                type: "value",
                val: "fjarrtrafikSkapbilarSkapbilarOvrigt",
              },
            ],
            text: tc.van,
            type: "value",
            val: "fjarrtrafikSkapbilar",
          },
          {
            active: false,
            children: [
              {
                active: false,
                text: tc.vehicleTransport,
                type: "value",
                val: "fjarrtrafikOvrigtFordonstransport",
              },
              {
                active: false,
                text: tc.animalTransport,
                type: "value",
                val: "fjarrtrafikOvrigtDjurtransport",
              },
              {
                active: false,
                text: tc.fuelTrucks,
                type: "value",
                val: "fjarrtrafikOvrigtTankbilar",
              },
              {
                active: false,
                text: tc.bulkCars,
                type: "value",
                val: "fjarrtrafikOvrigtBulkbilar",
              },
              {
                active: false,
                text: tc.other,
                type: "value",
                val: "fjarrtrafikOvrigtOvrigt",
              },
            ],
            text: tc.other,
            type: "value",
            val: "fjarrtrafikOvrigt",
          },
        ],
        text: tc.longDistanceTraffic,
        type: "value",
        val: "fjarrtrafik",
      },
      // Fjärrdistans
      {
        active: false,
        text:
          tc.longDistanceTraffic + "/" + tc.distribution + "/" + tc.entreprenad,
        type: "value",
        val: "fjarrtrafikDistributionEntreprenad",
      },
    ];
  },
  getTLBEuroKarossSegmentsTyped: () => {
    return [
      {
        "type": "value",
        "text": "BA - Lastbil",
        "val": "BA",
        "active": false,
        "children": [
          {
            "type": "value",
            "text": "21 - Fordon for transport av båtar",
            "val": "21",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "30 - Fordon för glastransport",
            "val": "30",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "20 - Kompressorfordon",
            "val": "20",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "06 - Med gardinsida",
            "val": "06",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "25 - Maskinstege (stegbil)",
            "val": "25",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "23 - Fordon för detaljhandel eller förevisning",
            "val": "23",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "26 - Kranbil",
            "val": "26",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "27 - Fordon med lyftplattform",
            "val": "27",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "28 - Väghållningsfordon",
            "val": "28",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "16 - Fordon med betongpump",
            "val": "16",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "19 - Fordon för gatusopning, gaturengöring och avloppsrening",
            "val": "19",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "31 - Brandbil",
            "val": "31",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "15 - Betongblandare",
            "val": "15",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "08 - Containerfordon",
            "val": "08",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "24 - Bärgningsfordon",
            "val": "24",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "11 - Tankfordon",
            "val": "11",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "01 - Öppet flak",
            "val": "01",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "12 - Tankfordon avsedda för transport av farligt gods",
            "val": "12",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "14 - Fordon för transport av fordon",
            "val": "14",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "17 - Timmmerfordon",
            "val": "17",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "18 - Fordon för transport av avfall",
            "val": "18",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "05 - Konditionerad kaross med isolerade väggar, utan utrustning för att upprätthålla innetemperatur",
            "val": "05",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "10 - Tippfordon",
            "val": "10",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "07 - Växelflak (utbytbar karosseristomme)",
            "val": "07",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "13 - Fordon för transport av levande djur",
            "val": "13",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "09 - Fordon försedda med lyftkrok",
            "val": "09",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "04 - Konditionerad kaross med isolerade väggar och utrustning för att upprätthålla innetemperatur",
            "val": "04",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "02 - Flaklämmar",
            "val": "02",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "03 - Skåp",
            "val": "03",
            "active": false,
            "children": []
          },
        ]
      },
      {
        "type": "value",
        "text": "BB - Skåpbil",
        "val": "BB",
        "active": false,
        "children": [
          {
            "type": "value",
            "text": "07 - Växelflak (utbytbar karosseristomme)",
            "val": "07",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "11 - Tankfordon",
            "val": "11",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "14 - Fordon för transport av fordon",
            "val": "14",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "01 - Öppet flak",
            "val": "01",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "17 - Timmmerfordon",
            "val": "17",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "10 - Tippfordon",
            "val": "10",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "09 - Fordon försedda med lyftkrok",
            "val": "09",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "19 - Fordon för gatusopning, gaturengöring och avloppsrening",
            "val": "19",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "02 - Flaklämmar",
            "val": "02",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "05 - Konditionerad kaross med isolerade väggar, utan utrustning för att upprätthålla innetemperatur",
            "val": "05",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "23 - Fordon för detaljhandel eller förevisning",
            "val": "23",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "04 - Konditionerad kaross med isolerade väggar och utrustning för att upprätthålla innetemperatur",
            "val": "04",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "27 - Fordon med lyftplattform",
            "val": "27",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "31 - Brandbil",
            "val": "31",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "03 - Skåp",
            "val": "03",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "13 - Fordon för transport av levande djur",
            "val": "13",
            "active": false,
            "children": []
          },
        ]
      },
      {
        "type": "value",
        "text": "BC - Dragfordon för påhängsvagn",
        "val": "BC",
        "active": false,
        "children": [
          {
            "type": "value",
            "text": "11 - Tankfordon",
            "val": "11",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "04 - Konditionerad kaross med isolerade väggar och utrustning för att upprätthålla innetemperatur",
            "val": "04",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "05 - Konditionerad kaross med isolerade väggar, utan utrustning för att upprätthålla innetemperatur",
            "val": "05",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "03 - Skåp",
            "val": "03",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "02 - Flaklämmar",
            "val": "02",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "01 - Öppet flak",
            "val": "01",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "26 - Kranbil",
            "val": "26",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "07 - Växelflak (utbytbar karosseristomme)",
            "val": "07",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "09 - Fordon försedda med lyftkrok",
            "val": "09",
            "active": false,
            "children": []
          },
        ]
      },
      {
        "type": "value",
        "text": "BD - Dragfordon för släpvagn",
        "val": "BD",
        "active": false,
        "children": []
      },
      {
        "type": "value",
        "text": "BE - Pick-up",
        "val": "BE",
        "active": false,
        "children": [
          {
            "type": "value",
            "text": "01 - Öppet flak",
            "val": "01",
            "active": false,
            "children": []
          },
          {
            "type": "value",
            "text": "02 - Flaklämmar",
            "val": "02",
            "active": false,
            "children": []
          },
        ]
      },
    ]
  },

  getSVEuroKarossSegmentsTyped: () => {
    return [
      {
        "type":"value",
        "val":"DA",
        "text":"DA - Påhängsvagn",
        "active":false,
        "children":[
          {
            "type":"value",
            "val":1,
            "text":"1 - Öppet flak.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":2,
            "text":"2 - Flaklämmar.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":3,
            "text":"3 - Skåp.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":4,
            "text":"4 - Konditionerad kaross med isolerade väggar och utrustning för att upprätthålla innetemperatur.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":5,
            "text":"5 - Konditionerad kaross med isolerade väggar, utan utrustning för att upprätthålla innetemperatur.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":6,
            "text":"6 - Med gardinsida.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":7,
            "text":"7 - Växelflak (utbytbar karosseristomme).",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":8,
            "text":"8 - Containerfordon.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":10,
            "text":"10 - Tippfordon.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":11,
            "text":"11 - Tankfordon.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":12,
            "text":"12 - Tankfordon avsedda för transport av farligt gods.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":13,
            "text":"13 - Fordon för transport av levande djur.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":14,
            "text":"14 - Fordon för transport av fordon.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":15,
            "text":"15 - Betongblandare.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":16,
            "text":"16 - Fordon med betongpump.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":17,
            "text":"17 - Timmerfordon.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":"18",
            "text":"18 - Fordon för transport av avfall.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":20,
            "text":"20 - Kompressorfordon.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":21,
            "text":"21 - Fordon för transport av båtar.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":23,
            "text":"23 - Fordon för detaljhandel eller förevisning.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":29,
            "text":"29 - Låggolvssläpvagn.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":99,
            "text":"99 - Karosseri som inte ingår i denna förteckning",
            "active":false,
            "children":[
              
            ]
          }
        ]
      },
      {
        "type":"value",
        "val":"DB",
        "text":"DB - Släpvagn med dragstång",
        "active":false,
        "children":[
          {
            "type":"value",
            "val":1,
            "text":"1 - Öppet flak.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":2,
            "text":"2 - Flaklämmar.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":3,
            "text":"3 - Skåp.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":4,
            "text":"4 - Konditionerad kaross med isolerade väggar och utrustning för att upprätthålla innetemperatur.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":5,
            "text":"5 - Konditionerad kaross med isolerade väggar, utan utrustning för att upprätthålla innetemperatur.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":6,
            "text":"6 - Med gardinsida.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":7,
            "text":"7 - Växelflak (utbytbar karosseristomme).",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":8,
            "text":"8 - Containerfordon.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":9,
            "text":"9 - Fordon försedda med lyftkrok.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":10,
            "text":"10 - Tippfordon.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":11,
            "text":"11 - Tankfordon.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":12,
            "text":"12 - Tankfordon avsedda för transport av farligt gods.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":13,
            "text":"13 - Fordon för transport av levande djur.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":14,
            "text":"14 - Fordon för transport av fordon.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":17,
            "text":"17 - Timmerfordon.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":19,
            "text":"19 - Fordon för gatusopning, gaturengöring och avloppsrening.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":21,
            "text":"21 - Fordon för transport av båtar.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":23,
            "text":"23 - Fordon för detaljhandel eller förevisning.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":29,
            "text":"29 - Låggolvssläpvagn.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":99,
            "text":"99 - Karosseri som inte ingår i denna förteckning",
            "active":false,
            "children":[
              
            ]
          }
        ]
      },
      {
        "type":"value",
        "val":"DC",
        "text":"DC - Släpkärra",
        "active":false,
        "children":[
          {
            "type":"value",
            "val":1,
            "text":"1 - Öppet flak.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":2,
            "text":"2 - Flaklämmar.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":3,
            "text":"3 - Skåp.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":4,
            "text":"4 - Konditionerad kaross med isolerade väggar och utrustning för att upprätthålla innetemperatur.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":5,
            "text":"5 - Konditionerad kaross med isolerade väggar, utan utrustning för att upprätthålla innetemperatur.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":6,
            "text":"6 - Med gardinsida.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":7,
            "text":"7 - Växelflak (utbytbar karosseristomme).",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":8,
            "text":"8 - Containerfordon.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":9,
            "text":"9 - Fordon försedda med lyftkrok.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":10,
            "text":"10 - Tippfordon.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":11,
            "text":"11 - Tankfordon.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":12,
            "text":"12 - Tankfordon avsedda för transport av farligt gods.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":13,
            "text":"13 - Fordon för transport av levande djur.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":14,
            "text":"14 - Fordon för transport av fordon.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":15,
            "text":"15 - Betongblandare.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":16,
            "text":"16 - Fordon med betongpump.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":17,
            "text":"17 - Timmerfordon.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":"18",
            "text":"18 - Fordon för transport av avfall.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":19,
            "text":"19 - Fordon för gatusopning, gaturengöring och avloppsrening.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":20,
            "text":"20 - Kompressorfordon.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":21,
            "text":"21 - Fordon för transport av båtar.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":22,
            "text":"22 - Fordon för transport av glidflygare.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":23,
            "text":"23 - Fordon för detaljhandel eller förevisning.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":25,
            "text":"25 - Maskinstege (stegbil).",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":27,
            "text":"27 - Fordon med lyftplattform.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":28,
            "text":"28 - Väghållningsfordon.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":29,
            "text":"29 - Låggolvssläpvagn.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":30,
            "text":"30 - Fordon för glastransport.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":31,
            "text":"31 - Brandbil.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":99,
            "text":"99 - Karosseri som inte ingår i denna förteckning",
            "active":false,
            "children":[
              
            ]
          }
        ]
      },
      {
        "type":"value",
        "val":"DE",
        "text":"DE - Släpvagn med fast dragstång.",
        "active":false,
        "children":[
          {
            "type":"value",
            "val":1,
            "text":"1 - Öppet flak.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":2,
            "text":"2 - Flaklämmar.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":3,
            "text":"3 - Skåp.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":4,
            "text":"4 - Konditionerad kaross med isolerade väggar och utrustning för att upprätthålla innetemperatur.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":5,
            "text":"5 - Konditionerad kaross med isolerade väggar, utan utrustning för att upprätthålla innetemperatur.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":6,
            "text":"6 - Med gardinsida.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":7,
            "text":"7 - Växelflak (utbytbar karosseristomme).",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":8,
            "text":"8 - Containerfordon.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":9,
            "text":"9 - Fordon försedda med lyftkrok.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":10,
            "text":"10 - Tippfordon.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":12,
            "text":"12 - Tankfordon avsedda för transport av farligt gods.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":13,
            "text":"13 - Fordon för transport av levande djur.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":14,
            "text":"14 - Fordon för transport av fordon.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":17,
            "text":"17 - Timmerfordon.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":19,
            "text":"19 - Fordon för gatusopning, gaturengöring och avloppsrening.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":20,
            "text":"20 - Kompressorfordon.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":21,
            "text":"21 - Fordon för transport av båtar.",
            "active":false,
            "children":[
              
            ]
          },
          {
            "type":"value",
            "val":99,
            "text":"99 - Karosseri som inte ingår i denna förteckning",
            "active":false,
            "children":[
              
            ]
          }
        ]
      }
    ]
  },

  getVehicleTypeFromAbbr: (abbr) => {
    if (!abbr) {
      return "";
    }

    const tc = store?.getState()?.user?.info?.lang
      ? store.getState().user.info.lang === "en"
        ? text.english
        : text.swedish
      : text.swedish;

    const type = tc[abbr.toLowerCase()];
    return type ? type : abbr;
  },

  getVehicleTypes: () => {
    return [
      'PB',
      'LB',
      'TLB',
      'SV',
      'HB',
      'HV',
      'BU',
      'TR',
      'MC',
      'ATV',
      'SS',
      'MP',
      'MR',
      'ATR',
    ]
  },
};
