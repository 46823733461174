import React, { useContext, useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import LanguageContext from "language-context";
import { tableHelper, textHelper } from "helpers";
import {
  getSearchResultsData,
  getSearchResultsDataDebounced,
  getSearchResultsDataMultipleDebounced,
  getSearchResultsDataMultiple,
} from "store/searchResults/tasks";
import { updateSettings } from "store/user/tasks";
import { saveProspectsToList } from "store/lists/tasks";
import { TablePropsManaged } from "components/table";
import Loading from "components/loading";
import Menu from "components/menu";
import PageHeader from "components/page_header";
import SaveToList from "components/save_to_list";

const SearchResults = (state) => {
  let { q } = useParams();
  q = q ? textHelper.removeDashFromOrgnr(decodeURIComponent(q)) : null;
  const [displayMode, setDisplayMode] = useState("prospects");
  const [headlineSub, setHeadlineSub] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showSaveToList, setShowSaveToList] = useState(false);
  const [searchType, setSearchType] = useState(null);
  const [queryIds, setQueryIds] = useState(null);
  const [sort, setSort] = useState({
    order: "asc",
    orderBy: "",
  });
  const [tableSearchQuery, setTableSearchQuery] = useState(null);
  const tc = useContext(LanguageContext);
  const { search, pathname } = useLocation();

  useEffect(() => {
    const parseQueryString = () => {
      try {
        const ids = JSON.parse(new URLSearchParams(search).get("ids"));
        const multiple = JSON.parse(
          new URLSearchParams(search).get("multiple")
        );

        setSearchType(multiple ? "multiple" : "single");
        setQueryIds(ids);
      } catch (err) {
        setQueryIds([]);
        setSearchType("single");
      }
    };

    parseQueryString();
  }, [pathname, search]);

  useEffect(() => {
    if (searchType === "single") {
      if (tableSearchQuery && tableSearchQuery.length) {
        getSearchResultsDataDebounced({
          displayMode: displayMode,
          page: page,
          pageSize: rowsPerPage,
          query: q,
          sort: sort,
          tableSearchQuery: tableSearchQuery,
        });
      } else {
        getSearchResultsData({
          displayMode: displayMode,
          page: page,
          pageSize: rowsPerPage,
          query: q,
          sort: sort,
        });
      }
    } else if (searchType === "multiple") {
      if (tableSearchQuery && tableSearchQuery.length) {
        getSearchResultsDataMultipleDebounced({
          displayMode: displayMode,
          page: page,
          pageSize: rowsPerPage,
          query: q,
          sort: sort,
          tableSearchQuery: tableSearchQuery,
          ids: queryIds,
        });
      } else {
        getSearchResultsDataMultiple({
          displayMode: displayMode,
          page: page,
          pageSize: rowsPerPage,
          query: q,
          sort: sort,
          ids: queryIds,
        });
      }
    }
  }, [
    q,
    displayMode,
    page,
    rowsPerPage,
    sort,
    tableSearchQuery,
    searchType,
    queryIds,
  ]);

  useEffect(() => {
    if (
      state.searchResults.data &&
      state.searchResults.data.hasOwnProperty("total") &&
      state.searchResults.data.total.hasOwnProperty("total")
    ) {
      setHeadlineSub(
        `${tc.total} ${
          state.searchResults.data && state.searchResults.data.total
            ? state.searchResults.data.total.total
            : 0
        } ${
          displayMode === "prospects"
            ? tc.prospects.toLowerCase()
            : tc.vehicles.toLowerCase()
        }`
      );
    } else {
      setHeadlineSub("");
    }
  }, [state.searchResults.data, displayMode]);

  const _stateCheck = () => {
    return !!(
      state.searchResults?.data &&
      state.searchResults.data.hasOwnProperty("total")
    );
  };

  return (
    <div className="searchResultsWrapper">
      <div className="searchResultsWrapper__searchResults">
        <div className="searchResultsWrapper__searchResults__header">
          <PageHeader
            headline={
              <>
                <span className="searchQuery">{tc.searchQuery}:</span> {q}
              </>
            }
            headlineSub={headlineSub}
          />
        </div>
        <div className="searchResultsWrapper__searchResults__content">
          <div className="searchResultsWrapper__searchResults__content__top">
            <Menu
              items={[
                {
                  disabled: !(
                    state.searchResults.data &&
                    state.searchResults.data.total &&
                    state.searchResults.data.total.total
                  ),
                  label: tc.saveToList,
                  onClick: () => {
                    setShowSaveToList(true);
                  },
                  type: "button",
                },
              ]}
            />
          </div>
          <div className="searchResultsWrapper__searchResults__content__bottom">
            {_stateCheck() ? (
              <>
                <div className="searchResultsWrapper__searchResults__content__bottom__menu">
                  <Menu
                    skipSort={true}
                    centered={true}
                    type="floatFree"
                    items={[
                      {
                        active: displayMode === "prospects",
                        label: tc.prospectsView,
                        onClick: () => {
                          setDisplayMode("prospects");
                          setSelectedRows([]);
                        },
                        type: "button",
                      },
                      {
                        active: displayMode === "cars",
                        label: tc.vehiclesView,
                        onClick: () => {
                          setDisplayMode("cars");
                          setSelectedRows([]);
                        },
                        type: "button",
                      },
                    ]}
                  />
                </div>
                <TablePropsManaged
                  adjustToContainer={true}
                  columns={
                    displayMode === "prospects"
                      ? tableHelper.getProspectColumns({
                          activeColumns:
                            state.user?.settings?.settings?.tables?.prospects
                              ?.columns,
                          numberOfMatchingCars: false,
                        })
                      : tableHelper.getVehicleColumns({
                          activeColumns:
                            state.user?.settings?.settings?.tables?.vehicles
                              ?.columns,
                          hideAllSort: true,
                        })
                  }
                  onColumnsChange={(columns) => {
                    if (state.user?.settings?.settings?.tables) {
                      const type =
                        displayMode === "prospects" ? "prospects" : "vehicles";

                      let settings = {
                        ...state.user.settings.settings,
                        tables: {
                          ...state.user.settings.settings.tables,
                          [type]: {
                            ...state.user.settings.settings.tables[type],
                            columns: columns,
                          },
                        },
                      };

                      updateSettings({
                        settings: settings,
                      });
                    }
                  }}
                  onSelect={(arr) => {
                    if (displayMode === "prospects") {
                      setSelectedRows(
                        state.searchResults.data.data.filter((num) =>
                          arr.includes(num.user_id)
                        )
                      );
                    } else if (displayMode === "cars") {
                      setSelectedRows(
                        state.searchResults.data.data.filter((num) =>
                          arr.includes(num.reg_number)
                        )
                      );
                    }
                  }}
                  page={page}
                  query={tableSearchQuery}
                  rows={
                    displayMode === "prospects"
                      ? tableHelper.getProspectRows({
                          numberOfMatchingCars: false,
                          rows: state.searchResults?.data?.data?.length
                            ? state.searchResults.data.data
                            : [],
                        })
                      : tableHelper.getVehicleRows({
                          rows: state.searchResults?.data?.data?.length
                            ? state.searchResults.data.data
                            : [],
                          historic: false,
                          koncern: false,
                        })
                  }
                  rowsPerPage={rowsPerPage}
                  selected={
                    displayMode === "prospects"
                      ? selectedRows.map((num) => num.user_id)
                      : selectedRows.map((num) => num.reg_number)
                  }
                  search={(newQuery) => {
                    setTableSearchQuery(newQuery);
                  }}
                  total={state.searchResults.data.total.total}
                  order={sort.order}
                  orderBy={sort.orderBy}
                  pageChange={(newPage) => {
                    setPage(newPage);
                  }}
                  rowsPerPageChange={(val) => {
                    setRowsPerPage(val);
                  }}
                  sort={(newSort) => {
                    setSort(newSort);
                  }}
                />
              </>
            ) : (
              <Loading />
            )}
          </div>
        </div>
        {showSaveToList ? (
          <SaveToList
            close={() => {
              setShowSaveToList(false);
            }}
            save={async (payload) => {
              setShowSaveToList(false);

              let prospectIds;
              if (selectedRows.length) {
                // Get selected prospect ids.
                prospectIds = selectedRows.map((num) => num.user_id.toString());
              } else {
                // Get all prospect ids for search.
                prospectIds = await getSearchResultsData({
                  allHits: true,
                  displayMode: displayMode,
                  query: q,
                  total: state.searchResults.data.total.total,
                });
              }

              // Save to list.
              if (payload.lists) {
                return await saveProspectsToList({
                  lists: payload.lists,
                  prospectIds: prospectIds,
                });
              } else if (payload.name) {
                return await saveProspectsToList({
                  name: payload.name,
                  prospectIds: prospectIds,
                });
              }
            }}
          />
        ) : null}
      </div>
    </div>
  );
};

const MapStateToProps = (state) => {
  return {
    searchResults: state.searchResults,
    user: state.user,
  };
};

export default connect(MapStateToProps)(SearchResults);
