import SingleRowNew from "./single_row_new";

/**
 *
 * @param {{data: {}, activityTypesToDisplay: string[], totals: {}, setInspect: ({show, user}) => void}} props
 * @description renders the table's content with an overview for each user's activity.
 */
const TableContent = ({
  setInspect,
  data,
  activityTypesToDisplay,
  totals,
  goals,
}) => {
  return (
    <div className="activityTypesTableWrapper__activityTypesTable__content__table">
      <div className="activityTypesTableWrapper__activityTypesTable__content__table__rowsHolder">
        <div className="activityTypesTableWrapper__activityTypesTable__content__table__rowsHolder__rows">
          {Object.keys(data).map((user) => {
            return (
              <SingleRowNew
                key={user}
                name={user}
                keys={activityTypesToDisplay}
                data={data[user]}
                totals={totals}
                isDeal={false}
                onClick={() =>
                  setInspect({
                    show: true,
                    user: { name: user, data: data[user] },
                  })
                }
                goals={
                  goals
                    ? goals.find(
                        (goal) => goal.user_id === data[user]?.["user_id"]
                      )
                    : null
                }
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TableContent;
