import {listActionTypes} from './actions';

interface ListState {
    data: object | null,
}

const initialState: ListState = {
    data: null,
};

export const listReducer = (state = initialState, action) => {
    switch(action.type) {
        case listActionTypes.LIST_SET_DATA: {
            return {
                ...state,
                data: action.payload,
            }
        }
        default: {
            return state;
        }
    }
};
