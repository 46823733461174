import React, { useState } from "react";
import Popup from "components/popup";

/**
 * A component that display content in a popup on click.
 * This is sort of a special solution for when we cannot use state, as in tableHelper.
 *
 * @param props.popupContent - element - Content to display in popup when props.visibleContent is clicked.
 * @param props.popupHeader - element (optional) - Content to display in popup header.
 * @param props.popupSize - string (optional)
 * @param props.visibleContent - element - Content to display.
 */
const PopupHolder = (props) => {
  const [showPopup, setShowPopup] = useState(false);

  return (
    <div className="popupHolderWrapper">
      <div
        className="popupHolderWrapper__popupHolder"
        onClick={(e) => {
          e.stopPropagation();
          setShowPopup(true);
        }}
      >
        {props.visibleContent}
        {showPopup ? (
          <Popup
            close={() => {
              setShowPopup(false);
            }}
            size={props.popupSize}
          >
            <div className="popupHolderWrapper__popupHolder__popupContent">
              {props.popupHeader ? (
                <div className="popupHolderWrapper__popupHolder__popupContent__header">
                  {props.popupHeader}
                </div>
              ) : null}
              <div className="popupHolderWrapper__popupHolder__popupContent__content">
                {props.popupContent}
              </div>
            </div>
          </Popup>
        ) : null}
      </div>
    </div>
  );
};

export default PopupHolder;
