import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getNews,
  markAsRead,
  removeNewsItem,
  setItemToDisplay,
  setEditId,
} from "store/news/tasks";
import { miscHelper } from "helpers";
import moment from "moment";
import LanguageContext from "language-context";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ActionButton from "components/action_button";
import CreateNewsItem from "components/create_news_item";
import DefaultNewsImage from "images/home_page/header_graphic.svg";
import Icon from "components/icon";
import Info from "components/info";
import Loading from "components/loading";
import Menu from "components/menu";
import PageHeader from "components/page_header";
import Popup from "components/popup";
import UserImage from "components/user_image";
import WidgetHeader from "components/widget_header";
import WidgetFooter from "components/widget_footer";

const News = (state) => {
  const [displayItem, setDisplayItem] = useState(null);
  const [hasNewsAccess, setHasNewsAccess] = useState(false);
  const [filters, setFilters] = useState({
    date: {
      from: new Date(
        moment().subtract(1, "year").format("YYYY-MM-DD HH:mm:ss")
      ),
      to: new Date(moment().add(1, "hour").format("YYYY-MM-DD HH:mm:ss")), // Add an hour so there isn't a problem when we edit an item and updated date goes over set filters.date.to.
    },
    newsType: [],
  });
  const [items, setItems] = useState(null);
  const [showCreateNewsItem, setShowCreateNewsItem] = useState(false);
  const [removeItem, setRemoveItem] = useState(null);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    getNews();
  }, []);

  useEffect(() => {
    setHasNewsAccess(
      !!(
        state.user.info.dealer_id === 5141 &&
        state.user.info.access === "unrestricted"
      )
    );
  }, [state.user.info]);

  useEffect(() => {
    // Currently we collect all news items from backend and do all filtering in frontend.
    if (
      items &&
      Array.isArray(state.news?.items) &&
      (filters.newsType?.length || (filters.date?.from && filters.date.to))
    ) {
      let itemsFiltered = JSON.parse(JSON.stringify(state.news.items));

      if (filters.newsType.length) {
        itemsFiltered = itemsFiltered.filter((item) => {
          let hasType = false;
          item.newsType.forEach((type) => {
            if (filters.newsType.includes(type)) {
              hasType = true;
            }
          });

          return hasType;
        });
      }

      if (filters.date?.from && filters.date?.to) {
        itemsFiltered = itemsFiltered.filter((item) => {
          return !!(
            new Date(item.updated) > filters.date.from &&
            new Date(item.updated) < filters.date.to
          );
        });
      }

      setItems(itemsFiltered);
      _setDisplayItem(itemsFiltered);
    } else {
      if (Array.isArray(state.news?.items)) {
        setItems(state.news.items);
        _setDisplayItem(state.news.items);
      }
    }
  }, [state.news.items, filters]);

  useEffect(() => {
    setDisplayItem(state.news.displayItem)
  }, [state.news.displayItem])

  const _renderDisplayNewsItem = () => {
    if (!displayItem) {
      return (
        <Info>
          <h4>{tc.noNews}</h4>
          <p>{tc.noNewsWhy}</p>
        </Info>
      );
    }

    return (
      <div className="newsWrapper__news__content__bottom__left__displayNewsItem">
        <div className="newsWrapper__news__content__bottom__left__displayNewsItem__headline">
          {displayItem.headline}
        </div>
        <div className="newsWrapper__news__content__bottom__left__displayNewsItem__dateAndNewsType">
          <div className="newsWrapper__news__content__bottom__left__displayNewsItem__dateAndNewsType__date">
            {`${tc.published} ${moment(new Date(displayItem.updated)).format(
              "LL"
            )}`}
            <div className="newsWrapper__news__content__bottom__left__displayNewsItem__dateAndNewsType__date__read">
              <Icon val="visibility" />
              {displayItem.readCount}
            </div>
          </div>
          <div className="newsWrapper__news__content__bottom__left__displayNewsItem__dateAndNewsType__newsType">
            {displayItem.newsType.map((num) => (
              <span
                key={num}
                className={`tag ${
                  filters.newsType.includes(num) ? "active" : ""
                } `}
                onClick={() => {
                  if (filters.newsType.includes(num)) {
                    setFilters({
                      ...filters,
                      newsType: filters.newsType.filter((x) => x !== num),
                    });
                  } else {
                    setFilters({
                      ...filters,
                      newsType: filters.newsType.concat(num),
                    });
                  }
                }}
              >
                {tc[num]}
              </span>
            ))}
          </div>
        </div>
        {displayItem.ingress?.length ? (
          <div className="newsWrapper__news__content__bottom__left__displayNewsItem__ingress">
            {displayItem.ingress}
          </div>
        ) : null}
        {displayItem.author?.email ? (
          <div className="newsWrapper__news__content__bottom__left__displayNewsItem__author">
            <div className="newsWrapper__news__content__bottom__left__displayNewsItem__author__img">
              <UserImage
                img={displayItem.author.img}
                name={displayItem.author.name}
                type="news"
              />
            </div>
            <div className="newsWrapper__news__content__bottom__left__displayNewsItem__author__name">
              {displayItem.author.name}
              <span className="email">
                {displayItem.author.email.toLowerCase()}
              </span>
            </div>
          </div>
        ) : null}
        <div className="newsWrapper__news__content__bottom__left__displayNewsItem__content">
          <ReactQuill
            readOnly={true}
            theme="bubble"
            value={displayItem.content}
          />
        </div>
        <div className="newsWrapper__news__content__bottom__left__displayNewsItem__img">
          <img
            className={displayItem.img ? "" : "default"}
            src={displayItem.img ? displayItem.img : DefaultNewsImage}
            alt={displayItem.headline}
          />
        </div>
        {hasNewsAccess ? (
          <div className="newsWrapper__news__content__bottom__left__displayNewsItem__footer">
            <div className="newsWrapper__news__content__bottom__left__displayNewsItem__footer__editButtons">
              <ActionButton
                label={tc.edit}
                onClick={() => {
                  setEditId(displayItem.id);
                }}
              />
              <ActionButton
                label={tc.remove}
                onClick={() => {
                  setRemoveItem(displayItem);
                }}
              />
            </div>
            {!displayItem.published?.length ? (
              <div className="newsWrapper__news__content__bottom__left__displayNewsItem__footer__draft">
                {tc.thisIsADraft}
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  };

  const _renderNewsArchive = () => {
    if (!items.length) {
      return;
    }

    let indexLimit = 2;

    return (
      <div className="newsWrapper__news__content__bottom__right__archive">
        <div className="newsWrapper__news__content__bottom__right__archive__label">
          {tc.newsArchive}
        </div>
        <div className="newsWrapper__news__content__bottom__right__archive__items">
          {items.map((num, i) => {
            if (num.id === displayItem?.id) {
              if (i <= indexLimit) {
                indexLimit = indexLimit + 1;
              }
              return null;
            }

            if (!num || i <= indexLimit) {
              return null;
            }

            return (
              <div
                className="newsWrapper__news__content__bottom__right__archive__items__item"
                key={num.id}
              >
                <div
                  className={`newsWrapper__news__content__bottom__right__archive__items__item__row ${
                    displayItem?.id === num.id ? "active" : ""
                  }`}
                  onClick={() => {
                    setDisplayItem(num);
                    markAsRead(num._id);
                  }}
                >
                  <div className="newsWrapper__news__content__bottom__right__archive__items__item__row__date">
                    {moment(new Date(num.updated)).format("LL")}
                  </div>
                  <div className="newsWrapper__news__content__bottom__right__archive__items__item__row__headline">
                    {num.headline}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const _removeNewsItem = () => {
    removeNewsItem(removeItem.id);
    setRemoveItem(null);
  };

  const _renderNewsThumbs = () => {
    if (!items.length) {
      return;
    }

    let indexLimit = 2;

    return (
      <div className="newsWrapper__news__content__bottom__right__thumbs">
        <div className="newsWrapper__news__content__bottom__right__thumbs__label">
          {tc.latest}
        </div>
        <div className="newsWrapper__news__content__bottom__right__thumbs__items">
          {items.map((num, i) => {
            if (num.id === displayItem?.id) {
              if (i <= indexLimit) {
                indexLimit = indexLimit + 1;
              }
              return null;
            }

            if (!num || i > indexLimit) {
              return null;
            }

            return (
              <div
                className="newsWrapper__news__content__bottom__right__thumbs__items__item"
                key={num.id}
                onClick={() => {
                  setDisplayItem(num);
                  markAsRead(num._id);
                }}
              >
                <div
                  className={`newsWrapper__news__content__bottom__right__thumbs__items__item__img ${
                    num.id === displayItem?.id ? "active" : ""
                  }`}
                >
                  <img
                    className={num.img ? "" : "default"}
                    src={num.img ? num.img : DefaultNewsImage}
                    alt={num.headline}
                  />
                  <div className="newsWrapper__news__content__bottom__right__thumbs__items__item__img__box">
                    <div className="newsWrapper__news__content__bottom__right__thumbs__items__item__img__box__date">
                      {moment(new Date(num.updated)).format("LL")}
                    </div>
                    <div className="newsWrapper__news__content__bottom__right__thumbs__items__item__img__box__headline">
                      {num.headline}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  function _setDisplayItem(arr) {
    const findLatest = (arr) => {
      if (!arr?.length) {
        return null;
      }

      let item = arr[0];
      if (!item) {
        return null;
      } else if (item?.published?.length) {
        if (item?.userHasRead) return item;
        // Latest item is a published item.
        markAsRead(item.id);
        return item;
      } else if (item.user_id === state.user.info.id) {
        if (item?.userHasRead) return item;
        // Last item is a draft, check if same user.
        markAsRead(item.id);
        return item;
      } else {
        return findLatest(arr.slice(1, arr.length - 1));
      }
    };

    // state.news.displayItem is set from other pages (like dashboard) so we can navigate to a specific news item.
    // If this has a value we it has precedence, but reset this whenever used.
    if (state.news.displayItem) {
      if (!state.news.displayItem?.userHasRead)
        markAsRead(state.news.displayItem.id);
      setDisplayItem(state.news.displayItem);
      /*setTimeout(() => {
        setItemToDisplay(null);
      }, 1000); */
    } else if (Array.isArray(arr) && arr.length) {
      const item = findLatest(JSON.parse(JSON.stringify(arr)));
      if (!item?.userHasRead) markAsRead(item.id);
      setDisplayItem(item);
    } else {
      setDisplayItem(null);
    }
  };

  const _stateCheck = () => {
    return !!items;
  };

  return (
    <div className="newsWrapper">
      <div className="newsWrapper__news">
        <div className="newsWrapper__news__header">
          <div className="dashboardWrapper__dashboard__header__top">
            <PageHeader />
          </div>
          <div className="newsWrapper__news__header__bottom">
            <Menu
              items={[
                {
                  dateFrom: filters.date.from,
                  dateTo: filters.date.to,
                  onChange: (date) => {
                    setFilters({
                      ...filters,
                      date: {
                        from: date.from,
                        to: date.to,
                      },
                    });
                  },
                  showTime: false,
                  showYear: true,
                  type: "date",
                },
                {
                  checkboxes: true,
                  label: tc.newsType,
                  items: miscHelper.newsTypes.map((type) => {
                    return {
                      active: filters.newsType.includes(type),
                      label: tc[type],
                      onClick: () => {
                        if (filters.newsType.includes(type)) {
                          setFilters({
                            ...filters,
                            newsType: filters.newsType.filter(
                              (x) => x !== type
                            ),
                          });
                        } else {
                          setFilters({
                            ...filters,
                            newsType: filters.newsType.concat(type),
                          });
                        }
                      },
                    };
                  }),
                  type: "dropdown",
                },
                hasNewsAccess
                  ? {
                      label: tc.addNewsItem,
                      onClick: () => {
                        setShowCreateNewsItem(true);
                      },
                      type: "button",
                    }
                  : null,
              ]}
              skipSort={true}
            />
          </div>
        </div>
        {_stateCheck() ? (
          <>
            <div className="newsWrapper__news__content">
              <div className="newsWrapper__news__content__bottom">
                <div className="newsWrapper__news__content__bottom__left">
                  {_renderDisplayNewsItem()}
                </div>
                <div className="newsWrapper__news__content__bottom__right">
                  {items.length > 1 ? _renderNewsThumbs() : null}
                  {items.length > 4 ? _renderNewsArchive() : null}
                </div>
              </div>
            </div>
          </>
        ) : (
          <Loading />
        )}

        {showCreateNewsItem || state.news.editId ? (
          <CreateNewsItem
            close={() => {
              setShowCreateNewsItem(false);
              setEditId(null);
            }}
          />
        ) : null}
        {removeItem && !showCreateNewsItem && !state.news.editId ? (
          <Popup
            close={() => {
              setRemoveItem(null);
            }}
            size="small"
          >
            <div className="genericPopupContentWrapper">
              <div className="genericPopupContentWrapper__genericPopupContent">
                <div className="genericPopupContentWrapper__genericPopupContent__header">
                  <WidgetHeader headline={tc.removeNewsItem} />
                </div>
                <div className="genericPopupContentWrapper__genericPopupContent__content">
                  <p>{`${
                    tc.removeNewsItem
                  } ${tc.with.toLowerCase()} ${tc.headline.toLowerCase()}: ${
                    removeItem.headline
                  }`}</p>
                  <p>{tc.ensure}</p>
                </div>
                <div className="genericPopupContentWrapper__genericPopupContent__footer">
                  <WidgetFooter
                    buttonOneFunc={_removeNewsItem}
                    buttonOneText={tc.remove}
                    buttonTwoFunc={() => {
                      setRemoveItem(null);
                    }}
                    buttonTwoText={tc.cancel}
                  />
                </div>
              </div>
            </div>
          </Popup>
        ) : null}
      </div>
    </div>
  );
};

const MapStateToProps = (state, props) => {
  return {
    news: state.news,
    user: state.user,
  };
};

export default connect(MapStateToProps)(News);
