import React from 'react';
import Activities from 'components/activities';
import ActivityPipeline from 'components/activity_pipeline';
import DataVisualisation from 'components/analyse/data_visualisation';
import Events from 'components/events';
import LeadsWidget from 'components/leads_widget';
import NewsWidget from 'components/news_widget';
import SupertempWidget from 'components/supertemp_widget';

/**
 * Render a dashboard widget.
 */
const WidgetKanbanBoardItem = (props) => {
  const _renderWidget = () => {
    switch (
      props.type.toLowerCase() // <-- Note type in lower case :)
    ) {
      case 'activities':
        return (
          <Activities
            amountIncrease={3}
            hideCommentInput={true}
            view="flow"
            widget={props}
          />
        );
      case 'analysecar':
        return <DataVisualisation {...props.data} title={props.name} />;
      case 'analysesales':
        return <DataVisualisation {...props.data} title={props.name} />;
      case 'notifications':
        return (
          <Events
            amountIncrease={3}
            showCalendarView={true}
            type="all"
            view="calendar"
            isWidget={true}
          />
        );
      case 'leads':
        return <LeadsWidget />;
      case 'monitorlist':
        return <SupertempWidget widget={props} />;
      case 'pipeline':
        return <ActivityPipeline widget={props} />;
      case 'news':
        return <NewsWidget amount={5} />;
      // case "salesman_widget":
      //   return <ActivityPipeline widget={props} />;
      default:
        return null;
    }
  };

  return (
    <div className="widgetKanbanBoardItemWrapper" key={props._id}>
      <div className="widgetKanbanBoardItemWrapper__widgetKanbanBoardItem">
        {_renderWidget()}
      </div>
    </div>
  );
};

export default WidgetKanbanBoardItem;
