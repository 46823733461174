export const koncernActionTypes = {
  SET_KONCERN_DATA: 'SET_KONCERN_DATA',
  SET_KONCERN_RESPONSIBLE: 'SET_KONCERN_RESPONSIBLE',
  RESET_KONCERN_DATA: 'RESET_KONCERN_DATA',
  RESET_KONCERN_STRUCTURE: 'RESET_KONCERN_STRUCTURE',
  SET_KONCERN_STRUCTURE: 'SET_KONCERN_STRUCTURE',
  SET_KONCERN_DEALS: 'SET_KONCERN_DEALS',
  SET_KONCERN_BASIC_INFO: 'SET_KONCERN_BASIC_INFO',
  TOGGLE_SHOW_EMPTY: 'TOGGLE_SHOW_EMPTY',
};
