import React, { useRef, useContext } from 'react';
import LanguageContext from 'language-context';
/**
 *
 * @param onUpload - func
 * @param enableFileInput - bool - Enables click to on field to handle file upload
 * @returns
 */
const DragAndDropUploader = ({ onUpload, enableFileInput }) => {
  const tc = useContext(LanguageContext);
  const s3UploaderRef = useRef(null);
  // Event handler for drop
  const handleDrop = (e) => {
    e.preventDefault();
    const files = [...e.dataTransfer.files];
    onUpload(files);
  };

  // Event handler for drag over
  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const handleFileSelect = (e) => {
    const files = [...e.target.files];
    onUpload(files);
  };

  return (
    <div
      className="dragAndDropWrapper__container"
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onClick={() => {
        if (enableFileInput && s3UploaderRef.current) {
          s3UploaderRef.current.click();
        }
      }}
    >
      <h2>{tc.addFileHere}</h2>
      <input
        className="dragAndDropWrapper__container__hiddenInput"
        type="file"
        ref={s3UploaderRef}
        onChange={handleFileSelect}
      />
    </div>
  );
};

export default DragAndDropUploader;
