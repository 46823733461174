import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import LanguageContext from 'language-context';
import { activityHelper, miscHelper } from 'helpers';
import ActionButton from 'components/action_button';
import ActivitiesFlowItem from './activities_flow_item';
import Info from 'components/info';
import ActivitiesFlowItemMailingsAction from './activities_flow_item_mailings_action';
import { getActivities } from 'store/activities/tasks';

/**
 * Render activities in a flow list.
 * Receive activities data as props.
 *
 * @param props.activityTypesToDisplay - array - ["analysis", "mail", "visit" ...]
 * @param props.allRows - bool (optional) - Show all rows, no "retrieve more" button.
 * @param props.amountIncrease - number (optional) - How many rows we show initially and add every click.
 * @param props.data - array
 * @param props.embedded - bool - When in embedded mode, optional.
 * @param props.hideEdit - bool (optional)
 * @param props.target - string - Deal id | prospect id | vehicle reg num | lead id
 * @param props.user - user object from user reducer.
 */
const ActivitiesFlow = ({ targetType, ...props }) => {
  const tc = useContext(LanguageContext);
  const amountIncrease = props.amountIncrease ? props.amountIncrease : 5;
  const ACTIVITY_LOAD_BREAKPOINT = 150;
  //const [activities, setActivities] = useState(null);
  //const [activitiesHidden, setActivitiesHidden] = useState(null); // Used to render a hidden element to calculate heights.
  const [dataLength, setDataLength] = useState(null);
  const [displayAmountRows, setDisplayAmountRows] = useState(
    props.allRows ? props.data?.length : amountIncrease
  );
  const [showInfo, setShowInfo] = useState(false);
  const [userTouched, setUserTouched] = useState(false);
  const observer = useRef(null);
  const activitiesFlowRef = useRef(null);
  const activitiesFlowItemsRef = useRef(null);
  const activitiesFlowItemsVisibleRef = useRef(null);
  const activitiesFlowItemsHiddenRef = useRef(null);
  const lastResizeUpdate = useRef({ current: new Date().getTime() });

  const activitiesToRender = Array.isArray(props.data)
    ? props.data
    : Array.isArray(props.data?.activitiesByTarget) && props.target
    ? props.data.activitiesByTarget
    : null;

  useEffect(() => {
    // Listen for parent element size, make adjustments.
    let timeout;
    if (new Date().getTime() - lastResizeUpdate.current < 50) {
      timeout = setTimeout(() => {
        _setResponsive();
      }, 100);
      return;
    }
    clearTimeout(timeout);
    lastResizeUpdate.current = new Date().getTime();
    _setResponsive();
    observer.current = new ResizeObserver(_setResponsive);
    observer.current.observe(activitiesFlowRef.current);

    const ref = activitiesFlowRef.current;

    return () => observer.current.unobserve(ref);
  }, [dataLength]);

  useEffect(() => {
    if (!showInfo) {
      _setResponsiveDisplayAmountRows();
    }
  }, [showInfo, displayAmountRows]);

  useEffect(() => {
    async function fetchActivitiesByTarget() {
      await getActivities({
        target: props.target,
        type: 'target',
        targetType: targetType,
      });
    }
    if (props.target && props.fetchOwnActivities) {
      fetchActivitiesByTarget();
    }
  }, [props.fetchOwnActivities, props.target, targetType]);

  // useEffect(() => {
  //   const _setActivities = () => {
  //     // if (!props.activityTypesToDisplay.length) {
  //     //   return setActivities([]);
  //     // }

  //     let data = Array.isArray(props.data)
  //       ? props.data.filter((num) => {
  //           const displayComments =
  //             props.activityTypesToDisplay.includes("comment");

  //           if (!displayComments && !num.action) {
  //             // Don't display comments, comments doesn't have 'action' property.
  //             return false;
  //           } else if (!num.action) {
  //             // This is a comment, display.
  //             return true;
  //           }
  //           // The rest should have action property.
  //           return props.activityTypesToDisplay.includes(num.action);
  //         })
  //       : null;

  //     if (!data) {
  //       setDataLength(0);
  //       //return setActivities([]);
  //     }

  //     const _dataLength = data.length;
  //     setDataLength(_dataLength); // Set data length before slice.

  //     //const dataHidden = JSON.parse(JSON.stringify(data));
  //     // const dataHidden = [...data];
  //     console.log("data length: ", data.length);
  //     if (!props.allRows) {
  //       data = data.slice(0, displayAmountRows);
  //     }

  //     // setActivities(
  //     //   data.map((num, i) => {
  //     //     return (
  //     //       <React.Fragment key={i}>
  //     //         {_renderActivitiesItem(num, i)}
  //     //       </React.Fragment>
  //     //     );
  //     //   })
  //     // );

  //     // if (!props.allRows && displayAmountRows < _dataLength) {
  //     //   setActivitiesHidden(
  //     //     dataHidden.map((activity, i) => {
  //     //       return (
  //     //         <React.Fragment key={getKey(activity, i)}>
  //     //           <ActivitiesFlowItem
  //     //             action={activityHelper.getActionJSX(props.target, activity)}
  //     //             activityId={activity._id ? activity._id : activity.id}
  //     //             comment={activity.comment ?? null}
  //     //             date={activity.date_created ?? null}
  //     //             embedded={props.embedded}
  //     //             // icon={icon}
  //     //             // index={i}
  //     //             isEditable={
  //     //               !props.hideEdit &&
  //     //               +props.user.info.id === +activity.user_id &&
  //     //               ((activity.action && activity.action !== "move") ||
  //     //                 (!activity.action &&
  //     //                   activity.comment &&
  //     //                   activity.comment !== "" &&
  //     //                   (activity.id || activity._id)))
  //     //             }
  //     //             reason={
  //     //               activity.reason && activity.reason.length
  //     //                 ? activityHelper.getReadableActivity(activity.reason)
  //     //                 : null
  //     //             }
  //     //             scheduledActivity={
  //     //               activity?.action
  //     //                 ? activityHelper
  //     //                     .getActionTypes(true)
  //     //                     .includes(activity.action)
  //     //                 : false
  //     //             }
  //     //             type={activity.action ? activity.action : "comment"}
  //     //             user={activity.user}
  //     //           />
  //     //         </React.Fragment>
  //     //       );
  //     //     })
  //     //   );
  //     // }
  //   };

  //   _setActivities();
  // }, [
  //   props.activityTypesToDisplay,
  //   displayAmountRows,
  //   props.data,
  //   props.allRows,
  // ]);

  useEffect(() => {
    setShowInfo(
      !props.activityTypesToDisplay?.length || !activitiesToRender?.length
    );
  }, [props.activityTypesToDisplay, activitiesToRender]);

  // const hiddenItemsMemo = useMemo(() => {
  //   return props.data?.map((activity, i) => {
  //     return (
  //       <React.Fragment key={getKey(activity, i)}>
  //         <ActivitiesFlowItem
  //           action={activityHelper.getActionJSX(props.target, activity)}
  //           activityId={activity._id ? activity._id : activity.id}
  //           comment={activity.comment ?? null}
  //           date={activity.date_created ?? null}
  //           embedded={props.embedded}
  //           // icon={icon}
  //           // index={i}
  //           isEditable={
  //             !props.hideEdit &&
  //             +props.user.info.id === +activity.user_id &&
  //             ((activity.action && activity.action !== "move") ||
  //               (!activity.action &&
  //                 activity.comment &&
  //                 activity.comment !== "" &&
  //                 (activity.id || activity._id)))
  //           }
  //           reason={
  //             activity.reason && activity.reason.length
  //               ? activityHelper.getReadableActivity(activity.reason)
  //               : null
  //           }
  //           scheduledActivity={
  //             activity?.action
  //               ? activityHelper.getActionTypes(true).includes(activity.action)
  //               : false
  //           }
  //           type={activity.action ? activity.action : "comment"}
  //           user={activity.user}
  //         />
  //       </React.Fragment>
  //     );
  //   });
  // }, [props?.data, props.embedded, props.user, props.hideEdit, props.target]);

  const _renderActivitiesItem = useCallback((activity, i) => {
    // Action
    const action = activityHelper.getActionJSX(props.target, activity);

    // Comment
    const comment = activity.comment ? activity.comment : null;

    // Date
    const date = activity.date_created ? activity.date_created : null;

    // Icon
    // let icon;
    // if (activity.action) {
    //   icon = <Icon val={activity.action} />;
    // } else if (!activity.action && activity.comment) {
    //   icon = <Icon val="comment" />;
    // }

    // Only editable by same user, and only comments or "scheduled events".
    const isEditable =
      +props.user.info.id === +activity.user_id &&
      ((activity.action && activity.action !== 'move') ||
        (!activity.action &&
          activity.comment &&
          activity.comment !== '' &&
          (activity.id || activity._id)));

    // Reason
    const reason =
      activity.reason && activity.reason.length
        ? activityHelper.getReadableActivity(activity.reason)
        : null;

    // Is activity a "scheduled event" like meeting, phone call etc.
    const scheduledActivity = activity?.action
      ? activityHelper.getActionTypes(true).includes(activity.action)
      : false;

    return (
      <ActivitiesFlowItem
        action={action}
        activityId={activity._id ? activity._id : activity.id}
        comment={comment}
        date={date}
        embedded={props.embedded}
        // icon={icon}
        // index={i}
        isEditable={!props.hideEdit && isEditable}
        reason={reason}
        scheduledActivity={scheduledActivity}
        type={activity.action ? activity.action : 'comment'}
        user={activity.user}
      />
    );
  });

  function calculateRowChange(containerHeight, innerHeight, breakpoint) {
    if (containerHeight > innerHeight) {
      if (containerHeight - innerHeight > breakpoint) {
        const diff = parseInt(containerHeight - innerHeight);
        const increase = Math.floor(diff / breakpoint);
        return increase;
      }
    }

    if (containerHeight / displayAmountRows > breakpoint) {
      if (innerHeight - containerHeight > breakpoint) {
        const diff = parseInt(containerHeight - innerHeight);
        const decrease = Math.floor(diff / breakpoint);
        return decrease;
      }
    }

    return 0;
  }

  /**
   * Do adjustments based on parent element size.
   */
  const _setResponsive = () => {
    if (userTouched) return;

    const containerHeight =
      activitiesFlowItemsRef.current?.getBoundingClientRect().height;
    const innerHeight = activitiesFlowItemsVisibleRef.current?.scrollHeight;
    //activitiesFlowItemsVisibleRef.current.getBoundingClientRect().height;
    // console.log(
    //   "scroll height: ",
    //   activitiesFlowItemsVisibleRef.current.scrollHeight
    // );

    //increases or decreases the amount of activity rows displayed, one activity per 150px difference.
    setDisplayAmountRows((rows) => {
      return (
        rows +
        calculateRowChange(
          containerHeight,
          innerHeight,
          ACTIVITY_LOAD_BREAKPOINT
        )
      );
    });
    //_setResponsiveDisplayAmountRows();
  };

  /**
   * Check height of content and adjust how many items we display.
   *
   * (Important that styling is set correctly for this to work. Content holder should stretch but not overflow.)
   */
  const _setResponsiveDisplayAmountRows = () => {
    if (userTouched) {
      // If user has clicked "Hämta fler"-button we should not adjust items.
      return;
    }

    const calculation = miscHelper.calculateHeightAndItems(
      activitiesFlowItemsRef?.current,
      activitiesFlowItemsVisibleRef?.current?.children,
      activitiesFlowItemsHiddenRef?.current?.children
    );

    if (calculation.itemsExtra > 0) {
      const newValue = displayAmountRows + calculation.itemsExtra;
      setDisplayAmountRows(newValue < dataLength ? newValue : dataLength);
    } else if (calculation.itemsRemove > 1 && displayAmountRows > 1) {
      const newValue = displayAmountRows - calculation.itemsRemove;
      setDisplayAmountRows(newValue > 1 ? newValue : 1);
    }
  };

  function getKey(activity, i) {
    let id = '';
    if (activity._id) {
      id = activity._id;
    } else if (activity.id) {
      id = activity.id;
    }
    if (activity.date_created) {
      id = id + activity.date_created;
    } else {
      id = id + i;
    }

    if (activity.action) {
      id = id + activity.action;
    }
    return id;
  }

  return (
    <div className="activitiesFlowWrapper" ref={activitiesFlowRef}>
      <div className="activitiesFlowWrapper__activitiesFlow">
        <div className="activitiesFlowWrapper__activitiesFlow__content">
          {showInfo ? (
            <div className="activitiesFlowWrapper__activitiesFlow__content__middle">
              <Info>
                <h4>{tc.noActivity}</h4>
                <p>{tc.noActivityWhy}</p>
              </Info>
            </div>
          ) : (
            <>
              <div
                className="activitiesFlowWrapper__activitiesFlow__content__middle"
                ref={activitiesFlowItemsRef}
              >
                <div
                  className="activitiesFlowWrapper__activitiesFlow__content__middle__items"
                  ref={activitiesFlowItemsVisibleRef}
                >
                  {/* {activities} */}
                  {Array.isArray(activitiesToRender)
                    ? activitiesToRender
                        .filter((num) => {
                          const shouldDisplayComments =
                            props.activityTypesToDisplay.includes('comment');

                          if (!shouldDisplayComments && !num.action) {
                            return false;
                          } else if (!num.action) {
                            return true;
                          }
                          return props.activityTypesToDisplay.includes(
                            num.action
                          );
                        })
                        .slice(0, displayAmountRows)
                        .map((activity, i) => {
                          //console.log(activity);
                          return (
                            <React.Fragment key={getKey(activity, i)}>
                              <ActivitiesFlowItem
                                action={
                                  activity.action === 'mailings' ? (
                                    <ActivitiesFlowItemMailingsAction
                                      {...activity}
                                    />
                                  ) : (
                                    activityHelper.getActionJSX(
                                      props.target,
                                      activity
                                    )
                                  )
                                }
                                activityId={
                                  activity._id ? activity._id : activity.id
                                }
                                comment={activity.comment ?? null}
                                date={activity.date_created ?? null}
                                embedded={props.embedded}
                                // icon={icon}
                                // index={i}
                                isEditable={
                                  !props.hideEdit &&
                                  +props.user.info.id === +activity.user_id &&
                                  ((activity.action &&
                                    activity.action !== 'move') ||
                                    (!activity.action &&
                                      activity.comment &&
                                      activity.comment !== '' &&
                                      (activity.id || activity._id)))
                                }
                                reason={
                                  activity.reason && activity.reason.length
                                    ? activityHelper.getReadableActivity(
                                        activity.reason
                                      )
                                    : null
                                }
                                scheduledActivity={
                                  activity?.action
                                    ? activityHelper
                                        .getActionTypes(true)
                                        .includes(activity.action)
                                    : false
                                }
                                type={
                                  activity.action ? activity.action : 'comment'
                                }
                                user={activity.user}
                              />
                            </React.Fragment>
                          );
                        })
                    : null}
                </div>
              </div>
              <div
                className="activitiesFlowWrapper__activitiesFlow__content__middle__itemsHidden"
                ref={activitiesFlowItemsHiddenRef}
              >
                {/* {props.data?.map((activity, i) => {
                  return (
                    <React.Fragment key={getKey(activity, i)}>
                      <ActivitiesFlowItem
                        action={activityHelper.getActionJSX(
                          props.target,
                          activity
                        )}
                        activityId={activity._id ? activity._id : activity.id}
                        comment={activity.comment ?? null}
                        date={activity.date_created ?? null}
                        embedded={props.embedded}
                        // icon={icon}
                        // index={i}
                        isEditable={
                          !props.hideEdit &&
                          +props.user.info.id === +activity.user_id &&
                          ((activity.action && activity.action !== "move") ||
                            (!activity.action &&
                              activity.comment &&
                              activity.comment !== "" &&
                              (activity.id || activity._id)))
                        }
                        reason={
                          activity.reason && activity.reason.length
                            ? activityHelper.getReadableActivity(
                                activity.reason
                              )
                            : null
                        }
                        scheduledActivity={
                          activity?.action
                            ? activityHelper
                                .getActionTypes(true)
                                .includes(activity.action)
                            : false
                        }
                        type={activity.action ? activity.action : "comment"}
                        user={activity.user}
                      />
                    </React.Fragment>
                  );
                })} */}
              </div>
            </>
          )}
          {!props.allRows &&
          //dataLength > amountIncrease &&
          activitiesToRender?.length > amountIncrease &&
          !showInfo &&
          !props.embedded ? (
            <div className="activitiesFlowWrapper__activitiesFlow__content__bottom">
              {userTouched ? (
                <ActionButton
                  class="cancelDrag"
                  icon="reset"
                  label={tc.reset}
                  onClick={() => {
                    setUserTouched(false);
                    setDisplayAmountRows(amountIncrease);
                  }}
                  type="regular"
                />
              ) : null}
              {/* {dataLength && displayAmountRows < dataLength ? ( */}
              {activitiesToRender?.length &&
              activitiesToRender?.length > displayAmountRows ? (
                <ActionButton
                  class="cancelDrag"
                  icon="load"
                  label={tc.getMore}
                  onClick={() => {
                    setUserTouched(true);
                    setDisplayAmountRows(displayAmountRows + amountIncrease);
                  }}
                  type="regular"
                />
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ActivitiesFlow;
