import React, { useContext, useState } from 'react';
import LanguageContext from 'language-context';
import { NavLink } from 'react-router-dom';
import { activityHelper } from 'helpers';
import EventsCompleteEvent from './events_complete_event';
import EventsFlowItem from './events_flow_item';
import EventsRemoveEvent from './events_remove_event';
import Icon from 'components/icon';
import Popup from 'components/popup';
import WidgetHeader from 'components/widget_header';

/**
 * Render a day element for EventsCalendar component.
 *
 * props.date - {date, weekday, week} - Example: {date: '24', week: 46, weekDay: 'tisdag'}
 * props.day - string - Examples: 2021/6/04 | 2021/11/28
 * props.events - array - [{ _id, action, dealId, name, (...) }, {(...)}]
 * props.today - bool - If component represents current day.
 */
const EventsCalendarDayWrapper = (props) => {
  const [eventToComplete, setEventToComplete] = useState(null);
  const [eventToRemove, setEventToRemove] = useState(null);
  const [showEvents, setShowEvents] = useState(false);
  const tc = useContext(LanguageContext);

  const now = Date.now();

  const _renderEvents = () => {
    return (
      <div className="eventsCalendarDayWrapper__eventsCalendarDay__eventsWrapper">
        <div className="eventsCalendarDayWrapper__eventsCalendarDay__eventsWrapper__header">
          <WidgetHeader
            headline={props.events.length > 1 ? tc.activities : tc.activity}
            closeButton={() => {
              setShowEvents(false);
              setEventToComplete(null);
              setEventToRemove(null);
            }}
          />
        </div>
        <div className="eventsCalendarDayWrapper__eventsCalendarDay__eventsWrapper__content">
          {props.events.map((num) => {
            // Action
            const action = (
              <div className="readableActivity">
                {activityHelper.getReadableActivity(num.action, new Date(num.event_date) > now)}{' '}
                {activityHelper.getPreposition(num.action).toLowerCase()}{' '}
                <NavLink exact to={'/affar/' + num.dealId} key="affar">
                  {num.name ? num.name : tc.nameMissing}
                </NavLink>
              </div>
            );

            // Comment
            const comment = num.comment ? num.comment : null;

            // Date
            const date = num.event_date ? num.event_date : null;

            // Deal id
            const dealId = num.dealId;

            // Event id
            const eventId = num._id;

            return (
              <EventsFlowItem
                key={eventId}
                action={action}
                actionType={num.action}
                comment={comment}
                date={date}
                dealId={dealId}
                eventId={eventId}
                user={num.user}
              />
            );
          })}
        </div>
      </div>
    );
  };

  const _openEvents = () => {
    if (props.events?.length) {
      setShowEvents(true);
    }
  };

  return (
    <div
      className={`eventsCalendarDayWrapper ${
        props.events?.length ? 'hasEvent' : ''
      } ${activityHelper.getEventClass(props.day, true)} ${props.today ? 'today' : ''}`}
    >
      <div className="eventsCalendarDayWrapper__eventsCalendarDay" onClick={_openEvents}>
        <div className="eventsCalendarDayWrapper__eventsCalendarDay__top" />
        <div className="eventsCalendarDayWrapper__eventsCalendarDay__date">{props.date.date}</div>
        <div className="eventsCalendarDayWrapper__eventsCalendarDay__notification">
          {props.events?.length ? `${props.events.length} ${tc.aPiece.toLowerCase()}` : null}
        </div>
      </div>
      {showEvents ? (
        <Popup
          close={() => {
            setShowEvents(false);
            setEventToComplete(null);
            setEventToRemove(null);
          }}
          size="medium"
        >
          {_renderEvents()}
          {eventToComplete ? (
            <EventsCompleteEvent
              action={eventToComplete.action}
              close={() => {
                setEventToRemove(null);
                setEventToComplete(null);
                setShowEvents(null);
              }}
              eventId={eventToComplete._id}
            />
          ) : null}
          {eventToRemove ? (
            <EventsRemoveEvent
              action={eventToRemove.action}
              close={() => {
                setEventToRemove(null);
                setEventToComplete(null);
                setShowEvents(null);
              }}
              dealId={eventToRemove.dealId}
              eventId={eventToRemove._id}
            />
          ) : null}
        </Popup>
      ) : null}
    </div>
  );
};

export default EventsCalendarDayWrapper;
