import React, { useContext, useEffect, useState } from "react";
import LanguageContext from "language-context";
import { textHelper } from "helpers";
import { sendApplicationOfInterest } from "store/user/tasks";
import { showFlashMessage } from "store/flash_messages/tasks";
import Icon from "components/icon";

/**
 * Render a form for application of interest.
 */
const HomeForm = () => {
  const [formInfo, setFormInfo] = useState({
    name: "",
    company: "",
    orgNr: "",
    phone: "",
    email: "",
  });
  const [validation, setValidation] = useState({
    name: false,
    company: false,
    orgNr: false,
    phone: false,
    email: false,
  });
  const tc = useContext(LanguageContext);

  useEffect(() => {
    setValidation({
      company:
        formInfo.company.length &&
        textHelper.regexCompanyName.test(formInfo.company),
      email:
        formInfo.email.length && textHelper.regexEmail.test(formInfo.email),
      orgNr:
        formInfo.orgNr.length && textHelper.regexOrgnr.test(formInfo.orgNr),
      phone:
        formInfo.phone.length &&
        textHelper.regexPhoneNumber.test(formInfo.phone),
      name: formInfo.name.length && textHelper.regexName.test(formInfo.name),
    });
  }, [formInfo]);

  const _sendApplicationOfInterest = () => {
    if (
      validation.company &&
      validation.email &&
      validation.orgNr &&
      validation.name
    ) {
      sendApplicationOfInterest(formInfo);

      setFormInfo({
        name: "",
        company: "",
        orgNr: "",
        phone: "",
        email: "",
      });

      setValidation({
        name: false,
        company: false,
        orgNr: false,
        phone: false,
        email: false,
      });
    } else {
      showFlashMessage(tc.formMissingInput, "info");
    }
  };

  return (
    <div className="homeFormWrapper">
      <div className="homeFormWrapper__homeForm">
        <div className="homeFormWrapper__homeForm__content">
          <div className="homeFormWrapper__homeForm__content__row">
            <div className="homeFormWrapper__homeForm__content__row__icon">
              <Icon val="company" />
            </div>
            <input
              className={validation.company ? "valid" : "invalid"}
              type="text"
              onChange={(e) => {
                setFormInfo({
                  ...formInfo,
                  company: e.target.value,
                });
              }}
              placeholder={tc.company + " *"}
              value={formInfo.company}
            />
            <div
              className={
                validation.company
                  ? "homeFormWrapper__homeForm__content__row__notification valid"
                  : "homeFormWrapper__homeForm__content__row__notification invalid"
              }
            >
              <Icon val="check" />
            </div>
          </div>
          <div className="homeFormWrapper__homeForm__content__row">
            <div className="homeFormWrapper__homeForm__content__row__icon">
              <Icon val="infoOutlined" />
            </div>
            <input
              className={validation.orgNr ? "valid" : "invalid"}
              type="text"
              onChange={(e) => {
                setFormInfo({
                  ...formInfo,
                  orgNr: e.target.value,
                });
              }}
              placeholder={tc.orgNumberShort + " *"}
              value={formInfo.orgNr}
            />
            <div
              className={
                validation.orgNr
                  ? "homeFormWrapper__homeForm__content__row__notification valid"
                  : "homeFormWrapper__homeForm__content__row__notification invalid"
              }
            >
              <Icon val="check" />
            </div>
          </div>
          <div className="homeFormWrapper__homeForm__content__row">
            <div className="homeFormWrapper__homeForm__content__row__icon">
              <Icon val="person" />
            </div>
            <input
              type="text"
              onChange={(e) => {
                setFormInfo({
                  ...formInfo,
                  name: e.target.value,
                });
              }}
              placeholder={tc.name + " *"}
              value={formInfo.name}
            />
            <div
              className={
                validation.name
                  ? "homeFormWrapper__homeForm__content__row__notification valid"
                  : "homeFormWrapper__homeForm__content__row__notification invalid"
              }
            >
              <Icon val="check" />
            </div>
          </div>
          <div className="homeFormWrapper__homeForm__content__row">
            <div className="homeFormWrapper__homeForm__content__row__icon">
              <Icon val="phone" />
            </div>
            <input
              className={validation.phone ? "valid" : "invalid"}
              type="text"
              onChange={(e) => {
                setFormInfo({
                  ...formInfo,
                  phone: e.target.value,
                });
              }}
              placeholder={tc.phone}
              value={formInfo.phone}
            />
            <div
              className={
                validation.phone
                  ? "homeFormWrapper__homeForm__content__row__notification valid"
                  : "homeFormWrapper__homeForm__content__row__notification invalid"
              }
            >
              <Icon val="check" />
            </div>
          </div>
          <div className="homeFormWrapper__homeForm__content__row">
            <div className="homeFormWrapper__homeForm__content__row__icon">
              <Icon val="mail" />
            </div>
            <input
              className={validation.email ? "valid" : "invalid"}
              type="text"
              onChange={(e) => {
                setFormInfo({
                  ...formInfo,
                  email: e.target.value,
                });
              }}
              placeholder={tc.email + " *"}
              value={formInfo.email}
            />
            <div
              className={
                validation.email
                  ? "homeFormWrapper__homeForm__content__row__notification valid"
                  : "homeFormWrapper__homeForm__content__row__notification invalid"
              }
            >
              <Icon val="check" />
            </div>
          </div>
          <div
            className={
              validation.company &&
              validation.email &&
              validation.orgNr &&
              validation.name
                ? "homeFormWrapper__homeForm__content__button"
                : "homeFormWrapper__homeForm__content__button disabled"
            }
          >
            <button onClick={_sendApplicationOfInterest}>
              {tc.sendApplicationOfInterest}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeForm;
