import {
  ArrowForwardRounded,
  Circle,
  CircleOutlined,
  Clear,
} from "@mui/icons-material";

/**
 * Used to create a pageindicator. Series of dots to indicate
 * the current page/index. If the number of pages is less than 2 then
 * it returns nothing(no need to indicate 1/1).
 * @param {number} length - The length or the array
 * @param {number} index - The current(active) index
 * @returns React.ReactElement || null
 */
export const PageIndicator = ({ length, index }) => {
  if (length < 2) {
    return null;
  }

  const circles = [];

  for (let i = 0; i < length; i++) {
    if (i === index) {
      circles.push(
        <Circle key={`pageIndicatorCircleFull${i}`} fontSize="small" />
      );
    } else {
      circles.push(
        <CircleOutlined
          key={`pageIndicatorCircleOutline${i}`}
          fontSize="small"
        />
      );
    }
  }

  return <section className="pageIndicator">{circles}</section>;
};

/**
 * UI-element arrow button to change index/page.
 * @param {boolean} forward True = face right, false = face left.
 * @param {function} action Action that the button should trigger.
 * @returns React.ReactElement
 */
export const Arrow = ({ forward, action }) => {
  if (forward) {
    return (
      <div className="arrow" onClick={action}>
        <ArrowForwardRounded fontSize="large" className="arrow__icon" />
      </div>
    );
  }
  return (
    <div className="arrow backwards" onClick={action}>
      <ArrowForwardRounded fontSize="large" className="arrow__icon" />
    </div>
  );
};

/**
 * UI-element close button.
 * @param {function} action Action that the button should trigger.
 * @returns React.ReactElement
 */
export const Close = ({ action }) => {
  return (
    <div className="close" onClick={action}>
      <Clear fontSize="medium" />
    </div>
  );
};
