import React, { useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import LanguageContext from 'language-context';
import { activityHelper, agileHelper, dealHelper, miscHelper } from 'helpers';
import personHelper from 'shared_helpers/person_helper';
import moment from 'moment';
import {
  getColumnsData,
  getPreviewData,
  moveItem,
  setPreviewId,
} from 'store/agile/tasks';
import { updateDeal } from 'store/deal/tasks';
import { completeEvent, removeEvent } from 'store/events/tasks';
import { toggleConsent } from 'store/person/tasks';
import { showFlashMessage } from 'store/flash_messages/tasks';
import history from 'router-history';
import companyHelper from 'shared_helpers/company_helper';
import { Dropdown, DropdownItem } from 'components/dropdown';
import ActionButton from 'components/action_button';
import Activities from 'components/activities';
import Icon from 'components/icon';
import IconStyled from 'components/icon_styled';
import Loading from 'components/loading';
import Popup from 'components/popup';
import Print from 'components/print';
import Tooltip from 'components/tooltip';
import WidgetFooter from 'components/widget_footer';
import WidgetHeader from 'components/widget_header';
import ScriptView from './script_view/script_view';
import AgileBoardPreviewDealType from './agile_board_preview_deal_type';
import Info from 'components/info/info';

/**
 * Render preview for a deal or a prospect.
 *
 * No real functionality here, just a lot of divs.
 *
 * @param state.props.id - string/ number - Deal id or prospect id.
 */
const AgileBoardPreview = (state) => {
  const [comment, setComment] = useState('');
  const [editPotential, setEditPotential] = useState(false);
  const [hideBlocks, setHideBlocks] = useState([]);
  const [lostReason, setLostReason] = useState('');
  const [previewData, setPreviewData] = useState(null);
  const [selectedPotential, setSelectedPotential] = useState(null);
  const [showLostDeal, setShowLostDeal] = useState(false);
  const [showRemoveItem, setShowRemoveItem] = useState(false);
  const [showWonDeal, setShowWonDeal] = useState(false);
  const [showScriptView, setShowScriptView] = useState(false);
  const agilePreviewRef = useRef(null);
  const removeDealInputRef = useRef(null);
  const lostDealInputRef = useRef(null);
  const wonDealInputRef = useRef(null);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    /**
     * Close preview when clicking outside.
     */
    const _closePreview = (e) => {
      if (miscHelper.clickUnmount(e, agilePreviewRef, false)) {
        const navigation = document.querySelector('.navigationWrapper');
        const breadCrumbs = document.querySelector(
          '.pageHeaderWrapper__pageHeader__left__breadcrumbs'
        );
        if (
          !(navigation?.contains(e.target) || breadCrumbs?.contains(e.target))
        ) {
          // When user navigates to another page, keep item in preview.
          setPreviewId(null);
        }
      }
    };

    window.addEventListener('mousedown', _closePreview);
    return () => window.removeEventListener('mousedown', _closePreview);
  }, []);

  useEffect(() => {
    if (state.props.id) {
      getPreviewData({
        id: state.props.id,
        getSettings: true,
      });
    }
  }, [state.props.id]);

  useEffect(() => {
    removeDealInputRef &&
      removeDealInputRef.current &&
      removeDealInputRef.current.focus();
  }, [showRemoveItem]);

  useEffect(() => {
    lostDealInputRef &&
      lostDealInputRef.current &&
      lostDealInputRef.current.focus();
  }, [showLostDeal]);

  useEffect(() => {
    wonDealInputRef &&
      wonDealInputRef.current &&
      wonDealInputRef.current.focus();
  }, [showWonDeal]);

  useEffect(() => {
    let data = JSON.parse(JSON.stringify(state.agile.previewData));

    if (data) {
      if (data.item) {
        setSelectedPotential(data.item.potential ? data.item.potential : '');
      }
    }

    setPreviewData(data);
  }, [state.agile.previewData]);

  const _markDealAsLost = async () => {
    let listId = null;
    if (previewData.item.meta && previewData.item.meta.moved_from_list) {
      listId = previewData.item.meta.moved_from_list;
    }

    let prospectIds;
    if (previewData.item.prospect_id) {
      prospectIds = [previewData.item.prospect_id];
    }

    setPreviewId(null);

    await moveItem({
      comment: comment,
      id: previewData.item.id,
      listId: listId,
      prospectIds: prospectIds,
      reason: lostReason,
      target: 'lost',
      source: previewData.item.phase,
    });

    setComment('');
    setLostReason('');
    showFlashMessage(tc.dealLostMessage, 'info');
  };

  const _markDealAsWon = async () => {
    let listId = null;
    if (previewData.item.meta && previewData.item.meta.moved_from_list) {
      listId = previewData.item.meta.moved_from_list;
    }

    let prospectIds;
    if (previewData.item.prospect_id) {
      prospectIds = [previewData.item.prospect_id];
    }

    setPreviewId(null);

    await moveItem({
      comment: comment,
      id: previewData.item.id,
      listId: listId,
      prospectIds: prospectIds,
      target: 'won',
      source: previewData.item.phase,
    });

    setComment('');
    showFlashMessage(tc.dealWonMessage, 'success');
  };

  const _removeItem = async () => {
    let listId = null;
    if (previewData.item.listId) {
      listId = previewData.item.listId;
    } else if (previewData.item.meta && previewData.item.meta.moved_from_list) {
      listId = previewData.item.meta.moved_from_list;
    }

    let prospectIds;
    if (previewData.item.prospect_id) {
      prospectIds = [previewData.item.prospect_id];
    }

    setPreviewId(null);

    await moveItem({
      id: previewData.item.id
        ? previewData.item.id
        : previewData.item.prospectId.toString(),
      comment: comment,
      listId: listId,
      prospectIds: prospectIds,
      target: 'trash',
      source: previewData.item.phase ? previewData.item.phase : 'prospect',
    });

    setComment('');
    showFlashMessage(tc.dealRemovedMessage, 'success');
  };

  const _renderActivitiesBlock = () => {
    return (
      <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block">
        <div
          className="agileBoardPreviewWrapper__agileBoardPreview__content__block__header"
          onClick={() => {
            if (hideBlocks.includes('activities')) {
              setHideBlocks(hideBlocks.filter((num) => num !== 'activities'));
            } else {
              setHideBlocks(hideBlocks.concat('activities'));
            }
          }}
        >
          <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__header__title">
            {previewData.item.type === 'prospect' ? (
              <p>{tc.previousActivityForProspect}</p>
            ) : (
              <p>{tc.activity}</p>
            )}
            {hideBlocks.includes('activities') ? (
              <Icon val="maximize" />
            ) : (
              <Icon val="minimize" />
            )}
          </div>
        </div>
        {!hideBlocks.includes('activities') ? (
          <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content">
            <Activities
              targetType={
                previewData?.item?.itemType === 'prospect' ? 'prospect' : 'deal'
              }
              target={state.props.id}
              view="embedded"
            />
          </div>
        ) : null}
      </div>
    );
  };

  const _renderActiveDealsBlock = () => {
    if (previewData.item?.activeDeals?.length) {
      return (
        <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block">
          <div
            className="agileBoardPreviewWrapper__agileBoardPreview__content__block__header"
            onClick={() => {
              if (hideBlocks.includes('activeDeals')) {
                setHideBlocks(
                  hideBlocks.filter((num) => num !== 'activeDeals')
                );
              } else {
                setHideBlocks(hideBlocks.concat('activeDeals'));
              }
            }}
          >
            <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__header__title">
              <p>
                {tc.active} {tc.deals.toLowerCase()}
              </p>
              {hideBlocks.includes('activeDeals') ? (
                <Icon val="maximize" />
              ) : (
                <Icon val="minimize" />
              )}
            </div>
          </div>
          {!hideBlocks.includes('activeDeals') ? (
            <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content">
              {previewData.item.activeDeals.map((num, i) => {
                return (
                  <div
                    className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__row"
                    key={i}
                  >
                    <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__row__left">
                      <IconStyled class="info" iconVal="agile" />
                      <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__row__left__content">
                        {num.ownDeal ? (
                          <>
                            {`${
                              tc.prospect
                            } ${tc.appearsIn.toLowerCase()} ${tc.ownDeal.toLowerCase()} ${tc.with.toLowerCase()}`}
                            <span className="marginLeft marginRight">
                              {tc.phase.toLowerCase()}
                            </span>
                            <span className="highlight">
                              {agileHelper.buildPhaseToColumnTitle(num.phase)}
                            </span>
                          </>
                        ) : (
                          <>
                            {`${
                              tc.prospect
                            } ${tc.appearsIn.toLowerCase()} ${tc.deal.toLowerCase()}`}
                            <span className="marginLeft marginRight">
                              {tc.at.toLowerCase()}
                            </span>
                            <span className="highlight marginRight">
                              {num.user?.name ? num.user.name : tc.unknown}
                            </span>
                            {`${tc.with.toLowerCase()} ${tc.phase.toLowerCase()}`}
                            <span className="highlight marginLeft">
                              {agileHelper.buildPhaseToColumnTitle(num.phase)}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      );
    }
  };

  const _renderCompleteDealBlock = () => {
    if (previewData.item.type === 'deal') {
      return (
        <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block">
          <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content">
            <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__completeDeal">
              <ActionButton
                class="completeDealAsLost"
                icon="lost"
                label={tc.lost}
                onClick={() => {
                  setComment('');
                  setShowLostDeal(true);
                }}
                type="regular"
              />
              <ActionButton
                class="completeDealAsWon"
                icon="won"
                label={tc.won}
                onClick={() => {
                  setComment('');
                  setShowWonDeal(true);
                }}
                type="regular"
              />
            </div>
          </div>
        </div>
      );
    }
  };

  const _renderContactsBlock = () => {
    if (!previewData?.contacts || !previewData.contacts?.length) {
      return null;
    }

    return (
      <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block">
        <div
          className="agileBoardPreviewWrapper__agileBoardPreview__content__block__header"
          onClick={() => {
            if (hideBlocks.includes('contacts')) {
              setHideBlocks(hideBlocks.filter((num) => num !== 'contacts'));
            } else {
              setHideBlocks(hideBlocks.concat('contacts'));
            }
          }}
        >
          <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__header__title">
            <p>{tc.contacts}</p>
            {hideBlocks.includes('contacts') ? (
              <Icon val="maximize" />
            ) : (
              <Icon val="minimize" />
            )}
          </div>
        </div>
        {!hideBlocks.includes('contacts') ? (
          <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content">
            {previewData.contacts.map((contact, i) => {
              return (
                <div
                  className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__contact"
                  key={i}
                >
                  <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__contact__content">
                    {contact.name?.length ? (
                      <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow">
                        <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__left">
                          {tc.name}:
                        </div>
                        <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__right">
                          <p className="capitalize">
                            {contact.name.toLowerCase()}
                          </p>
                        </div>
                      </div>
                    ) : null}
                    {contact.tele?.length ? (
                      <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow">
                        <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__left">
                          {tc.phone}:
                        </div>
                        <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__right">
                          {contact.tele.map((num, i) => {
                            if (i === contact.tele.length - 1) {
                              return (
                                <a key={num + i} href={'tel:+46' + num}>
                                  {num}
                                </a>
                              );
                            } else {
                              return (
                                <a key={num + i} href={'tel:+46' + num}>
                                  {num},
                                </a>
                              );
                            }
                          })}
                        </div>
                      </div>
                    ) : null}
                    {contact.email?.length ? (
                      <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow">
                        <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__left">
                          {tc.email}:
                        </div>
                        <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__right">
                          {contact.email.map((num, i) => {
                            if (i === contact.email.length - 1) {
                              return <p key={num + i}>{num}</p>;
                            } else {
                              return <p key={num + i}>{num}, </p>;
                            }
                          })}
                        </div>
                      </div>
                    ) : null}
                    {contact.comment?.length ? (
                      <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow">
                        <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__left">
                          {tc.comment}:
                        </div>
                        <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__right">
                          {contact.comment}
                        </div>
                      </div>
                    ) : null}
                    {contact.updated && contact.updated !== '-1' ? (
                      <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow">
                        <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__left">
                          {tc.updated}:
                        </div>
                        <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__right">
                          {moment(
                            new Date(contact.updated),
                            'DD/MM/YYYY'
                          ).fromNow()}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  };

  const _renderDealInfoBlock = () => {
    if (previewData.item.type === 'deal') {
      return (
        <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block">
          <div
            className="agileBoardPreviewWrapper__agileBoardPreview__content__block__header"
            onClick={() => {
              if (hideBlocks.includes('dealInfo')) {
                setHideBlocks(hideBlocks.filter((num) => num !== 'dealInfo'));
              } else {
                setHideBlocks(hideBlocks.concat('dealInfo'));
              }
            }}
          >
            <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__header__title">
              <p>{tc.deal}</p>
              {hideBlocks.includes('dealInfo') ? (
                <Icon val="maximize" />
              ) : (
                <Icon val="minimize" />
              )}
            </div>
          </div>
          {!hideBlocks.includes('dealInfo') ? (
            <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content">
              <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow">
                <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__left">
                  {tc.owner}:
                </div>
                <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__right">
                  {previewData.item.user?.name
                    ? previewData.item.user.name
                    : tc.unknown}
                </div>
              </div>
              {previewData.item.deal_source === 'shared' &&
              previewData.item.shared_deal_comment ? (
                <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow">
                  <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__left">
                    Kommentar:
                  </div>
                  <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__right">
                    {previewData.item.shared_deal_comment}
                  </div>
                </div>
              ) : null}

              <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow">
                <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__left">
                  {tc.belongsToList}:
                </div>
                <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__right">
                  {previewData.item.listName ? (
                    previewData.item.listName
                  ) : (
                    <Loading small={true} />
                  )}
                </div>
              </div>
              {previewData.item.description &&
              previewData.item.description.length ? (
                <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow">
                  <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__left">
                    {tc.description}:
                  </div>
                  <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__right">
                    {previewData.item.description}
                  </div>
                </div>
              ) : null}
              <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow">
                <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__left">
                  {tc.potential}:
                </div>
                <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__right noFlexWrap">
                  {editPotential ? (
                    <>
                      <input
                        onChange={(e) => {
                          let potential = selectedPotential;
                          if (Number.isFinite(+e.target.value)) {
                            // Make sure potential is a monetary value (int).
                            potential = +e.target.value;
                          }

                          setSelectedPotential(potential);
                        }}
                        type="text"
                        value={selectedPotential}
                      />
                      <Icon
                        val="clear"
                        onClick={() => {
                          setEditPotential(false);
                          setSelectedPotential(previewData.item.potential);
                        }}
                      />
                      <Icon
                        val="save"
                        onClick={async () => {
                          setEditPotential(false);
                          // Set value in state directly before retrieving updated data from backend, it's nicer for the user.
                          setPreviewData({
                            ...previewData,
                            item: {
                              ...previewData.item,
                              potential: selectedPotential,
                            },
                          });
                          await updateDeal({
                            id: previewData.item.id,
                            potential: +selectedPotential,
                          });
                          await getColumnsData({});
                          await getPreviewData({
                            id: state.props.id,
                            getSettings: true,
                          });
                        }}
                      />
                    </>
                  ) : (
                    <>
                      {previewData.item.potential}
                      <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__right__icons">
                        {previewData.item &&
                        previewData.prospectInformation &&
                        previewData.contacts &&
                        state.activities.activitiesByTarget ? (
                          // Wait for all data to be loaded to avoid accidental edit of wrong deal.
                          <Icon
                            val="edit"
                            onClick={() => {
                              setEditPotential(true);
                            }}
                          />
                        ) : null}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow">
                <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__left">
                  {tc.phase}:
                </div>
                <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__right">
                  {agileHelper.buildPhaseToColumnTitle(previewData.item.phase)}
                </div>
              </div>
              <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow">
                <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__left">
                  {tc.created}:
                </div>
                <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__right">
                  {moment(
                    new Date(previewData.item.created),
                    'DD/MM/YYYY'
                  ).format('LL HH:mm')}
                </div>
              </div>
              <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow">
                <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__left">
                  {tc.updated}:
                </div>
                <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__right">
                  {moment(
                    new Date(previewData.item.updated),
                    'DD/MM/YYYY'
                  ).format('LL HH:mm')}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      );
    }
  };

  const _renderEventsBlock = () => {
    if (previewData.item?.events?.length) {
      return (
        <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block">
          <div
            className="agileBoardPreviewWrapper__agileBoardPreview__content__block__header"
            onClick={() => {
              if (hideBlocks.includes('events')) {
                setHideBlocks(hideBlocks.filter((num) => num !== 'events'));
              } else {
                setHideBlocks(hideBlocks.concat('events'));
              }
            }}
          >
            <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__header__title">
              <p>{tc.events}</p>
              {hideBlocks.includes('events') ? (
                <Icon val="maximize" />
              ) : (
                <Icon val="minimize" />
              )}
            </div>
          </div>
          {!hideBlocks.includes('events') ? (
            <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content">
              {previewData.item.events.map((event) => {
                return (
                  <div
                    className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__row"
                    key={event.id}
                  >
                    <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__row__left">
                      <IconStyled
                        class={activityHelper.getEventClass(event.event_date)}
                        iconVal={event.action}
                      />
                      <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__row__left__content">
                        <span className="highlight marginRight">
                          {tc[event.action]}
                        </span>
                        {`${moment(
                          new Date(event.event_date),
                          'DD/MM/YYYY'
                        ).format('LL HH:mm')}`}
                        {event.comment ? (
                          <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__row__center__additionalLine">
                            <span className="lightAndItalic">
                              {event.comment}
                            </span>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__row__right">
                      {previewData.item &&
                      previewData.prospectInformation &&
                      previewData.contacts &&
                      state.activities.activitiesByTarget ? (
                        // This check is basically to avoid accidental removal of event in wrong deal,
                        // as well as to avoid for the event to be removed and then displayed a short second after removal when loading is finished.
                        <>
                          <Tooltip tooltipContent={tc.completeEvent}>
                            <IconStyled
                              class="link"
                              iconVal="complete"
                              onClick={async () => {
                                await completeEvent({
                                  action: event.action,
                                  comment: event.comment,
                                  dealId: previewData.item.id,
                                  eventId: event._id,
                                });
                                const cloned = JSON.parse(
                                  JSON.stringify(previewData)
                                );
                                // Remove event from previewData directly, no need to retrieve and wait for backend data.
                                if (Array.isArray(cloned?.item?.events)) {
                                  cloned.item.events =
                                    cloned.item.events.filter((num) => {
                                      return num._id !== event._id;
                                    });
                                }
                                setPreviewData(cloned);
                                await getColumnsData({});
                              }}
                            />
                          </Tooltip>
                          <Tooltip tooltipContent={tc.removeEvent}>
                            <IconStyled
                              class="link"
                              iconVal="remove"
                              onClick={async () => {
                                await removeEvent({
                                  dealId: previewData.item._id,
                                  eventId: event._id,
                                });
                                const cloned = JSON.parse(
                                  JSON.stringify(previewData)
                                );
                                // Remove event from state directly, no need to retrieve and wait for backend data.
                                if (Array.isArray(cloned?.item?.events)) {
                                  cloned.item.events =
                                    cloned.item.events.filter((num) => {
                                      return num._id !== event._id;
                                    });
                                }
                                setPreviewData(cloned);
                                await getColumnsData({});
                              }}
                            />
                          </Tooltip>
                        </>
                      ) : null}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      );
    }
  };

  const _renderFleetRows = (prospect) => {
    if (
      !prospect.fleet ||
      (typeof prospect.fleet === 'object' &&
        !Object.keys(prospect.fleet).length)
    ) {
      return (
        <Info>
          <p>{tc.noVehiclesAdjustTypes}</p>
        </Info>
      );
    }

    let typesArray = [];
    for (const prop in prospect.fleet) {
      typesArray.push(
        <div
          className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__prospectOrCar__content__fleetHolder__type"
          key={prop}
        >
          <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__prospectOrCar__content__fleetHolder__type__header">
            <span className="listBullet">&#8226;</span>
            <p>{tc[prop.toLowerCase()]}</p>
          </div>
          <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__prospectOrCar__content__fleetHolder__type__content">
            {Array.isArray(prospect?.fleet[prop]?.hits)
              ? prospect.fleet[prop].hits.map((vehicle, i) => {
                  if (i > 4) {
                    return null;
                  } else {
                    const dateAcquired = vehicle.date_acquired.toString();
                    const acquiredDateString =
                      dateAcquired.substring(0, 4) +
                      '-' +
                      dateAcquired.substring(4, 6) +
                      '-' +
                      dateAcquired.substring(6, 8);

                    return (
                      <div
                        className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__prospectOrCar__content__fleetHolder__type__content__vehicle"
                        key={vehicle.chassi}
                      >
                        <Tooltip tooltipContent={tc.navigateToCar}>
                          <span
                            className="highlight regNumber"
                            onClick={() => {
                              history.push('/fordon/' + vehicle.reg_number);
                            }}
                          >
                            {vehicle.reg_number}
                          </span>
                        </Tooltip>
                        <span className="capitalize marginRight smallerFont">{`${vehicle.brand.toLowerCase()} (${vehicle.model.toLowerCase()})`}</span>
                        <span className=" smallerFont">{`${moment(
                          new Date(),
                          'DD/MM/YYYY'
                        ).diff(
                          moment(new Date(acquiredDateString), 'DD/MM/YYYY'),
                          'months'
                        )} ${tc.monthsShort.toLowerCase()}`}</span>
                      </div>
                    );
                  }
                })
              : null}
            {prospect.fleet[prop].total > 5 ? (
              <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__prospectOrCar__content__fleetHolder__type__content__info">
                {`...${
                  prospect.fleet[prop].total - 5
                } ${tc.moreVehicles.toLowerCase()}`}
              </div>
            ) : null}
          </div>
        </div>
      );
    }

    return (
      <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__prospectOrCar__content__fleetHolder">
        {typesArray}
      </div>
    );
  };

  const _renderIcons = () => {
    // Old buttons
    //   {previewData.item.type === "deal" ? (
    //     <div
    //       className={
    //         previewData.item &&
    //         previewData.prospectInformation &&
    //         previewData.contacts &&
    //         state.activities.activitiesByTarget
    //           ? "agileBoardPreviewWrapper__agileBoardPreview__header__bottom__item"
    //           : "agileBoardPreviewWrapper__agileBoardPreview__header__bottom__item__disabled"
    //       }
    //     >
    //       <Tooltip tooltipContent={tc.won}>
    //         <Icon
    //           onClick={() => {
    //             setComment("");
    //             setShowWonDeal(true);
    //           }}
    //           val="won"
    //         />
    //       </Tooltip>
    //     </div>
    //   ) : null}
    //   {previewData.item.type === "deal" ? (
    //     <div
    //       className={
    //         previewData.item &&
    //         previewData.prospectInformation &&
    //         previewData.contacts &&
    //         state.activities.activitiesByTarget
    //           ? "agileBoardPreviewWrapper__agileBoardPreview__header__bottom__item"
    //           : "agileBoardPreviewWrapper__agileBoardPreview__header__bottom__item__disabled"
    //       }
    //     >
    //       <Tooltip tooltipContent={tc.lost}>
    //         <Icon
    //           onClick={() => {
    //             setComment("");
    //             setShowLostDeal(true);
    //           }}
    //           val="lost"
    //         />
    //       </Tooltip>
    //     </div>
    //   ) : null}
    return (
      <div className="agileBoardPreviewWrapper__agileBoardPreview__header__bottom">
        <div
          className={
            previewData.item &&
            previewData.prospectInformation &&
            previewData.contacts &&
            state.activities.activitiesByTarget
              ? 'agileBoardPreviewWrapper__agileBoardPreview__header__bottom__item'
              : 'agileBoardPreviewWrapper__agileBoardPreview__header__bottom__item__disabled'
          }
        >
          <Tooltip tooltipContent={tc.print}>
            <Icon
              onClick={() => {
                miscHelper.openPrint(previewData.item.name);
              }}
              val="print"
            />
          </Tooltip>
        </div>
        <div
          className={
            previewData.item &&
            previewData.prospectInformation &&
            previewData.contacts &&
            state.activities.activitiesByTarget
              ? 'agileBoardPreviewWrapper__agileBoardPreview__header__bottom__item'
              : 'agileBoardPreviewWrapper__agileBoardPreview__header__bottom__item__disabled'
          }
        >
          <Tooltip
            tooltipContent={
              previewData.item.type === 'prospect'
                ? tc.removeProspect
                : tc.removeDeal
            }
          >
            <Icon
              onClick={() => {
                setComment('');
                setShowRemoveItem(true);
              }}
              val="remove"
            />
          </Tooltip>
        </div>
        <div className="agileBoardPreviewWrapper__agileBoardPreview__header__bottom__item">
          {showScriptView ? null : (
            <Tooltip tooltipContent="Visa Manus" placement="top">
              <Icon val="script" onClick={() => setShowScriptView(true)} />
            </Tooltip>
          )}
        </div>
        {showScriptView ? (
          <ScriptView setShowScriptView={setShowScriptView} />
        ) : null}
      </div>
    );
  };

  const _renderLeadsInformationBlock = () => {
    if (
      !previewData.item.meta ||
      (previewData.item.meta && !previewData.item.meta.leads)
    ) {
      return null;
    }

    const rows = [];

    for (const prop in previewData.item.meta.leads) {
      if (
        previewData.item.meta.leads[prop] &&
        previewData.item.meta.leads[prop] !== '' &&
        agileHelper.getLeadsUploadColumns.concat(['createdAt']).includes(prop)
      ) {
        rows.push(
          <div
            className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow"
            key={prop}
          >
            <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__left">
              {tc[prop]}:
            </div>
            <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__right">
              {previewData.item.meta.leads[prop]
                ? prop === 'createdAt' ||
                  prop === 'expireDate' ||
                  prop === 'lastContact'
                  ? moment(
                      new Date(previewData.item.meta.leads[prop]),
                      'DD/MM/YYYY'
                    ).format('LL HH:mm')
                  : previewData.item.meta.leads[prop]
                : ''}
            </div>
          </div>
        );
      }
    }

    return (
      <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block">
        <div
          className="agileBoardPreviewWrapper__agileBoardPreview__content__block__header"
          onClick={() => {
            if (hideBlocks.includes('leadsInformation')) {
              setHideBlocks(
                hideBlocks.filter((num) => num !== 'leadsInformation')
              );
            } else {
              setHideBlocks(hideBlocks.concat('leadsInformation'));
            }
          }}
        >
          <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__header__title">
            <p>{tc.leadsInformation}</p>
            {hideBlocks.includes('leadsInformation') ? (
              <Icon val="maximize" />
            ) : (
              <Icon val="minimize" />
            )}
          </div>
        </div>
        {!hideBlocks.includes('leadsInformation') ? (
          <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content">
            {rows}
          </div>
        ) : null}
      </div>
    );
  };

  const _renderProspectInformationBlock = () => {
    if (
      previewData?.item?.type === 'deal' &&
      !previewData?.item?.prospect_id?.length
    ) {
      return null;
    }

    return Array.isArray(previewData.prospectInformation) ? (
      <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block">
        <div
          className="agileBoardPreviewWrapper__agileBoardPreview__content__block__header"
          onClick={() => {
            if (hideBlocks.includes('prospectInformation')) {
              setHideBlocks(
                hideBlocks.filter((num) => num !== 'prospectInformation')
              );
            } else {
              setHideBlocks(hideBlocks.concat('prospectInformation'));
            }
          }}
        >
          <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__header__title">
            <p>{tc.prospectInformation}</p>
            {hideBlocks.includes('prospectInformation') ? (
              <Icon val="maximize" />
            ) : (
              <Icon val="minimize" />
            )}
          </div>
        </div>
        {!hideBlocks.includes('prospectInformation') ? (
          <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content">
            {previewData.prospectInformation.map((prospect) => {
              if (!prospect || !prospect?.type) {
                return null;
              }

              return (
                <div
                  className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__prospectOrCar"
                  key={prospect.user_id}
                >
                  <Tooltip tooltipContent={tc.navigateToProspect}>
                    <div
                      className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__prospectOrCar__header"
                      onClick={() => {
                        if (prospect.type === 'company') {
                          history.push('/foretag/' + prospect.user_id);
                        } else {
                          history.push('/person/' + prospect.user_id);
                        }
                      }}
                    >
                      <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__prospectOrCar__header__left">
                        {prospect.type === 'company' ? (
                          <Icon val="company" />
                        ) : (
                          <Icon val="person" />
                        )}
                        <h5 className="capitalize">
                          {prospect.name.toLowerCase()}
                        </h5>
                      </div>
                      <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__prospectOrCar__header__right">
                        <Icon val="navigateNext" />
                      </div>
                    </div>
                  </Tooltip>
                  <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__prospectOrCar__content">
                    {previewData.item.type === 'prospect' ? (
                      <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow">
                        <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__left">
                          {tc.belongsToList}:
                        </div>
                        <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__right">
                          {previewData.item.listName}
                        </div>
                      </div>
                    ) : null}
                    {prospect.type === 'person' ? (
                      <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow">
                        <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__left">
                          {tc.age}:
                        </div>
                        <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__right">
                          {personHelper.getAgeString(prospect.birthYear)}
                        </div>
                      </div>
                    ) : null}
                    {companyHelper.isValidOrgNr(prospect.user_id) ? (
                      <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow">
                        <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__left">
                          {tc.orgNumberShort}:
                        </div>
                        <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__right capitalize">
                          {prospect.user_id}
                        </div>
                      </div>
                    ) : null}
                    {prospect.address &&
                    prospect.zip &&
                    prospect.zipMuncipality ? (
                      <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow">
                        <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__left">
                          {tc.address}:
                        </div>
                        <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__right capitalize">
                          {`${prospect.address.toLowerCase()}, ${
                            prospect.zip
                          },  ${prospect.zipMuncipality.toLowerCase()}`}
                        </div>
                      </div>
                    ) : null}
                    {prospect.phoneNumbers &&
                    Array.isArray(prospect.phoneNumbers) &&
                    prospect.phoneNumbers.length ? (
                      <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow">
                        <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__left">
                          {tc.phoneNumber}:
                        </div>
                        <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__right">
                          {prospect.phoneNumbers.map((num, i) => {
                            if (i === prospect.phoneNumbers.length - 1) {
                              return (
                                <a
                                  key={num.value + i}
                                  href={'tel:+46' + num.value}
                                >
                                  {num.value}
                                </a>
                              );
                            } else {
                              return (
                                <a
                                  key={num.value + i}
                                  href={'tel:+46' + num.value}
                                >
                                  {num.value},
                                </a>
                              );
                            }
                          })}
                        </div>
                      </div>
                    ) : null}
                    {prospect.emails &&
                    Array.isArray(prospect.emails) &&
                    prospect.emails.length ? (
                      <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow">
                        <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__left">
                          {tc.email}:
                        </div>
                        <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__right">
                          {prospect.emails.map((num, i) => {
                            if (i === prospect.emails.length - 1) {
                              return <span key={num.value}>{num.value}</span>;
                            } else {
                              return <span key={num.value}>{num.value}, </span>;
                            }
                          })}
                        </div>
                      </div>
                    ) : null}
                    {prospect.type === 'person' ? (
                      <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow">
                        <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__left">
                          {tc.gdprConsent}:
                        </div>
                        <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__right">
                          <Tooltip tooltipContent={tc.gdprConsentInfo}>
                            <Icon
                              onClick={async () => {
                                await toggleConsent({ id: prospect.user_id });
                                await getPreviewData({
                                  id: state.props.id,
                                  updateProspectInformation: true,
                                  getSettings: true,
                                });
                              }}
                              val={prospect.consent ? 'check' : 'checkbox'}
                            />
                          </Tooltip>
                        </div>
                      </div>
                    ) : null}
                    {_renderFleetRows(prospect)}
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    ) : (
      <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block">
        <Loading />
      </div>
    );
  };

  const _renderVehicleInfoBlock = () => {
    if (
      previewData?.item?.type === 'prospect' ||
      (previewData?.item?.type === 'deal' && !previewData?.item?.cars?.length)
    ) {
      return null;
    }

    return Array.isArray(previewData.vehicleInformation) ? (
      <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block">
        <div
          className="agileBoardPreviewWrapper__agileBoardPreview__content__block__header"
          onClick={() => {
            if (hideBlocks.includes('vehicleInfo')) {
              setHideBlocks(hideBlocks.filter((num) => num !== 'vehicleInfo'));
            } else {
              setHideBlocks(hideBlocks.concat('vehicleInfo'));
            }
          }}
        >
          <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__header__title">
            <p>{tc.vehicleInformation}</p>
            {hideBlocks.includes('vehicleInfo') ? (
              <Icon val="maximize" />
            ) : (
              <Icon val="minimize" />
            )}
          </div>
        </div>
        {!hideBlocks.includes('vehicleInfo') ? (
          <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content">
            {previewData.vehicleInformation.map((vehicle) => {
              if (!vehicle || !vehicle?.reg_number) {
                return null;
              }

              return (
                <div
                  className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__prospectOrCar"
                  key={vehicle.reg_number}
                >
                  <Tooltip tooltipContent={tc.navigateToCar}>
                    <div
                      className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__prospectOrCar__header"
                      onClick={() => {
                        history.push('/fordon/' + vehicle.reg_number);
                      }}
                    >
                      <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__prospectOrCar__header__left">
                        <Icon val="car" />
                        <h5 className="capitalize">{`${vehicle.brand} - ${vehicle.model} (${vehicle.reg_number})`}</h5>
                      </div>
                      <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__prospectOrCar__header__right">
                        <Icon val="navigateNext" />
                      </div>
                    </div>
                  </Tooltip>
                  <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__prospectOrCar__content">
                    <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow">
                      <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__left">
                        {tc.vehicleType}:
                      </div>
                      <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__right">
                        {tc[vehicle.type.toLowerCase()]}
                      </div>
                    </div>
                    <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow">
                      <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__left">
                        {tc.vehicleStatus}:
                      </div>
                      <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__right">
                        {vehicle.car_status}
                      </div>
                    </div>
                    <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow">
                      <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__left">
                        {tc.vehicleUsageType}:
                      </div>
                      <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__right">
                        {vehicle.usage}
                      </div>
                    </div>
                    <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow">
                      <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__left">
                        {tc.vehicleManufactured}:
                      </div>
                      <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__right">
                        {vehicle.date_car_year}
                      </div>
                    </div>
                    <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow">
                      <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__left">
                        {tc.vehicleRegistered}:
                      </div>
                      <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__right">
                        {vehicle.date_registered}
                      </div>
                    </div>
                    <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow">
                      <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__left">
                        {tc.fuel}:
                      </div>
                      <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__right">
                        {vehicle.fuel}
                      </div>
                    </div>
                    <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow">
                      <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__left">
                        {tc.climateClassification}:
                      </div>
                      <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__right">
                        {vehicle.climate_classification}
                      </div>
                    </div>
                    <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow">
                      <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__left">
                        {tc.climateClass}:
                      </div>
                      <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__right">
                        {vehicle.climate_class}
                      </div>
                    </div>
                    <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow">
                      <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__left">
                        {tc.engineStrengthHk}:
                      </div>
                      <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block__content__infoRow__right">
                        {vehicle.engine_strength}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    ) : (
      <div className="agileBoardPreviewWrapper__agileBoardPreview__content__block">
        <Loading />
      </div>
    );
  };

  const _stateCheck = () => {
    return !!(previewData && previewData.item);
  };

  return (
    <div className="agileBoardPreviewWrapper" ref={agilePreviewRef}>
      {_stateCheck() ? (
        <div className="agileBoardPreviewWrapper__agileBoardPreview">
          <div className="agileBoardPreviewWrapper__agileBoardPreview__header">
            <div className="agileBoardPreviewWrapper__agileBoardPreview__header__top">
              <div
                className="agileBoardPreviewWrapper__agileBoardPreview__header__top__left"
                onClick={() => {
                  if (previewData.item.type === 'deal') {
                    history.push('/affar/' + state.props.id);
                  } else if (companyHelper.isValidOrgNr(state.props.id)) {
                    history.push('/foretag/' + state.props.id);
                  } else {
                    history.push('/person/' + state.props.id);
                  }
                }}
              >
                <WidgetHeader
                  headline={previewData.item.name}
                  headlineSub={
                    <div>
                      {previewData.item.type === 'deal'
                        ? tc.deal
                        : tc.oneProspect}
                      {previewData.item.contractData?.length > 0 ? (
                        <div className="contractInfoWrapper">
                          <Icon val="infoOutlined" /> {tc.connectedContracts}
                          {previewData.item.contractData
                            .map((c) => c.contract_customer_id)
                            .join(', ')}
                        </div>
                      ) : null}
                    </div>
                  }
                />
              </div>
              <div
                className="agileBoardPreviewWrapper__agileBoardPreview__header__top__right"
                onClick={() => {
                  setPreviewId(null);
                }}
              >
                <Icon val="clear" />
              </div>
            </div>
            {_renderIcons()}
          </div>
          <div className="agileBoardPreviewWrapper__agileBoardPreview__content">
            {_renderCompleteDealBlock()}
            {previewData.item.type === 'deal' ? (
              <AgileBoardPreviewDealType
                dealId={previewData.item.id}
                hideBlocks={hideBlocks}
                setHideBlocks={setHideBlocks}
              />
            ) : null}
            {_renderEventsBlock()}
            {_renderActiveDealsBlock()}
            {_renderDealInfoBlock()}
            {_renderContactsBlock()}
            {_renderProspectInformationBlock()}
            {_renderVehicleInfoBlock()}
            {_renderLeadsInformationBlock()}
            {_renderActivitiesBlock()}
          </div>
          {showRemoveItem ? (
            <Popup
              close={() => {
                setShowRemoveItem(false);
              }}
              size="small"
            >
              <div className="agilePopupWrapper">
                <div className="agilePopupWrapper__agilePopup">
                  <div className="agilePopupWrapper__agilePopup__header">
                    <WidgetHeader headline={tc.removeDeal} />
                  </div>
                  <div className="agilePopupWrapper__agilePopup__content">
                    <h4>{tc.addComment}:</h4>
                    <textarea
                      placeholder={tc.comment}
                      onChange={(e) => {
                        setComment(e.target.value);
                      }}
                      ref={removeDealInputRef}
                      value={comment}
                    />
                  </div>
                </div>
                <div className="agilePopupWrapper__agilePopup__footer">
                  <WidgetFooter
                    buttonOneFunc={_removeItem}
                    buttonOneText={tc.remove}
                    buttonTwoFunc={() => {
                      setShowRemoveItem(false);
                    }}
                    buttonTwoText={tc.cancel}
                    noBorder={true}
                  />
                </div>
              </div>
            </Popup>
          ) : null}
          {showLostDeal ? (
            <Popup
              close={() => {
                setShowLostDeal(false);
                setLostReason('');
              }}
              size="small"
            >
              <div className="agilePopupWrapper">
                <div className="agilePopupWrapper__agilePopup">
                  <div className="agilePopupWrapper__agilePopup__header">
                    <WidgetHeader headline={tc.markDealAsLost} />
                  </div>
                  <div className="agilePopupWrapper__agilePopup__content">
                    <div className="agilePopupWrapper__agilePopup__content__row">
                      <h4>{tc.reason}:</h4>
                      <div className="agilePopupWrapper__agilePopup__content__row__items">
                        {agileHelper.getLostDealReasons.map((num) => {
                          return (
                            <ActionButton
                              active={lostReason === num}
                              key={num}
                              icon={num}
                              label={agileHelper.getLostDealReasonsReadable(
                                num
                              )}
                              onClick={() => {
                                setLostReason(num);
                              }}
                              type="regular"
                            />
                          );
                        })}
                      </div>
                    </div>
                    <div className="agilePopupWrapper__agilePopup__content__row">
                      <h4>{tc.addComment}:</h4>
                      <textarea
                        placeholder={tc.comment}
                        onChange={(e) => {
                          setComment(e.target.value);
                        }}
                        ref={lostDealInputRef}
                        value={comment}
                      />
                    </div>
                  </div>
                  <div className="agilePopupWrapper__agilePopup__footer">
                    <WidgetFooter
                      buttonOneFunc={_markDealAsLost}
                      buttonTwoFunc={() => {
                        setShowLostDeal(false);
                        setLostReason('');
                      }}
                      buttonTwoText={tc.cancel}
                      disableButtonOne={
                        !(
                          comment &&
                          comment.length > 2 &&
                          lostReason &&
                          lostReason.length
                        )
                      }
                      noBorder={true}
                    />
                  </div>
                </div>
              </div>
            </Popup>
          ) : null}
          {showWonDeal ? (
            <Popup
              close={() => {
                setShowWonDeal(false);
              }}
              size="small"
            >
              <div className="agilePopupWrapper">
                <div className="agilePopupWrapper__agilePopup">
                  <div className="agilePopupWrapper__agilePopup__header">
                    <WidgetHeader headline={tc.markDealAsWon} />
                  </div>
                  <div className="agilePopupWrapper__agilePopup__content">
                    <h4>{tc.addComment}:</h4>
                    <textarea
                      placeholder={tc.comment}
                      onChange={(e) => {
                        setComment(e.target.value);
                      }}
                      ref={wonDealInputRef}
                      value={comment}
                    />
                  </div>
                  <div className="agilePopupWrapper__agilePopup__footer">
                    <WidgetFooter
                      buttonOneFunc={_markDealAsWon}
                      buttonTwoFunc={() => {
                        setShowWonDeal(false);
                      }}
                      buttonTwoText={tc.cancel}
                      noBorder={true}
                    />
                  </div>
                </div>
              </div>
            </Popup>
          ) : null}
          {state.props.id && previewData ? (
            <Print
              activities={state.activities.activitiesByTarget}
              data={previewData}
              target={state.props.id}
              type="agileBoardPreview"
            />
          ) : null}
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

const MapStateToProps = (state, props) => {
  return {
    activities: state.activities,
    agile: state.agile,
    props: props,
  };
};

export default connect(MapStateToProps)(AgileBoardPreview);
