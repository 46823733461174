import React, { useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import LanguageContext from 'language-context';
import { agileHelper, dealHelper, miscHelper } from 'helpers';
import moment from 'moment';
import {
  getColumnsData,
  getPreviewData,
  moveItem,
  getPreviewProspectFleet,
  setPreviewId,
} from 'store/agile/tasks';
import { completeEvent, removeEvent } from 'store/events/tasks';
import { toggleConsent } from 'store/person/tasks';
import { showFlashMessage } from 'store/flash_messages/tasks';
import history from 'router-history';
import { DropdownItem, NewDropdown } from 'components/dropdown';
import ActionButton from 'components/action_button';
import Icon from 'components/icon';
import Loading from 'components/loading';
import Popup from 'components/popup';
import Print from 'components/print';
import Tooltip from 'components/tooltip';
import WidgetFooter from 'components/widget_footer';
import WidgetHeader from 'components/widget_header';
import ScriptView from '../script_view/script_view';
import AgileBoardPopupEvents from './agile_board_popup_events';
import AgileBoardPopupDealInfo from './agile_board_popup_deal_info';
import AgileBoardPopupCompleteDeal from './agile_board_popup_complete_deal';
import AgileBoardPopupActivities from './agile_board_popup_activities';
import AgileBoardPopupVehicleInfo from './agile_board_popup_vehicle_info';
import AgileBoardPopupDealTypeDropdown from './agile_board_popup_dealtype_dropdown';
import AgileBoardPopupAdditionalInfo from './agile_board_popup_additional_info';
import AgileBoardPopupTab from './agile_board_popup_tab';
import FoldableSection from './foldable_section';
import AgileBoardPopupActiveDeal from './agile_board_popup_active_deal';
import PopupNew from 'components/popup/popup_new';

/**
 * Render preview for a deal or a prospect.
 *
 * No real functionality here, just a lot of divs.
 *
 * @param state.props.id - string/ number - Deal id or prospect id.
 * @param state.previewData - string/ number - Deal id or prospect id.
 * @param state.setPreviewData- string/ number - Deal id or prospect id.
 */
const AgileBoardPopup = (state) => {
  const [comment, setComment] = useState('');
  const [editPotential, setEditPotential] = useState(false);
  const [hideBlocks, setHideBlocks] = useState([]);
  const [lostReason, setLostReason] = useState('');
  const [previewData, setPreviewData] = useState(null);
  const [selectedPotential, setSelectedPotential] = useState(null);
  const [showLostDeal, setShowLostDeal] = useState(false);
  const [showRemoveItem, setShowRemoveItem] = useState(false);
  const [showWonDeal, setShowWonDeal] = useState(false);
  const [showScriptView, setShowScriptView] = useState(false);
  const [visibleVehicles, setVisibleVehicles] = useState({});
  const [fleetData, setFleetData] = useState(null);
  const [activeTab, setActiveTab] = useState('prospect');

  const agilePreviewRef = useRef(null);
  const removeDealInputRef = useRef(null);
  const lostDealInputRef = useRef(null);
  const wonDealInputRef = useRef(null);
  const tc = useContext(LanguageContext);
  const isDeal = previewData?.item?.type === 'deal';
  const isProspect = previewData?.item?.type === 'prospect';
  const toggleSection = (section) => {
    if (hideBlocks.includes(section)) {
      setHideBlocks((blocks) => blocks.filter((block) => block !== section));
    } else {
      setHideBlocks((blocks) => blocks.concat(section));
    }
  };
  const focusPopup = () => {
    if (agilePreviewRef.current) {
      agilePreviewRef.current.focus();
    }
  };

  useEffect(() => {
    /**
     * Close preview when clicking outside.
     */
    const _closePreview = async (e) => {
      if (miscHelper.clickUnmount(e, agilePreviewRef, false)) {
        const navigation = document.querySelector('.navigationWrapper');
        const breadCrumbs = document.querySelector(
          '.pageHeaderWrapper__pageHeader__left__breadcrumbs'
        );
        if (
          !(navigation?.contains(e.target) || breadCrumbs?.contains(e.target))
        ) {
          // When user navigates to another page, keep item in preview.

          setPreviewId(null);
        }
      }
    };

    window.addEventListener('mousedown', _closePreview);
    return () => window.removeEventListener('mousedown', _closePreview);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (state.props.id) {
          await getPreviewData({
            id: state.props.id,
            getSettings: true,
          });

          const fleet = await getPreviewProspectFleet({
            id: state.props.id,
            getSettings: true,
          });
          setFleetData(fleet);
        }
      } catch (error) {
        console.error('Error fetching preview data:', error);
      }
    };

    fetchData();
  }, [state.props.id]);

  useEffect(() => {
    let data = state.agile.previewData;

    if (data) {
      if (data.item) {
        setSelectedPotential(data.item.potential ? data.item.potential : '');
      }
    }

    setPreviewData(data);
  }, [state.agile.previewData]);

  useEffect(() => {
    removeDealInputRef &&
      removeDealInputRef.current &&
      removeDealInputRef.current.focus();
  }, [showRemoveItem]);

  useEffect(() => {
    lostDealInputRef &&
      lostDealInputRef.current &&
      lostDealInputRef.current.focus();
  }, [showLostDeal]);

  useEffect(() => {
    wonDealInputRef &&
      wonDealInputRef.current &&
      wonDealInputRef.current.focus();
  }, [showWonDeal]);

  const _markDealAsLost = async () => {
    let listId = null;
    if (previewData.item.moved_from_list) {
      listId = previewData.item.moved_from_list;
    }

    let prospectIds;
    if (
      previewData.item.prospects &&
      Array.isArray(previewData.item.prospects)
    ) {
      prospectIds = previewData.item.prospects.join(',');
    }

    await moveItem({
      comment: comment,
      id: previewData.item.id,
      listId: listId,
      prospectIds: prospectIds,
      reason: lostReason,
      target: 'lost',
      source: previewData.item.phase,
    });

    setPreviewId(null);

    setComment('');
    setLostReason('');
    showFlashMessage(tc.dealLostMessage, 'info');
  };

  const _markDealAsWon = async () => {
    let listId = null;
    if (previewData.item.moved_from_list) {
      listId = previewData.item.moved_from_list;
    }

    let prospectIds;
    if (
      previewData.item.prospects &&
      Array.isArray(previewData.item.prospects)
    ) {
      prospectIds = previewData.item.prospects.join(',');
    }

    await moveItem({
      comment: comment,
      id: previewData.item.id,
      listId: listId,
      prospectIds: prospectIds,
      target: 'won',
      source: previewData.item.phase,
    });

    setPreviewId(null);

    setComment('');
    showFlashMessage(tc.dealWonMessage, 'success');
  };

  const removeItem = async () => {
    let listId = null;
    if (previewData.item.listId) {
      listId = previewData.item.listId;
    } else if (previewData.item.meta && previewData.item.meta.moved_from_list) {
      listId = previewData.item.meta.moved_from_list;
    }

    let prospectIds;
    if (
      previewData.item.prospects &&
      Array.isArray(previewData.item.prospects)
    ) {
      prospectIds = previewData.item.prospects.join(',');
    }

    setPreviewId(null);

    await moveItem({
      id: previewData.item.id
        ? previewData.item.id
        : previewData.item.prospectId.toString(),
      comment: comment,
      listId: listId,
      prospectIds: prospectIds,
      target: 'trash',
      source: previewData.item.phase ? previewData.item.phase : 'prospect',
      itemType: previewData.item.itemType,
    });

    setComment('');
    showFlashMessage(tc.dealRemovedMessage, 'success');
  };

  const renderFleetRows = (prospect) => {
    const initialVisibleCount = 2;
    const incrementCount = 2; // Adjust as needed for how many more vehicles to show each time.
    const maxVisibleVehicles = 10; // Maximum number of vehicles to show.

    const loadMoreVehicles = (type) => {
      setVisibleVehicles((prevVisibleVehicles) => {
        const currentCount = prevVisibleVehicles[type] ?? initialVisibleCount;
        // Ensure we do not exceed both the total available and the max visible limit.
        const newCount = Math.min(
          currentCount + incrementCount,
          prospect.fleet[type]?.total,
          maxVisibleVehicles
        );
        return { ...prevVisibleVehicles, [type]: newCount };
      });
    };

    const hideCars = (type) => {
      setVisibleVehicles((prevVisibleVehicles) => {
        const newVisibleVehicles = {
          ...prevVisibleVehicles,
          [type]: initialVisibleCount,
        };
        return newVisibleVehicles;
      });
    };

    if (
      !prospect.fleet ||
      (typeof prospect.fleet === 'object' &&
        !Object.keys(prospect.fleet).length)
    ) {
      return null;
    }

    let typesArray = [];
    for (const prop in prospect.fleet) {
      const visibleCount = visibleVehicles[prop] || 2;

      typesArray.push(
        <div
          className="agileBoardPopupWrapper__agilePopup__content__block__content__prospectOrCar__content__fleetHolder__type"
          key={prop}
        >
          <div className="agileBoardPopupWrapper__agilePopup__content__block__content__prospectOrCar__content__fleetHolder__type__header">
            <span className="listBullet">&#8226;</span>
            <p>{tc[prop.toLowerCase()]}</p>
          </div>
          <div className="agileBoardPopupWrapper__agilePopup__content__block__content__prospectOrCar__content__fleetHolder__type__content">
            {Array.isArray(prospect?.fleet[prop]?.hits)
              ? prospect.fleet[prop].hits.map((vehicle, i) => {
                  if (i >= visibleCount) {
                    return null;
                  } else {
                    const dateAcquired = vehicle.date_acquired.toString();
                    const acquiredDateString =
                      dateAcquired.substring(0, 4) +
                      '-' +
                      dateAcquired.substring(4, 6) +
                      '-' +
                      dateAcquired.substring(6, 8);

                    return (
                      <div
                        className="agileBoardPopupWrapper__agilePopup__content__block__content__prospectOrCar__content__fleetHolder__type__content__vehicle"
                        key={vehicle.chassi}
                      >
                        <Tooltip tooltipContent={tc.navigateToCar}>
                          <span
                            className="highlight regNumber"
                            onClick={() => {
                              history.push('/fordon/' + vehicle.reg_number);
                            }}
                          >
                            {vehicle.reg_number}
                          </span>
                        </Tooltip>
                        <span className="capitalize marginRight smallerFont">{`${vehicle.brand.toLowerCase()} (${vehicle.model.toLowerCase()})`}</span>
                        <span className=" smallerFont">{`${moment(
                          new Date(),
                          'DD/MM/YYYY'
                        ).diff(
                          moment(new Date(acquiredDateString), 'DD/MM/YYYY'),
                          'months'
                        )} ${tc.monthsShort.toLowerCase()}`}</span>
                      </div>
                    );
                  }
                })
              : null}
            {prospect.fleet[prop].total > visibleCount ? (
              <div className="agileBoardPopupWrapper__agilePopup__content__block__content__prospectOrCar__content__fleetHolder__type__content__info">
                {`...${
                  prospect.fleet[prop].total - visibleCount
                } ${tc.moreVehicles.toLowerCase()}`}
              </div>
            ) : null}
            <div className="agileBoardPopupWrapper__agilePopup__content__block__buttonContainer">
              {visibleCount < prospect.fleet[prop].hits.length && (
                <ActionButton
                  class="agileBoardPopupWrapper__agilePopup__content__block__button"
                  onClick={() => loadMoreVehicles(prop)}
                  icon="reset"
                  label={tc.getMore}
                  type="regular"
                />
              )}
              {visibleCount > 2 && ( // Condition to show the hide button
                <ActionButton
                  class="agileBoardPopupWrapper__agilePopup__content__block__button"
                  onClick={() => hideCars(prop)}
                  icon="maximizeUp"
                  label={tc.hide}
                  type="regular"
                />
              )}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="agileBoardPopupWrapper__agilePopup__content__block__content__prospectOrCar__content__fleetHolder">
        {typesArray}
      </div>
    );
  };

  const _renderIcons = () => {
    return (
      <div className="agileBoardPopupWrapper__agilePopup__header__bottom">
        <div
          className={
            previewData.item &&
            previewData.prospectInformation &&
            previewData.contacts &&
            state.activities.activitiesByTarget
              ? 'agileBoardPopupWrapper__agilePopup__header__bottom__item'
              : 'agileBoardPopupWrapper__agilePopup__header__bottom__item__disabled'
          }
        >
          {previewData.item &&
          previewData.prospectInformation &&
          previewData.contacts &&
          state.activities.activitiesByTarget ? (
            <Tooltip tooltipContent={tc.print}>
              <Icon
                onClick={() => {
                  miscHelper.openPrint(previewData.item.name);
                }}
                val="print"
              />
            </Tooltip>
          ) : (
            <Loading small={true} />
          )}
        </div>
        <div
          className={
            previewData.item &&
            previewData.prospectInformation &&
            previewData.contacts &&
            state.activities.activitiesByTarget
              ? 'agileBoardPopupWrapper__agilePopup__header__bottom__item'
              : 'agileBoardPopupWrapper__agilePopup__header__bottom__item__disabled'
          }
        >
          {previewData.item &&
          previewData.prospectInformation &&
          previewData.contacts &&
          state.activities.activitiesByTarget ? (
            <Tooltip
              tooltipContent={
                previewData.item.type === 'prospect'
                  ? tc.removeProspect
                  : tc.removeDeal
              }
            >
              <Icon
                onClick={() => {
                  setComment('');
                  setShowRemoveItem(true);
                }}
                val="remove"
              />
            </Tooltip>
          ) : (
            <Loading small={true} />
          )}
        </div>
        <div className="agileBoardPopupWrapper__agilePopup__header__bottom__item">
          {showScriptView ? null : (
            <Tooltip tooltipContent="Visa Manus" placement="top">
              <Icon val="script" onClick={() => setShowScriptView(true)} />
            </Tooltip>
          )}
        </div>
        {showScriptView ? (
          <PopupNew
            close={() => {
              setShowScriptView(false);
              focusPopup();
            }}
            small={true}
          >
            <ScriptView setShowScriptView={setShowScriptView} />
          </PopupNew>
        ) : null}
      </div>
    );
  };

  let prospectName = '';
  if (
    previewData &&
    previewData.prospectInformation &&
    previewData.prospectInformation.length > 0
  ) {
    prospectName = previewData.prospectInformation[0].name;
  }
  const filteredProspects = previewData?.prospectInformation?.filter(
    (prospect) => prospect && prospect.type
  );

  const hamburgerMenuItems = [
    prospectName
      ? {
          label:
            previewData && previewData.prospectInformation ? (
              prospectName.charAt(0).toUpperCase() + prospectName.slice(1)
            ) : (
              <Loading small={true} />
            ),
          onClick: () => {
            filteredProspects.forEach((filteredProspect) => {
              if (previewData && filteredProspect.type === 'company') {
                history.push('/foretag/' + filteredProspect.user_id);
              } else {
                previewData &&
                  history.push('/person/' + filteredProspect.user_id);
              }
            });
          },
        }
      : null,
    {
      label: tc.fleetReport,
      onClick: () => {
        history.push('/vagnpark/' + previewData?.item.prospects?.[0]);
      },
    },
  ];

  const _stateCheck = () => {
    return !!(previewData && previewData.item);
  };

  return (
    <PopupNew
      close={() => {
        setPreviewId(null);
      }}
    >
      <div className="agileBoardPopupWrapper" ref={agilePreviewRef}>
        {_stateCheck() ? (
          <div className="agileBoardPopupWrapper__agilePopup">
            <div className="agileBoardPopupWrapper__agilePopup__header">
              <div className="agileBoardPopupWrapper__agilePopup__header__top">
                <div className="agileBoardPopupWrapper__agilePopup__header__top__left">
                  <div
                    className="tooltip-container"
                    onClick={() => {
                      setPreviewId(null);
                    }}
                  >
                    <Tooltip tooltipContent={tc.close} placement={'right-end'}>
                      <Icon val="clear" />
                    </Tooltip>
                  </div>
                  <div>
                    <p className="deal-name">{previewData.item.name}</p>
                    <div className="row-container">
                      {isDeal ? (
                        <div>
                          <p>{tc.owner}: </p>
                          <p>
                            {previewData.item.user?.name
                              ? previewData.item.user.name
                              : tc.unknown}
                          </p>
                        </div>
                      ) : null}
                      {isDeal ? (
                        <div>
                          <p>{tc.phase}:</p>
                          <p>
                            {agileHelper.buildPhaseToColumnTitle(
                              previewData.item.phase
                            )}
                          </p>
                        </div>
                      ) : null}
                      {isDeal ? (
                        <div>
                          <p>{tc.typeOfDeal}:</p>
                          <AgileBoardPopupDealTypeDropdown
                            dealId={previewData.item.id}
                          />
                        </div>
                      ) : null}
                    </div>
                    {previewData.item.contractData?.length > 0 ? (
                      <div className="contractInfoWrapper">
                        <Icon val="infoOutlined" /> {tc.connectedContracts}
                        {previewData.item.contractData
                          .map((c) => c.contract_customer_id)
                          .join(', ')}
                      </div>
                    ) : null}
                  </div>
                  <div className="agileBoardPopupWrapper__agilePopup__content__deal__completeType">
                    <div className="agileBoardPopupWrapper__agilePopup__content__deal__container__center">
                      <AgileBoardPopupCompleteDeal
                        setComment={setComment}
                        previewData={previewData}
                        setShowWonDeal={setShowWonDeal}
                        setShowLostDeal={setShowLostDeal}
                      />
                    </div>
                    <div className="agileBoardPopupWrapper__agilePopup__content__center__flexEnd">
                      {_renderIcons()}
                      <NewDropdown
                        iconVal={'navigateNext'}
                        header={{
                          size: 'small',
                          label: '',
                          icon: 'menu',
                          noBorder: true,
                          transparent: true,
                        }}
                        align="bottom-right"
                        closeOnItemClick={false}
                      >
                        {hamburgerMenuItems
                          .filter((item) => item && item.label !== null)
                          .map((item, index) =>
                            item ? (
                              <DropdownItem
                                key={index}
                                label={item.label}
                                onClick={item.onClick}
                                hideCheckbox={true}
                              >
                                {item.content}
                              </DropdownItem>
                            ) : null
                          )}
                      </NewDropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="agile-tab-menu">
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  paddingBottom: '32px',
                }}
              >
                <button
                  className={`agile-tab-button${
                    activeTab === 'prospect' ? ' active' : ''
                  }`}
                  onClick={() => setActiveTab('prospect')}
                >
                  Prospekt
                </button>
                <button
                  className={`agile-tab-button${
                    activeTab === 'deal' ? ' active' : ''
                  }`}
                  onClick={() => setActiveTab('deal')}
                >
                  Affär
                </button>
                <button
                  className={`agile-tab-button${
                    activeTab === 'activity' ? ' active' : ''
                  }`}
                  onClick={() => setActiveTab('activity')}
                >
                  Historik
                </button>
              </div>
              <AgileBoardPopupTab active={activeTab === 'activity'}>
                <AgileBoardPopupActivities
                  hideBlocks={hideBlocks}
                  setHideBlocks={setHideBlocks}
                  previewData={previewData}
                />
              </AgileBoardPopupTab>
              <AgileBoardPopupTab active={activeTab === 'prospect'}>
                <FoldableSection
                  toggleSection={() => toggleSection('events')}
                  label={'Planerade aktiviteter'}
                  active={!hideBlocks.includes('events')}
                  renderNothing={!previewData?.item?.events?.length}
                >
                  <AgileBoardPopupEvents
                    hideBlocks={hideBlocks}
                    previewData={previewData}
                    setHideBlocks={setHideBlocks}
                    getColumnsData={getColumnsData}
                    completeEvent={completeEvent}
                    removeEvent={removeEvent}
                    setPreviewData={setPreviewData}
                  />
                </FoldableSection>
                <AgileBoardPopupAdditionalInfo
                  hideBlocks={hideBlocks}
                  previewData={previewData}
                  setHideBlocks={setHideBlocks}
                  toggleConsent={toggleConsent}
                  getPreviewData={getPreviewData}
                  id={state.props.id}
                  renderFleetRows={renderFleetRows}
                  fleetData={fleetData}
                />
              </AgileBoardPopupTab>
              <AgileBoardPopupTab active={activeTab === 'deal'}>
                <AgileBoardPopupDealInfo
                  hideBlocks={hideBlocks}
                  setHideBlocks={setHideBlocks}
                  getColumnsData={getColumnsData}
                  getPreviewData={getPreviewData}
                  previewData={previewData}
                  setPreviewData={setPreviewData}
                  editPotential={editPotential}
                  setEditPotential={setEditPotential}
                  selectedPotential={selectedPotential}
                  setSelectedPotential={setSelectedPotential}
                  toggleSection={() => toggleSection('dealInfo')}
                />
                <FoldableSection
                  active={!hideBlocks.includes('activeDeals')}
                  label={'Aktiva affärer'}
                  toggleSection={() => toggleSection('activeDeals')}
                >
                  <AgileBoardPopupActiveDeal
                    hideBlocks={hideBlocks}
                    setHideBlocks={setHideBlocks}
                    previewData={previewData}
                    disableHeader={true}
                  />
                </FoldableSection>
                {!(
                  previewData?.item?.type === 'prospect' ||
                  (previewData?.item?.type === 'deal' &&
                    !previewData?.item?.cars?.length)
                ) ? (
                  <FoldableSection
                    label={tc.vehicleInformation}
                    toggleSection={() => toggleSection('vehicleInformation')}
                    active={!hideBlocks.includes('vehicleInformation')}
                    renderNothing={
                      previewData?.item?.type === 'prospect' ||
                      (previewData?.item?.type === 'deal' &&
                        !previewData?.item?.cars?.length)
                    }
                  >
                    <AgileBoardPopupVehicleInfo
                      hideBlocks={hideBlocks}
                      setHideBlocks={setHideBlocks}
                      previewData={previewData}
                    />
                  </FoldableSection>
                ) : null}
              </AgileBoardPopupTab>
            </div>
            {showRemoveItem ? (
              <PopupNew
                close={() => {
                  setShowRemoveItem(false);
                  setPreviewId(null);
                }}
                mini={true}
              >
                <div className="paddingWrapper">
                  <div className="agilePopupWrapper">
                    <div className="agilePopupWrapper__agilePopup">
                      <div className="agilePopupWrapper__agilePopup__header">
                        <WidgetHeader headline={tc.removeDeal} />
                      </div>
                      <div className="agilePopupWrapper__agilePopup__content">
                        <h4>{tc.addComment}:</h4>
                        <textarea
                          placeholder={tc.comment}
                          onChange={(e) => {
                            setComment(e.target.value);
                          }}
                          ref={removeDealInputRef}
                          value={comment}
                        />
                      </div>
                    </div>
                    <div className="agilePopupWrapper__agilePopup__footer">
                      <WidgetFooter
                        buttonOneFunc={removeItem}
                        buttonOneText={tc.remove}
                        buttonTwoFunc={() => {
                          setShowRemoveItem(false);
                        }}
                        buttonTwoText={tc.cancel}
                        noBorder={true}
                      />
                    </div>
                  </div>
                </div>
              </PopupNew>
            ) : null}
            {showLostDeal ? (
              <PopupNew
                close={() => {
                  setShowLostDeal(false);
                  setLostReason('');
                }}
                mini={true}
              >
                <div className="agilePopupWrapper">
                  <div className="agilePopupWrapper__agilePopup">
                    <div className="agilePopupWrapper__agilePopup__header">
                      <WidgetHeader headline={tc.markDealAsLost} />
                    </div>
                    <div className="agilePopupWrapper__agilePopup__content">
                      <div className="agilePopupWrapper__agilePopup__content__row">
                        <h4>{tc.reason}:</h4>
                        <div className="agilePopupWrapper__agilePopup__content__row__items">
                          {agileHelper.getLostDealReasons.map((num) => {
                            return (
                              <ActionButton
                                active={lostReason === num}
                                key={num}
                                icon={num}
                                label={agileHelper.getLostDealReasonsReadable(
                                  num
                                )}
                                onClick={() => {
                                  setLostReason(num);
                                }}
                                type="regular"
                              />
                            );
                          })}
                        </div>
                      </div>
                      <div className="agilePopupWrapper__agilePopup__content__row">
                        <h4>{tc.addComment}:</h4>
                        <textarea
                          placeholder={tc.comment}
                          onChange={(e) => {
                            setComment(e.target.value);
                          }}
                          ref={lostDealInputRef}
                          value={comment}
                        />
                      </div>
                    </div>
                    <div className="agilePopupWrapper__agilePopup__footer">
                      <WidgetFooter
                        buttonOneFunc={_markDealAsLost}
                        buttonTwoFunc={() => {
                          setShowLostDeal(false);
                          setLostReason('');
                        }}
                        buttonTwoText={tc.cancel}
                        disableButtonOne={
                          !(
                            comment &&
                            comment.length > 2 &&
                            lostReason &&
                            lostReason.length
                          )
                        }
                        noBorder={true}
                      />
                    </div>
                  </div>
                </div>
              </PopupNew>
            ) : null}
            {showWonDeal ? (
              <PopupNew
                close={() => {
                  setShowWonDeal(false);
                }}
                mini={true}
              >
                <div className="agilePopupWrapper">
                  <div className="agilePopupWrapper__agilePopup">
                    <div className="agilePopupWrapper__agilePopup__header">
                      <WidgetHeader headline={tc.markDealAsWon} />
                    </div>
                    <div className="agilePopupWrapper__agilePopup__content">
                      <h4>{tc.addComment}:</h4>
                      <textarea
                        placeholder={tc.comment}
                        onChange={(e) => {
                          setComment(e.target.value);
                        }}
                        ref={wonDealInputRef}
                        value={comment}
                      />
                    </div>
                    <div className="agilePopupWrapper__agilePopup__footer">
                      <WidgetFooter
                        buttonOneFunc={_markDealAsWon}
                        buttonTwoFunc={() => {
                          setShowWonDeal(false);
                        }}
                        buttonTwoText={tc.cancel}
                        noBorder={true}
                      />
                    </div>
                  </div>
                </div>
              </PopupNew>
            ) : null}
            {state.props.id && previewData ? (
              <Print
                activities={state.activities.activitiesByTarget}
                data={previewData}
                target={state.props.id}
                type="agileBoardPreview"
              />
            ) : null}
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </PopupNew>
  );
};

const MapStateToProps = (state, props) => {
  return {
    activities: state.activities,
    agile: state.agile,
    props: props,
  };
};

export default connect(MapStateToProps)(AgileBoardPopup);
