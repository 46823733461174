import React from "react";

/**
 * Render a highlighted text.
 *
 * @param props.text - string
 */
const Highlight = (props) => {
  return (
    <span className="highlightWrapper">
      <span className="highlightWrapper__highlight">
        <span className="highlightWrapper__highlight__text">{props.text}</span>
      </span>
    </span>
  );
};

export default Highlight;
