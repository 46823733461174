import { userActionTypes } from "./actions";

interface UserState {
  colleagues: Array<object> | null; // Holds colleagues within the same dealer.
  connections: Array<object> | null; // Holds data if user has any connections to other dealers.
  dataIntegration: boolean; // If user has access to data integration data ("Har köpt av" in Prospektera). This is set on dealer level so we don't have it in the user.info object.
  info: object | null; // Holds SQL user info such as user id, user access, user email etc.
  mapControlId: null | string;
  moduleMode: boolean; // When Bilprospekt is displayed as a module inside iframe on another website (like bilvision.se).
  navigationStatus: "hidden" | "minimize" | "regular"; // Sets navigation to smaller, hidden or regular version. This is set based on multiple factors such as responsiveMode and moduleMode, so we cannot only go by navigation status in settings.
  responsiveMode: boolean; // When screen is so small we switch to responsive mode.
  settings: object | null; // Holds user settings from mongodb.
  settingsActivePage: string | null; // This is only for Settings component, not user settings.
  settingsActiveSection: string | null; // This is only for Settings component, not user settings.
  showCampaign: boolean; // Use however fitting when we have an active campaign.
  showCampaignInfo: boolean; // Use however fitting when we need to show campaign info popup or something.
  showSettings: boolean;
}

const initialState: UserState = {
  colleagues: null,
  connections: null,
  dataIntegration: false,
  info: null,
  mapControlId: null,
  moduleMode: false,
  navigationStatus: "regular",
  responsiveMode: false,
  settings: null,
  settingsActivePage: null,
  settingsActiveSection: null,
  showCampaign: false,
  showCampaignInfo: false,
  showSettings: false,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case userActionTypes.SET_USER_COLLEAGUES: {
      return {
        ...state,
        colleagues: action.payload,
      };
    }
    case userActionTypes.SET_USER_CONNECTIONS: {
      return {
        ...state,
        connections: action.payload,
      };
    }
    case userActionTypes.SET_USER_DATA_INTEGRATION: {
      return {
        ...state,
        dataIntegration: action.payload,
      };
    }
    case userActionTypes.SET_USER_INFO: {
      return {
        ...state,
        info: action.payload,
      };
    }
    case userActionTypes.SET_USER_LANG: {
      return {
        ...state,
        info: {
          ...state.info,
          lang: action.payload,
        },
      };
    }
    case userActionTypes.SET_USER_MODULE_MODE: {
      return {
        ...state,
        moduleMode: action.payload,
      };
    }
    case userActionTypes.SET_USER_NAVIGATION_STATUS: {
      return {
        ...state,
        navigationStatus: action.payload,
      };
    }
    case userActionTypes.SET_USER_RESPONSIVE_MODE: {
      return {
        ...state,
        responsiveMode: action.payload,
      };
    }
    case userActionTypes.SET_USER_MAP_CONTROL_ID: {
      return {
        ...state,
        mapControlId: action.payload,
      };
    }
    case userActionTypes.SET_USER_SETTINGS: {
      return {
        ...state,
        settings: action.payload,
      };
    }
    case userActionTypes.SET_USER_SETTINGS_ACTIVE_PAGE: {
      return {
        ...state,
        settingsActivePage: action.payload,
      };
    }
    case userActionTypes.SET_USER_SETTINGS_ACTIVE_SECTION: {
      return {
        ...state,
        settingsActiveSection: action.payload,
      };
    }
    case userActionTypes.SET_USER_SHOW_CAMPAIGN: {
      return {
        ...state,
        showCampaign: action.payload,
      };
    }
    case userActionTypes.SET_USER_SHOW_CAMPAIGN_INFO: {
      return {
        ...state,
        showCampaignInfo: action.payload,
      };
    }
    case userActionTypes.SET_USER_SHOW_SETTINGS: {
      return {
        ...state,
        showSettings: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
