import React, { useContext } from "react";
import LanguageContext from "language-context";
import Icon from "components/icon";

/**
 * props.label is the only thing that is crucial here.
 *
 * When using the extra stuff:
 * Should not use props.iconToTheRight when labelSub is provided, it's not supported in styles.
 * Use labelSub when menu type is "large", not supported in styling for other types.
 * Use iconToTheRight when menu type is "delimited", not supported in styling for other types.
 * The div with class "menuButtonWrapper__menuButton__active" is only displayed when menu type is "large" and button is active..
 */
const MenuButton = (props) => {
  const tc = useContext(LanguageContext);

  return props.type === "large" ? (
    <div className={`menuButtonWrapper ${props.disabled ? "disabled" : ""}`}>
      <div
        className={`menuButtonWrapper__menuButton ${
          props.iconToTheRight ? "iconToTheRight" : ""
        } ${props.disabled ? "disabled" : ""} ${props.active ? "active" : ""}`}
      >
        <div className="menuButtonWrapper__menuButton__icon">
          {props.icon ? <Icon val={props.icon} /> : null}
        </div>
        <div className="menuButtonWrapper__menuButton__info">
          <div className="menuButtonWrapper__menuButton__info__label">
            {props.label}
          </div>
          {props.labelSub ? (
            <div className="menuButtonWrapper__menuButton__info__labelSub">
              {props.labelSub}
            </div>
          ) : null}
        </div>
        <div
          className={`menuButtonWrapper__menuButton__button ${
            props.active ? "active" : ""
          }`}
          onClick={props.onClick}
        >
          {props.active ? (
            <>
              <Icon val="check" />
              <span>{tc.selected}</span>
            </>
          ) : (
            <span>{tc.select}</span>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div
      className={`menuButtonWrapper ${props.disabled ? "disabled" : ""}`}
      onClick={props.onClick}
    >
      <div
        className={`menuButtonWrapper__menuButton ${
          props.iconToTheRight ? "iconToTheRight" : ""
        } ${props.disabled ? "disabled" : ""} ${props.active ? "active" : ""}`}
      >
        {props.icon ? <Icon val={props.icon} /> : null}
        {props.label}
        {props.labelSub ? (
          <span className="labelSub">{props.labelSub}</span>
        ) : null}
      </div>
    </div>
  );
};

export default MenuButton;
