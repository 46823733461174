import React, { useContext, useEffect, useState, useRef } from 'react';
import LanguageContext from 'language-context';
import PageHeader from 'components/page_header';
import { Table } from 'components/table';
import moment from 'moment';
import { getExportsList } from 'store/pbi/tasks';
import Info from 'components/info';
import { connect } from 'react-redux';

const ReportsExport = ({ user }) => {
  const tc = useContext(LanguageContext);

  const [data, setData] = useState([]);

  const columns = [
    {
      id: 'filename',
      visible: true,
      numeric: false,
      label: tc.filename,
    },
    {
      id: 'status',
      visible: true,
      numeric: false,
      label: tc.leadsStatus,
    },
    {
      id: 'created',
      visible: true,
      numeric: false,
      label: tc.created,
    },
    {
      id: 'download',
      visible: true,
      numeric: false,
      label: tc.download,
    },
  ];

  const refreshExportsList = async () => {
    const data = await getExportsList(user.info.id);
    setData(data);
  };

  useEffect(() => {
    const fetchData = async () => {
      const exports = await getExportsList(user.info.id);
      setData(exports);
    };

    fetchData();
  }, []);

  const mappedData =
    data?.map((exportObj) => {
      const obj = {
        filename: exportObj.fileName,
        status:
          exportObj.status === 'Running'
            ? tc.IN_PROGRESS
            : tc.reportsExportAvaliable,
        created: moment(exportObj.date).format('YYYY-MM-DD HH:mm'),
        download:
          exportObj.status === 'Succeeded' ? (
            <a href={exportObj.link}>{tc.download}</a>
          ) : null,
      };

      return obj;
    }) || [];

  return (
    <div className="pbiEmbeddWrapper">
      <PageHeader headline={tc.reportsExport} />
      <div className="pbiEmbeddWrapper__pbiEmbedd">
        <div className="pbiEmbeddWrapper__pbiEmbedd__info">
          <div className="pbiEmbeddWrapper__pbiEmbedd__info__content">
            <Info inline={false}>
              <p>{tc.generatePdfDelay}</p>
              <p>
                {tc.exportsInfo1}
                <span
                  className="pbiEmbeddWrapper__pbiEmbedd__info__content__click"
                  onClick={() => refreshExportsList()}
                >
                  {tc.here}
                </span>
                {tc.exportsInfo2}
              </p>
            </Info>
          </div>
        </div>
        {data && <Table columns={columns} rows={mappedData} />}
      </div>
    </div>
  );
};

const MapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(MapStateToProps)(ReportsExport);
