import React, { useContext, useEffect, useState, useRef } from "react";
import LanguageContext from "language-context";
import TooltipComponent from 'components/tooltip';
import Icon from 'components/icon';
import {
  drillBack,
} from 'helpers/analysera_helper';

const DrillSelector = ({
  drillType,
  drillLevel,
  drillValues,
  title,
  subTitle,
  granularities,
  granularity,
  drillDownOptions,
  queries,
  setDrillLevel,
  setDrillValues,
  setQueries,
}) => {
  const tc = useContext(LanguageContext);

  if (drillType === 'time') {
    return (
      <>
        {new Array(drillLevel)
          .fill(0)
          .map((x, i) => i + 1)
          .map((x, i) => (
            <span 
              className={i  + 1 === drillLevel ? "highlight granularity" : "granularity"}
              key={`${title}${subTitle}drilldown${i}`}
              onClick={() => {
                drillBack(
                  false,
                  x,
                  { drillLevel, drillValues, queries },
                  { setDrillValues, setDrillLevel, setQueries },
                )
              }}
            >
              {tc[granularities[granularity + x]]}
            </span>
        ))}
        <TooltipComponent tooltipContent={tc.reset}>
          <Icon
            val="clear"
            onClick={() => drillBack(
              true,
              undefined,
              { drillLevel, drillValues, queries },
              { setDrillValues, setDrillLevel, setQueries },

            )}
            className="drilldownReset"
          />
        </TooltipComponent>
      </>
    )
  }
  
  if (drillType === 'dimension') {
    return (
      <>
        {new Array(drillLevel)
          .fill(0)
          .map((x, i) => i + 1)
          .map((x, i) => (
            <span 
              className={i + 1 === drillLevel ? "highlight granularity" : "granularity"}
              key={`${title}${subTitle}drilldown${i}`}
              onClick={() => {
                drillBack(
                  false,
                  x,
                  { drillLevel, drillValues, queries },
                  { setDrillValues, setDrillLevel, setQueries },
                )
              }}
            >
              {tc[drillDownOptions[granularity + x]?.dimension.split('.')[1]]}
            </span>
        ))}
        <TooltipComponent tooltipContent={tc.reset}>
          <Icon
            val="clear"
            onClick={() => drillBack(
              true,
              undefined,
              { drillLevel, drillValues, queries },
              { setDrillValues, setDrillLevel, setQueries },
            )}
            className="drilldownReset"
          />
        </TooltipComponent>
      </>
    )
  }
}

export default DrillSelector;
