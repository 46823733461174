import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import LanguageContext from "language-context";
import Icon from "components/icon";
import Tooltip from "components/tooltip";

/**
 * Render a user image.
 *
 * If no props provided, render image for logged in user.
 *
 * @param state.props.type - "pageHeader" | "settings" | "news" (optional) - When used in a particular circumstance. Default type is the one we display in Activities etc.
 * @param state.props.img - string (optional) - Image. If this is provided, provide name as well.
 * @param state.props.name - string (optional) - If image doesn't exist we render name initial. Provide the whole name not just the intial.
 */
const UserImage = (state) => {
  const [content, setContent] = useState(null);
  const tc = useContext(LanguageContext);
  const alphabet = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
    "å",
    "ä",
    "ö",
  ];

  useEffect(() => {
    // Generate color class dynamically.
    // We use both first and last letter so two people that starts with the same letter doesn't always get the same color.
    // The same person should always get the same color.
    let firstLetter = "A";
    let lastLetter = "U";
    if (state.props.name?.length) {
      firstLetter = state.props.name.charAt(0);
      lastLetter = state.props.name.charAt(state.props.name.length - 1);
    } else if (state.user.info?.name?.length) {
      firstLetter = state.user.info.name.charAt(0);
      lastLetter = state.user.info.name.charAt(state.user.info.name.length - 1);
    }

    let combined =
      alphabet.indexOf(firstLetter.toLowerCase()) +
      alphabet.indexOf(lastLetter.toLowerCase());
    if (combined > 28) {
      combined = combined / 2;
    }

    const valueExtraClass = Math.round(combined / 3);

    // Set content, image or span with name initial.
    if (state.props.type === "settings") {
      if (state.props.img?.length) {
        const el = <img src={state.props.img} alt={state.props.name} />;
        setContent(el);
      } else if (state.user.info?.img?.length) {
        const el = <img src={state.user.info.img} alt={state.props.name} />;
        setContent(el);
      } else {
        // When type === "settings", set an icon instead of name initial.
        const el = <Icon val="user" />;
        setContent(el);
      }
    } else {
      if (state.props.img?.length) {
        const el = <img src={state.props.img} alt={state.props.name} />;
        setContent(el);
      } else if (state.props.name?.length) {
        const el = (
          <span className={`initial color-${valueExtraClass}`}>
            {state.props.name.charAt(0)}
          </span>
        );
        setContent(el);
      } else if (state.user.info?.img?.length) {
        const el = <img src={state.user.info.img} alt={state.props.name} />;
        setContent(el);
      } else if (state.user.info?.name?.length) {
        const el = (
          <span className={`initial color-${valueExtraClass}`}>
            {state.user.info.name.charAt(0)}
          </span>
        );
        setContent(el);
      }
    }
  }, [state.props.img, state.props.name, state.user.info]);

  return (
    <div className="userImageWrapper">
      <div
        className={`userImageWrapper__userImage ${
          state.props.type ? state.props.type : ""
        }`}
      >
        <Tooltip
          tooltipContent={
            state.props.name?.length
              ? state.props.name
              : state.user.info?.name?.length
              ? state.user.info.name
              : tc.unknown
          }
        >
          {content}
        </Tooltip>
      </div>
    </div>
  );
};

const MapStateToProps = (state, props) => {
  return {
    props: props,
    user: state.user,
  };
};

export default connect(MapStateToProps)(UserImage);
