import Icon from 'components/icon';
import { addOrRemoveItemFromArray } from 'store/prospect/tasks';

const SearchRowSuggestion = ({ item, data, keyPath }) => {
  if (!item) return null;
  const selected = !!data.find(
    (x) =>
      (x.val === item.val || Number(x.val) === Number(item.val)) && x.active
  );

  return (
    <div
      className="prospectSearchRowWrapper__prospectSearchRow__content__right__searchHolder__left__inputActive__suggestionsHolder__itemWrapper"
      key={item.val}
      onClick={() => {
        addOrRemoveItemFromArray({
          keyPath: keyPath,
          item: item,
        });
      }}
    >
      <div className="prospectSearchRowWrapper__prospectSearchRow__content__right__searchHolder__left__inputActive__suggestionsHolder__itemWrapper__item">
        <div className="prospectSearchRowWrapper__prospectSearchRow__content__right__searchHolder__left__inputActive__suggestionsHolder__itemWrapper__item__left">
          {item.text}
          {item.val && ` (${item.val})`}
        </div>
        <div className="prospectSearchRowWrapper__prospectSearchRow__content__right__searchHolder__left__inputActive__suggestionsHolder__itemWrapper__item__right">
          <Icon val={selected ? 'check' : 'checkbox'} />
        </div>
      </div>
    </div>
  );
};

export default SearchRowSuggestion;
