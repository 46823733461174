import React, { useContext, useEffect, useState } from 'react';
import LanguageContext from 'language-context';
import { getLists, removeLists, updateListName } from 'store/lists/tasks';
import { setActiveOrderStage, setListToOrder } from 'store/orders/tasks';
import { showFlashMessage } from 'store/flash_messages/tasks';
import { getUserAccess } from 'store/user/tasks';
import { recreateSearch } from 'store/prospect/tasks';
import { connect } from 'react-redux';
import { tableHelper } from 'helpers';
import history from 'router-history';
import { Table } from 'components/table';
import ArchiveLists from './archive_lists';
import CreateListSubscription from 'components/create_list_subscription';
import Excel from 'components/excel';
import Info from 'components/info';
import Loading from 'components/loading';
import Menu from 'components/menu';
import MergeLists from './merge_lists';
import PageHeader from 'components/page_header';
import RemoveList from './remove_lists';
import ShareLists from './share_lists';
import SplitList from './split_list';

const ListsActive = (state) => {
  const [handleListsType, setHandleListsType] = useState('');
  const [selectedLists, setSelectedLists] = useState([]);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    getLists({
      orderInformation: true,
    });
  }, []);

  const _closePopup = () => {
    setHandleListsType('');
    setSelectedLists([]);
  };

  const _orderList = () => {
    setListToOrder(selectedLists[0]);
    setActiveOrderStage('listOrder');
    history.push('/listor/bestalla');
  };

  const _saveOnEdit = async (id, value, columnName) => {
    if (columnName === 'name') {
      return await updateListName({ listId: id, name: value });
    }
  };

  const _stateCheck = () => {
    return !!(state && state.lists && state.lists.lists !== null);
  };

  return (
    <div className="listsSubcomponentWrapper">
      <div className="listsSubcomponentWrapper__listsSubcomponent">
        <div className="listsSubcomponentWrapper__listsSubcomponent__header">
          <PageHeader
            headlineSub={
              _stateCheck()
                ? `${tc.total} ${
                    state.lists.lists.length
                  } ${tc.lists.toLowerCase()}`
                : null
            }
          />
        </div>
        {_stateCheck() ? (
          <div className="listsSubcomponentWrapper__listsSubcomponent__content">
            <div className="listsSubcomponentWrapper__listsSubcomponent__content__top">
              {state.lists.lists.length ? (
                <Menu
                  items={[
                    {
                      disabled: !selectedLists.length,
                      label:
                        selectedLists.length > 1
                          ? tc.archiveLists
                          : tc.archiveList,
                      onClick: () => {
                        setHandleListsType('archiveLists');
                      },
                      type: 'button',
                    },
                    {
                      disabled: !!(
                        !selectedLists.length ||
                        selectedLists.filter(
                          (list) =>
                            list.meta &&
                            list.meta.criterias &&
                            Object.keys(list.meta.criterias).length
                        ).length !== selectedLists.length ||
                        selectedLists.filter(
                          (list) => list.meta?.subscription_ids?.length
                        ).length
                      ),
                      label:
                        selectedLists.length > 1
                          ? tc.createListSubscriptions
                          : tc.createListSubscription,
                      onClick: () => {
                        setHandleListsType('createListSubscription');
                      },
                      type: 'button',
                    },
                    {
                      disabled: !(selectedLists.length === 1),
                      label: tc.orderList,
                      onClick: _orderList,
                      type: 'button',
                    },
                    !getUserAccess().blockExcel
                      ? {
                          disabled: !selectedLists.length,
                          label: tc.excelOutput,
                          onClick: () => {
                            let rows = 0;
                            selectedLists.forEach(
                              (num) => (rows = rows + num.size)
                            );
                            if (
                              getUserAccess().bigExcel ||
                              (!getUserAccess().bigExcel && rows < 5000)
                            ) {
                              setHandleListsType('excel');
                            } else {
                              showFlashMessage(tc.excelRowsLimit, 'info');
                            }
                          },
                          type: 'button',
                        }
                      : null,
                    {
                      disabled: selectedLists.length < 2,
                      label: tc.mergeLists,
                      onClick: () => {
                        setHandleListsType('mergeLists');
                      },
                      type: 'button',
                    },
                    {
                      disabled: selectedLists.length !== 1,
                      label:
                        selectedLists.length > 1 ? tc.shareLists : tc.shareList,
                      onClick: () => {
                        setHandleListsType('shareLists');
                      },
                      type: 'button',
                    },
                    {
                      disabled: selectedLists.length !== 1,
                      label: tc.splitList,
                      onClick: () => {
                        setHandleListsType('splitList');
                      },
                      type: 'button',
                    },
                    {
                      disabled: !selectedLists.length,
                      label:
                        selectedLists.length > 1
                          ? tc.removeLists
                          : tc.removeList,
                      onClick: () => {
                        setHandleListsType('removeLists');
                      },
                      type: 'button',
                    },
                    {
                      disabled: !!(
                        selectedLists.length !== 1 ||
                        selectedLists.filter(
                          (list) =>
                            list.meta &&
                            list.meta.criterias &&
                            Object.keys(list.meta.criterias).length
                        ).length !== 1
                      ),
                      label: tc.recreateCriterias,
                      onClick: () => {
                        const list = selectedLists.find(
                          (num) =>
                            num.meta.criterias &&
                            Object.keys(num.meta.criterias).length
                        );
                        recreateSearch(list.meta.criterias);
                      },
                      type: 'button',
                    },
                  ]}
                />
              ) : null}
            </div>
            <div className="listsSubcomponentWrapper__listsSubcomponent__content__bottom">
              {state.lists.lists.length ? (
                <Table
                  adjustToContainer={true}
                  columns={tableHelper.getListsColumns()}
                  onSelect={(arr) => {
                    setSelectedLists(
                      state.lists.lists.filter((list) => arr.includes(list.id))
                    );
                  }}
                  rows={tableHelper.getListsRows(state.lists.lists)}
                  saveOnEdit={_saveOnEdit}
                  selected={selectedLists.map((list) => list.id)}
                />
              ) : (
                <Info>
                  <h4>{tc.noLists}</h4>
                  <p>{tc.noListsWhy}</p>
                </Info>
              )}
            </div>
            {handleListsType === 'archiveLists' ? (
              <ArchiveLists close={_closePopup} selectedLists={selectedLists} />
            ) : null}
            {handleListsType === 'createListSubscription' ? (
              <CreateListSubscription
                close={_closePopup}
                lists={selectedLists}
              />
            ) : null}
            {handleListsType === 'excel' ? (
              <Excel
                close={_closePopup}
                selectedLists={selectedLists}
                origin="list"
              />
            ) : null}
            {handleListsType === 'removeLists' ? (
              <RemoveList close={_closePopup} selectedLists={selectedLists} />
            ) : null}
            {handleListsType === 'mergeLists' ? (
              <MergeLists close={_closePopup} selectedLists={selectedLists} />
            ) : null}
            {handleListsType === 'shareLists' ? (
              <ShareLists close={_closePopup} selectedList={selectedLists[0]} />
            ) : null}
            {handleListsType === 'splitList' ? (
              <SplitList close={_closePopup} selectedList={selectedLists[0]} />
            ) : null}
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

const MapStateToProps = (state) => {
  return {
    lists: state.lists,
  };
};

export default connect(MapStateToProps)(ListsActive);
