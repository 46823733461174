import React, { useContext, useState } from 'react';
import LanguageContext from 'language-context';
import { deleteRegionPreset, shareRegionPreset } from 'store/prospect/tasks';
import Info from 'components/info';
import Icon from 'components/icon';
import Tooltip from 'components/tooltip';
import { connect, useSelector } from 'react-redux';
import ActionButton from '../action_button';

/**
 * Postcode Preset component.
 *
 * Display existing postcode presets
 * Enable presets CRUD
 *
 */

const ProspectPostortPresets = ({ user }) => {
  const postortPresets = useSelector((state) => state.prospect.postortPresets);
  const [groupToRemove, setGroupToRemove] = useState(null);
  const tc = useContext(LanguageContext);

  const ButtonGroup = (props) => {
    return (
      <div className="postNummerTools">
        <Tooltip tooltipContent={tc.removeGroup}>
          <div className="pointer">
            <Icon
              val="remove"
              class={'mapEdit'}
              onClick={() =>
                setGroupToRemove({ _id: props._id, name: props.name })
              }
            />
          </div>
        </Tooltip>
        {!props.shared && (
          <Tooltip tooltipContent={tc.shareGroup}>
            <div className="pointer">
              <Icon
                val="share"
                class={'mapEdit'}
                onClick={() =>
                  shareRegionPreset({ _id: props._id, share: true })
                }
              />
            </div>
          </Tooltip>
        )}
      </div>
    );
  };

  const PresetItem = (props) => (
    <div className="itemPanel">
      <div className="detailPanel">
        <div className="detailPanel__text">
          <div className="detailPanel__text__name">
            <h4>{props.name}</h4>
          </div>
          <div className="detailPanel__text__description">
            {props.description}
          </div>
        </div>
        <ButtonGroup {...props} />
      </div>
    </div>
  );

  const _renderGroups = () =>
    postortPresets.map((preset) => <PresetItem {...preset} key={preset._id} />);

  return (
    <div className="prospectGroupsWrapper">
      <div className="prospectGroupsWrapper__prospectGroups">
        <div className="prospectGroupsWrapper__prospectGroups__content">
          <div className="prospectGroupsWrapper__prospectGroups__content__right">
            {!user.mapControlId && postortPresets?.length ? (
              <div className="prospectGroupsWrapper__prospectGroups__content__right__row">
                <p className="marginTopMedium marginBottomMedium">
                  {tc.groupsPostcodesInfo}
                </p>
              </div>
            ) : null}
            {postortPresets?.length ? (
              groupToRemove ? (
                <div className="inputHolder noMargin">
                  <div className="inputHolder__left">
                    {tc.removeGroup}: <strong>{groupToRemove.name}</strong>
                  </div>
                  <div className="inputHolder__right">
                    <ActionButton
                      label={tc.cancel}
                      onClick={() => {
                        setGroupToRemove(null);
                      }}
                      type="regular"
                    />
                    <ActionButton
                      label={tc.remove}
                      onClick={async () => {
                        await deleteRegionPreset({
                          _id: groupToRemove._id,
                        });
                        setGroupToRemove(null);
                      }}
                      type="highlight"
                    />
                  </div>
                </div>
              ) : (
                _renderGroups()
              )
            ) : (
              <Info>
                <h4>{tc.groupsMissing}</h4>
                <p>{tc.groupsPostcodesInfo}</p>
              </Info>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const MapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(MapStateToProps)(ProspectPostortPresets);
