import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import _ from "underscore";
import Collapsible from "components/collapsible";

/**
 * Sub section.
 *
 * @param state.props.minimize - If section should be open or not. Defaults to closed.
 */
const ProspectSearchSubSection = (state) => {
  const [minimize, setMinimize] = useState(true);

  let initial = useRef(true);

  useEffect(() => {
    if (!initial.current) return;
    // Check if content contains active values, if so open/show the section.
    if (Array.isArray(state.props.content) && state.prospect.searchQuery) {
      let foundActiveContent = false;
      state.props.content.forEach((num) => {
        if (num?.props?.keyPath) {
          let path;

          if (
            num.props.keyPath[1] &&
            (num.props.keyPath[1] === "privatePerson" ||
              num.props.keyPath[1] === "company")
          ) {
            // Since keyPath isn't always in the same exact format as the searchQuery we will probably
            // have a few special cases depending on how we choose to put together our sub sections.
            path = num.props.keyPath.slice(1);
          } else {
            path = num.props.keyPath;
          }

          const hasPath = _.get(state.prospect.searchQuery, path);
          if (hasPath) {
            foundActiveContent = true;
          }
        }
      });

      initial.current = false;
      setMinimize(!foundActiveContent && state.props.minimize !== false);
    }
  }, [state.props.content, state.prospect.searchQuery]);

  return state.props.content?.length ? (
    <div className="prospectSearchSubSectionWrapper">
      <div className="prospectSearchSubSectionWrapper__prospectSearchSubSection">
        <Collapsible
          content={
            <div className="prospectSearchSubSectionWrapper__prospectSearchSubSection__content">
              {state.props.content}
            </div>
          }
          header={state.props.header}
          minimized={minimize}
        />
      </div>
    </div>
  ) : null;
};

const MapStateToProps = (state, props) => {
  return {
    props: props,
    prospect: state.prospect,
  };
};

export default connect(MapStateToProps)(ProspectSearchSubSection);
