import React, { useState } from "react";
import imgSled from "images/bilprospekt_Julsläde.webp";
import imgLogo from "images/bilprospekt_logo_white.png";

export const Christmas = () => {
  const [loaded, setLoaded] = useState();

  return (
    <div className="christmasWrapper" style={loaded ? {} : { display: "none" }}>
      <div>
        <h1>God Jul</h1>
      </div>
      <img
        src={imgSled}
        alt="christmas sled by bilprospekt"
        className="christmasWrapper__sled"
        onLoad={() => {
          setLoaded(true);
        }}
      />
      <h2>önskar vi på</h2>
      <img src={imgLogo} alt="" className="christmasWrapper__logo" />
    </div>
  );
};

export default React.memo(Christmas);
