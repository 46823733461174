import { useContext, useState } from "react";
import LanguageContext from "language-context";
import ActionButton from "components/action_button";
import { showFlashMessage } from "store/flash_messages/tasks";

/**
 *
 * @param {{
 *  scripts: any[], activeScriptId: string | null,
 *  setActiveScriptId: (id) => void, setEditMode: (boolean) => void,
 *  handleSubmit: () => void, handleCancel: () => void,
 *  handleDelete: (scripts: any[], scriptId: string) => Promise<void>} } props
 * @description Renders a view where you can delete, edit and add scripts.
 */
const ScriptEditView = ({
  scripts,
  activeScriptId,
  setActiveScriptId,
  setEditMode,
  handleSubmit,
  handleCancel,
  handleDelete,
}) => {
  const tc = useContext(LanguageContext);
  const activeScript = scripts.find((script) => script.id === activeScriptId);
  const [editScript, setEditScript] = useState({
    id: activeScript?.id || null,
    title: activeScript?.title || "",
    text: activeScript?.text || "",
  });
  const [isDeleting, setIsDeleting] = useState(false);

  const handleChange = (e) => {
    if (!e.target || !e.target.name) return;
    setEditScript({ ...editScript, [e.target.name]: e.target.value });
  };

  return (
    <div className="scriptWrapper__edit">
      <div className="scriptWrapper__edit__textFields">
        <label>
          Title
          <input
            type="text"
            name="title"
            id="script-title"
            value={editScript?.title}
            onChange={handleChange}
            placeholder={tc.scriptTitlePlaceholder}
          />
        </label>
        <label htmlFor="script-text">Text</label>
        <textarea
          spellCheck={false}
          rows="4"
          name="text"
          id="script-text"
          value={editScript.text}
          onChange={handleChange}
          placeholder={tc.scriptTextPlaceholder}
        ></textarea>
      </div>
      <div className="scriptWrapper__edit__buttons">
        {!isDeleting ? (
          <>
            <ActionButton
              type="highlight"
              label={tc.save}
              onClick={() => {
                if (!editScript.title || !editScript.text) {
                  showFlashMessage(
                    "Var vänlig lägg till både rubrik och text.",
                    "info"
                  );
                  return;
                }
                handleSubmit({ ...editScript });
              }}
            />
            <ActionButton
              type="regular"
              label={tc.cancel}
              onClick={() => handleCancel()}
            />
            {activeScript ? (
              <ActionButton
                type="regular"
                label={tc.remove}
                onClick={() => setIsDeleting(true)}
              />
            ) : null}
          </>
        ) : (
          <>
            <div>
              <p>
                {tc.deleting}{" "}
                <span style={{ fontWeight: "900" }}>
                  {
                    scripts.find((script) => script.id === activeScriptId)
                      ?.title
                  }
                  .
                </span>
              </p>
              <p>{tc.ensure}</p>
            </div>
            <ActionButton
              type="highlight"
              label={tc.confirm}
              onClick={async () => {
                await handleDelete(editScript.id);
                setIsDeleting(false);
                setEditMode(false);
              }}
            />
            <ActionButton
              type="regular"
              label={tc.cancel}
              onClick={() => {
                setIsDeleting(false);
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ScriptEditView;
