import { fleetActionTypes } from "./actions";

interface fleetState {
  displayHistoric: boolean; // On "/vagnpark" page, display historic or current fleet.
  fleet: object | null;
  fleetAnalysis: object | null;
  fleetAnalysisHistoric: object | null;
  fleetHistoric: object | null;
}

const initialState: fleetState = {
  displayHistoric: false,
  fleet: null,
  fleetAnalysis: null,
  fleetAnalysisHistoric: null,
  fleetHistoric: null,
};

export const fleetReducer = (state = initialState, action) => {
  switch (action.type) {
    case fleetActionTypes.SET_FLEET: {
      return {
        ...state,
        fleet: action.payload,
      };
    }
    case fleetActionTypes.SET_FLEET_ANALYSIS: {
      return {
        ...state,
        fleetAnalysis: action.payload,
      };
    }
    case fleetActionTypes.SET_FLEET_ANALYSIS_HISTORIC: {
      return {
        ...state,
        fleetAnalysisHistoric: action.payload,
      };
    }
    case fleetActionTypes.SET_FLEET_DISPLAY_HISTORIC: {
      return {
        ...state,
        displayHistoric: action.payload,
      };
    }
    case fleetActionTypes.SET_FLEET_HISTORIC: {
      return {
        ...state,
        fleetHistoric: action.payload,
      };
    }
    case fleetActionTypes.SET_FLEET_HISTORIC_LOADING: {
      return {
        ...state,
        fleetHistoric: {
          ...state.fleetHistoric,
          loading: action.payload,
        },
      };
    }
    case fleetActionTypes.SET_FLEET_LOADING: {
      return {
        ...state,
        fleet: {
          ...state.fleet,
          loading: action.payload,
        },
      };
    }
    default: {
      return state;
    }
  }
};
