import { pbiActionTypes } from "./actions";

interface PbiState {
  reports: Array<object> | null;
  report: Object | null;
  orgNr: String | null;
}

const initialState: PbiState = {
  reports: null,
  report: null,
  orgNr: null,
};

export const pbiReducer = (state = initialState, action) => {
  switch (action.type) {
    case pbiActionTypes.PBI_SET_REPORTS: {
      return {
        ...state,
        reports: action.payload,
      };
    }
    case pbiActionTypes.PBI_SET_REPORT: {
      return {
        ...state,
        report: action.payload,
      };
    }
    case pbiActionTypes.PBI_SET_ORGNR: {
      return {
        ...state,
        orgNr: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
