import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

const PopupNew = ({
  children,
  close = () => {},
  small = false,
  mini = false,
}) => {
  const popupRef = useRef(null);
  useEffect(() => {
    if (popupRef.current) {
      popupRef.current.focus();
    }
  }, []);
  const content = (
    <div
      className={'popup-new-bg'}
      onClick={(e) => {
        close();
        e.stopPropagation();
      }}
      onKeyDown={(e) => {
        if (e.key === 'Escape') {
          close();
          e.stopPropagation();
        }
      }}
      tabIndex={-1}
      ref={popupRef}
    >
      <div
        className={
          'popup-new-content' + (small ? ' small' : mini ? ' mini' : '')
        }
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {children}
      </div>
    </div>
  );
  return createPortal(content, document.querySelector('body'));
};

export default PopupNew;
