import React, { useContext, useState } from 'react';
import LanguageContext from 'language-context';
import { createListOrder } from 'store/orders/tasks';
import costHelper from 'shared_helpers/cost_helper';
import Info from 'components/info';
import Loading from 'components/loading';
import WidgetFooter from 'components/widget_footer';
import WidgetHeader from 'components/widget_header';
import { miscHelper } from 'helpers';

const OrdersManagement = (props) => {
  const { list } = props;

  const haveCompanies =
    props.list.prospectInformation &&
    props.list.prospectInformation.companies &&
    props.list.prospectInformation.companies > 0;
  //const managementIsPending = list?.orderHistory?.company?.isPending;
  const orderIsPending = miscHelper.hasPendingOrder(list);
  const tc = useContext(LanguageContext);
  const [isOrdering, setIsOrdering] = useState(false);

  return props.list && props.list.prospectInformation ? (
    <div className="ordersInformationWrapper">
      <div className="ordersInformationWrapper__ordersInformation">
        <div className="ordersInformationWrapper__ordersInformation__content">
          {haveCompanies && !orderIsPending ? (
            <div className="ordersInformationWrapper__ordersInformation__content__section">
              <div className="ordersInformationWrapper__ordersInformation__content__section__title">
                <WidgetHeader headline={tc.orderManagement} />
              </div>
              <div className="ordersInformationWrapper__ordersInformation__content__section__block">
                <span className="label">{tc.deliveryTime}</span>
                <p>{tc.deliveryTime1}</p>
                <p>{tc.deliveryTime2}</p>
                <p>{tc.deliveryTime3}</p>
                <p>{tc.deliveryTime4}</p>
              </div>
              <div className="ordersInformationWrapper__ordersInformation__content__section__block">
                <span className="label">{tc.prices}</span>
                <p>
                  <span className="lineThrough">
                    {tc.priceStart}:{' '}
                    {costHelper.getStartCost(
                      props.list.prospectInformation.companies
                    )}{' '}
                    {tc.swedishCrowns.toLowerCase()}
                  </span>
                </p>
                <p>
                  {tc.priceManagement}:{' '}
                  {costHelper.getCostForCompany(
                    props.list.prospectInformation.companies
                  )}{' '}
                  {tc.swedishCrowns.toLowerCase()}
                </p>
                <p className="priceSum">
                  {tc.priceSum}:{' '}
                  <strong className="highlight">
                    {
                      // costHelper.getStartCost(props.list.prospectInformation.companies) +
                      costHelper.getCostForCompany(
                        props.list.prospectInformation.companies
                      )
                    }{' '}
                    {tc.swedishCrowns.toLowerCase()}
                  </strong>
                </p>
              </div>
            </div>
          ) : (
            <>
              {orderIsPending ? (
                <>
                  <Info>
                    <h4>{tc.orderIsPending}</h4>
                    <p>{tc.cannotOrderIsPending}</p>
                  </Info>
                </>
              ) : null}
              {!haveCompanies && !orderIsPending ? (
                <>
                  <Info>
                    <h4>{tc.noCompanies}</h4>
                    <p>{tc.cannotOrderNoCompanies}</p>
                  </Info>
                </>
              ) : null}
            </>
          )}
        </div>
        {haveCompanies && !orderIsPending ? (
          <div className="ordersInformationWrapper__ordersInformation__footer">
            <div className="minHeight40">
              {isOrdering ? (
                <>
                  <p>{tc.creatingOrder}...</p>
                  {isOrdering ? <Loading small={true} /> : null}
                </>
              ) : null}
            </div>
            <WidgetFooter
              buttonOneFunc={async () => {
                setIsOrdering(true);
                await createListOrder({
                  orderManagement: true,
                });
              }}
              disableButtonOne={isOrdering}
              buttonOneText={tc.placeOrder}
              noBorder={true}
            />
          </div>
        ) : null}
      </div>
    </div>
  ) : (
    <Loading />
  );
};

export default OrdersManagement;
