import { store } from 'store';
import { tableActionTypes } from './actions';

export const toggleFilter = (
  filter: { key: string; count: number },
  columnId: string
) => {
  const state = store.getState().table.activeFilters ?? {};
  const current = state[columnId] ?? [];
  if (current.map((option) => option.key).includes(filter.key)) {
    const filters = {
      ...state,
      [columnId]: current.filter((f) => f.key !== filter.key),
    };
    store.dispatch({
      type: tableActionTypes.TABLE_SET_ACTIVE_FILTERS,
      payload: {
        data: filters,
      },
    });
  } else {
    const filters = { ...state, [columnId]: current.concat(filter) };
    store.dispatch({
      type: tableActionTypes.TABLE_SET_ACTIVE_FILTERS,
      payload: {
        data: filters,
      },
    });
  }
};
export const resetColumnFilters = async (columnId: string) => {
  const state = store.getState().table.activeFilters ?? {};
  const newState = { ...state, [columnId]: [] };
  store.dispatch({
    type: tableActionTypes.TABLE_SET_ACTIVE_FILTERS,
    payload: { data: newState },
  });
  return newState;
};

export const resetActiveTableFilters = () => {
  store.dispatch({
    type: tableActionTypes.TABLE_SET_ACTIVE_FILTERS,
    payload: {
      data: {},
    },
  });
};

export const setIsInitialized = (bool) => {
  store.dispatch({
    type: tableActionTypes.TABLE_SET_IS_INITIALIZED,
    payload: { data: bool },
  });
};
