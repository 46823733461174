import React, { useContext } from "react";
import LanguageContext from "language-context";
import { updateGdpr } from "store/user/tasks";
import ActionButton from "components/action_button";
import PageHeader from "components/page_header";
import WidgetHeader from "components/widget_header";

/**
 * Render a page with our terms and conditions.
 */
const Gdpr = () => {
  const tc = useContext(LanguageContext);

  return (
    <div className="gdprWrapper">
      <div className="gdprWrapper__gdpr">
        <div className="gdprWrapper__gdpr__header">
          <PageHeader />
        </div>
        <div className="gdprWrapper__gdpr__content">
          <div className="gdprWrapper__gdpr__conten__top">
            <WidgetHeader
              headline={tc.gdprPageTitle}
              headlineSub={tc.gdprPageIngress}
            />
          </div>
          <div className="gdprWrapper__gdpr__content__bottom">
            <div className="gdprWrapper__gdpr__content__bottom__section">
              <h3>{tc.gdprPageSection1Title}</h3>
              <p>{tc.gdprPageSection1Paragraph1}</p>
              <p>{tc.gdprPageSection1Paragraph2}</p>
              <p>{tc.gdprPageSection1Paragraph3}</p>
            </div>
            <div className="gdprWrapper__gdpr__content__bottom__section">
              <h3>{tc.gdprPageSection2Title}</h3>
              <p>{tc.gdprPageSection2Paragraph1}</p>
              <p>{tc.gdprPageSection2Paragraph2}</p>
              <p>{tc.gdprPageSection2Paragraph3}</p>
            </div>
            <div className="gdprWrapper__gdpr__content__bottom__section">
              <h3>{tc.gdprPageSection3Title}</h3>
              <p>{tc.gdprPageSection3Paragraph1}</p>
            </div>
            <div className="gdprWrapper__gdpr__content__bottom__section">
              <h3>{tc.gdprPageSection4Title}</h3>
              <p>{tc.gdprPageSection4Paragraph1}</p>
            </div>
            <div className="gdprWrapper__gdpr__content__bottom__section">
              <h3>{tc.gdprPageSection5Title}</h3>
              <p>{tc.gdprPageSection5Paragraph1}</p>
              <p>{tc.gdprPageSection5Paragraph2}</p>
            </div>
            <div className="gdprWrapper__gdpr__content__bottom__section">
              <h3>{tc.gdprPageSection6Title}</h3>
              <p>{tc.gdprPageSection6Paragraph1}</p>
              <p>{tc.gdprPageSection6Paragraph2}</p>
            </div>
            <div className="gdprWrapper__gdpr__content__bottom__section">
              <h3>{tc.gdprPageSection7Title}</h3>
              <p>{tc.gdprPageSection7Paragraph1}</p>
              <p>{tc.gdprPageSection7Paragraph2}</p>
              <p className="subHeadline">
                {tc.gdprPageSection7Paragraph3SubParagraph1Title}
              </p>
              <p>{tc.gdprPageSection7Paragraph3SubParagraph1Paragraph1}</p>
              <p className="subHeadline">
                {tc.gdprPageSection7Paragraph3SubParagraph2Title}
              </p>
              <p>{tc.gdprPageSection7Paragraph3SubParagraph2Paragraph1}</p>
              <p className="subHeadline">
                {tc.gdprPageSection7Paragraph3SubParagraph3Title}
              </p>
              <p>{tc.gdprPageSection7Paragraph3SubParagraph3Paragraph1}</p>
              <p>{tc.gdprPageSection7Paragraph3SubParagraph3Paragraph2}</p>
              <p>{tc.gdprPageSection7Paragraph3SubParagraph3Paragraph3}</p>
              <p>{tc.gdprPageSection7Paragraph3SubParagraph3Paragraph4}</p>
            </div>
            <div className="gdprWrapper__gdpr__content__bottom__section">
              <h3>{tc.gdprPageSection8Title}</h3>
              <p>{tc.gdprPageSection8Paragraph1}</p>
              <p>{tc.gdprPageSection8Paragraph2}</p>
              <p>{tc.gdprPageSection8Paragraph3}</p>
              <p className="subHeadline">
                {tc.gdprPageSection8Paragraph4SubParagraph1Title}
              </p>
              <p>{tc.gdprPageSection8Paragraph4SubParagraph1Paragraph1}</p>
            </div>
            <div className="gdprWrapper__gdpr__content__bottom__section">
              <h3>{tc.gdprPageSection9Title}</h3>
              <p className="subHeadline">
                {tc.gdprPageSection9Paragraph1SubParagraph1Title}
              </p>
              <p>{tc.gdprPageSection9Paragraph1SubParagraph1Paragraph1}</p>
              <p className="subHeadline">
                {tc.gdprPageSection9Paragraph1SubParagraph2Title}
              </p>
              <p>{tc.gdprPageSection9Paragraph1SubParagraph2Paragraph1}</p>
              <p className="subHeadline">
                {tc.gdprPageSection9Paragraph1SubParagraph3Title}
              </p>
              <p>{tc.gdprPageSection9Paragraph1SubParagraph3Paragraph1}</p>
              <p className="subHeadline">
                {tc.gdprPageSection9Paragraph1SubParagraph4Title}
              </p>
              <p>{tc.gdprPageSection9Paragraph1SubParagraph4Paragraph1}</p>
              <p>{tc.gdprPageSection9Paragraph1SubParagraph4Paragraph2}</p>
              <p>{tc.gdprPageSection9Paragraph1SubParagraph4Paragraph3}</p>
              <p className="subHeadline">
                {tc.gdprPageSection9Paragraph1SubParagraph5Title}
              </p>
              <p>
                {tc.gdprPageSection9Paragraph1SubParagraph5Paragraph1}
                <br />
                {tc.gdprPageSection9Paragraph1SubParagraph5Paragraph2}
                <br />
                {tc.gdprPageSection9Paragraph1SubParagraph5Paragraph3}
              </p>
            </div>
          </div>
        </div>
        <div className="gdprWrapper__gdpr__footer">
          <div className="gdprWrapper__gdpr__footer__links">
            <a
              target="_blank"
              href="/files/terms/allmanna.villkor.190925.bp.pdf"
            >
              {tc.termsAndConditions}
            </a>
            <a
              target="_blank"
              href="/files/terms/informationssakerhetspolicy.bp.pdf"
            >
              {tc.informationSecurityPolicy}
            </a>
            <a
              target="_blank"
              href="/files/terms/personuppgiftspolicy.användare.180515.bp.pdf"
            >
              {tc.personalDataPolicy}
            </a>
          </div>
          <div className="gdprWrapper__gdpr__footer__accept">
            <ActionButton
              label={tc.iAccept}
              onClick={updateGdpr}
              type="highlight"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gdpr;
