import React, { useContext, useState } from "react";
import { connect } from "react-redux";
import LanguageContext from "language-context";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import LeadsUploadSelector from "./leads_upload_selector";
import WidgetFooter from "components/widget_footer";
import { submitLeads, resetUpload } from "store/leads/tasks";
import { agileHelper } from "helpers";
import Loading from "components/loading";

const LeadsuploadList = ({ leads, user, props }) => {
  const { reload, setShowUpload } = props;
  const tc = useContext(LanguageContext);

  // We add firstname and surname temporary here, users might have this in different columns.
  // We put these together as 'name' in backend before saving.
  const leadsProperties = agileHelper.getLeadsUploadColumns.concat([
    "firstName",
    "surName",
  ]);

  const mapArray = leadsProperties.map((prop) => {
    return [tc[prop], prop];
  });

  const dbMap = new Map(mapArray);

  const {
    leadsUploadColumns,
    leadsUploadRows,
    leadsUploadChoosenColumns,
    leadsUploadFilename,
    leadsUploadCount,
  } = leads.upload;

  const { dealer_id } = user.info;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    setIsSubmitting(true);

    const headers = leadsUploadChoosenColumns.map((header) =>
      header ? dbMap.get(header) : header
    );

    const submit = await submitLeads({
      headers,
      filename: leadsUploadFilename,
      dealer_id,
      type: "leads",
    });

    if (submit.success) {
      reload();
      setTimeout(() => {
        setShowUpload(false);
      }, 1000);
    }
  };

  const handleReset = () => {
    resetUpload();
  };

  return (
    <div className="leadsUploadWrapper__leadsUpload__content">
      {isSubmitting ? (
        <Loading />
      ) : (
        <>
          <p>
            {tc.leadsUploadListDescriptionOne}
            <span>{leadsUploadCount - 1}</span>
            {tc.leadsUploadListDescriptionTwo}
          </p>
          <p>{tc.leadsUploadSelectColumnNames}</p>
          <div className="leadsUploadWrapper__leadsUpload__content__tableWrapper">
            <TableContainer>
              <Table aria-label="simple table" size="small">
                <TableHead>
                  <TableRow>
                    {leadsUploadColumns.map((name, index) => (
                      <TableCell key={name + index}>{name}</TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    {leadsUploadColumns.map((name, index) => (
                      <TableCell key={"selector" + name + index} padding="none">
                        <LeadsUploadSelector index={index} />
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {leadsUploadRows.slice(0, 10).map((row, index) => (
                    <TableRow key={row.join("") + index}>
                      {row.map((column, index) => (
                        <TableCell key={row.join("") + column + index}>
                          <span>{column}</span>
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <WidgetFooter
            buttonOneFunc={handleSubmit}
            buttonTwoFunc={handleReset}
            buttonTwoText={tc.reset}
            disableButtonOne={isSubmitting}
          />
        </>
      )}
    </div>
  );
};

const MapStateToProps = (state, props) => {
  return {
    leads: state.leads,
    user: state.user,
    props: props,
  };
};

export default connect(MapStateToProps)(LeadsuploadList);
