import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import LanguageContext from 'language-context';
import {
  getListData,
  getListDataDebounced,
  moveProspects,
  removeProspectsFromList,
  resetListData,
} from 'store/list/tasks';
import { updateSettings } from 'store/user/tasks';
import { tableHelper } from 'helpers';
import { connect } from 'react-redux';
import { TablePropsManaged } from 'components/table';
import Loading from 'components/loading';
import Menu from 'components/menu';
import PageHeader from 'components/page_header';
import SaveToList from 'components/save_to_list';
import { getColumnOptions } from 'store/fleet/tasks';
import { resetActiveTableFilters } from 'store/table/tasks';

/**
 * Display a list.
 * Get data based on url path param id.
 * Can display a table of prospects or vehicles.
 */
const List = (state) => {
  const tc = useContext(LanguageContext);
  const { id } = useParams();
  const [displayMode, setDisplayMode] = useState('prospects');
  const [headlineSub, setHeadlineSub] = useState(
    <Loading small={true} color="white" />
  );
  const [listName, setListName] = useState(
    `${tc.loading} ${tc.list.toLowerCase()}`
  );
  const [query, setQuery] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showMoveProspects, setShowMoveProspects] = useState(false);
  const [sort, setSort] = useState({
    order: 'asc',
    orderBy: '',
  });

  const [isApplyingFilters, setIsApplyingFilters] = useState(false);
  const firstLoad = useRef(true);
  const vehicleSettings = useMemo(() => {
    return state.user?.settings?.settings.prospect.vehicleTypes ?? {};
  }, [state.user?.settings?.settings.prospect.vehicleTypes]);
  useEffect(() => {
    return () => resetListData();
  }, []);

  useEffect(() => {
    const buildActiveFilters = () => {
      if (displayMode !== 'cars') return {};
      if (firstLoad.current || !state.table.isInitialized) {
        const result = {
          type: Object.entries(vehicleSettings).reduce(
            (acc, [vehicleType, active]) => {
              if (active) return acc.concat({ key: vehicleType });
              else return acc;
            },
            []
          ),
        };
        return result;
      } else {
        return state.activeFilters;
      }
    };
    if (query && query.length) {
      getListDataDebounced({
        displayMode: displayMode,
        listId: id,
        page: page,
        rowsPerPage: rowsPerPage,
        searchQuery: query,
        sort: sort,
        activeFilters: buildActiveFilters(),
      });
    } else {
      getListDataDebounced({
        displayMode: displayMode,
        listId: id,
        page: page,
        rowsPerPage: rowsPerPage,
        sort: sort,
        activeFilters: buildActiveFilters(),
      });
    }
    firstLoad.current = false;
  }, [
    displayMode,
    id,
    page,
    rowsPerPage,
    query,
    sort,
    state.activeFilters,
    vehicleSettings,
    state.table.isInitialized,
  ]);

  useEffect(() => {
    if (
      state.list.data &&
      state.list.data.hasOwnProperty('total') &&
      state.list.data.total.hasOwnProperty('total')
    ) {
      setHeadlineSub(
        `${tc.total} ${state.list.data.total.total} ${
          displayMode === 'prospects'
            ? tc.oneProspect.toLowerCase()
            : tc.vehicle.toLowerCase()
        }`
      );
      setListName(state.list.data.name);
    } else {
      setListName(`${tc.loading} ${tc.list.toLowerCase()}`);
      setHeadlineSub(null);
    }
  }, [state.list.data, displayMode]);

  const reloadListData = async () => {
    setIsApplyingFilters(true);
    await getListData({
      activeFilters: state.activeFilters,
      displayMode: displayMode,
      listId: id,
      page: page,
      rowsPerPage: rowsPerPage,
      sort: sort,
    });
    setIsApplyingFilters(false);
  };

  const _moveProspects = async (payload) => {
    // Either we get back a name string or an array of list ids from saveToList component.
    if (payload.lists && Array.isArray(payload.lists)) {
      await moveProspects({
        fromList: id,
        ids: selectedRows.map((num) => num.user_id),
        listIds: payload.lists,
        mode: 'savedLists',
      });
    } else if (payload.name) {
      await moveProspects({
        fromList: id,
        ids: selectedRows.map((num) => num.user_id),
        listName: payload.name,
        mode: 'newList',
      });
    }

    setSelectedRows([]);
    setShowMoveProspects(false);
    resetListData();
    getListDataDebounced({
      displayMode: displayMode,
      listId: id,
      page: page,
      rowsPerPage: rowsPerPage,
      sort: sort,
    });
  };

  const _removeProspectsFromList = async () => {
    await removeProspectsFromList({
      ids: selectedRows.map((num) => num.user_id),
      listId: id,
    });
    setSelectedRows([]);
    resetListData();
    getListDataDebounced({
      displayMode: displayMode,
      listId: id,
      page: page,
      rowsPerPage: rowsPerPage,
      sort: sort,
    });
  };

  const _stateCheck = () => {
    if (
      state.list.data &&
      state.list.data.total &&
      state.list.data.data &&
      Array.isArray(state.list.data.data)
    ) {
      if (state.list.data.data[0]) {
        if (displayMode === 'cars') {
          return !!state.list.data.data[0].reg_number;
        } else if (displayMode === 'prospects') {
          return !!state.list.data.data[0].user_id;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  return (
    <div className="listWrapper">
      <div className="listWrapper__list">
        <div className="listWrapper__list__header">
          <PageHeader headline={listName} headlineSub={headlineSub} />
        </div>
        <div className="listWrapper__list__content">
          <div className="listWrapper__list__content__top">
            {state.list?.data ? (
              state.list.data.type === 'contractCustomer' ? null : (
                <Menu
                  items={[
                    {
                      disabled: !selectedRows.length,
                      label: tc.removeProspectsFromList,
                      onClick: _removeProspectsFromList,
                      type: 'button',
                    },
                    {
                      disabled: !selectedRows.length,
                      label: tc.moveProspects,
                      onClick: () => {
                        setShowMoveProspects(true);
                      },
                      type: 'button',
                    },
                  ]}
                />
              )
            ) : (
              <Loading />
            )}
          </div>
          <div className="listWrapper__list__content__bottom">
            {_stateCheck() ? (
              <>
                <div className="listWrapper__list__content__bottom__menu">
                  <Menu
                    skipSort={true}
                    centered={true}
                    type="floatFree"
                    items={[
                      {
                        active: displayMode === 'prospects',
                        label: tc.prospectsView,
                        onClick: () => {
                          setDisplayMode('prospects');
                          setSelectedRows([]);
                          resetActiveTableFilters();
                        },
                        type: 'button',
                      },
                      {
                        active: displayMode === 'cars',
                        label: tc.vehiclesView,
                        onClick: () => {
                          setDisplayMode('cars');
                          setSelectedRows([]);
                          resetActiveTableFilters();
                        },
                        type: 'button',
                      },
                    ]}
                  />
                </div>
                {isApplyingFilters ? (
                  <div>
                    {' '}
                    <Loading />{' '}
                  </div>
                ) : null}
                <TablePropsManaged
                  isApplyingFilters={isApplyingFilters}
                  optionsInitialized={state.table.isInitialized}
                  reloadData={reloadListData}
                  getColumnOptions={async (
                    columns,
                    activeFilters,
                    activeSearch
                  ) => {
                    if (!columns) return [];
                    const { listId } = state.list.data;
                    const { type, dealerId, ...listCriterias } =
                      state.list.data.query;
                    return await getColumnOptions({
                      columns,
                      listCriterias,
                      listId,
                      type,
                      activeFilters,
                      activeSearch,
                    });
                  }}
                  vehicleSettings={vehicleSettings}
                  adjustToContainer={true}
                  allowColumnFilters={
                    displayMode === 'prospects' ? false : true
                  }
                  activeFilters={state.activeFilters}
                  columns={
                    displayMode === 'prospects'
                      ? tableHelper.getProspectColumns({
                          activeColumns:
                            state.user?.settings?.settings?.tables?.prospects
                              ?.columns,
                          numberOfMatchingCars: true,
                        })
                      : tableHelper.getVehicleColumns({
                          activeColumns:
                            state.user?.settings?.settings?.tables?.vehicles
                              ?.columns,
                          prospectPreview: false,
                        })
                  }
                  onColumnsChange={(columns) => {
                    if (state.user?.settings?.settings?.tables) {
                      const type =
                        displayMode === 'prospects' ? 'prospects' : 'vehicles';

                      let settings = {
                        ...state.user.settings.settings,
                        tables: {
                          ...state.user.settings.settings.tables,
                          [type]: {
                            ...state.user.settings.settings.tables[type],
                            columns: columns,
                          },
                        },
                      };

                      updateSettings({
                        settings: settings,
                      });
                    }
                  }}
                  onSelect={(arr) => {
                    if (displayMode === 'prospects') {
                      setSelectedRows(
                        state.list.data.data.filter((num) =>
                          arr.includes(num.user_id)
                        )
                      );
                    } else if (displayMode === 'cars') {
                      setSelectedRows(
                        state.list.data.data.filter((num) =>
                          arr.includes(num.reg_number)
                        )
                      );
                    }
                  }}
                  page={page}
                  query={query}
                  rows={
                    displayMode === 'prospects'
                      ? tableHelper.getProspectRows({
                          listId: state.list.data.listId,
                          numberOfMatchingCars: true,
                          rows: state.list?.data?.data?.length
                            ? state.list.data.data
                            : [],
                        })
                      : tableHelper.getVehicleRows({
                          rows: state.list?.data?.data?.length
                            ? state.list.data.data
                            : [],
                          historic: false,
                          koncern: false,
                        })
                  }
                  rowsPerPage={rowsPerPage}
                  order={sort.order}
                  orderBy={sort.orderBy}
                  pageChange={(newPage) => {
                    setPage(newPage);
                  }}
                  rowsPerPageChange={(val) => {
                    setRowsPerPage(val);
                  }}
                  search={(newQuery) => {
                    setQuery(newQuery);
                  }}
                  selected={
                    displayMode === 'prospects'
                      ? selectedRows.map((num) => num.user_id)
                      : selectedRows.map((num) => num.reg_number)
                  }
                  sort={(newSort) => {
                    setSort(newSort);
                  }}
                  total={state.list.data.total.total}
                />
              </>
            ) : (
              <Loading />
            )}
          </div>
          {showMoveProspects ? (
            <SaveToList
              close={() => {
                setShowMoveProspects(false);
              }}
              save={_moveProspects}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

const MapStateToProps = (state) => {
  return {
    list: state.list,
    user: state.user,
    table: state.table,
    activeFilters: state.table.activeFilters,
  };
};

export default connect(MapStateToProps)(List);
