import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import LanguageContext from 'language-context';
import history from 'router-history';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import { Dropdown, DropdownItem } from '../dropdown';
import {
  resetActiveTableFilters,
  resetColumnFilters,
  setIsInitialized,
  toggleFilter,
} from 'store/table/tasks';
import ActionButton from '../action_button/action_button';
import Icon from '../icon/icon';

/**
 * Table component.
 * Similar to <Table>, but handles pagination, rowsPerPage, search and sorting by props.
 * Use this component when we need to get new data from backend every time one of these things
 * changes (when all data is more rows than what the component can handle).
 * Only send in the rows you want to display.
 *
 * Also note that order and orderBy is sent in as props here.
 *
 * Make a backend call for every nextPage/prevPage to provide new rows.
 * Which means filter on search query is done backend as well (optional to provide props.search).
 * And sorting on columns is also done backend, if props.sort is provided.
 *
 * Rows can be selectable, if so provide onSelect function and 'id' property for every row.
 * If a row have a 'url' property the row is going to be a navigation link.
 * If a row have a 'onClick' property we're going to execute that when a row is clicked and it doesn't have a 'url' property.
 *
 * We can hide sorting functions for certain columns. If so, add hideSort: true to column cell.
 * Use when backend endpoint doesn't handle sorting on every property.
 *
 * Note that cells is not editable in this component, like they are in <Table>. (No reason for that, just not yet needed.)
 *
 * @param props.adjustToContainer - bool (optional) - When we want table to fill up the container.
 * @param props.columns - array - See below for columns examples.
 * @param props.onSelect - func (optional) - Provide this function when rows are to be selectable, this function receives the selected ids array. Note that every row object must have an 'id' property with a unique value and you have to provide props.selected array.
 * @param props.query - string - Search query.
 * @param props.pageChange - func - Called when page changes.
 * @param props.rows - array - See below for rows examples.
 * @param props.rowsPerPage - number - Rows per page.
 * @param props.rowsPerPageChange - func - When rows per page changes.
 * @param props.onColumnsChange - func (optional) - When this is provided we display a dropdown to hide/show columns and return the column selection in this callback (most likely to update user settings).
 * @param props.search - func (optional) - Called when search input have value.
 * @param props.selected - array (optional) - If we wanna use selection, provide array with selected ids.
 * @param props.sort - func - Called when a column header is clicked.
 * @param props.total - number - Number of rows total.
 *
 *  @param props.columns
 *      Example 1 (used with example 1 for rows): [
 *          { id: 'name', numeric: false, label: 'Namn', visible: true },
 *          { id: 'orgnr', numeric: true, label: 'Org nr', visible: true },
 *          { id: 'mileage', numeric: true, label: 'Antal mil', hideSort: true, visible: true },
 *      ];
 *      Example 2 (used with example 2 for rows): [
 *          { id: 'brand', numeric: false, label: 'Märke', visible: true },
 *          { id: 'reg_number', numeric: false, label: 'Registreringsnummer', visible: true },
 *      ];
 *
 * @param props.rows
 *      Example 1 (selectable rows): [
 *          {id: '123', name: 'Sockerkaka', calories: 100, fat: 50},
 *          {id: '456', name: 'Chokladboll', calories: 200, fat: 100},
 *          {id: '789', name: 'Havrekaka', calories: 100, fat: 50, onClick: () => {doSomething()},
 *      ];
 *      Example 2 (rows is links): [
 *          {reg_number: 'abc123', brand: 'HONDA', url: '/fordon/abc123'},
 *          {reg_number: 'rty456', brand: 'VOLOV', url: '/fordon/rty456'},
 *      ];
 *
 */
export const TablePropsManaged = (props) => {
  const [columns, setColumns] = useState([]);
  const [page, setPage] = useState(props.page);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [query, setQuery] = React.useState(props.query ? props.query : '');
  const [selected, setSelected] = React.useState([]);
  const [columnFilters, setColumnFilters] = useState({});
  const [activeSearch, setActiveSearch] = useState({});
  const tc = useContext(LanguageContext);
  const rowsPerPageOptions = [5, 25, 50, 100];

  const {
    getColumnOptions,
    allowColumnFilters = false,
    activeFilters = {},
    vehicleSettings = {},
    reloadData = () => {},
    optionsInitialized,
  } = props;

  const activeFilterKeysOnly = useMemo(() => {
    return Object.entries(activeFilters).reduce((acc, [column, options]) => {
      return { ...acc, [column]: options.map((option) => option.key) };
    }, {});
  }, [activeFilters]);

  const columnFilterKeysOnly = useMemo(() => {
    return Object.entries(columnFilters).reduce((acc, [column, options]) => {
      return { ...acc, [column]: options.options.map((option) => option.key) };
    }, {});
  }, [columnFilters]);

  const firstFetchDone = useRef(false);
  const initialOptionsAdded = useRef(false);

  const handleEnterSearch = (e) => {
    if (e.key === 'Enter' || e.key === 'enter') {
      getColumnOptions(
        columns.filter((c) => c.searchable).map((c) => c.id),
        undefined,
        activeSearch
      ).then((options) => setColumnFilters({ ...options }));
    }
  };

  useEffect(() => {
    // Keep columns in component state so we can hide/show columns fast and smooth.
    setColumns(props.columns);
  }, [props.columns]);

  useEffect(() => {
    setSelected(props.selected ? props.selected : []);
  }, [props.selected]);

  useEffect(() => {
    setQuery(props.query ? props.query : '');
  }, [props.query]);

  useEffect(() => {
    if (props.order) {
      setOrder(props.order);
    } else {
      setOrder('asc');
    }

    if (props.orderBy) {
      setOrderBy(props.orderBy);
    } else {
      setOrderBy('');
    }
  }, [props.order, props.orderBy]);

  useEffect(() => {
    setPage(props.page);
  }, [props.page]);

  useEffect(() => {
    if (typeof getColumnOptions !== 'function') return;
    if (!columns || columns.length < 1) return;
    if (firstFetchDone.current) return;
    if (!allowColumnFilters) return;
    if (!vehicleSettings || Object.keys(vehicleSettings).length < 1) return;
    getColumnOptions(
      columns.filter((col) => col.searchable).map((col) => col.id)
    ).then((options) => {
      firstFetchDone.current = true;
      setColumnFilters({ ...options });
      if (
        initialOptionsAdded.current === false &&
        vehicleSettings &&
        Object.keys(vehicleSettings).length > 0
      ) {
        Object.entries(vehicleSettings).forEach(([vehicleType, isActive]) => {
          const isAlreadyActive = activeFilters.type?.some(
            (type) => type.key === vehicleType
          );
          if (isActive && !isAlreadyActive) {
            toggleFilter({ key: vehicleType }, 'type');
          }
        });
        initialOptionsAdded.current = true;
        setIsInitialized(true);
      }

      // reloadData(activeFilters);
    });
  }, [
    getColumnOptions,
    columns,
    allowColumnFilters,
    vehicleSettings,
    activeFilters,
    reloadData,
  ]);

  useEffect(() => {
    if (!optionsInitialized || !initialOptionsAdded.current) return;
    updateColumnOptions();
  }, [activeFilters, optionsInitialized]);

  useEffect(() => {
    return () => resetActiveTableFilters();
  }, []);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    if (typeof props.pageChange === 'function') {
      props.pageChange(newPage);
    }
    if (typeof props.onSelect === 'function') {
      props.onSelect([]);
    }
  };

  const handleRowsPerPageChange = (event) => {
    if (props.rowsPerPageChange === 'function') {
      props.rowsPerPageChange(parseInt(event.target.value, 10));
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    if (typeof props.sort === 'function') {
      props.sort({ order: isAsc ? 'desc' : 'asc', orderBy: property });
    }
  };

  const handleSelect = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    if (props.onSelect && typeof props.onSelect === 'function') {
      props.onSelect(newSelected);
    }
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelected = props.rows.map((n) => n.id);
      if (props.onSelect && typeof props.onSelect === 'function') {
        props.onSelect(newSelected);
      }
    } else {
      if (props.onSelect && typeof props.onSelect === 'function') {
        props.onSelect([]);
      }
    }
  };

  const handleColumnSearch = (column, text) => {
    const newActiveSearch = { ...activeSearch, [column]: text };
    setActiveSearch(newActiveSearch);
  };

  const _resetColumnFilters = (columnId) => {
    const newState = resetColumnFilters(columnId);
    const filteredColumnIds = columns
      .filter((col) => col.searchable)
      .map((col) => col.id);

    getColumnOptions(filteredColumnIds, newState).then((options) =>
      setColumnFilters({ ...options })
    );
  };

  const getCurrentDropdownLabel = (columnId) => {
    let string = '';
    if (columnFilters[columnId]?.options?.length > 0) {
      if (activeFilters[columnId]?.options?.length > 0)
        string = tc.selectFilter;
      else string = tc.selectFilter;
    } else {
      return tc.optionsMissing;
    }

    const columnFilterKeys =
      columnFilters[columnId]?.options.map((option) => option.key) ?? [];
    const totalFilterAmount =
      columnFilterKeys.concat(
        activeFilterKeysOnly[columnId]?.filter(
          (key) => !columnFilterKeys.includes(key)
        ) ?? []
      )?.length ?? 0;
    const currentFilterAmount = activeFilters[columnId]?.length ?? 0;

    return string + ` (${currentFilterAmount}/${totalFilterAmount})`;
  };

  function updateColumnOptions() {
    if (!typeof getColumnOptions === 'function') return;
    if (!columns || columns.length < 1) return;

    getColumnOptions(
      columns.filter((col) => col.searchable).map((col) => col.id)
    ).then((options) => setColumnFilters({ ...options }));
    setActiveSearch({});
  }

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const renderCellsForRow = (row) => {
    const rows = [];
    let index = 0;
    for (const prop in row) {
      const column = columns.find((column) => column.id === prop);
      const doNotRenderTheseProperties = ['id', 'onClick', 'visible', 'url'];

      if (column?.visible && !doNotRenderTheseProperties.includes(prop)) {
        index++;
        rows.push(
          <TableCell
            align={column.numeric ? 'right' : 'left'}
            key={`${row[prop]}${index}`}
          >
            {row[prop]}
          </TableCell>
        );
      }
    }

    return rows;
  };

  const _getLabelForFilter = (filter, column) => {
    let label =
      column.id === 'reg_number' || !filter.count
        ? `${filter.key}`
        : `${filter.key} (${filter.count} ${tc.aPiece.toLowerCase()})`;
    return label;
  };

  const _isActiveFilter = (filter, column) => {
    return activeFilters[column.id]
      ?.map((filter) => filter.key)
      .includes(filter.key);
  };

  const renderTableHead = () => {
    const createSortHandler = (property) => (event) => {
      handleRequestSort(event, property);
    };

    const columnsNotToSearch = ['type'];

    return (
      <TableHead>
        <TableRow>
          {props.onSelect && typeof props.onSelect === 'function' && (
            <TableCell>
              <Checkbox
                indeterminate={
                  selected.length > 0 && selected.length < props.rows.length
                }
                checked={
                  props.rows.length > 0 && selected.length === props.rows.length
                }
                onChange={handleSelectAll}
                inputProps={{ 'aria-label': 'select all' }}
              />
            </TableCell>
          )}
          {columns
            .map((column) => {
              if (!column.visible) {
                return null;
              }

              if (column.hideSort) {
                return (
                  <React.Fragment key={column.id}>
                    <TableCell
                      key={column.id}
                      align={column.numeric ? 'right' : 'left'}
                      sortDirection={orderBy === column.id ? order : false}
                    >
                      {column.searchable && allowColumnFilters ? (
                        <div className="tableHeadFlex">
                          <Dropdown
                            isForTableColumnFilters={true}
                            onClose={updateColumnOptions}
                            children={[
                              !columnsNotToSearch?.includes(column.id) ? (
                                <div key={`${column.id}-search`}>
                                  <input
                                    type="text"
                                    placeholder={tc.placeholderSearchOptions}
                                    value={activeSearch[column.id] || ''}
                                    onKeyDown={(e) =>
                                      handleEnterSearch(
                                        e,
                                        column.id,
                                        activeSearch[column.id] || ''
                                      )
                                    }
                                    onChange={(e) => {
                                      handleColumnSearch(
                                        column.id,
                                        e.target.value
                                      );
                                    }}
                                  />
                                  <ActionButton
                                    class="buttonMargin"
                                    type="regular"
                                    onClick={() => {
                                      getColumnOptions(
                                        columns
                                          .filter((c) => c.searchable)
                                          .map((c) => c.id),
                                        undefined,
                                        activeSearch
                                      ).then((options) =>
                                        setColumnFilters({ ...options })
                                      );
                                    }}
                                    label={tc.searchInTable}
                                  />
                                </div>
                              ) : null,
                              columnFilters[column.id]?.options
                                // ?.filter((filter) => {
                                //   if (!activeSearch[column.id]) return true;
                                //   return filter.key
                                //     .toLowerCase()
                                //     .includes(activeSearch[column.id]);
                                // })
                                ?.concat(
                                  (activeFilters[column.id] ?? []).filter(
                                    (filter) => {
                                      return !columnFilterKeysOnly[
                                        column.id
                                      ]?.includes(filter.key);
                                    }
                                  )
                                )
                                .map((filter) => {
                                  return (
                                    <DropdownItem
                                      key={filter.key}
                                      active={_isActiveFilter(filter, column)}
                                      disabled={false}
                                      label={_getLabelForFilter(filter, column)}
                                      onClick={() => {
                                        toggleFilter(filter, column.id);
                                      }}
                                    />
                                  );
                                }),
                            ]}
                            closeOnItemClick={false}
                            displayValue={getCurrentDropdownLabel(column.id)}
                          />
                          <button
                            onClick={() => _resetColumnFilters(column.id)}
                            className="generalButton"
                          >
                            <Icon val="notAvailable" />
                          </button>
                        </div>
                      ) : null}
                      {column.label}
                    </TableCell>
                  </React.Fragment>
                );
              } else {
                return (
                  <React.Fragment key={column.id}>
                    <TableCell
                      key={column.id}
                      align={column.numeric ? 'right' : 'left'}
                      sortDirection={orderBy === column.id ? order : false}
                    >
                      {column.searchable && allowColumnFilters ? (
                        <div className="tableHeadFlex">
                          <Dropdown
                            isForTableColumnFilters={true}
                            onClose={() => {
                              updateColumnOptions();
                            }}
                            children={[
                              <div key={`${column.id}-search`}>
                                <input
                                  onKeyDown={(e) =>
                                    handleEnterSearch(
                                      e,
                                      column.id,
                                      activeSearch[column.id] || ''
                                    )
                                  }
                                  type="text"
                                  placeholder={tc.placeholderSearchOptions}
                                  value={activeSearch[column.id] || ''}
                                  onChange={(e) => {
                                    handleColumnSearch(
                                      column.id,
                                      e.target.value
                                    );
                                  }}
                                />
                                <ActionButton
                                  class="buttonMargin"
                                  type="regular"
                                  label={tc.searchInTable}
                                  onClick={() => {
                                    getColumnOptions(
                                      columns
                                        .filter((c) => c.searchable)
                                        .map((c) => c.id),
                                      undefined,
                                      activeSearch
                                    ).then((options) =>
                                      setColumnFilters({ ...options })
                                    );
                                  }}
                                />
                              </div>,

                              columnFilters[column.id]?.options
                                // ?.filter((filter) => {
                                //   if (!activeSearch[column.id]) return true;
                                //   return filter.key
                                //     .toLowerCase()
                                //     .includes(activeSearch[column.id]);
                                // })
                                ?.concat(
                                  (activeFilters[column.id] ?? []).filter(
                                    (filter) => {
                                      return !columnFilterKeysOnly[
                                        column.id
                                      ]?.includes(filter.key);
                                    }
                                  )
                                )
                                .map((filter) => {
                                  return (
                                    <DropdownItem
                                      key={filter.key}
                                      active={_isActiveFilter(filter, column)}
                                      disabled={false}
                                      label={_getLabelForFilter(filter, column)}
                                      onClick={() => {
                                        toggleFilter(filter, column.id);
                                      }}
                                    />
                                  );
                                }),
                            ]}
                            closeOnItemClick={false}
                            displayValue={getCurrentDropdownLabel(column.id)}
                          />
                          <button
                            onClick={() => _resetColumnFilters(column.id)}
                            className="generalButton"
                          >
                            <Icon val="notAvailable" />
                          </button>
                        </div>
                      ) : null}

                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : 'asc'}
                        onClick={createSortHandler(column.id)}
                      >
                        {column.label}
                        {orderBy === column.id ? (
                          <span className="hidden">
                            {order === 'desc'
                              ? 'sorted descending'
                              : 'sorted ascending'}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  </React.Fragment>
                );
              }
            })
            .filter((num) => num)}
        </TableRow>
      </TableHead>
    );
  };

  const _stateCheck = () => {
    return Array.isArray(columns) && Array.isArray(props.rows);
  };

  return _stateCheck() ? (
    <div
      className={`tableWrapper ${
        props.adjustToContainer ? 'adjustToContainer' : ''
      }`}
    >
      <div className="tableWrapper__table">
        <div className="tableWrapper__table__content">
          <div className="tableWrapper__table__content__top">
            {allowColumnFilters ? (
              <div>
                <ActionButton
                  onClick={async () => {
                    await props.reloadData(activeFilters);
                    if (!typeof getColumnOptions === 'function') return;
                    if (!columns || columns.length < 1) return;

                    getColumnOptions(
                      columns
                        .filter((col) => col.searchable)
                        .map((col) => col.id)
                    ).then((options) => setColumnFilters({ ...options }));
                  }}
                  label={tc.applyFilters}
                  type="highlight"
                />
              </div>
            ) : null}
            <div
              className={`tableWrapper__table__content__top__left ${
                selected?.length ? null : 'noSelected'
              }`}
            >
              <span>
                {tc.selectedRows}:{' '}
                <strong>{selected ? selected.length : 0}</strong>{' '}
                {tc.of.toLowerCase()} <strong>{props.total}</strong>
              </span>
            </div>
            <div className="tableWrapper__table__content__top__right">
              {typeof props.onColumnsChange === 'function' ? (
                <Dropdown
                  displayValue={tc.selectColumns}
                  transparent={true}
                  styling={{ adjustToHeader: true }}
                >
                  {columns
                    .filter((num) => num.id !== 'numberOfMatchingCars')
                    .map((num) => {
                      return (
                        <DropdownItem
                          active={num.visible}
                          //disabled={
                          //columns.filter((num) => num.visible).length === 1
                          //}
                          disabled={false}
                          key={num.id}
                          label={num.label}
                          onClick={() => {
                            const columnsUpdated = columns.map((x) => {
                              if (num.id === x.id) {
                                x.visible = !x.visible;
                              }
                              return x;
                            });

                            setColumns(columnsUpdated);

                            // Return visible columns as array with strings.
                            props.onColumnsChange(
                              columnsUpdated
                                .filter((num) => num.visible)
                                .map((num) => num.id)
                            );
                          }}
                        />
                      );
                    })}
                </Dropdown>
              ) : null}
            </div>
          </div>
          <TableContainer>
            <Table
              aria-label="table"
              size="small"
              stickyHeader={!!props.adjustToContainer}
            >
              {renderTableHead()}
              <TableBody>
                {props.rows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      className={row.url || row.onClick ? 'rowPointer' : ''}
                      hover
                      onClick={() => {
                        if (row.url) {
                          return history.push(row.url);
                        } else if (row.onClick) {
                          return row.onClick(row.id);
                        }
                      }}
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={`${row[Object.keys(row)[0]]}${index}`}
                      selected={isItemSelected}
                    >
                      {props.onSelect &&
                        typeof props.onSelect === 'function' &&
                        row.id && (
                          <TableCell
                            onClick={(e) => {
                              e.stopPropagation();
                              return handleSelect(e, row.id);
                            }}
                          >
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </TableCell>
                        )}
                      {renderCellsForRow(row)}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="tableWrapper__table__footer">
          {typeof props.search === 'function' && (
            <div className="tableWrapper__table__footer__left">
              <input
                type="text"
                placeholder={tc.placeholderSearchTable}
                onChange={(e) => {
                  props.search(e.target.value);
                }}
                value={query}
              />
            </div>
          )}
          <div className="tableWrapper__table__footer__middle">
            {typeof props.rowsPerPageChange === 'function' && (
              <div className="tableWrapper__table__footer__middle__rowsPerPage">
                <span className="tableWrapper__table__footer__middle__rowsPerPage__label">
                  {tc.rowsPerPage}:
                </span>
                {rowsPerPageOptions.map((num) => {
                  return (
                    <span
                      className={
                        props.rowsPerPage === num
                          ? 'tableWrapper__table__footer__middle__rowsPerPage__optionActive'
                          : 'tableWrapper__table__footer__middle__rowsPerPage__option'
                      }
                      key={num}
                      onClick={() => {
                        props.rowsPerPageChange(num);
                      }}
                    >
                      {num}
                    </span>
                  );
                })}
              </div>
            )}
          </div>
          <div className="tableWrapper__table__footer__right">
            <TablePagination
              labelDisplayedRows={({ from, to, count }) =>
                `${from} - ${to} ${tc.of.toLowerCase()} ${count}`
              }
              rowsPerPageOptions={[0]}
              component="div"
              count={props.total}
              rowsPerPage={props.rowsPerPage}
              page={page}
              // onChangePage={handlePageChange}
              onPageChange={handlePageChange}
              // onChangeRowsPerPage={handleRowsPerPageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </div>
        </div>
      </div>
    </div>
  ) : null;
};
