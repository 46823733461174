import React, { useContext, useEffect, useState } from 'react';
import LanguageContext from 'language-context';
import {
  shareListSubscription,
  getListsSubscriptions,
} from 'store/lists/tasks';
import ColleaguesRows from 'components/colleagues_rows';
import Popup from 'components/popup';
import WidgetFooter from 'components/widget_footer';
import WidgetHeader from 'components/widget_header';

/**
 * Component that helps user to split a list.
 * If a user manually adjusts the value for rows, we automatically calculate average value for the other rows.
 *
 * @param props.close - func
 * @param props.selectedLists - array - The lists to share.
 */
const ShareListSubsciptions = (props) => {
  const [existingUsers, setExistingUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    if (props.selectedSubscription) {
      if (props.selectedSubscription.subscribers) {
        setExistingUsers(props.selectedSubscription.subscribers);
      }
    }
  }, [props.selectedSubscription]);

  const _shareSubscriptions = async () => {
    await shareListSubscription({
      subscriptionId: props.selectedSubscription.id,
      userIds: users.map((user) => user.id),
    });
    props.close();
    await getListsSubscriptions();
  };

  return props.selectedSubscription ? (
    <Popup close={props.close} size="big">
      <div className="listsGenericWrapper">
        <div className="listsGenericWrapper__listsGeneric">
          <div className="listsGenericWrapper__listsGeneric__header">
            <WidgetHeader
              headline={tc.shareListSubsciptions}
              headlineSub={tc.shareListInfo}
            />
          </div>
          <div className="listsGenericWrapper__listsGeneric__content">
            <ColleaguesRows
              existingUsers={existingUsers}
              onChange={(arr) => {
                setUsers(arr);
              }}
            />
          </div>
          <div className="listsGenericWrapper__listsGeneric__footer">
            <WidgetFooter
              buttonOneFunc={() => {
                if (users.length) {
                  return _shareSubscriptions(users.map((num) => num.id));
                }
              }}
              buttonOneText={tc.shareListSubscriptions}
              buttonTwoFunc={props.close}
              buttonTwoText={tc.cancel}
              disableButtonOne={!users.length}
            />
          </div>
        </div>
      </div>
    </Popup>
  ) : null;
};

export default ShareListSubsciptions;
