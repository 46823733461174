import React, { useContext } from "react";
import LanguageContext from "language-context";
import { connect } from "react-redux";
import { removeComment } from "store/comment/tasks";
import Popup from "components/popup";
import WidgetFooter from "components/widget_footer";
import WidgetHeader from "components/widget_header";

const CommentRemove = (state) => {
  const tc = useContext(LanguageContext);

  const _removeComment = async () => {
    await removeComment({ id: state.comment.removeId });
    state.props.close();
  };

  return (
    <Popup close={state.props.close} size="small">
      <div className="commentWrapper">
        <div className="commentWrapper__comment">
          <div className="commentWrapper__comment__header">
            <WidgetHeader
              headline={tc.removeComment}
              headlineSub={state.props.headline ? state.props.headline : null}
            />
          </div>
          <div className="commentWrapper__comment__content marginTop marginBottom">
            {tc.ensure}
          </div>
          <div className="commentWrapper__comment__footer">
            <WidgetFooter
              buttonOneFunc={_removeComment}
              buttonOneText={tc.remove}
              buttonTwoFunc={state.props.close}
              buttonTwoText={tc.cancel}
            />
          </div>
        </div>
      </div>
    </Popup>
  );
};

const MapStateToProps = (state, props) => {
  return {
    comment: state.comment,
    props: props,
  };
};

export default connect(MapStateToProps)(CommentRemove);
