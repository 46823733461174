import React, { useContext, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import LanguageContext from 'language-context';
import FilterBox from 'components/filter_box/filter_box';
import { getListsSubscriptions } from 'store/lists/tasks';

const ActivitiesContentBottom = (state) => {
  const tc = useContext(LanguageContext);
  const { widget } = state;
  const fetchedSubsRef = useRef(false);
  useEffect(() => {
    if (widget?.data?.subscriptions?.length < 1) return;
    if (fetchedSubsRef.current === true) return;
    else {
      getListsSubscriptions();
      fetchedSubsRef.current = true;
    }
  }, [widget?.data]);

  return state.props?.widget?.data ? (
    <div className="activitiesWrapper__activities__content__bottom">
      <FilterBox
        filters={[
          widget.data.currentWeek
            ? {
                label: tc.currentWeek,
                type: 'currentWeek',
              }
            : null,
          widget.data.currentMonth
            ? {
                label: tc.currentMonth,
                type: 'currentMonth',
              }
            : null,
          widget.data.previousWeek
            ? {
                label: tc.previousWeek,
                type: 'previousWeek',
              }
            : null,
          widget.data.previousMonth
            ? {
                label: tc.previousMonth,
                type: 'previousMonth',
              }
            : null,
          widget.data.pastThirtyDays
            ? {
                label: tc.pastThirtyDays,
                type: 'pastThirtyDays',
              }
            : null,
          widget.data.past365Days
            ? {
                label: tc.past365Days,
                type: 'past365Days',
              }
            : null,
          widget.data.date &&
          !widget.data.currentWeek &&
          !widget.data.currentMonth &&
          !widget.data.pastThirtyDays &&
          !widget.data.past365Days
            ? {
                label: tc.date,
                type: 'date',
                values: widget.data.date,
              }
            : null,
          widget.data.lists?.length
            ? {
                label: tc.lists,
                type: 'lists',
                values: Array.isArray(widget.data.lists)
                  ? widget.data.lists
                  : [widget.data.lists],
              }
            : null,
          widget.data.users?.length
            ? {
                label: tc.users,
                type: 'users',
                values: Array.isArray(widget.data.users)
                  ? widget.data.users
                  : [widget.data.users],
              }
            : null,
          widget.data.subscriptions?.length
            ? {
                label: tc.listsSubscriptions,
                type: 'subscriptions',
                values: widget.data.subscriptions.map((subId) => {
                  const subscription = state.subscriptions?.find(
                    (sub) => sub._id === subId
                  );
                  if (subscription) return subscription.prefix_name;
                  else return subId;
                }),
              }
            : null,
        ]}
        recreateActivityFilters={true}
      />
    </div>
  ) : null;
};

const MapStateToProps = (state, props) => {
  return {
    activities: state.activities,
    activity: state.activity,
    comment: state.comment,
    props: props,
    widget: props.widget,
    user: state.user,
    widgets: state.widgets,
    filter: state.activity.filter,
    subscriptions: state.lists.listsSubscriptions,
  };
};

export default connect(MapStateToProps)(ActivitiesContentBottom);
