import React, { useContext, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import LanguageContext from "language-context";
import carHelper from "shared_helpers/car_helper";
import { miscHelper } from "helpers";
import companyHelper from "shared_helpers/company_helper";
import {
  getKoncernCompaniesSuggestions,
  resetSearch,
  resetSelected,
  toggleSelected,
} from "store/search/tasks";
import ActionButton from "components/action_button";
import Icon from "components/icon";

const SearchSelectKoncernCompanies = (state) => {
  const [showSearch, setShowSearch] = useState(false);
  const [selected, setSelected] = useState([]);
  const inputSelectKoncernCompaniesRef = useRef(null);
  const searchSelectKoncernCompaniesWrapperRef = useRef(null);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    resetSelected({ type: "koncernCompanies" }); // Reset selected array.
    resetSearch(); // Reset search suggestions.

    /**
     * When clicking outside searchWrapper, reset search.
     */
    const _handleMouseDown = (e) => {
      if (
        miscHelper.clickUnmount(
          e,
          searchSelectKoncernCompaniesWrapperRef,

          false
        )
      ) {
        _closeSearch();
      }
    };

    /**
     * Handle key press.
     */
    const _handleKey = async (e) => {
      if (e.keyCode === 27) {
        _closeSearch();
      }
    };

    window.addEventListener("mousedown", _handleMouseDown);
    window.addEventListener("keydown", _handleKey);
    return () => {
      window.removeEventListener("mousedown", _handleMouseDown);
      window.removeEventListener("keydown", _handleKey);
    };
  }, []);

  useEffect(() => {
    setSelected(state.search.selectedKoncernCompanies);
  }, [state.search.selectedKoncernCompanies]);

  const _closeSearch = () => {
    setShowSearch(false);
    if (inputSelectKoncernCompaniesRef?.current) {
      inputSelectKoncernCompaniesRef.current.value = "";
    }
  };

  /**
   * Handle input change.
   */
  const _handleInput = async (e) => {
    if (e.target?.value?.length) {
      setShowSearch(true);
      await getKoncernCompaniesSuggestions({
        q: e.target.value,
        target: state.props.target,
      });
    } else {
      _closeSearch();
      resetSearch();
    }
  };

  /**
   * Render selected values, with remove button.
   */
  const _renderChips = () => {
    if (selected.length) {
      return selected.map((num) => {
        return (
          <div
            className="searchSelectWrapper__searchSelect__content__chips__item"
            key={num.id ? num.id : num._id}
          >
            {num.name}
            <Icon
              val="clear"
              onClick={() => {
                _toggleSelected(num);
              }}
            />
          </div>
        );
      });
    }
  };

  /**
   * Return search result rows.
   */
  const _renderSuggestionRows = () => {
    // Property 'searchSuggestions' in search reducer is shared between the different search types ('all', 'cars' etc.),
    // so we cannot only check if we have search suggestions, we also check for showSearch variable.
    // Otherwise search suggestions would be displayed in all search components on the page.
    if (state.search.searchSuggestions?.length && showSearch) {
      const rows = state.search.searchSuggestions.map((num, i) => {
        let iconVal;
        if (companyHelper.isValidOrgNr(num.id ? num.id : num._id)) {
          iconVal = "company";
        } else if (carHelper.isValidRegNumber(num.id ? num.id : num._id)) {
          iconVal = "car";
        } else {
          iconVal = "person";
        }

        const iconCheckboxVal = selected.find((x) => {
          const selectedId = x.id ? x.id : x._id;
          const searchResultId = num.id ? num.id : num._id;
          return selectedId === searchResultId;
        })
          ? "check"
          : "checkbox";

        return (
          <div
            className="searchSelectWrapper__searchSelect__header__bottom__searchResult__item"
            key={Math.random().toString()}
            onClick={() => {
              _toggleSelected(num);
            }}
          >
            <span className="small">
              <Icon val={iconVal} />
            </span>
            <span className="text">{num.name}</span>
            <Icon val={iconCheckboxVal} />
          </div>
        );
      });

      return (
        <div className="searchSelectWrapper__searchSelect__header__bottom__searchResult">
          {rows}
        </div>
      );
    }
  };

  const _saveSelected = async () => {
    if (typeof state.props.save === "function") {
      await state.props.save();
    }

    _closeSearch();
    resetSearch();
    resetSelected({ type: "koncernCompanies" });
  };

  const _toggleSelected = (payload) => {
    toggleSelected({ obj: payload, type: "koncernCompanies" });
  };

  return (
    <div
      className="searchSelectWrapper"
      ref={searchSelectKoncernCompaniesWrapperRef}
    >
      <div className="searchSelectWrapper__searchSelect">
        <div className="searchSelectWrapper__searchSelect__header">
          <div className="searchSelectWrapper__searchSelect__header__top">
            <div className="searchSelectWrapper__searchSelect__header__top__input">
              <input
                ref={inputSelectKoncernCompaniesRef}
                type="search"
                onChange={_handleInput}
              />
            </div>
            <div className="searchSelectWrapper__searchSelect__header__top__icon">
              <Icon val="search" />
            </div>
          </div>
          <div className="searchSelectWrapper__searchSelect__header__bottom">
            {_renderSuggestionRows()}
          </div>
        </div>
        {selected.length > 0 && (
          <div className="searchSelectWrapper__searchSelect__content">
            <div className="searchSelectWrapper__searchSelect__content__chips">
              {_renderChips()}
            </div>
            <ActionButton
              label={tc.add}
              onClick={_saveSelected}
              type="highlight"
            />
          </div>
        )}
      </div>
    </div>
  );
};

const MapStateToProps = (state, props) => {
  return {
    props: props,
    search: state.search,
  };
};

export default connect(MapStateToProps)(SearchSelectKoncernCompanies);
