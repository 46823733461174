import React, { useContext } from 'react';
import LanguageContext from 'language-context';
import ActionButton from 'components/action_button';

/**
 * Footer with buttons.
 *
 * Usually used with one or two buttons, but handles up to three.
 * Usually ButtonOne is "save" and buttonTwo is "cancel".
 * ButtonOne gets the "save button" style.
 *
 * @param props.buttonOneFunc - func (optional)
 * @param props.buttonOneText - string (optional)
 * @param props.buttonTwoFunc - func (optional)
 * @param props.buttonTwoText - string (optional)
 * @param props.buttonThreeFunc - func (optional)
 * @param props.buttonThreeText - string (optional)
 * @param props.buttonFourFunc - func (optional)
 * @param props.buttonFourText - string (optional)
 * @param props.disableButtonOne - bool (optional)
 * @param props.disableButtonTwo - bool (optional)
 * @param props.disableButtonThree - bool (optional)
 * @param props.disableButtonFour - bool (optional)
 *
 * @param props.noBorder - bool (optional) - No top border for component.
 */
const WidgetFooter = (props) => {
  const tc = useContext(LanguageContext);

  const buttonOne = props.buttonOneFunc ? (
    <ActionButton
      disabled={props.disableButtonOne}
      label={props.buttonOneText ? props.buttonOneText : tc.save}
      onClick={!props.disableButtonOne ? props.buttonOneFunc : null}
      type="highlight"
    />
  ) : null;

  const buttonTwo = props.buttonTwoFunc ? (
    <ActionButton
      disabled={props.disableButtonTwo}
      label={props.buttonTwoText ? props.buttonTwoText : tc.remove}
      onClick={!props.disableButtonTwo ? props.buttonTwoFunc : null}
      type="regular"
    />
  ) : null;

  const buttonThree = props.buttonThreeFunc ? (
    <ActionButton
      disabled={props.disableButtonThree}
      label={props.buttonThreeText ? props.buttonThreeText : tc.remove}
      onClick={!props.disableButtonThree ? props.buttonThreeFunc : null}
      type="regular"
    />
  ) : null;
  const buttonFour = props.buttonFourFunc ? (
    <ActionButton
      disabled={props.disableButtonFour}
      label={props.buttonFourText ? props.buttonFourText : tc.remove}
      onClick={!props.disableButtonThree ? props.buttonFourFunc : null}
      type="regular"
    />
  ) : null;

  const alignButtons = props.alignTwoButtonsRight ? (
    <>
      <div className="widgetFooterWrapper__widgetFooter__container">
        {buttonThree}
        {buttonFour}
        {buttonOne}
      </div>
      <div className="widgetFooterWrapper__widgetFooter__container__flex"></div>
      {buttonTwo}
    </>
  ) : (
    <>
      <div>{buttonOne}</div>
      <div>{buttonFour}</div>
      <div>{buttonThree}</div>
      <div>{buttonTwo}</div>
    </>
  );

  return (
    <div className="widgetFooterWrapper">
      <div
        className={
          props.noBorder
            ? 'widgetFooterWrapper__widgetFooter noBorder'
            : 'widgetFooterWrapper__widgetFooter'
        }
      >
        {alignButtons}
      </div>
    </div>
  );
};

export default WidgetFooter;
