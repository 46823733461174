import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import LanguageContext from 'language-context';
import {
  getKoncernStructure,
  getBasicKoncernInfo,
  toggleShowEmpty,
} from 'store/koncern/tasks';
import Loading from 'components/loading';
import WidgetHeader from 'components/widget_header';
import Tooltip from '../tooltip/tooltip';
import Icon from '../icon/icon';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem, { useTreeItem } from '@mui/lab/TreeItem';
import ActionButton from 'components/action_button';
import { NavLink } from 'react-router-dom';
import { vehicleHelper } from 'helpers';

const vehicleTypesInTable = ['PB', 'LB', 'TLB'];

const ExpandButton = ({ handleExpand, expanded }) => {
  return expanded ? (
    <Icon
      val="minimize"
      onClick={handleExpand}
      class="companyStructureWrapper__customComponent__icon"
    />
  ) : (
    <Icon
      val="maximize"
      onClick={handleExpand}
      class="companyStructureWrapper__customComponent__icon"
    />
  );
};

const TotalColumn = ({ total, tooltipData }) => {
  const tc = useContext(LanguageContext);

  return (
    <div className="companyStructureWrapper__customComponent__column">
      <span>{total || '-'}</span>
      {total ? (
        <Tooltip
          tooltipContent={Object.keys(tooltipData).map((val, i) => (
            <span
              key={i + 'total' + val}
              className="companyStructureWrapper__customComponent__column__toolTip"
            >
              {`${tc[val.toLowerCase()]} : ${tooltipData[val]}`}
            </span>
          ))}
        >
          <Icon val="infoOutlined" />
        </Tooltip>
      ) : null}
    </div>
  );
};

const VehicleTypeCountColumn = ({ type, data, extraStyles, extraClasses }) => {
  return (
    <div
      style={extraStyles}
      className={`companyStructureWrapper__customComponent__column ${
        extraClasses ? extraClasses : ''
      }`}
    >
      <span>{data[type] || '-'}</span>
    </div>
  );
};

const CustomContent = React.forwardRef(function CustomContent(props, ref) {
  const { nodeId, hasChildren, hasFilialer, data, showEmpty, depth } = props;

  const { expanded, handleExpansion, preventSelection } = useTreeItem(nodeId);

  const [showFilialer, setShowFilialer] = useState(false);

  const handleMouseDown = (event) => {
    preventSelection(event);
  };

  const handleExpansionClick = (event) => {
    handleExpansion(event);
  };

  const getFleetCount = (expanded, showFilialer, data) => {
    const selfCount = data.total ? data.total : 0;
    const childFleetCount =
      !expanded && (hasChildren || hasFilialer) && !showFilialer
        ? data.childFleet.total
        : 0;
    const filialCount = expanded
      ? hasFilialer
        ? !showFilialer
          ? data.filialerFleetCount.total
          : 0
        : 0
      : 0;

    return selfCount + childFleetCount + filialCount;
  };

  const getToolTipData = (
    vehicleTypes,
    data,
    showFilialer,
    expanded,
    isFilial
  ) => {
    return vehicleTypes.reduce((acc, item) => {
      if (isFilial) {
        const count = !isNaN(Number(data[item])) ? data[item] : 0;
        acc[item] = count;
        return acc;
      }

      const fleetCount = !isNaN(Number(data.fleet[item]))
        ? data.fleet[item]
        : 0;
      const childFleetCount = !isNaN(Number(data.childFleet[item]))
        ? data.childFleet[item]
        : 0;
      const filialFleetCount = !isNaN(Number(data.filialerFleetCount[item]))
        ? data.filialerFleetCount[item]
        : 0;

      if (expanded) {
        if (showFilialer) {
          acc[item] = fleetCount;
        } else {
          acc[item] = fleetCount + filialFleetCount;
        }
      } else {
        if (showFilialer) {
          acc[item] = fleetCount + childFleetCount - filialFleetCount;
        } else {
          acc[item] = fleetCount + childFleetCount;
        }
      }

      return acc;
    }, {});
  };

  return (
    <div className="companyStructureWrapper">
      <div
        className={`companyStructureWrapper__customComponent companyStructureWrapper__customComponent__group`}
        onMouseDown={handleMouseDown}
        ref={ref}
      >
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div
          className="companyStructureWrapper__customComponent__column"
          style={{
            paddingLeft: `${hasChildren ? depth * 15 : depth * 15 + 15}px`,
            overflowX: 'auto',
          }}
        >
          {hasChildren ? (
            <ExpandButton
              handleExpand={handleExpansionClick}
              expanded={expanded}
            />
          ) : null}

          <div>
            <NavLink exact to={`/foretag/${data.id}`} key="hem">
              {data.name}
            </NavLink>
          </div>
          {hasFilialer && (
            <span
              onClick={() => setShowFilialer(!showFilialer)}
              className="companyStructureWrapper__customComponent__column__showFilial"
            >
              +
            </span>
          )}
        </div>
        <div className="companyStructureWrapper__customComponent__column">
          <span>{data.id}</span>
        </div>
        {vehicleTypesInTable.map((vehicleType) => {
          return (
            <VehicleTypeCountColumn
              extraStyles={{ width: '10%' }}
              extraClasses="hideOnMobile"
              key={vehicleType}
              depth={depth}
              type={vehicleType}
              data={getToolTipData(
                vehicleHelper.getVehicleTypes(),
                data,
                showFilialer,
                expanded,
                false
              )}
            />
          );
        })}
        <TotalColumn
          depth={depth}
          total={getFleetCount(expanded, showFilialer, data)}
          tooltipData={getToolTipData(
            vehicleHelper.getVehicleTypes(),
            data,
            showFilialer,
            expanded,
            false
          )}
        />
      </div>
      {showFilialer &&
        data.filialer
          .filter((fil) => (showEmpty ? true : fil.total > 0))
          .map((fil) => (
            <div
              className="companyStructureWrapper__customComponent companyStructureWrapper__customComponent__filial"
              key={fil.id}
            >
              <div className="companyStructureWrapper__customComponent__column">
                <div
                  className={
                    'companyStructureWrapper__customComponent__iconContainer__noIcon'
                  }
                />
                <NavLink exact to={`/foretag/${fil.id}`} key="hem">
                  {fil.name}
                </NavLink>
              </div>
              <div className="companyStructureWrapper__customComponent__column">
                {fil.id}
              </div>
              {vehicleTypesInTable.map((vehicleType) => {
                return (
                  <VehicleTypeCountColumn
                    extraStyles={{ width: '10%', paddingLeft: '12px' }}
                    extraClasses="hideOnMobile"
                    padding={''}
                    key={vehicleType}
                    depth={depth}
                    type={vehicleType}
                    data={getToolTipData(
                      vehicleHelper.getVehicleTypes(),
                      fil,
                      false,
                      false,
                      true
                    )}
                  />
                );
              })}
              <TotalColumn
                total={fil.total}
                tooltipData={getToolTipData(
                  vehicleHelper.getVehicleTypes(),
                  fil,
                  false,
                  false,
                  true
                )}
              />
            </div>
          ))}
    </div>
  );
});

const CustomTreeItem = (props) => {
  return <TreeItem ContentComponent={CustomContent} {...props} />;
};
/**
 *
 * @param isMinimized - A boolean indicating whether the component is minimized or not.
 * @param onMinimizeChange - Callback function invoked when the minimize state changes.
 */
const KoncernStructure = ({
  id,
  structure,
  allNodeIds,
  rootId,
  basicInfo,
  isMinimized,
  onMinimizeChange,
  isCompanyPage,
}) => {
  const [expanded, setExpanded] = useState([]);
  const [allExpanded, setAllExpanded] = useState(false);
  const [showEmpty, setShowEmpty] = useState(true);
  const [minimize, setMinimize] = useState(false);
  const tc = useContext(LanguageContext);
  useEffect(() => {
    setMinimize(isMinimized);
  }, [isMinimized]);
  useEffect(() => {
    getKoncernStructure({ id });
    getBasicKoncernInfo({ id });
  }, [id]);

  useEffect(() => {
    if (rootId) {
      setExpanded([rootId]);
    }
  }, [rootId]);

  const renderTree = (nodes, depth) => {
    return (
      nodes.visible && (
        <CustomTreeItem
          key={nodes.id}
          nodeId={nodes.nodeId}
          label={nodes.name}
          ContentProps={{
            depth,
            hasChildren:
              Array.isArray(nodes.children) &&
              nodes.children.length > 0 &&
              nodes.children.some((node) => node.is_filial === 0),
            hasFilialer:
              Array.isArray(nodes.filialer) && nodes.filialer.length > 0,
            data: nodes,
            showEmpty,
          }}
        >
          {Array.isArray(nodes.children)
            ? nodes.children.map((node) => renderTree(node, depth + 1))
            : null}
        </CustomTreeItem>
      )
    );
  };

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const toggleExpandAll = () => {
    if (allExpanded) {
      setExpanded([]);
    } else {
      setExpanded(allNodeIds);
    }
    setAllExpanded(!allExpanded);
  };

  return (
    <div className="companyStructureWrapper">
      <div className="companyStructureWrapper__companyStructure">
        <div className="companyStructureWrapper__companyStructure__header">
          <WidgetHeader
            dashboardItems={
              isCompanyPage
                ? null
                : [
                    {
                      disabled: !minimize,
                      icon: 'maximize',
                      label: tc.maximize,
                      onClick: () => {
                        setMinimize(false);
                      },
                    },
                    {
                      disabled: minimize,
                      icon: 'minimize',
                      label: tc.minimize,
                      onClick: () => {
                        setMinimize(true);
                        if (onMinimizeChange) {
                          onMinimizeChange(true);
                        }
                      },
                    },
                  ]
            }
            headline={tc.koncernStructure}
            headlineSub={
              basicInfo ? (
                `${
                  basicInfo.companyCount
                } ${tc.companies.toLowerCase()} ${tc.with.toLowerCase()} ${
                  basicInfo.fleetCount
                } ${tc.car}`
              ) : (
                <Loading small={true} />
              )
            }
          />
        </div>
        <div
          className={
            minimize
              ? 'companyStructureWrapper__companyStructure__content minimize'
              : 'companyStructureWrapper__companyStructure__content'
          }
        >
          {structure ? (
            <>
              <div className="companyStructureWrapper__companyStructure__content__buttons">
                <ActionButton
                  onClick={toggleExpandAll}
                  label={allExpanded ? tc.fold : tc.unfold}
                  active={allExpanded}
                  type="regular"
                />
                <ActionButton
                  onClick={() => {
                    toggleShowEmpty({ isVisible: !showEmpty, obj: structure });
                    setShowEmpty(!showEmpty);
                  }}
                  label={
                    showEmpty
                      ? tc.hideCompaniesWithEmptyFleet
                      : tc.showCompaniesWithEmptyFleet
                  }
                  active={showEmpty}
                  type="regular"
                />
              </div>
              <div className="companyStructureWrapper__companyStructure__content__table">
                <div className="companyStructureWrapper__companyStructure__content__table__header">
                  <span>{tc.name}</span>
                  <span>{tc.orgNumberShort}</span>
                  {vehicleTypesInTable.map((vehicleType) => {
                    return (
                      <span
                        className={'hideOnMobile'}
                        style={{ width: '10%' }}
                        key={vehicleType}
                      >
                        {tc[vehicleType]}
                      </span>
                    );
                  })}
                  <span>{tc.amountVehicles}</span>
                </div>
                <TreeView
                  aria-label="rich object"
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                  sx={{ flexGrow: 1 }}
                  expanded={expanded}
                  onNodeToggle={handleToggle}
                >
                  {renderTree(structure, 0)}
                </TreeView>
              </div>
            </>
          ) : (
            <Loading />
          )}
        </div>
      </div>
    </div>
  );
};

const MapStateToProps = (state) => {
  return {
    structure: state.koncern.structure,
    allNodeIds: state.koncern.allNodeIds,
    rootId: state.koncern.rootId,
    basicInfo: state.koncern.basicInfo,
  };
};

export default connect(MapStateToProps)(KoncernStructure);
