import React, { useContext, useEffect, useState } from "react";
import LanguageContext from "language-context";
import { textHelper } from "helpers";

/**
 * Render preview section.
 *
 * @param props.adjustMarginBottom - bool - When review is open, adjust bottom margin for last section.
 * @param props.name - string
 * @param props.renderContent - func - Render content within section, such as sub sections, rows.
 * @param props.subSection -  bool
 */
const PreviewSection = (props) => {
  const [marginBottom, setMarginBottom] = useState(0);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    window.addEventListener("resize", _setMarginBottom);
    return () => {
      window.removeEventListener("resize", _setMarginBottom);
    };
  }, [props.adjustMarginBottom]);

  useEffect(() => {
    const timer = setTimeout(() => {
      _setMarginBottom();
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [props.adjustMarginBottom]);

  /**
   * Calculate and set bottom margin for last section, so scroll is working nicely.
   */
  const _setMarginBottom = () => {
    if (!props.adjustMarginBottom || props.subSection) {
      return;
    }

    const section = document.querySelector("#preview-" + props.name);
    const allSections = document.querySelectorAll(
      ".previewSectionWrapper:not(.subSection)"
    );

    if (
      !section ||
      !allSections ||
      (section &&
        allSections &&
        section !== allSections[allSections.length - 1])
    ) {
      // Only adjust last section.
      return setMarginBottom(0);
    }

    const previewRightContent = document.querySelector(
      ".previewWrapper__preview__right__content"
    );

    if (previewRightContent) {
      const viewHeight =
        document.documentElement.clientHeight || window.innerHeight || 0;

      const sectionHeight = section.getBoundingClientRect()
        ? section.getBoundingClientRect().height ||
          section.offsetHeight ||
          section.clientHeight
        : section.offsetHeight || section.clientHeight;

      const marginBottom = viewHeight - sectionHeight;

      setMarginBottom(marginBottom > 0 ? marginBottom : 0);
    }
  };

  return (
    <div
      className={`previewSectionWrapper ${
        props.subSection ? "subSection" : ""
      }`}
      id={"preview-" + props.name}
      style={{ marginBottom: `${marginBottom}px` }}
    >
      <div className="previewSectionWrapper__previewSection">
        <div className="previewSectionWrapper__previewSection__title">
          {tc[props.name]
            ? textHelper.formatText(tc[props.name])
            : textHelper.formatText(props.name)}
        </div>
        <div className="previewSectionWrapper__previewSection__content">
          {props.renderContent()}
        </div>
      </div>
    </div>
  );
};

export default PreviewSection;
