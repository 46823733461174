import React, { useContext, useEffect, useRef, useState } from 'react';
import LanguageContext from 'language-context';
import { Dropdown, DropdownItem } from 'components/dropdown';
import Icon from 'components/icon';
import Tooltip from 'components/tooltip';
import moment from 'moment';

/***
 * We use this to render headers for components when they are displayed as a widget.
 *
 * @param props.closeButton - func (optional) - Just render a 'close' button, no dashboard.
 * @param props.dashboardItems - array - (optional) - Array with objects which we render as buttons. Objects as follows -> {active (optional) : bool, disabled (optional): bool, icon: string, label: string. onClick: func, selectable (optional): bool,
 * @param props.headline - string - headline
 * @param props.headlineSub string/element - (optional) - Sub headline. Can be string but also an element of choice (usually a loading bar).
 * @param props.alignment styling alignment for widdgetHeader, options are "center", "right", "default". "widgetHeaderWrapper__widgetHeader__${prop}" if none are choosen, left will be used.
 */
const WidgetHeader = (props) => {
  const [responsiveClassWidth, setResponsiveClassWidth] = useState('');
  const observer = useRef(null);
  const widgetHeaderRef = useRef(null);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    _setResponsiveClass();
    observer.current = new ResizeObserver(_setResponsiveClass);
    observer.current.observe(widgetHeaderRef.current);

    const ref = widgetHeaderRef.current;

    return () => observer.current.unobserve(ref);
  }, []);

  const _renderDashboardItems = () => {
    if (!Array.isArray(props.dashboardItems)) {
      return;
    }

    const items = props.dashboardItems.sort((a, b) => {
      return a?.label > b?.label ? 1 : -1;
    });

    return (
      <div className="widgetHeaderWrapper__widgetHeader__right__dashboard">
        <Dropdown
          alignRight={true}
          closeOnItemClick={true}
          headerElement={
            <div className="widgetHeaderWrapper__widgetHeader__right__dashboard__button">
              <Tooltip tooltipContent={tc.openMenu}>
                <Icon val="more" />
              </Tooltip>
            </div>
          }
        >
          {items.map((num) => {
            if (!num) {
              return null;
            }
            return (
              <DropdownItem
                active={num.active}
                disabled={num.disabled}
                hideCheckbox={!num.selectable}
                key={num.label}
                label={num.label}
                onClick={() => {
                  if (typeof num.onClick === 'function') {
                    num.onClick();
                  }
                }}
              />
            );
          })}
        </Dropdown>
      </div>
    );
  };

  const _setResponsiveClass = () => {
    if (!widgetHeaderRef?.current) {
      return;
    }

    const width = widgetHeaderRef.current.getBoundingClientRect().width;
    const breakpoint1 = 220;

    if (width <= breakpoint1) {
      setResponsiveClassWidth('widgetHeaderResponsiveWidth1');
    } else if (width > breakpoint1) {
      setResponsiveClassWidth('');
    }
  };
  const { alignment = 'left' } = props;
  const alignmentClassName = `widgetHeaderWrapper__widgetHeader__${alignment}`;
  return (
    <div
      className={`widgetHeaderWrapper ${responsiveClassWidth}`}
      ref={widgetHeaderRef}
    >
      <div className="widgetHeaderWrapper__widgetHeader">
        <div className={alignmentClassName}>
          {props.headlineElement && props.headlineElement}
          {props.headline && <h2>{props.headline}</h2>}
          {props.headlineSub ? (
            typeof props.headlineSub === 'string' ? (
              <h3>{props.headlineSub}</h3>
            ) : (
              props.headlineSub
            )
          ) : null}
          {props.headlineSub2 ? (
            <h4>
              {moment(props.headlineSub2.from).format('D MMM YYYY') +
                ' - ' +
                moment(props.headlineSub2.to).format('D MMM YYYY')}
              {/* {new Date(props.headlineSub2.from)?.toDateString() +
                " to " +
                new Date(props.headlineSub2.to)?.toDateString()} */}
            </h4>
          ) : null}
        </div>
        {props.dashboardItems?.length && !props.closeButton ? (
          <div className="widgetHeaderWrapper__widgetHeader__right">
            {_renderDashboardItems()}
          </div>
        ) : props.closeButton ? (
          <div className="widgetHeaderWrapper__widgetHeader__right">
            <div className="widgetHeaderWrapper__widgetHeader__right__dashboard">
              <div className="widgetHeaderWrapper__widgetHeader__right__dashboard__button">
                <Tooltip tooltipContent={tc.close}>
                  <Icon val="clear" onClick={props.closeButton} />
                </Tooltip>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default WidgetHeader;
