import { NavLink } from "react-router-dom";
import companyHelper from "shared_helpers/company_helper";
import LanguageContext from "language-context";
import { useContext } from "react";

const ActivitiesFlowItemMailingsAction = (props) => {
  const tc = useContext(LanguageContext);
  const allowedTypes = ["mailings"];
  if (!allowedTypes.includes(props.type)) return null;
  const isCarMailingWithRegNumber =
    props.data?.regNumber && props.data?.mailingsType === "car";
  return (
    <div>
      {tc.mailingTo}{" "}
      <NavLink
        exact
        to={
          companyHelper.isValidOrgNr(props.prospectId)
            ? `/foretag/${props.prospectId}/`
            : `/person/${props.prospectId}`
        }
      >
        {props.name}{" "}
      </NavLink>
      {isCarMailingWithRegNumber ? (
        <>
          <span>{tc.on.toLowerCase()}</span>{" "}
          <NavLink exact to={`/fordon/${props.data.regNumber}`}>
            {props.data.regNumber}
          </NavLink>
        </>
      ) : null}
    </div>
  );
};
export default ActivitiesFlowItemMailingsAction;
