import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import LanguageContext from "language-context";
import { NavLink } from "react-router-dom";
import { updateDeal } from "store/deal/tasks";
import Icon from "components/icon";
import Info from "components/info";
import Loading from "components/loading";
import Popup from "components/popup";
import Search from "components/search";
import Tooltip from "components/tooltip";
import WidgetFooter from "components/widget_footer";
import WidgetHeader from "components/widget_header";

/**
 * This component renders a list of prospects, based on store.deal.prospectsInfo.
 *
 * @param state.props.isRemovable - bool
 */
const DealProspects = (state) => {
  const [prospectRows, setProspectRows] = useState(null); // Holds JSX content.
  const [removeProspectFromDeal, setRemoveProspectFromDeal] = useState(null);
  const [minimize, setMinimize] = useState(false);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    const _renderProspects = () => {
      let data = state.deal.prospectInfo;

      if (!data || (data && data.length === 0)) {
        setMinimize(true);
        return setProspectRows(
          <Info>
            <h4>{tc.noProspects}</h4>
            <p>{tc.prospectsHowTo}</p>
          </Info>
        );
      }

      setMinimize(false);

      setProspectRows(
        data.map((num, i) => {
          return (
            <React.Fragment key={i}>{_renderProspectItem(num)}</React.Fragment>
          );
        })
      );
    };

    const _renderProspectItem = (prospect) => {
      return (
        <div
          className={
            state.props.isRemovable
              ? "dealProspectsWrapper__dealProspects__content__prospects__item"
              : "dealProspectsWrapper__dealProspects__content__prospects__itemNoRemove"
          }
        >
          <div className="dealProspectsWrapper__dealProspects__content__prospects__item__icon">
            <div className="dealProspectsWrapper__dealProspects__content__prospects__item__icon__visible">
              {prospect.type === "company" ? (
                <Icon val="company" />
              ) : (
                <Icon val="person" />
              )}
            </div>
            <div className="dealProspectsWrapper__dealProspects__content__prospects__item__icon__hidden">
              <Tooltip tooltipContent={tc.removeProspect}>
                <Icon
                  val="remove"
                  onClick={() => {
                    setRemoveProspectFromDeal(prospect.id);
                  }}
                />
              </Tooltip>
            </div>
          </div>
          <NavLink
            exact
            to={
              prospect.type === "company"
                ? "/foretag/" + prospect.id
                : "/person/" + prospect.id
            }
            key={prospect.id}
          >
            <div className="dealProspectsWrapper__dealProspects__content__prospects__item__infoHolder">
              <div className="dealProspectsWrapper__dealProspects__content__prospects__item__infoHolder__info">
                <div className="dealProspectsWrapper__dealProspects__content__prospects__item__infoHolder__info__name">
                  {prospect.name}
                </div>
                <div className="dealProspectsWrapper__dealProspects__content__prospects__item__infoHolder__info__address">
                  {prospect.zipMuncipality}
                </div>
              </div>
            </div>
            <div className="dealProspectsWrapper__dealProspects__content__prospects__item__linkHolder">
              <Tooltip tooltipContent={tc.navigateToProspect}>
                <Icon val="navigateNext" />
              </Tooltip>
            </div>
          </NavLink>
        </div>
      );
    };

    if (state.deal?.prospectInfo) {
      _renderProspects();
    }
  }, [state.deal]);

  const _addProspects = async () => {
    let prospects = state.search.selectedAll.map((num) => num.id.toString());
    if (Array.isArray(state.deal.deal.prospects)) {
      prospects = state.deal.deal.prospects.concat(prospects);
    } else {
      prospects = [].concat(prospects);
    }

    return await updateDeal({ prospects: prospects });
  };

  const _removeProspect = async (id) => {
    const prospects = state.deal.deal.prospects.filter(
      (num) => num.toString() !== id.toString()
    );
    return await updateDeal({ prospects: prospects });
  };

  const _stateCheck = () => {
    return !!(state && state.deal && state.deal.prospectInfo);
  };

  return (
    <div className="dealProspectsWrapper">
      <div className="dealProspectsWrapper__dealProspects">
        <div className="dealProspectsWrapper__dealProspects__header">
          <WidgetHeader
            dashboardItems={
              _stateCheck()
                ? [
                    {
                      disabled: !minimize,
                      icon: "maximize",
                      label: tc.maximize,
                      onClick: () => {
                        setMinimize(false);
                      },
                    },
                    {
                      disabled: minimize,
                      icon: "minimize",
                      label: tc.minimize,
                      onClick: () => {
                        setMinimize(true);
                      },
                    },
                  ]
                : null
            }
            headline={tc.prospectMultiple}
            headlineSub={
              _stateCheck() ? tc.handleProspects : <Loading small={true} />
            }
          />
        </div>
        {_stateCheck() ? (
          <div
            className={
              minimize
                ? "dealProspectsWrapper__dealProspects__content minimize"
                : "dealProspectsWrapper__dealProspects__content"
            }
          >
            <div className="dealProspectsWrapper__dealProspects__content__search">
              <div className="dealProspectsWrapper__dealProspects__content__search__top">
                <span>{tc.connectProspects}:</span>
              </div>
              <div className="dealProspectsWrapper__dealProspects__content__search__bottom">
                <Search type="all" save={_addProspects} />
              </div>
            </div>
            <div className="dealProspectsWrapper__dealProspects__content__prospects">
              {prospectRows}
            </div>
          </div>
        ) : null}
      </div>
      {removeProspectFromDeal ? (
        <Popup
          close={() => {
            setRemoveProspectFromDeal(null);
          }}
          size="small"
        >
          <div className="genericPopupContentWrapper">
            <div className="genericPopupContentWrapper__genericPopupContent">
              <div className="genericPopupContentWrapper__genericPopupContent__header">
                <WidgetHeader headline={tc.removeProspect} />
              </div>
              <div className="genericPopupContentWrapper__genericPopupContent__content">
                <p>{tc.ensure}</p>
              </div>
              <div className="genericPopupContentWrapper__genericPopupContent__footer">
                <WidgetFooter
                  buttonOneFunc={async () => {
                    setRemoveProspectFromDeal(null);
                    await _removeProspect(removeProspectFromDeal);
                  }}
                  buttonOneText={tc.remove}
                  buttonTwoFunc={() => {
                    setRemoveProspectFromDeal(null);
                  }}
                  buttonTwoText={tc.cancel}
                />
              </div>
            </div>
          </div>
        </Popup>
      ) : null}
    </div>
  );
};

const MapStateToProps = (state, props) => {
  return {
    deal: state.deal,
    props: props,
    search: state.search,
  };
};

export default connect(MapStateToProps)(DealProspects);
