import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import LanguageContext from 'language-context';
import id from 'valid-objectid';
import { NavLink } from 'react-router-dom';
import Info from 'components/info';
import Tooltip from 'components/tooltip';
import SingleRow from 'components/activity_types_table/single_row';
import Icon from 'components/icon';
import { activityHelper, miscHelper } from 'helpers';

/**
 * Render an activity table of sorts.
 * Receive activities data as props.
 * Activity divided by users+dealers/deals.
 * Can display multiple dealer sections (when activity data includes connections).
 * This is supposed to be used for Activities component as well as Events component.
 *
 * (This is usually referred to as 'types'. It was built before ActivityTypesGroups, which also displays activity types.)
 *
 * @param props.activityTypesToDisplay - array - ["analysis", "mail", "visit" ...]
 * @param props.data - Array with activity rows.
 * @param props.historic boolean - If historic activity or planned activity(events).
 * @param props.target - string - Deal id | prospect id | vehicle reg num | lead id. If target exists, hide displayMode Menu and set mode to user.
 */
const ActivityTypesTable = (props) => {
  const [displayDealsUserIds, setDisplayDealsUserIds] = useState(null); // Display the deal rows for these user ids.
  const [responsiveClassWidth, setResponsiveClassWidth] = useState('');
  const [showInfo, setShowInfo] = useState(false); // Show info box, no data.
  const activityTypesTable = useRef(null);
  const observer = useRef(null);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    setDisplayDealsUserIds(null);
    setShowInfo(!props.activityTypesToDisplay?.length);
  }, [props.activityTypesToDisplay]);

  useEffect(() => {
    _setResponsiveClass();
    observer.current = new ResizeObserver(_setResponsiveClass);
    observer.current.observe(activityTypesTable.current);

    const ref = activityTypesTable.current;

    return () => observer.current.unobserve(ref);
  }, []);

  const _renderHead = () => {
    let columns = [
      <div
        className="activityTypesTableWrapper__activityTypesTable__content__tableColumnHeadersWrapper__tableColumnHeaders__cell"
        key="user"
      >
        {tc.user}
      </div>,
    ];

    props.activityTypesToDisplay.forEach((action) => {
      columns = columns.concat(
        <div
          className="activityTypesTableWrapper__activityTypesTable__content__tableColumnHeadersWrapper__tableColumnHeaders__cell"
          key={action}
        >
          {tc[action]}
        </div>
      );
    });

    return (
      <div className="activityTypesTableWrapper__activityTypesTable__content__tableColumnHeadersWrapper__tableColumnHeaders">
        {columns}
      </div>
    );
  };

  /**
   * Render activity rows.
   *
   * Render:
   * a row for each user
   * a row for each deal (hidden until user row is clicked)
   * a row for each dealer with total sum of the values within that dealer.
   *
   *  Note that this is called recursively to display a rows holder with deal rows below each user row.
   * _renderTableRows -> _renderSingleRowsHolder -> _renderSingleRow -> _renderTableRows, etc...
   *
   * We've chosen to hide comments as an activity group. We do this by never include comments in props.activityTypesToDisplay array.
   * Still we handle comment activities in the code below so we easily can include comments again if we want.
   * Comment rows have somewhat of a different format from the other activities.
   */

  const _renderTableRows = useCallback(
    (
      typeOfRow = 'user', // can be "user" or "deal".
      userId = null,
      dealerId = null,
      level = 0
    ) => {
      const _renderSingleRow = (
        row,
        i,
        rows,
        level,
        dealerId,
        shouldRenderDealerRow
      ) => {
        return (
          <div
            className={`activityTypesTableWrapper__activityTypesTable__content__table__rowsHolder__rows__rowHolder`}
            key={i}
            onClick={() => {
              if (displayDealsUserIds !== null) return;
              setDisplayDealsUserIds({
                userId: row.userId,
                dealerId,
                row,
                rows,
              });
            }}
          >
            <div
              className={`activityTypesTableWrapper__activityTypesTable__content__table__rowsHolder__rows__rowHolder__row`}
            >
              {Object.keys(row).map((num) => {
                if (num === 'userId' || num === 'userName' || num === 'rows') {
                  return null;
                }

                const numberValue = Number.isFinite(+row[num]);
                const value = numberValue ? +row[num] : row[num];
                const dealerTotalSum = Number.isFinite(+row[num])
                  ? rows.filter((row) => {
                      return (
                        +row.user.dealer_id === +dealerId &&
                        ((!row.action && num === 'comment' && row.comment) ||
                          row.action === num)
                      );
                    }).length
                  : null;
                const percentage =
                  Number.isFinite(dealerTotalSum) && value > 0
                    ? (value / dealerTotalSum) * 100
                    : 0;

                return (
                  <div
                    className={`activityTypesTableWrapper__activityTypesTable__content__table__rowsHolder__rows__rowHolder__row__cell`}
                    key={num}
                  >
                    {numberValue ? (
                      !shouldRenderDealerRow ? (
                        <>
                          <div className="activityTypesTableWrapper__activityTypesTable__content__table__rowsHolder__rows__rowHolder__row__cell__value">
                            <span>{value}</span>
                          </div>
                          <div className="activityTypesTableWrapper__activityTypesTable__content__table__rowsHolder__rows__rowHolder__row__cell__barHolder">
                            <div
                              className="bar"
                              style={{
                                width:
                                  percentage < 1
                                    ? '0%'
                                    : percentage.toString() + '%',
                              }}
                            />
                          </div>
                        </>
                      ) : (
                        <Tooltip
                          tooltipContent={`${value} ${tc.of.toLowerCase()} ${tc.total.toLowerCase()} ${dealerTotalSum} ${tc.for.toLowerCase()} ${tc.facility.toLowerCase()}`}
                        >
                          <div className="activityTypesTableWrapper__activityTypesTable__content__table__rowsHolder__rows__rowHolder__row__cell__value">
                            <span>{value}</span>
                          </div>
                          <div className="activityTypesTableWrapper__activityTypesTable__content__table__rowsHolder__rows__rowHolder__row__cell__barHolder">
                            <div
                              className="bar"
                              key={num}
                              style={{
                                width:
                                  percentage < 1
                                    ? '0%'
                                    : percentage.toString() + '%',
                              }}
                            />
                          </div>
                        </Tooltip>
                      )
                    ) : (
                      <div
                        className={`activityTypesTableWrapper__activityTypesTable__content__table__rowsHolder__rows__rowHolder__row__cell__value`}
                      >
                        <span>{value}</span>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        );
      };

      const _renderSingleRowsHolder = (
        dealers,
        dealerId,
        level,
        userId,
        types,
        rows
      ) => {
        const dealerObj = dealers[dealerId];
        const shouldRenderDealerRow =
          (Object.keys(dealers).length > 1 || dealerObj.rows.length > 1) &&
          level === 0;

        return (
          <div
            className={`activityTypesTableWrapper__activityTypesTable__content__table__rowsHolder ${
              level > 0
                ? displayDealsUserIds?.userId === userId
                  ? 'open'
                  : 'closed'
                : ''
            }`}
            key={dealerObj.name + level}
          >
            <div className="activityTypesTableWrapper__activityTypesTable__content__table__rowsHolder__rows">
              {dealerObj.rows.map((row, i) =>
                _renderSingleRow(
                  row,
                  i,
                  rows,
                  level,
                  dealerId,
                  shouldRenderDealerRow
                )
              )}
              {shouldRenderDealerRow ? (
                // Add a summary row for dealer total values.
                <div
                  className="activityTypesTableWrapper__activityTypesTable__content__table__rowsHolder__rows__rowHolder summary"
                  key={dealerObj.name}
                >
                  <div className="activityTypesTableWrapper__activityTypesTable__content__table__rowsHolder__rows__rowHolder__row">
                    <div className="activityTypesTableWrapper__activityTypesTable__content__table__rowsHolder__rows__rowHolder__row__cell">
                      <div className="activityTypesTableWrapper__activityTypesTable__content__table__rowsHolder__rows__rowHolder__row__cell__value">
                        <Tooltip tooltipContent={dealerObj.name}>
                          <span>{dealerObj.name}</span>
                        </Tooltip>
                      </div>
                    </div>
                    {Object.keys(types).map((type, i) => {
                      const dealerValue = rows.filter((row) => {
                        return (
                          +row.user.dealer_id === +dealerId &&
                          ((!row.action && type === 'comment' && row.comment) ||
                            row.action === type)
                        );
                      }).length;

                      return (
                        <div
                          className={`activityTypesTableWrapper__activityTypesTable__content__table__rowsHolder__rows__rowHolder__row__cell`}
                          key={dealerId + type + i}
                        >
                          <div
                            className={`activityTypesTableWrapper__activityTypesTable__content__table__rowsHolder__rows__rowHolder__row__cell__value`}
                          >
                            <span>{dealerValue}</span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        );
      };

      const _getDealersObj = (
        rows,
        dealerId,
        userId,
        typeOfRow,
        types,
        skipTooltip = false
      ) => {
        let dealersObj = {};

        let grouped = {};

        for (let i = 0; i < rows?.length; i++) {
          if (
            (!dealerId && !dealersObj[rows[i]?.user?.dealer_id]) ||
            (dealerId &&
              +rows[i]?.user?.dealer_id === +dealerId &&
              !dealersObj[rows[i]?.user?.dealer_id])
          ) {
            let filteredRows = rows.filter((row) => {
              if (!row['user_id'] && !row['targetDeal']) return false;
              if (!row['targetDeal'] && typeOfRow !== 'user') return false;
              if (userId) {
                return (
                  row?.user?.dealer_id === rows[i].user.dealer_id &&
                  row?.user?.id === userId
                );
              } else {
                return row?.user?.dealer_id === rows[i].user.dealer_id;
              }
            });

            if (typeOfRow === 'user') {
              grouped = miscHelper.groupBy(filteredRows, 'user_id');
            } else {
              grouped = miscHelper.groupBy(filteredRows, 'targetDeal');
            }

            if (grouped?.length === 0 || Object.keys(grouped)?.length === 0)
              return;

            let dealerRows = [];
            const typesObj = { ...types };

            // Put together the dealers object which is what we use to render.
            Object.keys(grouped).forEach((key) => {
              Object.keys(types).forEach((type) => {
                typesObj[type] = rows.filter((row) => {
                  if (!row.action && type === 'comment' && row.comment) {
                    // Comment activity row/object doesn't have "action" property.
                    return typeOfRow === 'user'
                      ? +key === +row.user.id
                      : key === row.targetDeal;
                  } else {
                    return typeOfRow === 'user'
                      ? +key === +row.user.id && row.action === type
                      : key === row.targetDeal && row.action === type;
                  }
                }).length;
              });

              // Flag for if there are no values for the active activity types to display.
              let haveValues = false;
              props.activityTypesToDisplay.forEach((type) => {
                if (typesObj[type] && typesObj[type] > 0) {
                  haveValues = true;
                }
              });

              if (!haveValues) {
                return;
              }

              if (typeOfRow === 'user') {
                const row = rows.find((num) => +num.user?.id === +key);
                const name = row?.user?.name ? row.user.name : tc.nameMissing;
                dealerRows = dealerRows.concat({
                  user: skipTooltip ? (
                    name
                  ) : (
                    <Tooltip tooltipContent={name} inline={true}>
                      <span>{name}</span>
                    </Tooltip>
                  ),
                  userId: row?.user?.id ? row.user.id : null,
                  userName: name,
                  rows: rows,
                  ...typesObj,
                });
              } else {
                const row = rows.find((num) => num.targetDeal === key);
                const name = row?.deal?.name
                  ? row.deal.name
                  : row?.name
                  ? row.name
                  : tc.nameMissing;
                const dealLink = row ? (
                  skipTooltip ? (
                    name
                  ) : (
                    <Tooltip
                      tooltipContent={`${tc.navigateToDeal} ${name}`}
                      inline={true}
                    >
                      <NavLink exact to={'/affar/' + key}>
                        {name}
                      </NavLink>
                    </Tooltip>
                  )
                ) : (
                  tc.nameMissing
                );

                dealerRows = dealerRows.concat({
                  deal: dealLink,
                  userId: row?.user?.id ? row.user.id : null,
                  ...typesObj,
                });
              }

              dealersObj[rows[i].user.dealer_id] = {
                name: rows[i].user.dealerName,
                rows: dealerRows.sort((a, b) => (a.user > b.user ? 1 : -1)),
              };
            });
          }
        }
        return dealersObj;
      };
      let types = {};
      props.activityTypesToDisplay.forEach((key) => {
        types[key] = '';
      });

      const rows = activityHelper.getValidRowsForType(
        props.data,
        typeOfRow,
        true
      );
      //const rows = _getValidRowsForType(props.data, typeOfRow);
      const dealers = _getDealersObj(rows, dealerId, userId, typeOfRow, types);

      if (!Object.keys(dealers).length) {
        if (level === 0) {
          setShowInfo(true);
        }
        return null;
      }

      // Render a rows holder for each dealer.

      return Object.keys(dealers).map((key) => {
        return key === 'userName'
          ? null
          : _renderSingleRowsHolder(dealers, key, level, userId, types, rows);
      });
    },
    [displayDealsUserIds, tc, props.data, props.activityTypesToDisplay]
  );

  const memoizedTableRows = useMemo(() => {
    return _renderTableRows('user', null, null, 0);
  }, [_renderTableRows]);

  const _setResponsiveClass = () => {
    if (!activityTypesTable?.current) {
      return;
    }

    const width = activityTypesTable.current.getBoundingClientRect().width;
    const breakpoint1 = 550;
    const breakpoint2 = 440;

    if (width <= breakpoint2) {
      setResponsiveClassWidth('activityTypesTableResponsiveWidth2');
    } else if (width <= breakpoint1 && width > breakpoint2) {
      setResponsiveClassWidth('activityTypesTableResponsiveWidth1');
    } else if (width > breakpoint1) {
      setResponsiveClassWidth('');
    }
  };

  return (
    <div className="activityTypesTableWrapper" ref={activityTypesTable}>
      {displayDealsUserIds !== null ? (
        <button
          className="backIcon"
          onClick={() => {
            setDisplayDealsUserIds(null);
          }}
        >
          <Icon val="arrowBack" />
        </button>
      ) : null}

      <div className="activityTypesTableWrapper__activityTypesTable">
        <div className="activityTypesTableWrapper__activityTypesTable__content">
          {showInfo ? (
            <Info>
              <h4>{tc.noActivity}</h4>
              <p>{tc.noActivityTypesTableWhy}</p>
            </Info>
          ) : (
            <>
              <div
                className={`activityTypesTableWrapper__activityTypesTable__content__tableColumnHeadersWrapper ${responsiveClassWidth}`}
              >
                {_renderHead()}
              </div>
              <div
                className={`activityTypesTableWrapper__activityTypesTable__content__table ${responsiveClassWidth}`}
              >
                {displayDealsUserIds === null ? (
                  memoizedTableRows
                ) : (
                  <>
                    <SingleRow
                      row={displayDealsUserIds.row}
                      dealerId={displayDealsUserIds.dealerId}
                      rows={displayDealsUserIds.rows}
                    />
                    {_renderTableRows(
                      'deal',
                      displayDealsUserIds.userId,
                      displayDealsUserIds.dealerId,
                      1
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ActivityTypesTable;
