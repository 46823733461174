const regexOrgnrDashed = /^[\d]{6}[-][\d]{0,4}$/;
const regexSanitizeUserInput = /[~\\/#%&*{}:;[\]<>?|"'()'$]/g;
export const textHelper = {
  /**
   * Helper function, return strings with the format we choose.
   * Remove dash, return lower case with first letter capital.
   * If capitalize === true, return whole string capitalized.
   *
   * @param capitalize
   * @param text
   */
  formatText: (text, capitalize = false) => {
    if (!text) {
      return null;
    }
    if (text.includes("-")) {
      let splits = text
        .split("-")
        .map((num) => num.charAt(0).toUpperCase() + num.slice(1).toLowerCase());
      return splits.join("-");
    } else if (capitalize) {
      let splits = text
        .split(" ")
        .map((num) => num.charAt(0).toUpperCase() + num.slice(1).toLowerCase());
      return splits.join(" ");
    } else {
      return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    }
  },
  regexCompanyName: new RegExp(/^[a-zåäö0-9\-\s.]+$/i),
  regexEmail: new RegExp(
    /^[a-zA-Z0-9_.+\-]+@[a-zA-Z0-9\-]+\.[a-zA-Z0-9\-.]+$/i
  ),
  regexOrgnr: new RegExp(/^[\d]{6}[-]?[\d]{4}$/),
  regexPhoneNumber: new RegExp(/^[\d\-]+$/),
  regexName: new RegExp(/^[a-zåäö\-\s]+$/i),
  removeDashFromOrgnr: val => regexOrgnrDashed.test(val) ? val.replace('-','') : val,
  sanitizeUserInput: term => term.replace(regexSanitizeUserInput,'')
};
