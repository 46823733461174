import { store } from 'store';
import { request, requestBody } from 'helpers';
import { showFlashMessage } from 'store/flash_messages/tasks';
import { leadsActionTypes } from './actions';
import debounce from 'debounce';
import { event } from 'tracking';
import * as text from 'text-content';
import { clearCompanyContracts } from 'store/company/tasks';

/**
 * Fetch leads with pagination and other params.
 *
 * @param payload.limit - number
 * @param payload.order - 'asc' | 'desc'
 * @param payload.orderBy - string (optional) - Column name to apply order on.
 * @param payload.query - string (optional) - Search query
 * @param payload.skip - number
 */
const getPaginatedLeadsDebounced = async (payload) => {
  try {
    if (!payload || (payload && !payload.hasOwnProperty('limit'))) {
      return console.error(
        'Missing params in getPaginatedLeadsDebounced',
        payload
      );
    }

    const data = await request({
      data: {
        limit: payload.limit,
        order: payload.order,
        orderBy: payload.orderBy,
        query: payload.query,
        skip: payload.skip,
      },
      method: 'get',
      url: '/leads/getPaginatedLeads',
    });

    if (data instanceof Error) {
      throw data;
    }

    if (!data) {
      return store.dispatch({
        type: leadsActionTypes.LEADS_SET_LEADS,
        payload: {
          items: [],
          latestBatchCount: 0,
          totalCount: 0,
        },
      });
    } else {
      return store.dispatch({
        type: leadsActionTypes.LEADS_SET_LEADS,
        payload: data,
      });
    }
  } catch (err) {
    return console.error('Error in getPaginatedLeadsDebounced', err);
  }
};

export const getPaginatedLeads = debounce(getPaginatedLeadsDebounced, 500);

/**
 * Remove leads.
 *
 * @param payload.rows - Array<string>
 */
export const deleteLeads = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    event({
      category: 'leads',
      action: 'leads_remove_leads',
    });

    const data = await request({
      method: 'post',
      url: '/leads/delete',
      data: {
        rows: payload.rows,
      },
    });

    if (data instanceof Error) {
      throw data;
    }

    let leadsData = store.getState().leads.data;
    leadsData.totalCount = leadsData.totalCount - payload.rows.length;
    leadsData.items = leadsData.items.filter(
      (num) => !payload.rows.includes(num._id)
    );

    store.dispatch({
      type: leadsActionTypes.LEADS_SET_LEADS,
      payload: leadsData,
    });
    showFlashMessage(tc.deletedLeads, 'success');
  } catch (err) {
    showFlashMessage(tc.couldNotRemoveLeads, 'fail');
    return console.error('Error in deleteLeads', err);
  }
};

/**
 * Move leads to agile.
 *
 * @param payload.rows
 * @param payload.userId
 */
export const moveToAgile = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    event({
      category: 'leads',
      action: 'leads_move_agile',
    });

    const data = await request({
      method: 'post',
      url: '/leads/createDeal',
      data: {
        ids: payload.rows,
        userId: payload.userId,
      },
    });

    if (data instanceof Error) {
      throw data;
    }

    showFlashMessage(tc.movedLeadsToAgile, 'success');

    getPaginatedLeads({
      limit: 10,
      skip: 0,
      order: 'desc',
      orderBy: '',
    });
  } catch (err) {
    showFlashMessage(tc.couldNotMoveToAgile, 'fail');
    return console.error('Error in moveToAgile', err);
  }
};

/**
 * Move leads to list.
 *
 * @param payload.mode - 'newList' | 'savedLists'
 * @param payload.rows
 * @param payload.value
 */
export const moveToList = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    event({
      category: 'leads',
      action: 'leads_move_list',
    });

    const data = await request({
      method: 'post',
      url: '/leads/saveToList/',
      data: payload,
    });

    if (data instanceof Error) {
      throw data;
    }

    showFlashMessage(
      data.success + ' ' + tc.movedLeadsToList + ' ' + data.total,
      'success'
    );

    getPaginatedLeads({
      limit: 50,
      skip: 0,
      order: 'desc',
      orderBy: '',
    });
  } catch (err) {
    showFlashMessage(tc.couldNotMoveToList, 'fail');
    return console.error('Error in moveToList', err);
  }
};

export const moveAllToList = async (payload: {
  mode: 'existing' | 'new';
  listId: number | null;
  name: string | null;
}) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;
  try {
    const { mode, listId, name } = payload;
    if (
      !['existing', 'new'].includes(mode) ||
      (mode === 'existing' && !listId) ||
      (mode === 'new' && !name)
    )
      throw new Error('Bad params in moveAllToList');
    const res = await request({
      method: 'POST',
      url: '/leads/moveAll',
      data: {
        mode,
        listId: mode === 'new' ? null : listId,
        listName: mode === 'new' ? name : null,
      },
    });
    if (!res || res instanceof Error) throw res;
    showFlashMessage(tc.leadsMovedAll, 'success');
  } catch (err) {
    showFlashMessage(tc.leadsCouldNotMove, 'fail');
    console.error('Error in moveAllToList: ', err);
  }
};

export const uploadAvtalskunder = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    const { file, selectedMethod, selectedContractList, contractListName } =
      payload;

    const formData = new FormData();
    formData.append('file', file, file.name);

    const data = await fetch('/leads/uploadXLSX', {
      method: 'POST',
      body: formData,
    });

    const json = await data.json();

    if (data instanceof Error) {
      throw data;
    }

    if (selectedMethod || selectedContractList || contractListName) {
      store.dispatch({
        type: leadsActionTypes.LEADS_UPLOAD_SET_ATTRIBUTES,
        payload: {
          selectedMethod,
          selectedContractList,
          contractListName,
        },
      });
    }

    store.dispatch({
      type: leadsActionTypes.LEADS_UPLOAD_SET_DATA,
      payload: json,
    });

    return { success: true };
  } catch (err) {
    showFlashMessage(tc.contractUploadFailed, 'fail');
    return { success: false };
  }
};

export const uploadLeads = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    const formData = new FormData();
    formData.append('file', payload, payload.name);

    const data = await fetch('/leads/uploadXLSX', {
      method: 'POST',
      body: formData,
    });

    const json = await data.json();

    if (data instanceof Error) {
      throw data;
    }

    store.dispatch({
      type: leadsActionTypes.LEADS_UPLOAD_SET_DATA,
      payload: json,
    });

    return { success: true };
  } catch (err) {
    showFlashMessage(tc.leadsUploadFailed, 'fail');
    return { success: false };
  }
};

export const setLeadsUploadChoosenColumns = (payload) => {
  store.dispatch({
    type: leadsActionTypes.LEADS_UPLOAD_SET_CHOOSEN_COLUMNS,
    payload,
  });
};

export const submitAvtalskunder = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  const resetData = () => {
    clearCompanyContracts();
    store.dispatch({ type: leadsActionTypes.LEADS_UPLOAD_RESET_DATA });
  };
  try {
    event({
      category: 'leads',
      action: 'leads_submit',
    });

    const data = await requestBody({
      url: '/leads/uploadXLSXSaveData',
      method: 'post',
      data: payload,
    });

    if (data instanceof Error) {
      throw data;
    }

    showFlashMessage(tc.contractsUploadSuccess, 'success');

    setTimeout(resetData, 1000);

    return { success: true };
  } catch (err) {
    showFlashMessage(tc.contractsUploadSaveFailed, 'fail');
    setTimeout(resetData, 1000);

    return { success: false };
  }
};

export const submitLeads = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    event({
      category: 'leads',
      action: 'leads_submit',
    });

    const data = await requestBody({
      url: '/leads/uploadXLSXSaveData',
      method: 'post',
      data: payload,
    });

    if (data instanceof Error) {
      throw data;
    }

    showFlashMessage(tc.leadsSuccessfullySavedUploadedLeads, 'success');

    setTimeout(() => {
      store.dispatch({
        type: leadsActionTypes.LEADS_UPLOAD_RESET_DATA,
      });
    }, 1000);

    return { success: true };
  } catch (err) {
    showFlashMessage(tc.leadsCouldNotSaveUploadedLeads, 'fail');
    setTimeout(() => {
      store.dispatch({
        type: leadsActionTypes.LEADS_UPLOAD_RESET_DATA,
      });
    }, 1000);

    return { success: false };
  }
};

export const resetUpload = () => {
  store.dispatch({
    type: leadsActionTypes.LEADS_UPLOAD_RESET_DATA,
  });
};

export const getContractLists = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    const data = await request({
      method: 'GET',
      url: '/lists/getContractLists',
    });

    if (data instanceof Error) {
      throw data;
    }

    return data;
  } catch (err) {
    showFlashMessage(tc.contractGetFailed, 'fail');
    return [];
  }
};
