import React, { useContext } from 'react';
import moment from 'moment';
import LanguageContext from 'language-context';
import companyHelper from 'shared_helpers/company_helper';
import { setPreviewId } from 'store/agile/tasks';
import Icon from 'components/icon';
import Tooltip from 'components/tooltip';
import { activityHelper } from 'helpers';
import ProspectSource from './prospect_source';

/**
 * Card component to be used with react-trello.
 *
 * This expects an agile column item object as props.
 * We do not use the props that react-trello mesh together with the item object,
 * so basically this can be used in any kanban board column with our items.
 *
 * An item/card can be either a prospect or a deal.
 * We differentiate them by checking for property '_id' (deal) or 'prospectId' (prospect).
 */
const AgileKanbanBoardCard = (props) => {
  const tc = useContext(LanguageContext);

  const _addActivity = (e) => {
    e.stopPropagation();
    props.addActivity(props.id, props);
  };

  const _openItem = (e) => {
    e.stopPropagation();

    // Set either deal _id or prospect id.
    // Set prospect id along with list id since prospect column can contain multiple items with
    // same prospect id from different lists, and we need this id to be unique.
    const idToSet =
      props.itemType === 'deal'
        ? props.id
        : `${props.prospectId}/${props.listId}`;
    setPreviewId(idToSet);
  };

  /**
   * Render event reminder icon based on events.
   * If no events scheduled, render a default 'add event' icon.
   */
  const _renderEvent = () => {
    let element = null;

    if (props.events && props.events.length) {
      let date = null;
      props.events.forEach((event) => {
        if (!date && event.event_date) {
          date = new Date(event.event_date);
        } else if (event.event_date && new Date(event.event_date) < date) {
          date = new Date(event.event_date);
        }
      });

      if (date) {
        const eventClass = activityHelper.getEventClass(date);
        let addActivity = (
          <div className="agileKanbanBoardItemWrapper__agileKanbanBoardItem__content__event__defaultHidden">
            <Tooltip tooltipContent={tc.addActivity}>
              <Icon val="add" onClick={_addActivity} />
            </Tooltip>
          </div>
        );
        const getClassNameEnding = (className) => {
          if (className === 'withinOneDay') {
            return 'withinOneDay';
          } else if (className === 'passed') {
            return 'passed';
          } else if (className === 'event' || className === 'hasEvent') {
            return 'hasEvent';
          } else {
            return 'defaultVisible';
          }
        };

        element = (
          <div className="agileKanbanBoardItemWrapper__agileKanbanBoardItem__content__event">
            {addActivity}
            <div
              className={`agileKanbanBoardItemWrapper__agileKanbanBoardItem__content__event__${getClassNameEnding(
                eventClass
              )}`}
            >
              <Icon val="time" />
            </div>
          </div>
        );
      }
    }

    if (!element) {
      element = (
        <div className="agileKanbanBoardItemWrapper__agileKanbanBoardItem__content__event">
          <div className="agileKanbanBoardItemWrapper__agileKanbanBoardItem__content__event__defaultVisible">
            <Tooltip tooltipContent={tc.addActivity}>
              <Icon val="add" onClick={_addActivity} />
            </Tooltip>
          </div>
        </div>
      );
    }

    return element;
  };

  const _renderDealItem = () => {
    return (
      <div
        className={
          props.openInPreview
            ? 'agileKanbanBoardItemWrapper__agileKanbanBoardItem openInPreview'
            : 'agileKanbanBoardItemWrapper__agileKanbanBoardItem'
        }
      >
        <div
          className="agileKanbanBoardItemWrapper__agileKanbanBoardItem__content"
          onClick={_openItem}
        >
          {_renderEvent()}
          <div className="agileKanbanBoardItemWrapper__agileKanbanBoardItem__content__main">
            <div className="agileKanbanBoardItemWrapper__agileKanbanBoardItem__content__main__mainTop">
              <div className="agileKanbanBoardItemWrapper__agileKanbanBoardItem__content__main__mainTop__dealName">
                {props.name}
                {_renderInformationHolder()}
              </div>
            </div>
            <ProspectSource source={props.deal_source} />
            {props.updated ? (
              <div className="agileKanbanBoardItemWrapper__agileKanbanBoardItem__content__main__mainBottom">
                <span className="agileKanbanBoardItemWrapper__agileKanbanBoardItem__content__main__mainBottom__label">
                  {tc.updated}:
                </span>
                <span className="agileKanbanBoardItemWrapper__agileKanbanBoardItem__content__main__mainBottom__value">
                  {moment(new Date(props.updated), 'DD/MM/YYYY').fromNow()}
                </span>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  const _renderInformationHolder = () => {
    return (
      <div className="agileKanbanBoardItemWrapper__agileKanbanBoardItem__content__main__mainTop__informationHolder">
        <div className="agileKanbanBoardItemWrapper__agileKanbanBoardItem__content__main__mainTop__informationHolder__icon">
          {props.activeDeals ? <Icon val="agile" /> : null}
          {props.deal_source === 'shared' ? <Icon val="fire" /> : null}
        </div>
        {props.contractData?.length > 0 ? (
          <div className="agileKanbanBoardItemWrapper__agileKanbanBoardItem__content__main__mainTop__informationHolder__icon dark">
            <Tooltip
              tooltipContent={
                <div>
                  <p>{tc.connectedContracts}</p>
                  {props.contractData.map((c) => (
                    <p key={c.contract_customer_id}>{c.contract_customer_id}</p>
                  ))}
                </div>
              }
            >
              <Icon val="infoOutlined" />
            </Tooltip>
          </div>
        ) : null}
      </div>
    );
  };

  const _renderProspectItem = () => {
    return (
      <div
        className={
          props.openInPreview
            ? 'agileKanbanBoardItemWrapper__agileKanbanBoardItem openInPreview'
            : 'agileKanbanBoardItemWrapper__agileKanbanBoardItem'
        }
      >
        <div
          className="agileKanbanBoardItemWrapper__agileKanbanBoardItem__content"
          onClick={_openItem}
        >
          <div className="agileKanbanBoardItemWrapper__agileKanbanBoardItem__content__user">
            {companyHelper.isValidOrgNr(props.prospectId) ? (
              <Icon val="company" />
            ) : (
              <Icon val="user" />
            )}
          </div>
          <div className="agileKanbanBoardItemWrapper__agileKanbanBoardItem__content__main">
            <div className="agileKanbanBoardItemWrapper__agileKanbanBoardItem__content__main__mainTop">
              <div className="agileKanbanBoardItemWrapper__agileKanbanBoardItem__content__main__mainTop__prospectName">
                {props.name}
                {_renderInformationHolder()}
                <ProspectSource source={props.prospectSource} />
              </div>
            </div>
            <p className="listDate">
              {tc.generated}:{' '}
              {props.listDate
                ? new Date(props.listDate).toLocaleDateString('se')
                : tc.dataMissing}
            </p>
          </div>
        </div>
      </div>
    );
  };

  return props.hide ? null : (
    <div className="agileKanbanBoardItemWrapper">
      {props.prospectId ? _renderProspectItem() : _renderDealItem()}
    </div>
  );
};

export default AgileKanbanBoardCard;
