import React from 'react';
import MenuDelimited from './menu_delimited';
import MenuFloatFree from './menu_float_free';
import MenuLarge from './menu_large';
import MenuRegular from './menu_regular';

/**
 * Render a menu.
 */
const Menu = (props) => {
  switch (props.type) {
    case 'delimited':
      return (
        <MenuDelimited
          centered={props.centered}
          items={props.items}
          skipSort={props.skipSort}
          startFromRight={props.startFromRight}
        />
      );
    case 'floatFree':
      return (
        <MenuFloatFree
          centered={props.centered}
          items={props.items}
          border={props.border}
          skipSort={props.skipSort}
        />
      );
    case 'large':
      return <MenuLarge items={props.items} />;
    default:
      return (
        <MenuRegular
          items={props.items}
          skipSort={props.skipSort}
          autoClose={props.autoClose}
        />
      );
  }
};

export default Menu;
