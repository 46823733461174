import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { miscHelper } from "helpers";
import cssVariables from "../../styles/_exported_variables.module.scss";
import Icon from "components/icon";
import Popper from "components/popper";

const MenuSearch = (state) => {
  const [searchStyling, setSearchStyling] = useState({
    maxHeight: 0,
    top: 0,
    left: 0,
    minWidth: 0,
    maxWidth: 0,
  });
  const [showItems, setShowItems] = useState(true);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const menuSearchInputRef = useRef(null);
  const menuSearchRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      _adjustSearchStyling(state.user.responsiveMode);

      const menu = document.querySelector(".menuWrapper");
      if (menu) {
        menu.addEventListener("scroll", () => {
          _adjustSearchStyling(state.user.responsiveMode);
        });
      }
    }, 1000);

    window.addEventListener("resize", () => {
      _adjustSearchStyling(state.user.responsiveMode);
    });
    return () => {
      clearTimeout(timer);
      const menu = document.querySelector(".menuWrapper");
      if (menu) {
        menu.removeEventListener("scroll", () => {
          _adjustSearchStyling(state.user.responsiveMode);
        });
      }
      window.removeEventListener("resize", () => {
        _adjustSearchStyling(state.user.responsiveMode);
      });
    };
  }, [state.user.responsiveMode]);

  useEffect(() => {
    /**
     * When clicking outside search items box, close it.
     */
    const _unmountDropdown = (e) => {
      if (miscHelper.clickUnmount(e, menuSearchRef, false)) {
        setShowItems(false);
        setShowSearchInput(false);
        state.props.onChange(null);
        if (menuSearchInputRef?.current) {
          menuSearchInputRef.current.value = "";
        }
      }
    };

    window.addEventListener("mousedown", _unmountDropdown);
    return () => window.removeEventListener("mousedown", _unmountDropdown);
  }, []);

  useEffect(() => {
    // Adjust suggestions to put active on top.
    if (Array.isArray(state.props.suggestions)) {
      if (!state.props.suggestions.length) {
        return setSuggestions([]);
      }

      const active = state.props.suggestions
        .filter((x) => x.active)
        .sort((a, b) => {
          return a.text.toLowerCase() < b.text.toLowerCase() ? -1 : 1;
        });

      const unactive = state.props.suggestions
        .filter((x) => !x.active)
        .sort((a, b) => {
          return a.text.toLowerCase() < b.text.toLowerCase() ? -1 : 1;
        });

      setSuggestions(active.concat(unactive));
    }
  }, [state.props.suggestions]);

  /**
   * Adjust position for search suggestions.
   * (Because we use scroll on Menu we cannot use position absolute,s o we use Popper component.)
   *
   * Set position top and left in correlation to search input holder (needs to have position relative),
   * with possible offsets in props.
   * Set width in correlation to dropdownWrapper, with possible extra width in props.
   * Make sure we dont go outside viewport.
   * */
  const _adjustSearchStyling = (responsiveMode) => {
    if (menuSearchInputRef?.current) {
      const node = ReactDOM.findDOMNode(menuSearchInputRef.current);
      if (node) {
        let left;
        let maxWidth;
        let minWidth;
        if (responsiveMode) {
          left = 0;
          maxWidth = "100%";
          minWidth = "100%";
        } else {
          left = node.getBoundingClientRect().left;
          if (left < +cssVariables.widthMinimizedNavigation.replace("px", "")) {
            // Should never cover left minimized navigation.
            left = cssVariables.widthMinimizedNavigation;
          }

          maxWidth =
            typeof left === "string"
              ? window.innerWidth - left.replace("px", "")
              : window.innerWidth - left;
          if (maxWidth > 420) {
            maxWidth = 420;
          }

          minWidth = node.getBoundingClientRect().width;
          if (minWidth > maxWidth) {
            minWidth = maxWidth;
          }

          minWidth = `${minWidth}px`;
          maxWidth = `${maxWidth}px`;
        }

        setSearchStyling({
          maxHeight: `calc(100vh - ${
            node.getBoundingClientRect().top +
            node.getBoundingClientRect().height +
            12
          }px)`,
          top:
            node.getBoundingClientRect().top +
            node.getBoundingClientRect().height +
            8,
          left: left,
          minWidth: minWidth,
          maxWidth: maxWidth,
        });
      }
    }
  };

  return (
    <div className="menuSearchWrapper" ref={menuSearchRef}>
      <div
        className={
          state.props.disabled
            ? "menuSearchWrapper__menuSearch disabled"
            : state.props.active
            ? "menuSearchWrapper__menuSearch active"
            : "menuSearchWrapper__menuSearch"
        }
        onClick={() => {
          setShowSearchInput(true);
          setShowItems(true);
          state.props.onChange("");
          setTimeout(() => {
            menuSearchInputRef?.current && menuSearchInputRef.current.focus();
            setShowItems(true);
          }, 300);
        }}
      >
        <Icon val="search" />
        {state.props.label}
        <input
          className={showSearchInput ? null : "minimize"}
          onChange={(e) => {
            state.props.onChange(e.target.value);
          }}
          ref={menuSearchInputRef}
        />
      </div>
      {suggestions.length ? (
        <Popper>
          <div
            className={
              showItems
                ? "menuSearchSuggestionsWrapper"
                : "menuSearchSuggestionsWrapper minimize"
            }
            style={{
              maxHeight: searchStyling.maxHeight,
              top: searchStyling.top,
              left: searchStyling.left,
              minWidth: searchStyling.minWidth,
              maxWidth: searchStyling.maxWidth,
            }}
          >
            <div className="menuSearchSuggestionsWrapper__menuSearchSuggestions">
              {suggestions.map((suggestion) => {
                return (
                  <div
                    className={
                      suggestion.active
                        ? "menuSearchSuggestionsWrapper__menuSearchSuggestions__itemWrapper active"
                        : "menuSearchSuggestionsWrapper__menuSearchSuggestions__itemWrapper"
                    }
                    key={suggestion.val}
                    onClick={() => {
                      state.props.onSelect(suggestion.val);
                    }}
                  >
                    <div className="menuSearchSuggestionsWrapper__menuSearchSuggestions__itemWrapper__item">
                      <div className="menuSearchSuggestionsWrapper__menuSearchSuggestions__itemWrapper__item__left">
                        {suggestion.text}
                      </div>
                      <div className="menuSearchSuggestionsWrapper__menuSearchSuggestions__itemWrapper__item__right">
                        <Icon val={suggestion.active ? "check" : "checkbox"} />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Popper>
      ) : null}
    </div>
  );
};

const MapStateToProps = (state, props) => {
  return {
    props: props,
    user: state.user,
  };
};

export default connect(MapStateToProps)(MenuSearch);
