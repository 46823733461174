import { store } from "store";
import { fetchRequest } from "helpers/request_helper";
import * as text from "text-content";
import { showFlashMessage } from "../flash_messages/tasks";
import pbiActionType, { pbiActionTypes } from './actions';

const userValidationURL = '/pbi';

export const getExportsList = async (userId) => {
  try {
    const userIdPrefix = 'bp';
    const scope = 'Bilprospekt';

    const res = await fetchRequest(`${userValidationURL}/exports?userId=${userIdPrefix + userId}&scope=${scope}`, {
      method: 'GET',
    });

    return res.status === 200 ? res.data : null;
  } catch (err) {
    const tc = store.getState().user?.info?.lang
      ? store.getState().user.info.lang === "en"
        ? text.english
        : text.swedish
      : text.swedish;

    console.error(err);
    showFlashMessage(tc.thereWasAProblemContactCustomerService, 'error')
    return null;
  }
}

export const deletePdfExport = async (key) => {
  try {
    const res = await fetchRequest(`/pbi/pdf/delete`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ s3Key: key })
    })

    if (res.status === 200) {
      showFlashMessage('PDF-fil har tagigts bort', 'success');
    } else {
      throw new Error();
    }
  } catch (err) {
    const tc = store.getState().user?.info?.lang
      ? store.getState().user.info.lang === "en"
        ? text.english
        : text.swedish
      : text.swedish;

    console.error(err);
    showFlashMessage(tc.thereWasAProblemContactCustomerService, 'error')
    return null;
  }
}

export const getS3Link = async (key, filename) => {
  try {
    const res = await fetchRequest('/pbi/pdf/exports/download', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ s3Key: key, filename })
    })

    return res.status === 200 ? res.data : null;
  } catch (err) {
    const tc = store.getState().user?.info?.lang
      ? store.getState().user.info.lang === "en"
        ? text.english
        : text.swedish
      : text.swedish;

    console.error(err);
    showFlashMessage(tc.thereWasAProblemContactCustomerService, 'error')
    return null;
  }
}

export const getToken = async (report, userId) => {
  try {
    const userIdPrefix = 'bp';
    const scope = 'Bilprospekt';

    const res = await fetchRequest(`${userValidationURL}/reports/embedToken?userId=${userIdPrefix + userId}&scope=${scope}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(report),
    });

    return res.status === 200 ? res.data : null;
  } catch (err) {
    const tc = store.getState().user?.info?.lang
      ? store.getState().user.info.lang === "en"
        ? text.english
        : text.swedish
      : text.swedish;

    console.error(err);
    showFlashMessage(tc.thereWasAProblemContactCustomerService, 'error')
    return null;
  }
}

export const clearReports = async () => {
  try {
    store.dispatch({
      type: pbiActionTypes.PBI_SET_REPORTS,
      payload: null,
    })
  } catch (err) {
    const tc = store.getState().user?.info?.lang
      ? store.getState().user.info.lang === "en"
        ? text.english
        : text.swedish
      : text.swedish;

    console.error(err);
    showFlashMessage(tc.thereWasAProblemContactCustomerService, 'error')
    return null;
  }
}

export const setOrgnr = async (orgNr) => {
  try {
    store.dispatch({
      type: pbiActionTypes.PBI_SET_REPORTS,
      payload: orgNr,
    })
  } catch (err) {
    const tc = store.getState().user?.info?.lang
      ? store.getState().user.info.lang === "en"
        ? text.english
        : text.swedish
      : text.swedish;

    console.error(err);
    showFlashMessage(tc.thereWasAProblemContactCustomerService, 'error')
    return null;
  }
}

export const clearReport = async () => {
  try {
    store.dispatch({
      type: pbiActionTypes.PBI_SET_REPORT,
      payload: null,
    })
  } catch (err) {
    const tc = store.getState().user?.info?.lang
      ? store.getState().user.info.lang === "en"
        ? text.english
        : text.swedish
      : text.swedish;

    console.error(err);
    showFlashMessage(tc.thereWasAProblemContactCustomerService, 'error')
    return null;
  }
}

export const setReport = async (report) => {
  try {
    store.dispatch({
      type: pbiActionTypes.PBI_SET_REPORT,
      payload: report,
    })
  } catch (err) {
    const tc = store.getState().user?.info?.lang
      ? store.getState().user.info.lang === "en"
        ? text.english
        : text.swedish
      : text.swedish;

    console.error(err);
    showFlashMessage(tc.thereWasAProblemContactCustomerService, 'error')
    return null;
  }
}

export const getReports = async (userId) => {
  try {
    const userIdPrefix = 'bp';
    const scope = 'Bilprospekt';

    const res = await fetchRequest(`${userValidationURL}/reports?userId=${userIdPrefix + userId}&scope=${scope}`, {
      method: 'GET',
    });

    if (res.status === 200) {
      return res.data;
    } else {
      throw new Error();
    }
  } catch (err) {
    const tc = store.getState().user?.info?.lang
      ? store.getState().user.info.lang === "en"
        ? text.english
        : text.swedish
      : text.swedish;

    console.error(err);
    showFlashMessage(tc.thereWasAProblemContactCustomerService, 'error')
    return null;
  }
}

export const getBookmarks = async (userId) => {
  try {
    const userIdPrefix = 'bp';
    const scope = 'Bilprospekt';

    const res = await fetchRequest(`${userValidationURL}/bookmarks?userId=${userIdPrefix + userId}&scope=${scope}`, {
      method: 'GET',
    });

    if (res.status === 200) {
      return res.data;
    } else {
      throw new Error();
    }
  } catch (err) {
    const tc = store.getState().user?.info?.lang
      ? store.getState().user.info.lang === "en"
        ? text.english
        : text.swedish
      : text.swedish;

    console.error(err);
    showFlashMessage(tc.thereWasAProblemContactCustomerService, 'error')
    return null;
  }
}

export const deleteBookmark = async (bookmarkId, userId) => {
  try {
    const userIdPrefix = 'bp';
    const scope = 'Bilprospekt';

    const res = await fetchRequest(`${userValidationURL}/bookmarks/${bookmarkId}?userId=${userIdPrefix + userId}&scope=${scope}`, {
      method: 'DELETE',
    });

    if (res.status === 200) {
      return showFlashMessage('Bookmärket har tagigts bort', 'success');
    } else {
      throw new Error();
    }
  } catch (err) {
    const tc = store.getState().user?.info?.lang
      ? store.getState().user.info.lang === "en"
        ? text.english
        : text.swedish
      : text.swedish;

    console.error(err);

    return showFlashMessage(tc.thereWasAProblemContactCustomerService, 'fail');
  }
}

export const print = async (reportId, state, filters, reportName, filename) => {
  try {
    const tc = store.getState().user?.info?.lang
      ? store.getState().user.info.lang === "en"
        ? text.english
        : text.swedish
      : text.swedish;

    if (!filters || !state) {
      showFlashMessage(tc.thereWasAProblemContactCustomerService, 'error');
      return;
    }

    const res = await fetchRequest(`${userValidationURL}/pdf/${reportId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ state, filters, reportName, filename })
    })

    if (res.status !== 200) {
      if (res.status === 403) {
        return showFlashMessage('Det finns redan en pågående export. Vänligen försök igen om en stund', 'info');
      }

    }

    showFlashMessage("PDF-utskrift genereras. Detta kan ta en stund. Filen hittar du i vänstermenyn under Rapporter -> Exporter", "success")
  } catch (err) {
    const tc = store.getState().user?.info?.lang
      ? store.getState().user.info.lang === "en"
        ? text.english
        : text.swedish
      : text.swedish;

    console.error(err);

    return showFlashMessage(tc.thereWasAProblemContactCustomerService, 'fail');
  }
}

export const printBVApi = async (reportData, filename, state, userId) => {
  try {
    const tc = store.getState().user?.info?.lang
      ? store.getState().user.info.lang === "en"
        ? text.english
        : text.swedish
      : text.swedish;

    const userIdPrefix = 'bp';
    const scope = 'Bilprospekt';

    const res = await fetchRequest(`${userValidationURL}/exports?userId=${userIdPrefix + userId}&scope=${scope}`, {
      method: 'POST',
      body: JSON.stringify({
        ...reportData,
        name: filename,
        state,
      }),
      headers: {
        "Content-Type": "application/json"
      }
    });

    if (res.status !== 200) {
      if (res.status === 403) {
        return showFlashMessage(tc.pdfAlreadyInProgress, 'info');
      }

    }

    showFlashMessage(tc.pdfInProgress, "success")
  } catch (err) {
    const tc = store.getState().user?.info?.lang
      ? store.getState().user.info.lang === "en"
        ? text.english
        : text.swedish
      : text.swedish;

    console.error(err);

    return showFlashMessage(tc.thereWasAProblemContactCustomerService, 'fail');
  }
}

export const saveBookmark = async (name, state, reportId, userId) => {
  try {
    const tc = store.getState().user?.info?.lang
      ? store.getState().user.info.lang === "en"
        ? text.english
        : text.swedish
      : text.swedish;

    const userIdPrefix = 'bp';
    const scope = 'Bilprospekt';

    const res = await fetchRequest(`${userValidationURL}/bookmarks?userId=${userIdPrefix + userId}&scope=${scope}`, {
      method: 'POST',
      body: JSON.stringify({
        reportId,
        name,
        bookmarkState: state,
        comment: '',
      }),
      headers: {
        "Content-Type": "application/json"
      }
    });

    if (res.status === 200) {
      showFlashMessage(tc.bookmarkHasBeenCreated, "success")
    } else {
      throw new Error();
    }

  } catch (err) {
    const tc = store.getState().user?.info?.lang
      ? store.getState().user.info.lang === "en"
        ? text.english
        : text.swedish
      : text.swedish;

    console.error(err);

    return showFlashMessage(tc.thereWasAProblemContactCustomerService, 'fail');
  }
}
