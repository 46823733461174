import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { getDeal } from 'store/deal/tasks';
import { setPreviewId, updateAgileFilter } from 'store/agile/tasks';
import LanguageContext from 'language-context';
import history from 'router-history';
import Activities from 'components/activities';
import Contacts from 'components/contacts';
import DealCars from './deal_cars';
import DealFiles from './deal_files';
import DealMain from './deal_main';
import DealProspects from './deal_prospects';
import Events from 'components/events';
import Loading from 'components/loading';
import PageHeader from 'components/page_header';

/**
 * Render a deal view.
 */
const Deal = (state) => {
  const { id } = useParams();
  const [dataIsCollected, setDataIsCollected] = useState(false);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    const getData = async () => {
      await getDeal({ id: id });
      // We use this flag to prevent sub components to retrieve information for previous deal in store.
      setDataIsCollected(true);
    };

    getData();
  }, [id]);

  const _openInAgile = async () => {
    if (+state.deal.deal.user_id !== +state.user.info.id) {
      // Can only open own deals in agile.
      return;
    }

    await updateAgileFilter({
      resetFilters: true,
    });
    await setPreviewId(id);
    history.push('/bearbeta');
  };

  const _stateCheck = () => {
    return (
      dataIsCollected &&
      state.deal?.deal &&
      Object.keys(state.deal?.deal).length
    );
  };

  return _stateCheck() ? (
    <div className="dealWrapper">
      <div className="dealWrapper__deal">
        <div className="dealWrapper__deal__header">
          <PageHeader
            headline={
              ['lost', 'trash', 'won'].includes(state.deal.deal.phase)
                ? `${state.deal.deal.name} (${tc[
                    state.deal.deal.phase
                  ].toLowerCase()})`
                : state.deal.deal.name
            }
            headlineOnclick={_openInAgile}
          />
        </div>
        <div className="dealWrapper__deal__content">
          <div className="dealWrapper__deal__content__item">
            <DealMain dataIsCollected={dataIsCollected} />
          </div>
          <div className="dealWrapper__deal__content__item">
            <Events onlyShowFlow={true} target={id} type="target" view="flow" />
          </div>
          <div className="dealWrapper__deal__content__item">
            <DealFiles />
          </div>
          <div className="dealWrapper__deal__content__item">
            <Contacts entityId={state.deal.deal.id} entityType="deal" />
          </div>
          <div className="dealWrapper__deal__content__item">
            <DealProspects
              isRemovable={state.user.info.id === state.deal.deal.user_id}
            />
          </div>
          <div className="dealWrapper__deal__content__item">
            <DealCars
              isRemovable={state.user.info.id === state.deal.deal.user_id}
            />
          </div>
          <div className="dealWrapper__deal__content__item">
            <Activities target={id} targetType="deal" view="flow" />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Loading />
  );
};

const MapStateToProps = (state) => {
  return {
    deal: state.deal,
    user: state.user,
  };
};

export default connect(MapStateToProps)(Deal);
