import React, { useContext, useState } from "react";
import LanguageContext from "language-context";
import WidgetFooter from "components/widget_footer";
import WidgetHeader from "components/widget_header";
import Popup from "components/popup";
import Menu from "components/menu";
import Info from "components/info";
import Icon from "components/icon";
import ToggleOption from "components/toggle_option";
import Loading from "components/loading";
import { activitiesByFilterToExcel } from "store/activities/tasks";

const SaveToExcel = ({ setShowExcelluttag, activityTypesToDisplay }) => {
  const [excelUttagstyp, setExceluttagstyp] = useState("table");
  const [excelUttagLoading, setExceluttagLoading] = useState(false);
  const [excelUttagError, setExcelUttagError] = useState(false);
  const [excelUttagDownloadLink, setExcelUttagDownloadLink] = useState(null);
  const [excelUttagGroupByDealer, setExcelUttagGroupByDealer] = useState(false);

  const tc = useContext(LanguageContext);

  return (
    <Popup
      close={() => {
        setShowExcelluttag(false);
        setExcelUttagError(false);
        setExcelUttagDownloadLink(null);
      }}
      size="medium"
    >
      <div className="activitiesExcelWrapper">
        <div className="activitiesExcelWrapper__activitiesExcel">
          <div className="activitiesExcelWrapper__activitiesExcel__header">
            <WidgetHeader
              headline={`${
                tc.excelOutput
              } ${tc.for.toLowerCase()} ${tc.activity.toLowerCase()}`}
            />
          </div>
          <div className="activitiesExcelWrapper__activitiesExcel__content">
            {!excelUttagLoading ? (
              <>
                {!excelUttagError ? (
                  <>
                    {excelUttagDownloadLink ? (
                      <div className="activitiesExcelWrapper__activitiesExcel__content__complete">
                        <div className="activitiesExcelWrapper__activitiesExcel__content__complete__left">
                          <p>{tc.fileHasBeenCreated}</p>
                          <a href={excelUttagDownloadLink}>
                            {tc.download}
                          </a>
                        </div>
                        <div className="activitiesExcelWrapper__activitiesExcel__content__complete__right">
                          <Icon val="check" />
                        </div>
                      </div>
                    ) : (
                      <div className="activitiesExcelWrapper__activitiesExcel__content__initial">
                        <Menu
                          centered={true}
                          items={[
                            {
                              active: excelUttagstyp === "table",
                              disabled: false,
                              icon: "actionTypes",
                              label: tc.table,
                              labelSub: tc.excelOutputTableFormat,
                              onClick: () => setExceluttagstyp("table"),
                              type: "button",
                            },
                            {
                              active: excelUttagstyp === "flow",
                              disabled: false,
                              icon: "flow",
                              label: tc.activitiesFlow,
                              labelSub: tc.excelOutputFlowFormat,
                              onClick: () => setExceluttagstyp("flow"),
                              type: "button",
                            },
                          ]}
                          type="large"
                          skipSort={true}
                        />
                        <div className="activitiesExcelWrapper__activitiesExcel__content__initial__toggle">
                          <ToggleOption
                            active={excelUttagGroupByDealer}
                            label={tc.groupByDealer}
                            labelSub={tc.oneSheetInExcelForEveryDealer}
                            onClick={() =>
                              setExcelUttagGroupByDealer(
                                !excelUttagGroupByDealer
                              )
                            }
                          />
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <Info>
                    <h4>{tc.error}</h4>
                    <p>{tc.couldNotExportToExcel}</p>
                  </Info>
                )}
              </>
            ) : (
              <>
                <p>{tc.excelOutputInfo}</p>
                <Loading />
              </>
            )}
          </div>
          <div className="activitiesExcelWrapper__activitiesExcel__footer">
            <WidgetFooter
              buttonOneFunc={() => {
                if (
                  !excelUttagLoading &&
                  !excelUttagError &&
                  !excelUttagDownloadLink
                ) {
                  setExceluttagLoading(true);
                  activitiesByFilterToExcel(
                    excelUttagstyp,
                    excelUttagGroupByDealer,
                    activityTypesToDisplay
                      .filter((type) => type.active === true)
                      .map((type) => type.val)
                  ).then((res) => {
                    if (res.error === true) {
                      setExcelUttagError(true);
                    }
                    setExcelUttagDownloadLink(res.url);
                    setExceluttagLoading(false);
                  });
                } else {
                  setShowExcelluttag(false);
                  setExcelUttagError(false);
                  setExcelUttagDownloadLink(null);
                }
              }}
              disableButtonOne={excelUttagLoading}
              buttonOneText={
                !excelUttagLoading &&
                !excelUttagDownloadLink &&
                !excelUttagError
                  ? tc.createFile
                  : tc.close
              }
              buttonTwoFunc={() => {
                setShowExcelluttag(false);
                setExcelUttagError(false);
                setExcelUttagDownloadLink(null);
              }}
              buttonTwoText={tc.cancel}
            />
          </div>
        </div>
      </div>
    </Popup>
  )

}

export default SaveToExcel;
