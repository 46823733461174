import React, { useContext, useEffect, useRef, useState } from 'react';
import { tableHelper } from 'helpers';
import LanguageContext from 'language-context';
import history from 'router-history';
import ReactDOM from 'react-dom';
import { Dropdown, DropdownItem } from 'components/dropdown';
import Icon from 'components/icon';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import Tooltip from 'components/tooltip';
import { NavLink } from 'react-router-dom';

/**
 * Table component.
 * This is a stand alone component. Pagination, search and sorting is not provided through props but instead handled by MUI component itself.
 * So all the rows has to be provided.
 * If you need a table that handles pagination, search etc. via backend use component <TablePropsManaged>.
 *
 * Just send in columns and rows in the correct format and it should work.
 *
 * Rows can be selectable, if so provide onSelect function and 'id' property for every row.
 * If a row have a 'url' property the row is going to be a navigation link.
 * If a row have a 'onClick' property we're going to execute that when a row is clicked and it doesn't have a 'url' property.
 *
 * Cells can be editable. If so, set that column to editable: true. Also provide saveOnEdit function.
 *
 * Two examples on how to use this component:
 *      <Table columns={tableHelper.getFleetColumns(state.props.historic)} onSelect={_onSelect} rows={tableHelper.getFleetRows(fleet.data, state.props.historic)}/>
 *      <Table columns={tableHelper.getFleetColumns(state.props.historic)} rows={tableHelper.getFleetRows(fleet.data, state.props.historic)}/>
 *
 * @param props.adjustToContainer - bool (optional) - When we want table to fill up the container. Make sure parent container has "display: flex, flex-direction: column" to workin all browsers.
 * @param props.columns - array -  See below for examples.
 * @param props.onSelect - func (optional) - Provide this function when rows are to be selectable, this function receives the selected ids array. Note that every row object must have an 'id' property with a unique value and you have to provide props.selected array.
 * @param props.resizable - bool (optional) -  For special cases. Hide footer, pagination etc. and fill up available content with rows and columns. Not the same as adjustToContainer. Used mainly for resizable widgets. Note that parent element for Table should have display: flex, flex-direction: column, for tableWrapper to stretch height in Safari.
 * @param props.rows - array - See below for rows examples.
 * @param props.rowsPerPage - number (optional)
 * @param props.onColumnsChange - func (optional) - When this is provided we display a dropdown to hide/show columns and return the column selection in this callback (most likely to update user settings).
 * @param props.saveOnEdit - func (optional) - To be provided if we have cells that are editable. Receives id for row, new value and column name.
 * @param props.search - bool (optional) - When explicitly set to false, we hide search. Defaults to show search.
 * @param props.selected - array (optional) - If we wanna use selection, provide array with selected ids.
 * @param props.hideSelectAll - boolean (optional) - Hides select all checkbox when true
 *
 *  @param props.columns
 *      Example 1 (used with example 1 for rows): [
 *          { id: 'name', editable: true, numeric: false, label: 'Namn', visible: true },
 *          { id: 'orgnr', numeric: true, label: 'Org nr', visible: true },
 *          { id: 'mileage', numeric: true, label: 'Antal mil', hideSort: true, visible: true},
 *      ];
 *      Example 2 (used with example 2 for rows): [
 *          { id: 'brand', numeric: false, label: 'Märke', visible: true },
 *          { id: 'reg_number', numeric: false, label: 'Registreringsnummer', visible: true },
 *      ];
 *
 * @param props.rows
 *      Example 1 (selectable rows): [
 *          {id: '123', name: 'Sockerkaka', calories: 100, fat: 50},
 *          {id: '456', name: 'Chokladboll', calories: 200, fat: 100},
 *          {id: '789', name: 'Havrekaka', calories: 100, fat: 50, onClick: () => {doSomething()},
 *      ];
 *      Example 2 (rows is links): [
 *          {reg_number: 'abc123', brand: 'HONDA', url: '/fordon/abc123'},
 *          {reg_number: 'rty456', brand: 'VOLOV', url: '/fordon/rty456'},
 *      ];
 */
export const TableComponent = (props) => {
  const [columns, setColumns] = useState([]);
  const [columnsVisibleAmount, setColumnsVisibleAmount] = useState(null); // Only used when props.resizable === true.
  const [editId, setEditId] = useState('');
  const [editValue, setEditValue] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    props.rowsPerPage ? props.rowsPerPage : props.adjustToContainer ? 50 : 25
  );
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [query, setQuery] = React.useState('');
  const [selected, setSelected] = React.useState([]);
  const editInputRefs = useRef([]);
  const editInputHolderRefs = useRef([]);
  const tableRef = useRef(null);
  const observer = useRef(null);
  const tc = useContext(LanguageContext);
  const rowsPerPageOptions = [5, 25, 50, 100];

  useEffect(() => {
    // Keep columns in component state so we can hide/show columns fast and smooth.
    setColumns(props.columns);
    if (props.resizable && Array.isArray(props.columns)) {
      setColumnsVisibleAmount(props.columns.length);
    }
  }, [props.columns, props.resizable]);

  useEffect(() => {
    setSelected(props.selected ? props.selected : []);
  }, [props.selected]);

  useEffect(() => {
    _setResponsive();
    observer.current = new ResizeObserver(_setResponsive);
    observer.current.observe(tableRef.current);

    const ref = tableRef.current;

    return () => observer.current.unobserve(ref);
  }, []);

  // useEffect(() => {
  //   _setResponsiveRows();
  // }, [rowsPerPage]);

  useEffect(() => {
    _setResponsiveColumns();
  }, [columnsVisibleAmount]);

  useEffect(() => {
    /**
     *  When in edit mode and clicking outside of that cell, close edit mode.
     */
    const _closeEdit = (e) => {
      if (
        editId &&
        editId.length &&
        editInputHolderRefs &&
        editInputHolderRefs.current &&
        editInputHolderRefs.current[editId]
      ) {
        const node = ReactDOM.findDOMNode(editInputHolderRefs.current[editId]);
        if (node && !node.contains(e.target)) {
          setEditId('');
          setEditValue('');
        }
      }
    };

    window.addEventListener('mousedown', _closeEdit);
    return () => window.removeEventListener('mousedown', _closeEdit);
  }, [editInputHolderRefs, editId]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelect = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    if (props.onSelect && typeof props.onSelect === 'function') {
      props.onSelect(newSelected);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelected = props.rows.map((n) => n.id);
      if (props.onSelect && typeof props.onSelect === 'function') {
        props.onSelect(newSelected);
      }
    } else {
      if (props.onSelect && typeof props.onSelect === 'function') {
        props.onSelect([]);
      }
    }
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const _onInputChange = (key) => {
    setEditValue(editInputRefs.current[key].value);
  };

  const renderCellsForRow = (row) => {
    const columnLinks = props.columnLinks
      ? props.columnLinks.find((link) => link.id === row.reg_number)
      : null;
    const rows = [];
    let index = 0;
    for (const prop in row) {
      const column = columns.find((column) => column.id === prop);
      const doNotRenderTheseProperties = ['id', 'onClick', 'visible', 'url'];

      if (column?.visible && column.editable && !columnLinks) {
        index++;
        // Note that we move onClick from cell to div here.
        rows.push(
          <TableCell
            align={column.numeric ? 'right' : 'left'}
            key={`${row[prop]}${index}`}
          >
            {renderEditableCell(row[prop], row.id, prop, row)}
          </TableCell>
        );
      } else if (
        column?.visible &&
        !doNotRenderTheseProperties.includes(prop) &&
        !props.columnLinks
      ) {
        index++;
        rows.push(
          <TableCell
            align={column.numeric ? 'right' : 'left'}
            // key={`${row[prop]}${index}`}
            key={`${Math.random()}`} // Fix this, have to be unique or problems occur.
            onClick={() => {
              if (row.url) {
                // Property url has presedence.
                return history.push(row.url);
              } else if (row.onClick) {
                return row.onClick(row.id);
              }
            }}
          >
            {row[prop]}
          </TableCell>
        );
      } else if (
        column?.visible &&
        columnLinks &&
        !doNotRenderTheseProperties.includes(prop)
      ) {
        index++;
        const columnLink = columnLinks[column.id];
        rows.push(
          <TableCell
            align={column.numeric ? 'right' : 'left'}
            // key={`${row[prop]}${index}`}
            key={`${Math.random()}`} // Fix this, have to be unique or problems occur.
          >
            {columnLink &&
            columnLink.url &&
            row[prop] !== 'Saknas' &&
            row[prop] ? (
              <NavLink exact to={columnLink.url}>
                {row[prop]}
              </NavLink>
            ) : (
              row[prop]
            )}
          </TableCell>
        );
      }
    }

    return rows;
  };

  const renderEditableCell = (value, rowId, columnName, row) => {
    const key = columnName + '-' + rowId;
    return editId === key ? (
      <div
        className="editableCell"
        ref={(el) => (editInputHolderRefs.current[key] = el)}
        onClick={() => {
          if (row.url) {
            // Property url has presedence.
            return history.push(row.url);
          } else if (row.onClick) {
            return row.onClick(row.id);
          }
        }}
      >
        <input
          type="text"
          onChange={() => {
            _onInputChange(key);
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
          ref={(el) => (editInputRefs.current[key] = el)}
          value={editValue}
        />
        <div className="editableCell__icons">
          <Tooltip tooltipContent={tc.cancel}>
            <Icon
              onClick={(e) => {
                e.stopPropagation();
                setEditValue('');
                setEditId('');
              }}
              val="clear"
            />
          </Tooltip>
          <Tooltip tooltipContent={tc.save}>
            <Icon
              onClick={(e) => {
                e.stopPropagation();
                if (typeof props.saveOnEdit === 'function') {
                  props.saveOnEdit(rowId, editValue, columnName);
                }
                setEditValue('');
                setEditId('');
              }}
              val="save"
            />
          </Tooltip>
        </div>
      </div>
    ) : (
      <div
        className="editableCell"
        ref={(el) => (editInputHolderRefs.current[key] = el)}
        onClick={() => {
          if (row.url) {
            // Property url has presedence.
            return history.push(row.url);
          } else if (row.onClick) {
            return row.onClick(row.id);
          }
        }}
      >
        <div className="editableCell__value">{value}</div>
        <div className="editableCell__icon">
          <Tooltip tooltipContent={tc.edit}>
            <Icon
              val="edit"
              onClick={(e) => {
                e.stopPropagation();
                if (editId === '') {
                  setEditId(key);
                  setEditValue(value);
                }
              }}
            />
          </Tooltip>
        </div>
      </div>
    );
  };

  const renderTableHead = () => {
    const createSortHandler = (property) => (event) => {
      handleRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {props.onSelect && typeof props.onSelect === 'function' && (
            <TableCell>
              {props.hideSelectAll === true ? null : (
                <Checkbox
                  indeterminate={
                    selected.length > 0 && selected.length < props.rows.length
                  }
                  checked={
                    props.rows.length > 0 &&
                    selected.length === props.rows.length
                  }
                  onChange={handleSelectAll}
                  inputProps={{ 'aria-label': 'select all' }}
                />
              )}
            </TableCell>
          )}
          {columns
            .map((column, i) => {
              if (!column.visible) {
                return null;
              }

              if (
                props.resizable &&
                typeof columnsVisibleAmount === 'number' &&
                i + 1 > columnsVisibleAmount
              ) {
                return null;
              }

              if (column.hideSort) {
                return (
                  <TableCell
                    key={column.id}
                    align={column.numeric ? 'right' : 'left'}
                    sortDirection={orderBy === column.id ? order : false}
                  >
                    {column.label}
                  </TableCell>
                );
              } else {
                return (
                  <TableCell
                    key={column.id}
                    align={column.numeric ? 'right' : 'left'}
                    sortDirection={orderBy === column.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : 'asc'}
                      onClick={createSortHandler(column.id)}
                    >
                      {column.label}
                      {orderBy === column.id ? (
                        <span className="hidden">
                          {order === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                );
              }
            })
            .filter((num) => num)}
        </TableRow>
      </TableHead>
    );
  };

  const _setResponsive = () => {
    if (!columnsVisibleAmount && Array.isArray(props.columns)) {
      setColumnsVisibleAmount(props.columns.length);
    }

    _setResponsiveColumns();
    _setResponsiveRows();
  };

  const _setResponsiveColumns = () => {
    if (!tableRef?.current || typeof columnsVisibleAmount !== 'number') {
      return;
    }

    const tableWidth = tableRef.current.getBoundingClientRect().width;
    const columnWidth = 170; // Correlates to CSS max-width value for td in responsive mode.
    let increase = 0;
    let decrease = 0;

    const _calculate = () => {
      if (
        tableWidth > columnWidth * (columnsVisibleAmount + increase + 1) &&
        columnsVisibleAmount + (increase + 1) <= columns.length
      ) {
        increase = increase + 1;
        return _calculate();
      } else if (
        tableWidth < columnWidth * (columnsVisibleAmount - (decrease + 1)) &&
        columnsVisibleAmount - (decrease + 1) > 0
      ) {
        decrease = decrease + 1;
        return _calculate();
      }
    };

    _calculate();

    if (increase > 0) {
      setColumnsVisibleAmount(columnsVisibleAmount + increase);
    } else if (decrease > 0) {
      setColumnsVisibleAmount(columnsVisibleAmount - decrease);
    }
  };

  const _setResponsiveRows = () => {
    if (
      !props.resizable ||
      !tableRef?.current ||
      !tableRef?.current?.getBoundingClientRect()
    ) {
      return;
    }

    const tableTop = tableRef.current.querySelector(
      '.tableWrapper__table__content__top'
    );
    if (!tableTop || !tableTop?.getBoundingClientRect()) {
      return;
    }

    const tableHead = tableRef.current.querySelector('thead');
    if (!tableHead || !tableHead?.getBoundingClientRect()) {
      return;
    }

    const tableTopHeight = tableTop.getBoundingClientRect().height;
    const tableHeadHeight = tableHead.getBoundingClientRect().height;
    const tableAvailableHeight =
      tableRef.current.getBoundingClientRect().height -
      tableTopHeight -
      tableHeadHeight;
    const rowHeight = 49;
    let increase = 0;
    let decrease = 0;

    const _calculate = () => {
      if (
        tableAvailableHeight > rowHeight * (rowsPerPage + increase + 1) &&
        rowsPerPage + (increase + 1) < 100
      ) {
        increase = increase + 1;
        return _calculate();
      } else if (
        tableAvailableHeight < rowHeight * (rowsPerPage - (decrease + 1)) &&
        rowsPerPage - (decrease + 1) > 3
      ) {
        decrease = decrease + 1;
        return _calculate();
      }
    };

    _calculate();

    if (increase > 0) {
      // Room for more table rows.
      setRowsPerPage(rowsPerPage + increase);
    } else if (decrease > 0) {
      // Remove table rows.
      setRowsPerPage(rowsPerPage - decrease);
    }
  };

  const _stateCheck = () => {
    return (
      Array.isArray(columns) && columns.length && Array.isArray(props.rows)
    );
  };

  return (
    <div
      className={`tableWrapper ${
        props.adjustToContainer ? 'adjustToContainer' : ''
      } ${props.resizable ? 'resizable' : ''}`}
      ref={tableRef}
    >
      {_stateCheck() ? (
        <div className="tableWrapper__table">
          <div className="tableWrapper__table__content">
            <div className="tableWrapper__table__content__top">
              <div
                className={`tableWrapper__table__content__top__left ${
                  selected?.length ? null : 'noSelected'
                }`}
              >
                <span>
                  {tc.selectedRows}:{' '}
                  <strong>{selected ? selected.length : 0}</strong>{' '}
                  {tc.of.toLowerCase()} <strong>{props.rows.length}</strong>
                </span>
              </div>
              <div className="tableWrapper__table__content__top__right">
                {typeof props.onColumnsChange === 'function' ? (
                  <Dropdown
                    displayValue={tc.selectColumns}
                    transparent={true}
                    styling={{ adjustToHeader: true }}
                  >
                    {columns
                      .filter((num) => num.id !== 'numberOfMatchingCars')
                      .map((num) => {
                        return (
                          <DropdownItem
                            active={num.visible}
                            disabled={
                              columns.filter((num) => num.visible).length ===
                                1 || props.resizable
                            }
                            key={num.id}
                            label={num.label}
                            onClick={() => {
                              const columnsUpdated = columns.map((x) => {
                                if (num.id === x.id) {
                                  x.visible = !x.visible;
                                }
                                return x;
                              });

                              setColumns(columnsUpdated);

                              // Return visible columns as array with strings.
                              props.onColumnsChange(
                                columnsUpdated
                                  .filter((num) => num.visible)
                                  .map((num) => num.id)
                              );
                            }}
                          />
                        );
                      })}
                  </Dropdown>
                ) : null}
              </div>
            </div>
            <TableContainer>
              <Table
                aria-label="table"
                size="small"
                stickyHeader={!!props.adjustToContainer}
              >
                {renderTableHead()}
                <TableBody>
                  {tableHelper
                    .stableSort(
                      props.rows,
                      tableHelper.getComparator(order, orderBy),
                      query
                    )
                    .slice(
                      props.resizable ? 0 : page * rowsPerPage,
                      props.resizable
                        ? rowsPerPage
                        : page * rowsPerPage + rowsPerPage
                    )
                    .map((row, index) => {
                      if (
                        props.resizable &&
                        columnsVisibleAmount !== columns.length
                      ) {
                        // If props.resizable === true that means we adjust columns based on width, so only keep properties (cells) in correlation to this.
                        // Is this a stupid solution?
                        const visibleColumns = JSON.parse(
                          JSON.stringify(columns)
                        )
                          .slice(0, columnsVisibleAmount)
                          .map((num) => num.id);
                        const keys = Object.keys(row).filter((key) => {
                          return (
                            key === 'url' ||
                            key === 'onClick' ||
                            key === 'id' ||
                            visibleColumns.includes(key)
                          );
                        });

                        let newRow = {};
                        keys.forEach((key) => {
                          newRow[key] = row[key];
                        });

                        row = newRow;
                      }

                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          className={row.url || row.onClick ? 'rowPointer' : ''}
                          hover
                          onClick={() => {
                            if (row.url) {
                              // Property url has presedence.
                              return history.push(row.url);
                            } else if (row.onClick) {
                              return row.onClick(row.id);
                            }
                          }}
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={`${row[Object.keys(row)[0]]}${index}`}
                          selected={isItemSelected}
                        >
                          {props.onSelect &&
                            typeof props.onSelect === 'function' &&
                            row.id && (
                              <TableCell
                                onClick={(e) => {
                                  e.stopPropagation();
                                  return handleSelect(e, row.id);
                                }}
                              >
                                <Checkbox
                                  checked={isItemSelected}
                                  inputProps={{ 'aria-labelledby': labelId }}
                                />
                              </TableCell>
                            )}
                          {renderCellsForRow(row)}
                        </TableRow>
                      );
                    })
                    .filter((num) => num)}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {props.resizable ? null : (
            <div className="tableWrapper__table__footer cancelDrag">
              {props.search !== false ? (
                <div className="tableWrapper__table__footer__left">
                  <input
                    type="text"
                    placeholder={tc.placeholderSearchTable}
                    onChange={(e) => {
                      setQuery(e.target.value);
                    }}
                    value={query}
                  />
                </div>
              ) : null}
              <div className="tableWrapper__table__footer__middle">
                <div className="tableWrapper__table__footer__middle__rowsPerPage">
                  <span className="tableWrapper__table__footer__middle__rowsPerPage__label">
                    {tc.rowsPerPage}:
                  </span>
                  {rowsPerPageOptions.map((num) => {
                    return (
                      <span
                        className={
                          rowsPerPage === num
                            ? 'tableWrapper__table__footer__middle__rowsPerPage__optionActive'
                            : 'tableWrapper__table__footer__middle__rowsPerPage__option'
                        }
                        key={num}
                        onClick={() => {
                          setRowsPerPage(num);
                        }}
                      >
                        {num}
                      </span>
                    );
                  })}
                </div>
              </div>
              <div className="tableWrapper__table__footer__right">
                <TablePagination
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from} - ${to} ${tc.of.toLowerCase()} ${count}`
                  }
                  rowsPerPageOptions={[0]}
                  component="div"
                  count={props.rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};
