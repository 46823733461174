import React, { useContext, useEffect, useRef, useState } from "react";
import LanguageContext from "language-context";
import Info from "components/info";
import Popup from "components/popup";
import WidgetHeader from "components/widget_header";
import ActivityGroup from "components/activity_types_groups/activity_group";
import ActivitiesFlowNew from "../activities/activities_flow_new";
import {
  getActivitiesSubscriptionDataCount,
  getActivityGoals,
} from "store/activities/tasks";
import { connect } from "react-redux";
import Loading from "../loading";
import { getWeeks, aggregateGoals } from "components/activities/utils";

/**
 * Render activity types as groups.
 * Receive activities data as props.
 * This is supposed to be used for Activities component as well as Events component.
 *
 * @param props.activityTypesToDisplay - array - ["analysis", "mail", "visit" ...]
 * @param props.data - Object with a number of activities related to each action
 * @param props.historic boolean - If historic activity or planned activity(events).
 * @param props.target - string - Deal id | prospect id | vehicle reg num | lead id. If target exists, hide displayMode Menu and set mode to user.
 */
const ActivityTypesGroups = ({
  widget,
  connections,
  colleagues,
  filters,
  activityTypesToDisplay,
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [popup, setPopup] = useState({ type: null, show: false, total: null });
  const [responsiveClassWidth, setResponsiveClassWidth] = useState("");
  const [showInfo, setShowInfo] = useState(false); // Show info box, no data.
  const [goals, setGoals] = useState(null);
  const activityTypesGroupsRef = useRef(null);
  const observer = useRef(null);
  const tc = useContext(LanguageContext);
  const isLoadingRef = useRef(false);

  function getTotal(data) {
    return Object.keys(data).reduce((acc, key) => {
      if (data[key] !== undefined && data[key] !== null) {
        return acc + data[key];
      }
      return acc;
    }, 0);
  }

  useEffect(() => {
    async function getData() {
      if (widget && widget.data) {
        isLoadingRef.current = true;
        const allUsers = connections
          .reduce((acc, item) => [...acc, ...item.users], [])
          .concat(colleagues);

        const users = widget.data.users
          .map((id) => allUsers.find((user) => user.id === id))
          .filter((user) => Boolean(user))
          .map((user) => ({ userId: user.id, dealerId: user.dealer_id }))
          .filter((user) => user.userId && user.dealerId);

        const goalsData = await getActivityGoals({ users });
        const weeks = getWeeks(widget.data);
        const aggregatedGoals = aggregateGoals(goalsData, weeks);

        setGoals(aggregatedGoals);
        const data = await getActivitiesSubscriptionDataCount({
          ...widget.data,
        });

        setData(data);
        setLoading(false);
      } else if (filters) {
        const allUsers = connections
          .reduce((acc, item) => [...acc, ...item.users], [])
          .concat(colleagues);

        const users = filters.users
          .map((id) => allUsers.find((user) => user.id === id))
          .map((user) => ({ userId: user.id, dealerId: user.dealer_id }))
          .filter((user) => user.userId && user.dealerId);

        if (!users) return;

        const goalsData = await getActivityGoals({ users });
        const weeks = getWeeks(filters);
        const aggregatedGoals = aggregateGoals(goalsData, weeks);

        setGoals(aggregatedGoals);

        const data = await getActivitiesSubscriptionDataCount({
          ...filters,
        });
        setData(data);
        setLoading(false);
        isLoadingRef.current = false;
      }
    }
    if (isLoadingRef.current) return;
    if ((widget || filters) && connections && colleagues) {
      getData();
    }
  }, [widget, filters, connections, colleagues]);

  useEffect(() => {
    setShowInfo(
      (!activityTypesToDisplay?.length ||
        !data ||
        !Object.keys(data)?.length) &&
        !loading
    );
  }, [activityTypesToDisplay, data, loading]);

  useEffect(() => {
    _setResponsiveClass();
    observer.current = new ResizeObserver(_setResponsiveClass);
    observer.current.observe(activityTypesGroupsRef.current);

    const ref = activityTypesGroupsRef.current;

    return () => observer.current.unobserve(ref);
  }, []);

  const _setResponsiveClass = () => {
    if (!activityTypesGroupsRef?.current) {
      return;
    }

    const width = activityTypesGroupsRef.current.getBoundingClientRect().width;
    const breakpoint1 = 1400;
    const breakpoint2 = 1050;
    const breakpoint3 = 600;
    const breakpoint4 = 400;
    const breakpoint5 = 250;
    const breakpoint6 = 200;

    if (width <= breakpoint6) {
      setResponsiveClassWidth("activityTypesGroupsResponsiveWidth6");
    } else if (width <= breakpoint5 && width > breakpoint6) {
      setResponsiveClassWidth("activityTypesGroupsResponsiveWidth5");
    } else if (width <= breakpoint4 && width > breakpoint5) {
      setResponsiveClassWidth("activityTypesGroupsResponsiveWidth4");
    } else if (width <= breakpoint3 && width > breakpoint4) {
      setResponsiveClassWidth("activityTypesGroupsResponsiveWidth3");
    } else if (width <= breakpoint2 && width > breakpoint3) {
      setResponsiveClassWidth("activityTypesGroupsResponsiveWidth2");
    } else if (width <= breakpoint1 && width > breakpoint2) {
      setResponsiveClassWidth("activityTypesGroupsResponsiveWidth1");
    } else if (width > breakpoint1) {
      setResponsiveClassWidth("");
    }
  };

  return data ? (
    <div className="activityTypesGroupsWrapper" ref={activityTypesGroupsRef}>
      <div className="activityTypesGroupsWrapper__activityTypesGroups">
        <div className="activityTypesGroupsWrapper__activityTypesGroups__content">
          {showInfo ? (
            <Info>
              <h4>{tc.noActivity}</h4>
              <p>{tc.noActivityTypesGroupsWhy}</p>
            </Info>
          ) : !loading ? (
            <>
              <div
                className={`activityTypesGroupsWrapper__activityTypesGroups__content__groups cancelDrag ${responsiveClassWidth}`}
              >
                {Object.keys(data)
                  .sort((a, b) => {
                    if (data[a] > 0 || data[b] > 0) return data[b] - data[a];
                    return 0;
                  })
                  .map((key) => {
                    return activityTypesToDisplay.includes(key) ? (
                      <ActivityGroup
                        key={key}
                        amount={data[key]}
                        type={key}
                        total={getTotal(data)}
                        onClick={() => {
                          setPopup({
                            type: key,
                            show: true,
                            total: data[key],
                          });
                        }}
                        goal={goals[key]}
                      />
                    ) : null;
                  })}
              </div>
            </>
          ) : (
            <Loading />
          )}
        </div>
      </div>
      {popup.show ? (
        <Popup
          size="medium"
          close={() => {
            setPopup({ type: null, show: false });
          }}
        >
          <div className="genericPopupContentWrapper">
            <div className="genericPopupContentWrapper__genericPopupContent">
              <div className="genericPopupContentWrapper__genericPopupContent__header">
                <WidgetHeader headline={tc.activities} />
              </div>
              <div className="genericPopupContentWrapper__genericPopupContent__content noPadding">
                <ActivitiesFlowNew
                  activityTypesToDisplay={activityTypesToDisplay ?? []}
                  queryData={widget?.data ?? filters}
                  type={popup.type}
                  total={popup.total}
                />
              </div>
            </div>
          </div>
        </Popup>
      ) : null}
    </div>
  ) : null;
};

const mapStateToProps = (state, props) => {
  return {
    ...props,
    filters: state.activity.filter,
    colleagues: state.user.colleagues,
    connections: state.user.connections,
  };
};

export default connect(mapStateToProps)(ActivityTypesGroups);
