import React, { useContext, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import LanguageContext from "language-context";
import {
  getComment,
  setCommentEditId,
  updateComment,
} from "store/comment/tasks";
import history from "router-history";
import Popup from "components/popup";
import WidgetFooter from "components/widget_footer";
import WidgetHeader from "components/widget_header";

const CommentEdit = (state) => {
  const [text, setText] = useState("");
  const commentTextRef = useRef(null);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    if (state.comment.editId) {
      const _getComment = async () => {
        await getComment({ id: state.comment.editId });
        setText(state.comment.new.comment ? state.comment.new.comment : "");
        if (state.comment.editId && commentTextRef?.current) {
          commentTextRef.current.focus();
        }
      };

      _getComment();
    }
  }, [state.comment.new.comment, state.comment.editId]);

  useEffect(() => {
    history.listen(() => {
      _cancelEdit();
    });
  }, []);

  const _cancelEdit = () => {
    setCommentEditId(null);
    setText("");
  };

  const _onChange = () => {
    if (commentTextRef?.current) {
      setText(commentTextRef.current.value);
    }
  };

  const _saveComment = async () => {
    if (text?.length) {
      await updateComment({ comment: text, id: state.comment.editId });
      state.props.close();
    }
  };

  return (
    <Popup close={state.props.close} size="small">
      <div className="commentWrapper">
        <div className="commentWrapper__comment">
          <div className="commentWrapper__comment__header">
            <WidgetHeader
              headline={tc.editComment}
              headlineSub={state.props.headline ? state.props.headline : null}
            />
          </div>
          <div className="commentWrapper__comment__content marginTop">
            <div className="commentWrapper__comment__content__input">
              <textarea
                onChange={_onChange}
                placeholder={tc.comment}
                ref={commentTextRef}
                value={text}
              />
            </div>
          </div>
          <div className="commentWrapper__comment__footer">
            <WidgetFooter
              buttonOneFunc={_saveComment}
              buttonOneText={tc.saveComment}
              buttonTwoFunc={_cancelEdit}
              buttonTwoText={tc.cancelEdit}
              disableButtonOne={!text?.length}
              noBorder={true}
            />
          </div>
        </div>
      </div>
    </Popup>
  );
};

const MapStateToProps = (state, props) => {
  return {
    comment: state.comment,
    props: props,
  };
};

export default connect(MapStateToProps)(CommentEdit);
