import costHelper from 'shared_helpers/cost_helper';
import LanguageContext from 'language-context';
import { useContext } from 'react';

const OrdersOrderHubspotPrice = ({
  orderSettings,
  privatePersons = 0,
  companies = 0,
}) => {
  const tc = useContext(LanguageContext);
  const { name, phone } = orderSettings;
  const priceName = name ? costHelper.getRawCost(privatePersons) : 0;
  const priceStartPhone = phone
    ? costHelper.getStartCostPhone(privatePersons, phone)
    : 0;
  const pricePhone = phone ? costHelper.getPhoneCost(privatePersons, phone) : 0;
  const priceHubspot = costHelper.getCostForHubspot(privatePersons + companies);
  const sumPrice = priceName + priceStartPhone + pricePhone + priceHubspot;

  return (
    <div className="ordersInformationWrapper__ordersInformation__content__section__block">
      <span className="label">{tc.prices}</span>
      {
        <>
          <p>
            {tc.priceName}: {priceName}
            {tc.swedishCrowns.toLowerCase()}
          </p>
        </>
      }
      {
        <>
          <p>
            {tc.priceStartPhone}: {priceStartPhone}
            {tc.swedishCrowns.toLowerCase()}
          </p>
          <p>
            {tc.pricePhone}: {pricePhone}
            {tc.swedishCrowns.toLowerCase()}
          </p>
        </>
      }
      <p>
        {tc.hubspotPrice}: {priceHubspot}
        {tc.swedishCrowns.toLowerCase()}
      </p>
      <p className="priceSum">
        {tc.priceSum}:{' '}
        <strong className="highlight">
          {sumPrice} {tc.swedishCrowns.toLowerCase()}
        </strong>
      </p>
    </div>
  );
};

export default OrdersOrderHubspotPrice;
