import Icon from 'components/icon';
/**
 * @param {Object[]} sectionNavInfo - Information about navigation sections.
 * @param {React.RefObject} sectionNavInfo.ref - Reference to the section.
 * @param {string} sectionNavInfo.icon - Icon for the section.
 * @param {string} sectionNavInfo.name - Name of the section.
 * @param {Function} sectionNavInfo.onClick - Click handler for setting minimize false for the choosen section.
 *
 * @param {string} companyId - The ID of the company.
 */
const CompanySubNavigation = ({ sectionNavInfo }) => {
  const handleSectionClick = (index) => {
    const { ref } = sectionNavInfo[index];
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <div className="company_sub_navigation_wrapper">
      {sectionNavInfo.map(({ ref, icon, name, onClick }, index) => (
        <button
          className="company__subNavigation__buttons"
          key={name}
          onClick={() => {
            handleSectionClick(index);
            onClick();
          }}
        >
          <span className="company__subNavigation__buttons__icon">
            {index < sectionNavInfo.length - 2 ? <Icon val={icon} /> : null}
          </span>
          <span className="company__subNavigation__buttons__text">{name}</span>
          <span className="company__subNavigation__buttons__icon">
            {index >= sectionNavInfo.length - 2 ? <Icon val={icon} /> : null}
          </span>
        </button>
      ))}
    </div>
  );
};
export default CompanySubNavigation;
