import { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PageHeader from 'components/page_header';
import { getContractLists } from 'store/leads/tasks';
import { Table } from 'components/table';
import LanguageContext from 'language-context';
import Menu from 'components/menu';
import Loading from 'components/loading';
import ShareLists from 'components/lists/share_lists';
import AvtalskunderUpload from 'components/leads/avtalskunder/avtalskunder_upload';
import Popup from 'components/popup';
import { removeListsContracts } from 'store/lists/tasks';
import Icon from 'components/icon';
import Tooltip from 'components/tooltip';
import ActionButton from 'components/action_button';

const ListsContracts = ({ user }) => {
  const [lists, setLists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedLists, setSelectedLists] = useState([]);
  const [showShare, setShowShare] = useState(false);
  const [showAvtalskunderUploadPopup, setShowAvtalskunderUploadPopup] =
    useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [colleagues, setColleagues] = useState([]);
  const [connections, setConnections] = useState([]);

  const tc = useContext(LanguageContext);

  async function updateLists() {
    setLoading(true);
    const data = await getContractLists();
    setLists(data ?? []);
    setLoading(false);
  }

  useEffect(() => {
    updateLists();
  }, []);

  useEffect(() => {
    if (user) {
      if (user.connections)
        setConnections(
          user.connections.reduce((acc, item) => acc.concat(item.users), [])
        );
      if (user.colleagues) setColleagues(user.colleagues);
    }
  }, [user]);

  const _closePopup = () => {
    setSelectedLists([]);
    setShowShare(false);

    updateLists();
  };

  return (
    user &&
    user.colleagues &&
    user.connections && (
      <div className="contractCustomerListsWrapper">
        <PageHeader headlineSub={`${lists.length} ${tc.contractLists}`} />
        <Menu
          items={[
            {
              label: tc.shareList,
              disabled: selectedLists.length !== 1,
              onClick: () => {
                setShowShare(true);
              },
              type: 'button',
            },
            {
              label: tc.contractCustomersUpload,
              onClick: () => {
                setShowAvtalskunderUploadPopup(true);
              },
              type: 'button',
            },
            {
              disabled: selectedLists.length < 1,
              label: selectedLists.length > 1 ? tc.removeLists : tc.removeList,
              onClick: () => {
                setShowConfirmDelete(true);
              },
              type: 'button',
            },
          ]}
          autoClose={true}
        />
        <div className="contractCustomerListsWrapper__tableWrapper">
          {!loading ? (
            <Table
              columns={[
                {
                  id: 'name',
                  numeric: false,
                  label: tc.fullName,
                  visible: true,
                },
                {
                  id: 'amount',
                  numeric: true,
                  label: tc.amount,
                  visible: true,
                },
                {
                  id: 'sharedWith',
                  numeric: false,
                  label: tc.sharedWith,
                  hideSort: true,
                  visible: true,
                },
              ]}
              rows={lists.map((list) => {
                return {
                  id: list.id,
                  name: list.name,
                  amount: list.data?.length,
                  url: `/lista/${list.id}`,
                  sharedWith:
                    list.users.length > 1 ? (
                      <div className="tableCellIconHolder">
                        <Tooltip
                          horizontalDirection="left"
                          tooltipContent={colleagues
                            .concat(connections)
                            .filter(
                              (col) =>
                                list.users.includes(col.id) &&
                                col.id !== user.info.id
                            )
                            .map((u) => u.name)
                            .join(', ')}
                        >
                          <Icon class="notification" val="team" />
                        </Tooltip>
                      </div>
                    ) : null,
                };
              })}
              selected={selectedLists.map((l) => l.id)}
              onSelect={(arr) => {
                setSelectedLists(lists.filter((list) => arr.includes(list.id)));
              }}
              adjustToContainer={true}
            />
          ) : (
            <Loading />
          )}
        </div>
        {showShare ? (
          <ShareLists close={_closePopup} selectedList={selectedLists[0]} />
        ) : null}
        {showAvtalskunderUploadPopup ? (
          <Popup
            close={() => {
              setShowAvtalskunderUploadPopup(false);
            }}
            size="big"
          >
            <AvtalskunderUpload
              handleSuccess={async () => {
                const data = await getContractLists();
                setLists(data);
              }}
              setShowUpload={setShowAvtalskunderUploadPopup}
            />
          </Popup>
        ) : null}
        {showConfirmDelete ? (
          <Popup close={() => setShowConfirmDelete(false)}>
            <div>
              <p>
                Du kommer ta bort{' '}
                {selectedLists.length > 1 ? 'avtalslistorna' : 'avtalslistan'}{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {selectedLists.map((l) => l.name).join(', ')}
                </span>{' '}
                permanent.
              </p>
              <p>Ta bort?</p>
              <div style={{ display: 'flex', gap: '12px', marginTop: '18px' }}>
                <ActionButton
                  onClick={async () => {
                    await removeListsContracts({
                      ids: selectedLists.map((l) => l.id),
                    });
                    setShowConfirmDelete(false);
                    updateLists();
                    setSelectedLists([]);
                  }}
                  label={tc.remove}
                  type="highlight"
                />
                <ActionButton
                  onClick={() => setShowConfirmDelete(false)}
                  label={tc.cancel}
                />
              </div>
            </div>
          </Popup>
        ) : null}
      </div>
    )
  );
};

const MapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(MapStateToProps)(ListsContracts);
