import React, { useContext, useEffect, useState, useRef } from 'react';
import LanguageContext from 'language-context';
import { getActivityCompletionByFilter } from 'store/activity/tasks';
import moment from 'moment';
import { Table } from 'components/table';
import Info from 'components/info';
import { widgetHelper } from 'helpers/widget_helper';

const ActivitiesCompletionContent = ({
  minimize,
  filter,
  responsiveClassWidth,
  user,
  widget,
  rowsPerPage,
}) => {
  const tc = useContext(LanguageContext);

  const loadRef = useRef(false);

  const [data, setData] = useState([]);
  const [mappedData, setMappedData] = useState([]);

  const columns = [
    {
      id: 'listName',
      visible: true,
      numeric: false,
      label: tc.list,
    },
    {
      id: 'processingRate',
      visible: true,
      numeric: false,
      label: 'Bearbetningsgrad',
    },
    {
      id: 'listCreated',
      visible: true,
      numeric: false,
      label: 'Skapad',
    },
    {
      id: 'listCreator',
      visible: true,
      numeric: false,
      label: 'Skapad av',
    },
    {
      id: 'prospects',
      visible: true,
      numeric: false,
      label: 'Prospekt',
    },
    {
      id: 'prospectsWithActions',
      visible: true,
      numeric: false,
      label: 'Bearbetade prospekt',
    },
  ];

  useEffect(() => {
    const fetchData = async (date) => {
      loadRef.current = true;
      const lists = await getActivityCompletionByFilter({
        date,
      });

      setData(lists);
      loadRef.current = false;
    };

    if (!loadRef.current) {
      if (widget) {
        fetchData(widgetHelper.parseWidgetDate(widget.data));
      } else {
        if (filter) {
          fetchData(filter.date);
        }
      }
    }
  }, [filter, widget]);

  useEffect(() => {
    if (data && user) {
      const mapped = data.map((x) => {
        const color =
          x.processingRate >= 0 && x.processingRate <= 49
            ? 'red'
            : x.processingRate >= 50 && x.processingRate <= 74
              ? 'yellow'
              : 'green';

        const obj = {
          listName: x.listName,
          processingRate: (
            <span
              className={`activitiesWrapper__activities__content__processingRate__${color}`}
            >{`${x.processingRate}%`}</span>
          ),
          listCreated: moment(x.listCreated).format('YYYY-MM-DD HH:ss'),
          listCreator: x.listCreator,
          prospects: x.prospects,
          prospectsWithActions: x.prospectsWithActions,
        };

        return obj;
      });
      setMappedData(mapped);
    }
  }, [data, user]);

  return (
    <div
      className={`activitiesWrapper__activities__content cancelDrag ${responsiveClassWidth} ${
        minimize ? 'minimize' : ''
      }`}
    >
      {mappedData.length > 0 ? (
        <Table
          columns={columns}
          rows={mappedData}
          rowsPerPage={rowsPerPage || 5}
        />
      ) : (
        <Info>
          <p>Inga listor som matchar valda filter</p>
        </Info>
      )}
    </div>
  );
};

export default ActivitiesCompletionContent;
