import React from "react";

function SummaryTotal({ count }) {
  return (
    <div className="summaryWrapper__totalWrapper">
      <h4>Total {count}</h4>
    </div>
  );
}

export default React.memo(SummaryTotal);
