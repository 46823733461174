import React, { useContext } from "react";
import LanguageContext from "language-context";
import carHelper from "shared_helpers/car_helper";
import companyHelper from "shared_helpers/company_helper";
import { NavLink } from "react-router-dom";
import Icon from "components/icon";
import IconStyled from "components/icon_styled";
import Tooltip from "components/tooltip";

/**
 * Render a contact item.
 * @param props.contact
 * @param props.editContact
 * @param props.sticky - bool - Non editable.
 */
const ContactItem = (props) => {
  const tc = useContext(LanguageContext);

  return (
    <div className="contactsWrapper__contacts__content__contacts__item">
      <div
        className={
          props.sticky
            ? "contactsWrapper__contacts__content__contacts__item__header sticky"
            : "contactsWrapper__contacts__content__contacts__item__header"
        }
      >
        <div className="contactsWrapper__contacts__content__contacts__item__header__icon">
          <Icon val="contact" class={props.sticky ? "sticky" : null} />
        </div>
        <div className="contactsWrapper__contacts__content__contacts__item__header__name">
          {props?.contact?.name?.length ? props.contact.name : tc.nameMissing}
        </div>
        {!props.sticky && (
          <div className="contactsWrapper__contacts__content__contacts__item__header__edit">
            <Tooltip tooltipContent={tc.editContact}>
              <Icon
                val="edit"
                onClick={() => {
                  props.editContact(props.contact.id);
                }}
              />
            </Tooltip>
          </div>
        )}
      </div>
      <div className="contactsWrapper__contacts__content__contacts__item__content">
        <div className="contactsWrapper__contacts__content__contacts__item__content__row">
          <div className="contactsWrapper__contacts__content__contacts__item__content__row__left">
            {tc.phone}:
          </div>
          <div className="contactsWrapper__contacts__content__contacts__item__content__row__right">
            {Array.isArray(props.contact.tele) && props.contact.tele.length ? (
              props.contact.tele.map((num, i) => {
                if (i + 1 === props.contact.tele.length) {
                  return (
                    <a key={num} href={"tel:+46" + num}>
                      {num}
                    </a>
                  );
                } else {
                  return (
                    <a key={num} href={"tel:+46" + num}>
                      {num},{" "}
                    </a>
                  );
                }
              })
            ) : typeof props.contact.tele === "string" ? (
              <a href={"tel:+46" + props.contact.tele}>{props.contact.tele}</a>
            ) : (
              <p className="italic">{tc.dataMissing}</p>
            )}
          </div>
        </div>
        <div className="contactsWrapper__contacts__content__contacts__item__content__row">
          <div className="contactsWrapper__contacts__content__contacts__item__content__row__left">
            {tc.mail}:
          </div>
          <div className="contactsWrapper__contacts__content__contacts__item__content__row__right">
            {Array.isArray(props.contact.email) &&
            props.contact.email.length ? (
              props.contact.email.map((num, i) => <p key={i}>{num}</p>)
            ) : typeof props.contact.email === "string" ? (
              <p>{props.contact.email}</p>
            ) : (
              <p className="italic">{tc.dataMissing}</p>
            )}
          </div>
        </div>
        <div className="contactsWrapper__contacts__content__contacts__item__content__row">
          <div className="contactsWrapper__contacts__content__contacts__item__content__row__left">
            {tc.comment}:
          </div>
          <div className="contactsWrapper__contacts__content__contacts__item__content__row__right">
            {props.contact.comment?.length ? (
              <p>{props.contact.comment}</p>
            ) : (
              <p className="italic">{tc.dataMissing}</p>
            )}
          </div>
        </div>
        <div className="contactsWrapper__contacts__content__contacts__item__content__row">
          <div className="contactsWrapper__contacts__content__contacts__item__content__row__left">
            {tc.connectedTo}:
          </div>
          <div className="contactsWrapper__contacts__content__contacts__item__content__row__right">
            <div className="contactsWrapper__contacts__content__contacts__item__content__row__right__entities">
              {props.contact.savedTo?.length &&
                props.contact.savedTo.map((num, i) => {
                  let link;
                  if (carHelper.isValidRegNumber(num.entityId)) {
                    link = (
                      <NavLink exact to={"/fordon/" + num.entityId} key={i}>
                        {num.entityName ? num.entityName : num.entityId}
                      </NavLink>
                    );
                  } else if (companyHelper.isValidOrgNr(num.entityId)) {
                    link = (
                      <NavLink exact to={"/foretag/" + num.entityId} key={i}>
                        {num.entityName ? num.entityName : tc.company}
                      </NavLink>
                    );
                  } else {
                    link = (
                      <NavLink exact to={"/affar/" + num.entityId} key={i}>
                        {num.entityName ? num.entityName : tc.deal}
                      </NavLink>
                    );
                  }
                  return (
                    <div
                      className="contactsWrapper__contacts__content__contacts__item__content__row__right__entities__entity"
                      key={i}
                    >
                      {link}
                      {i === props.contact.savedTo.length - 1 ? "" : ", "}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactItem;
