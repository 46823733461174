import React, { useContext, useEffect, useState } from 'react';
import LanguageContext from 'language-context';
import { getLists, removeLists, undoArchive } from 'store/lists/tasks';
import { connect } from 'react-redux';
import { tableHelper } from 'helpers';
import { Table } from 'components/table';
import Info from 'components/info';
import Menu from 'components/menu';
import PageHeader from 'components/page_header';
import Popup from 'components/popup';
import WidgetHeader from 'components/widget_header';
import WidgetFooter from 'components/widget_footer';
import { getUserAccess } from 'store/user/tasks';
import { showFlashMessage } from 'store/flash_messages/tasks';
import { recreateSearch } from 'store/prospect/tasks';
import { setActiveOrderStage, setListToOrder } from 'store/orders/tasks';
import history from 'router-history';
import MergeLists from './merge_lists';
import ShareLists from './share_lists';
import SplitList from './split_list';
import ArchiveLists from './archive_lists';
import Excel from 'components/excel';

const SubscriptionLists = (state) => {
  // const [activePopup, setActivePopup] = useState('');
  const [selectedLists, setSelectedLists] = useState([]);
  const [handleListsType, setHandleListsType] = useState('');

  const tc = useContext(LanguageContext);

  useEffect(() => {
    getLists({
      subscriptionLists: true,
      orderInformation: true,
    });
  }, []);

  const _removeLists = async () => {
    // setActivePopup('');
    _closePopup();
    await removeLists({
      subscriptionLists: true,
      listIds: selectedLists.map((list) => list.id),
    });
    return setSelectedLists([]);
  };

  const _closePopup = () => {
    setHandleListsType('');
    setSelectedLists([]);
  };

  const _stateCheck = () => {
    return !!(state && state.lists && state.lists.subscriptionLists !== null);
  };

  const _orderList = () => {
    setListToOrder(selectedLists[0]);
    setActiveOrderStage('listOrder');
    history.push('/listor/bestalla');
  };

  return (
    <div className="listsSubcomponentWrapper">
      <div className="listsSubcomponentWrapper__listsSubcomponent">
        <div className="listsSubcomponentWrapper__listsSubcomponent__header">
          <PageHeader
            headlineSub={
              _stateCheck()
                ? `${tc.total} ${
                    state.lists.subscriptionLists.length
                  } ${tc.lists.toLowerCase()}`
                : null
            }
          />
        </div>
        {_stateCheck() ? (
          <div className="listsSubcomponentWrapper__listsSubcomponent__content">
            <div className="listsSubcomponentWrapper__listsSubcomponent__content__top">
              {state.lists.subscriptionLists.length ? (
                <Menu
                  items={[
                    // {
                    //   disabled: !selectedLists.length,
                    //   label:
                    //     selectedLists.length > 1
                    //       ? tc.removeLists
                    //       : tc.removeList,
                    //   onClick: () => {
                    //     setActivePopup('removeLists');
                    //   },
                    //   type: 'button',
                    // },
                  ].concat([
                    {
                      disabled: !selectedLists.length,
                      label:
                        selectedLists.length > 1
                          ? tc.archiveLists
                          : tc.archiveList,
                      onClick: () => {
                        setHandleListsType('archiveLists');
                      },
                      type: 'button',
                    },
                    {
                      disabled: !(selectedLists.length === 1),
                      label: tc.orderList,
                      onClick: _orderList,
                      type: 'button',
                    },
                    !getUserAccess().blockExcel
                      ? {
                          disabled: !selectedLists.length,
                          label: tc.excelOutput,
                          onClick: () => {
                            let rows = 0;
                            selectedLists.forEach(
                              (num) => (rows = rows + num.size)
                            );
                            if (
                              getUserAccess().bigExcel ||
                              (!getUserAccess().bigExcel && rows < 5000)
                            ) {
                              setHandleListsType('excel');
                            } else {
                              showFlashMessage(tc.excelRowsLimit, 'info');
                            }
                          },
                          type: 'button',
                        }
                      : null,
                    {
                      disabled: selectedLists.length < 2,
                      label: tc.mergeLists,
                      onClick: () => {
                        setHandleListsType('mergeLists');
                      },
                      type: 'button',
                    },
                    {
                      disabled: selectedLists.length !== 1,
                      label:
                        selectedLists.length > 1 ? tc.shareLists : tc.shareList,
                      onClick: () => {
                        setHandleListsType('shareLists');
                      },
                      type: 'button',
                    },
                    {
                      disabled: selectedLists.length !== 1,
                      label: tc.splitList,
                      onClick: () => {
                        setHandleListsType('splitList');
                      },
                      type: 'button',
                    },
                    {
                      disabled: !selectedLists.length,
                      label:
                        selectedLists.length > 1
                          ? tc.removeLists
                          : tc.removeList,
                      onClick: () => {
                        setHandleListsType('removeLists');
                      },
                      type: 'button',
                    },
                    {
                      disabled: !!(
                        selectedLists.length !== 1 ||
                        selectedLists.filter(
                          (list) =>
                            list.meta &&
                            list.meta.criterias &&
                            Object.keys(list.meta.criterias).length
                        ).length !== 1
                      ),
                      label: tc.recreateCriterias,
                      onClick: () => {
                        const list = selectedLists.find(
                          (num) =>
                            num.meta.criterias &&
                            Object.keys(num.meta.criterias).length
                        );
                        recreateSearch(list.meta.criterias);
                      },
                      type: 'button',
                    },
                  ])}
                />
              ) : null}
            </div>
            <div className="listsSubcomponentWrapper__listsSubcomponent__content__bottom">
              {state.lists.subscriptionLists.length ? (
                <Table
                  adjustToContainer={true}
                  columns={tableHelper.getListsColumns()}
                  onSelect={(arr) => {
                    setSelectedLists(
                      state.lists.subscriptionLists.filter((subscriptionList) =>
                        arr.includes(subscriptionList.id)
                      )
                    );
                  }}
                  rows={tableHelper.getListsRows(state.lists.subscriptionLists)}
                  selected={selectedLists.map((list) => list.id)}
                />
              ) : (
                <Info>
                  <h4>{tc.noListsSubscriptionLists}</h4>
                  <p>{tc.noListsSubscriptionListsWhy}</p>
                </Info>
              )}
            </div>
            {handleListsType === 'removeLists' ? (
              <Popup
                // close={() => {
                //   setActivePopup('');
                // }}
                close={_closePopup}
                size="small"
              >
                <div className="listsGenericWrapper">
                  <div className="listsGenericWrapper__listsGeneric">
                    <div className="listsGenericWrapper__listsGeneric__header">
                      <WidgetHeader
                        headline={
                          selectedLists.length > 1
                            ? tc.removeLists
                            : tc.removeList
                        }
                      />
                    </div>
                    <div className="listsGenericWrapper__listsGeneric__content">
                      <p>{tc.ensure}</p>
                    </div>
                    <div className="listsGenericWrapper__listsGeneric__footer">
                      <WidgetFooter
                        buttonOneFunc={_removeLists}
                        buttonOneText={tc.remove}
                        buttonTwoFunc={() => {
                          // setActivePopup('');
                          _closePopup();
                        }}
                        buttonTwoText={tc.cancel}
                      />
                    </div>
                  </div>
                </div>
              </Popup>
            ) : null}
            {handleListsType === 'mergeLists' ? (
              <MergeLists close={_closePopup} selectedLists={selectedLists} />
            ) : null}
            {handleListsType === 'shareLists' ? (
              <ShareLists close={_closePopup} selectedList={selectedLists[0]} />
            ) : null}
            {handleListsType === 'splitList' ? (
              <SplitList close={_closePopup} selectedList={selectedLists[0]} />
            ) : null}
            {handleListsType === 'archiveLists' ? (
              <ArchiveLists close={_closePopup} selectedLists={selectedLists} />
            ) : null}
            {handleListsType === 'excel' ? (
              <Excel
                close={_closePopup}
                selectedLists={selectedLists}
                origin="list"
              />
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

const MapStateToProps = (state) => {
  return {
    lists: state.lists,
  };
};

export default connect(MapStateToProps)(SubscriptionLists);
