import React, { useContext, useEffect, useState, useRef } from "react";
import LanguageContext from "language-context";
import { NavLink, Route } from "react-router-dom";
import { connect } from "react-redux";
import { setNavigationStatus } from "store/user/tasks";
import Icon from "components/icon";
import Tooltip from "components/tooltip";
import { miscHelper } from "helpers";
import { getUpdateDate } from 'store/prospect/tasks';
import Loading from 'components/loading';

/**
 * Navigation component.
 */
const Navigation = (state) => {
  //const isDevUser = state.user.info.dealer_id === 5141;
  
  const fetchingRef = useRef(false);

  useEffect(() => {
    const getData = async () => {
      fetchingRef.current = true;
      const data = await getUpdateDate();

      setUpdateDate(data);

      fetchingRef.current = false;
    }

    if (!fetchingRef.current) {
      getData();
    }
  }, [])

  const [updateDate, setUpdateDate] = useState(null);
  const [subLevelDisplayed, setSubLevelDisplayed] = useState(null);
  const tc = useContext(LanguageContext);
  const dashboardChildren = [
    {
      icon: "newsItem",
      name: tc.news,
      path: "/nyheter",
    },
  ];
  const listsBaseChildren = [
    { icon: "archive", name: tc.archivedLists, path: "/listor/arkiverade" },
    {
      icon: "orders",
      name: tc.toOrder,
      path: "/listor/bestalla",
    },
    {
      icon: "subscription",
      name: tc.listsSubscriptions,
      path: "/listor/prenumerationer",
    },
    {
      icon: "subscription",
      name: tc.subscriptionLists,
      path: "/listor/prenumerationslistor",
    },
  ];
  const items = [
    {
      icon: "dashboard",
      name: tc.dashboard,
      path: "/",
      children: dashboardChildren,
    },
    {
      icon: "agile",
      name: tc.agile,
      path: "/bearbeta",
      children: [
        {
          icon: "measureActivity",
          name: tc.activity,
          path: "/bearbeta/aktivitet",
        },
      ],
    },
    {
      icon: "lists",
      name: tc.lists,
      path: "/listor",
      children: miscHelper.hasContractCustomersPermission(state.user)
        ? listsBaseChildren.concat({
            icon: "lists",
            name: tc.contractCustomerLists,
            path: "/listor/avtal",
          })
        : listsBaseChildren,
    },
    {
      icon: "prospectSearch",
      name: tc.prospectSearch,
      path: "/prospektera",
    },
    {
      icon: "analysis",
      name: tc.analyse,
      path: "/analysera",
    },
    { icon: "leads", name: tc.leads, path: "/leads" },
    {
      icon: "leads",
      path: "/reports",
      name: tc.reports,
      children: [
        {
          icon: "leads",
          name: tc.exports,
          path: "/reports/exports",
        },
      ],
    },
  ];

  useEffect(() => {
    if (state.user.responsiveMode) {
      return;
    }

    const subLevels = document.querySelectorAll(
      ".navigationWrapper__navigation__content__itemsHolder__subLevel"
    );

    return subLevels.forEach((subLevel) => {
      if (subLevelDisplayed && subLevel.className.includes(subLevelDisplayed)) {
        const topLevel = document.querySelector("." + subLevelDisplayed);
        const subLevel = document.querySelector(".parent-" + subLevelDisplayed);
        let parentLiClientRect;
        if (topLevel) {
          parentLiClientRect = topLevel.getBoundingClientRect();
        }
        if (subLevel && parentLiClientRect) {
          subLevel.classList.add("display");
          subLevel.style.top = parentLiClientRect.y + "px";
        }
      } else {
        subLevel.classList.remove("display");
      }
    });
  }, [subLevelDisplayed]);

  const _renderSubLevelItem = (item, pathname) => {
    return (
      <li
        className="navigationWrapper__navigation__content__itemsHolder__subLevel__item"
        key={item.name}
        onClick={() => {
          setSubLevelDisplayed(null);
          if (state.user.responsiveMode) {
            setNavigationStatus({
              val: "hidden",
              skipUpdateSettings: true,
            });
          }
        }}
      >
        <div
          className={
            pathname === item.path
              ? "navigationWrapper__navigation__content__itemsHolder__subLevel__item__wrapper active"
              : "navigationWrapper__navigation__content__itemsHolder__subLevel__item__wrapper"
          }
        >
          <NavLink exact to={item.path} key={item.path}>
            <div className="navigationWrapper__navigation__content__itemsHolder__subLevel__item__wrapper__icon">
              <Icon val={item.icon} />
            </div>
            <div className="navigationWrapper__navigation__content__itemsHolder__subLevel__item__wrapper__name">
              {item.name}
            </div>
          </NavLink>
        </div>
      </li>
    );
  };

  const _renderSubLevels = () => {
    return items.map((num, i) => {
      if (!num || !(Array.isArray(num?.children) && num.children.length)) {
        return null;
      }

      return (
        <ul
          className={`navigationWrapper__navigation__content__itemsHolder__subLevel parent-${num.name}`}
          key={"subLevel-" + num.name}
          onMouseEnter={() => {
            setSubLevelDisplayed(num.name);
          }}
          onMouseLeave={() => {
            setSubLevelDisplayed(null);
          }}
        >
          <Route>
            {({ location }) => {
              const pathname = location.pathname.toLowerCase();
              return (
                <>
                  {state.user.navigationStatus === "minimize" ? (
                    <div className="navigationWrapper__navigation__content__itemsHolder__subLevel__title">
                      {num.name}
                    </div>
                  ) : null}
                  {num.children.map((x) => {
                    return _renderSubLevelItem(x, pathname);
                  })}
                </>
              );
            }}
          </Route>
        </ul>
      );
    });
  };

  const _renderTopLevel = () => {
    return (
      <ul className="navigationWrapper__navigation__content__itemsHolder__topLevel">
        {items.map((num, i) => {
          if (!num) {
            return null;
          }
          return (
            <React.Fragment key={num.name}>
              <Route>
                {({ location }) => {
                  const pathname = location.pathname.toLowerCase();
                  const item = (
                    <li
                      className={`navigationWrapper__navigation__content__itemsHolder__topLevel__item ${num.name}`}
                      key={num.name}
                      onMouseEnter={() => {
                        setSubLevelDisplayed(num.name);
                      }}
                      onMouseLeave={() => {
                        setSubLevelDisplayed(null);
                      }}
                      onClick={() => {
                        setSubLevelDisplayed(null);
                        if (state.user.responsiveMode) {
                          setNavigationStatus({
                            val: "hidden",
                            skipUpdateSettings: true,
                          });
                        }
                      }}
                    >
                      <div
                        className={`navigationWrapper__navigation__content__itemsHolder__topLevel__item__wrapper ${
                          state.user.responsiveMode
                            ? pathname === num.path
                              ? "active"
                              : ""
                            : (pathname === "/" && num.path === "/") ||
                              (pathname.includes(num.path) &&
                                num.path !== "/") ||
                              (pathname === "/nyheter" && num.path === "/")
                            ? "active"
                            : ""
                        }`}
                      >
                        <NavLink exact to={num.path} key={num.path}>
                          <div className="navigationWrapper__navigation__content__itemsHolder__topLevel__item__wrapper__icon">
                            <Icon val={num.icon} />
                          </div>
                          <div className="navigationWrapper__navigation__content__itemsHolder__topLevel__item__wrapper__name">
                            {num.name}
                          </div>
                        </NavLink>
                      </div>
                      {state.user.responsiveMode &&
                      Array.isArray(num?.children) &&
                      num.children.length ? (
                        <ul
                          className={`navigationWrapper__navigation__content__itemsHolder__subLevel parent-${num.name}`}
                          key={"subLevel-" + num.name}
                          onMouseEnter={() => {
                            setSubLevelDisplayed(num.name);
                          }}
                          onMouseLeave={() => {
                            setSubLevelDisplayed(null);
                          }}
                        >
                          <>
                            {state.user.navigationStatus === "minimize" ? (
                              <div className="navigationWrapper__navigation__content__itemsHolder__subLevel__title">
                                {num.name}
                              </div>
                            ) : null}
                            {num.children.map((x) => {
                              return _renderSubLevelItem(x, pathname);
                            })}
                          </>
                        </ul>
                      ) : null}
                    </li>
                  );

                  return state.user.navigationStatus === "minimize" &&
                    !(Array.isArray(num.children) && num.children.length) ? (
                    <Tooltip
                      tooltipContent={num.name}
                      placement="right"
                      key={num.path}
                    >
                      {item}
                    </Tooltip>
                  ) : (
                    item
                  );
                }}
              </Route>
            </React.Fragment>
          );
        })}
      </ul>
    );
  };

  return (
    <div
      className={`navigationWrapper ${state.user.navigationStatus} ${
        state.user.responsiveMode ? "responsiveMode" : ""
      }`}
    >
      <div className="navigationWrapper__navigation">
        <div className="navigationWrapper__navigation__header">
          <div className="navigationWrapper__navigation__header__logotype" />
        </div>
        <div className="navigationWrapper__navigation__content">
          <div className="navigationWrapper__navigation__content__itemsHolder">
            {_renderTopLevel()}
            {state.user.responsiveMode ? null : _renderSubLevels()}
          </div>
        </div>
        <div className="navigationWrapper__navigation__date">
          {updateDate && state.user.navigationStatus === 'regular'
            ? <span>{`Senaste datauppdatering: ${updateDate.date}`}</span>
            : null
          }
        </div>
        <div
          className="navigationWrapper__navigation__footer"
          onClick={() => {
            if (state.user.responsiveMode) {
              setNavigationStatus({
                val:
                  state.user.navigationStatus === "regular"
                    ? "hidden"
                    : "regular",
              });
            } else {
              setNavigationStatus({
                val:
                  state.user.navigationStatus === "regular"
                    ? "minimize"
                    : "regular",
              });
            }
          }}
        >
          <Icon
            val={
              state.user.responsiveMode
                ? "clear"
                : state.user.navigationStatus === "regular"
                ? "navigatePrevious"
                : "navigateNext"
            }
          />
        </div>
      </div>
    </div>
  );
};

const MapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(MapStateToProps)(Navigation);
