import {koncernActionTypes} from './actions';

interface KoncernState {
    info: object | null,
    aggs: object | null,
    responsible: object | null,
    structure: Array<object> | null,
    allNodeIds: Array<string> | null,
    rootId: string | null,
    deals: Array<object> | null,
    basicInfo: object | null,
}

const initialState: KoncernState = {
    info: null,
    aggs: null,
    responsible: null,
    structure: null,
    allNodeIds: null,
    rootId: null,
    deals: null,
    basicInfo: null,
};

export const koncernReducer = (state = initialState, action) => {
    switch(action.type) {
        case koncernActionTypes.SET_KONCERN_DATA: {
            return {
                ...state,
                info: action.payload.info,
                aggs: action.payload.aggs,
            }
        }
        case koncernActionTypes.SET_KONCERN_BASIC_INFO: {
            return {
                ...state,
                basicInfo: action.payload,
            }
        }
        case koncernActionTypes.RESET_KONCERN_DATA: {
            return {
                ...state,
                info: null,
                aggs: null,
            }
        }
        case koncernActionTypes.RESET_KONCERN_STRUCTURE: {
            return {
                ...state,
                structure: null,
                allNodeIds: null,
                rootId: null,
            }
        }
        case koncernActionTypes.SET_KONCERN_RESPONSIBLE: {
            return {
                ...state,
                responsible: action.payload,
            }
        }
        case koncernActionTypes.SET_KONCERN_STRUCTURE: {
            return {
                ...state,
                structure: action.payload.tree[0],
                allNodeIds: action.payload.allNodeIds,
                rootId: action.payload.rootId,
            }
        }
        case koncernActionTypes.SET_KONCERN_DEALS: {
            return {
                ...state,
                deals: action.payload,
            }
        }
        case koncernActionTypes.TOGGLE_SHOW_EMPTY: {
            return {
                ...state,
                structure: action.payload,
            }
        }
        default: {
            return state;
        }
    }
};
