import { newsActionTypes } from "./actions";

interface NewsState {
  displayItem: string | null;
  editId: string | null;
  items: Array<object> | null;
}

const initialState: NewsState = {
  displayItem: null,
  editId: null,
  items: null,
};

export const newsReducer = (state = initialState, action) => {
  switch (action.type) {
    case newsActionTypes.NEWS_SET_DISPLAY_ITEM: {
      return {
        ...state,
        displayItem: action.payload,
      };
    }
    case newsActionTypes.NEWS_SET_EDIT_ID: {
      return {
        ...state,
        editId: action.payload,
      };
    }
    case newsActionTypes.NEWS_SET_ITEMS: {
      return {
        ...state,
        items: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
