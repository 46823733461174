import React from "react";
import { Portal } from "react-portal";

/**
 * Render an element in its own react-tree.
 */
const Popper = (props) => {
  return <Portal>{props.children}</Portal>;
};

export default Popper;
