import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSummary } from "./hooks/useSummary";
import SummaryMessage from "./summary_message";
import SummaryItem from "./summary_item";
import SummaryTotal from "./summary_total";
import { Box, Skeleton, Stack } from "@mui/material";
import { activityHelper } from "helpers";

/**
 * This component shows a summary of the users activities during a specified period of time.
 * It uses a custom hook to get and filter the user activities.
 * @returns React.ReactElement | null
 */
const Summary = ({ summation }) => {
  const { message, tc } = useSummary();
  const [loaded, setLoaded] = useState(false);
  const [count, setCount] = useState(0);
  const total = useMemo(() => {
    return Object.values(summation).reduce((a, b) => a + b, 0);
  }, [summation]);

  /**
   * Used to add to the total amount/number/count of activities passed to SummaryTotal.
   * @type {function}
   *
   * Memoized to prevent unnecessary SummaryItem re-renders.
   */
  const addToTotal = useCallback((amount) => {
    setCount((prev) => setCount(prev + amount));
  }, []);

  //To mitigate stuttering loading
  useEffect(() => {
    const timeOut = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timeOut);
  }, []);

  if (message && summation) {
    return (
      <Stack
        sx={{
          width: "100%",
          height: "100%",
          backgroundColor: "white",
        }}
      >
        <SummaryMessage message={message} />
        {loaded ? (
          <Box
            sx={{
              height: "100%",
              display: "grid",
              gridTemplateColumns: "repeat(4, 1fr)",
              gap: "2%",
              placeContent: "center",
            }}
          >
            {Object.keys(summation).map((key, index) => (
              <SummaryItem
                key={tc[key]}
                type={activityHelper.getReadableActivity(key, false)}
                action={key}
                amount={summation[key]}
                addToTotal={addToTotal}
              />
            ))}
          </Box>
        ) : (
          <Box
            sx={{
              height: "100%",
              display: "grid",
              gridTemplateColumns: "repeat(4, 1fr)",
              gap: "2%",
              placeContent: "center",
            }}
          >
            {Object.keys(summation).map((key) => (
              <Stack justifyContent="center" alignItems="center" key={key}>
                <Skeleton sx={{ width: "100px", height: "100px" }} />
              </Stack>
            ))}
          </Box>
        )}
        <SummaryTotal count={count} total={total} />
      </Stack>
    );
  }

  return null;
};

export default React.memo(Summary);
