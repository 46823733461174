import React, { useEffect, useRef, useState } from "react";
import MenuButton from "./menu_button";

/**
 * Render a menu that is basically a few buttons.
 * When overflown switch to responsive version.
 *
 * @param props.centered - bool - Centered buttons or not.
 * @param props.items - array - Example: [
 *  {
 *     active: bool (optional),
 *     disabled: bool, (optional),
 *     label: 'Menu option 2',
 *     onClick: func,
 *     type: 'button',
 *   },
 * ]
 * @param props.skipSort - bool (optional) - Skip alphabetic sorting.
 */
const MenuDelimited = (props) => {
  const [items, setItems] = useState([]);
  const [isOverflown, setIsOverflown] = useState(false);
  const [scrollWidth, setScrollWidth] = useState(null);
  const menuDelimitedRef = useRef(null);
  const menuDelimitedResponsiveRef = useRef(null);

  useEffect(() => {
    // Sort alphabetically initially.
    if (props.items?.length) {
      if (props.skipSort) {
        setItems(props.items.filter((num) => num));
      } else {
        setItems(
          props.items
            .filter((num) => num)
            .sort((a, b) => {
              if (a.label < b.label) {
                return -1;
              } else if (a.label > b.label) {
                return 1;
              } else {
                return 0;
              }
            })
        );
      }
    }
  }, [props.items]);

  useEffect(() => {
    const timer = setTimeout(_checkOverflow, 500);
    window.addEventListener("resize", _checkOverflow);
    return () => {
      window.removeEventListener("resize", _checkOverflow);
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    _checkOverflow();
  }, [isOverflown, props.items]);

  /**
   * If buttons cannot fit on one row set isOverflown which adjusts style to a responsive version of the menu.
   * Also adjust back when screen gets wider.
   */
  const _checkOverflow = () => {
    if (menuDelimitedRef?.current) {
      const scrollW = menuDelimitedRef.current.scrollWidth;
      setIsOverflown(scrollW > menuDelimitedRef.current.clientWidth);
      if (!scrollWidth) {
        setScrollWidth(scrollW);
      }
    } else if (!scrollWidth && menuDelimitedResponsiveRef.current) {
      // If user loads page where menuFloatFreeResponsiveRef is active right away, we want to quickly render regular Menu
      // if scrollWidth doesn't already exist, and that way get a scrollWidth value.
      const scroll = scrollWidth ? scrollWidth : 0;
      setIsOverflown(scroll > menuDelimitedResponsiveRef.current.clientWidth);
    }
  };

  return (
    <div
      className={
        isOverflown
          ? "menuDelimitedWrapper responsive"
          : props.centered
          ? "menuDelimitedWrapper centered"
          : props.startFromRight
          ? "menuDelimitedWrapper startFromRight"
          : "menuDelimitedWrapper"
      }
    >
      <div
        className="menuDelimitedWrapper__menuDelimited"
        ref={isOverflown ? menuDelimitedResponsiveRef : menuDelimitedRef}
      >
        <div className="menuDelimitedWrapper__menuDelimited__items">
          {items.map((num, i) => {
            if (num.type === "button") {
              return (
                <MenuButton
                  active={num.active}
                  disabled={num.disabled}
                  icon={num.icon}
                  iconToTheRight={num.iconToTheRight}
                  key={i}
                  label={num.label}
                  onClick={() => {
                    if (!num.disabled && typeof num.onClick === "function") {
                      num.onClick();
                    }
                  }}
                />
              );
            } else {
              return null;
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default MenuDelimited;
