import React, { useContext } from 'react';
import LanguageContext from 'language-context';
import { archiveLists } from 'store/lists/tasks';
import Popup from 'components/popup';
import WidgetFooter from 'components/widget_footer';
import WidgetHeader from '../widget_header';

const ArchiveLists = (props) => {
  const tc = useContext(LanguageContext);

  const _archiveLists = async () => {
    await archiveLists({ listIds: props.selectedLists.map((list) => list.id) });
    props.close();
  };

  return props.selectedLists?.length ? (
    <Popup close={props.close} size="small">
      <div className="listsGenericWrapper">
        <div className="listsGenericWrapper__listsGeneric">
          <div className="listsGenericWrapper__listsGeneric__header">
            <WidgetHeader
              headline={
                props.selectedLists.length > 1
                  ? tc.archiveLists
                  : tc.archiveList
              }
              headlineSub={tc.archiveListsInfo}
            />
          </div>
          <div className="listsGenericWrapper__listsGeneric__content">
            <div className="listsGenericWrapper__listsGeneric__content__listHolder">
              <div className="listsGenericWrapper__listsGeneric__content__listHolder__left">
                {props.selectedLists.length > 1 ? tc.lists : tc.list}:
              </div>
              <div className="listsGenericWrapper__listsGeneric__content__listHolder__right">
                {props.selectedLists.map((list, i) => {
                  if (i === props.selectedLists.length - 1) {
                    return <p key={i}>{list.name}</p>;
                  } else {
                    return (
                      <p key={i}>
                        {list.name}
                        <span className="bullet">&#8226;</span>
                      </p>
                    );
                  }
                })}
              </div>
            </div>
            <p>{tc.archiveListsContent}</p>
          </div>
        </div>
        <div className="listsGenericWrapper__listsGeneric__footer">
          <WidgetFooter
            buttonOneFunc={_archiveLists}
            buttonOneText={
              props.selectedLists.length > 1 ? tc.archiveLists : tc.archiveList
            }
            buttonTwoFunc={props.close}
            buttonTwoText={tc.cancel}
          />
        </div>
      </div>
    </Popup>
  ) : null;
};

export default ArchiveLists;
