import React, { useContext } from 'react';
import LanguageContext from 'language-context';
import { removeLists } from 'store/lists/tasks';
import WidgetFooter from 'components/widget_footer';
import WidgetHeader from 'components/widget_header';
import Popup from '../popup';

const RemoveLists = (props) => {
  const tc = useContext(LanguageContext);

  const _removeLists = async () => {
    await removeLists({
      archived: false,
      listIds: props.selectedLists.map((list) => list.id),
    });
    props.close();
  };

  return props.selectedLists?.length ? (
    <Popup close={props.close} size="small">
      <div className="listsGenericWrapper">
        <div className="listsGenericWrapper__listsGeneric">
          <div className="listsGenericWrapper__listsGeneric__header">
            <WidgetHeader
              headline={
                props.selectedLists.length > 1 ? tc.removeLists : tc.removeList
              }
              headlineSub={tc.removeListsInfo}
            />
          </div>
          <div className="listsGenericWrapper__listsGeneric__content">
            <p>
              {tc.ensure} {tc.archiveListsFunctionality}
            </p>
          </div>
          <div className="listsGenericWrapper__listsGeneric__footer">
            <WidgetFooter
              buttonOneFunc={_removeLists}
              buttonOneText={tc.remove}
              buttonTwoFunc={props.close}
              buttonTwoText={tc.cancel}
            />
          </div>
        </div>
      </div>
    </Popup>
  ) : null;
};

export default RemoveLists;
