import React from "react";
import PrintAgileBoardPreview from "./print_agile_board_preview";

/**
 * Render a printable view.
 *
 * We use this solution because some of our users have requested to be able to see a
 * simpler view without necessarily printing it.
 */
const Print = (props) => {
  let content;

  switch (props.type) {
    case "agilePreview":
      content = (
        <PrintAgileBoardPreview
          activities={props.activities}
          data={props.data}
          target={props.target}
        />
      );
      break;
    default:
      content = (
        <PrintAgileBoardPreview
          activities={props.activities}
          data={props.data}
          target={props.target}
        />
      );
  }

  return <div id="printHidden">{content}</div>;
};

export default Print;
