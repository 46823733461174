import React, { useState, useContext } from 'react';
import IconStyled from 'components/icon_styled';
import Icon from 'components/icon';
import languageContext from 'language-context';
import { agileHelper } from 'helpers';
import ActionButton from 'components/action_button';
import Info from 'components/info';
const AgileBoardPopupActiveDeal = ({
  hideBlocks,
  setHideBlocks,
  previewData,
  disableHeader,
}) => {
  const tc = useContext(languageContext);
  const [visibleDeals, setVisibleDeals] = useState(1);

  const handleLoadMore = () => {
    setVisibleDeals((prevVisibleDeals) => prevVisibleDeals + 3);
  };
  const handleToggleVisibility = () => {
    setVisibleDeals(
      (prevVisibleDeals) =>
        prevVisibleDeals - (previewData.item.activeDeals.length + 1)
    );
  };

  if (previewData.item?.activeDeals?.length) {
    const displayedDeals = previewData.item.activeDeals.slice(0, visibleDeals);
    return (
      <div className="agileBoardPopupWrapper__agilePopup__content__block__custom__margin">
        {!disableHeader && (
          <div
            className="agileBoardPopupWrapper__agilePopup__content__block__header"
            onClick={() => {
              if (hideBlocks.includes('activeDeals')) {
                setHideBlocks(
                  hideBlocks.filter((num) => num !== 'activeDeals')
                );
              } else {
                setHideBlocks(hideBlocks.concat('activeDeals'));
              }
            }}
          >
            <div className="agileBoardPopupWrapper__agilePopup__content__block__header__title">
              <p>
                {tc.active} {tc.deals.toLowerCase()}
              </p>
              {hideBlocks.includes('activeDeals') ? (
                <Icon val="maximize" />
              ) : (
                <Icon val="minimize" />
              )}
            </div>
          </div>
        )}
        {disableHeader && (
          <p className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__left">
            {tc.active} {tc.deals.toLowerCase()}
          </p>
        )}
        {!hideBlocks.includes('activeDeals') ? (
          <div className="agileBoardPopupWrapper__agilePopup__content__block__content">
            {displayedDeals.map((num, i) => {
              return (
                <div
                  className="agileBoardPopupWrapper__agilePopup__content__block__content__row__custom"
                  key={i}
                >
                  <div className="agileBoardPopupWrapper__agilePopup__content__block__content__row__left">
                    <IconStyled class="info" iconVal="agile" />{' '}
                    <div className="agileBoardPopupWrapper__agilePopup__content__block__content__row__left__content">
                      {num.ownDeal ? (
                        <>
                          {`${
                            tc.prospect
                          } ${tc.appearsIn.toLowerCase()} ${tc.ownDeal.toLowerCase()} ${tc.with.toLowerCase()}`}
                          <span className="marginLeft marginRight">
                            {tc.phase.toLowerCase()}
                          </span>
                          <span className="highlight">
                            {agileHelper.buildPhaseToColumnTitle(num.phase)}
                          </span>
                        </>
                      ) : (
                        <>
                          {`${
                            tc.prospect
                          } ${tc.appearsIn.toLowerCase()} ${tc.deal.toLowerCase()}`}
                          <span className="marginLeft marginRight">
                            {tc.at.toLowerCase()}
                          </span>
                          <span className="highlight marginRight">
                            {num.user?.name ? num.user.name : tc.unknown}
                          </span>
                          {`${tc.with.toLowerCase()} ${tc.phase.toLowerCase()}`}
                          <span className="highlight marginLeft">
                            {agileHelper.buildPhaseToColumnTitle(num.phase)}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
            {previewData.item?.activeDeals?.length > visibleDeals ? (
              <div className="agileBoardPopupWrapper__agilePopup__content__block__buttonContainer">
                <ActionButton
                  class="agileBoardPopupWrapper__agilePopup__content__block__button"
                  icon={'reset'}
                  label={tc.getMore}
                  onClick={handleLoadMore}
                  type="regular"
                />
              </div>
            ) : visibleDeals > 1 ? (
              <div className="agileBoardPopupWrapper__agilePopup__content__block__buttonContainer">
                <ActionButton
                  class="agileBoardPopupWrapper__agilePopup__content__block__button"
                  icon={'maximizeUp'}
                  label={tc.hide}
                  onClick={handleToggleVisibility}
                  type="regular"
                />
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  } else {
    return (
      <div className="agileBoardPopupWrapper__agilePopup__content__block__custom__deal">
        <p className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__left">
          {tc.active} {tc.deals.toLowerCase()}:
        </p>
        <div className="activitiesFlowWrapper__activitiesFlow__content__middle">
          <Info>
            <h4>{tc.noActiveDeals}</h4>
            <p>Aktiva affärer saknas.</p>
          </Info>
        </div>
      </div>
    );
  }
};
export default AgileBoardPopupActiveDeal;
