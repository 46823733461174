import cssVariables from "../styles/_exported_variables.module.scss";
import CarHelper from "shared_helpers/car_helper";
import moment from "moment";
import { store } from "store";
import * as text from "text-content";
import { widgetHelper } from "./widget_helper";

export const chartHelper = {
  /**
   * Get one colors.
   *
   * @param index - number
   * @param dark - bool
   * @param light - bool
   * @param opacity - bool
   */
  getChartColor: (index, dark = false, light = false, opacity = false) => {
    const chartColors = Object.keys(cssVariables)
      .map((key) => {
        if (dark) {
          return key.includes("colorChartDark") ? cssVariables[key] : null;
        } else if (light) {
          return key.includes("colorChartLight") ? cssVariables[key] : null;
        } else if (opacity) {
          return key.includes("colorChartOpacity") ? cssVariables[key] : null;
        } else {
          return key.includes("colorChartRegular") ? cssVariables[key] : null;
        }
      })
      .filter((num) => num);

    return chartColors[index] ? chartColors[index] : chartColors[0];
  },
  /**
   * Generate chart colors array in order back and forth.
   *
   * @param length - number - Length of array.
   * @param light - bool - Return light colors.
   * @param dark - bool - return darker colors.
   * @param opacity - bool - return opacity colors.
   */
  generateChartColors: (
    length,
    light = false,
    dark = false,
    opacity = false
  ) => {
    //prevent endless loop of doom!
    if (!length) return;
    let result = [];
    const chartColors = Object.keys(cssVariables)
      .map((key) => {
        if (dark) {
          return key.includes("colorChartDark") ? cssVariables[key] : null;
        } else if (light) {
          return key.includes("colorChartLight") ? cssVariables[key] : null;
        } else if (opacity) {
          return key.includes("colorChartOpacity") ? cssVariables[key] : null;
        } else {
          return key.includes("colorChartRegular") ? cssVariables[key] : null;
        }
      })
      .filter((num) => num);

    let i = 0;
    const generateColorBackAndForth = () => {
      if (i < chartColors.length - 1) {
        result = result.concat(chartColors[i]);
        i++;
      } else {
        chartColors.reverse();
        i = 0;
        result = result.concat(chartColors[i]);
        i = 1;
      }

      // Recursive.
      return result.length >= length ? true : generateColorBackAndForth();
    };

    generateColorBackAndForth();
    return result;
  },
  /**
   * Options for chart.js
   */
  optionsBar: (payload) => {
    return {
      plugins: {
        legend: {
          display: !!payload.legend,
        },
      },
      onHover: payload.drillable
        ? (event, chartElement) => {
            chartElement[0]
              ? (event.native.target.style.cursor = "pointer")
              : (event.native.target.style.cursor = "default");
          }
        : undefined,
      indexAxis: payload.horizontal ? "y" : "x",
      responsive: true,
      scales: {
        yAxes: {
          position: "left",
          stacked: true,
          ticks: {
            beginAtZero: true,
            min: 0,
          },
          grid: {
            display: false,
          },
        },
        xAxes: {
          stacked: true,
          ticks: {
            beginAtZero: true,
            min: 0,
          },
          grid: {
            display: false,
          },
        },
      },
    };
  },
  optionsBarAnalysera: (payload) => {
    return {
      plugins: {
        legend: {
          display: !!payload.legend,
        },
      },
      onHover: payload.drillable
        ? (event, chartElement) => {
            chartElement[0]
              ? (event.native.target.style.cursor = "pointer")
              : (event.native.target.style.cursor = "default");
          }
        : undefined,
      indexAxis: payload.horizontal ? "y" : "x",
      responsive: true,
      scales: {
        yAxes: {
          position: "left",
          stacked: false,
          ticks: {
            beginAtZero: true,
            min: 0,
            callback: function (value) {
              const tc = store?.getState()?.user?.info?.lang
                ? store.getState().user.info.lang === "en"
                  ? text.english
                  : text.swedish
                : text.swedish;

              const val = this.getLabelForValue(value);
              return tc[val] || val;
            },
          },
          grid: {
            display: false,
          },
        },
        xAxes: {
          stacked: false,
          ticks: {
            beginAtZero: true,
            min: 0,
            callback: function (value) {
              const tc = store?.getState()?.user?.info?.lang
                ? store.getState().user.info.lang === "en"
                  ? text.english
                  : text.swedish
                : text.swedish;

              const val =
                this.getLabelForValue(value).length > 10
                  ? this.getLabelForValue(value).substring(0, 8) + "..."
                  : this.getLabelForValue(value);
              return tc[val] || val;
            },
          },
          grid: {
            display: false,
          },
        },
      },
    };
  },
  /**
   * Options for chart.js
   */
  optionsLine: (payload) => {
    return {
      plugins: {
        legend: {
          display: !!payload.legend,
        },
      },
      onHover: payload.drillable
        ? (event, chartElement) => {
            chartElement[0]
              ? (event.native.target.style.cursor = "pointer")
              : (event.native.target.style.cursor = "default");
          }
        : undefined,
      responsive: true,
      maintainAspectRatio: true,
      elements: {
        line: {
          tension: 0,
          fill: false,
        },
      },
      scales: {
        yAxes: {
          ticks: {
            beginAtZero: true,
            min: 0,
            fontColor: "#000",
          },
          gridLines: {
            display: false,
          },
        },
        xAxes: {
          stacked: false,
          ticks: {
            beginAtZero: true,
            min: 0,
          },
          gridLines: {
            display: false,
          },
        },
      },
    };
  },
  /**
   * Options for chart.js
   */
  optionsBubble: {
    plugins: {
      legend: {
        display: true,
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          let str = "";
          if (
            data?.datasets[tooltipItem.datasetIndex] &&
            data.datasets[tooltipItem.datasetIndex].data &&
            data.datasets[tooltipItem.datasetIndex].label &&
            tooltipItem &&
            tooltipItem.hasOwnProperty("index") &&
            data.datasets[tooltipItem.datasetIndex].data[
              tooltipItem.index
            ].hasOwnProperty("x") &&
            data.datasets[tooltipItem.datasetIndex].data[
              tooltipItem.index
            ].hasOwnProperty("y")
          ) {
            // We use callback to remove 'r' value from tooltip.
            str = `${data.datasets[tooltipItem.datasetIndex].label} ${
              data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].x
            }: ${
              data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].y
            }`;
          }
          return str;
        },
      },
    },
    responsive: true,
    scales: {
      yAxes: {
        position: "left",
        ticks: {
          beginAtZero: true,
          min: 0,
        },
        grid: {
          display: true,
        },
      },
      xAxes: {
        grid: {
          display: true,
        },
      },
    },
  },
  /**
   * Options for chart.js
   */
  optionsDoughnut: {
    plugins: {
      legend: {
        display: true,
        position: "left",
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          let str = "";
          if (
            data?.datasets[tooltipItem.datasetIndex] &&
            data.datasets[tooltipItem.datasetIndex].data &&
            data.datasets[tooltipItem.datasetIndex].label &&
            data.datasets[tooltipItem.datasetIndex].labels &&
            tooltipItem &&
            tooltipItem.hasOwnProperty("index")
          ) {
            // We use callback since dataset label isn't displayed as default,
            // which make it impossible to know which values is related to last month dimension.
            str = `${data.datasets[tooltipItem.datasetIndex].label} ${
              data.datasets[tooltipItem.datasetIndex].labels[tooltipItem.index]
            }: ${
              data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
            }`;
          }
          return str;
        },
      },
    },
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 2,
    scales: {
      yAxes: {
        grid: {
          drawBorder: false,
          display: false,
        },
        ticks: {
          display: false,
        },
      },
      xAxes: {
        grid: {
          drawBorder: false,
          display: false,
        },
        ticks: {
          display: false,
        },
      },
    },
  },
  formatXLabels: (label) => {
    const tc = store?.getState()?.user?.info?.lang
      ? store.getState().user.info.lang === "en"
        ? text.english
        : text.swedish
      : text.swedish;

    const vehicleSegments = CarHelper.allTypes.reduce((acc, type) => {
      acc[type] = type.toLowerCase();
      return acc;
    }, {});
    const dateFormat = "YYYY-MM-DDTHH:mm:ss.SSS";

    if (moment(label, dateFormat, true).isValid()) {
      return moment(label).format("YYYY-MM-DD");
    } else {
      return tc[vehicleSegments[label] || label] || label;
    }
  },
  formatXLabelsCompareDateRanges: (label, granularity) => {
    if (granularity === "day") {
      return moment(label).format("DD");
    } else if (granularity === "week") {
      return moment(label).format("WW");
    } else if (granularity === "month") {
      return moment(label).format("MMMM");
    } else if (granularity === "quarter") {
      return moment(label).quarter();
    } else if (granularity === "year") {
      return moment(label).format("YYYY");
    } else {
      return;
    }
  },
  /**
   * @description Get map config
   *
   */
  mapConfig: {
    intervalTypes: ["EQI", "APG", "GPG", "JNK"],
    postortFeaturesSourceLayer: "ytaorter-3y9im2",
    MAPBOX_TOKEN:
      "pk.eyJ1IjoiYmlscCIsImEiOiJjbDgyeXpoZ2YwMHFuM3lxbzF3aGVidnQwIn0.IF4fYdP3Wc_uGRV5CwU1Eg",
    MAPBOX_STYLE: "mapbox://styles/bilp/cl8isr9yi003115llog2b73nh",
  },
  /**
   * @description Get map gradients.
   *
   * @param {number } intervals
   * @param {string} shade
   * @see https://colorbrewer2.org/
   */
  mapGradients: (intervals = 5, shade = "YlGn") => {
    const gradients = {
      YlGn: {
        4: [
          "rgb(255,255,204)",
          "rgb(194,230,153)",
          "rgb(120,198,121)",
          "rgb(35,132,67)",
        ],
        5: [
          "rgb(255,255,204)",
          "rgb(194,230,153)",
          "rgb(120,198,121)",
          "rgb(49,163,84)",
          "rgb(0,104,55)",
        ],
        6: [
          "rgb(255,255,204)",
          "rgb(217,240,163)",
          "rgb(173,221,142)",
          "rgb(120,198,121)",
          "rgb(49,163,84)",
          "rgb(0,104,55)",
        ],
        7: [
          "rgb(255,255,204)",
          "rgb(217,240,163)",
          "rgb(173,221,142)",
          "rgb(120,198,121)",
          "rgb(65,171,93)",
          "rgb(35,132,67)",
          "rgb(0,90,50)",
        ],
      },
      PuBuGn: {
        4: [
          "rgb(246,239,247)",
          "rgb(189,201,225)",
          "rgb(103,169,207)",
          "rgb(2,129,138)",
        ],
        5: [
          "rgb(246,239,247)",
          "rgb(189,201,225)",
          "rgb(103,169,207)",
          "rgb(28,144,153)",
          "rgb(1,108,89)",
        ],
        6: [
          "rgb(246,239,247)",
          "rgb(208,209,230)",
          "rgb(166,189,219)",
          "rgb(103,169,207)",
          "rgb(28,144,153)",
          "rgb(1,108,89)",
        ],
        7: [
          "rgb(246,239,247)",
          "rgb(208,209,230)",
          "rgb(166,189,219)",
          "rgb(103,169,207)",
          "rgb(54,144,192)",
          "rgb(2,129,138)",
          "rgb(1,100,80)",
        ],
      },
      PuRd: {
        4: [
          "rgb(241,238,246)",
          "rgb(215,181,216)",
          "rgb(223,101,176)",
          "rgb(206,18,86)",
        ],
        5: [
          "rgb(241,238,246)",
          "rgb(215,181,216)",
          "rgb(223,101,176)",
          "rgb(221,28,119)",
          "rgb(152,0,67)",
        ],
        6: [
          "rgb(241,238,246)",
          "rgb(212,185,218)",
          "rgb(201,148,199)",
          "rgb(223,101,176)",
          "rgb(221,28,119)",
          "rgb(152,0,67)",
        ],
        7: [
          "rgb(241,238,246)",
          "rgb(212,185,218)",
          "rgb(201,148,199)",
          "rgb(223,101,176)",
          "rgb(231,41,138)",
          "rgb(206,18,86)",
          "rgb(145,0,63)",
        ],
      },
      Purples: {
        4: [
          "rgb(242,240,247)",
          "rgb(203,201,226)",
          "rgb(158,154,200)",
          "rgb(106,81,163)",
        ],
        5: [
          "rgb(242,240,247)",
          "rgb(203,201,226)",
          "rgb(158,154,200)",
          "rgb(117,107,177)",
          "rgb(84,39,143)",
        ],
        6: [
          "rgb(242,240,247)",
          "rgb(218,218,235)",
          "rgb(188,189,220)",
          "rgb(158,154,200)",
          "rgb(117,107,177)",
          "rgb(84,39,143)",
        ],
        7: [
          "rgb(242,240,247)",
          "rgb(218,218,235)",
          "rgb(188,189,220)",
          "rgb(158,154,200)",
          "rgb(128,125,186)",
          "rgb(106,81,163)",
          "rgb(74,20,134)",
        ],
      },
      BuPu: {
        4: [
          "rgb(237,248,251)",
          "rgb(179,205,227)",
          "rgb(140,150,198)",
          "rgb(136,65,157)",
        ],
        5: [
          "rgb(237,248,251)",
          "rgb(179,205,227)",
          "rgb(140,150,198)",
          "rgb(136,86,167)",
          "rgb(129,15,124)",
        ],
        6: [
          "rgb(237,248,251)",
          "rgb(191,211,230)",
          "rgb(158,188,218)",
          "rgb(140,150,198)",
          "rgb(136,86,167)",
          "rgb(129,15,124)",
        ],
        7: [
          "rgb(237,248,251)",
          "rgb(191,211,230)",
          "rgb(158,188,218)",
          "rgb(140,150,198)",
          "rgb(140,107,177)",
          "rgb(136,65,157)",
          "rgb(110,1,107)",
        ],
      },
      Blues: {
        4: [
          "rgb(239,243,255)",
          "rgb(189,215,231)",
          "rgb(107,174,214)",
          "rgb(33,113,181)",
        ],
        5: [
          "rgb(239,243,255)",
          "rgb(189,215,231)",
          "rgb(107,174,214)",
          "rgb(49,130,189)",
          "rgb(8,81,156)",
        ],
        6: [
          "rgb(239,243,255)",
          "rgb(198,219,239)",
          "rgb(158,202,225)",
          "rgb(107,174,214)",
          "rgb(49,130,189)",
          "rgb(8,81,156)",
        ],
        7: [
          "rgb(239,243,255)",
          "rgb(198,219,239)",
          "rgb(158,202,225)",
          "rgb(107,174,214)",
          "rgb(66,146,198)",
          "rgb(33,113,181)",
          "rgb(8,69,148)",
        ],
      },
    };
    return gradients[shade][intervals];
  },

  /**
   * Creates an object like:
   * {key<configuration/gridView>: _id[], ...}
   * Can be useful for filtering out widgets that are not in the current grid view for generating a default layouts object.
   */
  passFilter: (widgets) => {
    if (!Array.isArray(widgets) || widgets.length === 0) {
      return {};
    }
    return widgets.reduce((acc, widget) => {
      for (const [view, options] of Object.entries(widget.dashboards)) {
        if (options.active) {
          acc[view] = acc.hasOwnProperty(view)
            ? [...acc[view], widget._id]
            : [widget._id];
        } else {
          acc[view] = acc.hasOwnProperty(view) ? [...acc[view]] : [];
        }
      }
      return acc;
    }, {});
  },
};
