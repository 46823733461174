import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  getUserPdfs,
  removeUserPdf,
  shareUserPdf,
  uploadUserPdf,
} from 'store/pdf/tasks';
import { showFlashMessage } from 'store/flash_messages/tasks';
import LanguageContext from 'language-context';
import ActionButton from 'components/action_button';
import ColleaguesRows from 'components/colleagues_rows';
import Icon from 'components/icon';
import Info from 'components/info';
import ItemWithButtons from 'components/item_with_buttons';
import Loading from 'components/loading';
import PdfPreview from 'components/pdf_preview';
import Tooltip from 'components/tooltip';
import WidgetFooter from 'components/widget_footer';

/**
 * Component to display PDF-archive with possibility to upload new files.
 *
 * @param state.props.download - bool (optional) - If download possibility should be displayed. Default to true.
 * @param state.props.fullWidth - bool (optional) - Full width for items.
 * @param state.props.hideUpload - bool (optional) - If download possibility should be displayed. Default to tfalse.
 * @param state.props.onSelect - func (optional) - If this prop is provided the pdf can be selected and returns the pdf via onSelect.
 * @param state.props.preview - bool (optional) - If preview possibility should be displayed. Default to true.
 * @param state.props.remove - bool (optional) - If remove possibility should be displayed. Default to true.
 * @param state.props.selectedS3Key - string (optional) - The s3Key of selected pdf. Probably used together with onSelect.
 * @param state.props.share - bool (optional) - If share possibility should be displayed. Default to true.
 */
const PdfArchiveManageFiles = (state) => {
  const tc = useContext(LanguageContext);
  const [customFileName, setCustomFileName] = useState('');
  const [originalFileName, setOriginalFileName] = useState('');
  const [pdfToRemove, setPdfToRemove] = useState(null);
  const [pdfToShare, setPdfToShare] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [sharePdfUsers, setSharePdfUsers] = useState([]);

  useEffect(() => {
    getUserPdfs({
      type: 'mailings',
    });
  }, []);

  const _reset = () => {
    setPdfToRemove(null);
    setPdfToShare(null);
    setPreviewUrl(null);
    setCustomFileName('');
    setOriginalFileName('');
    setSharePdfUsers([]);

    const customName = document.querySelector('#custom-name');
    if (customName) {
      customName.value = '';
    }

    const inputPdf = document.querySelector('#user-pdf');
    if (inputPdf) {
      inputPdf.value = null;
    }
  };

  const _removeUserPdf = async () => {
    await removeUserPdf(pdfToRemove);
    _reset();
    getUserPdfs({
      type: 'mailings',
    });
  };

  const _shareUserPdf = async () => {
    await shareUserPdf({
      id: pdfToShare.id,
      users: sharePdfUsers.map((num) => num.id),
    });
    _reset();
  };

  const _uploadUserPdfToArchive = async () => {
    const inputPdf = document.querySelector('#user-pdf');
    if (
      inputPdf?.files &&
      inputPdf.files[0] &&
      originalFileName &&
      customFileName?.length
    ) {
      await uploadUserPdf({
        name: customFileName,
        pdf: inputPdf.files[0],
        type: 'mailings',
      });
      _reset();
      getUserPdfs({
        type: 'mailings',
      });
    } else {
      showFlashMessage(tc.couldNotUploadFile, 'fail');
    }
  };

  return (
    <div className="pdfArchiveManageFilesWrapper">
      <div className="pdfArchiveManageFilesWrapper__pdfArchiveManageFiles">
        <div className="pdfArchiveManageFilesWrapper__pdfArchiveManageFiles__content">
          {!state.props.hideUpload ? (
            <div className="pdfArchiveManageFilesWrapper__pdfArchiveManageFiles__content__upload">
              <div className="pdfArchiveManageFilesWrapper__pdfArchiveManageFiles__content__upload__rowOne">
                {originalFileName ? (
                  <div className="pdfArchiveManageFilesWrapper__pdfArchiveManageFiles__content__upload__label">
                    <strong>{tc.nameNewFile}:</strong>
                  </div>
                ) : null}
                <div className="pdfArchiveManageFilesWrapper__pdfArchiveManageFiles__content__upload__rowOne__file">
                  <div className="pdfArchiveManageFilesWrapper__pdfArchiveManageFiles__content__upload__rowOne__file__custom">
                    <input
                      type="file"
                      accept={'.pdf'}
                      id="user-pdf"
                      name="user-pdf"
                      onChange={(e) => {
                        if (e.target?.files && e.target.files[0]) {
                          setOriginalFileName(e.target.files[0].name);
                        }
                      }}
                    />
                    <label
                      className={originalFileName ? 'hidden' : null}
                      htmlFor="user-pdf"
                    >
                      <Icon val="add" />
                      <span>{tc.uploadFileToPDFArchive}</span>
                    </label>
                    <form autoComplete="off">
                      <input
                        className={!originalFileName ? 'hidden' : null}
                        type="text"
                        id="custom-name"
                        onChange={(e) => {
                          setCustomFileName(e.target.value);
                        }}
                      />
                    </form>
                  </div>
                  {originalFileName ? (
                    <ActionButton
                      disabled={customFileName?.length < 3}
                      label={tc.save}
                      onClick={_uploadUserPdfToArchive}
                      type="highlight"
                    />
                  ) : null}
                </div>
              </div>
              {originalFileName ? (
                <div className="pdfArchiveManageFilesWrapper__pdfArchiveManageFiles__content__upload__rowTwo">
                  <div className="pdfArchiveManageFilesWrapper__pdfArchiveManageFiles__content__upload__label">
                    <strong>{tc.file}:</strong>
                  </div>
                  <div className="pdfArchiveManageFilesWrapper__pdfArchiveManageFiles__content__upload__rowTwo__originalName">
                    <span className="fileName">{originalFileName}</span>
                    <Tooltip tooltipContent={tc.cancel}>
                      <Icon onClick={_reset} val="clear" />
                    </Tooltip>
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
          <div className="pdfArchiveManageFilesWrapper__pdfArchiveManageFiles__content__archive">
            {pdfToRemove ? (
              <div
                className={`inputHolder ${
                  state.props.hideUpload ? 'noMargin' : ''
                }`}
              >
                <div className="inputHolder__left">
                  {tc.removeFile}: <strong>{pdfToRemove.name}</strong>
                </div>
                <div className="inputHolder__right">
                  <ActionButton
                    label={tc.cancel}
                    onClick={() => {
                      setPdfToRemove(null);
                    }}
                    type="regular"
                  />
                  <ActionButton
                    label={tc.remove}
                    onClick={_removeUserPdf}
                    type="highlight"
                  />
                </div>
              </div>
            ) : pdfToShare ? (
              <div>
                <p>
                  {tc.shareFile}: <strong>{pdfToShare.name}</strong>
                </p>
                <p className="marginBottomMedium">{tc.shareFileInfo}</p>
                <ColleaguesRows
                  onChange={(arr) => {
                    setSharePdfUsers(arr);
                  }}
                />
                <WidgetFooter
                  buttonOneFunc={_shareUserPdf}
                  buttonOneText={tc.shareFile}
                  buttonTwoFunc={() => {
                    setPdfToShare(null);
                  }}
                  buttonTwoText={tc.cancel}
                  disableButtonOne={!sharePdfUsers.length}
                  noBorder={true}
                />
              </div>
            ) : (
              <div className="pdfArchiveManageFilesWrapper__pdfArchiveManageFiles__content__archive__items">
                {state.pdf.userPdfs ? (
                  Array.isArray(state.pdf.userPdfs) &&
                  state.pdf.userPdfs.length ? (
                    state.pdf.userPdfs.map((num) => {
                      return (
                        <div
                          className={`pdfArchiveManageFilesWrapper__pdfArchiveManageFiles__content__archive__items__item ${
                            state.props.fullWidth ? 'fullWidth' : ''
                          }`}
                          key={num.s3Key}
                        >
                          <ItemWithButtons
                            buttons={[
                              state.props.preview === false
                                ? null
                                : {
                                    active: previewUrl === num.s3SignedUrl,
                                    icon:
                                      previewUrl === num.s3SignedUrl
                                        ? 'visibilityOff'
                                        : 'visibility',
                                    onClick: () => {
                                      if (previewUrl === num.s3SignedUrl) {
                                        setPreviewUrl(null);
                                      } else {
                                        setPreviewUrl(num.s3SignedUrl);
                                      }
                                    },
                                    tooltip:
                                      previewUrl === num.s3SignedUrl
                                        ? tc.closePreview
                                        : tc.preview,
                                  },
                              state.props.share === false
                                ? null
                                : {
                                    icon: 'share',
                                    onClick: () => {
                                      setPdfToShare(num);
                                      setPreviewUrl(null);
                                    },
                                    tooltip: tc.shareFile,
                                  },
                              state.props.download === false
                                ? null
                                : {
                                    icon: 'download',
                                    href: num.s3SignedUrl,
                                    onClick: null,
                                    tooltip: tc.download,
                                  },
                              state.props.remove === false
                                ? null
                                : {
                                    disabled: num.activeSubscriptions?.length,
                                    icon: 'remove',
                                    onClick: () => {
                                      setPdfToRemove(num);
                                      setPreviewUrl(null);
                                    },
                                    tooltip: num.activeSubscriptions?.length
                                      ? tc.cannotRemovePdfWithActiveSubscription
                                      : tc.remove,
                                  },
                            ].filter((num) => num)}
                            // icon="file"
                            name={num.name}
                            onSelect={
                              typeof state.props.onSelect === 'function'
                                ? () => {
                                    state.props.onSelect(num);
                                  }
                                : null
                            }
                            warning={!num.validated}
                            warningText={tc.waitingForValidation}
                            selected={state.props.selectedS3Key === num.s3Key}
                            label={tc.pdf}
                          />
                        </div>
                      );
                    })
                  ) : (
                    <Info>
                      <h4>{tc.noPdfs}</h4>
                      <p>{tc.noPdfsWhy}</p>
                    </Info>
                  )
                ) : (
                  <Loading />
                )}
              </div>
            )}
          </div>
          {previewUrl ? (
            <div className="pdfArchiveManageFilesWrapper__pdfArchiveManageFiles__content__preview">
              <PdfPreview source={previewUrl} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const MapStateToProps = (state, props) => {
  return {
    pdf: state.pdf,
    props: props,
  };
};

export default connect(MapStateToProps)(PdfArchiveManageFiles);
