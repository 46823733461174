import React, { useContext, useEffect, useState } from 'react';
import LanguageContext from 'language-context';
import {
  getListsSubscriptions,
  removeListsSubscriptions,
} from 'store/lists/tasks';
import { recreateSearch } from 'store/prospect/tasks';
import { connect } from 'react-redux';
import { tableHelper } from 'helpers';
import { Table } from 'components/table';
import Info from 'components/info';
import Menu from 'components/menu';
import PageHeader from 'components/page_header';
import Popup from 'components/popup';
import WidgetHeader from 'components/widget_header';
import WidgetFooter from 'components/widget_footer';
import ShareListSubscriptions from 'components/lists/share_list_subscriptions';
import EditListSubscription from 'components/edit_list_subscription';

const ListsSubscriptions = (state) => {
  const [activePopup, setActivePopup] = useState('');
  const [selectedSubscriptions, setSelectedSubscriptions] = useState([]);
  const [rows, setRows] = useState([]);
  const [colleagues, setColleagues] = useState([]);
  const [connections, setConnections] = useState([]);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    getListsSubscriptions();
  }, []);

  useEffect(() => {
    if (state.user) {
      if (state.user.colleagues) setColleagues(state.user.colleagues);
      if (state.user.connections)
        setConnections(
          state.user.connections.reduce(
            (acc, item) => acc.concat(item.users),
            []
          )
        );
    }
  }, [state.user, state.user.colleagues, state.user.connections]);

  useEffect(() => {
    if (state.lists.listsSubscriptions) {
      const rows = tableHelper.getListsSubscriptionsRows(
        state.lists.listsSubscriptions.map((sub) => ({
          ...sub,
          userNames: colleagues
            .concat(connections)
            .filter(
              (col) =>
                sub.subscribers.includes(col.id) &&
                col.id !== state.user.info.id
            )
            .map((user) => user.name),
        }))
      );
      setRows(rows);
    }
  }, [
    state.lists.listsSubscriptions,
    state.user.info.id,
    colleagues,
    connections,
  ]);

  const _removeListsSubscriptions = async () => {
    setActivePopup('');
    await removeListsSubscriptions({
      ids: selectedSubscriptions.map((num) => num.id),
    });
    return setSelectedSubscriptions([]);
  };

  const _stateCheck = () => {
    return !!(
      state &&
      state.lists &&
      state.lists.listsSubscriptions !== null &&
      state.user &&
      state.user.colleagues &&
      state.user.connections
    );
  };

  return (
    <div className="listsSubcomponentWrapper">
      <div className="listsSubcomponentWrapper__listsSubcomponent">
        <div className="listsSubcomponentWrapper__listsSubcomponent__header">
          <PageHeader
            headlineSub={
              _stateCheck()
                ? `${tc.total} ${
                    state.lists.listsSubscriptions.length
                  } ${tc.listsSubscriptions.toLowerCase()}`
                : null
            }
          />
        </div>
        {_stateCheck() ? (
          <div className="listsSubcomponentWrapper__listsSubcomponent__content">
            <div className="listsSubcomponentWrapper__listsSubcomponent__content__top">
              {state.lists.listsSubscriptions.length ? (
                <Menu
                  items={[
                    {
                      disabled: !selectedSubscriptions.length,
                      label:
                        selectedSubscriptions.length > 1
                          ? tc.removeSubscriptions
                          : tc.removeSubscription,
                      onClick: () => {
                        setActivePopup('removeListsSubscriptions');
                      },
                      type: 'button',
                    },
                    {
                      disabled: !(
                        selectedSubscriptions.length &&
                        selectedSubscriptions.length === 1
                      ),
                      label: tc.recreateCriterias,
                      onClick: () => {
                        recreateSearch(selectedSubscriptions[0].criterias);
                      },
                      type: 'button',
                    },
                    {
                      disabled: selectedSubscriptions.length !== 1,
                      label: tc.shareListSubscription,
                      onClick: () => {
                        setActivePopup('ShareListSubscriptions');
                      },
                      type: 'button',
                    },
                    {
                      disabled: selectedSubscriptions.length !== 1,
                      label: tc.editListSubscription,
                      onClick: () => {
                        setActivePopup('editListSubscription');
                      },
                      type: 'button',
                    },
                  ]}
                />
              ) : null}
            </div>
            <div className="listsSubcomponentWrapper__listsSubcomponent__content__bottom">
              {state.lists.listsSubscriptions.length ? (
                <Table
                  adjustToContainer={true}
                  columns={tableHelper.getListsSubscriptionsColumns()}
                  onSelect={(arr) => {
                    setSelectedSubscriptions(
                      state.lists.listsSubscriptions.filter((num) =>
                        arr.includes(num.id)
                      )
                    );
                  }}
                  rows={rows}
                  selected={selectedSubscriptions.map((num) => num.id)}
                />
              ) : (
                <Info>
                  <h4>{tc.noListsSubscriptionLists}</h4>
                  <p>{tc.noListsSubscriptionListsWhy}</p>
                </Info>
              )}
            </div>
            {activePopup === 'removeListsSubscriptions' ? (
              <Popup
                close={() => {
                  setActivePopup('');
                }}
                size="small"
              >
                <div className="listsGenericWrapper">
                  <div className="listsGenericWrapper__listsGeneric">
                    <div className="listsGenericWrapper__listsGeneric__header">
                      <WidgetHeader
                        headline={
                          selectedSubscriptions.length > 1
                            ? tc.removeSubscriptions
                            : tc.removeSubscription
                        }
                      />
                    </div>
                    <div className="listsGenericWrapper__listsGeneric__content">
                      <p>{tc.ensure}</p>
                    </div>
                    <div className="listsGenericWrapper__listsGeneric__footer">
                      <WidgetFooter
                        buttonOneFunc={_removeListsSubscriptions}
                        buttonOneText={tc.remove}
                        buttonTwoFunc={() => {
                          setActivePopup('');
                        }}
                        buttonTwoText={tc.cancel}
                      />
                    </div>
                  </div>
                </div>
              </Popup>
            ) : null}
            {activePopup === 'ShareListSubscriptions' ? (
              <ShareListSubscriptions
                close={() => {
                  setActivePopup('');
                  setSelectedSubscriptions([]);
                }}
                selectedSubscription={selectedSubscriptions[0]}
              />
            ) : null}
            {activePopup === 'editListSubscription' && (
              <EditListSubscription
                close={() => {
                  setActivePopup('');
                  setSelectedSubscriptions([]);
                }}
                selectedSubscription={selectedSubscriptions[0]}
              />
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

const MapStateToProps = (state) => {
  return {
    lists: state.lists,
    user: state.user,
  };
};

export default connect(MapStateToProps)(ListsSubscriptions);
