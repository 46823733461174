import React, { useContext, useEffect, useState } from 'react';
import LanguageContext from 'language-context';
import { activityHelper } from 'helpers';
import DatePicker from 'react-datepicker';
import ActionButton from 'components/action_button';
import Popup from 'components/popup';
import WidgetFooter from 'components/widget_footer';
import WidgetHeader from 'components/widget_header';
import { connect } from 'react-redux';
import {
  getAgileActivityTypes,
  updateAgileActivityTypes,
} from 'store/agile/tasks';
import Loading from 'components/loading';
import moment from 'moment';
import ColleaguesDropdown from 'components/colleagues_dropdown/colleagues_dropdown';
import { showFlashMessage } from 'store/flash_messages/tasks';

/**
 * Render add activity component.
 *
 * @param props.close - func
 * @param props.addActivity - func
 * @param props.moveItem- func
 * @param props.isMoving - bool - When we're in the process of moving an item. Adds a 'skip' activity that lets you move it without functionally adding an activity.
 * @param props.allowPlanned - bool - only deals can have planned activities (events). Block/hide if not allowed.
 */
const AgileBoardAddActivity = (props) => {
  const [action, setAction] = useState(null);
  const [activityIsPerformed, setActivityIsPerformed] = useState(true);
  const [comment, setComment] = useState('');
  const [date, setDate] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const [typesLoaded, setTypesLoaded] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [targetShareUser, setTargetShareUser] = useState(null);
  const tc = useContext(LanguageContext);
  const actionsThatCannotBePlanned =
    activityHelper.getActionsThatCannotBePlanned();

  useEffect(() => {
    async function getTypes() {
      const data = await getAgileActivityTypes();
      if (activityHelper.dataIsDefault(data)) {
        await updateAgileActivityTypes({
          activityTypes: activityHelper.getDefaultActionTypes(),
        });
      }
      setTypesLoaded(true);
    }
    getTypes();
  }, []);

  useEffect(() => {
    if (activityIsPerformed !== null && action === 'skip') setIsValid(true);
    else setIsValid(!!(activityIsPerformed !== null && date && action));
  }, [activityIsPerformed, action, date]);

  useEffect(() => {
    if (activityIsPerformed !== null && date === null && action !== null)
      setDate(new Date());
  }, [activityIsPerformed, action, date]);

  useEffect(() => {
    if (activityIsPerformed) {
      if (!date) return;

      const today = moment().format('YYYYMMDD');
      const dateSet = moment(date).format('YYYYMMDD');

      if (dateSet > today) {
        setDateError(true);
        return;
      } else {
        setDateError(false);
        return;
      }
    }

    setDateError(false);
  }, [activityIsPerformed, date]);

  return (
    <Popup close={props.close} size="big">
      <div className="agileBoardAddActivityWrapper">
        <div className="agileBoardAddActivityWrapper__agileBoardAddActivity">
          <div className="agileBoardAddActivityWrapper__agileBoardAddActivity__header">
            <WidgetHeader headline={tc.addActivity} />
          </div>
          <div className="agileBoardAddActivityWrapper__agileBoardAddActivity__content">
            <div className="agileBoardAddActivityWrapper__agileBoardAddActivity__content__isPerformed">
              <h4>{tc.occurrence}</h4>
              <div className="agileBoardAddActivityWrapper__agileBoardAddActivity__content__isPerformed__items">
                <ActionButton
                  active={activityIsPerformed === true}
                  icon="check"
                  label={tc.performedActivity}
                  onClick={() => {
                    if (activityIsPerformed) return;
                    setActivityIsPerformed(true);
                  }}
                  type="regular"
                />
                {props.allowPlanned ? (
                  <ActionButton
                    active={activityIsPerformed === false}
                    icon="time"
                    label={tc.plannedActivity}
                    onClick={() => {
                      setActivityIsPerformed(false);

                      if (action === 'lift') {
                        setAction(null);
                      }
                    }}
                    type="regular"
                  />
                ) : null}
              </div>
            </div>
            <div
              className={`agileBoardAddActivityWrapper__agileBoardAddActivity__content__actions ${
                activityIsPerformed === null ? 'disabled' : ''
              }`}
            >
              <h4>{tc.actionType}</h4>
              <div className="agileBoardAddActivityWrapper__agileBoardAddActivity__content__actions__items">
                {typesLoaded ? (
                  props.activeTypes?.length === 0 ? (
                    <p>No activity types are active, please select some.</p>
                  ) : (
                    activityHelper
                      .getActionTypes(true)
                      .filter((actionType) => {
                        if (activityIsPerformed) {
                          return true;
                        } else {
                          return !actionsThatCannotBePlanned.includes(
                            actionType
                          );
                        }
                      })
                      .map((type) => {
                        // only render buttons for active types, and only render skip activity if active & moving.
                        // if it's a planned activity, do not allow lift activity.
                        if (
                          type === 'skip' &&
                          (!props.isMoving || activityIsPerformed === false)
                        )
                          return null;
                        if (!props.activeTypes?.includes(type)) return null;
                        if (activityIsPerformed === false && type === 'lift')
                          return null;
                        return (
                          <ActionButton
                            active={action === type}
                            disabled={activityIsPerformed === null}
                            icon={type}
                            key={type}
                            label={activityHelper.getReadableActivity(
                              type,
                              true
                            )}
                            onClick={() => {
                              setAction(type);
                              if (
                                type === 'share' &&
                                props.activeItem?.meta?.sharedDealComment
                              ) {
                                // affärer sparar kommentaren från den som delat affären till dig.
                                // visas som default om du väljer "dela" som aktivitet för att inte gå miste om info
                                setComment(
                                  props.activeItem?.meta?.sharedDealComment
                                );
                              }
                            }}
                            type="regular"
                          />
                        );
                      })
                  )
                ) : (
                  <Loading />
                )}
              </div>
            </div>
            <div
              className={
                activityIsPerformed === null ||
                action === null ||
                action === 'skip'
                  ? 'agileBoardAddActivityWrapper__agileBoardAddActivity__content__date disabled'
                  : date === null
                  ? 'agileBoardAddActivityWrapper__agileBoardAddActivity__content__date'
                  : 'agileBoardAddActivityWrapper__agileBoardAddActivity__content__date active'
              }
            >
              <h4>{tc.dateAndTime}</h4>
              <h4>{dateError && ' - Ange ett giltligt datum'}</h4>
              <DatePicker
                dateFormat="Pp"
                locale="sv"
                maxDate={activityIsPerformed ? new Date() : null}
                minDate={!activityIsPerformed ? new Date() : null}
                onChange={(val) => {
                  setDate(val);
                }}
                selected={date}
                showTimeSelect
                timeCaption={tc.time}
                timeIntervals={15}
                showPopperArrow={false}
              />
            </div>
            <div
              className={
                activityIsPerformed === null ||
                date === null ||
                action === null ||
                action === 'skip'
                  ? 'agileBoardAddActivityWrapper__agileBoardAddActivity__content__comment disabled'
                  : comment.length
                  ? 'agileBoardAddActivityWrapper__agileBoardAddActivity__content__comment active'
                  : 'agileBoardAddActivityWrapper__agileBoardAddActivity__content__comment'
              }
            >
              <h4>{tc.comment}</h4>
              <textarea
                value={comment}
                onChange={(e) => {
                  setComment(e.target.value);
                }}
              />
            </div>
            {action === 'share' ? (
              <div>
                <p>{tc.chooseUserToShareTo}</p>
                <ColleaguesDropdown
                  activeId={targetShareUser?.userId || props.user.info.id}
                  activeName={targetShareUser?.name || props.user.info.name}
                  transparent={false}
                  onClick={(id, name, dealerId) => {
                    setTargetShareUser({ userId: id, name, dealerId });
                  }}
                />
              </div>
            ) : null}
          </div>
          <div className="agileBoardAddActivityWrapper__agileBoardAddActivity__footer">
            <WidgetFooter
              disableButtonOne={!isValid || dateError}
              buttonOneFunc={
                props.isMoving
                  ? () => {
                      if (action === 'share') {
                        if (
                          !targetShareUser ||
                          targetShareUser.userId === props.user.info.id
                        ) {
                          return showFlashMessage(
                            tc.cannotShareDealToYourself,
                            'info'
                          );
                        }
                        props.shareDeal({
                          action,
                          comment,
                          event_date: date,
                          targetShareUser,
                        });
                      } else if (action === 'skip') {
                        props.moveItem({
                          addActivity: false,
                        });
                      } else
                        props.moveItem({
                          addActivity: true,
                          addActivityObject: {
                            action: action,
                            comment: comment,
                            event_date: date,
                            performed: activityIsPerformed,
                          },
                        });
                    }
                  : () => {
                      if (action === 'share') {
                        if (
                          !targetShareUser ||
                          targetShareUser.userId === props.user.info.id
                        ) {
                          return showFlashMessage(
                            tc.cannotShareDealToYourself,
                            'info'
                          );
                        }
                        props.shareDeal({
                          action,
                          comment,
                          event_date: date,
                          targetShareUser,
                        });
                      } else {
                        props.addActivity({
                          action: action,
                          comment: comment,
                          event_date: date,
                          performed: activityIsPerformed,
                        });
                      }
                    }
              }
              buttonOneText={action === 'skip' ? tc.skip : tc.save}
              buttonTwoFunc={props.close}
              buttonTwoText={tc.cancel}
              noBorder={true}
            />
          </div>
        </div>
      </div>
    </Popup>
  );
};

const mapStateToProps = (state, props) => {
  return {
    activeTypes: state.agile?.activityTypes?.active,
    user: state.user,
    props: { ...props },
  };
};

export default connect(mapStateToProps)(AgileBoardAddActivity);
