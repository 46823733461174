import { getPreviewData } from 'store/agile/tasks';
import { toggleConsent } from 'store/person/tasks';
import personHelper from 'shared_helpers/person_helper';
import companyHelper from 'shared_helpers/company_helper';
import languageContext from 'language-context';
import React, { useContext } from 'react';
import Icon from 'components/icon';
import Tooltip from 'components/tooltip';

const GeneralInfo = ({ previewData = {}, prospect = {}, id }) => {
  const tc = useContext(languageContext);
  return (
    <>
      {previewData.item.listName ? (
        <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow">
          <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__left">
            {tc.belongsToList}:
          </div>
          <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__right">
            {previewData.item.listName}
          </div>
        </div>
      ) : null}
      {prospect.type === 'person' ? (
        <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow">
          <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__left">
            {tc.age}:
          </div>
          <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__right">
            {personHelper.getAgeString(prospect.birthYear)}
          </div>
        </div>
      ) : null}
      {companyHelper.isValidOrgNr(prospect.user_id) ? (
        <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow">
          <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__left">
            {tc.orgNumberShort}:
          </div>
          <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__right capitalize">
            {prospect.user_id}
          </div>
        </div>
      ) : null}
      {prospect.address && prospect.zip && prospect.zipMuncipality ? (
        <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow">
          <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__left">
            {tc.address}:
          </div>
          <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__right capitalize">
            {`${prospect.address.toLowerCase()}, ${
              prospect.zip
            },  ${prospect.zipMuncipality.toLowerCase()}`}
          </div>
        </div>
      ) : null}
      {prospect.phoneNumbers &&
      Array.isArray(prospect.phoneNumbers) &&
      prospect.phoneNumbers.length ? (
        <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow">
          <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__left">
            {tc.phoneNumber}:
          </div>
          <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__right">
            {prospect.phoneNumbers.map((num, i) => {
              const phoneNumbers = num.value.split(',');
              return (
                <React.Fragment key={num.value + i}>
                  {phoneNumbers.map((phoneNumber, index) => (
                    <React.Fragment key={index}>
                      <a href={'tel:+46' + phoneNumber.trim()}>
                        {phoneNumber.trim()}
                      </a>
                      {index < phoneNumbers.length - 1 && ', '}
                    </React.Fragment>
                  ))}
                  {i < prospect.phoneNumbers.length - 1 && ', '}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      ) : null}

      {prospect.emails &&
      Array.isArray(prospect.emails) &&
      prospect.emails.length ? (
        <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow">
          <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__left">
            {tc.email}:
          </div>
          <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__right">
            {prospect.emails.map((num, i) => {
              if (i === prospect.emails.length - 1) {
                return <span key={num.value}>{num.value}</span>;
              } else {
                return <span key={num.value}>{num.value}, </span>;
              }
            })}
          </div>
        </div>
      ) : null}
      {prospect.type === 'person' ? (
        <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow">
          <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__left">
            {tc.gdprConsent}:
          </div>
          <div className="agileBoardPopupWrapper__agilePopup__content__block__content__infoRow__right">
            <Tooltip tooltipContent={tc.gdprConsentInfo}>
              <Icon
                onClick={async () => {
                  await toggleConsent({ id: prospect.user_id });
                  await getPreviewData({
                    id: id,
                    updateProspectInformation: true,
                    getSettings: true,
                  });
                }}
                val={prospect.consent ? 'check' : 'checkbox'}
              />
            </Tooltip>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default GeneralInfo;
