import Icon from "components/icon";
import { activityHelper } from "helpers";

const ActivityGroup = ({ total, amount, type, onClick, goal }) => {
  const percentage =
    Number.isFinite(goal) && amount > 0 ? (amount / goal) * 100 : 0;
  return (
    <div
      className={`activityTypesGroupsWrapper__activityTypesGroups__content__groups__group`}
      key={type}
      onClick={() => {
        onClick();
      }}
    >
      <div className="activityTypesGroupsWrapper__activityTypesGroups__content__groups__group__icon">
        <Icon val={type} />
      </div>
      <div className="activityTypesGroupsWrapper__activityTypesGroups__content__groups__group__activity">
        <div className="activityTypesGroupsWrapper__activityTypesGroups__content__groups__group__activity__label">
          {activityHelper.getReadableActivity(type, false)}
        </div>
        <div className="activityTypesGroupsWrapper__activityTypesGroups__content__groups__group__activity__value">
          {goal ? `${amount}/${goal}` : `${amount}`}
        </div>
      </div>
      <div className="activityTypesGroupsWrapper__activityTypesGroups__content__groups__group__barHolder">
        <div
          className="bar"
          style={{
            width: Number.isFinite(percentage) && percentage > 0 ? percentage.toString() + "%" : "0%",
          }}
        />
      </div>
    </div>
  );
};

export default ActivityGroup;
