import { createStore, combineReducers } from 'redux';
import { activitiesReducer } from 'store/activities/reducer';
import { activityReducer } from 'store/activity/reducer';
import { agileReducer } from 'store/agile/reducer';
import { analyseReducer } from 'store/analyse/reducer';
import { carReducer } from 'store/car/reducer';
import { commentReducer } from 'store/comment/reducer';
import { companyReducer } from 'store/company/reducer';
import { contactsReducer } from 'store/contacts/reducer';
import { dealReducer } from 'store/deal/reducer';
import { eventsReducer } from 'store/events/reducer';
import { excelReducer } from 'store/excel/reducer';
import { fleetReducer } from 'store/fleet/reducer';
import { flashMessagesReducer } from 'store/flash_messages/reducer';
import { leadsReducer } from 'store/leads/reducer';
import { listReducer } from 'store/list/reducer';
import { listsReducer } from 'store/lists/reducer';
import { newsReducer } from 'store/news/reducer';
import { ordersReducer } from 'store/orders/reducer';
import { pdfReducer } from 'store/pdf/reducer';
import { personReducer } from 'store/person/reducer';
import { previewReducer } from 'store/preview/reducer';
import { prospectReducer } from 'store/prospect/reducer';
import { searchReducer } from 'store/search/reducer';
import { searchResultsReducer } from 'store/searchResults/reducer';
import { supertempReducer } from 'store/supertemp/reducer';
import { widgetsReducer } from 'store/widgets/reducer';
import { userReducer } from 'store/user/reducer';
import { koncernReducer } from 'store/koncern/reducer';
import { scriptsReducer } from './scripts/reducer';
import { tableReducer } from './table/reducer';
import { pbiReducer } from './pbi/reducer';

// All reducers combined.
const reducer = combineReducers({
  activities: activitiesReducer,
  activity: activityReducer,
  agile: agileReducer,
  analyse: analyseReducer,
  car: carReducer,
  comment: commentReducer,
  company: companyReducer,
  contacts: contactsReducer,
  deal: dealReducer,
  events: eventsReducer,
  excel: excelReducer,
  fleet: fleetReducer,
  flashMessages: flashMessagesReducer,
  leads: leadsReducer,
  list: listReducer,
  lists: listsReducer,
  news: newsReducer,
  orders: ordersReducer,
  pdf: pdfReducer,
  person: personReducer,
  preview: previewReducer,
  prospect: prospectReducer,
  scripts: scriptsReducer,
  search: searchReducer,
  searchResults: searchResultsReducer,
  supertemp: supertempReducer,
  table: tableReducer,
  widgets: widgetsReducer,
  user: userReducer,
  koncern: koncernReducer,
  pbi: pbiReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_STATE') {
    state = undefined;
  }

  return reducer(state, action);
};

// Creating redux store.
const localDevelopment = process?.env?.NODE_ENV === 'development';

const disableLogging = process?.env?.REACT_APP_LOGGING === 'disable';

const reduxDevtoolsConfig = localDevelopment
  ? { trace: true, traceLimit: 25 }
  : {};

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ &&
    localDevelopment &&
    window.__REDUX_DEVTOOLS_EXTENSION__(reduxDevtoolsConfig)
);

export { store };
