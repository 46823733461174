import React, { useContext } from "react";
import { connect } from "react-redux";
import LanguageContext from "language-context";
import { setActiveOrderStage, setListToOrder } from "store/orders/tasks";
import { tableHelper } from "helpers";
import { Table } from "components/table";
import Info from "components/info";
import WidgetHeader from "components/widget_header";

const OrdersSelectList = (state) => {
  const tc = useContext(LanguageContext);

  return (
    <div className="ordersWrapper__orders__content">
      {state.lists.lists.length ? (
        <>
          <div className="ordersWrapper__orders__content__top">
            <div className="ordersWrapper__orders__content__top__left">
              <div className="ordersWrapper__orders__content__top__section">
                <div className="ordersWrapper__orders__content__top__section__header">
                  <WidgetHeader
                    headline={`${tc.select} ${tc.list.toLowerCase()}`}
                  />
                </div>
                <p>{tc.orderSelectListInfo}</p>
              </div>
            </div>
          </div>
          <div className="ordersWrapper__orders__content__bottom">
            <Table
              adjustToContainer={true}
              columns={tableHelper.getListsOrdersColumns()}
              // onSelect={(arr) => {
              //   if (Array.isArray(arr)) {
              //     const newId = arr[arr.length - 1];
              //     const list = state.lists.lists.find(
              //       (num) => num._id === newId
              //     );
              //     if (list) {
              //       setListToOrder(list);
              //       setActiveOrderStage("listOrder");
              //     }
              //   }
              // }}
              rows={tableHelper.getListsOrdersRows(state.lists.lists)}
              // selected={
              //   state.orders?.list?._id ? [state.orders?.list?._id] : []
              // }
            />
          </div>
        </>
      ) : (
        <div className="ordersWrapper__orders__content__top">
          <div className="ordersWrapper__orders__content__top__left">
            <Info>
              <h4>{tc.noListsToOrder}</h4>
              <p>{tc.noListsToOrderWhy}</p>
            </Info>
          </div>
        </div>
      )}
    </div>
  );
};

const MapStateToProps = (state, props) => {
  return {
    lists: state.lists,
    orders: state.orders,
    props: props,
  };
};

export default connect(MapStateToProps)(OrdersSelectList);
