import Icon from "components/icon";
import Tooltip from "components/tooltip";
import { prospectHelper } from "helpers";
import LanguageContext from "language-context";
import { useContext } from "react";
const ProspectSearchDateTooltip = ({ options, object }) => {
  const tc = useContext(LanguageContext);
  return (
    <Tooltip
      tooltipContent={
        object.from === null && object.to === null ? (
          <p>{tc.prospectSearchDateTooltip}</p>
        ) : (
          <div>
            <p>{tc.prospectSearchDateTooltipSelected}</p>
            {options?.map((o) => {
              const date = prospectHelper.convertDate(object, o);
              return (
                <p key={o}>
                  {tc[o]}: {date.from + " - " + date.to}
                </p>
              );
            })}
          </div>
        )
      }
    >
      <div style={{ cursor: "help", display: "flex", alignItems: "center" }}>
        <Icon val="other" />
      </div>
    </Tooltip>
  );
};

export default ProspectSearchDateTooltip;
