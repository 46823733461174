import useNotification from "./hooks/useNotification";
/**
 * Mounting point for the notification system.
 * The notifications and visual components get
 * generated by the useNotification hook.
 * @returns Content
 */
const Notification = () => {
  const { Content } = useNotification();

  return (
    <>
      <Content />
    </>
  );
};

export default Notification;
