import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";

/**
 * Loading animation.
 *
 * @param props.color - 'white' (optional) - When used where background is same as default color, only support for white right now.
 * @param props.inline - bool
 * @param props.small - bool
 */
const Loading = (props) => {
  if (props.small) {
    if (props.inline) {
      return (
        <span
          className={`loadingWrapperSmall ${props.color ? props.color : ""}`}
        >
          <LinearProgress />
        </span>
      );
    } else {
      return (
        <div
          className={`loadingWrapperSmall ${props.color ? props.color : ""}`}
        >
          <LinearProgress />
        </div>
      );
    }
  } else {
    return (
      <div className={`loadingWrapper ${props.color ? props.color : ""}`}>
        <CircularProgress />
      </div>
    );
  }
};

export default Loading;
