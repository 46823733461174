import React, { useContext } from 'react';
import LanguageContext from 'language-context';
import { connect } from 'react-redux';
import Comment from 'components/comment';
import { Dropdown, DropdownItem } from 'components/dropdown';

const ActivityContentTop = ({
  setCommentEditId,
  setCommentRemoveId,
  activityTypesToDisplay,
  setActivityTypesToDisplay,
  hideCommentInput,
  hideSelectActivityTypes,
  view,
  target,
  targetType,
  widget,
  updateWidgets,
  comment,
  widgets,
}) => {
  const tc = useContext(LanguageContext);
  return (
    <div
      className={
        hideCommentInput && (hideSelectActivityTypes || view === 'embedded')
          ? 'activitiesWrapper__activities__content__top noMarginBottom'
          : 'activitiesWrapper__activities__content__top'
      }
    >
      <div
        className={
          view === 'embedded'
            ? 'activitiesWrapper__activities__content__top__left embedded'
            : 'activitiesWrapper__activities__content__top__left'
        }
      >
        {!hideCommentInput && target ? (
          <Comment target={target} targetType={targetType} type="new" />
        ) : null}
        {!comment.removeId && comment.editId ? (
          <Comment
            close={() => {
              setCommentEditId(null);
            }}
            type="edit"
          />
        ) : null}
        {comment.removeId ? (
          <Comment
            close={() => {
              setCommentRemoveId(null);
            }}
            type="remove"
          />
        ) : null}
      </div>
      <div className="activitiesWrapper__activities__content__top__right">
        {!hideSelectActivityTypes && view !== 'embedded' ? (
          <div className="activitiesWrapper__activities__content__top__right__selectActivities">
            <Dropdown
              displayValue={`${
                tc.show
              } / ${tc.hide.toLowerCase()} ${tc.activities.toLowerCase()}`}
              transparent={true}
              styling={{ adjustToHeader: true }}
            >
              {activityTypesToDisplay.map((num) => {
                return (
                  <DropdownItem
                    active={num.active}
                    key={num.val}
                    label={num.label}
                    onClick={() => {
                      const newActivityTypesToDisplay =
                        activityTypesToDisplay.map((x) => {
                          if (x.val === num.val) {
                            x.active = !x.active;
                          }

                          return x;
                        });

                      setActivityTypesToDisplay(newActivityTypesToDisplay);

                      if (
                        Array.isArray(widgets?.data?.widgets) &&
                        widget?._id
                      ) {
                        // Save widget state to db.
                        // Only save text values for active types in widget activityTypesToDisplay array, not objects.
                        updateWidgets({
                          widgets: widgets.data.widgets.map((widgetItem) => {
                            if (
                              widget?._id === widgetItem._id &&
                              widgetItem?.dashboards?.dashboard
                            ) {
                              widgetItem.dashboards.dashboard.activityTypesToDisplay =
                                newActivityTypesToDisplay
                                  .filter((type) => type.active)
                                  .map((type) => type.val);
                            }
                            return widgetItem;
                          }),
                        });
                      }
                    }}
                  />
                );
              })}
            </Dropdown>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const MapStateToProps = (state, props) => {
  return {
    comment: state.comment,
    widgets: state.widgets,
    target: props.target,
  };
};

export default connect(MapStateToProps)(ActivityContentTop);
