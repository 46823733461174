import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import LanguageContext from "language-context";
import { getPresets, removePreset, sharePreset } from "store/prospect/tasks";
import ActionButton from "components/action_button";
import ColleaguesRows from "components/colleagues_rows";
import Info from "components/info";
import ItemWithButtons from "components/item_with_buttons";
import Loading from "components/loading";
import WidgetFooter from "components/widget_footer";

/**
 * Handle prospect preselections such as groups and presets.
 */
const ProspectPreselections = (state) => {
  const [presetToRemove, setPresetToRemove] = useState(false);
  const [presetToShare, setPresetToShare] = useState(false);
  // const [shareWithUsers, setShareWithUsers] = useState([]);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    getPresets();
  }, []);

  const _renderPresetRow = (preset) => {
    return (
      <div
        className="prospectPresetsWrapper__prospectPresets__content__preset"
        key={preset._id}
      >
        <ItemWithButtons
          buttons={[
            // {
            //   icon: "share",
            //   onClick: () => {
            //     setPresetToShare(preset);
            //   },
            //   tooltip: tc.sharePreset,
            // },
            {
              icon: "remove",
              onClick: () => {
                setPresetToRemove(preset);
              },
              tooltip: tc.removePreset,
            },
          ]}
          icon="prospectPreset"
          name={preset.name}
          label={tc.prospectPreset}
        />
      </div>
    );
  };

  const _stateCheck = () => {
    return !!(state.prospect && state.prospect.presets);
  };

  return (
    <div className="prospectPresetsWrapper">
      <div className="prospectPresetsWrapper__prospectPresets">
        {_stateCheck() ? (
          <div className="prospectPresetsWrapper__prospectPresets__content">
            {state.prospect.presets.length ? (
              <div className="prospectPresetsWrapper__prospectPresets__content__row marginBottomMedium">
                <p>{tc.prospectPresetsInfo}</p>
              </div>
            ) : null}
            {state.prospect.presets.length ? (
              presetToShare ? null : //     {tc.sharePreset}: <strong>{presetToShare.name}</strong> //   <p> // <div>
              //   </p>
              //   <p className="marginBottomMedium">{tc.sharePresetInfo}</p>
              //   <ColleaguesRows
              //     onChange={(arr) => {
              //       setShareWithUsers(arr);
              //     }}
              //   />
              //   <WidgetFooter
              //     buttonOneText={tc.sharePreset}
              //     buttonOneFunc={() => {
              //       sharePreset({
              //         id: presetToShare._id,
              //         users: shareWithUsers.map((num) => num.id),
              //       });
              //       setPresetToShare(null);
              //     }}
              //     buttonTwoText={tc.cancel}
              //     buttonTwoFunc={() => {
              //       setPresetToShare(null);
              //     }}
              //   />
              // </div>
              presetToRemove ? (
                <div className="inputHolder noMargin">
                  <div className="inputHolder__left">
                    {tc.removePreset}: <strong>{presetToRemove.name}</strong>
                  </div>
                  <div className="inputHolder__right">
                    <ActionButton
                      label={tc.cancel}
                      onClick={() => {
                        setPresetToRemove(null);
                      }}
                      type="regular"
                    />
                    <ActionButton
                      label={tc.remove}
                      onClick={() => {
                        removePreset({
                          id: presetToRemove._id,
                        });
                        setPresetToRemove(null);
                      }}
                      type="highlight"
                    />
                  </div>
                </div>
              ) : (
                <>
                  {state.prospect.presets.map((preset) =>
                    _renderPresetRow(preset)
                  )}
                </>
              )
            ) : (
              <Info>
                <h4>{tc.noPresets}</h4>
                <p>{tc.noPresetsWhy}</p>
              </Info>
            )}
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

const MapStateToProps = (state) => {
  return {
    prospect: state.prospect,
  };
};

export default connect(MapStateToProps)(ProspectPreselections);
