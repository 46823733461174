import React, { useContext, useState } from "react";
import moment from "moment";
import LanguageContext from "language-context";
import DatePicker from "react-datepicker";
import Popup from "components/popup";
import WidgetHeader from "components/widget_header";

/**
 * Render date picker.
 * Either one date or a range.
 *
 * @param props
 */
const MenuDatepicker = (props) => {
  const {
    dateFrom, // Always provide.
    dateTo, // Only provide when selecting date range.
    maxDate,
    minDate,
    onChange,
    showTime,
    showYear,
  } = props;
  const [showDatepicker, setShowDatepicker] = useState(false);
  const tc = useContext(LanguageContext);

  return (
    <div className="menuDatepickerWrapper">
      <div
        className={
          props.disabled
            ? "menuDatepickerWrapper__menuDatepicker disabled"
            : props.active
            ? "menuDatepickerWrapper__menuDatepicker active"
            : "menuDatepickerWrapper__menuDatepicker"
        }
        onClick={() => {
          setShowDatepicker(true);
        }}
      >
        {`${tc.select} ${tc.date.toLowerCase()}`}
      </div>
      {showDatepicker ? (
        <Popup
          close={() => {
            setShowDatepicker(false);
          }}
          onlyContent={true}
        >
          <div className="menuDatepickerWrapper__menuDatepicker__selection">
            <div className="menuDatepickerWrapper__menuDatepicker__selection__header">
              <WidgetHeader
                headline={`${tc.select} ${tc.date.toLowerCase()}`}
              />
            </div>
            <div className="menuDatepickerWrapper__menuDatepicker__selection__content">
              {dateTo ? (
                <>
                  <div className="menuDatepickerWrapper__menuDatepicker__selection__content__datepicker">
                    <p>
                      <strong>{`${tc.date} ${tc.from.toLowerCase()}:`}</strong>
                      {showTime
                        ? moment(new Date(dateFrom)).format("LL HH:mm")
                        : moment(new Date(dateFrom)).format("LL")}
                    </p>
                    <DatePicker
                      dateFormat="Pp"
                      inline={true}
                      locale="sv"
                      selected={dateFrom}
                      maxDate={dateTo}
                      onChange={(val) => {
                        let newDate;
                        if (!showTime) {
                          newDate = moment(
                            new Date(val).setHours(0, 0, 1)
                          ).toDate();
                        } else {
                          newDate = val;
                        }

                        onChange({
                          from: newDate ? newDate : dateFrom,
                          to: dateTo,
                        });
                      }}
                      startDate={dateFrom}
                      showTimeSelect={!!showTime}
                      showPopperArrow={false}
                      showYearDropdown={!!showYear}
                      scrollableYearDropdown={!!showYear}
                      yearDropdownItemNumber={5}
                      timeCaption={showTime ? tc.time : ""}
                      timeIntervals={15}
                    />
                  </div>
                  <div className="menuDatepickerWrapper__menuDatepicker__selection__content__datepicker">
                    <p>
                      <strong>{`${tc.date} ${tc.to.toLowerCase()}:`}</strong>
                      {showTime
                        ? moment(new Date(dateTo)).format("LL HH:mm")
                        : moment(new Date(dateTo)).format("LL")}
                    </p>
                    <DatePicker
                      dateFormat="Pp"
                      inline={true}
                      locale="sv"
                      selected={dateTo}
                      minDate={dateFrom}
                      onChange={(val) => {
                        onChange({
                          from: dateFrom,
                          to: val ? val : dateTo,
                        });
                      }}
                      startDate={dateTo}
                      showTimeSelect={!!showTime}
                      showPopperArrow={false}
                      showYearDropdown={!!showYear}
                      scrollableYearDropdown={!!showYear}
                      yearDropdownItemNumber={5}
                      timeCaption={showTime ? tc.time : ""}
                      timeIntervals={15}
                    />
                  </div>
                </>
              ) : (
                <div className="menuDatepickerWrapper__menuDatepicker__selection__content__datepicker">
                  <p>
                    <strong>{`${
                      tc.date
                    } ${tc.selected.toLowerCase()}:`}</strong>
                    {showTime
                      ? moment(new Date(dateFrom)).format("LL HH:mm")
                      : moment(new Date(dateFrom)).format("LL")}
                  </p>
                  <DatePicker
                    dateFormat="Pp"
                    inline={true}
                    locale="sv"
                    selected={dateFrom}
                    onChange={(val) => {
                      onChange({
                        from: val ? val : dateFrom,
                      });
                    }}
                    maxDate={maxDate ? maxDate : null}
                    minDate={minDate ? minDate : null}
                    showTimeSelect={!!showTime}
                    showPopperArrow={false}
                    showYearDropdown={!!showYear}
                    scrollableYearDropdown={!!showYear}
                    yearDropdownItemNumber={5}
                    timeCaption={showTime ? tc.time : ""}
                    timeIntervals={15}
                  />
                </div>
              )}
            </div>
          </div>
        </Popup>
      ) : null}
    </div>
  );
};

export default MenuDatepicker;
