import React, { useContext, useState } from 'react';
import FoldableSection from './foldable_section';
import { DropdownItem, NewDropdown } from 'components/dropdown';
import ContactItem from './agile_board_popup_contact_item';
import languageContext from 'language-context';

const AgileBoardPopupContacts = ({
  previewData,
  toggleSection = () => {},
  active,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const tc = useContext(languageContext);

  if (!previewData?.contacts?.length) {
    return null;
  }

  return (
    <FoldableSection
      toggleSection={toggleSection}
      active={active}
      label={tc.contacts}
    >
      <div className="agileBoardPopupWrapper__agilePopup__content__block__content">
        <div>
          {previewData.contacts.length > 1 ? (
            <NewDropdown header={{ label: tc.selectContact }}>
              {previewData.contacts.map((contact, i) => {
                return (
                  <DropdownItem
                    key={contact._id}
                    active={activeIndex === i}
                    label={contact.name || tc.nameMissing}
                    onClick={() => setActiveIndex(i)}
                  />
                );
              })}
            </NewDropdown>
          ) : null}
        </div>
        <ContactItem contact={previewData.contacts[activeIndex]} />
      </div>
    </FoldableSection>
  );
};

export default AgileBoardPopupContacts;
