import React, { useContext, useEffect, useState } from "react";
import LanguageContext from "language-context";
import Board from "react-trello";
import Info from "components/info";
import WidgetKanbanBoardItem from "./widget_kanban_board_item";

/**
 * Render a board with draggable widgets.
 *
 * @params props.dashboard - string - Type of dashboard ("dashboard" | "analyse").
 * @params props.handleDragEnd - func
 * @params props.widgets - array - The widgets
 */
const WidgetKanbanBoard = (props) => {
  const { handleDragEnd, widgets } = props;
  const tc = useContext(LanguageContext);

  /**
   * Since we don't save lane/column property on widgets we need to calculate new positions.
   * This is the flow for widgets in the board:
   *      0 1   -->
   * -->  2 3   -->
   * -->  4 5   -->
   * -->  6 7   -->
   * -->  8 9   -->
   * -->  10 11 -->
   * -->  12 13 -->
   *
   * Which means actual positions for lane 1 (left):
   * For lanePosition 0 widget position property should be: 0
   * For lanePosition 1 widget position property should be: 2
   * For lanePosition 2 widget position property should be: 4
   * For lanePosition 3 widget position property should be: 6
   * For lanePosition 4 widget position property should be: 8
   * For lanePosition 5 widget position property should be: 10
   * etc.
   *
   * And positions for lane 2 (right):
   * For lanePosition 0 widget position property should be: 1
   * For lanePosition 1 widget position property should be: 3
   * For lanePosition 2 widget position property should be: 5
   * For lanePosition 3 widget position property should be: 7
   * For lanePosition 4 widget position property should be: 9
   * For lanePosition 5 widget position property should be: 11
   * etc.
   */
  const _handleDragEnd = (cardId, sourceLaneId, targetLaneId, lanePosition) => {
    let dontUpdate = false;

    const originalPosition = widgets.find((num) => num._id === cardId)
      .dashboards[props.dashboard].position;

    const newPosition =
      targetLaneId.toString() === "2"
        ? lanePosition === 0
          ? 1
          : lanePosition * 2 + 1
        : lanePosition === 0
        ? 0
        : lanePosition * 2;

    // Update positions.
    let newWidgets = widgets.map((widget) => {
      if (widget._id === cardId) {
        // This is the card that was drag and dropped.
        if (sourceLaneId === targetLaneId && originalPosition === newPosition) {
          // No change.
          dontUpdate = true;
        } else {
          // New position.
          widget.dashboards[props.dashboard].position = newPosition;
        }
      } else {
        if (
          ((widget.dashboards[props.dashboard].position % 2 === 0 &&
            sourceLaneId.toString() === "1") ||
            (widget.dashboards[props.dashboard].position % 2 !== 0 &&
              sourceLaneId.toString() === "2")) &&
          widget.dashboards[props.dashboard].position > originalPosition
        ) {
          // Widget inside source lane, positioned below the widget that was dragged, so move it up (decrease position by 2).
          const newPos = widget.dashboards[props.dashboard].position - 2;
          widget.dashboards[props.dashboard].position = newPos > 0 ? newPos : 0;
        }
        if (
          ((widget.dashboards[props.dashboard].position % 2 === 0 &&
            targetLaneId.toString() === "1") ||
            (widget.dashboards[props.dashboard].position % 2 !== 0 &&
              targetLaneId.toString() === "2")) &&
          widget.dashboards[props.dashboard].position >= newPosition
        ) {
          // Widget inside target lane, positioned below the widget that was dropped, so move it down (increase position by 2).
          widget.dashboards[props.dashboard].position =
            widget.dashboards[props.dashboard].position + 2;
        }
      }

      return widget;
    });

    if (dontUpdate) {
      return false;
    } else {
      return handleDragEnd(newWidgets);
    }
  };

  return (
    <div className="widgetKanbanBoardWrapper">
      <div className="widgetKanbanBoardWrapper__widgetKanbanBoard">
        <div className="widgetKanbanBoardWrapper__widgetKanbanBoard__content">
          {widgets?.length ? (
            <Board
              components={{
                Card: WidgetKanbanBoardItem,
              }}
              data={{
                lanes: [
                  {
                    cards: widgets
                      .filter(
                        (widget) =>
                          widget.dashboards[props.dashboard].position % 2 === 0
                      )
                      .map((widget) => {
                        return {
                          ...widget,
                          id: widget._id,
                          title: widget.name,
                          draggable: !((widget.type === 'analyseCar' || widget.type === 'analyseSales') && widget.data.defaultChartType === 'map')
                        };
                      }),
                    id: "1",
                    title: "",
                  },
                  {
                    cards: widgets
                      .filter(
                        (widget) =>
                          widget.dashboards[props.dashboard].position % 2 !== 0
                      )
                      .map((widget) => {
                        return {
                          ...widget,
                          id: widget._id,
                          title: widget.name,
                          draggable: !((widget.type === 'analyseCar' || widget.type === 'analyseSales') && widget.data.defaultChartType === 'map')
                        };
                      }),
                    id: "2",
                    title: "",
                  },
                ],
              }}
              handleDragEnd={_handleDragEnd}
            />
          ) : (
            <div className="widgetKanbanBoardWrapper__widgetKanbanBoard__content__info">
              <Info>
                <h4>{tc.noWidgets}</h4>
                <p>{tc.noWidgetsInfo}</p>
              </Info>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WidgetKanbanBoard;
