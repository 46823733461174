import React, { useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import LanguageContext from 'language-context';
import confetti from 'canvas-confetti';
import { miscHelper } from 'helpers';
import {
  getAllSuggestions,
  redirectSearch,
  resetSearch,
} from 'store/search/tasks';
import Icon from 'components/icon';
import IconStyled from 'components/icon_styled';

const SearchMain = (state) => {
  const [showSearch, setShowSearch] = useState(false);
  const searchMainInputRef = useRef(null);
  const searchMainWrapperRef = useRef(null);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    if (showSearch) {
      searchMainInputRef &&
        searchMainInputRef.current &&
        searchMainInputRef.current.focus();
      resetSearch();
    }
  }, [showSearch]);

  useEffect(() => {
    resetSearch();

    /**
     * When clicking outside searchWrapper, close it.
     */
    const _handleMouseDown = (e) => {
      if (miscHelper.clickUnmount(e, searchMainWrapperRef, false)) {
        _closeSearch();
      }
    };

    /**
     * Handle key press.
     */
    const _handleKeyDown = async (e) => {
      if (e.keyCode === 27) {
        _closeSearch();
      } else if (e.keyCode === 13) {
        return await _redirectSearch();
      }
    };

    window.addEventListener('mousedown', _handleMouseDown, { capture: true });
    window.addEventListener('keydown', _handleKeyDown, { capture: true });
    return () => {
      window.removeEventListener('mousedown', _handleMouseDown, {
        capture: true,
      });
      window.removeEventListener('keydown', _handleKeyDown, { capture: true });
    };
  }, []);

  const _closeSearch = () => {
    setShowSearch(false);
    if (searchMainInputRef?.current) {
      searchMainInputRef.current.value = '';
      const input = document.querySelector('#searchMainInput');
      if (input) {
        input.blur();
      }
    }
  };

  /**
   * Handle input change.
   */
  const _handleInput = async (e) => {
    if (e.target?.value?.length) {
      setShowSearch(true);
      if (e.target.value.toLowerCase() === 'robin dehn') {
        confetti();
      }
      await getAllSuggestions({ limit: 6, q: e.target.value });
    } else {
      // We don't have a loading flag for when we wait for search suggestions,
      // so use timeout to make sure our reset isn't directly followed by returned search suggestions.
      setTimeout(() => {
        resetSearch();
      }, 500);
    }
  };

  const _redirectSearch = async () => {
    if (searchMainInputRef?.current?.value?.length) {
      redirectSearch({ q: searchMainInputRef.current.value });
    }
    _closeSearch();
    resetSearch();
  };

  const _renderSearchContent = () => {
    return showSearch ? (
      <div className="searchMainWrapper__searchMain__content">
        {state.search?.searchSuggestions?.length
          ? _renderSuggestionRows()
          : null}
        {searchMainInputRef?.current?.value?.length ? (
          <div
            className="searchMainWrapper__searchMain__content__submitWrapper"
            onClick={_redirectSearch}
          >
            <div
              className="searchMainWrapper__searchMain__content__submitWrapper__submit"
              onClick={_redirectSearch}
            >
              {tc.doVehicleSearch}
              <Icon val="navigateNext" />
            </div>
          </div>
        ) : null}
      </div>
    ) : null;
  };

  /**
   * Return search result rows.
   */
  const _renderSuggestionRows = () => {
    return (
      <div className="searchMainWrapper__searchMain__content__searchResult">
        {state.search.searchSuggestions
          .filter((searchSuggestion) =>
            ['company', 'car', 'person'].includes(searchSuggestion.type)
          )
          .map((searchSuggestion) => {
            let iconVal = searchSuggestion.type;
            let to;

            switch (iconVal) {
              case 'company':
                to = '/foretag/' + searchSuggestion.id;
                break;
              case 'car':
                to = '/fordon/' + searchSuggestion.id;
                break;
              case 'person':
                to = '/person/' + searchSuggestion.id;
                break;
              default:
                break;
            }

            return (
              <div
                className="searchMainWrapper__searchMain__content__searchResult__item"
                key={Math.random().toString()}
              >
                <NavLink
                  exact
                  to={to}
                  key={searchSuggestion.id}
                  onClick={_closeSearch}
                >
                  <IconStyled iconVal={iconVal} class="link" />
                  <span>{searchSuggestion.name}</span>
                </NavLink>
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <div
      className={showSearch ? 'searchMainWrapper open' : 'searchMainWrapper'}
      ref={searchMainWrapperRef}
    >
      <div
        className={
          showSearch
            ? 'searchMainWrapper__searchMain open'
            : 'searchMainWrapper__searchMain'
        }
      >
        <div className="searchMainWrapper__searchMain__header">
          <div
            className="searchMainWrapper__searchMain__header__searchIcon"
            onClick={() => {
              setShowSearch(true);
            }}
          >
            <Icon val="search" />
          </div>
          <div
            className="searchMainWrapper__searchMain__header__searchForm"
            onClick={() => {
              setShowSearch(true);
            }}
          >
            <form autoComplete="off">
              <input
                id="searchMainInput"
                placeholder={showSearch ? tc.placeholderSearchAll : tc.search}
                onChange={_handleInput}
                ref={searchMainInputRef}
                type="search"
              />
            </form>
          </div>
        </div>
        {_renderSearchContent()}
      </div>
    </div>
  );
};

const MapStateToProps = (state, props) => {
  return {
    props: props,
    search: state.search,
  };
};

export default connect(MapStateToProps)(SearchMain);
