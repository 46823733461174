import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
// import LanguageContext from "language-context";
import { getLists } from "store/lists/tasks";
import Loading from "components/loading";
import OrdersOrder from "./orders_order";
import OrdersSelectList from "./orders_select_list";
import PageHeader from "components/page_header";
// import Popup from "components/popup";
// import WidgetFooter from "components/widget_footer";
// import WidgetHeader from "../widget_header";

const Orders = (state) => {
  // const [showInfo, setShowInfo] = useState(false);
  // const tc = useContext(LanguageContext);

  useEffect(() => {
    getLists({
      orderInformation: true,
    });
  }, []);

  return (
    <div className="ordersWrapper">
      <div className="ordersWrapper__orders">
        <div className="ordersWrapper__orders__header">
          <PageHeader />
        </div>
        {state.lists.lists && state.orders.activeOrderStage === "selectList" ? (
          <OrdersSelectList />
        ) : state.orders?.list &&
          state.orders.activeOrderStage === "listOrder" ? (
          <OrdersOrder />
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

const MapStateToProps = (state) => {
  return {
    lists: state.lists,
    orders: state.orders,
  };
};

export default connect(MapStateToProps)(Orders);
