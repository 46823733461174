import React, { useState } from "react";
import Icon from "components/icon";
import useCountUp from "./hooks/useCountUp";

/**
 * This component is used to display three things:
 * 1. The Icon corresponding the activity.
 * 2. The Amount of these activities.
 * 3. The name of the activity.
 *
 * It uses a custom hook to drive a countUp animation.
 * The countUp gets triggered by the css animation for the wrapper ending.
 * @param {string} type The activity(action) type parsed by textContent
 * @param {string} action The activity type none parsed
 * @param {number} amount The amount/number of activities the user has
 * @param {function} addToTotal Callback function to update the total amount of activities
 * @returns
 */
function SummaryItem({ type, action, amount, addToTotal }) {
  const [startCount, setStartCount] = useState(false);
  const { count, setStart } = useCountUp({
    endValue: amount,
    duration: 300,
    onComplete: () => addToTotal(amount),
  });

  return (
    <div
      className="summaryWrapper__item"
      onAnimationEnd={() => {
        setStartCount(true);
        setStart(true);
      }}
    >
      <div className="summaryWrapper__item__iconWrapper">
        <div className="summaryWrapper__item__iconWrapper__icon">
          <Icon val={action} />
        </div>
        <div className="summaryWrapper__item__iconWrapper__celebrate">
          {action === "dealsWon" ? <Icon val="star" /> : null}
        </div>
      </div>
      <p className="summaryWrapper__item__amount">{startCount ? count : 0}</p>
      <h4>{type}</h4>
    </div>
  );
}

export default React.memo(SummaryItem);
