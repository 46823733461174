import React, { useContext, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import carHelper from 'shared_helpers/car_helper';
import { NavLink } from 'react-router-dom';
import {
  setNavigationStatus,
  setSettingsActivePageOrSection,
  setShowSettings,
} from 'store/user/tasks';
import LanguageContext from 'language-context';
import history from 'router-history';
import Icon from 'components/icon';
import Search from 'components/search';
import Settings from 'components/settings';
import UserImage from 'components/user_image';
import { Dropdown } from '../dropdown';
import PageHeaderNotifications from './page_header_notifications';

const toggleChat = () => {
  // if open-chat-state is true, close it and hide the widget otherwise do the opposite
  let openChatState = localStorage.getItem(
    'helpcrunch.com-bilvision-1-open-chat-state'
  );
  if (openChatState === 'true') {
    window.HelpCrunch('closeChat');
    window.HelpCrunch('hideChatWidget');
  } else {
    window.HelpCrunch('openChat');
    localStorage.setItem('helpcrunch.com-bilvision-1-open-chat-state', 'true');
  }
};

/**
 * Render a page header.
 *
 * @param state.props.headline (optional)
 * @param state.props.headlineOnclick (optional)
 * @param state.props.headlineSub (optional)
 */
const PageHeader = (state) => {
  const [breadcrumbs, setBreadcrumbs] = useState(null);
  const [headline, setHeadline] = useState('');
  const [notificationsCleared, setNotificationsCleared] = useState(false);
  const [newNews, setNewNews] = useState(false);
  const [unreadNewsCount, setUnreadNewsCount] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const tc = useContext(LanguageContext);

  // All sub paths in routes need to exist here to be displayed correctly.
  // (Note that we might not use this component on each of these pages, but make it work for all pages in case we want to.)
  const routes = {
    affar: tc.deal,
    aktivitet: tc.activity,
    aktiva: tc.active,
    alla: tc.all,
    analysera: tc.analyse,
    arkiverade: tc.archived,
    aterstall: tc.resetPassword,
    avtal: 'Avtal',
    bearbeta: tc.agile,
    bestalla: tc.toOrder,
    bevakning: tc.supertempMonitoring,
    bil: tc.car,
    bilprospekt: tc.bilprospekt,
    borttagna: tc.removed,
    dashboard: tc.dashboard,
    foretag: tc.company,
    forlorade: tc.lostPlural,
    forval: tc.preselections,
    grid: 'Grid demo',
    informationssakerhet: tc.informationSecurity,
    inloggning: tc.login,
    koncern: tc.koncern,
    leads: tc.leads,
    lista: tc.list,
    listor: tc.lists,
    nyheter: tc.news,
    person: tc.person,
    prenumerationer: tc.subscriptions,
    priser: tc.prices,
    prospektera: tc.prospectSearch,
    resultat: tc.result,
    sok: tc.aSearch,
    vagnpark: tc.fleet,
    vunna: tc.wonPlural,
    instrumentpanel: tc.dashboard,
  };

  useEffect(() => {
    _generateBreadcrumbsAndHeadline();
  }, [state.props.headline]);

  useEffect(() => {
    return history.listen(() => {
      _generateBreadcrumbsAndHeadline();
    });
  }, []);

  const handleNewsUpdate = (hasNews) => {
    setNewNews(hasNews);
  };
  const handleNotificationsCleared = (notificationsCleared) => {
    setNotificationsCleared(notificationsCleared);
  };
  const updateUnreadNewsCount = (count) => {
    setUnreadNewsCount(count);
  };
  const handleDropdownClose = () => {
    setDropdownOpen((prevOpen) => !prevOpen);
  };

  /* Width size handling for dropdown notification dropdown for responsive/smaller screens */
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 767);
  useEffect(() => {
    let isMounted = true; // Flag to track mounted/unmounted state to handle errors
    const handleResize = () => {
      const newWidth = window.innerWidth;
      const newHeight = window.innerHeight;
      if (isMounted) {
        windowSize.current = [newWidth, newHeight];
        setIsSmallScreen(newWidth <= 767);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial setup

    return () => {
      isMounted = false; // Set the flag to false when the component is unmounted
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  /**
   * Check if value start with number/underscore or is valid reg number - or if previous path is one where id is expected.
   * If so, we treat it as an ID parameter (for routes such as person/:id, bil/:regNum, affar/:id, bevakning/:id etc.)...
   * ...which means concat it with the previous value and remove the value itself.
   **/
  const _adjustForIdPath = (pathnames) => {
    pathnames.forEach((value, index) => {
      // if (Number.isInteger(Number(value.charAt(0)) ||
      if (
        value.charAt(0) === '_' ||
        carHelper.isValidRegNumber(value) ||
        pathnames[index - 1] === 'affar' ||
        pathnames[index - 1] === 'bil' ||
        pathnames[index - 1] === 'foretag' ||
        (pathnames[index - 1] === 'vagnpark' &&
          pathnames[index] !== 'koncern') ||
        (pathnames[index - 1] === 'koncern' &&
          pathnames[index - 2] !== 'vagnpark') ||
        pathnames[index - 1] === 'lista' ||
        pathnames[index - 1] === 'person' ||
        pathnames[index - 1] === 'sok' ||
        pathnames[index - 1] === 'bevakning'
      ) {
        pathnames[index - 1] = pathnames[index - 1] + '/' + value;
        pathnames = pathnames.splice(0, index);
      }

      if (
        pathnames[index - 2] === 'vagnpark' &&
        pathnames[index - 1] === 'koncern'
      ) {
        pathnames[index - 2] =
          pathnames[index - 2] + '/' + pathnames[index - 1] + '/' + value;
        pathnames = pathnames.splice(0, index - 1);
      }
    });

    return pathnames;
  };

  /**
   * Set breadcrumbs and headline.
   * We do not render last path name as a breadcrumb, we set that as headline instead.
   **/
  const _generateBreadcrumbsAndHeadline = () => {
    let header;
    let crumbs = [];
    let pathnames = history.location.pathname
      .split('/')
      .filter((x) => x.toLowerCase());
    pathnames = _adjustForIdPath(pathnames);

    pathnames.forEach((value, index) => {
      const to = `/${pathnames.slice(0, index + 1).join('/')}`;
      let routeValue = value;

      if (value.indexOf('/') !== -1) {
        // This is a path with an id as parameter, so we need to adjust routeValue.
        routeValue = value.split('/')[0];
      }

      if (index === pathnames.length - 1) {
        header = routes[routeValue];
      } else {
        crumbs = crumbs.concat([
          <div
            className="pageHeaderWrapper__pageHeader__center__breadcrumbs__routes__route"
            key={to}
          >
            <NavLink to={to}>{routes[routeValue]}</NavLink>
            <span className="delimiter">&#x0003E;</span>
          </div>,
        ]);
      }
    });

    if (pathnames.length === 0) {
      if (state.user?.info?.id) {
        header = routes['dashboard'];
      } else {
        header = routes['bilprospekt'];
      }
    }

    if (state.props.headline) {
      // We might want to send in list name as headline on list page, etc...
      setHeadline(state.props.headline);
    } else {
      setHeadline(header);
    }

    if (crumbs.length) {
      setBreadcrumbs(
        <div className="pageHeaderWrapper__pageHeader__center__breadcrumbs__routes">
          {crumbs}
        </div>
      );
    }
  };

  return (
    <div className="pageHeaderWrapper">
      <div className="pageHeaderWrapper__pageHeader">
        <div className="pageHeaderWrapper__pageHeader__left">
          <div className="pageHeaderWrapper__pageHeader__left__search">
            <Search type="main" />
          </div>
        </div>
        <div className="pageHeaderWrapper__pageHeader__center">
          <div className="pageHeaderWrapper__pageHeader__center__breadcrumbs">
            {breadcrumbs}
          </div>
          <div className="pageHeaderWrapper__pageHeader__center__pageTitle">
            <h2
              className={state.props.headlineOnclick ? 'click' : null}
              onClick={
                state.props.headlineOnclick ? state.props.headlineOnclick : null
              }
            >
              {headline}
            </h2>
            {state.props.headlineSub?.length ? (
              <h3>{state.props.headlineSub}</h3>
            ) : null}
          </div>
        </div>
        {state.user?.info?.id ? (
          <div className="pageHeaderWrapper__pageHeader__right">
            {state.user.responsiveMode && !state.user.moduleMode ? (
              <div
                className="pageHeaderWrapper__pageHeader__right__icon"
                onClick={() => {
                  setNavigationStatus({
                    val: 'regular',
                    skipUpdateSettings: true,
                  });
                }}
              >
                <Icon val="menu" />
              </div>
            ) : null}
            <div
              className="pageHeaderWrapper__pageHeader__right__icon"
              onClick={() => {
                setShowSettings(true);
                setSettingsActivePageOrSection({
                  page: 'universal',
                  section: 'contactUs',
                });
              }}
            >
              <Icon val="contact_info" />
            </div>
            <div
              className="pageHeaderWrapper__pageHeader__right__icon"
              id="show-chat-icon" // Used for event listener.
              onClick={() => toggleChat()}
            >
              <Icon val="comment" />
            </div>
            {/* notification icon and logic */}
            <Dropdown
              headerElement={
                <div className="pageHeaderWrapper__pageHeader__right__icon">
                  <Icon
                    val={newNews ? 'notificationsActive' : 'notifications'}
                  />
                  {(unreadNewsCount > 0 || notificationsCleared) && (
                    <div className="pageHeader_news_badge">
                      <span className="pageHeader_news_badge_content">
                        {unreadNewsCount}
                      </span>
                    </div>
                  )}
                </div>
              }
              closeOnItemClick={dropdownOpen}
              alignRight={false}
              styling={{
                adjustToHeader: true,
                alignRight: true,
                extraWidth: windowSize.current[0],
                offsetLeft: isSmallScreen ? -windowSize.current[0] : -412,
              }}
              closeDropdown={unreadNewsCount === 0}
              source="menu"
              children={[
                <PageHeaderNotifications
                  key={'PageHeaderNotifications'}
                  onNewsUpdate={handleNewsUpdate}
                  amountDays={10}
                  onNotificationsCleared={handleNotificationsCleared}
                  updateUnreadNewsCount={updateUnreadNewsCount}
                  stateUserId={state.user.info.id}
                  handleDropdownClose={handleDropdownClose}
                />,
              ]}
            />
            <div
              className="pageHeaderWrapper__pageHeader__right__icon userImage"
              onClick={() => {
                setShowSettings(true);
              }}
            >
              <UserImage
                type="pageHeader"
                onClick={() => {
                  setShowSettings(true);
                }}
              />
            </div>
          </div>
        ) : null}
      </div>
      {state.user.showSettings ? <Settings /> : null}
    </div>
  );
};

const MapStateToProps = (state, props) => {
  return {
    props: props,
    user: state.user,
  };
};

export default connect(MapStateToProps)(PageHeader);
