import moment from "moment";

/**
 * For comparing timestamp to current date using
 * the set interval "week" or "month"
 * returns true or false depending if within current interval range
 *
 * @param timestamp date object
 * @param timing "yearly","weeky" or "monthly"
 */
export function withinInterval(timestamp, timing) {
  //If missing arguments - return false - we should probably show the summary
  if (!timestamp || !timing) {
    return false;
  }

  //Create momen objects to compare dates
  const now = moment();
  const then = moment(timestamp);

  //"week" - Check if timestamp is within this week
  if (timing === "weekly") {
    return then.isoWeek() === now.isoWeek(); // If the same week we should not show the summary again
  }

  //"month" - Check if timestamp is within this month
  if (timing === "monthly") {
    return then.month() === now.month();
  }

  //"year" - Check if timestamp is within this year
  if (timing === "yearly") {
    return then.year() === now.year();
  }

  //Default
  return false;
}

/* 
The possible types/values are:
"dealsWon", "analysis", "mail", "move", "lift", "visit", 
"meeting", "offer", "testride", "call", "valuation", "other",
*/

//Use this list to filter out AND decide the order
const order = [
  "dealsWon",
  "offer",
  "visit",
  "call",
  "lift",
  "meeting",
  "valuation",
  "analysis",
  "mail",
  "testride",
  "move",
  "other",
]; //!"move" should not be in the list - just for testing

//Filter out unwanted types
function filterActivities(activities) {
  return activities.filter((activity) => order.includes(activity.action));
}

//Sort the order according to "order" list
export function sortByOrder(activities) {
  if (Array.isArray(activities)) {
    const sorted = filterActivities(activities).sort(
      (a, b) => order.indexOf(a.action) - order.indexOf(b.action)
    );
    return sorted.length > 0 ? sorted : [];
  }
}

//Counts the number of action types and returns object
export function countActionTypes(arr) {
  return arr.reduce((allActions, action) => {
    const currCount = allActions[action.action] || 0;
    return {
      ...allActions,
      [action.action]: currCount + 1,
    };
  }, []);
}

/**
 *Used to generate a summary message
 * @param {string} interval - the interval specified "year", "month" or "week"
 * @param {string} modifier - how far back the interval should be
 * @param {object} tc - the app text-content/context-object
 * @param {string} name - the users name
 * @returns message - {wellcomeMessage, summaryMessage}
 */
export function generateSummaryMessage(interval, modifier, tc, name) {
  if (!interval || !tc || !name) {
    return null;
  }

  const period = mapToPeriod(interval, modifier);
  const summaryMessage = `${tc.summaryMessage} ${period}`;
  const firstName = name.split(" ")[0];
  const welcomeMessage = `${tc.hello ?? "Hej"} ${firstName}`;

  //The message that will be populated on the summary
  const message = {
    welcomeMessage: welcomeMessage,
    summaryMessage: summaryMessage,
  };

  //Maps interval and modifier to appropriate period text
  function mapToPeriod(interval, modifier) {
    let period = "period"; //Default if nothing else is true

    //this year
    if ((interval === "year") & (modifier === 0)) {
      period = tc.thisYear;
    }
    //previous year
    if ((interval === "year") & (modifier === 1)) {
      period = tc.previousYear;
    }
    //this month
    if ((interval === "month") & (modifier === 0)) {
      period = tc.thisMonth;
    }
    //prev month
    if ((interval === "month") & (modifier === 1)) {
      period = tc.previousMonth;
    }
    //this week
    if ((interval === "week") & (modifier === 0)) {
      period = tc.currentWeek;
    }
    //prev week
    if ((interval === "week") & (modifier === 1)) {
      period = tc.previousWeek;
    }

    return period.toLowerCase();
  }

  return message;
}

export const dummyData = [
  {
    _id: "adsakdpajwdopawdopadw",
    action: "offer",
  },
  {
    _id: "adsakdpajwdopawdopadw",
    action: "call",
  },
  {
    _id: "adsakdpajwdopawdopadw",
    action: "testride",
  },
  {
    _id: "adsakdpajwdopawdopadw",
    action: "visit",
  },
  {
    _id: "adsakdpajwdopawdopadw",
    action: "valuation",
  },
  {
    _id: "adsakdpajwdopawdopadw",
    action: "dealsWon",
  },
  {
    _id: "adsakdpajwdopawdopadw",
    action: "dealsWon",
  },
  {
    _id: "adsakdpajwdopawdopadw",
    action: "meeting",
  },
];
