import { Container, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Christmas from '../../greetings/variations/christmas';
import Popup from 'components/popup/popup';
import Summary from '../../summary';
import { Arrow, Close, PageIndicator } from '../ui';
import { Generic } from '../generic';

/**
 * Handles the behind the scenes logic concerning notifications.
 * @returns React.ReactElement | null
 */
function useNotification() {
  const [currentIndex, setCurrentIndex] = useState(0); //Used to move between notifications
  const [data, setData] = useState([]);
  const [notifications, setNotifications] = useState();
  const [seenIDs, setSeenIDs] = useState([]); //Used to update backend on what notifications has been seen
  const [url, setURL] = useState(); //Used to trigger navigation by notification
  const reFetchRateMinutes = (min) => min * (1000 * 60);

  //Fetch notificaitons on mount
  useEffect(() => {
    fetchNotifications();
  }, []);

  //Refetch Notifications when no data
  useEffect(() => {
    let timeOut;

    if (data.length > 0) {
      return () => clearTimeout(timeOut);
    }

    timeOut = setInterval(() => {
      fetchNotifications();
    }, reFetchRateMinutes(120));

    return () => clearTimeout(timeOut);
  }, [data]);

  //Store what notificationIds has been seen by user.
  useEffect(() => {
    if (Array.isArray(data) && data.length > 0) {
      let match = seenIDs.find((id) => data[currentIndex]._id === id);
      if (match) {
        return;
      }
      setSeenIDs([...seenIDs, data[currentIndex]._id]);
    }
  }, [data]);

  //Handle mapping of notifications when data changes
  useEffect(() => {
    mapDataToNotifications(data);
  }, [data]);

  //If url is set = user has pressed button to navigate
  useEffect(() => {
    if (url) {
      setSeen();
    }
  }, [url]);

  /**
   *  The visual content to be shown to the user.
   * @returns React.ReactElement || null
   */
  const Content = () => {
    return notifications?.length ? (
      <Popup
        close={close}
        noPadding={true}
        borderRadius={true}
        onlyContent={true}
        overflow={true}
        center={true}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ width: '100%', height: '100%' }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{
              width: 'clamp(800px,75vw,400px)',
              height: 'clamp(600px, 75vh, 200px)',
              backgroundColor: 'white',
              borderRadius: '5px',
            }}
          >
            {notifications?.length > 1 ? (
              <Arrow forward={false} action={backwards} />
            ) : null}
            <Stack
              alignItems="center"
              sx={{ width: '100%', height: '100%' }}
              className="relative"
            >
              <Container
                sx={{ width: '100%', height: '100%' }}
                disableGutters={true}
              >
                {notifications[currentIndex]}
              </Container>
              <PageIndicator
                index={currentIndex}
                length={notifications?.length}
              />
              <Close action={close} />
            </Stack>
            {notifications?.length > 1 ? (
              <Arrow forward={true} action={forwards} />
            ) : null}
          </Stack>
        </Stack>
      </Popup>
    ) : null;
  };

  /**
   * Used to get the users notifications from the backend
   */
  function fetchNotifications() {
    fetch(`/notifications/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((resp) => {
        try {
          return resp.json();
        } catch (error) {
          return [];
        }
      })
      .then((data) => {
        setData(data);
      });
  }
  /**
   * Used to send what ids has been seen by the user to the backend.
   */
  function setSeen() {
    if (seenIDs?.length >= 1) {
      fetch('/notifications/setseen', {
        method: 'PUT',
        body: JSON.stringify({ notificationIds: seenIDs }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }).then(() => {
        //If notification has set an url in state, open new tab
        if (url) {
          window.open(url);
        }
        //Clear state
        setCurrentIndex(0);
        setSeenIDs([]);
        setURL();

        //Try to refetch notifications
        fetchNotifications();
      });
    }
  }

  /**
   * Used to generate an array of components that's used to populate the Content
   * for the Notification(parent component).
   * @param {Array} data - An array of notifications or empty array
   * @returns {Array} result - An array of react components
   */
  function mapDataToNotifications(data) {
    if (!Array.isArray(data) || !data?.length) {
      setNotifications();
      return;
    }
    const result = [];

    data.forEach((notification) => {
      if (notification.type === 'summary') {
        result.push(<Summary summation={notification.data} />);
      }

      if (notification.type === 'christmas') {
        result.push(<Christmas />);
      }

      if (notification.type === 'generic' || notification.type === 'global') {
        result.push(<Generic data={notification.data} setURL={setURL} />);
      }
    });

    setNotifications(result);
  }

  /**
   * Used to close the notification popup and
   * save what notifications was shown to the user
   * @type {function}
   */
  function close() {
    setSeen();
  }

  /**
   * Used to move to the next page/index
   * @type {function}
   */
  function forwards() {
    if (data?.length) {
      setCurrentIndex((prev) =>
        prev === notifications.length - 1 ? 0 : prev + 1
      );
    }
  }

  /**
   * Used to move to the previous page/index
   * @type {function}
   */
  function backwards() {
    if (data?.length) {
      setCurrentIndex((prev) =>
        prev === 0 ? notifications?.length - 1 : prev - 1
      );
    }
  }

  return { Content };
}

export default useNotification;
