import React, { useContext, useEffect, useRef, useState } from 'react';
import LanguageContext from 'language-context';
import { splitList } from 'store/lists/tasks';
import Icon from 'components/icon';
import Info from 'components/info';
import Popup from 'components/popup';
import WidgetFooter from 'components/widget_footer';
import WidgetHeader from '../widget_header';

/**
 * Component that helps user to split a list.
 * If a user manually adjusts the value for rows, we automatically calculate average value for the other rows.
 *
 * @param props.close - func
 * @param props.selectedList - object - The list
 */
const SplitList = (props) => {
  const tc = useContext(LanguageContext);
  const [info, setInfo] = useState({
    hint: props.selectedList.total > 1 ? '' : tc.tooFewProspectsInList,
    validated: true,
  });
  const [splits, setSplits] = useState([]);
  const [touched, setTouched] = useState([]); // Indexes of splits where user have manually adjusted the size.
  const splitListAmountInputRefs = useRef([]);
  const splitListNameInputRefs = useRef([]);

  useEffect(() => {
    if (props.selectedList && props.selectedList.total > 1) {
      setSplits([
        {
          size: Math.floor(props.selectedList.total / 2),
          name: props.selectedList.name + ' 1',
        },
        {
          size:
            props.selectedList.total -
            Math.floor(+props.selectedList.total / 2),
          name: props.selectedList.name + ' 2',
        },
      ]);
    }
  }, [props.selectedList]);

  const _calculateSplits = (payload) => {
    let splitsCopy = splits.slice();
    let touchedCopy = touched.slice();
    let amountTouched = 0; // Total of manually adjusted.

    if (payload.add) {
      // Add split.
      splitsCopy.push({
        size: 0,
        name: props.selectedList.name + ' ' + (splitsCopy.length + 1),
      });

      // Calculate sum of manually adjusted.
      splitsCopy.forEach((split, i) => {
        if (touchedCopy.includes(i)) {
          amountTouched = amountTouched + split.size;
        }
      });
    } else if (payload.remove) {
      // Remove split.
      splitsCopy = splitsCopy.filter((split, i) => i !== payload.index);
      touchedCopy = touchedCopy.filter((num) => num !== payload.index);

      // Calculate sum of manually adjusted.
      splitsCopy.forEach((split, i) => {
        if (touchedCopy.includes(i)) {
          amountTouched = amountTouched + split.size;
        }
      });
    } else if (payload.amountChange) {
      // User have adjusted a split manually.
      if (!touchedCopy.includes(payload.index)) {
        touchedCopy.push(payload.index);
      }

      // Calculate sum of manually adjusted...
      splitsCopy.forEach((split, i) => {
        if (touchedCopy.includes(i) && i !== payload.index) {
          amountTouched = amountTouched + split.size;
        }
      });
      // ...and add current input value to sum.
      amountTouched = amountTouched + payload.value;

      // Value too high, stop.
      if (
        amountTouched > props.selectedList.total ||
        (amountTouched > props.selectedList.total - 1 &&
          splitsCopy.length > touchedCopy.length)
      ) {
        return setInfo({
          ...info,
          hint: tc.valueTooHigh,
        });
      }

      // Set current input value to correct split.
      splitsCopy.map((split, i) => {
        if (i === payload.index) {
          split.size = payload.value;
        }
        return split;
      });
    }

    // Set indexes of manually touched splits.
    setTouched(touchedCopy);

    // New total to use for automatic calculation.
    const newTotalCalculate = props.selectedList.total - amountTouched;

    // Calculated value for splits that isn't manually adjusted.
    let amountCalculatedSplits = splitsCopy.length - touchedCopy.length;

    // If there isn't enough to give each split size >= 1, remove splits until this is true.
    if (newTotalCalculate / amountCalculatedSplits < 1) {
      const removeAmountCalculatedSplits = () => {
        if (amountCalculatedSplits === 0) {
          return;
        }
        splitsCopy.pop();
        amountCalculatedSplits = amountCalculatedSplits - 1;
        if (newTotalCalculate / amountCalculatedSplits < 1) {
          removeAmountCalculatedSplits();
        }
      };
      removeAmountCalculatedSplits();
      return setInfo({
        ...info,
        hint: tc.valuesAndListsDontAddUp,
      });
    }

    // Calculate splits.
    let count = 0;
    const newSplits = splitsCopy.map((split, i) => {
      if (i === splitsCopy.length - 1) {
        let size =
          props.selectedList.total - count < 0
            ? 0
            : props.selectedList.total - count;
        if (size > props.selectedList.total) {
          size = props.selectedList.total;
        } else if (size < 0) {
          size = 0;
        }
        count = count + size;
        // Last one, add the rest.
        return {
          size: size,
          name: split.name,
        };
      } else if (touchedCopy.includes(i)) {
        // Been touched, do not adjust.
        let size = split.size < 0 ? 0 : split.size;
        if (size > props.selectedList.total - 1) {
          size = props.selectedList.total - 1;
        }
        count = count + size;
        return {
          size: size,
          name: split.name,
        };
      } else {
        let size =
          Math.floor(newTotalCalculate / amountCalculatedSplits) < 0
            ? 0
            : Math.floor(newTotalCalculate / amountCalculatedSplits);
        if (size > props.selectedList.total) {
          size = props.selectedList.total;
        }
        count = count + size;
        return {
          size: size,
          name: split.name,
        };
      }
    });

    setInfo({
      hint: '',
      validated: props.selectedList.total - count === 0, // Should always be true, extra safe catch just in case.
    });

    return setSplits(newSplits);
  };

  const _addSplit = () => {
    if (props.selectedList.total === splits.length) {
      return setInfo({
        ...info,
        hint: tc.cannotAddMoreListsThanProspects,
      });
    }

    return _calculateSplits({ add: true });
  };

  const _onAmountInputChange = (index) => {
    if (
      Math.floor(+splitListAmountInputRefs.current[index].value) >
      props.selectedList.total - 1
    ) {
      return setInfo({
        ...info,
        hint: tc.valueTooHigh,
      });
    }
    let inputValue = 0;
    if (
      splitListAmountInputRefs.current[index] &&
      splitListAmountInputRefs.current[index].value
    ) {
      inputValue =
        Math.floor(+splitListAmountInputRefs.current[index].value) >
        props.selectedList.total - 1
          ? props.selectedList.total - 1
          : Math.floor(+splitListAmountInputRefs.current[index].value);
    }

    return _calculateSplits({
      amountChange: true,
      index: index,
      value: inputValue,
    });
  };

  const _onNameInputChange = (index) => {
    if (!splitListNameInputRefs.current[index].value.length) {
      return setInfo({
        ...info,
        hint: tc.nameCannotBeEmpty,
      });
    } else {
      setInfo({
        ...info,
        hint: '',
      });
    }
    setSplits(
      splits.map((split, i) => {
        if (i === index) {
          split.name = splitListNameInputRefs.current[index].value;
        }
        return split;
      })
    );
  };

  const _removeSplit = (index) => {
    if (splits.length === 2) {
      return setInfo({
        ...info,
        hint: tc.cannotSplitLessThanTwo,
      });
    }

    return _calculateSplits({ index: index, remove: true });
  };

  const _renderSplitRows = () => {
    if (splits && splits.length) {
      return splits.map((split, i) => {
        return (
          <div
            className="splitListWrapper__splitList__content__row"
            key={i + 1}
          >
            <div className="splitListWrapper__splitList__content__row__size">
              <input
                disabled={i === splits.length - 1}
                onChange={() => {
                  _onAmountInputChange(i);
                }}
                ref={(el) => (splitListAmountInputRefs.current[i] = el)}
                type="text"
                value={split.size}
              />
            </div>
            <div className="splitListWrapper__splitList__content__row__touched">
              <Icon class={touched.includes(i) ? 'active' : null} val="check" />
            </div>
            <div className="splitListWrapper__splitList__content__row__name">
              <input
                onChange={() => {
                  _onNameInputChange(i);
                }}
                ref={(el) => (splitListNameInputRefs.current[i] = el)}
                type="text"
                value={split.name}
              />
            </div>
            <div className="splitListWrapper__splitList__content__row__remove">
              <Icon
                class={i > 1 ? 'active' : null}
                val="remove"
                onClick={() => {
                  _removeSplit(i);
                }}
              />
            </div>
          </div>
        );
      });
    }
  };

  const _splitList = async (splits) => {
    await splitList({
      listId: props.selectedList.id,
      splits: splits,
    });
    props.close();
  };

  return props.selectedList ? (
    <Popup close={props.close} size="small">
      <div className="splitListWrapper">
        <div className="splitListWrapper__splitList">
          <div className="splitListWrapper__splitList__header">
            <WidgetHeader
              headline={tc.splitList}
              headlineSub={tc.splitListInfo}
            />
          </div>
          <div className="splitListWrapper__splitList__content">
            {props.selectedList.total > 1 ? (
              <div className="splitListWrapper__splitList__content__info">
                <p>
                  <span className="label">{tc.list}:</span>
                  <span>{props.selectedList.name}</span>
                </p>
                <p>
                  <span className="label">{tc.amountProspects}:</span>
                  <span>
                    {props.selectedList.total} {tc.aPiece.toLowerCase()}
                  </span>
                </p>
              </div>
            ) : null}
            {info.hint.length ? (
              <Info>
                <h4>{tc.thereWasAProblem}</h4>
                <p>{info.hint}</p>
              </Info>
            ) : null}
            {props.selectedList.total > 1 ? (
              <>
                <div className="splitListWrapper__splitList__content__row">
                  <div className="splitListWrapper__splitList__content__row__size label">
                    {tc.amount}
                  </div>
                  <div className="splitListWrapper__splitList__content__row__touched label">
                    {tc.adjusted}
                  </div>
                  <div className="splitListWrapper__splitList__content__row__name label">
                    {tc.listName}
                  </div>
                  <div className="splitListWrapper__splitList__content__row__remove label">
                    {tc.remove}
                  </div>
                </div>
                {_renderSplitRows()}
                <div
                  className="splitListWrapper__splitList__content__addRow"
                  onClick={_addSplit}
                >
                  {tc.addRow}
                </div>
              </>
            ) : null}
          </div>
          {props.selectedList.total > 1 ? (
            <div className="splitListWrapper__splitList__footer">
              <WidgetFooter
                buttonOneFunc={() => {
                  if (!info.validated) {
                    // Should never happen, extra safe catch just in case.
                    return setInfo({
                      ...info,
                      hint: tc.splitListMessage,
                    });
                  } else {
                    return _splitList(splits);
                  }
                }}
                buttonOneText={tc.splitList}
                buttonTwoFunc={() => {
                  if (typeof props.close === 'function') {
                    props.close();
                  }
                }}
                buttonTwoText={tc.cancel}
              />
            </div>
          ) : null}
        </div>
      </div>
    </Popup>
  ) : null;
};

export default SplitList;
