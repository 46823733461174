import React from "react";
import Icon from "components/icon";

/**
 * Render one toggle button.
 *
 * @param props.active - bool
 * @param props.disabled - bool (optional)
 * @param props.label - string
 * @param props.labelSub - string (optional)
 * @param props.onClick - func
 */
const ToggleOption = (props) => {
  return (
    <div className="toggleOptionWrapper">
      <div
        className={`toggleOptionWrapper__toggleOption ${
          props.disabled ? "disabled" : ""
        }`}
      >
        <div className="toggleOptionWrapper__toggleOption__label">
          <div className="toggleOptionWrapper__toggleOption__label__main">
            {props.label}
          </div>
          {props.labelSub?.length ? (
            <div className="toggleOptionWrapper__toggleOption__label__sub">
              {props.labelSub}
            </div>
          ) : null}
        </div>
        <div
          className={`toggleOptionWrapper__toggleOption__icon ${
            props.active ? "active" : ""
          }`}
          onClick={() => {
            if (!props.disabled && typeof props.onClick === "function") {
              props.onClick();
            }
          }}
        >
          <Icon val={props.active ? "toggleOn" : "toggleOff"} />
        </div>
      </div>
    </div>
  );
};

export default ToggleOption;
