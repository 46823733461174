import React, { useContext, useEffect, useState } from 'react';
import languageContext from 'language-context';
import Loading from 'components/loading';
import { connect } from 'react-redux';
import AgileBoardPopupContacts from './agile_board_popup_contacts';
import FoldableSection from './foldable_section';
import GeneralInfo from './_general_info';
import { Table } from 'components/table';
import Info from 'components/info';

const AgileBoardPopupAdditionalInfo = ({
  hideBlocks,
  previewData,
  setHideBlocks,
  id,
  fleetData,
}) => {
  const [noFleet, setNoFleet] = useState(false);

  useEffect(() => {
    if (fleetData) {
      const keys = Object.keys(fleetData);

      if (keys.length < 1) {
        setNoFleet(true);
      }
    }
  }, [fleetData]);
  const tc = useContext(languageContext);
  if (
    previewData?.item?.type === 'deal' &&
    !previewData?.item?.prospect_id?.length
  ) {
    return null;
  }
  const toggleSection = (section) => {
    if (hideBlocks.includes(section)) {
      setHideBlocks(hideBlocks.filter((num) => num !== section));
    } else {
      setHideBlocks(hideBlocks.concat(section));
    }
  };
  // const prospectFleet = previewData.prospectInformation?.[0].fleet;
  const fleet = Object.keys(fleetData || {})
    .map((key) => {
      return fleetData[key].hits;
    })
    .reduce((acc, array) => {
      return acc.concat(array);
    }, [])
    .filter(Boolean);
  return Array.isArray(previewData.prospectInformation) ? (
    <div>
      <div>
        {/* Plan is to only have 1 prospect per deal... */}
        {previewData.prospectInformation.slice(0, 1).map((prospect) => {
          if (!prospect || !prospect?.type) {
            return null;
          }

          return (
            <div
              className="agileBoardPopupWrapper__agilePopup__content__block__content__prospectOrCar"
              key={prospect.user_id}
            >
              <div className="half-sections">
                <FoldableSection
                  active={!hideBlocks.includes('additionalProspectInfo')}
                  label={tc.generalInfo}
                  toggleSection={() => toggleSection('additionalProspectInfo')}
                >
                  <GeneralInfo
                    id={id}
                    previewData={previewData}
                    prospect={prospect}
                  />
                </FoldableSection>
                <AgileBoardPopupContacts
                  previewData={previewData}
                  toggleSection={() => toggleSection('contacts')}
                  active={!hideBlocks.includes('contacts')}
                />
              </div>

              <FoldableSection
                active={!hideBlocks.includes('vehicles')}
                label={tc.fleet}
                toggleSection={() => toggleSection('vehicles')}
              >
                {noFleet ? (
                  <Info>
                    <h4>{tc.fleet}</h4>
                    <p>{tc.noVehicles}</p>
                  </Info>
                ) : fleet && fleet.length > 0 ? (
                  <Table
                    columns={[
                      { id: 'brand', label: tc.brand, visible: true },
                      { id: 'model', label: tc.model, visible: true },
                      { id: 'regnr', label: tc.regNumberShort, visible: true },
                      {
                        id: 'possession',
                        label: tc.acquiredAges,
                        visible: true,
                      },
                      { id: 'financedBy', label: tc.financed, visible: true },
                    ]}
                    rows={fleet.map((vehicle) => {
                      const financedBy = (props = {}) => {
                        if (props.leasing === 1) {
                          return tc.leasing;
                        } else if (props.credit === 1) {
                          return tc.credit;
                        } else if (props.leasing === 1 && props.credit === 1) {
                          return tc.dataMissing;
                        } else {
                          return tc.cash;
                        }
                      };
                      return {
                        brand: vehicle.brand,
                        model: vehicle.model,
                        regnr: vehicle.reg_number,
                        possession: vehicle.searchable_data.possession,
                        financedBy: financedBy(vehicle),
                      };
                    })}
                  />
                ) : (
                  <Loading />
                )}
              </FoldableSection>
            </div>
          );
        })}
      </div>
    </div>
  ) : (
    <div className="agileBoardPopupWrapper__agilePopup__content__block">
      <Loading />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(AgileBoardPopupAdditionalInfo);
