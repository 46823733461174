import React, { useContext, useEffect, useState } from "react";
import LanguageContext from "language-context";
import { connect } from "react-redux";
import Collapsible from "components/collapsible";
import Icon from "components/icon";
import Loading from "components/loading";

/**
 * Render all colleagues, including connections, as selectable rows.
 *
 * @param state.props.disabledUsers - arr (optional) - Array with user ids, if some should be disabled.
 * @param state.props.onChange - func - Return selected colleagues.
 */
const ColleaguesRows = (state) => {
  const [users, setUsers] = useState([]);
  const disabledUsers = Array.isArray(state.props.disabledUsers)
    ? state.props.disabledUsers
    : [];
  const tc = useContext(LanguageContext);

  useEffect(() => {
    if (state.props.existingUsers) {
      const connectedColleagues = state.user.connections.reduce(
        (acc, item) => acc.concat(item.users),
        []
      );
      const users = state.props.existingUsers
        .map((id) =>
          state.user.colleagues
            .concat(connectedColleagues)
            .find((colleague) => colleague.id === id)
        )
        .filter((user) => Boolean(user));
      setUsers(users);
    }
  }, [
    state.props.existingUsers,
    state.user.colleagues,
    state.user.connections,
  ]);

  useEffect(() => {
    if (typeof state.props.onChange === "function") {
      state.props.onChange(users);
    }
  }, [state.props, users]);

  const _isDealerToggled = (arr) => {
    return !arr.find((num) => !users.find((x) => num.id === x.id));
  };

  const _toggleDealer = (arr) => {
    const isToggled = _isDealerToggled(arr);

    if (isToggled) {
      setUsers([]);
    } else {
      setUsers(arr);
    }
  };

  const _renderColleagues = () => {
    if (state.user.colleagues && state.user.colleagues.length) {
      const isToggled = _isDealerToggled(state.user.colleagues);
      const content = (
        <>
          <div
            className={`colleaguesRowsWrapper__colleaguesRows__content__colleagues__dealer__toggleAll ${
              isToggled ? "active" : ""
            }`}
            onClick={() => {
              _toggleDealer(state.user.colleagues);
            }}
          >
            <Icon val={isToggled ? "check" : "checkbox"} />
            {isToggled ? tc.unselectAll : tc.selectAll}
          </div>
          <div className="colleaguesRowsWrapper__colleaguesRows__content__colleagues__dealer__users">
            {state.user.colleagues.map((num, i) => {
              let className;
              if (disabledUsers.includes(num.id)) {
                className =
                  "colleaguesRowsWrapper__colleaguesRows__content__colleagues__dealer__users__item disabled";
              } else if (users.find((x) => x.id === num.id)) {
                className =
                  "colleaguesRowsWrapper__colleaguesRows__content__colleagues__dealer__users__item active";
              } else {
                className =
                  "colleaguesRowsWrapper__colleaguesRows__content__colleagues__dealer__users__item";
              }

              return (
                <div
                  className={className}
                  key={num.id}
                  onClick={() => {
                    if (users.find((x) => x.id === num.id)) {
                      setUsers(users.filter((x) => x.id !== num.id));
                    } else {
                      setUsers(users.concat([num]));
                    }
                  }}
                >
                  <Icon
                    class={users.find((x) => x.id === num.id) ? "active" : null}
                    val={
                      users.find((x) => x.id === num.id) ||
                      disabledUsers.find((x) => x === num.id)
                        ? "check"
                        : "checkbox"
                    }
                  />
                  {num.name}
                </div>
              );
            })}
          </div>
        </>
      );

      return (
        <div className="colleaguesRowsWrapper__colleaguesRows__content__colleagues__dealer">
          <Collapsible content={content} header={state.user.info.dealerName} />
        </div>
      );
    }
  };

  const _renderConnections = () => {
    if (state.user.connections && state.user.connections.length) {
      return state.user.connections.map((dealer) => {
        const isToggled = _isDealerToggled(dealer.users);
        const content = (
          <>
            <div
              className={`colleaguesRowsWrapper__colleaguesRows__content__colleagues__dealer__toggleAll ${
                isToggled ? "active" : ""
              }`}
              onClick={() => {
                _toggleDealer(dealer.users);
              }}
            >
              <Icon val={isToggled ? "check" : "checkbox"} />
              {isToggled ? tc.unselectAll : tc.selectAll}
            </div>
            <div className="colleaguesRowsWrapper__colleaguesRows__content__colleagues__dealer__users">
              {dealer.users.map((num, i) => {
                let className;
                if (disabledUsers.includes(num.id)) {
                  className =
                    "colleaguesRowsWrapper__colleaguesRows__content__colleagues__dealer__users__item disabled";
                } else if (users.find((x) => x.id === num.id)) {
                  className =
                    "colleaguesRowsWrapper__colleaguesRows__content__colleagues__dealer__users__item active";
                } else {
                  className =
                    "colleaguesRowsWrapper__colleaguesRows__content__colleagues__dealer__users__item";
                }

                return (
                  <div
                    className={className}
                    key={num.id}
                    onClick={() => {
                      if (users.find((x) => x.id === num.id)) {
                        setUsers(users.filter((x) => x.id !== num.id));
                      } else {
                        setUsers(users.concat([num]));
                      }
                    }}
                  >
                    <Icon
                      class={
                        users.find((x) => x.id === num.id) ? "active" : null
                      }
                      val={
                        users.find((x) => x.id === num.id) ||
                        disabledUsers.find((x) => x === num.id)
                          ? "check"
                          : "checkbox"
                      }
                    />
                    {num.name}
                  </div>
                );
              })}
            </div>
          </>
        );

        return (
          <div
            className="colleaguesRowsWrapper__colleaguesRows__content__colleagues__dealer"
            key={dealer.id}
          >
            <Collapsible content={content} header={dealer.name} />
          </div>
        );
      });
    }
  };

  const _stateCheck = () => {
    return !!(state.user && state.user.colleagues);
  };

  return _stateCheck() ? (
    <div className="colleaguesRowsWrapper">
      <div className="colleaguesRowsWrapper__colleaguesRows">
        <div className="colleaguesRowsWrapper__colleaguesRows__content">
          {users.length ? (
            <div className="colleaguesRowsWrapper__colleaguesRows__content__selectedUsers">
              <div className="colleaguesRowsWrapper__colleaguesRows__content__selectedUsers__label">
                {tc.selectedColleagues}:
              </div>
              <div className="colleaguesRowsWrapper__colleaguesRows__content__selectedUsers__users">
                {users.map((num, i) => {
                  if (i === users.length - 1) {
                    return <p key={i}>{num.name}</p>;
                  } else {
                    return (
                      <p key={i}>
                        {num.name}
                        <span className="bullet">&#8226;</span>
                      </p>
                    );
                  }
                })}
              </div>
            </div>
          ) : null}
          <div className="colleaguesRowsWrapper__colleaguesRows__content__colleagues">
            {_renderColleagues()}
            {_renderConnections()}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Loading />
  );
};

const MapStateToProps = (state, props) => {
  return {
    props: props,
    user: state.user,
  };
};

export default connect(MapStateToProps)(ColleaguesRows);
