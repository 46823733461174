import React, { useEffect, useRef, useState } from "react";
import Icon from "components/icon";

/**
 * Render an info component.
 * Normally expects a <h4> element and optional <p> elements to follow as props.children.
 *
 * @param props.inline - bool (optional) - When we don't want to display a smaller version.
 */
const Info = (props) => {
  const [responsiveClassWidth, setResponsiveClassWidth] = useState("");
  const infoRef = useRef(null);
  const observer = useRef(null);

  useEffect(() => {
    _setResponsiveClass();
    observer.current = new ResizeObserver(_setResponsiveClass);
    observer.current.observe(infoRef.current);

    const ref = infoRef.current;

    return () => observer.current.unobserve(ref);
  }, []);

  const _setResponsiveClass = () => {
    if (!infoRef?.current) {
      return;
    }

    const width = infoRef.current.getBoundingClientRect().width;
    const breakpoint1 = 300;

    if (width <= breakpoint1) {
      setResponsiveClassWidth("infoResponsiveWidth1");
    } else if (width > breakpoint1) {
      setResponsiveClassWidth("");
    }
  };

  return (
    <div
      className={`infoWrapper ${responsiveClassWidth} ${
        props.inline ? "inline" : ""
      }`}
      ref={infoRef}
    >
      <div className="infoWrapper__info">
        <div className="infoWrapper__info__left">
          <Icon val="infoOutlined" />
        </div>
        <div className="infoWrapper__info__right">{props.children}</div>
      </div>
    </div>
  );
};

export default Info;
