import React, { useContext } from "react";
import LanguageContext from "language-context";
import Icon from "components/icon";

const HomeFlow = () => {
  const tc = useContext(LanguageContext);

  return (
    <div className="homeFlowWrapper">
      <div className="homeFlowWrapper__homeFlow">
        <div className="homeFlowWrapper__homeFlow__content">
          <div className="homeFlowWrapper__homeFlow__content__item">
            <div className="homeFlowWrapper__homeFlow__content__item__left">
              <div className="homeFlowWrapper__homeFlow__content__item__left__icon">
                <Icon val="search" />
              </div>
            </div>
            <div className="homeFlowWrapper__homeFlow__content__item__right">
              <h3>{tc.homeServiceFlow1_1}</h3>
              <p>{tc.homeServiceFlow1_2}</p>
            </div>
          </div>
          <div className="homeFlowWrapper__homeFlow__content__item">
            <div className="homeFlowWrapper__homeFlow__content__item__left">
              <div className="homeFlowWrapper__homeFlow__content__item__left__icon">
                <Icon val="agile" />
              </div>
            </div>
            <div className="homeFlowWrapper__homeFlow__content__item__right">
              <h3>{tc.homeServiceFlow2_1}</h3>
              <p>{tc.homeServiceFlow2_2}</p>
              <p>{tc.homeServiceFlow2_3}</p>
            </div>
          </div>
          <div className="homeFlowWrapper__homeFlow__content__item">
            <div className="homeFlowWrapper__homeFlow__content__item__left">
              <div className="homeFlowWrapper__homeFlow__content__item__left__icon">
                <Icon val="person" />
              </div>
            </div>
            <div className="homeFlowWrapper__homeFlow__content__item__right">
              <h3>{tc.homeServiceFlow3_1}</h3>
              <p>{tc.homeServiceFlow3_2}</p>
            </div>
          </div>
          <div className="homeFlowWrapper__homeFlow__content__item">
            <div className="homeFlowWrapper__homeFlow__content__item__left">
              <div className="homeFlowWrapper__homeFlow__content__item__left__icon">
                <Icon val="analysis" />
              </div>
            </div>
            <div className="homeFlowWrapper__homeFlow__content__item__right">
              <h3>{tc.homeServiceFlow5_1}</h3>
              <p>{tc.homeServiceFlow5_2}</p>
            </div>
          </div>
          <div className="homeFlowWrapper__homeFlow__content__item">
            <div className="homeFlowWrapper__homeFlow__content__item__left">
              <div className="homeFlowWrapper__homeFlow__content__item__left__icon">
                <Icon val="charts" />
              </div>
            </div>
            <div className="homeFlowWrapper__homeFlow__content__item__right">
              <h3>{tc.homeServiceFlow4_1}</h3>
              <p>{tc.homeServiceFlow4_2}</p>
              <p>{tc.homeServiceFlow4_3}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeFlow;
