import React, { useContext, useState, useRef, useEffect } from 'react';
import LanguageContext from 'language-context';
import {
  resetUpload,
  uploadAvtalskunder,
  getContractLists,
} from 'store/leads/tasks';
import { showFlashMessage } from 'store/flash_messages/tasks';
import Loading from 'components/loading';
import WidgetFooter from 'components/widget_footer';
import Menu from 'components/menu';
import { NavLink } from 'react-router-dom';
import Icon from 'components/icon';
import Info from '../../info';

/**
 * Upload file.
 *
 * @param props.close - func
 */
const AvtalskunderUploadUploader = (props) => {
  const tc = useContext(LanguageContext);
  const maxSize = 1024 * 1024 * 2;
  const acceptedFileTypes = ['csv', 'xls', 'xlsx', 'ods'];
  const acceptedMimeTypes = [
    'text/csv',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.oasis.opendocument.spreadsheet',
  ];

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [contracts, setContracts] = useState(null);
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [contractListName, setContractListName] = useState('');
  const [selectedContractList, setSelectedContractList] = useState('');

  const inputRef = useRef(null);

  useEffect(() => {
    if (!contracts) {
      (async () => {
        const data = await getContractLists();

        if (data) {
          if (data.error) {
            setError('Kunde ej hämta Avtalslistor');
            return;
          }
          setContracts(data);
        }
      })();
    }
  }, [contracts]);

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];

    if (!file) {
      setError('Nåt gick fel...');
      return;
    }

    const validated = _validateFile(file);

    if (validated.success === false) {
      setError(validated.msg);
      return;
    }

    setError('');
    setLoading(true);

    const res = await uploadAvtalskunder({
      file,
      selectedMethod,
      selectedContractList,
      contractListName,
    });

    if (!res.success) {
      setLoading(false);
      _uploadError();
    }
  };

  const _handleFileUploadClick = () => {
    if (inputRef) {
      inputRef.current.click();
    }
  };

  const _uploadError = () => {
    showFlashMessage(tc.leadsUploadFailed, 'fail');
    resetUpload();
  };

  const _validateFile = (file) => {
    const splits = file.name.split('.');
    const fileType = splits[splits.length - 1];

    if (file.size > maxSize) {
      return {
        success: false,
        msg: `${tc.leadsUploadFileExceedsMaxSize} 2 MB.`,
      };
    } else if (
      !acceptedFileTypes.includes(fileType) ||
      !acceptedMimeTypes.includes(file.type)
    ) {
      return {
        success: false,
        msg: `${tc.leadsUploadUnsupportedFileType} ${
          tc.supportedFiletypesIs
        }: ${acceptedFileTypes.join(', ')}.`,
      };
    }

    return {
      success: true,
      msg: null,
    };
  };

  return (
    <div className="leadsUploadWrapper__leadsUpload__content">
      <input
        type="file"
        onChange={handleFileUpload}
        ref={inputRef}
        className="hidden"
      />
      {loading ? (
        <Loading />
      ) : (
        <>
          <p>{tc.contractListsUploadDescriptionOne}</p>
          <p>{tc.contractListsUploadDescriptionTwo}</p>
          <p>{tc.contractListsUploadDescriptionThree}</p>
          {error && (
            <p className="leadsUploadWrapper__leadsUpload__content__error">
              {error}
            </p>
          )}
          {!selectedMethod && (
            <div className="leadsUploadWrapper__leadsUpload__content__menu">
              <Menu
                items={[
                  {
                    active: selectedMethod === 'existing',
                    icon: 'list',
                    label: tc.contractListsReplaceLabel,
                    labelSub: tc.contractListsReplaceLabelSub,
                    onClick: () => {
                      setSelectedMethod('existing');
                    },
                    type: 'button',
                  },
                  {
                    active: selectedMethod === 'new',
                    icon: 'list',
                    label: tc.contractListsNewLabel,
                    labelSub: 'Skapa en ny avtalslista',
                    onClick: () => {
                      setSelectedMethod('new');
                    },
                    type: 'button',
                  },
                ]}
                type="large"
              />
            </div>
          )}
          {selectedMethod === 'existing' ? (
            contracts.length > 0 ? (
              <div className="leadsUploadWrapper__leadsUpload__content__lists">
                <div className="leadsUploadWrapper__leadsUpload__content__lists__header">
                  <h5>{'Välj en avtalslista att skriva över'}</h5>
                </div>
                {contracts.map((contract) => (
                  <div
                    className="leadsUploadWrapper__leadsUpload__content__lists__list"
                    key={contract.id}
                  >
                    <NavLink to={`/lista/${contract.id}`}>
                      {contract.name}
                    </NavLink>
                    <div
                      className="leadsUploadWrapper__leadsUpload__content__lists__list__checkbox"
                      onClick={() => {
                        if (selectedContractList === contract.id) {
                          setSelectedContractList('');
                          return;
                        }
                        setContractListName(contract.name);
                        setSelectedContractList(contract.id);
                      }}
                    >
                      {selectedContractList === contract.id ? (
                        <Icon val="check" />
                      ) : (
                        <Icon val="checkbox" />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <Info>
                <p style={{ margin: 0 }}>
                  {tc.contractListsToReplaceUnavailable}
                </p>
              </Info>
            )
          ) : null}
          {selectedMethod === 'new' && (
            <div className="leadsUploadWrapper__leadsUpload__content__new">
              <div className="leadsUploadWrapper__leadsUpload__content__header">
                <h5>{'Ange ett namn för din avtalslista'}</h5>
              </div>
              <div className="leadsUploadWrapper__leadsUpload__content__input">
                <input
                  type="text"
                  value={contractListName}
                  onChange={(e) => setContractListName(e.target.value)}
                />
              </div>
            </div>
          )}
          <WidgetFooter
            buttonOneText={'Ladda upp avtalskunder'}
            buttonOneFunc={_handleFileUploadClick}
            buttonTwoFunc={() => {
              if (typeof props.close === 'function') {
                props.close();
              }
            }}
            buttonTwoText={tc.cancel}
            disableButtonOne={
              !selectedMethod ||
              (selectedMethod === 'new' && contractListName === '') ||
              (selectedMethod === 'existing' && !selectedContractList)
            }
          />
        </>
      )}
    </div>
  );
};

export default AvtalskunderUploadUploader;
