import React, { useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import LanguageContext from 'language-context';
import CompanyHelper from 'shared_helpers/company_helper';
import { miscHelper } from 'helpers';
import _ from 'underscore';
import {
  createGroup,
  removeGroup,
  searchStore,
  shareGroup,
  updateGroup,
} from 'store/prospect/tasks';
import { showFlashMessage } from 'store/flash_messages/tasks';
import ActionButton from 'components/action_button';
import ColleaguesRows from 'components/colleagues_rows';
import Icon from 'components/icon';
import Info from 'components/info';
import Loading from 'components/loading';
import ItemWithButtons from 'components/item_with_buttons';
import WidgetFooter from 'components/widget_footer';
import { debouncedCompanyDealers } from 'store/company/tasks';

/**
 * Group component.
 *
 * At this point this component handles userIds groups that uses a text input field,
 * and dealer groups that uses an input search field.
 * New group types will probably be added.
 *
 * @param state.props.groupId - string
 */
const ProspectGroups = (state) => {
  const [data, setData] = useState(null); // Groups.
  const [input, setInput] = useState(null); // Values input array. Only holds data values strings, like orgnrs.
  const [invalidInput, setInvalidInput] = useState(null); // Faulty input values array, so we can display these. Only hold strings.
  const [groupToAdd, setGroupToAdd] = useState(null);
  const [groupToEdit, setGroupToEdit] = useState(null);
  const [groupToRemove, setGroupToRemove] = useState(null);
  const [groupToShare, setGroupToShare] = useState(null);
  const [showSearchDropdown, setShowSearchDropdown] = useState(false);
  const [searchResult, setSearchResult] = useState(null);
  const [users, setUsers] = useState(false);
  const [validInput, setValidInput] = useState(null); // Valid values input array. Hold objects with 'name' and 'variables' properties. The 'variables' values is what ends up in group data when group is saved.
  const nameInputRef = useRef(null);
  const searchDropdownRef = useRef(null);
  const valuesInputRef = useRef(null);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    switch (state.props.groupId) {
      case 'userIds':
        setData(state.prospect.groups?.userIds);
        break;
      case 'dealer':
        setData(state.prospect.groups?.dealer);
        break;
      default:
        setData(null);
    }
  }, [state.props.groupId, state.prospect.groups]);

  useEffect(() => {
    /**
     * When clicking outside search suggestions box, close it.
     */
    const _unmountSearchDropdown = (e) => {
      if (miscHelper.clickUnmount(e, searchDropdownRef, true)) {
        setShowSearchDropdown(false);
      }
    };

    window.addEventListener('mousedown', _unmountSearchDropdown);
    return () =>
      window.removeEventListener('mousedown', _unmountSearchDropdown);
  }, []);

  const _initiateInputOnEdit = (data) => {
    setInput(data.map((num) => num.variables));
    setValidInput(
      data.map((num) => {
        if (!num.name) {
          num.name = num.variables;
        }

        return num;
      })
    );
  };

  const _onInputChange = async (query) => {
    // Set data input.
    switch (state.props.groupId) {
      case 'userIds':
        const input = valuesInputRef.current.value
          ? valuesInputRef.current.value
              .replace(/\s/g, '')
              .split(',')
              .filter((num) => num)
          : [];

        let invalid = [];
        let valid = [];
        input.forEach((num) => {
          // Sort valid orgnrs, we can only save these since user cannot know TS user ids for private persons.
          CompanyHelper.isValidOrgNr(num) ? valid.push(num) : invalid.push(num);
        });

        // Remove duplicates.
        invalid = _.uniq(invalid);
        valid = _.uniq(valid);

        setInput(valuesInputRef.current.value.split(','));
        setInvalidInput(invalid.length ? invalid : null);
        setValidInput(
          valid.length
            ? valid.map((num) => {
                return {
                  name: num,
                  variables: num,
                };
              })
            : null
        );

        break;
      case 'dealer':
        if (typeof query === 'string') {
          const result = await debouncedCompanyDealers({ q: query });
          setSearchResult(result);
          setShowSearchDropdown(!!result.length);
        }

        break;
      default:
        return;
    }

    // Set name input.
    if (groupToAdd) {
      setGroupToAdd({
        ...groupToAdd,
        name: nameInputRef.current.value,
      });
    } else if (groupToEdit && !groupToAdd) {
      setGroupToEdit({
        ...groupToEdit,
        name: nameInputRef.current.value,
      });
    }
  };

  const _renderFormFooter = () => {
    return (
      <div className="prospectGroupsWrapper__prospectGroups__content__left__formHolder__footer">
        <ActionButton
          label={tc.cancel}
          onClick={() => {
            _reset();
          }}
          type="regular"
        />
        <ActionButton
          label={tc.save}
          onClick={() => {
            let dataToSave = validInput
              ? validInput.map((num) => num.variables)
              : null;

            if (groupToAdd) {
              let payload = groupToAdd;
              payload.data = dataToSave;

              if (
                !payload.name ||
                !payload.name?.length ||
                !payload.data?.length
              ) {
                showFlashMessage(tc.formMissingInput, 'info');
              } else {
                createGroup(payload);
                _reset();
              }
            } else if (groupToEdit && !groupToAdd) {
              let payload = groupToEdit;
              payload.data = dataToSave;

              if (
                !payload.name ||
                !payload.name?.length ||
                !payload.data.length
              ) {
                showFlashMessage(tc.formMissingInput, 'info');
              } else {
                updateGroup(payload);
                _reset();
              }
            }
          }}
          type="highlight"
        />
      </div>
    );
  };

  const _renderForm = () => {
    const suggestionRows = !searchResult
      ? null
      : searchResult.map((num) => {
          const selected = validInput
            ? !!validInput.find((x) => num.val === x.variables)
            : false;

          return (
            <div
              className="prospectGroupsWrapper__prospectGroups__content__left__formHolder__content__searchWrapper__searchHolder__suggestionsHolder__itemWrapper"
              key={num.text}
              onClick={() => {
                let newData;
                if (selected) {
                  newData = validInput.filter((x) => x.variables !== num.val);
                } else {
                  newData = validInput
                    ? validInput.concat([
                        {
                          name: num.text,
                          variables: num.val,
                        },
                      ])
                    : [
                        {
                          name: num.text,
                          variables: num.val,
                        },
                      ];
                }

                setValidInput(newData);
              }}
            >
              <div className="prospectGroupsWrapper__prospectGroups__content__left__formHolder__content__searchWrapper__searchHolder__suggestionsHolder__itemWrapper__item">
                <div className="prospectGroupsWrapper__prospectGroups__content__left__formHolder__content__searchWrapper__searchHolder__suggestionsHolder__itemWrapper__item__left">
                  {num.text}
                </div>
                <div className="prospectGroupsWrapper__prospectGroups__content__left__formHolder__content__searchWrapper__searchHolder__suggestionsHolder__itemWrapper__item__right">
                  <Icon val={selected ? 'check' : 'checkbox'} />
                </div>
              </div>
            </div>
          );
        });

    let chips;
    if (validInput?.length) {
      chips = validInput
        .sort((a, b) => {
          if (!a.name || !b.name) {
            return 0;
          }

          return a.name.localeCompare(b.name, 'sv'); // Sort alphabetically.
        })
        .map((num) => {
          return (
            <div
              className="prospectGroupsWrapper__prospectGroups__content__left__formHolder__content__chipsHolder__chips__chip"
              key={num.name}
            >
              <div className="prospectGroupsWrapper__prospectGroups__content__left__formHolder__content__chipsHolder__chips__chip__left">
                {num.name}
              </div>
              <div className="prospectGroupsWrapper__prospectGroups__content__left__formHolder__content__chipsHolder__chips__chip__right">
                <Icon
                  val="clear"
                  onClick={() => {
                    let newData;
                    newData = validInput.filter(
                      (x) => x.variables !== num.variables
                    ); // Remove from valid data.
                    setValidInput(newData);
                    let newInput = input
                      ? input.filter(
                          (x) =>
                            x.toString().trim() !==
                            num.variables.toString().trim()
                        )
                      : null; // Also remove from input field.

                    setInput(newInput);
                  }}
                />
              </div>
            </div>
          );
        });
    }

    return (
      <div className="prospectGroupsWrapper__prospectGroups__content__left__formHolder">
        <div className="prospectGroupsWrapper__prospectGroups__content__left__formHolder__content">
          <div className="prospectGroupsWrapper__prospectGroups__content__left__formHolder__content__name">
            <span className="label">{tc.name}:</span>
            <input
              onChange={_onInputChange}
              ref={nameInputRef}
              type="text"
              placeholder={tc.nameGroup}
              value={groupToEdit ? groupToEdit.name : groupToAdd.name}
            />
          </div>
          {state.props.groupId === 'userIds' ? (
            <>
              <div className="prospectGroupsWrapper__prospectGroups__content__left__formHolder__content__values">
                <span className="label">{tc.values}:</span>
                <input
                  placeholder={tc.groupsUserIdsHowTo1}
                  ref={valuesInputRef}
                  onChange={_onInputChange}
                  type="text"
                  value={input ? input : ''}
                />
              </div>
              <Info>
                <h4>{tc.tip}</h4>
                <p>
                  {tc.groupsUserIdsHowTo2}
                  <a
                    href="https://convert.town/column-to-comma-separated-list"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {tc.thisLink.toLowerCase()}
                  </a>
                </p>
              </Info>
            </>
          ) : (
            <div className="prospectGroupsWrapper__prospectGroups__content__left__formHolder__content__searchWrapper">
              <span className="label">{tc.search}:</span>
              <div
                className={
                  showSearchDropdown
                    ? 'prospectGroupsWrapper__prospectGroups__content__left__formHolder__content__searchWrapper__searchHolder active'
                    : 'prospectGroupsWrapper__prospectGroups__content__left__formHolder__content__searchWrapper__searchHolder'
                }
              >
                <input
                  placeholder={tc.groupsDealerHowTo}
                  onChange={(e) => {
                    _onInputChange(e.target.value);
                  }}
                  type="search"
                />
                {showSearchDropdown ? (
                  <div
                    className="prospectGroupsWrapper__prospectGroups__content__left__formHolder__content__searchWrapper__searchHolder__suggestionsHolder"
                    ref={searchDropdownRef}
                  >
                    {suggestionRows}
                  </div>
                ) : null}
              </div>
            </div>
          )}
          {chips ? (
            <div
              className={
                showSearchDropdown
                  ? 'prospectGroupsWrapper__prospectGroups__content__left__formHolder__content__chipsHolder fill'
                  : 'prospectGroupsWrapper__prospectGroups__content__left__formHolder__content__chipsHolder'
              }
            >
              <span className="label">{tc.values}:</span>
              <div className="prospectGroupsWrapper__prospectGroups__content__left__formHolder__content__chipsHolder__chips">
                {chips}
              </div>
            </div>
          ) : null}
          {invalidInput ? (
            <div className="prospectGroupsWrapper__prospectGroups__content__left__formHolder__content__invalid">
              <div className="prospectGroupsWrapper__prospectGroups__content__left__formHolder__content__invalid__info">
                {tc.invalidValuesWillNotBeSaved}:
              </div>
              <div className="prospectGroupsWrapper__prospectGroups__content__left__formHolder__content__invalid__values">
                {invalidInput.map((num, i) => {
                  return i < invalidInput.length - 1 ? (
                    <span key={num}>{num}, </span>
                  ) : (
                    <span key={num}>{num}</span>
                  );
                })}
              </div>
            </div>
          ) : null}
        </div>
        {_renderFormFooter()}
      </div>
    );
  };

  const _renderGroups = () => {
    return data.map((group) => {
      return (
        <div
          className={
            groupToEdit || groupToAdd
              ? 'prospectGroupsWrapper__prospectGroups__content__right__group editMode'
              : 'prospectGroupsWrapper__prospectGroups__content__right__group'
          }
          key={group._id}
        >
          <ItemWithButtons
            buttons={[
              {
                disabled: !!group.dealerId,
                icon: 'edit',
                onClick: () => {
                  if (!groupToAdd && !group.dealerId) {
                    // Groups on dealer level cannot be edited by users (these are created by Bilprospekt on request).
                    setGroupToEdit(group);
                    _initiateInputOnEdit(group.data);
                  }
                },
                tooltip: tc.editGroup,
              },
              {
                disabled: !!group.dealerId,
                icon: 'share',
                onClick: () => {
                  setGroupToShare(group);
                },
                tooltip: tc.shareGroup,
              },
              {
                disabled: !!group.dealerId,
                icon: 'remove',
                onClick: () => {
                  if (!group.dealerId) {
                    // Groups on dealer level cannot be removed by users (these are created by Bilprospekt on request).
                    setGroupToRemove(group);
                  }
                },
                tooltip: tc.removeGroup,
              },
            ]}
            icon="prospectGroup"
            name={group.name}
            label={tc.prospectGroup}
          />
        </div>
      );
    });
  };

  const _reset = () => {
    setGroupToAdd(null);
    setGroupToEdit(null);
    setInput(null);
    setInvalidInput(null);
    setSearchResult(null);
    setShowSearchDropdown(null);
    setValidInput(null);
  };

  const _stateCheck = () => {
    return !!(data && state.user.info);
  };

  return (
    <div className="prospectGroupsWrapper">
      <div className="prospectGroupsWrapper__prospectGroups">
        {_stateCheck() ? (
          <div className="prospectGroupsWrapper__prospectGroups__content">
            {groupToAdd || groupToEdit ? (
              <div className="prospectGroupsWrapper__prospectGroups__content__left">
                {_renderForm()}
              </div>
            ) : null}
            <div className="prospectGroupsWrapper__prospectGroups__content__right">
              {!groupToAdd && !groupToEdit && data.length ? (
                <div className="prospectGroupsWrapper__prospectGroups__content__right__row">
                  <p>
                    {state.props.groupId === 'dealer'
                      ? tc.groupsDealerInfo
                      : state.props.groupId === 'userIds'
                      ? tc.groupsUserIdsInfo
                      : null}
                  </p>
                  <div
                    className="buttonLinkStyledWithIcon marginBottomMedium marginTopMedium"
                    onClick={() => {
                      if (!groupToEdit) {
                        setGroupToAdd({
                          data: [],
                          name: '',
                          groupId: state.props.groupId,
                        });
                        setGroupToEdit(null);
                        setGroupToRemove(null);
                        setGroupToShare(null);
                      }
                    }}
                  >
                    <Icon val="add" />
                    <span>{tc.addGroup}</span>
                  </div>
                </div>
              ) : null}
              {data.length ? (
                groupToShare ? (
                  <div>
                    <p>
                      {tc.shareGroup}: <strong>{groupToShare.name}</strong>
                    </p>
                    <p className="marginBottomMedium">{tc.shareGroupInfo}</p>
                    <ColleaguesRows
                      onChange={(arr) => {
                        setUsers(arr);
                      }}
                    />
                    <WidgetFooter
                      buttonOneText={tc.shareGroup}
                      buttonOneFunc={async () => {
                        await shareGroup({
                          id: groupToShare._id,
                          users: users.map((num) => num.id),
                        });
                        setGroupToShare(null);
                      }}
                      buttonTwoText={tc.cancel}
                      buttonTwoFunc={() => {
                        setGroupToShare(null);
                      }}
                    />
                  </div>
                ) : groupToRemove ? (
                  <div className="inputHolder noMargin">
                    <div className="inputHolder__left">
                      {tc.removeGroup}: <strong>{groupToRemove.name}</strong>
                    </div>
                    <div className="inputHolder__right">
                      <ActionButton
                        label={tc.cancel}
                        onClick={() => {
                          setGroupToRemove(null);
                        }}
                        type="regular"
                      />
                      <ActionButton
                        label={tc.remove}
                        onClick={async () => {
                          await removeGroup({
                            id: groupToRemove._id,
                          });
                          setGroupToRemove(null);
                        }}
                        type="highlight"
                      />
                    </div>
                  </div>
                ) : (
                  _renderGroups()
                )
              ) : !groupToAdd ? (
                <Info>
                  <h4>{tc.groupsMissing}</h4>
                  <p>
                    {state.props.groupId === 'dealer'
                      ? tc.groupsDealerInfo
                      : state.props.groupId === 'userIds'
                      ? tc.groupsUserIdsInfo
                      : tc.groupsMissing}
                  </p>
                  <div
                    className="buttonLinkStyledWithIcon"
                    onClick={() => {
                      if (!groupToEdit) {
                        setGroupToAdd({
                          data: [],
                          name: '',
                          groupId: state.props.groupId,
                        });
                      }
                    }}
                  >
                    <Icon val="add" />
                    <span>{tc.addGroup}</span>
                  </div>
                </Info>
              ) : null}
            </div>
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

const MapStateToProps = (state, props) => {
  return {
    props: props,
    prospect: state.prospect,
    user: state.user,
  };
};

export default connect(MapStateToProps)(ProspectGroups);
