import { store } from "store";
import { flashMessagesActionTypes } from "./actions";

/**
 * Show a flash message.
 *
 * @param message - string
 * @param type - "info" | "success" | "fail"
 */
export const showFlashMessage = (message, type = "info") => {
  store.dispatch({
    type: flashMessagesActionTypes.SET_MESSAGE,
    payload: {
      message: message,
      type: type,
    },
  });

  store.dispatch({
    type: flashMessagesActionTypes.SET_SHOW_MESSAGE,
    payload: true,
  });

  return setTimeout(() => {
    store.dispatch({
      type: flashMessagesActionTypes.SET_SHOW_MESSAGE,
      payload: false,
    });
  }, 4900); // Slightly less time value than what's used in css animation for flash_messages.
};
