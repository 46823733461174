import React, { useContext, useState } from 'react';
import LanguageContext from 'language-context';
import { createListOrder } from 'store/orders/tasks';
import Info from 'components/info';
import { connect } from 'react-redux';
import Loading from 'components/loading';
import WidgetFooter from 'components/widget_footer';
import WidgetHeader from 'components/widget_header';
import OrdersOrderHubspotOrder from './orders_order_hubspot_order';
import OrdersOrderHubspotPrice from './orders_order_hubspot_price';
import { miscHelper } from 'helpers';

const OrdersHubspot = (state) => {
  const { props } = state;
  const { list } = props;
  const hubspotIsPending = list?.orderHistory?.hubspot?.isPending;
  const tc = useContext(LanguageContext);

  const [isOrdering, setIsOrdering] = useState(false);
  const [orderSettings, setOrderSettings] = useState({
    name: false,
    phone: false,
  });

  const nameIsPending = list?.orderHistory?.name?.isPending;
  const nameIsFresh =
    list?.orderHistory?.name?.isDelivered &&
    list?.orderHistory?.name?.isAvailable;
  const phoneIsPending = list?.orderHistory?.phone?.isPending;
  const phoneIsFresh =
    list?.orderHistory?.phone?.isDelivered &&
    list?.orderHistory?.phone?.isAvailable;

  const orderIsPending = miscHelper.hasPendingOrder(list);

  const nameCheckboxStatus = nameIsFresh
    ? 'check'
    : nameIsPending
    ? 'clear'
    : orderSettings.name
    ? 'check'
    : 'checkbox';

  const phoneCheckboxStatus = phoneIsFresh
    ? 'check'
    : phoneIsPending
    ? 'clear'
    : orderSettings.phone && (orderSettings.name || nameIsFresh)
    ? 'check'
    : orderSettings.name || nameIsFresh
    ? 'checkbox'
    : 'clear';

  const getNameCheckboxLabel = () => {
    if (nameIsFresh) return tc.hubspotNameIsFresh;
    if (nameIsPending) return tc.hubspotNameIsPending;
    else return tc.hubspotOrderName;
  };
  const getPhoneCheckboxLabel = () => {
    if (phoneIsFresh) return tc.hubspotPhoneIsFresh;
    if (phoneIsPending) return tc.hubspotPhoneIsPending;
    else return tc.hubspotOrderPhoneNumber;
  };

  const componentProps = {
    orderSettings,
    setOrderSettings,
    nameCheckboxStatus,
    nameCheckboxLabel: getNameCheckboxLabel(),
    phoneCheckboxStatus,
    phoneCheckboxLabel: getPhoneCheckboxLabel(),
    nameIsFresh,
  };

  return props.list && props.list.prospectInformation ? (
    <div className="ordersInformationWrapper">
      <div className="ordersInformationWrapper__ordersInformation">
        <div className="ordersInformationWrapper__ordersInformation__content">
          {!orderIsPending ? (
            <div className="ordersInformationWrapper__ordersInformation__content__section">
              <div className="ordersInformationWrapper__ordersInformation__content__section__title">
                <WidgetHeader headline={tc.hubspotOrderLeads} />
              </div>
              <div className="ordersInformationWrapper__ordersInformation__content__section__block">
                <span className="label">{tc.hubspotInformation}</span>
              </div>
              <div className="ordersInformationWrapper__ordersInformation__content__section__block">
                <span className="label">{tc.hubspotInformationAdditional}</span>
                <p>{tc.hubspotOnlyCriteriaLists}</p>
              </div>
              <OrdersOrderHubspotOrder {...componentProps} />
              <div className="ordersInformationWrapper__ordersInformation__content__section__block">
                <OrdersOrderHubspotPrice
                  privatePersons={list?.prospectInformation?.privatePersons}
                  companies={list?.prospectInformation?.companies}
                  orderSettings={orderSettings}
                />
              </div>
            </div>
          ) : (
            <>
              {orderIsPending ? (
                <>
                  <Info>
                    <h4>{tc.orderIsPending}</h4>
                    <p>{tc.cannotOrderIsPending}</p>
                    {hubspotIsPending ? (
                      <p>{tc.hubspotOrderIsPending}</p>
                    ) : null}
                    {nameIsPending ? <p>{tc.hubspotNameIsPending}</p> : null}
                    {phoneIsPending ? <p>{tc.hubspotPhoneIsPending}</p> : null}
                  </Info>
                </>
              ) : null}
            </>
          )}
        </div>
        {!hubspotIsPending ? (
          <div className="ordersInformationWrapper__ordersInformation__footer">
            <div className="minHeight40">
              {isOrdering ? (
                <>
                  <p>{tc.creatingOrder}...</p>
                  {isOrdering ? <Loading small={true} /> : null}
                </>
              ) : null}
            </div>
            <WidgetFooter
              buttonOneFunc={async () => {
                setIsOrdering(true);
                await createListOrder({
                  orderHubspot: true,
                  orderName: orderSettings.name ? true : false,
                  orderPhone: orderSettings.phone ? true : false,
                });
              }}
              disableButtonOne={
                isOrdering || orderIsPending || !props.list?.meta?.criterias
              }
              buttonOneText={tc.placeOrder}
              noBorder={true}
            />
          </div>
        ) : null}
      </div>
    </div>
  ) : (
    <Loading />
  );
};

const MapStateToProps = (state, props) => {
  return {
    orders: state.orders,
    pdf: state.pdf,
    props: props,
  };
};

export default connect(MapStateToProps)(OrdersHubspot);
