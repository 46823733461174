import { store } from "store";
import { request } from "helpers";
import { koncernActionTypes } from "./actions";
import { showFlashMessage } from "store/flash_messages/tasks";
import { getResponsible, setResponsibility } from 'store/company/tasks';
import { event } from "tracking";
import * as text from "text-content";

export const getKoncern = async (payload) => {
  try {

    if (!payload || (payload && !payload.id)) {
      throw new Error("Missing params in getKoncern");
    }

    store.dispatch({
      type: koncernActionTypes.RESET_KONCERN_DATA,
      payload: null,
    });

    const data = await request({
      method: 'GET',
      url: `/api/koncern/refactor/data/${payload.id}`
    });

    store.dispatch({
      type: koncernActionTypes.SET_KONCERN_DATA,
      payload: data,
    });

    return data;
  } catch (err) {
    console.log('Error in getKoncern: ', err);
  }
}

export const getKoncernResponsible = async (payload) => {
  try {
    if (!payload || (payload && !payload.id)) {
      throw new Error("Missing params in getKoncernResponsible");
    }

    store.dispatch({
      type: koncernActionTypes.SET_KONCERN_RESPONSIBLE,
      payload: null,
    });

    const data = await getResponsible({ id: payload.id })

    store.dispatch({
      type: koncernActionTypes.SET_KONCERN_RESPONSIBLE,
      payload: data,
    });
  } catch (err) {
    console.log('Error in getKoncernResponsible: ', err);
  }
}

export const setKoncernResponsible = async (payload) => {
  try {
    if (!payload.entityId || !payload.responsibleUserId) {
      throw new Error("Missing params in setKoncernResponsible");
    }

    store.dispatch({
      type: koncernActionTypes.SET_KONCERN_RESPONSIBLE,
      payload: null,
    });

    await setResponsibility(payload);

    const data = await getResponsible({ id: payload.entityId })

    store.dispatch({
      type: koncernActionTypes.SET_KONCERN_RESPONSIBLE,
      payload: data,
    });

  } catch (err) {
    console.log('Error in getKoncernResponsible: ', err);
  }
}

export const getKoncernStructure = async (payload) => {
  try {
    if (!payload || (payload && !payload.id)) {
      throw new Error("Missing params in getKoncernStructure");
    }

    store.dispatch({
      type: koncernActionTypes.RESET_KONCERN_STRUCTURE,
    });

    const data = await request({
      method: 'GET',
      url: `/api/koncern/refactor/structure/${payload.id}`
    });

    store.dispatch({
      type: koncernActionTypes.SET_KONCERN_STRUCTURE,
      payload: data,
    });

  } catch (err) {
    console.log('Error in getKoncernStructure: ', err);
  }
}

export const getKoncernDeals = async (payload) => {
  try {
    if (!payload || (payload && !payload.id)) {
      throw new Error("Missing params in getKoncernResponsible");
    }

    store.dispatch({
      type: koncernActionTypes.SET_KONCERN_DEALS,
      payload: null,
    });

    const data = await request({
      method: 'GET',
      url: `/api/koncern/refactor/deals/${payload.id}`
    });

    store.dispatch({
      type: koncernActionTypes.SET_KONCERN_DEALS,
      payload: data,
    });
  } catch (err) {
    console.log('Error in getKoncernResponsible: ', err);
  }
}

export const getBasicKoncernInfo = async (payload) => {
  try {
    if (!payload || (payload && !payload.id)) {
      throw new Error("Missing params in getBasicKoncernInfo");
    }

    store.dispatch({
      type: koncernActionTypes.SET_KONCERN_BASIC_INFO,
      payload: null,
    });

    const data = await request({
      method: 'GET',
      url: `/api/koncern/refactor/koncern-info/${payload.id}`
    });

    store.dispatch({
      type: koncernActionTypes.SET_KONCERN_BASIC_INFO,
      payload: data,
    });

    return data;
  } catch (err) {
    console.log('Error in getBasicKoncernInfo: ', err);
  }
}

export const toggleShowEmpty = (payload) => {
  try {
    const toggleVisibility = (obj) => {
      const newObj = {}

      for (const prop in obj) {
        if (prop === 'visible' && obj.is_filial === 0) {
          const fleetCount = Number(obj.fleet.total) > 0 || Number(obj.childFleet.total) > 0

          if (!fleetCount) {
            newObj[prop] = payload.isVisible;
          } else {
            newObj[prop] = obj[prop];
          }
        } else if (prop === 'children') {
          if (Array.isArray(obj[prop])) {
            newObj[prop] = obj[prop].map((child) => toggleVisibility(child))
          }
        } else {
          newObj[prop] = obj[prop];
        }
      }

      return newObj
    }

    const data = toggleVisibility(payload.obj)

    store.dispatch({
      type: koncernActionTypes.TOGGLE_SHOW_EMPTY,
      payload: data,
    });
  } catch (err) {
    console.log('Error in getKoncernResponsible: ', err);
  }
}
