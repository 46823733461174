import React, { useContext, useEffect, useState, useRef } from 'react';
import LanguageContext from 'language-context';
import { getSharedDealStats } from 'store/activity/tasks';
import { Table } from 'components/table';
import Info from 'components/info';
import { widgetHelper } from 'helpers/widget_helper';

const ActivityDealsContent = ({
  minimize,
  filter,
  responsiveClassWidth,
  user,
  widget,
  rowsPerPage,
}) => {
  const tc = useContext(LanguageContext);

  const loadRef = useRef(false);

  const [data, setData] = useState([]);

  const columns = [
    {
      id: 'userName',
      visible: true,
      numeric: false,
      label: tc.user,
    },
    { id: 'dealAmount', visible: true, numeric: true, label: tc.numberOfDeals },
    {
      id: 'processingRate',
      visible: true,
      numeric: false,
      label: tc.processingRate,
    },
    {
      id: 'successRate',
      visible: true,
      numeric: false,
      label: tc.conversionRate,
    },
  ];

  useEffect(() => {
    const fetchData = async (date, userIds) => {
      loadRef.current = true;
      const stats = await getSharedDealStats({
        date,
        userIds,
      });

      setData(
        stats.map((userData) => {
          const { userName, dealAmount, processingRate, successRate } =
            userData;
          return {
            userName,
            dealAmount,
            processingRate: `${processingRate}%`,
            successRate: `${successRate}%`,
          };
        })
      );
      loadRef.current = false;
    };

    if (!loadRef.current) {
      if (widget) {
        fetchData(
          widgetHelper.parseWidgetDate(widget.data),
          widget.data?.users
        );
      } else {
        if (filter) {
          fetchData(filter.date, filter.users);
        }
      }
    }
  }, [filter, widget]);

  return (
    <div
      className={`activitiesWrapper__activities__content cancelDrag ${responsiveClassWidth} ${
        minimize ? 'minimize' : ''
      }`}
    >
      {data.length > 0 ? (
        <Table columns={columns} rows={data} rowsPerPage={rowsPerPage || 25} />
      ) : (
        <Info>
          <p>{tc.noListsMatch}</p>
        </Info>
      )}
    </div>
  );
};

export default ActivityDealsContent;
