import React, { useContext } from "react";
import LanguageContext from "language-context";
import { connect } from "react-redux";
import { setLeadsUploadChoosenColumns } from "store/leads/tasks";
import {
  InputLabel,
  FormControl,
  NativeSelect,
  FormHelperText,
} from "@mui/material";
import { agileHelper } from "helpers";

const LeadsUploadSelector = ({ leads, props }) => {
  const { leadsUploadChoosenColumns } = leads.upload;
  const { index } = props;
  const tc = useContext(LanguageContext);

  // We add firstname and surname temporary here, users might have this in different columns.
  // We put these together as 'name' in backend before saving.
  let columnNameChoices = agileHelper.getLeadsUploadColumns.concat([
    "firstName",
    "surName",
  ]);
  columnNameChoices = columnNameChoices
    .map((prop) => tc[prop])
    .sort((a, b) => {
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    });

  const handleChange = (e) => {
    setLeadsUploadChoosenColumns({ value: e.target.value, index });
  };

  return (
    <FormControl variant="filled">
      <InputLabel shrink htmlFor="filled-age-native-simple">
        {tc.matchColumn}
      </InputLabel>
      <NativeSelect
        value={leadsUploadChoosenColumns[index]}
        onChange={handleChange}
        inputProps={{
          name: "kolumnnamn",
          id: "filled-age-native-simple",
        }}
      >
        <option aria-label={tc.dontChoose} value={""} />
        {columnNameChoices.map((name, index) => (
          <option
            value={name}
            key={name ? "option" + name : "option" + index}
            disabled={leadsUploadChoosenColumns.includes(name)}
          >
            {name}
          </option>
        ))}
      </NativeSelect>
      <FormHelperText
        className={
          leadsUploadChoosenColumns[index] ? "selected" : "not-selected"
        }
      >
        {leadsUploadChoosenColumns[index] ? tc.saved : tc.notSaved}
      </FormHelperText>
    </FormControl>
  );
};

const MapStateToProps = (state, props) => {
  return {
    leads: state.leads,
    props: props,
  };
};

export default connect(MapStateToProps)(LeadsUploadSelector);
