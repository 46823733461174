import React, { useContext, useEffect, useRef, useState } from 'react';
import LanguageContext from 'language-context';
import history from 'router-history';
import { connect } from 'react-redux';
import { activityHelper } from 'helpers';
import {
  getPipelineSubscriptionData,
  resetActivityFilter,
  setActivityFilter,
} from 'store/activity/tasks';
import { updateWidgets } from 'store/widgets/tasks';
import FilterBox from 'components/filter_box';
import Icon from 'components/icon';
import Loading from 'components/loading';
import SaveActivityWidget from 'components/save_activity_widget';
import WidgetHeader from 'components/widget_header';
import { getListsSubscriptions } from 'store/lists/tasks';

/**
 * Render a pipeline component.
 * Can be used with props data, or as widget where we retrieve data for that specific widget id.
 *
 * @param state.props.data - object - Data as props when not used as widget.
 * @param state.props.widget - object - Widget object from dashboard, when used as widget.
 */
const ActivityPipeline = (state) => {
  const [data, setData] = useState(null);
  const [minimize, setMinimize] = useState(false);
  const [responsiveClassWidth, setResponsiveClassWidth] = useState('');
  const [showAddToDashboard, setShowAddToDashboard] = useState(false);
  const activityPipeline = useRef(null);
  const observer = useRef(null);
  const tc = useContext(LanguageContext);

  const { widget } = state.props;
  const fetchedSubsRef = useRef(false);
  useEffect(() => {
    if (widget?.data?.subscriptions?.length < 1) return;
    if (fetchedSubsRef.current === true) return;
    else {
      getListsSubscriptions();
      fetchedSubsRef.current = true;
    }
  }, [widget?.data]);

  useEffect(() => {
    _setResponsiveClass();
    observer.current = new ResizeObserver(_setResponsiveClass);
    observer.current.observe(activityPipeline.current);

    const ref = activityPipeline.current;

    return () => observer.current.unobserve(ref);
  }, []);

  useEffect(() => {
    if (
      state.props?.widget?.dashboards?.dashboard &&
      state.props.widget.dashboards.dashboard.hasOwnProperty('minimized')
    ) {
      setMinimize(state.props.widget.dashboards.dashboard.minimized);
    }
  }, [state.props.widget]);

  useEffect(() => {
    // If we already have data for subscription in state, widgets have probably only moved position, so no backend call necessary.
    if (
      state.props.widget?._id &&
      state.props.widget?.data &&
      !state.activity.pipelineSubscriptions.hasOwnProperty(
        state.props.widget._id
      ) &&
      !state.activity.subscriptionsLoading.includes(state.props.widget._id)
    ) {
      const now = new Date();
      getPipelineSubscriptionData({
        id: state.props.widget._id,
        currentWeek: state.props.widget.data?.currentWeek
          ? state.props.widget.data.currentWeek
          : false,
        currentMonth: state.props.widget.data?.currentMonth
          ? state.props.widget.data.currentMonth
          : false,
        previousWeek: state.props.widget.data?.previousWeek
          ? state.props.widget.data.previousWeek
          : false,
        previousMonth: state.props.widget.data?.previousMonth
          ? state.props.widget.data.previousMonth
          : false,
        date: {
          from: state.props.widget.data?.date?.from?.length
            ? state.props.widget.data.date.from
            : new Date(
                now.setFullYear(
                  now.getFullYear() - activityHelper.getDefaultFromYears()
                )
              ).toISOString(), // If no "from" date provided, set to default value.
          to: state.props.widget.data?.date?.to?.length
            ? state.props.widget.data.date.to
            : new Date().toISOString(), // If no "to" date provided, set to today.
        },
        listIds: state.props.widget.data.lists,
        userIds: state.props.widget.data.users,
        subscriptions: state.props.widget.data.subscriptions,
      });
    }
  }, [state.props.widget, state.activity.pipelineSubscriptions]);

  useEffect(() => {
    if (state.props.widget) {
      setData(
        state.activity.subscriptionsLoading.includes(state.props.widget._id)
          ? null
          : state.activity.pipelineSubscriptions[state.props.widget._id]
      );
    } else if (state.props.data) {
      setData(state.props.data);
    }
  }, [
    state.props.widget,
    state.props.data,
    state.activity.pipelineSubscriptions,
  ]);

  const _renderStatuses = () => {
    let statuses = [];

    for (const prop in data) {
      if (prop !== 'all') {
        statuses.push(
          <div
            className="activityPipelineWrapper__activityPipeline__content__top__statuses__item"
            onClick={() => {
              // If component is displayed as a dashboard widget
              // we have certain criterias to set in filter before navigating to inspect.
              if (state.props.widget && state.props.widget.data) {
                resetActivityFilter();
                if (state.props.widget.data.users?.length) {
                  setActivityFilter({
                    action: 'add',
                    type: 'user',
                    val: state.props.widget.data.users,
                  });
                }
                if (state.props.widget.data.lists?.length) {
                  setActivityFilter({
                    action: 'add',
                    type: 'list',
                    val: state.props.widget.data.lists,
                  });
                }
                if (state.props.widget.data.date?.from?.length) {
                  setActivityFilter({
                    type: 'date',
                    val: {
                      from: new Date(state.props.widget.data.date.from),
                      to: state.props.widget.data.date.to?.length
                        ? new Date(state.props.widget.data.date.to)
                        : new Date(),
                    },
                  });
                } else {
                  const now = new Date();
                  setActivityFilter({
                    type: 'date',
                    val: {
                      from: new Date(
                        now.setFullYear(
                          now.getFullYear() -
                            activityHelper.getDefaultFromYears()
                        )
                      ).toISOString(), // If no "from" date provided, set to default value.
                      to: new Date(),
                    },
                  });
                }
              }
              history.push(
                '/bearbeta/aktivitet/' +
                  activityHelper.getPipelinePathFromKey(prop)
              );
            }}
            key={prop}
          >
            <div className="activityPipelineWrapper__activityPipeline__content__top__statuses__item__name">
              {activityHelper.getPipelineTitleFromKey(prop)}
              <Icon val="navigateNext" />
            </div>
            <div className="activityPipelineWrapper__activityPipeline__content__top__statuses__item__values">
              <div className="activityPipelineWrapper__activityPipeline__content__top__statuses__item__values__amount">
                {data[prop].amount}
              </div>
              <div className="activityPipelineWrapper__activityPipeline__content__top__statuses__item__values__percentage">
                {data[prop].percentage} %
              </div>
            </div>
            <div className="activityPipelineWrapper__activityPipeline__content__top__statuses__item__barHolder">
              <div className="activityPipelineWrapper__activityPipeline__content__top__statuses__item__barHolder__bar">
                <div
                  className="activityPipelineWrapper__activityPipeline__content__top__statuses__item__barHolder__bar__dynamic"
                  style={{
                    width:
                      data[prop].percentage === 0
                        ? '1%'
                        : data[prop].percentage.toString() + '%',
                  }}
                />
              </div>
            </div>
          </div>
        );
      }
    }

    return (
      <div className="activityPipelineWrapper__activityPipeline__content__top__statuses">
        {statuses}
      </div>
    );
  };

  const _setResponsiveClass = () => {
    if (!activityPipeline?.current) {
      return;
    }

    const width = activityPipeline.current.getBoundingClientRect().width;
    const breakpoint1 = 360;

    if (width <= breakpoint1) {
      setResponsiveClassWidth('activityPipelineResponsiveWidth1');
    } else if (width > breakpoint1) {
      setResponsiveClassWidth('');
    }
  };

  const _stateCheck = () => {
    return !!(data && Object.keys(data).length);
  };

  return (
    <div
      className={`activityPipelineWrapper ${responsiveClassWidth}`}
      ref={activityPipeline}
    >
      <div className="activityPipelineWrapper__activityPipeline">
        <div className="activityPipelineWrapper__activityPipeline__header">
          <WidgetHeader
            dashboardItems={
              _stateCheck()
                ? [
                    {
                      disabled: !minimize,
                      icon: 'maximize',
                      label: tc.maximize,
                      onClick: () => {
                        setMinimize(false);
                        if (Array.isArray(state.widgets?.data?.widgets)) {
                          updateWidgets({
                            widgets: state.widgets.data.widgets.map((num) => {
                              if (
                                state.props?.widget?._id === num._id &&
                                num?.dashboards?.dashboard
                              ) {
                                num.dashboards.dashboard.minimized = false;
                              }
                              return num;
                            }),
                          });
                        }
                      },
                    },
                    !state.props.widget
                      ? {
                          disabled: state.props.widget,
                          icon: 'dashboard',
                          label: tc.addToDashboard,
                          onClick: () => {
                            setShowAddToDashboard(true);
                          },
                        }
                      : null,
                    {
                      disabled: minimize,
                      icon: 'minimize',
                      label: tc.minimize,
                      onClick: () => {
                        setMinimize(true);
                        if (Array.isArray(state.widgets?.data?.widgets)) {
                          updateWidgets({
                            widgets: state.widgets.data.widgets.map((num) => {
                              if (
                                state.props?.widget?._id === num._id &&
                                num?.dashboards?.dashboard
                              ) {
                                num.dashboards.dashboard.minimized = true;
                              }
                              return num;
                            }),
                          });
                        }
                      },
                    },
                  ]
                : null
            }
            headline={
              state.props?.widget?.name ? state.props.widget.name : tc.pipeline
            }
            headlineSub={
              _stateCheck() ? tc.pipelineInfo : <Loading small={true} />
            }
          />
        </div>
        {_stateCheck() ? (
          <div
            className={`activityPipelineWrapper__activityPipeline__content cancelDrag ${
              minimize ? 'minimize' : ''
            }`}
          >
            <div className="activityPipelineWrapper__activityPipeline__content__top">
              <div
                className="activityPipelineWrapper__activityPipeline__content__top__title"
                onClick={() => {
                  // If component is displayed as a dashboard widget
                  // we have certain criterias to set in filter before navigating to inspect.
                  if (state.props.widget && state.props.widget.data) {
                    resetActivityFilter();
                    if (
                      state.props.widget.data.users &&
                      state.props.widget.data.users.length
                    ) {
                      setActivityFilter({
                        action: 'add',
                        type: 'user',
                        val: state.props.widget.data.users,
                      });
                    }
                    if (
                      state.props.widget.data.lists &&
                      state.props.widget.data.lists.length
                    ) {
                      setActivityFilter({
                        action: 'add',
                        type: 'list',
                        val: state.props.widget.data.lists,
                      });
                    }
                    if (state.props.widget.data.date?.from?.length) {
                      setActivityFilter({
                        type: 'date',
                        val: {
                          from: new Date(state.props.widget.data.date.from),
                          to: state.props.widget.data.date.to?.length
                            ? new Date(state.props.widget.data.date.to)
                            : new Date(),
                        },
                      });
                    } else {
                      const now = new Date();
                      setActivityFilter({
                        type: 'date',
                        val: {
                          from: new Date(
                            now.setFullYear(
                              now.getFullYear() -
                                activityHelper.getDefaultFromYears()
                            )
                          ).toISOString(), // If no "from" date provided, set to default value.
                          to: new Date(),
                        },
                      });
                    }
                  }

                  history.push('/bearbeta/aktivitet/alla');
                }}
              >
                <span>{`${
                  data.all.amount
                } ${tc.dealsForSelectedFilter.toLowerCase()}`}</span>
                <Icon val="navigateNext" />
              </div>
              {_renderStatuses()}
            </div>
            {state.props.widget?.data ? (
              <div className="activityPipelineWrapper__activityPipeline__content__bottom">
                <FilterBox
                  filters={[
                    state.props.widget.data.currentWeek
                      ? {
                          label: tc.currentWeek,
                          type: 'currentWeek',
                        }
                      : null,
                    state.props.widget.data.currentMonth
                      ? {
                          label: tc.currentMonth,
                          type: 'currentMonth',
                        }
                      : null,
                    state.props.widget.data.previousWeek
                      ? {
                          label: tc.previousWeek,
                          type: 'previousWeek',
                        }
                      : null,
                    state.props.widget.data.previousMonth
                      ? {
                          label: tc.previousMonth,
                          type: 'previousMonth',
                        }
                      : null,
                    state.props.widget.data.date &&
                    !state.props.widget.data.currentWeek &&
                    !state.props.widget.data.currentMonth
                      ? {
                          label: tc.date,
                          type: 'date',
                          values: state.props.widget.data.date,
                        }
                      : null,
                    state.props.widget.data.lists?.length
                      ? {
                          label: tc.lists,
                          type: 'lists',
                          values: state.props.widget.data.lists,
                        }
                      : null,
                    state.props.widget.data.users?.length
                      ? {
                          label: tc.users,
                          type: 'users',
                          values: state.props.widget.data.users,
                        }
                      : null,
                    state.props.widget.data.subscriptions?.length
                      ? {
                          label: tc.listsSubscriptions,
                          type: 'subscriptions',
                          values: widget.data.subscriptions.map((subId) => {
                            const subscription = state.subscriptions?.find(
                              (sub) => sub._id === subId
                            );
                            if (subscription) return subscription.prefix_name;
                            else return subId;
                          }),
                        }
                      : null,
                  ]}
                  recreateActivityFilters={true}
                />
              </div>
            ) : null}
          </div>
        ) : null}
        {showAddToDashboard ? (
          <SaveActivityWidget
            close={() => {
              setShowAddToDashboard(false);
            }}
            type="pipeline"
          />
        ) : null}
      </div>
    </div>
  );
};

const MapStateToProps = (state, props) => {
  return {
    activity: state.activity,
    subscriptions: state.lists.listsSubscriptions,
    props: props,
    widgets: state.widgets,
  };
};

export default connect(MapStateToProps)(ActivityPipeline);
