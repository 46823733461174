import { supertempActionTypes } from "./actions";

interface SupertempState {
  subscriptions: object;
  subscriptionsLoading: Array<string>;
}

const initialState: SupertempState = {
  subscriptions: {},
  subscriptionsLoading: [], // Subscription ids that is currently loading data.
};

export const supertempReducer = (state = initialState, action) => {
  switch (action.type) {
    case supertempActionTypes.SUPERTEMP_SET_SUBSCRIPTION_LOADING: {
      const newArr =
        action.payload.type === "remove"
          ? state.subscriptionsLoading.filter(
              (num) => num !== action.payload.id
            )
          : state.subscriptionsLoading.concat(action.payload.id);

      return {
        ...state,
        subscriptionsLoading: newArr,
      };
    }
    case supertempActionTypes.SUPERTEMP_SET_SUPERTEMP_SUBSCRIPTION: {
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          [action.payload.id]: action.payload.data,
        },
      };
    }
    default: {
      return state;
    }
  }
};
