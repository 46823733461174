import React, { useContext, useState, useEffect } from 'react';
import Icon from 'components/icon';
import Activities from 'components/activities';
import languageContext from 'language-context';

const AgileBoardPopupActivities = ({
  hideBlocks,
  setHideBlocks,
  previewData,
}) => {
  const tc = useContext(languageContext);

  const prospectId =
    Array.isArray(previewData?.prospectInformation) &&
    previewData?.prospectInformation[0]?.user_id;
  const isDeal = previewData?.item?.itemType === 'deal';

  const toggleHideBlocks = () => {
    if (hideBlocks.includes('activities')) {
      setHideBlocks(hideBlocks.filter((block) => block !== 'activities'));
    } else {
      setHideBlocks([...hideBlocks, 'activities']);
    }
  };
  return (
    <div className="agileBoardPopupWrapper__agilePopup__content__block">
      <div
        className="agileBoardPopupWrapper__agilePopup__content__block__header"
        onClick={toggleHideBlocks}
      >
        <div className="agileBoardPopupWrapper__agilePopup__content__block__header__title">
          {tc.activity}
          <Icon
            val={hideBlocks.includes('activities') ? 'maximize' : 'minimize'}
            class="agileBoardPopupWrapper__agilePopup__content__block__header__icon"
          />
        </div>
      </div>
      {!hideBlocks.includes('activities') && (
        <div className="agileBoardPopupWrapper__agilePopup__content__block__content">
          {/* <Activities target={previewData?.item?._id} view="embedded" /> */}

          {prospectId && (
            <Activities
              target={isDeal ? previewData.item.id : prospectId}
              targetType={previewData?.item?.itemType}
              view="embedded"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default AgileBoardPopupActivities;
