import React, { useContext } from "react";
import LanguageContext from "language-context";
import { completeEvent } from "store/events/tasks";
import Popup from "components/popup";
import WidgetFooter from "components/widget_footer";
import WidgetHeader from "components/widget_header";

const EventsCompleteEvent = (props) => {
  const tc = useContext(LanguageContext);

  const _completeEvent = async () => {
    completeEvent({ eventId: props.eventId });
    props.close();
  };

  return (
    <Popup close={props.close} size="small">
      <div className="eventsFlowItemPopupWrapper">
        <div className="eventsFlowItemPopupWrapper__eventsFlowItemPopup">
          <div className="eventsFlowItemPopupWrapper__eventsFlowItemPopup__header">
            <WidgetHeader
              headline={`${tc.mark} ${tc[
                props.action
              ].toLowerCase()} ${tc.asComplete.toLowerCase()}`}
            />
          </div>
          <div className="eventsFlowItemPopupWrapper__eventsFlowItemPopup__content">
            {tc.ensure}
          </div>
          <div className="eventsFlowItemPopupWrapper__eventsFlowItemPopup__footer">
            <WidgetFooter
              buttonOneFunc={_completeEvent}
              buttonTwoFunc={props.close}
              buttonTwoText={tc.cancel}
            />
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default EventsCompleteEvent;
