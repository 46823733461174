import React from "react";
import Icon from "components/icon";

/**
 * Returns an icon with a specific style and size.
 *
 * @param props.class - "active" | "disabled | ""fail" | "info" | "link" | "linkDark" | "passed" | "success" | "successFill" | "white" | "withinOneDay" (optional) - This affects the color, defaults to inherit. Note that some of these return same color, but they are still different "types" and we might want to change the color for some classes in the future.
 * @param props.iconVal - string - Icon value.
 * @param props.onClick - func (optional)
 */
const IconStyled = (props) => {
  return (
    <div
      className={`iconStyled ${props.class ? props.class : ""}`}
      onClick={props.onClick}
    >
      <Icon val={props.iconVal} />
    </div>
  );
};

export default IconStyled;
