import React from 'react';
import Icon from 'components/icon';

/**
 * Item for component Dropdown.
 *
 * @param props.active - boolean - Sets active class, and shows check/checkbox.
 * @param props.disabled - boolean
 * @param props.hideCheckbox - boolean (optional) - If we want to hide checkbox.
 * @param props.label - string / element - Usually text to be displayed, but can be JSX element.
 * @param props.onClick - func - Handler for onClick.
 * @param props.iconString - string for icon val.
 */
export const DropdownItem = (props) => {
  return (
    <div
      className={`${
        props.hideCheckbox
          ? 'dropdownNoCheckboxItemWrapper'
          : 'dropdownItemWrapper'
      } ${props.disabled ? 'disabled' : ''} ${props.active ? 'active' : ''}`}
      onClick={props.onClick}
    >
      <div className="dropdownItemWrapper__dropdownItem">
        <div className="dropdownItemWrapper__dropdownItem__label">
          {props.label}
        </div>
        <div className="dropdownItemWrapper__dropdownItem__button">
          {props.iconString ? (
            <Icon val={props.iconString} />
          ) : props.active ? (
            <Icon val="check" />
          ) : (
            <Icon val="checkbox" />
          )}
        </div>
      </div>
    </div>
  );
};
